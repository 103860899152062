import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={"100%"} viewBox="0 0 191.38 156.72" {...props}>
      <g
        style={{
          isolation: "isolate",
        }}
      >
        <g id="servicescardphoto3__Layer_2" data-name="Layer 2">
          <g id="servicescardphoto3__Layer_1-2" data-name="Layer 1">
            <path
              fill="#232323"
              d="M91.42 109.45l1.81-.97 3.41-4.33-5.74-2.34.52 7.64z"
            />
            <path
              d="M93.65 106.59l-.19-.34 4.32-2.57a4.15 4.15 0 014 .36 13.31 13.31 0 016.22 10.32 4.37 4.37 0 01-1.62 3.92l-.13.08-4.5 2.64-.28-.4a5.42 5.42 0 01-2.41-.73A13.33 13.33 0 0193 109.56a5.36 5.36 0 01.65-2.97z"
              fill="#3c3c3b"
            />
            <ellipse
              cx={97.62}
              cy={113.62}
              rx={4.89}
              ry={8.48}
              transform="rotate(-29.94 97.65 113.629)"
              fill="#575756"
            />
            <path
              d="M101.09 115.62a8 8 0 00-3.54-6.19c-1.91-1.1-3.44-.11-3.4 2.2a8 8 0 003.55 6.19c1.9 1.1 3.43.1 3.39-2.2z"
              fill="#d9d9d9"
            />
            <path
              d="M100.16 113.93a3 3 0 00-1.31-2.28c-.7-.41-1.26 0-1.25.81a3 3 0 001.31 2.28c.7.4 1.26.04 1.25-.81zM7.23 58.54l-.18-.35 4.32-2.57a4.16 4.16 0 014 .36 13.3 13.3 0 016.17 10.32 4.34 4.34 0 01-1.62 3.92l-.13.08-4.45 2.7-.28-.41a5.26 5.26 0 01-2.41-.73 13.27 13.27 0 01-6.11-10.35 5.32 5.32 0 01.69-2.97z"
              fill="#3c3c3b"
            />
            <ellipse
              cx={11.21}
              cy={65.56}
              rx={4.89}
              ry={8.48}
              transform="rotate(-29.94 11.219 65.573)"
              fill="#575756"
            />
            <ellipse
              cx={11.21}
              cy={65.56}
              rx={2.85}
              ry={5.05}
              transform="rotate(-28.26 11.209 65.55)"
              fill="#d9d9d9"
            />
            <ellipse
              cx={12.46}
              cy={65.13}
              rx={1.05}
              ry={1.86}
              transform="rotate(-28.26 12.456 65.124)"
              fill="#3c3c3b"
            />
            <path
              d="M20.91 66.2l-.19-.35L25 63.28a4.13 4.13 0 014.05.36A13.28 13.28 0 0135.22 74a4.39 4.39 0 01-1.62 3.93l-.13.08L29 80.62l-.28-.4a5.45 5.45 0 01-2.41-.74 13.3 13.3 0 01-6.11-10.31 5.34 5.34 0 01.71-2.97z"
              fill="#3c3c3b"
            />
            <ellipse
              cx={24.88}
              cy={73.23}
              rx={4.89}
              ry={8.48}
              transform="rotate(-29.94 24.879 73.233)"
              fill="#575756"
            />
            <ellipse
              cx={24.88}
              cy={73.23}
              rx={2.85}
              ry={5.05}
              transform="rotate(-28.26 24.867 73.212)"
              fill="#d9d9d9"
            />
            <path
              d="M27.41 73.53a3 3 0 00-1.3-2.28c-.7-.4-1.27 0-1.25.81a3 3 0 001.3 2.28c.71.41 1.27.04 1.25-.81zM34.58 73.86l-.19-.34L38.71 71a4.15 4.15 0 014.05.36 13.31 13.31 0 016.12 10.31c0 1.9-.59 3.25-1.62 3.93l-.13.07-4.43 2.64-.28-.4a5.42 5.42 0 01-2.42-.76 13.33 13.33 0 01-6.11-10.32 5.34 5.34 0 01.69-2.97z"
              fill="#3c3c3b"
            />
            <ellipse
              cx={38.55}
              cy={80.89}
              rx={4.89}
              ry={8.48}
              transform="rotate(-29.94 38.558 80.898)"
              fill="#575756"
            />
            <ellipse
              cx={38.55}
              cy={80.89}
              rx={2.85}
              ry={5.05}
              transform="rotate(-28.26 38.545 80.88)"
              fill="#d9d9d9"
            />
            <path
              d="M41.09 81.19a3 3 0 00-1.31-2.28c-.7-.4-1.26 0-1.25.82A3 3 0 0039.84 82c.7.41 1.26 0 1.25-.81zM48.25 81.53l-.19-.35 4.32-2.57a4.15 4.15 0 014.06.37 13.3 13.3 0 016.11 10.31c0 1.89-.59 3.25-1.62 3.92l-.14.08L56.35 96l-.27-.41a5.33 5.33 0 01-2.42-.73 13.33 13.33 0 01-6.11-10.36 5.42 5.42 0 01.7-2.97z"
              fill="#3c3c3b"
            />
            <ellipse
              cx={52.22}
              cy={88.56}
              rx={4.89}
              ry={8.48}
              transform="rotate(-29.94 52.237 88.563)"
              fill="#575756"
            />
            <path
              d="M55.69 90.55a8 8 0 00-3.54-6.19c-1.91-1.1-3.43-.11-3.39 2.2a8 8 0 003.54 6.19c1.9 1.1 3.43.11 3.39-2.2z"
              fill="#d9d9d9"
            />
            <path
              d="M54.76 88.86a3 3 0 00-1.31-2.28c-.7-.41-1.26 0-1.25.81a3 3 0 001.31 2.28c.7.4 1.26.04 1.25-.81z"
              fill="#3c3c3b"
            />
            <path
              fill="#ed1c24"
              d="M123.39 117.93l24.17-14.56L23.27 35.61 0 50.51l123.39 67.42z"
            />
            <path
              fill="#575756"
              d="M138.19 98.35l-23.26 14.97L0 50.51l23.27-14.9 114.92 62.74z"
            />
            <path
              fill="#1a1a1a"
              d="M137.55 62.2l-11.49 30.99L23.27 35.61 23.52 0l114.03 62.2z"
            />
            <path
              d="M123.39 117.93l1.76.79 4.24 2.47.67 2.21s-3.57-2.46-3.51 1.41v1.77a5.6 5.6 0 00-.45 2.08l-2.58-1.45-18.11-10-.07-4a6.23 6.23 0 00-2.9-4.82l-8.34-4.63c-1.57-.86-2.82-.11-2.79 1.68l.07 4L.16 59.8 0 50.51z"
              fill="#8e000b"
            />
            <path
              d="M.16 59.8L0 50.51l114.92 62.74.17 9.29-9.64-5.33-.07-4a6.23 6.23 0 00-2.9-4.82l-8.34-4.63c-1.57-.86-2.82-.11-2.79 1.68l.07 4z"
              fill="#3c3c3b"
            />
            <g>
              <ellipse
                cx={52.22}
                cy={88.56}
                rx={4.89}
                ry={8.48}
                transform="rotate(-29.94 52.237 88.563)"
                fill="#575756"
              />
              <path
                d="M55.69 90.55a8 8 0 00-3.54-6.19c-1.91-1.1-3.43-.11-3.39 2.2a8 8 0 003.54 6.19c1.9 1.1 3.43.11 3.39-2.2z"
                fill="#d9d9d9"
              />
              <path
                d="M54.76 88.86a3 3 0 00-1.31-2.28c-.7-.41-1.26 0-1.25.81a3 3 0 001.31 2.28c.7.4 1.26.04 1.25-.81z"
                fill="#3c3c3b"
              />
            </g>
            <g>
              <ellipse
                cx={38.55}
                cy={80.89}
                rx={4.89}
                ry={8.48}
                transform="rotate(-29.94 38.558 80.898)"
                fill="#575756"
              />
              <ellipse
                cx={38.55}
                cy={80.89}
                rx={2.85}
                ry={5.05}
                transform="rotate(-28.26 38.545 80.88)"
                fill="#d9d9d9"
              />
              <path
                d="M41.09 81.19a3 3 0 00-1.31-2.28c-.7-.4-1.26 0-1.25.82A3 3 0 0039.84 82c.7.41 1.26 0 1.25-.81z"
                fill="#3c3c3b"
              />
            </g>
            <g>
              <ellipse
                cx={24.88}
                cy={73.23}
                rx={4.89}
                ry={8.48}
                transform="rotate(-29.94 24.879 73.233)"
                fill="#575756"
              />
              <ellipse
                cx={24.88}
                cy={73.23}
                rx={2.85}
                ry={5.05}
                transform="rotate(-28.26 24.867 73.212)"
                fill="#d9d9d9"
              />
              <path
                d="M27.41 73.53a3 3 0 00-1.3-2.28c-.7-.4-1.27 0-1.25.81a3 3 0 001.3 2.28c.71.41 1.27.04 1.25-.81z"
                fill="#3c3c3b"
              />
            </g>
            <g>
              <ellipse
                cx={11.21}
                cy={65.56}
                rx={4.89}
                ry={8.48}
                transform="rotate(-29.94 11.219 65.573)"
                fill="#575756"
              />
              <ellipse
                cx={11.21}
                cy={65.56}
                rx={2.85}
                ry={5.05}
                transform="rotate(-28.26 11.209 65.55)"
                fill="#d9d9d9"
              />
              <ellipse
                cx={12.46}
                cy={65.13}
                rx={1.05}
                ry={1.86}
                transform="rotate(-28.26 12.456 65.124)"
                fill="#3c3c3b"
              />
            </g>
            <path
              style={{
                mixBlendMode: "overlay",
              }}
              fill="#094aa8"
              d="M123.08 126.39l.47.27-.15-8.25-.47-.26.15 8.24z"
            />
            <path
              fill="#333"
              d="M23.27 35.61L23.52 0 .24 14.97 0 50.51l23.27-14.9z"
            />
            <g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M28.02 42.22l.12 8.66-9.53 5.77-.13-8.66 9.54-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M24.3 44.48l.03 1.87-.66.4-.76.46-.02-1.88 1.41-.85z"
                />
                <path
                  fill="#fed0b5"
                  d="M18.48 47.99l.13 8.66-11.48-6.37L7 41.63l11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M28.02 42.22l-9.54 5.77L7 41.63l9.54-5.77 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M24.3 44.48l-1.41.85-11.48-6.36 1.41-.85 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M10.82 50.12a.75.75 0 010 .2.59.59 0 01-.28.55.3.3 0 00-.14.29l.14.08a.08.08 0 010 .07l-.36-.19a.12.12 0 010-.08l.13.07a.91.91 0 00-.14-.45 1.51 1.51 0 01-.29-1zM11.53 50.25l1.66.92v1.88l-1.65-.91zm1.6 2.67v-1.7l-1.5-.83v1.7l1.49.83" />
                <path d="M12.37 50.92a1.4 1.4 0 01.58.91.24.24 0 00-.1-.15.07.07 0 00-.1 0 .3.3 0 00-.1-.14.07.07 0 00-.1 0 .21.21 0 00-.11-.13.06.06 0 00-.07 0v.64c0 .13-.09.18-.2.12a.45.45 0 01-.21-.35v-.07.25a.3.3 0 00.15.24.09.09 0 00.14-.08v-.66a.19.19 0 00-.07-.08h-.1a.21.21 0 00-.11-.13.06.06 0 00-.1 0 .24.24 0 00-.1-.14.07.07 0 00-.1 0 .37.37 0 01.58-.26s.1.02.12.03zM8.9 50.29v.11l-1.01-.57-.01-.11 1.02.57zM8.9 50.07v.11l-1.02-.56v-.11l.16.09.13.07.44.24.13.07.16.09z" />
                <path d="M8.66 48.93l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27z" />
                <path d="M7.54 48l1.66 1v1.88l-1.65-.91zm1.6 2.67v-1.7l-1.5-.83v1.7l1.49.83" />
                <path d="M8.09 48.62l.25.54-.18-.11.01.62-.13-.07-.01-.62-.18-.1.24-.26zM9.54 49.15l1.65.92v1.88L9.57 51zm1.6 2.66v-1.7l-1.49-.82v1.7l1.5.82" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M41.08 49.51l.13 8.65-9.54 5.77-.13-8.65 9.54-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M37.36 51.76l.03 1.88-.66.4-.75.46-.03-1.88 1.41-.86z"
                />
                <path
                  fill="#fed0b5"
                  d="M31.54 55.28l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M41.08 49.51l-9.54 5.77-11.48-6.36 9.54-5.77 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M37.36 51.76l-1.41.86-11.48-6.36 1.41-.86 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M23.88 57.4a.76.76 0 010 .21.57.57 0 01-.28.54.3.3 0 00-.13.3l.13.07a.12.12 0 010 .08l-.36-.2a.08.08 0 010-.07l.14.07a.89.89 0 00-.15-.45A1.5 1.5 0 0123 56.9zM24.6 57.54l1.65.92v1.88l-1.66-.92zm1.6 2.67v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M25.43 58.21a1.47 1.47 0 01.59.91.3.3 0 00-.1-.14.09.09 0 00-.11 0 .19.19 0 00-.1-.13.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.07v.64c0 .13-.09.18-.2.12a.5.5 0 01-.21-.35v-.07a.11.11 0 010 .06v.07a.32.32 0 00.14.24c.08 0 .14 0 .14-.08v-.65a.12.12 0 00-.07-.07.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.23.23 0 00-.11-.14.06.06 0 00-.1 0 .37.37 0 01.58-.26s.1.12.12.13zM21.96 57.57l.01.11-1.02-.56v-.11l1.01.56zM21.96 57.36v.11l-1.01-.56-.01-.11.16.08.13.07.44.25.13.07.16.09z" />
                <path d="M21.72 56.22l.26.54-.19-.1.01.61-.13-.07-.01-.61-.18-.11.24-.26z" />
                <path d="M20.61 55.33l1.65.92v1.88l-1.66-.92zm1.6 2.67v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M21.15 55.9l.26.54-.19-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27zM22.6 56.44l1.66.91v1.89l-1.65-.92zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M54.14 56.8l.13 8.65-9.54 5.77-.12-8.65 9.53-5.77z"
                  />
                  <path
                    fill="#e2af98"
                    d="M50.43 59.05l.02 1.88-.65.4-.76.45-.03-1.87 1.42-.86z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M44.61 62.57l.12 8.65-11.47-6.36-.13-8.66 11.48 6.37z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M54.14 56.8l-9.53 5.77-11.48-6.37 9.53-5.76 11.48 6.36z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M50.43 59.05l-1.42.86-11.48-6.36 1.42-.86 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M37 64.69a1.5 1.5 0 010 .2.54.54 0 01-.28.55.32.32 0 00-.13.3l.13.07a.09.09 0 01.05.07h-.05l-.35-.2a.08.08 0 01-.05-.07h.05l.13.08a.93.93 0 00-.15-.46 1.52 1.52 0 01-.28-1zM37.66 64.83l1.65.92v1.88l-1.65-.92zm1.6 2.66v-1.7L37.74 65v1.7l1.49.82" />
                  <path d="M38.49 65.5a1.41 1.41 0 01.59.91.26.26 0 00-.1-.14h-.1a.26.26 0 00-.11-.14.07.07 0 00-.1 0 .3.3 0 00-.1-.14h-.07v.64c0 .12-.08.18-.2.11a.42.42 0 01-.2-.34v-.07.07a.32.32 0 00.14.25c.08 0 .14 0 .14-.09v-.64l.02.08h-.11a.26.26 0 00-.1-.14.07.07 0 00-.1 0 .22.22 0 00-.1-.14h-.1a.35.35 0 01.57-.25l.03.03zM35.03 64.86v.11l-1.02-.56v-.11l1.02.56zM35.02 64.65l.01.11-1.02-.57v-.11l.15.09.14.07.44.25.13.07.15.09z" />
                  <path d="M34.79 63.51l.25.54-.18-.1.01.61-.13-.07-.01-.62-.18-.1.24-.26z" />
                  <path d="M33.67 62.62l1.65.92v1.88l-1.62-.92zm1.6 2.66v-1.7l-1.49-.83v1.7l1.49.83" />
                  <path d="M34.22 63.19l.25.54-.18-.1.01.61-.14-.07v-.61l-.18-.1.24-.27zM35.66 63.72l1.66.92v1.88l-1.66-.91zm1.61 2.67v-1.7l-1.49-.83v1.7l1.5.83" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M67.2 64.08l.13 8.66-9.53 5.77-.13-8.66 9.53-5.77z"
                  />
                  <path
                    fill="#e2af98"
                    d="M63.49 66.34l.03 1.88-.66.4-.76.45-.02-1.88 1.41-.85z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M57.67 69.85l.13 8.66-11.48-6.36-.13-8.66 11.48 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M67.2 64.08l-9.53 5.77-11.48-6.36 9.53-5.77 11.48 6.36z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M63.49 66.34l-1.41.85-11.48-6.36 1.41-.85 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M50 72a.75.75 0 010 .2.59.59 0 01-.28.55.31.31 0 00-.14.29l.14.08a.08.08 0 010 .07L49.4 73a.13.13 0 010-.07l.13.08a.91.91 0 00-.14-.46 1.51 1.51 0 01-.29-1zM50.72 72.12l1.66.91v1.89L50.75 74zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M51.56 72.79a1.4 1.4 0 01.58.91.22.22 0 00-.1-.14.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.23.23 0 00-.11-.14.06.06 0 00-.07 0V74c0 .12-.09.17-.2.11a.45.45 0 01-.21-.34v-.07.07a.3.3 0 00.15.24c.07 0 .14 0 .14-.08v-.64a.19.19 0 00-.07-.08h-.1a.2.2 0 00-.11-.14.06.06 0 00-.1 0 .3.3 0 00-.1-.14.07.07 0 00-.1 0 .37.37 0 01.58-.26s.1.11.12.12zM48.09 72.15v.11l-1.01-.57-.01-.11 1.02.57zM48.09 71.93v.11l-1.02-.56v-.11l.16.09.13.07.44.25.13.07.16.08z" />
                  <path d="M47.85 70.79l.25.54-.18-.1.01.62-.13-.07-.01-.62-.18-.1.24-.27z" />
                  <path d="M46.73 69.91l1.66.91v1.88l-1.65-.91zm1.6 2.66v-1.7L46.81 70v1.7l1.49.83" />
                  <path d="M47.28 70.48l.25.54-.18-.1.01.61-.13-.07-.01-.62-.18-.1.24-.26zM48.73 71l1.65.92v1.88l-1.66-.92zm1.6 2.67v-1.7l-1.49-.83v1.7l1.5.83" />
                </g>
              </g>
            </g>
            <g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M80.1 71.5l.13 8.66-9.53 5.76-.13-8.65 9.53-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M76.39 73.75l.03 1.89-.66.39-.76.46-.03-1.88 1.42-.86z"
                />
                <path
                  fill="#fed0b5"
                  d="M70.57 77.27l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M80.1 71.5l-9.53 5.77-11.48-6.36 9.53-5.77L80.1 71.5z"
                />
                <path
                  fill="#fed0b5"
                  d="M76.39 73.75l-1.42.86-11.48-6.36 1.42-.86 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M62.91 79.4v.2a.59.59 0 01-.28.55.3.3 0 00-.14.29l.13.08a.08.08 0 01.05.07l-.36-.19v-.08c0-.08 0 0 0 0l.13.07a.83.83 0 00-.15-.45 1.54 1.54 0 01-.28-1zM63.62 79.54l1.66.91v1.88l-1.65-.91zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                <path d="M64.46 80.2a1.44 1.44 0 01.58.91.22.22 0 00-.1-.14h-.1a.3.3 0 00-.1-.14h-.1a.24.24 0 00-.11-.13.06.06 0 00-.07 0v.64c0 .13-.09.18-.2.12a.42.42 0 01-.21-.34v-.08.07a.32.32 0 00.14.24c.08 0 .15 0 .15-.08v-.64a.29.29 0 00-.07-.08h-.1a.24.24 0 00-.11-.13.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.36.36 0 01.57-.26s.11.17.13.18zM60.99 79.57v.11l-1.02-.57V79l1.02.57zM60.99 79.35v.11l-1.02-.56v-.11l.16.09.13.07.44.24.13.08.16.08z" />
                <path d="M60.75 78.21l.25.54-.18-.1.01.62-.13-.08-.01-.61-.18-.1.24-.27z" />
                <path d="M59.63 77.33l1.66.91v1.88l-1.65-.91zm1.6 2.67v-1.7l-1.5-.83v1.7l1.49.83" />
                <path d="M60.18 77.9l.25.54-.18-.11.01.62-.13-.07-.01-.62-.18-.1.24-.26zM61.63 78.43l1.65.92v1.88l-1.66-.92zm1.6 2.66v-1.7l-1.49-.82v1.7l1.5.82" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M93.17 78.79l.13 8.65-9.54 5.77-.13-8.65 9.54-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M89.45 81.04l.03 1.88-.66.4-.75.46-.03-1.88 1.41-.86z"
                />
                <path
                  fill="#fed0b5"
                  d="M83.63 84.56l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M93.17 78.79l-9.54 5.77-11.48-6.36 9.54-5.77 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M89.45 81.04l-1.41.86-11.48-6.36 1.41-.86 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M76 86.68a.76.76 0 010 .21.6.6 0 01-.28.55.3.3 0 00-.14.29l.14.07a.12.12 0 010 .08l-.36-.2a.08.08 0 010-.07l.14.07a1 1 0 00-.15-.45 1.56 1.56 0 01-.29-1zM76.68 86.82l1.66.92v1.88l-1.66-.92zm1.61 2.67v-1.7L76.77 87v1.7l1.5.83" />
                <path d="M77.52 87.49a.09.09 0 010 .05 1.47 1.47 0 01.59.91.26.26 0 00-.11-.14.07.07 0 00-.1 0 .26.26 0 00-.1-.14h-.1a.26.26 0 00-.11-.14h-.07v.64c0 .13-.09.18-.2.12a.47.47 0 01-.21-.35v-.07a.07.07 0 010 .06v.07a.33.33 0 00.15.24c.08 0 .14 0 .14-.08v-.65a.16.16 0 00-.07-.07.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.23.23 0 00-.11-.14.06.06 0 00-.1 0 .36.36 0 01.58-.26s.1.08.12.09zM74.05 86.85v.11l-1.01-.56v-.11l1.01.56zM74.05 86.64v.11l-1.02-.56v-.11l.16.08.13.08.44.24.13.07.16.09z" />
                <path d="M73.81 85.5l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27z" />
                <path d="M72.69 84.61l1.66.92v1.88l-1.66-.92zm1.61 2.67v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M73.24 85.18l.25.54-.18-.1.01.62-.13-.08-.01-.61-.18-.1.24-.27zM74.69 85.72l1.65.91v1.89l-1.65-.92zm1.6 2.66v-1.7l-1.49-.83v1.7l1.49.83" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M106.23 86.08l.13 8.65-9.54 5.77-.13-8.65 9.54-5.77z"
                  />
                  <path
                    fill="#e2af98"
                    d="M102.52 88.33l.02 1.88-.66.4-.75.46-.03-1.88 1.42-.86z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M96.69 91.85l.13 8.65-11.48-6.36-.12-8.65 11.47 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M106.23 86.08l-9.54 5.77-11.47-6.36 9.53-5.77 11.48 6.36z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M102.52 88.33l-1.42.86-11.48-6.36 1.41-.86 11.49 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M89 94a1.5 1.5 0 010 .2.56.56 0 01-.28.55.3.3 0 00-.13.3l.13.07a.1.1 0 010 .07l-.35-.2a.08.08 0 01-.05-.07l.14.08a.93.93 0 00-.15-.46 1.47 1.47 0 01-.28-1zM89.75 94.11l1.65.89v1.88L89.77 96zm1.6 2.66v-1.7l-1.49-.82v1.7l1.5.82" />
                  <path d="M90.58 94.78a1.41 1.41 0 01.59.91.26.26 0 00-.1-.14h-.11a.24.24 0 00-.1-.14.07.07 0 00-.1 0 .3.3 0 00-.1-.14h-.07V96a.13.13 0 01-.2.12.46.46 0 01-.2-.35v-.07a.09.09 0 010 .05v.07a.32.32 0 00.14.25c.08 0 .14 0 .14-.09v-.64a.18.18 0 00-.08-.07.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.22.22 0 00-.1-.14h-.11a.36.36 0 01.58-.25s.1.03.12.04zM87.12 94.14v.11l-1.02-.56v-.11l1.02.56zM87.11 93.93v.11l-1.01-.57v-.11l.15.09.13.07.44.25.13.07.16.09z" />
                  <path d="M86.87 92.79l.26.54-.19-.11.01.62-.13-.07-.01-.62-.18-.1.24-.26z" />
                  <path d="M85.76 91.9l1.65.92v1.88l-1.65-.92zm1.6 2.66v-1.7L85.84 92v1.7l1.5.82" />
                  <path d="M86.3 92.47l.26.54-.19-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27zM87.75 93l1.66.92v1.88l-1.65-.91zm1.6 2.67v-1.7l-1.5-.83v1.7l1.49.83" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M119.29 93.36l.13 8.66-9.53 5.77-.13-8.66 9.53-5.77z"
                  />
                  <path
                    fill="#e2af98"
                    d="M115.58 95.62l.03 1.88-.66.4-.76.45-.03-1.88 1.42-.85z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M109.76 99.13l.13 8.66-11.48-6.36-.13-8.66 11.48 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M119.29 93.36l-9.53 5.77-11.48-6.36 9.53-5.77 11.48 6.36z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M115.58 95.62l-1.42.85-11.48-6.36 1.42-.85 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M102.1 101.26a1.5 1.5 0 010 .2.55.55 0 01-.28.55.32.32 0 00-.13.29l.13.08a.08.08 0 010 .07l-.36-.2v-.07c0-.07 0 0 0 0l.13.08a.93.93 0 00-.15-.46 1.54 1.54 0 01-.28-1zM102.81 101.4l1.65.91v1.89l-1.65-.92zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M103.65 102.07a.08.08 0 010 .05 1.44 1.44 0 01.58.91.26.26 0 00-.1-.14h-.1a.3.3 0 00-.1-.14h-.11a.22.22 0 00-.1-.14.06.06 0 00-.07 0v.65c0 .12-.09.17-.2.11a.42.42 0 01-.21-.34V103v.07a.32.32 0 00.14.24c.08 0 .15 0 .14-.08v-.64a.29.29 0 00-.07-.08h-.1c-.01 0-.06-.12-.11-.14a.06.06 0 00-.1 0 .28.28 0 00-.1-.15h-.1a.36.36 0 01.57-.26s.12.1.14.11zM100.18 101.43v.11l-1.02-.56v-.12l1.02.57zM100.18 101.22v.11l-1.02-.57v-.11l.16.09.13.07.44.25.13.07.16.09z" />
                  <path d="M99.94 100.07l.25.54-.18-.1.01.62-.13-.07-.01-.62-.18-.1.24-.27z" />
                  <path d="M98.82 99.19l1.65.91v1.89l-1.65-.92zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M99.37 99.76l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.11.24-.26zM100.82 100.29l1.65.92v1.88l-1.66-.92zm1.6 2.67v-1.7l-1.49-.83v1.7l1.5.83" />
                </g>
              </g>
            </g>
            <g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M33.73 35.09l.13 8.65-9.54 5.77-.13-8.66 9.54-5.76z"
                />
                <path
                  fill="#e2af98"
                  d="M30.01 37.34l.03 1.88-.66.4-.75.45-.03-1.88 1.41-.85z"
                />
                <path
                  fill="#fed0b5"
                  d="M24.19 40.85l.13 8.66-11.48-6.36-.13-8.66 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M33.73 35.09l-9.54 5.76-11.48-6.36 9.54-5.76 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M30.01 37.34l-1.41.85-11.48-6.36 1.41-.85 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M16.53 43a.75.75 0 010 .2.58.58 0 01-.28.55.31.31 0 00-.14.29l.14.08a.08.08 0 010 .07l-.36-.2a.1.1 0 010-.07l.14.08a1 1 0 00-.15-.46 1.48 1.48 0 01-.28-1zM17.24 43.12l1.66.88v1.89L17.27 45zm1.61 2.66v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M18.08 43.79a.05.05 0 010 .05 1.41 1.41 0 01.59.91.23.23 0 00-.11-.14.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.23.23 0 00-.11-.14.06.06 0 00-.07 0V45c0 .12-.09.18-.2.11a.45.45 0 01-.21-.34v-.07a.05.05 0 010 .05v.07a.33.33 0 00.15.24c.08.05.14 0 .14-.08v-.64a.19.19 0 00-.07-.08.07.07 0 00-.1 0 .26.26 0 00-.1-.14h-.1a.25.25 0 00-.11-.15.07.07 0 00-.1 0 .36.36 0 01.58-.25s.1.06.12.07zM14.61 43.15l.01.11-1.02-.56v-.11l1.01.56zM14.61 42.94v.11l-1.01-.57-.01-.11.16.09.13.07.44.25.13.07.16.09z" />
                <path d="M14.37 41.8l.26.54-.19-.11.01.62-.13-.07-.01-.62-.18-.1.24-.26z" />
                <path d="M13.26 40.91l1.65.91v1.89l-1.66-.92zm1.6 2.66v-1.7L13.34 41v1.7l1.5.83" />
                <path d="M13.8 41.48l.25.54-.18-.1.01.61-.13-.07-.01-.62-.18-.1.24-.26zM15.25 42l1.65.92v1.88l-1.65-.92zm1.6 2.67v-1.7l-1.5-.83v1.7l1.49.83" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M46.79 42.37l.13 8.66-9.54 5.77-.12-8.66 9.53-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M43.08 44.63l.02 1.88-.65.39-.76.46-.03-1.88 1.42-.85z"
                />
                <path
                  fill="#fed0b5"
                  d="M37.26 48.14l.12 8.66-11.48-6.36-.12-8.66 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M46.79 42.37l-9.53 5.77-11.48-6.36 9.53-5.77 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M43.08 44.63l-1.42.85-11.48-6.36 1.41-.85 11.49 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M29.6 50.27a1.5 1.5 0 010 .2.57.57 0 01-.28.55c-.09 0-.13.15-.13.29l.13.08a.08.08 0 010 .07l-.32-.17a.1.1 0 010-.08l.13.07a.92.92 0 00-.15-.45 1.49 1.49 0 01-.28-1.05zM30.31 50.41l1.65.91v1.88l-1.65-.91zm1.6 2.66v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M31.14 51.08a1.41 1.41 0 01.59.91.22.22 0 00-.1-.14h-.1a.31.31 0 00-.11-.14.08.08 0 00-.1 0 .23.23 0 00-.1-.13.06.06 0 00-.07 0v.64c0 .13-.08.18-.2.12a.44.44 0 01-.2-.34.09.09 0 010 .05v.07a.29.29 0 00.14.24c.08 0 .14 0 .14-.08v-.64a.2.2 0 00-.08-.08.08.08 0 00-.1 0 .23.23 0 00-.1-.13.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.11a.37.37 0 01.58-.26s.1.04.12.05zM27.68 50.44v.11l-1.02-.57v-.11l1.02.57zM27.67 50.22l.01.11-1.02-.56v-.11l.15.09.13.07.45.24.13.07.15.09z" />
                <path d="M27.43 49.08l.26.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.23-.27z" />
                <path d="M26.32 48.2l1.65.91L28 51l-1.65-.91zm1.6 2.66v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M26.86 48.77l.26.54-.19-.11.01.62-.13-.07-.01-.62-.18-.1.24-.26zM28.31 49.3l1.66.92v1.88l-1.66-.92zm1.6 2.7v-1.7l-1.5-.82v1.7l1.49.82" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M59.85 49.66l.13 8.65-9.53 5.77-.13-8.65 9.53-5.77z"
                  />
                  <path
                    fill="#e2af98"
                    d="M56.14 51.91l.03 1.88-.66.4-.76.46-.02-1.88 1.41-.86z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M50.32 55.43l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M59.85 49.66l-9.53 5.77-11.48-6.36 9.53-5.77 11.48 6.36z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M56.14 51.91l-1.41.86-11.49-6.36 1.42-.86 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M42.66 57.55a1.52 1.52 0 010 .21.6.6 0 01-.28.55.3.3 0 00-.14.29l.13.07a.1.1 0 01.05.08l-.36-.2a.13.13 0 010-.07l.13.07a.88.88 0 00-.19-.45 1.51 1.51 0 01-.29-1.05zM43.37 57.69l1.66.92v1.88l-1.65-.92zM45 60.36v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M44.21 58.36a1.49 1.49 0 01.58.92.28.28 0 00-.1-.15.07.07 0 00-.1 0 .26.26 0 00-.1-.14h-.1a.31.31 0 00-.11-.14h-.07v.64c0 .13-.09.18-.2.12a.44.44 0 01-.21-.35v-.07a.07.07 0 010 .06v.07a.33.33 0 00.14.24c.08 0 .15 0 .15-.08v-.65a.23.23 0 00-.07-.07h-.1a.26.26 0 00-.11-.14.07.07 0 00-.1 0 .26.26 0 00-.1-.14h-.1a.36.36 0 01.57-.26s.11.13.13.14zM40.74 57.72v.11l-1.02-.56v-.11l1.02.56zM40.74 57.51v.11l-1.02-.56v-.11l.16.08.13.08.44.24.13.07.16.09z" />
                  <path d="M40.5 56.37l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27z" />
                  <path d="M39.38 55.48l1.62.92v1.88l-1.65-.92zM41 58.15v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M39.93 56.05l.25.54-.18-.1.01.62-.13-.08-.01-.61-.18-.1.24-.27zM41.38 56.59l1.62.91v1.89l-1.66-.92zM43 59.25v-1.7l-1.49-.83v1.7l1.5.83" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M72.92 56.95l.13 8.65-9.54 5.77-.13-8.65 9.54-5.77z"
                  />
                  <path
                    fill="#e2af98"
                    d="M69.2 59.2l.03 1.88-.66.4-.75.46-.03-1.88 1.41-.86z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M63.38 62.72l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M72.92 56.95l-9.54 5.77-11.48-6.36 9.54-5.77 11.48 6.36z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M69.2 59.2l-1.41.86-11.48-6.36 1.41-.86L69.2 59.2z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M55.72 64.84a.75.75 0 010 .2.58.58 0 01-.28.55.33.33 0 00-.14.3l.14.07a.1.1 0 010 .07l-.36-.2a.08.08 0 010-.07l.14.08a1 1 0 00-.15-.46 1.54 1.54 0 01-.29-1zM56.43 65l1.66.92v1.88l-1.66-.92zM58 67.64v-1.7l-1.49-.82v1.7l1.5.82" />
                  <path d="M57.27 65.65a1.41 1.41 0 01.59.91.23.23 0 00-.11-.14.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.26.26 0 00-.11-.14h-.07v.64a.13.13 0 01-.2.12.47.47 0 01-.21-.35V66.67a.33.33 0 00.15.25c.08 0 .14 0 .14-.09v-.64a.16.16 0 00-.07-.07.06.06 0 00-.1 0A.3.3 0 0057 66h-.1a.2.2 0 00-.11-.14.06.06 0 00-.1 0 .37.37 0 01.58-.25s-.02.03 0 .04zM53.8 65.01v.11l-1.01-.56v-.11l1.01.56zM53.8 64.8v.11l-1.02-.57v-.11l.16.09.13.07.44.25.13.07.16.09z" />
                  <path d="M53.56 63.66l.25.54-.18-.1.01.61-.13-.07-.01-.62-.18-.1.24-.26z" />
                  <path d="M52.44 62.77l1.66.92v1.88l-1.66-.92zm1.61 2.66v-1.7l-1.49-.82v1.7l1.5.82" />
                  <path d="M52.99 63.34l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27zM54.44 63.87l1.65.92v1.88l-1.65-.91zM56 66.54v-1.7L54.52 64v1.7l1.49.83" />
                </g>
              </g>
            </g>
            <g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M85.81 64.37l.13 8.65-9.53 5.77-.13-8.66 9.53-5.76z"
                />
                <path
                  fill="#e2af98"
                  d="M82.1 66.62l.03 1.88-.66.4-.75.45-.03-1.88 1.41-.85z"
                />
                <path
                  fill="#fed0b5"
                  d="M76.28 70.13l.13 8.66-11.48-6.36-.13-8.66 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M85.81 64.37l-9.53 5.76-11.48-6.36 9.53-5.76 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M82.1 66.62l-1.41.85-11.48-6.36 1.41-.85 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M68.62 72.26a.75.75 0 010 .2.58.58 0 01-.28.55.31.31 0 00-.14.3l.14.07a.08.08 0 010 .07l-.36-.2a.1.1 0 010-.07l.13.08a.91.91 0 00-.11-.46 1.49 1.49 0 01-.29-1zM69.33 72.4l1.66.92v1.88l-1.66-.92zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                <path d="M70.17 73.07a.09.09 0 010 .05 1.45 1.45 0 01.59.91.23.23 0 00-.11-.14.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.23.23 0 00-.11-.14.06.06 0 00-.07 0v.65c0 .12-.09.18-.2.11a.45.45 0 01-.21-.34V74v.07a.3.3 0 00.15.24c.07 0 .14 0 .14-.08v-.64a.27.27 0 00-.07-.08h-.1a.26.26 0 00-.1-.14h-.11a.24.24 0 00-.1-.15.07.07 0 00-.1 0 .36.36 0 01.58-.25s.1.09.12.1zM66.7 72.43v.11l-1.01-.56v-.11l1.01.56zM66.7 72.22v.11l-1.02-.57v-.11l.16.09.13.07.44.25.13.07.16.09z" />
                <path d="M66.46 71.08l.25.53-.18-.1.01.62-.13-.07-.01-.62-.18-.1.24-.26z" />
                <path d="M65.34 70.19l1.66.91V73l-1.66-.92zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                <path d="M65.89 70.76l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27zM67.34 71.29l1.65.92v1.88l-1.65-.91zm1.6 2.71v-1.7l-1.49-.83v1.7l1.5.83" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M98.88 71.65l.13 8.66-9.54 5.77-.13-8.66 9.54-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M95.16 73.91l.03 1.88-.66.39-.75.46-.03-1.88 1.41-.85z"
                />
                <path
                  fill="#fed0b5"
                  d="M89.34 77.42l.13 8.66-11.48-6.36-.13-8.66 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M98.88 71.65l-9.54 5.77-11.48-6.36 9.54-5.77 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M95.16 73.91l-1.41.85-11.48-6.36 1.41-.85 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M81.69 79.55a1.5 1.5 0 010 .2.59.59 0 01-.28.55.28.28 0 00-.13.29l.13.08a.08.08 0 010 .07l-.35-.19a.1.1 0 010-.08l.14.07a.92.92 0 00-.15-.45 1.49 1.49 0 01-.28-1zM82.4 79.69l1.65.91v1.88l-1.66-.91zm1.6 2.66v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M83.23 80.36a1.41 1.41 0 01.59.91.22.22 0 00-.1-.14h-.11a.24.24 0 00-.1-.14.07.07 0 00-.1 0 .22.22 0 00-.1-.14h-.07v.64c0 .13-.09.18-.2.12a.44.44 0 01-.2-.34v-.07.07a.29.29 0 00.14.24c.08 0 .14 0 .14-.08v-.64a.14.14 0 00-.07-.08.07.07 0 00-.1 0 .22.22 0 00-.1-.14h-.1a.26.26 0 00-.11-.14.07.07 0 00-.1 0 .37.37 0 01.58-.26s.09.18.11.19zM79.77 79.72v.11l-1.02-.57v-.11l1.02.57zM79.76 79.5v.11l-1.01-.56v-.11l.15.09.13.07.44.24.13.08.16.08z" />
                <path d="M79.52 78.36l.26.54-.19-.1.01.62-.13-.08-.01-.61-.18-.1.24-.27z" />
                <path d="M78.41 77.48l1.65.91v1.88l-1.66-.91zM80 80.14v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M78.95 78.05l.26.54-.19-.11.01.62-.13-.07-.01-.62-.18-.1.24-.26zM80.4 78.58l1.66.92v1.88l-1.65-.92zm1.6 2.66v-1.7l-1.5-.82v1.7l1.49.82" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M111.94 78.94l.13 8.65-9.53 5.77-.13-8.65 9.53-5.77z"
                  />
                  <path
                    fill="#e2af98"
                    d="M108.23 81.19l.02 1.88-.65.4-.76.46-.03-1.88 1.42-.86z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M102.41 84.71l.13 8.65L91.06 87l-.13-8.65 11.48 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M111.94 78.94l-9.53 5.77-11.48-6.36 9.53-5.77 11.48 6.36z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M108.23 81.19l-1.42.86-11.48-6.36 1.42-.86 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M94.75 86.83a1.49 1.49 0 010 .21.56.56 0 01-.28.55.31.31 0 00-.13.29l.13.07s.05 0 .05.08 0 0-.05 0l-.35-.2a.08.08 0 010-.07l.13.07a.89.89 0 00-.15-.45 1.54 1.54 0 01-.28-1zM95.46 87l1.65.92v1.88l-1.65-.91zm1.6 2.67v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M96.29 87.64a1.46 1.46 0 01.59.92.28.28 0 00-.1-.15h-.1a.23.23 0 00-.11-.14.06.06 0 00-.1 0 .3.3 0 00-.1-.14.08.08 0 00-.07 0v.64c0 .13-.08.18-.2.12a.44.44 0 01-.2-.35v-.07a.11.11 0 010 .06v.07a.3.3 0 00.14.24c.08 0 .14 0 .14-.08v-.65l-.07-.07h-.1A.31.31 0 0096 88a.07.07 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.36.36 0 01.57-.26s.01.03.02.04zM92.83 87v.11l-1.02-.56v-.11l1.02.56zM92.83 86.79v.11l-1.02-.56v-.11l.16.08.12.08.45.24.13.07.16.09z" />
                  <path d="M92.59 85.65l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27z" />
                  <path d="M91.47 84.76l1.65.92v1.88l-1.65-.92zm1.6 2.67v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M92.02 85.33l.25.54-.18-.1v.62l-.12-.08-.01-.61-.18-.1.24-.27zM93.46 85.87l1.66.91v1.89l-1.66-.92zm1.61 2.66v-1.7L93.55 86v1.7l1.5.83" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M125 86.23l.13 8.65-9.53 5.77-.13-8.65 9.53-5.77z"
                  />
                  <path
                    fill="#e2af98"
                    d="M121.29 88.48l.03 1.88-.66.4-.76.46-.02-1.88 1.41-.86z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M115.47 92l.13 8.65-11.48-6.36-.13-8.65L115.47 92z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M125 86.23L115.47 92l-11.48-6.36 9.54-5.77L125 86.23z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M121.29 88.48l-1.41.86-11.48-6.36 1.41-.86 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M107.81 94.12a.75.75 0 010 .2.58.58 0 01-.28.55.33.33 0 00-.14.3l.14.07a.1.1 0 010 .07l-.36-.2a.08.08 0 010-.07l.13.08a.85.85 0 00-.14-.45 1.52 1.52 0 01-.29-1zM108.52 94.26l1.66.92v1.88l-1.65-.92zm1.6 2.66v-1.7l-1.5-.82v1.7l1.49.82" />
                  <path d="M109.36 94.93a1.4 1.4 0 01.58.91.21.21 0 00-.1-.14.08.08 0 00-.1 0 .23.23 0 00-.1-.13h-.1a.26.26 0 00-.11-.14h-.07v.64a.13.13 0 01-.2.12.47.47 0 01-.16-.19v-.07.07a.33.33 0 00.15.24c.07 0 .14 0 .14-.09v-.65a.16.16 0 00-.07-.07h-.1a.26.26 0 00-.11-.14.07.07 0 00-.1 0 .19.19 0 00-.1-.14.06.06 0 00-.1 0 .36.36 0 01.58-.25s.05.02.07.03zM105.89 94.29v.11l-1.01-.56-.01-.11 1.02.56zM105.89 94.08v.11l-1.02-.56v-.12l.16.09.13.07.44.25.13.07.16.09z" />
                  <path d="M105.65 92.94l.25.54-.18-.1.01.61-.13-.07-.01-.62-.18-.1.24-.26z" />
                  <path d="M104.53 92.05l1.66.92v1.88l-1.65-.92zm1.6 2.66v-1.7l-1.5-.82v1.7l1.49.82" />
                  <path d="M105.08 92.62l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27zM106.53 93.16l1.65.91v1.88l-1.62-.95zm1.6 2.66v-1.7l-1.49-.83v1.7l1.5.83" />
                </g>
              </g>
            </g>
            <g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M40.16 26.14l.12 8.65-9.53 5.77-.13-8.65 9.54-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M36.44 28.39l.03 1.88-.66.4-.75.46-.03-1.88 1.41-.86z"
                />
                <path
                  fill="#fed0b5"
                  d="M30.62 31.91l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M40.16 26.14l-9.54 5.77-11.48-6.36 9.54-5.77 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M36.44 28.39l-1.41.86-11.48-6.36 1.41-.86 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M23 34a.76.76 0 010 .21.6.6 0 01-.28.55.3.3 0 00-.14.29l.14.07a.12.12 0 010 .08l-.36-.2a.08.08 0 010-.07l.14.07a1 1 0 00-.15-.45 1.49 1.49 0 01-.28-1zM23.67 34.17l1.66.92v1.88l-1.66-.92zm1.61 2.67v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M24.51 34.84a1.46 1.46 0 01.59.92.25.25 0 00-.11-.15.07.07 0 00-.1 0 .26.26 0 00-.1-.14h-.1a.26.26 0 00-.11-.14.08.08 0 00-.07 0v.64c0 .13-.09.18-.2.12a.45.45 0 01-.21-.35v-.07a.07.07 0 010 .06v.07a.32.32 0 00.15.24c.08 0 .14 0 .14-.08v-.65a.16.16 0 00-.07-.07.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.23.23 0 00-.11-.14.06.06 0 00-.1 0 .37.37 0 01.58-.26s.1.13.12.14zM21.04 34.2l.01.11-1.02-.56v-.11l1.01.56zM21.04 33.99v.11l-1.02-.56v-.11l.16.08.13.08.44.24.13.07.16.09z" />
                <path d="M20.8 32.85l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27z" />
                <path d="M19.69 32l1.65.92v1.88l-1.66-.92zm1.6 2.67v-1.7l-1.49-.83v1.7l1.5.83" />
                <path d="M20.23 32.53l.25.54-.18-.1.01.62-.13-.08-.01-.61-.18-.1.24-.27zM21.68 33.07l1.65.91v1.89L21.71 35zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M53.22 33.43l.13 8.65-9.54 5.77-.12-8.65 9.53-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M49.51 35.68l.02 1.88-.65.4-.76.46-.03-1.88 1.42-.86z"
                />
                <path
                  fill="#fed0b5"
                  d="M43.69 39.2l.12 8.65-11.48-6.36-.12-8.65 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M53.22 33.43l-9.53 5.77-11.48-6.36 9.53-5.77 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M49.51 35.68l-1.42.86-11.48-6.36 1.41-.86 11.49 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M36 41.32a1.5 1.5 0 010 .2.56.56 0 01-.28.55.3.3 0 00-.13.3l.13.07a.1.1 0 010 .07l-.35-.2a.08.08 0 01-.05-.07h.05l.13.08a.93.93 0 00-.15-.46 1.47 1.47 0 01-.28-1zM36.74 41.46l1.65.92v1.88l-1.65-.92zm1.6 2.66v-1.7l-1.49-.82v1.7l1.5.82" />
                <path d="M37.57 42.13a1.41 1.41 0 01.59.91.26.26 0 00-.1-.14h-.1a.31.31 0 00-.11-.14.07.07 0 00-.1 0 .3.3 0 00-.1-.14h-.07v.64a.13.13 0 01-.2.12.46.46 0 01-.2-.35v-.07.07a.32.32 0 00.14.25c.08 0 .14 0 .14-.09v-.49a.18.18 0 00-.08-.07.06.06 0 00-.1 0 .3.3 0 00-.1-.14.07.07 0 00-.1 0 .22.22 0 00-.1-.14h-.11a.37.37 0 01.58-.25s.1.02.12.03zM34.11 41.49v.11l-1.02-.56v-.11l1.02.56zM34.1 41.28v.11l-1.01-.57v-.11l.15.09.13.07.44.25.14.07.15.09z" />
                <path d="M33.87 40.14l.25.54-.18-.1.01.61-.14-.07v-.62l-.18-.1.24-.26z" />
                <path d="M32.75 39.25l1.65.92v1.88l-1.65-.92zm1.6 2.66v-1.7l-1.49-.82v1.7l1.5.82" />
                <path d="M33.29 39.82l.26.54-.18-.1v.61l-.13-.07-.01-.61-.18-.1.24-.27zM34.74 40.35l1.66.92v1.88l-1.66-.91zm1.6 2.65v-1.7l-1.5-.83v1.7l1.49.83" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M66.28 40.72l.13 8.65-9.53 5.77-.13-8.66 9.53-5.76z"
                  />
                  <path
                    fill="#e2af98"
                    d="M62.57 42.97l.03 1.88-.66.4-.76.45-.02-1.88 1.41-.85z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M56.75 46.48l.13 8.66-11.48-6.36-.13-8.66 11.48 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M66.28 40.72l-9.53 5.76-11.48-6.36 9.53-5.77 11.48 6.37z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M62.57 42.97l-1.41.85-11.49-6.36 1.42-.85 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M49.09 48.61v.2a.59.59 0 01-.28.55.31.31 0 00-.14.29l.13.08a.08.08 0 01.05.07l-.36-.2v-.07l.13.08a.91.91 0 00-.14-.46 1.51 1.51 0 01-.29-1zM49.8 48.75l1.66.91v1.89l-1.65-.92zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M50.64 49.42a1.44 1.44 0 01.58.91.26.26 0 00-.1-.14.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1c-.01 0-.06-.12-.11-.14a.06.06 0 00-.07 0v.65c0 .12-.09.17-.2.11a.42.42 0 01-.21-.34v-.07.07a.32.32 0 00.14.24c.08 0 .15 0 .15-.08V50a.29.29 0 00-.07-.08h-.1c-.02 0-.06-.12-.11-.14a.06.06 0 00-.1 0 .28.28 0 00-.1-.15h-.1a.36.36 0 01.57-.26s.11.04.13.05zM47.17 48.78v.11l-1.02-.56v-.11l1.02.56zM47.17 48.56v.11l-1.02-.56V48l.16.09.13.07.44.25.13.07.16.08z" />
                  <path d="M46.93 47.42l.25.54-.18-.1.01.62-.13-.07-.01-.62-.18-.1.24-.27z" />
                  <path d="M45.81 46.54l1.66.91v1.89l-1.65-.92zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M46.36 47.11l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.11.24-.26zM47.81 47.64l1.65.92v1.88l-1.66-.92zm1.6 2.67v-1.7l-1.49-.83v1.7l1.5.83" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M79.35 48l.12 8.66-9.53 5.76-.13-8.65L79.35 48z"
                  />
                  <path
                    fill="#e2af98"
                    d="M75.63 50.26l.03 1.87-.66.4-.75.46-.03-1.88 1.41-.85z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M69.81 53.77l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M79.35 48l-9.54 5.77-11.48-6.36 9.54-5.77L79.35 48z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M75.63 50.26l-1.41.85-11.48-6.36 1.41-.85 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M62.15 55.9a.75.75 0 010 .2.59.59 0 01-.28.55.3.3 0 00-.14.29l.14.08a.08.08 0 010 .07l-.36-.19a.12.12 0 010-.08l.14.07a1 1 0 00-.15-.45 1.56 1.56 0 01-.29-1zM62.86 56l1.66.92v1.88l-1.66-.91zm1.61 2.67v-1.7l-1.47-.8v1.7l1.5.83" />
                  <path d="M63.7 56.7a1.41 1.41 0 01.59.91.25.25 0 00-.11-.15.07.07 0 00-.1 0 .3.3 0 00-.1-.14.07.07 0 00-.1 0 .21.21 0 00-.11-.13.06.06 0 00-.07 0V58c0 .13-.09.18-.2.12a.45.45 0 01-.21-.35v-.07.07a.3.3 0 00.15.24c.08 0 .14 0 .14-.08v-.64a.19.19 0 00-.07-.08.08.08 0 00-.1 0 .23.23 0 00-.1-.13h-.1a.26.26 0 00-.11-.14.07.07 0 00-.1 0 .37.37 0 01.58-.26s.1.01.12.02zM60.23 56.07v.11l-1.01-.57v-.11l1.01.57zM60.23 55.85v.11l-1.02-.56v-.11l.16.09.13.07.44.24.13.07.16.09z" />
                  <path d="M59.99 54.71l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27z" />
                  <path d="M58.87 53.82l1.66.92v1.88l-1.66-.91zm1.61 2.67v-1.7L59 54v1.7l1.5.83" />
                  <path d="M59.42 54.4l.25.54-.18-.11.01.62-.13-.07-.01-.62-.18-.1.24-.26zM60.87 54.93l1.65.92v1.88l-1.65-.92zm1.6 2.66v-1.7L61 55.07v1.7l1.49.82" />
                </g>
              </g>
            </g>
            <g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M92.25 55.42l.12 8.66-9.53 5.76-.13-8.65 9.54-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M88.53 57.67l.03 1.88-.66.4-.76.46-.02-1.88 1.41-.86z"
                />
                <path
                  fill="#fed0b5"
                  d="M82.71 61.19l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M92.25 55.42l-9.54 5.77-11.48-6.36 9.54-5.77 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M88.53 57.67l-1.41.86-11.48-6.36 1.41-.86 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M75.05 63.31a.76.76 0 010 .21.6.6 0 01-.28.55.3.3 0 00-.14.29l.14.07a.12.12 0 010 .08l-.36-.2a.08.08 0 010-.07l.13.07a.88.88 0 00-.14-.45 1.51 1.51 0 01-.29-1zM75.76 63.45l1.66.92v1.88l-1.66-.91zm1.6 2.67v-1.7l-1.5-.83v1.7l1.49.83" />
                <path d="M76.6 64.12a1.5 1.5 0 01.59.92.25.25 0 00-.11-.15.07.07 0 00-.1 0 .26.26 0 00-.1-.14h-.1a.26.26 0 00-.11-.14.08.08 0 00-.07 0v.64c0 .13-.09.18-.2.12a.45.45 0 01-.21-.35v-.07a.07.07 0 010 .06v.07a.32.32 0 00.15.24.09.09 0 00.14-.08v-.65a.16.16 0 00-.07-.07h-.1a.3.3 0 00-.1-.14h-.11a.24.24 0 00-.1-.14.06.06 0 00-.1 0 .36.36 0 01.58-.26s.1.13.12.14zM73.13 63.48v.11l-1.01-.56-.01-.11 1.02.56zM73.13 63.27v.11l-1.02-.56v-.11l.16.09.13.07.44.24.13.07.16.09z" />
                <path d="M72.89 62.13l.25.54-.18-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27z" />
                <path d="M71.77 61.24l1.66.92v1.88l-1.66-.92zm1.6 2.67v-1.7l-1.5-.83v1.7l1.49.83" />
                <path d="M72.32 61.81l.25.54-.18-.1.01.62-.13-.07-.01-.62-.18-.1.24-.27zM73.77 62.35l1.65.91v1.89l-1.65-.92zm1.6 2.65v-1.7l-1.49-.83v1.7l1.5.83" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M105.31 62.71l.13 8.65-9.54 5.77-.13-8.65 9.54-5.77z"
                />
                <path
                  fill="#e2af98"
                  d="M101.59 64.96l.03 1.88-.66.4-.75.46-.03-1.88 1.41-.86z"
                />
                <path
                  fill="#fed0b5"
                  d="M95.77 68.48l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                />
                <path
                  fill="#ffdcc5"
                  d="M105.31 62.71l-9.54 5.77-11.48-6.36 9.54-5.77 11.48 6.36z"
                />
                <path
                  fill="#fed0b5"
                  d="M101.59 64.96l-1.41.86-11.48-6.36 1.41-.86 11.48 6.36z"
                />
              </g>
              <g fill="#616173">
                <path d="M88.12 70.6a1.5 1.5 0 010 .2.58.58 0 01-.28.55.3.3 0 00-.13.3l.13.07a.1.1 0 010 .07l-.36-.2a.08.08 0 010-.07l.14.08a.86.86 0 00-.15-.45 1.5 1.5 0 01-.28-1.05zM88.83 70.74l1.65.92v1.88l-1.66-.92zm1.6 2.66v-1.7l-1.49-.82v1.7l1.5.82" />
                <path d="M89.66 71.41a1.41 1.41 0 01.59.91.3.3 0 00-.1-.14.09.09 0 00-.11 0 .19.19 0 00-.1-.13.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.07v.64a.13.13 0 01-.2.12.5.5 0 01-.21-.35v-.07.08a.32.32 0 00.14.24c.08 0 .14 0 .14-.09V72a.12.12 0 00-.07-.07.06.06 0 00-.1 0 .3.3 0 00-.1-.14h-.1a.2.2 0 00-.11-.14.06.06 0 00-.1 0 .37.37 0 01.58-.25s.1 0 .12.01zM86.19 70.77l.01.11-1.02-.56v-.11l1.01.56zM86.19 70.56v.11l-1.01-.56-.01-.12.16.09.13.07.44.25.13.07.16.09z" />
                <path d="M85.95 69.42l.26.54-.19-.1.01.61-.13-.07-.01-.62-.18-.1.24-.26z" />
                <path d="M84.84 68.53l1.65.92v1.88l-1.66-.92zm1.6 2.66v-1.7l-1.49-.82v1.7l1.5.82" />
                <path d="M85.38 69.1l.26.54-.19-.1.01.61-.13-.07-.01-.61-.18-.1.24-.27zM86.83 69.64l1.66.91v1.88l-1.65-.91zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M118.37 70l.13 8.65-9.53 5.77-.13-8.66 9.53-5.76z"
                  />
                  <path
                    fill="#e2af98"
                    d="M114.66 72.25l.03 1.88-.66.4-.76.45-.03-1.88 1.42-.85z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M108.84 75.76l.13 8.66-11.49-6.36-.12-8.66 11.48 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M118.37 70l-9.53 5.76-11.48-6.36 9.53-5.77L118.37 70z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M114.66 72.25l-1.42.85-11.48-6.36 1.42-.85 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M101.18 77.89a1.5 1.5 0 010 .2.54.54 0 01-.28.55.32.32 0 00-.13.29l.13.08a.09.09 0 010 .07l-.35-.2a.09.09 0 010-.07l.13.08a.93.93 0 00-.15-.46 1.53 1.53 0 01-.28-1zM101.89 78l1.65.91v1.89l-1.65-.92zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M102.72 78.7a1.41 1.41 0 01.59.91.26.26 0 00-.1-.14h-.1a.26.26 0 00-.11-.14.07.07 0 00-.1 0 .26.26 0 00-.1-.14.06.06 0 00-.07 0V80c0 .12-.08.18-.2.11a.44.44 0 01-.2-.34v-.07a.09.09 0 010 .05v.07a.29.29 0 00.14.24c.08.05.14 0 .14-.08v-.64a.29.29 0 00-.07-.08h-.1c-.01 0-.06-.12-.11-.14a.06.06 0 00-.1 0 .28.28 0 00-.1-.15h-.1a.36.36 0 01.57-.26l.12-.01zM99.26 78.06v.11l-1.02-.56v-.11l1.02.56zM99.25 77.84l.01.11-1.02-.56v-.11l.15.09.14.07.44.25.13.07.15.08z" />
                  <path d="M99.02 76.7l.25.55-.18-.11.01.62-.13-.07-.01-.62-.18-.1.24-.27z" />
                  <path d="M97.9 75.82l1.65.91v1.89l-1.65-.92zm1.6 2.66v-1.7L98 76v1.7l1.49.83" />
                  <path d="M98.44 76.39l.26.54-.18-.1.01.61-.14-.07v-.62l-.19-.09.24-.27zM99.89 76.92l1.66.92v1.88l-1.66-.92zm1.61 2.67v-1.7l-1.5-.83v1.7l1.5.83" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M131.44 77.28l.12 8.66-9.53 5.76-.13-8.65 9.54-5.77z"
                  />
                  <path
                    fill="#e2af98"
                    d="M127.72 79.53l.03 1.89-.66.39-.76.46-.02-1.88 1.41-.86z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M121.9 83.05l.13 8.65-11.48-6.36-.13-8.65 11.48 6.36z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M131.44 77.28l-9.54 5.77-11.48-6.36 9.53-5.77 11.49 6.36z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M127.72 79.53l-1.41.86-11.48-6.36 1.41-.86 11.48 6.36z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M114.24 85.18a.75.75 0 010 .2.59.59 0 01-.28.55.3.3 0 00-.14.29l.14.08a.08.08 0 010 .07l-.36-.19a.12.12 0 010-.08l.13.07a.91.91 0 00-.14-.45 1.51 1.51 0 01-.29-1zM115 85.32l1.66.91v1.88L115 87.2zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M115.79 86a1.4 1.4 0 01.58.91.19.19 0 00-.1-.14.06.06 0 00-.1 0 .3.3 0 00-.1-.14.07.07 0 00-.1 0 .21.21 0 00-.11-.13.06.06 0 00-.07 0v.64c0 .13-.09.18-.2.12a.45.45 0 01-.21-.34v-.08.16a.3.3 0 00.15.24.09.09 0 00.14-.08v-.64a.19.19 0 00-.07-.08h-.1a.21.21 0 00-.11-.13.06.06 0 00-.1 0 .24.24 0 00-.1-.14.07.07 0 00-.1 0 .37.37 0 01.58-.26s.1.09.12.09zM112.32 85.35v.11l-1.01-.57-.01-.11 1.02.57zM112.32 85.13v.11l-1.02-.56v-.11l.16.09.13.07.44.24.13.08.16.08z" />
                  <path d="M112.08 83.99l.25.54-.18-.1.01.62-.13-.08-.01-.61-.18-.1.24-.27z" />
                  <path d="M111 83.11l1.66.91v1.88L111 85zm1.6 2.66v-1.7l-1.5-.83v1.7l1.49.83" />
                  <path d="M111.51 83.68l.25.54-.18-.11.01.62-.13-.07-.01-.62-.18-.1.24-.26zM113 84.21l1.65.92v1.88l-1.65-.92zm1.6 2.66v-1.7l-1.6-.82v1.7l1.5.82" />
                </g>
              </g>
            </g>
            <path
              fill="#333"
              d="M138.19 98.35l-.64-36.15-23.26 14.97.63 36.08 23.27-14.9z"
            />
            <g>
              <path
                d="M142.68 83.55c9.28-3 16.7 12.64 16.7 12.64l.49 27.58L139 136.3l-17.15-40.21z"
                fill="#8e000b"
              />
              <path
                d="M121.85 96.09l.55 31.07 16.6 9.14-.49-27.58S131.14 93 121.85 96.09z"
                fill="#c1272d"
              />
              <path
                d="M161.05 106.21h.07l.28 15.73a.71.71 0 01-.39.57 2.27 2.27 0 01-2 0 .74.74 0 01-.44-.57l-.28-15.75h.08a.8.8 0 01.31-.29 2.21 2.21 0 012 0 .87.87 0 01.37.31z"
                fill="#df8f92"
              />
              <path
                d="M158.7 106.81a2.27 2.27 0 002 0c.54-.33.51-.86-.06-1.17a2.24 2.24 0 00-2 0c-.53.36-.51.86.06 1.17z"
                fill="#e9b1b3"
              />
              <g fill="#bdbdbd">
                <path d="M160.1 95.38l.2 10.87a.31.31 0 01-.18.25 1 1 0 01-.9 0 .36.36 0 01-.2-.25l-.19-10.88a.32.32 0 01.13-.13 1 1 0 01.91 0 .41.41 0 01.23.14z" />
                <path d="M160.13 95.38a2.1 2.1 0 00-1.85-2.08c-.25-.12-.94.83-.11 1.18s.69.89.69.89v.81h1.28z" />
              </g>
              <path
                fill="#e9b1b3"
                d="M170.42 113.72l-.5.31.02 1.17.5-.25-.02-1.23z"
              />
              <path
                fill="#919191"
                d="M171.19 110.79l.13.07-.13.26.09 4.66-.62.9-.06.12-.13-.07-.09-4.99.81-.95z"
              />
              <path
                fill="#b5b5b5"
                d="M170.6 116.8l.81-.95-.09-4.99-.81.95.09 4.99z"
              />
              <path
                fill="#df8f92"
                d="M171.41 115.85l.65-1.93-1.46 2.88.81-.95z"
              />
              <path fill="#fff" d="M171.32 110.86l.74 3.06-1.55-2.11.81-.95z" />
              <path
                fill="#e6a6a9"
                d="M170.51 111.81l1.55 2.11-1.46 2.88-.09-4.99z"
              />
              <path
                d="M158.21 100.84c6.57-1.6 10.14 6.42 10.14 6.42l2.21 9 .28 15.95-14.67 8.79-12.63-31.4z"
                fill="#ed1c24"
              />
              <path
                d="M143.54 109.63l-.44 24.26 13.07 7.11-.28-16-2.21-9s-3.57-8-10.14-6.37z"
                fill="#8e000b"
              />
              <path d="M156.33 147.15l5.82-3.5-2.27-2.22-3.95 2.82.4 2.9z" />
              <path
                d="M122.84 98.36l.53-.32a.51.51 0 01.5 0 1.64 1.64 0 01.76 1.27.55.55 0 01-.2.49l-.55.32a.64.64 0 01-.3-.09 1.61 1.61 0 01-.75-1.27.7.7 0 01.01-.4z"
                fill="#fff"
              />
              <path
                d="M124.07 99.64a1.65 1.65 0 00-.75-1.27c-.41-.23-.73 0-.73.45a1.62 1.62 0 00.76 1.27c.4.23.73.03.72-.45z"
                fill="#a3a3a3"
              />
              <path
                d="M122.91 102l.54-.32a.54.54 0 01.5 0 1.67 1.67 0 01.75 1.28.51.51 0 01-.2.48l-.54.33a.78.78 0 01-.3-.09 1.67 1.67 0 01-.75-1.28.64.64 0 010-.4z"
                fill="#696969"
              />
              <path
                d="M124.14 103.24a1.65 1.65 0 00-.76-1.27c-.4-.22-.73 0-.72.46a1.67 1.67 0 00.75 1.27c.41.22.73.02.73-.46z"
                fill="#4d4d4d"
              />
              <path
                fill="#fff"
                d="M143.17 130.2l3.02 1.68-4.18 2.51-3.01-1.67 4.17-2.52z"
              />
              <path
                fill="#a3a3a3"
                d="M138.99 132.72l3.02 1.67.06 3.59-3.02-1.68-.06-3.58z"
              />
              <path
                fill="#919191"
                d="M142.07 137.98l-.06-3.59 4.18-2.51.07 3.66-4.19 2.44z"
              />
              <path
                fill="#fff"
                d="M146.2 132.17l12.29 6.8-2.74 1.65-12.29-6.8 2.74-1.65z"
              />
              <path
                fill="#a3a3a3"
                d="M143.44 133.82l12.3 6.81.02 1.65-12.29-6.81-.03-1.65z"
              />
              <path
                fill="#fff"
                d="M143.48 135.47l12.29 6.8-1.42.86-12.29-6.81 1.42-.85z"
              />
              <path
                fill="#a3a3a3"
                d="M142.04 136.32l12.3 6.81.02 1.61-12.29-6.76-.03-1.66zM144.36 116.08h.06l.29 15.73a.73.73 0 01-.4.57 2.24 2.24 0 01-2 0 .73.73 0 01-.44-.56l-.28-15.75h.08a1.09 1.09 0 01.31-.29 2.27 2.27 0 012 0 .93.93 0 01.38.3z"
              />
              <path
                d="M142 116.68a2.21 2.21 0 002 0c.54-.33.52-.86-.05-1.17a2.24 2.24 0 00-2 0c-.54.34-.51.86.05 1.17z"
                fill="#fff"
              />
              <g fill="#bdbdbd">
                <path d="M143.41 105.25l.19 10.87a.34.34 0 01-.17.26 1 1 0 01-.9 0 .32.32 0 01-.2-.25l-.19-10.88a.47.47 0 01.14-.14 1 1 0 01.9 0 .36.36 0 01.23.14z" />
                <path d="M143.44 105.25a2.1 2.1 0 00-1.85-2.08c-.26-.11-.94.83-.11 1.18s.69.9.69.9v.81h1.27z" />
              </g>
              <path
                d="M125.56 99.91l.06 3.44 9.29 5.11v-1.5s-4.17-8.76-9.35-7.05z"
                fill="#fff"
              />
              <path
                fill="#1d1d1b"
                d="M125.65 104.92l9.29 5.15.31 17.54-9.29-5.14-.31-17.55z"
                opacity={0.4}
              />
              <path
                fill="#fff"
                d="M126.28 106.06l8.05 4.45.12 6.83-8.04-4.46-.13-6.82zM168.43 109.27l-13.86 8.3 1.41 6.09 13.96-8.34-1.51-6.05z"
              />
              <path
                transform="rotate(-15.76 162.381 116.139)"
                fill="#ed1c24"
                d="M162.05 111.71h.75v8.88h-.75z"
              />
              <path
                fill="#fff"
                d="M152.93 117.6l1.41 6.09-9.21-5.07-.09-5.11 7.89 4.09z"
              />
              <g
                style={{
                  mixBlendMode: "overlay",
                }}
                opacity={0.4}
              >
                <path
                  fill="#1d1d1b"
                  d="M145.13 118.61l9.21 5.09.2 11.35-9.21-5.1-.2-11.34z"
                />
              </g>
              <g>
                <path
                  fill="#d15d62"
                  d="M146.05 123.74l1.64.9-.01-.54-1.64-.91.01.55z"
                />
                <path
                  fill="#e6a6a9"
                  d="M146.06 124.29l1.63.91v-.54l-1.64-.91.01.54z"
                />
              </g>
              <g>
                <path
                  d="M171.31 131.33l3.77-2.36a6 6 0 01.85-.53c1.56-.81 4.11-1.29 7.67.67 6.4 3.52 6.4 3.56 6.4 3.56l.11 6-5.34 3.21-16-8.76z"
                  fill="#ef3a40"
                />
                <path
                  d="M168.73 133.15s3.13-4.35 9.53-.83 6.4 3.55 6.4 3.55l.11 6z"
                  fill="#4d4d4d"
                />
                <path
                  fill="#828282"
                  d="M190 132.66l-5.34 3.21.11 6.04 5.34-3.21-.11-6.04z"
                />
                <g>
                  <path
                    fill="#fff"
                    d="M189.65 136.98l-.06-3.57-4.46 2.68.06 3.57 4.46-2.68z"
                  />
                  <path
                    d="M189.22 134.07l.05 2.7-3.71 2.23-.05-2.7 3.71-2.23zm.73-1.32l-1.12.67-3.71 2.23-.37.23v.43l.05 2.7v1.31l1.12-.68 3.72-2.23.37-.22v-4.44z"
                    fill="#c1272d"
                  />
                  <g fill="#c1272d">
                    <path d="M189.68 138.57l-.03-1.81-4.46 2.69.03 1.8 4.46-2.68z" />
                    <path d="M189.28 137.42v.93l-3.72 2.24v-.93l3.71-2.24zm.73-1.31l-1.12.67-3.71 2.22-.37.23v2.67l1.12-.68 3.72-2.23.37-.22v-2.67z" />
                  </g>
                </g>
              </g>
              <g>
                <path d="M171.5 133.87l-2.79-1.54z" fill="#696969" />
                <path
                  fill="#8e000b"
                  d="M156.92 125.83l.69.38 11.1 6.12 2.79 1.54.25 14.24-14.58-7.96-.25-14.32z"
                />
                <path
                  fill="#ef3a40"
                  d="M176.97 128.42l-12.18-6.61 3.68-2.98 14.59 8.06-6.09 1.53z"
                />
                <path
                  fill="#d1d1d1"
                  d="M171.75 148.37v-.26l1.46.8-1.46-.54zM173.21 148.91l11.55-7zM183.06 126.89l1.45.8-1.45-.8z"
                />
                <path d="M173 134.68l-1.46-.81z" fill="#696969" />
                <path d="M173 134.68l-1.46-.81z" fill="#d1d1d1" />
                <path
                  fill="#8c8c8c"
                  d="M171.5 133.87l1.46.81.25 14.23-1.46-.8-.25-14.24z"
                />
                <path
                  fill="#ccc"
                  d="M176.97 128.42l6.09-1.53 1.45.8-5.81 1.66-1.73-.93z"
                />
                <path
                  fill="#a3a3a3"
                  d="M172.96 134.68l5.74-5.33 5.81-1.66.25 14.23-11.55 6.99-.25-14.23z"
                />
                <path
                  d="M164.79 121.81l-7.87 4z"
                  fill="#1d1d1b"
                  opacity={0.1}
                />
                <path
                  fill="#8a8a8a"
                  d="M176.97 128.42l-.16.04-5.31 5.41-2.79-1.54-11.79-6.5 7.87-4.02 12.18 6.61z"
                />
                <path
                  fill="#8e000b"
                  d="M176.97 128.42l-.16.04-5.31 5.41-2.79-1.54-11.79-6.5 7.87-4.02 12.18 6.61z"
                />
                <path
                  fill="#b5b5b5"
                  d="M171.5 133.87l5.31-5.41.16-.04 1.73.93-5.74 5.33-1.46-.81z"
                />
                <g>
                  <path d="M179.29 134.16l4.34-2.61-.01-.6-4.34 2.61.01.6zM179.32 135.7l4.33-2.6-.01-.6-4.33 2.6.01.6zM179.35 137.25l4.33-2.61-.01-.6-4.33 2.61.01.6zM179.38 138.79l4.33-2.6-.01-.6-4.33 2.6.01.6zM179.4 140.34l4.34-2.61-.02-.6-4.33 2.6.01.61zM179.43 141.88l4.33-2.6-.01-.6-4.33 2.6.01.6zM179.46 143.42l4.33-2.6-.01-.6-4.33 2.6.01.6z" />
                  <g>
                    <path d="M174.2 137.22l4.33-2.6-.01-.6-4.33 2.6.01.6zM174.23 138.76l4.33-2.6-.01-.6-4.33 2.6.01.6zM174.25 140.31l4.34-2.6-.02-.61-4.33 2.61.01.6zM174.28 141.85l4.33-2.6-.01-.6-4.33 2.6.01.6zM174.31 143.4l4.33-2.61-.01-.6-4.33 2.61.01.6zM174.34 144.94l4.33-2.6-.01-.6-4.33 2.6.01.6zM174.36 146.49l4.33-2.61v-.6l-4.34 2.61.01.6z" />
                  </g>
                </g>
                <path d="M172.99 136.02l11.58-6.97-.08-.12-11.51 6.91-16-8.88-.07.13 16.08 8.93z" />
              </g>
              <g>
                <path d="M157.88 145.21l-.13-.24 3.09-1.85a3 3 0 012.9.25 9.55 9.55 0 014.38 7.4 3.12 3.12 0 01-1.16 2.81l-.1.06-3.17 1.89-.2-.29a3.93 3.93 0 01-1.73-.53 9.53 9.53 0 01-4.38-7.39 3.84 3.84 0 01.5-2.11z" />
                <path
                  d="M165 152.63a9.53 9.53 0 00-4.38-7.39c-2.36-1.31-4.25-.12-4.21 2.63a9.52 9.52 0 004.38 7.39c2.39 1.31 4.28.13 4.21-2.63z"
                  fill="#212121"
                />
                <ellipse
                  cx={160.73}
                  cy={150.25}
                  rx={2.05}
                  ry={3.62}
                  transform="rotate(-28.26 160.691 150.237)"
                  fill="#df8f92"
                />
                <path d="M162.54 150.47a2.14 2.14 0 00-.93-1.64c-.51-.29-.91 0-.9.58a2.12 2.12 0 00.94 1.64c.5.29.9.03.89-.58z" />
                <path
                  d="M165 152.63a9.53 9.53 0 00-4.38-7.39c-2.36-1.31-4.25-.12-4.21 2.63a9.52 9.52 0 004.38 7.39c2.39 1.31 4.28.13 4.21-2.63z"
                  fill="#212121"
                />
                <ellipse
                  cx={160.73}
                  cy={150.25}
                  rx={2.05}
                  ry={3.62}
                  transform="rotate(-28.26 160.691 150.237)"
                  fill="#df8f92"
                />
                <path d="M162.54 150.47a2.14 2.14 0 00-.93-1.64c-.51-.29-.91 0-.9.58a2.12 2.12 0 00.94 1.64c.5.29.9.03.89-.58z" />
              </g>
              <g>
                <path
                  d="M161.31 143.51c-2.57-1.42-4.67-.39-5 2.29l-4.47-2.44 2.58-1.82 3.76-2.36a6.58 6.58 0 01.85-.53c1.56-.81 4.11-1.29 7.68.67 6.4 3.52 6.4 3.55 6.4 3.55l.1 6-5.33 3.21-1.4-.76a11.54 11.54 0 00-5.17-7.81z"
                  fill="#ef3a40"
                />
                <path
                  fill="#828282"
                  d="M173.11 142.88l-5.34 3.2.11 6.04 5.33-3.21-.1-6.03z"
                />
                <path
                  fill="#fff"
                  d="M172.76 147.19l-.07-3.57-4.46 2.68.07 3.57 4.46-2.68z"
                />
                <path
                  d="M172.33 144.28v2.7l-3.72 2.23v-2.7l3.72-2.23zm.73-1.32l-1.12.67-3.71 2.23-.38.23v4.44l1.12-.68 3.72-2.23.37-.22V143z"
                  fill="#c1272d"
                />
                <g fill="#c1272d">
                  <path d="M172.79 148.78l-.04-1.81-4.46 2.69.03 1.8 4.47-2.68z" />
                  <path d="M172.39 147.63v.93l-3.71 2.24v-.93l3.72-2.24zm.73-1.31L172 147l-3.71 2.23-.38.23v2.67l1.12-.68 3.71-2.23.38-.22v-2.67z" />
                </g>
              </g>
              <g>
                <path
                  fill="#a6a6a6"
                  d="M154.94 122.95l-.5.31.02 1.17.5-.25-.02-1.23z"
                />
                <path
                  fill="#919191"
                  d="M154.33 121.01l.13.07-.12.26.08 4.66-2.72.68-.05.12-.14-.08-.09-4.99 2.91-.72z"
                />
                <path
                  fill="#b5b5b5"
                  d="M151.65 126.8l2.9-.73-.09-4.99-2.9.73.09 4.99z"
                />
                <path
                  fill="#a6a6a6"
                  d="M154.55 126.07l-1.44-2.15-1.46 2.88 2.9-.73z"
                />
                <path
                  fill="#ccc"
                  d="M154.46 121.08l-1.35 2.84-1.55-2.11 2.9-.73z"
                />
              </g>
              <path
                d="M161.31 143.51c-2.57-1.42-4.67-.39-5 2.29l-4.47-2.44s3.13-4.35 9.53-.83 6.4 3.55 6.4 3.55l.11 6-1.4-.76a11.54 11.54 0 00-5.17-7.81z"
                fill="#42210b"
              />
              <g>
                <path
                  fill="#8f8f8f"
                  d="M167.94 156.72l23.44-14.13-.07-4-23.44 14.12.07 4.01z"
                />
                <path
                  fill="#a6a6a6"
                  d="M191.31 138.59l-1.22-.62.02.73 1.2-.11z"
                />
                <path
                  fill="#ccc"
                  d="M191.31 138.59l-1.2.11-22.23 13.42-.01.59 23.44-14.12z"
                />
                <path
                  fill="#7a7a7a"
                  d="M166.02 155.66l1.92 1.06-.07-4.01-1.92-1.06.07 4.01z"
                />
                <path
                  fill="#999"
                  d="M165.95 151.65l.53-.3 1.4.77-.01.59-1.92-1.06z"
                />
              </g>
              <g>
                <path
                  fill="#c1272d"
                  d="M163.03 133.26l-4.56-2.52.03 1.8 4.56 2.52-.03-1.8z"
                />
                <path
                  d="M158.86 131.39l3.79 2.1v.93l-3.79-2.1v-.93zm-.77-1.3v2.68l.38.21 3.79 2.1 1.15.63V133l-.38-.21-3.79-2.1-1.14-.64z"
                  fill="#212121"
                />
              </g>
              <path d="M122.05 128.16l34.28 18.99-.02-1.35-34.28-18.99.02 1.35z" />
              <path
                fill="#c1272d"
                d="M122.03 126.81l.36-.3v.21l33.95 18.85-.03.23-34.28-18.99z"
              />
              <g>
                <path d="M188.46 143.16l1.56-.93-.03-1.57-1.55.93.02 1.57zM183.58 146.1l3.52-2.11-.03-1.58-3.51 2.12.02 1.57zM171.17 151.97l-1.56.94.03 1.57 1.56-.94-.03-1.57zM176.05 149.03l-3.51 2.12.02 1.57 3.52-2.11-.03-1.58z" />
              </g>
              <g
                style={{
                  mixBlendMode: "overlay",
                }}
                opacity={0.5}
              >
                <path
                  d="M141.61 87.1c3.54-.06 6.43 4.34 7.54 6.32a1.1 1.1 0 01-.39 1.49l-6.12 3.66a1.2 1.2 0 01-.54.16 1.13 1.13 0 01-1-.56c-.93-1.63-3-4.81-5.63-5.9a1.1 1.1 0 01-.15-2l4.88-2.92a.85.85 0 01.26-.11 4.51 4.51 0 011.13-.18zm0-.52a4.65 4.65 0 00-1.27.19 1.84 1.84 0 00-.38.17l-4.88 2.92a1.62 1.62 0 00.22 2.9c2.54 1.06 4.6 4.32 5.37 5.67a1.62 1.62 0 001.44.82 1.53 1.53 0 00.81-.23l6.08-3.66a1.61 1.61 0 00.58-2.2 20 20 0 00-2.94-4c-1.68-1.73-3.38-2.58-5.07-2.55z"
                  fill="#1d1d1b"
                />
              </g>
              <path
                transform="rotate(-61.09 130.677 128.13)"
                d="M130.59 118.62h.15v19.01h-.15z"
              />
              <g
                style={{
                  mixBlendMode: "overlay",
                }}
                opacity={0.3}
              >
                <path d="M171.5 133.87l.02 1.25-14.58-8.1-.02-1.19 14.58 8.04z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;

