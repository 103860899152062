import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={"100%"}
      viewBox="0 0 1920 4598.69"
      {...props}
    >
      <defs>
        <linearGradient
          id="backgroundlayer__linear-gradient"
          x1={51.39}
          y1={44.94}
          x2={17.93}
          y2={8.55}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#232323" stopOpacity={0} />
          <stop offset={0.96} />
        </linearGradient>
      </defs>
      <g id="backgroundlayer__Layer_2" data-name="Layer 2">
        <g id="backgroundlayer__Layer_1-2" data-name="Layer 1">
          <path
            d="M978.88 1860.21l-2.16 1.25a24.06 24.06 0 01-2.49 1.24 1.26 1.26 0 01-.88-1.21v-1.2a19.42 19.42 0 002.09-1l.47-.27v1.24zM842 2317.2h-43.42a1.28 1.28 0 00-1.28 1.28v24.42a1.28 1.28 0 001.28 1.28h42.3a1.23 1.23 0 001.24-1 .57.57 0 000-.18.28.28 0 000-.09v-23.69zm-2.4 24.42h-39.75v-21.87h39.76z"
            fill="#999"
          />
          <path
            d="M797.29 2342.9v120.7h44.86v-120.7a1.29 1.29 0 01-1.28 1.31h-42.3a1.29 1.29 0 01-1.28-1.31z"
            fill="#999"
          />
          <g fill="#999">
            <path d="M1103 3603.87v149.21a1.27 1.27 0 01-1.27 1.27h-42.31a1.27 1.27 0 01-1.28-1.27v-149.21a1.25 1.25 0 01.38-.89 1.23 1.23 0 01.9-.38h42.31a1.28 1.28 0 011.27 1.27z" />
            <path d="M1101.74 3578.19h-42.31a1.25 1.25 0 00-.9.36 1.27 1.27 0 00-.38.9v24.44a1.27 1.27 0 001.28 1.26h42.31a1.27 1.27 0 001.27-1.26v-24.44a1.26 1.26 0 00-1.27-1.26zm-1.28 24.41h-39.75v-21.87h39.75z" />
          </g>
          <path
            d="M393.26 4488.46v110.23H348.4v-110.23a1.29 1.29 0 001.28 1.31H392a1.25 1.25 0 00.92-.4 1.2 1.2 0 00.26-.4 1.09 1.09 0 00.07-.24 1.24 1.24 0 00.01-.27zM1420.92 1305.08h-42.3a1.26 1.26 0 00-1.28 1.28v24.42a1.19 1.19 0 00.09.47l.1.2a1.2 1.2 0 001.09.61h42.3a1.3 1.3 0 001-.46l.1-.15.1-.2a1.19 1.19 0 00.09-.47v-24.42a1.28 1.28 0 00-1.29-1.28zm-1.27 24.42h-39.75v-21.87h39.76z"
            fill="#999"
          />
          <path
            d="M1422.2 1330.78v120.7h-44.86v-120.7a1.29 1.29 0 001.28 1.31h42.3a1.29 1.29 0 001.28-1.31zM1016.94 1735.24h-42.31a1.28 1.28 0 00-.9.37 1.26 1.26 0 00-.37.9v24.43a1.27 1.27 0 001.28 1.27h42.3a1.27 1.27 0 001.28-1.27v-24.43a1.27 1.27 0 00-1.28-1.27zm-41 24.42v-21.87h39.75v21.87z"
            fill="#999"
          />
          <path
            d="M1018.21 1760.93v100.56a1.27 1.27 0 01-1.27 1.28h-42.31a1.05 1.05 0 01-.4-.07 24.06 24.06 0 002.49-1.24l2.16-1.25h-3V1859l-.47.27a19.42 19.42 0 01-2.09 1v-99.36a1.29 1.29 0 01.38-.9 1.26 1.26 0 01.9-.37h42.31a1.27 1.27 0 011.3 1.29z"
            fill="#999"
          />
          <path
            fill="url(#backgroundlayer__linear-gradient)"
            d="M98.28 0L0 84.98V0h98.28z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;

