import React from "react";
import ContactCard from "./ContactCard";
import ContactBackground from "./ContactBackground";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { Alert } from 'reactstrap';

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }
  get initialState() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      alertVisible: false,
      alertContent:"",
      alertColor: "danger",
      isAlert: false,
      errors: {
        name: "",
        email: "",
        message: ""
      },
      requiredFieldsFilled: {
        name: false,
        email: false,
        message: false
      },
      isRequiredFieldChanged: false,
      isFormSubmitted: false
    }
  }
  handleErrors = (event) => {
    
  }
  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    this.setState({isRequiredFieldChanged: true});
    switch (name) {
      case 'name': 
        errors.name = 
          value.length < 5
            ? 'Name must be at least 5 characters long!'
            : '';
        this.state.requiredFieldsFilled.name = true;
        break;
      case 'email': 
        errors.email = 
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
        this.state.requiredFieldsFilled.email = true;
        break;
      case 'message': 
        errors.message = 
          (value.length < 50 || value.length > 500)
            ? 'Message should be 50-500 characters only.'
            : '';
        this.state.requiredFieldsFilled.message = true;
        break;
      default:
        break;
    }
    this.setState({errors, [name]: value});
  }
  handleSubmit = (event) => {
    event.preventDefault();
    if( this.validateForm(this.state.errors, this.state.requiredFieldsFilled)) {
      this.submitForm()
    }else{
      this.handleErrors(event);
    }
  }
  validateForm = (errors,requiredFieldsFilled) => {
    let inValidKeyCount = 0;
    Object.keys(requiredFieldsFilled).map(key => {
      
      let errors = this.state.errors;
      var err = "";
      if(key=='name'){
        if(requiredFieldsFilled[key]==false || this.state.name.length < 5){
          ++inValidKeyCount;
          errors.name = 'Name must be at least 5 characters long!'
        }
      }
      else if(key=='email'){
        if(requiredFieldsFilled[key]==false || !validEmailRegex.test(this.state.email)){
          ++inValidKeyCount;
          errors.email = 'Email is not valid!'
        }
      }
      else if(key=='message'){
        if(requiredFieldsFilled[key]==false || (this.state.message.length < 50 || this.state.message.length > 500)){
          ++inValidKeyCount;
          errors.message = 'Message should be 50-500 characters only.'
        }
      }
      this.setState({errors});
    });
    if(inValidKeyCount == 0) {
      Object.values(errors).forEach(val => (val.length>0)?(++inValidKeyCount):inValidKeyCount);
    }
    return ((inValidKeyCount > 0)? false : true);
  };
  submitForm=()=>{
    this.setState({ isFormSubmitted: true})
    let submitInfo = {
      NAME: this.state.name,
      EMAIL: this.state.email,
      PHONE: this.state.phone,
      MESSAGE: this.state.message
    }
    fetch('api/contact_us.php' ,{
      method:'POST',
      headers: {'content-type':'application/json'},
      body:JSON.stringify(submitInfo)
    }).then(r=>r.json()).then(res=>{
      this.onShowAlert()
      
      if (res.STATUS){
        this.setState({
          alertColor: "success",
          alertContent: res.MESSAGE,
          isAlert: true
        })
        setTimeout(
          () => {
            this.setState(this.initialState);
          }, 
          1200
        );
      }
      else {
        this.setState({
          alertColor: "danger",
          alertContent: res.MESSAGE,
          isAlert: true
        })
      }
    })
  }
  onShowAlert = ()=>{
    this.setState({alertVisible:true},()=>{
      window.setTimeout(()=>{
        this.setState({alertVisible:false})
      },2000)
    });
  }
  render() {
    return (
      <motion.div className="overflow-hidden" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
        {this.state.isAlert && (
          <Alert color={this.state.alertColor} isOpen={this.state.alertVisible}>
          <strong> {this.state.alertContent}</strong> 
        </Alert>
        )}
        <ContactBackground  />
        <header>
          <NavBar color="black" hamcolor="black" className=" " />
        </header>

        <div
          className="w-full absolute top-0 flex z-10"
          style={{ marginTop: "25vh" }}
        >
          <div className="w-11/12 mx-auto flex">
            <div className="w-1/2">
              <div className="w-3/4 mx-auto">
                <form onSubmit={this.handleSubmit} noValidate>
                  <h1 className="text-2vw uppercase text-white font-bold">
                    contact us
                  </h1>
                  <p className="text-1.4vw text-white font-medium  uppercase">
                    Feel free to contat us anytime. we will get back to you as
                    soon as we can!
                  </p>
                  <label className="text-white flex flex-col w-full text-1.1vw mt-6 position-relative">
                    <input
                      placeholder="Name *"
                      type="text"
                      name="name"
                      value={this.state.name}
                      className="bg-transparent w-full placeholder-white border-b border-custom-lightgray pb-1 text-0.9vw mt-2 focus:outline-none"
                      onChange={this.changeHandler,this.handleChange}
                    ></input>
                    {this.state.errors.name.length > 0 && 
                <span className='error animate__animated animate__shakeX animate__slow'>{this.state.errors.name}</span>}
                  </label>
                  <label className="text-white flex flex-col w-full text-1.1vw mt-6 position-relative">
                    <input
                      placeholder="Phone Number"
                      type="text"
                      name="phone"
                      value={this.state.phone}
                      className="bg-transparent w-full placeholder-white border-b border-custom-lightgray pb-1 text-0.9vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-white flex flex-col w-full text-1.1vw mt-6 position-relative">
                    <input
                      placeholder="E-Mail *"
                      type="email"
                      name="email"
                      value={this.state.email}
                      className="bg-transparent w-full placeholder-white border-b border-custom-lightgray pb-1 text-0.9vw mt-2 focus:outline-none"
                      onChange={this.changeHandler,this.handleChange}
                    ></input>
                    {this.state.errors.email.length > 0 && 
                <span className='error animate__animated animate__shakeX animate__slow'>{this.state.errors.email}</span>}
                  </label>
                  <label className="text-white flex flex-col w-full text-0.9vw mt-6 position-relative textarea-sec">
                      Message *
                    <textarea
                      type="text"
                      name="message"
                      value={this.state.message}
                      rows="8"
                      className="bg-transparent w-full placeholder-white border border-custom-lightgray pb-1 text-0.9vw focus:outline-none"
                      onChange={this.changeHandler,this.handleChange}
                    ></textarea>
                    {this.state.errors.message.length > 0 && 
                <span className='error animate__animated animate__shakeX animate__slow'>{this.state.errors.message}</span>}
                  </label>

                  <button
                    className="w-full bg-custom-red mt-10 relative focus:outline-none hover:bg-custom-redlight"
                    style={{ paddingBottom: "8%", borderRadius: "1vw" }}
                    onClick={this.onSubmit}
                  >
                    <span
                      className="text-white uppercase absolute text-0.9vw"
                      style={{
                        top: "50%",
                        left: "50%",
                        letterSpacing:"3px",
                        transform: "translateX(-50%) translateY(-50%)",
                      }}
                    >
                      send message
                    </span>
                  </button>
                </form>
              </div>
            </div>
            <div className="w-1/2">
              <div className="w-4/5 mx-auto">
                <ContactCard />
              </div>
            </div>
          </div>
        </div>
        <Footer  />
      </motion.div>
    );
  }
}

export default Contact;
