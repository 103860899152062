import React, {useEffect} from "react";
import {motion,useAnimation } from "framer-motion"
function SvgComponent(props) {
  const truckVariant = {
    start :{
      x:0,
      y:0,
      opacity:0,
    },
    showTruck:{
      x:0,
      y:0,
      opacity:1
    },
    drive:{
      x:450,
      y:260,
      opacity: 1,
      transition : {
        duration:5,
      }
    },
    hideAfterDrive : {
      x:450,
      y:260,
      opacity: 0,
      transition : {
        duration:0.1,
      }
    },
    moveToStart : {
      x:0,
      y:0,
      opacity:0
    }
  }
  const trainVariant = {
    start :{
      x:-807,
      y:-470,
      opacity:0
    },

    justAfterStart :{
      x:-807,
      y:-470,
      opacity:1,
      transition:{
        duration:0.1
      }
    }
,
    middle:{
       x:0,
       y:0,
       opacity:1,
       transition:{
         duration:5,
  
       } 
    },
    end:{
      x:1030,
      y:603,
      opacity:1,
      transition:{
        duration:5,

      }
    },
    justAfterEnd:{
      x:1030,
      y:603,
      opacity:0,
      transition:{
        duration:0.1,

      }
    },
    moveToStart:{
      x:-807,
      y:-470,
      opacity:0,
      transition:{
        duration:0.1
      }
    }
  }

  const containerVariant = {
    start : {
      x:0,y:-30,opacity:1
    },

    placeOnTrain :{
      x:0,y:34,opacity:1,
      transition:{
        duration: 2,
      }
    },
    travelWithTrain :{
      x:1030,y:637,opacity:1,
      transition:{
        duration: 5,
      }
    },
    justAfterEnd:{
      x:1030,y:637,opacity:0,
      transition:{
        duration:0.1
      }
    },
    moveToStart:{
      x:0,y:-30,opacity:0,
      transition:{
        duration:0.1
      }
    },
    justAfterStart:{
      x:0,y:-30,opacity:1,
      transition:{
        duration:0.1
      }
    }
  }
  

  const trainControls = useAnimation();
  const containerControls = useAnimation();
  const truckControls = useAnimation();
  useEffect (()=>{
      
    const sequence = async ()=>{
      await Promise.all([trainControls.start(trainVariant.justAfterStart),containerControls.start(containerVariant.justAfterStart),truckControls.start(truckVariant.showTruck)])
     await Promise.all([trainControls.start(trainVariant.middle),truckControls.start(truckVariant.drive)])
     await containerControls.start(containerVariant.placeOnTrain)
     await Promise.all([trainControls.start(trainVariant.end),containerControls.start(containerVariant.travelWithTrain),truckControls.start(truckVariant.drive)])
     await Promise.all([trainControls.start(trainVariant.justAfterEnd),containerControls.start(containerVariant.justAfterEnd),truckControls.start(truckVariant.hideAfterDrive)])
     await Promise.all([trainControls.start(trainVariant.moveToStart),containerControls.start(containerVariant.moveToStart),truckControls.start(truckVariant.moveToStart)])
     
     await sequence();
     
    }
    sequence();
  })
  return (
    <svg
    width={"100%"}
    viewBox="0 0 1152.71 950"
      {...props}
    >
      <defs>
        <linearGradient
          id="prefix__linear-gradient"
          x1={9353.84}
          y1={183.27}
          x2={10072.11}
          y2={544.27}
          gradientTransform="translate(-8990.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.03} stopColor="#383838" />
          <stop offset={0.45} stopColor="#2d2d2d" />
          <stop offset={0.58} stopColor="#363636" />
          <stop offset={0.78} stopColor="#4c4c4c" />
          <stop offset={0.92} stopColor="#383838" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_5"
          data-name="New Gradient Swatch copy 5"
          x1={9353.38}
          y1={164.44}
          x2={10095.9}
          y2={550.63}
          gradientTransform="translate(-8990.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#4d4d4d" />
          <stop offset={0.33} stopColor="#5c5c5c" />
          <stop offset={0.89} stopColor="#858585" />
          <stop offset={1} stopColor="#8d8d8d" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-2"
          x1={9699.05}
          y1={415.5}
          x2={9721.02}
          y2={376.32}
          gradientTransform="translate(-8990.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#bebebe" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17"
          data-name="New Gradient Swatch copy 17"
          x1={9352.12}
          y1={308.99}
          x2={10142.4}
          y2={308.99}
          gradientTransform="translate(-8990.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#8d8d8d" />
          <stop offset={0.15} stopColor="#949494" />
          <stop offset={0.35} stopColor="#a6a6a6" />
          <stop offset={0.58} stopColor="#c2c2c2" />
          <stop offset={0.8} stopColor="#d2d2d2" />
          <stop offset={1} stopColor="#e4e4e4" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-2"
          x1={9388.61}
          y1={125.32}
          x2={9736.85}
          y2={21.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-3"
          x1={9338.3}
          y1={158.64}
          x2={9377.39}
          y2={149.88}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-4"
          x1={9586.65}
          y1={-193.83}
          x2={9416.1}
          y2={131.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23"
          data-name="New Gradient Swatch copy 23"
          x1={9518.47}
          y1={77.11}
          x2={9524.41}
          y2={77.11}
          gradientTransform="translate(-8990.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} stopColor="#c1a1d5" />
          <stop offset={0.05} stopColor="#c8abda" />
          <stop offset={0.11} stopColor="#dac6e6" />
          <stop offset={0.2} stopColor="#f7f2f9" />
          <stop offset={0.22} stopColor="#fff" />
          <stop offset={0.25} stopColor="#f9f5fb" />
          <stop offset={0.3} stopColor="#e9dbf2" />
          <stop offset={0.36} stopColor="#d1b4e3" />
          <stop offset={0.43} stopColor="#b395d6" />
          <stop offset={0.49} stopColor="#9c7dcb" />
          <stop offset={0.56} stopColor="#8e6ec5" />
          <stop offset={0.61} stopColor="#8969c3" />
          <stop offset={0.76} stopColor="#9471c6" />
          <stop offset={1} stopColor="#b086cc" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35"
          data-name="New Gradient Swatch copy 35"
          x1={9519.07}
          y1={62.57}
          x2={9523.85}
          y2={65.33}
          gradientTransform="translate(-8990.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#9f79c8" />
          <stop offset={0.14} stopColor="#a580cb" />
          <stop offset={0.33} stopColor="#b492d3" />
          <stop offset={0.55} stopColor="#cdb0e1" />
          <stop offset={0.58} stopColor="#d1b4e3" />
          <stop offset={0.76} stopColor="#dbc4e9" />
          <stop offset={1} stopColor="#ebdef3" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-2"
          x1={9526.12}
          y1={89.81}
          x2={9532.06}
          y2={89.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-2"
          x1={9526.72}
          y1={83.09}
          x2={9531.5}
          y2={85.85}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-3"
          x1={9522.81}
          y1={79.61}
          x2={9528.75}
          y2={79.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-3"
          x1={9523.41}
          y1={65.07}
          x2={9528.19}
          y2={67.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-4"
          x1={9530.46}
          y1={92.31}
          x2={9536.4}
          y2={92.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-4"
          x1={9531.06}
          y1={85.59}
          x2={9535.84}
          y2={88.34}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-5"
          x1={9527.15}
          y1={82.11}
          x2={9533.09}
          y2={82.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-5"
          x1={9527.75}
          y1={67.57}
          x2={9532.53}
          y2={70.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-6"
          x1={9534.81}
          y1={94.81}
          x2={9540.74}
          y2={94.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-6"
          x1={9535.41}
          y1={88.09}
          x2={9540.18}
          y2={90.84}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-7"
          x1={9531.49}
          y1={84.6}
          x2={9537.43}
          y2={84.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-7"
          x1={9532.09}
          y1={70.07}
          x2={9536.87}
          y2={72.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-8"
          x1={9539.15}
          y1={97.31}
          x2={9545.09}
          y2={97.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-8"
          x1={9539.75}
          y1={90.58}
          x2={9544.52}
          y2={93.34}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-9"
          x1={9535.84}
          y1={87.1}
          x2={9541.77}
          y2={87.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-9"
          x1={9536.44}
          y1={72.57}
          x2={9541.21}
          y2={75.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-10"
          x1={9543.49}
          y1={99.8}
          x2={9549.43}
          y2={99.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-10"
          x1={9544.09}
          y1={93.08}
          x2={9548.86}
          y2={95.84}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-11"
          x1={9540.18}
          y1={89.6}
          x2={9546.12}
          y2={89.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-11"
          x1={9540.78}
          y1={75.06}
          x2={9545.55}
          y2={77.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-12"
          x1={9547.83}
          y1={102.3}
          x2={9553.77}
          y2={102.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-12"
          x1={9548.43}
          y1={95.58}
          x2={9553.21}
          y2={98.34}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-13"
          x1={9544.52}
          y1={92.1}
          x2={9550.46}
          y2={92.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-13"
          x1={9545.12}
          y1={77.56}
          x2={9549.9}
          y2={80.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-14"
          x1={9552.17}
          y1={104.8}
          x2={9558.11}
          y2={104.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-14"
          x1={9552.77}
          y1={98.08}
          x2={9557.55}
          y2={100.84}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-15"
          x1={9548.86}
          y1={94.6}
          x2={9554.8}
          y2={94.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-15"
          x1={9549.46}
          y1={80.06}
          x2={9554.24}
          y2={82.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-16"
          x1={9556.51}
          y1={107.3}
          x2={9562.45}
          y2={107.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-16"
          x1={9557.11}
          y1={100.58}
          x2={9561.89}
          y2={103.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-17"
          x1={9553.2}
          y1={97.1}
          x2={9559.14}
          y2={97.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-17"
          x1={9553.8}
          y1={82.56}
          x2={9558.58}
          y2={85.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-18"
          x1={9560.86}
          y1={109.8}
          x2={9566.79}
          y2={109.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-18"
          x1={9561.46}
          y1={103.08}
          x2={9566.23}
          y2={105.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-19"
          x1={9557.54}
          y1={99.59}
          x2={9563.48}
          y2={99.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-19"
          x1={9558.14}
          y1={85.06}
          x2={9562.92}
          y2={87.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-20"
          x1={9565.2}
          y1={112.3}
          x2={9571.14}
          y2={112.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-20"
          x1={9565.8}
          y1={105.57}
          x2={9570.57}
          y2={108.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-21"
          x1={9561.89}
          y1={102.09}
          x2={9567.82}
          y2={102.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-21"
          x1={9562.49}
          y1={87.56}
          x2={9567.26}
          y2={90.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-22"
          x1={9569.54}
          y1={114.79}
          x2={9575.48}
          y2={114.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-22"
          x1={9570.14}
          y1={108.07}
          x2={9574.91}
          y2={110.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-23"
          x1={9566.23}
          y1={104.59}
          x2={9572.17}
          y2={104.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-23"
          x1={9566.83}
          y1={90.05}
          x2={9571.6}
          y2={92.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-24"
          x1={9573.88}
          y1={117.29}
          x2={9579.82}
          y2={117.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-24"
          x1={9574.48}
          y1={110.57}
          x2={9579.26}
          y2={113.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-25"
          x1={9570.57}
          y1={107.09}
          x2={9576.51}
          y2={107.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-25"
          x1={9571.17}
          y1={92.55}
          x2={9575.94}
          y2={95.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-26"
          x1={9578.22}
          y1={119.79}
          x2={9584.16}
          y2={119.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-26"
          x1={9578.82}
          y1={113.07}
          x2={9583.6}
          y2={115.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-27"
          x1={9574.91}
          y1={109.59}
          x2={9580.85}
          y2={109.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-27"
          x1={9575.51}
          y1={95.05}
          x2={9580.29}
          y2={97.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-28"
          x1={9582.56}
          y1={122.29}
          x2={9588.5}
          y2={122.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-28"
          x1={9583.16}
          y1={115.57}
          x2={9587.94}
          y2={118.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-29"
          x1={9579.25}
          y1={112.09}
          x2={9585.19}
          y2={112.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-29"
          x1={9579.85}
          y1={97.55}
          x2={9584.63}
          y2={100.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-30"
          x1={9586.9}
          y1={124.79}
          x2={9592.84}
          y2={124.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-30"
          x1={9587.51}
          y1={118.07}
          x2={9592.28}
          y2={120.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-31"
          x1={9583.59}
          y1={114.58}
          x2={9589.53}
          y2={114.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-31"
          x1={9584.19}
          y1={100.05}
          x2={9588.97}
          y2={102.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-32"
          x1={9591.25}
          y1={127.29}
          x2={9597.18}
          y2={127.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-32"
          x1={9591.85}
          y1={120.56}
          x2={9596.62}
          y2={123.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-33"
          x1={9587.93}
          y1={117.08}
          x2={9593.87}
          y2={117.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-33"
          x1={9588.54}
          y1={102.55}
          x2={9593.31}
          y2={105.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-34"
          x1={9595.59}
          y1={129.78}
          x2={9601.53}
          y2={129.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-34"
          x1={9596.19}
          y1={123.06}
          x2={9600.96}
          y2={125.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-35"
          x1={9592.28}
          y1={119.58}
          x2={9598.22}
          y2={119.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-35"
          x1={9592.88}
          y1={105.04}
          x2={9597.65}
          y2={107.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-36"
          x1={9599.93}
          y1={132.28}
          x2={9605.87}
          y2={132.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-36"
          x1={9600.53}
          y1={125.56}
          x2={9605.31}
          y2={128.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-37"
          x1={9596.62}
          y1={122.08}
          x2={9602.56}
          y2={122.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-37"
          x1={9597.22}
          y1={107.54}
          x2={9601.99}
          y2={110.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-38"
          x1={9604.27}
          y1={134.78}
          x2={9610.21}
          y2={134.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-38"
          x1={9604.87}
          y1={128.06}
          x2={9609.65}
          y2={130.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-39"
          x1={9600.96}
          y1={124.58}
          x2={9606.9}
          y2={124.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-39"
          x1={9601.56}
          y1={110.04}
          x2={9606.34}
          y2={112.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-40"
          x1={9608.61}
          y1={137.28}
          x2={9614.55}
          y2={137.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-40"
          x1={9609.21}
          y1={130.56}
          x2={9613.99}
          y2={133.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-41"
          x1={9605.3}
          y1={127.08}
          x2={9611.24}
          y2={127.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-41"
          x1={9605.9}
          y1={112.54}
          x2={9610.68}
          y2={115.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-42"
          x1={9612.95}
          y1={139.78}
          x2={9618.89}
          y2={139.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-42"
          x1={9613.55}
          y1={133.06}
          x2={9618.33}
          y2={135.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-43"
          x1={9609.64}
          y1={129.58}
          x2={9615.58}
          y2={129.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-43"
          x1={9610.24}
          y1={115.04}
          x2={9615.02}
          y2={117.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-44"
          x1={9617.3}
          y1={142.28}
          x2={9623.23}
          y2={142.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-44"
          x1={9617.9}
          y1={135.55}
          x2={9622.67}
          y2={138.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-45"
          x1={9613.98}
          y1={132.07}
          x2={9619.92}
          y2={132.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-45"
          x1={9614.58}
          y1={117.54}
          x2={9619.36}
          y2={120.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-46"
          x1={9621.64}
          y1={144.77}
          x2={9627.58}
          y2={144.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-46"
          x1={9622.24}
          y1={138.05}
          x2={9627.01}
          y2={140.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-47"
          x1={9618.33}
          y1={134.57}
          x2={9624.26}
          y2={134.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-47"
          x1={9618.93}
          y1={120.03}
          x2={9623.7}
          y2={122.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-48"
          x1={9625.98}
          y1={147.27}
          x2={9631.92}
          y2={147.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-48"
          x1={9626.58}
          y1={140.55}
          x2={9631.35}
          y2={143.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-49"
          x1={9622.67}
          y1={137.07}
          x2={9628.61}
          y2={137.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-49"
          x1={9623.27}
          y1={122.53}
          x2={9628.04}
          y2={125.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-50"
          x1={9630.32}
          y1={149.77}
          x2={9636.26}
          y2={149.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-50"
          x1={9630.92}
          y1={143.05}
          x2={9635.7}
          y2={145.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-51"
          x1={9627.01}
          y1={139.57}
          x2={9632.95}
          y2={139.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-51"
          x1={9627.61}
          y1={125.03}
          x2={9632.39}
          y2={127.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-52"
          x1={9634.66}
          y1={152.27}
          x2={9640.6}
          y2={152.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-52"
          x1={9635.26}
          y1={145.55}
          x2={9640.04}
          y2={148.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-53"
          x1={9631.35}
          y1={142.07}
          x2={9637.29}
          y2={142.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-53"
          x1={9631.95}
          y1={127.53}
          x2={9636.73}
          y2={130.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-54"
          x1={9639}
          y1={154.77}
          x2={9644.94}
          y2={154.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-54"
          x1={9639.6}
          y1={148.05}
          x2={9644.38}
          y2={150.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-55"
          x1={9635.69}
          y1={144.57}
          x2={9641.63}
          y2={144.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-55"
          x1={9636.29}
          y1={130.03}
          x2={9641.07}
          y2={132.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-56"
          x1={9643.35}
          y1={157.27}
          x2={9649.28}
          y2={157.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-56"
          x1={9643.95}
          y1={150.54}
          x2={9648.72}
          y2={153.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-57"
          x1={9640.03}
          y1={147.06}
          x2={9645.97}
          y2={147.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-57"
          x1={9640.63}
          y1={132.53}
          x2={9645.41}
          y2={135.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-58"
          x1={9647.69}
          y1={159.76}
          x2={9653.63}
          y2={159.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-58"
          x1={9648.29}
          y1={153.04}
          x2={9653.06}
          y2={155.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-59"
          x1={9644.38}
          y1={149.56}
          x2={9650.31}
          y2={149.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-59"
          x1={9644.98}
          y1={135.02}
          x2={9649.75}
          y2={137.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-60"
          x1={9652.03}
          y1={162.26}
          x2={9657.97}
          y2={162.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-60"
          x1={9652.63}
          y1={155.54}
          x2={9657.4}
          y2={158.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-61"
          x1={9648.72}
          y1={152.06}
          x2={9654.66}
          y2={152.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-61"
          x1={9649.32}
          y1={137.52}
          x2={9654.09}
          y2={140.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-62"
          x1={9656.37}
          y1={164.76}
          x2={9662.31}
          y2={164.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-62"
          x1={9656.97}
          y1={158.04}
          x2={9661.75}
          y2={160.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-63"
          x1={9653.06}
          y1={154.56}
          x2={9659}
          y2={154.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-63"
          x1={9653.66}
          y1={140.02}
          x2={9658.43}
          y2={142.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-64"
          x1={9660.71}
          y1={167.26}
          x2={9666.65}
          y2={167.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-64"
          x1={9661.31}
          y1={160.54}
          x2={9666.09}
          y2={163.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-65"
          x1={9657.4}
          y1={157.06}
          x2={9663.34}
          y2={157.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-65"
          x1={9658}
          y1={142.52}
          x2={9662.78}
          y2={145.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-66"
          x1={9665.05}
          y1={169.76}
          x2={9670.99}
          y2={169.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-66"
          x1={9665.65}
          y1={163.04}
          x2={9670.43}
          y2={165.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-67"
          x1={9661.74}
          y1={159.56}
          x2={9667.68}
          y2={159.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-67"
          x1={9662.34}
          y1={145.02}
          x2={9667.12}
          y2={147.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-68"
          x1={9669.4}
          y1={172.26}
          x2={9675.33}
          y2={172.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-68"
          x1={9670}
          y1={165.53}
          x2={9674.77}
          y2={168.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-69"
          x1={9666.08}
          y1={162.05}
          x2={9672.02}
          y2={162.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-69"
          x1={9666.68}
          y1={147.52}
          x2={9671.46}
          y2={150.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-70"
          x1={9673.74}
          y1={174.75}
          x2={9679.67}
          y2={174.75}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-70"
          x1={9674.34}
          y1={168.03}
          x2={9679.11}
          y2={170.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-71"
          x1={9670.43}
          y1={164.55}
          x2={9676.36}
          y2={164.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-71"
          x1={9671.03}
          y1={150.01}
          x2={9675.8}
          y2={152.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-72"
          x1={9678.08}
          y1={177.25}
          x2={9684.02}
          y2={177.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-72"
          x1={9678.68}
          y1={170.53}
          x2={9683.45}
          y2={173.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-73"
          x1={9674.77}
          y1={167.05}
          x2={9680.71}
          y2={167.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-73"
          x1={9675.37}
          y1={152.51}
          x2={9680.14}
          y2={155.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-74"
          x1={9682.42}
          y1={179.75}
          x2={9688.36}
          y2={179.75}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-74"
          x1={9683.02}
          y1={173.03}
          x2={9687.8}
          y2={175.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-75"
          x1={9679.11}
          y1={169.55}
          x2={9685.05}
          y2={169.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-75"
          x1={9679.71}
          y1={155.01}
          x2={9684.48}
          y2={157.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-76"
          x1={9686.76}
          y1={182.25}
          x2={9692.7}
          y2={182.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-76"
          x1={9687.36}
          y1={175.53}
          x2={9692.14}
          y2={178.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-77"
          x1={9683.45}
          y1={172.05}
          x2={9689.39}
          y2={172.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-77"
          x1={9684.05}
          y1={157.51}
          x2={9688.83}
          y2={160.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-78"
          x1={9691.1}
          y1={184.75}
          x2={9697.04}
          y2={184.75}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-78"
          x1={9691.7}
          y1={178.03}
          x2={9696.48}
          y2={180.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-79"
          x1={9687.79}
          y1={174.55}
          x2={9693.73}
          y2={174.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-79"
          x1={9688.39}
          y1={160.01}
          x2={9693.17}
          y2={162.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-80"
          x1={9695.44}
          y1={187.25}
          x2={9701.38}
          y2={187.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-80"
          x1={9696.04}
          y1={180.52}
          x2={9700.82}
          y2={183.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-81"
          x1={9692.13}
          y1={177.04}
          x2={9698.07}
          y2={177.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-81"
          x1={9692.73}
          y1={162.51}
          x2={9697.51}
          y2={165.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-82"
          x1={9699.79}
          y1={189.74}
          x2={9705.72}
          y2={189.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-82"
          x1={9700.39}
          y1={183.02}
          x2={9705.16}
          y2={185.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-83"
          x1={9696.47}
          y1={179.54}
          x2={9702.41}
          y2={179.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-83"
          x1={9697.08}
          y1={165}
          x2={9701.85}
          y2={167.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-84"
          x1={9704.13}
          y1={192.24}
          x2={9710.07}
          y2={192.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-84"
          x1={9704.73}
          y1={185.52}
          x2={9709.5}
          y2={188.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-85"
          x1={9700.82}
          y1={182.04}
          x2={9706.75}
          y2={182.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-85"
          x1={9701.42}
          y1={167.5}
          x2={9706.19}
          y2={170.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-86"
          x1={9708.47}
          y1={194.74}
          x2={9714.41}
          y2={194.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-86"
          x1={9709.07}
          y1={188.02}
          x2={9713.85}
          y2={190.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-87"
          x1={9705.16}
          y1={184.54}
          x2={9711.1}
          y2={184.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-87"
          x1={9705.76}
          y1={170}
          x2={9710.53}
          y2={172.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-88"
          x1={9712.81}
          y1={197.24}
          x2={9718.75}
          y2={197.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-88"
          x1={9713.41}
          y1={190.52}
          x2={9718.19}
          y2={193.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-89"
          x1={9709.5}
          y1={187.04}
          x2={9715.44}
          y2={187.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-89"
          x1={9710.1}
          y1={172.5}
          x2={9714.88}
          y2={175.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-90"
          x1={9717.15}
          y1={199.74}
          x2={9723.09}
          y2={199.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-90"
          x1={9717.75}
          y1={193.02}
          x2={9722.53}
          y2={195.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-91"
          x1={9713.84}
          y1={189.54}
          x2={9719.78}
          y2={189.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-91"
          x1={9714.44}
          y1={175}
          x2={9719.22}
          y2={177.75}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-92"
          x1={9721.49}
          y1={202.24}
          x2={9727.43}
          y2={202.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-92"
          x1={9722.09}
          y1={195.51}
          x2={9726.87}
          y2={198.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-93"
          x1={9718.18}
          y1={192.03}
          x2={9724.12}
          y2={192.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-93"
          x1={9718.78}
          y1={177.5}
          x2={9723.56}
          y2={180.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-94"
          x1={9725.84}
          y1={204.73}
          x2={9731.77}
          y2={204.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-94"
          x1={9726.44}
          y1={198.01}
          x2={9731.21}
          y2={200.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-95"
          x1={9722.52}
          y1={194.53}
          x2={9728.46}
          y2={194.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-95"
          x1={9723.12}
          y1={179.99}
          x2={9727.9}
          y2={182.75}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-96"
          x1={9730.18}
          y1={207.23}
          x2={9736.12}
          y2={207.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-96"
          x1={9730.78}
          y1={200.51}
          x2={9735.55}
          y2={203.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-97"
          x1={9726.87}
          y1={197.03}
          x2={9732.8}
          y2={197.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-97"
          x1={9727.47}
          y1={182.49}
          x2={9732.24}
          y2={185.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-98"
          x1={9734.52}
          y1={209.73}
          x2={9740.46}
          y2={209.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-98"
          x1={9735.12}
          y1={203.01}
          x2={9739.89}
          y2={205.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-99"
          x1={9731.21}
          y1={199.53}
          x2={9737.15}
          y2={199.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-99"
          x1={9731.81}
          y1={184.99}
          x2={9736.58}
          y2={187.75}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-100"
          x1={9738.86}
          y1={212.23}
          x2={9744.8}
          y2={212.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-100"
          x1={9739.46}
          y1={205.51}
          x2={9744.24}
          y2={208.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-101"
          x1={9735.55}
          y1={202.03}
          x2={9741.49}
          y2={202.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-101"
          x1={9736.15}
          y1={187.49}
          x2={9740.93}
          y2={190.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-102"
          x1={9743.2}
          y1={214.73}
          x2={9749.14}
          y2={214.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-102"
          x1={9743.8}
          y1={208.01}
          x2={9748.58}
          y2={210.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-103"
          x1={9739.89}
          y1={204.53}
          x2={9745.83}
          y2={204.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-103"
          x1={9740.49}
          y1={189.99}
          x2={9745.27}
          y2={192.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-104"
          x1={9747.54}
          y1={217.23}
          x2={9753.48}
          y2={217.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-104"
          x1={9748.14}
          y1={210.5}
          x2={9752.92}
          y2={213.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-105"
          x1={9744.23}
          y1={207.02}
          x2={9750.17}
          y2={207.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-105"
          x1={9744.83}
          y1={192.49}
          x2={9749.61}
          y2={195.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-106"
          x1={9751.89}
          y1={219.72}
          x2={9757.82}
          y2={219.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-106"
          x1={9752.49}
          y1={213}
          x2={9757.26}
          y2={215.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-107"
          x1={9748.57}
          y1={209.52}
          x2={9754.51}
          y2={209.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-107"
          x1={9749.17}
          y1={194.98}
          x2={9753.95}
          y2={197.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-108"
          x1={9756.23}
          y1={222.22}
          x2={9762.17}
          y2={222.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-108"
          x1={9756.83}
          y1={215.5}
          x2={9761.6}
          y2={218.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-109"
          x1={9752.92}
          y1={212.02}
          x2={9758.85}
          y2={212.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-109"
          x1={9753.52}
          y1={197.48}
          x2={9758.29}
          y2={200.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-110"
          x1={9760.57}
          y1={224.72}
          x2={9766.51}
          y2={224.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-110"
          x1={9761.17}
          y1={218}
          x2={9765.94}
          y2={220.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-111"
          x1={9757.26}
          y1={214.52}
          x2={9763.2}
          y2={214.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-111"
          x1={9757.86}
          y1={199.98}
          x2={9762.63}
          y2={202.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-112"
          x1={9764.91}
          y1={227.22}
          x2={9770.85}
          y2={227.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-112"
          x1={9765.51}
          y1={220.5}
          x2={9770.29}
          y2={223.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-113"
          x1={9761.6}
          y1={217.02}
          x2={9767.54}
          y2={217.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-113"
          x1={9762.2}
          y1={202.48}
          x2={9766.97}
          y2={205.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-114"
          x1={9769.25}
          y1={229.72}
          x2={9775.19}
          y2={229.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-114"
          x1={9769.85}
          y1={223}
          x2={9774.63}
          y2={225.75}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-115"
          x1={9765.94}
          y1={219.52}
          x2={9771.88}
          y2={219.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-115"
          x1={9766.54}
          y1={204.98}
          x2={9771.32}
          y2={207.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-116"
          x1={9773.59}
          y1={232.22}
          x2={9779.53}
          y2={232.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-116"
          x1={9774.19}
          y1={225.49}
          x2={9778.97}
          y2={228.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-117"
          x1={9770.28}
          y1={222.01}
          x2={9776.22}
          y2={222.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-117"
          x1={9770.88}
          y1={207.48}
          x2={9775.66}
          y2={210.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-118"
          x1={9777.93}
          y1={234.71}
          x2={9783.87}
          y2={234.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-118"
          x1={9778.54}
          y1={227.99}
          x2={9783.31}
          y2={230.75}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-119"
          x1={9774.62}
          y1={224.51}
          x2={9780.56}
          y2={224.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-119"
          x1={9775.22}
          y1={209.97}
          x2={9780}
          y2={212.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-120"
          x1={9782.28}
          y1={237.21}
          x2={9788.21}
          y2={237.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-120"
          x1={9782.88}
          y1={230.49}
          x2={9787.65}
          y2={233.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-121"
          x1={9778.96}
          y1={227.01}
          x2={9784.9}
          y2={227.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-121"
          x1={9779.57}
          y1={212.47}
          x2={9784.34}
          y2={215.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-122"
          x1={9786.62}
          y1={239.71}
          x2={9792.56}
          y2={239.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-122"
          x1={9787.22}
          y1={232.99}
          x2={9791.99}
          y2={235.75}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-123"
          x1={9783.31}
          y1={229.51}
          x2={9789.25}
          y2={229.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-123"
          x1={9783.91}
          y1={214.97}
          x2={9788.68}
          y2={217.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-124"
          x1={9790.96}
          y1={242.21}
          x2={9796.9}
          y2={242.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-124"
          x1={9791.56}
          y1={235.49}
          x2={9796.34}
          y2={238.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-125"
          x1={9787.65}
          y1={232.01}
          x2={9793.59}
          y2={232.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-125"
          x1={9788.25}
          y1={217.47}
          x2={9793.02}
          y2={220.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-126"
          x1={9795.3}
          y1={244.71}
          x2={9801.24}
          y2={244.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-126"
          x1={9795.9}
          y1={237.99}
          x2={9800.68}
          y2={240.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-127"
          x1={9791.99}
          y1={234.51}
          x2={9797.93}
          y2={234.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-127"
          x1={9792.59}
          y1={219.97}
          x2={9797.37}
          y2={222.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-128"
          x1={9799.64}
          y1={247.21}
          x2={9805.58}
          y2={247.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-128"
          x1={9800.24}
          y1={240.48}
          x2={9805.02}
          y2={243.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-129"
          x1={9796.33}
          y1={237}
          x2={9802.27}
          y2={237}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-129"
          x1={9796.93}
          y1={222.47}
          x2={9801.71}
          y2={225.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-130"
          x1={9803.98}
          y1={249.7}
          x2={9809.92}
          y2={249.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-130"
          x1={9804.58}
          y1={242.98}
          x2={9809.36}
          y2={245.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-131"
          x1={9800.67}
          y1={239.5}
          x2={9806.61}
          y2={239.5}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-131"
          x1={9801.27}
          y1={224.96}
          x2={9806.05}
          y2={227.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-132"
          x1={9808.33}
          y1={252.2}
          x2={9814.26}
          y2={252.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-132"
          x1={9808.93}
          y1={245.48}
          x2={9813.7}
          y2={248.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-133"
          x1={9805.01}
          y1={242}
          x2={9810.95}
          y2={242}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-133"
          x1={9805.61}
          y1={227.46}
          x2={9810.39}
          y2={230.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-134"
          x1={9812.67}
          y1={254.7}
          x2={9818.61}
          y2={254.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-134"
          x1={9813.27}
          y1={247.98}
          x2={9818.04}
          y2={250.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-135"
          x1={9809.36}
          y1={244.5}
          x2={9815.29}
          y2={244.5}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-135"
          x1={9809.96}
          y1={229.96}
          x2={9814.73}
          y2={232.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-136"
          x1={9817.01}
          y1={257.2}
          x2={9822.95}
          y2={257.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-136"
          x1={9817.61}
          y1={250.48}
          x2={9822.38}
          y2={253.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-137"
          x1={9813.7}
          y1={247}
          x2={9819.64}
          y2={247}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-137"
          x1={9814.3}
          y1={232.46}
          x2={9819.07}
          y2={235.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-138"
          x1={9821.35}
          y1={259.7}
          x2={9827.29}
          y2={259.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-138"
          x1={9821.95}
          y1={252.98}
          x2={9826.73}
          y2={255.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-139"
          x1={9818.04}
          y1={249.5}
          x2={9823.98}
          y2={249.5}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-139"
          x1={9818.64}
          y1={234.96}
          x2={9823.42}
          y2={237.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-140"
          x1={9825.69}
          y1={262.2}
          x2={9831.63}
          y2={262.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-140"
          x1={9826.29}
          y1={255.47}
          x2={9831.07}
          y2={258.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-141"
          x1={9822.38}
          y1={251.99}
          x2={9828.32}
          y2={251.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-141"
          x1={9822.98}
          y1={237.46}
          x2={9827.76}
          y2={240.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-142"
          x1={9830.03}
          y1={264.69}
          x2={9835.97}
          y2={264.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-142"
          x1={9830.63}
          y1={257.97}
          x2={9835.41}
          y2={260.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-143"
          x1={9826.72}
          y1={254.49}
          x2={9832.66}
          y2={254.49}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-143"
          x1={9827.32}
          y1={239.95}
          x2={9832.1}
          y2={242.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-144"
          x1={9834.38}
          y1={267.19}
          x2={9840.31}
          y2={267.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-144"
          x1={9834.98}
          y1={260.47}
          x2={9839.75}
          y2={263.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-145"
          x1={9831.06}
          y1={256.99}
          x2={9837}
          y2={256.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-145"
          x1={9831.66}
          y1={242.45}
          x2={9836.44}
          y2={245.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-146"
          x1={9838.72}
          y1={269.69}
          x2={9844.66}
          y2={269.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-146"
          x1={9839.32}
          y1={262.97}
          x2={9844.09}
          y2={265.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-147"
          x1={9835.41}
          y1={259.49}
          x2={9841.34}
          y2={259.49}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-147"
          x1={9836.01}
          y1={244.95}
          x2={9840.78}
          y2={247.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-148"
          x1={9843.06}
          y1={272.19}
          x2={9849}
          y2={272.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-148"
          x1={9843.66}
          y1={265.47}
          x2={9848.43}
          y2={268.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-149"
          x1={9839.75}
          y1={261.99}
          x2={9845.69}
          y2={261.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-149"
          x1={9840.35}
          y1={247.45}
          x2={9845.12}
          y2={250.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-150"
          x1={9847.4}
          y1={274.69}
          x2={9853.34}
          y2={274.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-150"
          x1={9848}
          y1={267.97}
          x2={9852.78}
          y2={270.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-151"
          x1={9844.09}
          y1={264.49}
          x2={9850.03}
          y2={264.49}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-151"
          x1={9844.69}
          y1={249.95}
          x2={9849.46}
          y2={252.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-152"
          x1={9851.74}
          y1={277.19}
          x2={9857.68}
          y2={277.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-152"
          x1={9852.34}
          y1={270.46}
          x2={9857.12}
          y2={273.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-153"
          x1={9848.43}
          y1={266.98}
          x2={9854.37}
          y2={266.98}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-153"
          x1={9849.03}
          y1={252.45}
          x2={9853.81}
          y2={255.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-154"
          x1={9856.08}
          y1={279.68}
          x2={9862.02}
          y2={279.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-154"
          x1={9856.68}
          y1={272.96}
          x2={9861.46}
          y2={275.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-155"
          x1={9852.77}
          y1={269.48}
          x2={9858.71}
          y2={269.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-155"
          x1={9853.37}
          y1={254.94}
          x2={9858.15}
          y2={257.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-156"
          x1={9860.42}
          y1={282.18}
          x2={9866.36}
          y2={282.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-156"
          x1={9861.03}
          y1={275.46}
          x2={9865.8}
          y2={278.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-157"
          x1={9857.11}
          y1={271.98}
          x2={9863.05}
          y2={271.98}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-157"
          x1={9857.71}
          y1={257.44}
          x2={9862.49}
          y2={260.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-158"
          x1={9864.77}
          y1={284.68}
          x2={9870.7}
          y2={284.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-158"
          x1={9865.37}
          y1={277.96}
          x2={9870.14}
          y2={280.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-159"
          x1={9861.46}
          y1={274.48}
          x2={9867.39}
          y2={274.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-159"
          x1={9862.06}
          y1={259.94}
          x2={9866.83}
          y2={262.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-160"
          x1={9869.11}
          y1={287.18}
          x2={9875.05}
          y2={287.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-160"
          x1={9869.71}
          y1={280.46}
          x2={9874.48}
          y2={283.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-161"
          x1={9865.8}
          y1={276.98}
          x2={9871.74}
          y2={276.98}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-161"
          x1={9866.4}
          y1={262.44}
          x2={9871.17}
          y2={265.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-162"
          x1={9873.45}
          y1={289.68}
          x2={9879.39}
          y2={289.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-162"
          x1={9874.05}
          y1={282.96}
          x2={9878.83}
          y2={285.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-163"
          x1={9870.14}
          y1={279.48}
          x2={9876.08}
          y2={279.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-163"
          x1={9870.74}
          y1={264.94}
          x2={9875.51}
          y2={267.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-164"
          x1={9877.79}
          y1={292.18}
          x2={9883.73}
          y2={292.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-164"
          x1={9878.39}
          y1={285.45}
          x2={9883.17}
          y2={288.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-165"
          x1={9874.48}
          y1={281.97}
          x2={9880.42}
          y2={281.97}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-165"
          x1={9875.08}
          y1={267.44}
          x2={9879.86}
          y2={270.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-166"
          x1={9882.13}
          y1={294.67}
          x2={9888.07}
          y2={294.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-166"
          x1={9882.73}
          y1={287.95}
          x2={9887.51}
          y2={290.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-167"
          x1={9878.82}
          y1={284.47}
          x2={9884.76}
          y2={284.47}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-167"
          x1={9879.42}
          y1={269.93}
          x2={9884.2}
          y2={272.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-168"
          x1={9886.47}
          y1={297.17}
          x2={9892.41}
          y2={297.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-168"
          x1={9887.07}
          y1={290.45}
          x2={9891.85}
          y2={293.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-169"
          x1={9883.16}
          y1={286.97}
          x2={9889.1}
          y2={286.97}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-169"
          x1={9883.76}
          y1={272.43}
          x2={9888.54}
          y2={275.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-170"
          x1={9890.82}
          y1={299.67}
          x2={9896.75}
          y2={299.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-170"
          x1={9891.42}
          y1={292.95}
          x2={9896.19}
          y2={295.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-171"
          x1={9887.5}
          y1={289.47}
          x2={9893.44}
          y2={289.47}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-171"
          x1={9888.11}
          y1={274.93}
          x2={9892.88}
          y2={277.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-172"
          x1={9895.16}
          y1={302.17}
          x2={9901.1}
          y2={302.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-172"
          x1={9895.76}
          y1={295.45}
          x2={9900.53}
          y2={298.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-173"
          x1={9891.85}
          y1={291.97}
          x2={9897.78}
          y2={291.97}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-173"
          x1={9892.45}
          y1={277.43}
          x2={9897.22}
          y2={280.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-174"
          x1={9899.5}
          y1={304.67}
          x2={9905.44}
          y2={304.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-174"
          x1={9900.1}
          y1={297.95}
          x2={9904.88}
          y2={300.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-175"
          x1={9896.19}
          y1={294.47}
          x2={9902.13}
          y2={294.47}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-175"
          x1={9896.79}
          y1={279.93}
          x2={9901.56}
          y2={282.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-176"
          x1={9903.84}
          y1={307.17}
          x2={9909.78}
          y2={307.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-176"
          x1={9904.44}
          y1={300.44}
          x2={9909.22}
          y2={303.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-177"
          x1={9900.53}
          y1={296.96}
          x2={9906.47}
          y2={296.96}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-177"
          x1={9901.13}
          y1={282.43}
          x2={9905.91}
          y2={285.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-178"
          x1={9908.18}
          y1={309.66}
          x2={9914.12}
          y2={309.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-178"
          x1={9908.78}
          y1={302.94}
          x2={9913.56}
          y2={305.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-179"
          x1={9904.87}
          y1={299.46}
          x2={9910.81}
          y2={299.46}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-179"
          x1={9905.47}
          y1={284.92}
          x2={9910.25}
          y2={287.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-180"
          x1={9912.52}
          y1={312.16}
          x2={9918.46}
          y2={312.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-180"
          x1={9913.12}
          y1={305.44}
          x2={9917.9}
          y2={308.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-181"
          x1={9909.21}
          y1={301.96}
          x2={9915.15}
          y2={301.96}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-181"
          x1={9909.81}
          y1={287.42}
          x2={9914.59}
          y2={290.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-182"
          x1={9916.87}
          y1={314.66}
          x2={9922.8}
          y2={314.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-182"
          x1={9917.47}
          y1={307.94}
          x2={9922.24}
          y2={310.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-183"
          x1={9913.55}
          y1={304.46}
          x2={9919.49}
          y2={304.46}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-183"
          x1={9914.15}
          y1={289.92}
          x2={9918.93}
          y2={292.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-184"
          x1={9921.21}
          y1={317.16}
          x2={9927.15}
          y2={317.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-184"
          x1={9921.81}
          y1={310.44}
          x2={9926.58}
          y2={313.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-185"
          x1={9917.9}
          y1={306.96}
          x2={9923.83}
          y2={306.96}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-185"
          x1={9918.5}
          y1={292.42}
          x2={9923.27}
          y2={295.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-186"
          x1={9925.55}
          y1={319.66}
          x2={9931.49}
          y2={319.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-186"
          x1={9926.15}
          y1={312.94}
          x2={9930.92}
          y2={315.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-187"
          x1={9922.24}
          y1={309.46}
          x2={9928.18}
          y2={309.46}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-187"
          x1={9922.84}
          y1={294.92}
          x2={9927.61}
          y2={297.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-188"
          x1={9929.89}
          y1={322.16}
          x2={9935.83}
          y2={322.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-188"
          x1={9930.49}
          y1={315.43}
          x2={9935.27}
          y2={318.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-189"
          x1={9926.58}
          y1={311.95}
          x2={9932.52}
          y2={311.95}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-189"
          x1={9927.18}
          y1={297.42}
          x2={9931.96}
          y2={300.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-190"
          x1={9934.23}
          y1={324.65}
          x2={9940.17}
          y2={324.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-190"
          x1={9934.83}
          y1={317.93}
          x2={9939.61}
          y2={320.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-191"
          x1={9930.92}
          y1={314.45}
          x2={9936.86}
          y2={314.45}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-191"
          x1={9931.52}
          y1={299.91}
          x2={9936.3}
          y2={302.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-192"
          x1={9938.57}
          y1={327.15}
          x2={9944.51}
          y2={327.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-192"
          x1={9939.17}
          y1={320.43}
          x2={9943.95}
          y2={323.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-193"
          x1={9935.26}
          y1={316.95}
          x2={9941.2}
          y2={316.95}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-193"
          x1={9935.86}
          y1={302.41}
          x2={9940.64}
          y2={305.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-194"
          x1={9942.92}
          y1={329.65}
          x2={9948.85}
          y2={329.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-194"
          x1={9943.52}
          y1={322.93}
          x2={9948.29}
          y2={325.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-195"
          x1={9939.6}
          y1={319.45}
          x2={9945.54}
          y2={319.45}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-195"
          x1={9940.2}
          y1={304.91}
          x2={9944.98}
          y2={307.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-196"
          x1={9947.26}
          y1={332.15}
          x2={9953.19}
          y2={332.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-196"
          x1={9947.86}
          y1={325.43}
          x2={9952.63}
          y2={328.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-197"
          x1={9943.95}
          y1={321.95}
          x2={9949.88}
          y2={321.95}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-197"
          x1={9944.55}
          y1={307.41}
          x2={9949.32}
          y2={310.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-198"
          x1={9951.6}
          y1={334.65}
          x2={9957.54}
          y2={334.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-198"
          x1={9952.2}
          y1={327.93}
          x2={9956.97}
          y2={330.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-199"
          x1={9948.29}
          y1={324.45}
          x2={9954.23}
          y2={324.45}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-199"
          x1={9948.89}
          y1={309.91}
          x2={9953.66}
          y2={312.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-200"
          x1={9955.94}
          y1={337.15}
          x2={9961.88}
          y2={337.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-200"
          x1={9956.54}
          y1={330.42}
          x2={9961.32}
          y2={333.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-201"
          x1={9952.63}
          y1={326.94}
          x2={9958.57}
          y2={326.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-201"
          x1={9953.23}
          y1={312.41}
          x2={9958}
          y2={315.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-202"
          x1={9960.28}
          y1={339.64}
          x2={9966.22}
          y2={339.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-202"
          x1={9960.88}
          y1={332.92}
          x2={9965.66}
          y2={335.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-203"
          x1={9956.97}
          y1={329.44}
          x2={9962.91}
          y2={329.44}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-203"
          x1={9957.57}
          y1={314.9}
          x2={9962.35}
          y2={317.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-204"
          x1={9964.62}
          y1={342.14}
          x2={9970.56}
          y2={342.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-204"
          x1={9965.22}
          y1={335.42}
          x2={9970}
          y2={338.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-205"
          x1={9961.31}
          y1={331.94}
          x2={9967.25}
          y2={331.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-205"
          x1={9961.91}
          y1={317.4}
          x2={9966.69}
          y2={320.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-206"
          x1={9968.96}
          y1={344.64}
          x2={9974.9}
          y2={344.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-206"
          x1={9969.57}
          y1={337.92}
          x2={9974.34}
          y2={340.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-207"
          x1={9965.65}
          y1={334.44}
          x2={9971.59}
          y2={334.44}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-207"
          x1={9966.25}
          y1={319.9}
          x2={9971.03}
          y2={322.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-208"
          x1={9973.31}
          y1={347.14}
          x2={9979.24}
          y2={347.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-208"
          x1={9973.91}
          y1={340.42}
          x2={9978.68}
          y2={343.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-209"
          x1={9969.99}
          y1={336.94}
          x2={9975.93}
          y2={336.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-209"
          x1={9970.6}
          y1={322.4}
          x2={9975.37}
          y2={325.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-210"
          x1={9977.65}
          y1={349.64}
          x2={9983.59}
          y2={349.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-210"
          x1={9978.25}
          y1={342.92}
          x2={9983.02}
          y2={345.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-211"
          x1={9974.34}
          y1={339.44}
          x2={9980.27}
          y2={339.44}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-211"
          x1={9974.94}
          y1={324.9}
          x2={9979.71}
          y2={327.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-212"
          x1={9981.99}
          y1={352.14}
          x2={9987.93}
          y2={352.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-212"
          x1={9982.59}
          y1={345.41}
          x2={9987.37}
          y2={348.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-213"
          x1={9978.68}
          y1={341.93}
          x2={9984.62}
          y2={341.93}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-213"
          x1={9979.28}
          y1={327.4}
          x2={9984.05}
          y2={330.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-214"
          x1={9986.33}
          y1={354.63}
          x2={9992.27}
          y2={354.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-214"
          x1={9986.93}
          y1={347.91}
          x2={9991.71}
          y2={350.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-215"
          x1={9983.02}
          y1={344.43}
          x2={9988.96}
          y2={344.43}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-215"
          x1={9983.62}
          y1={329.89}
          x2={9988.4}
          y2={332.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-216"
          x1={9990.67}
          y1={357.13}
          x2={9996.61}
          y2={357.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-216"
          x1={9991.27}
          y1={350.41}
          x2={9996.05}
          y2={353.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-217"
          x1={9987.36}
          y1={346.93}
          x2={9993.3}
          y2={346.93}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-217"
          x1={9987.96}
          y1={332.39}
          x2={9992.74}
          y2={335.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-218"
          x1={9995.01}
          y1={359.63}
          x2={10000.95}
          y2={359.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-218"
          x1={9995.61}
          y1={352.91}
          x2={10000.39}
          y2={355.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-219"
          x1={9991.7}
          y1={349.43}
          x2={9997.64}
          y2={349.43}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-219"
          x1={9992.3}
          y1={334.89}
          x2={9997.08}
          y2={337.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-220"
          x1={9999.36}
          y1={362.13}
          x2={10005.29}
          y2={362.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-220"
          x1={9999.96}
          y1={355.41}
          x2={10004.73}
          y2={358.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-221"
          x1={9996.04}
          y1={351.93}
          x2={10001.98}
          y2={351.93}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-221"
          x1={9996.64}
          y1={337.39}
          x2={10001.42}
          y2={340.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-222"
          x1={10003.7}
          y1={364.63}
          x2={10009.64}
          y2={364.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-222"
          x1={10004.3}
          y1={357.91}
          x2={10009.07}
          y2={360.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-223"
          x1={10000.39}
          y1={354.43}
          x2={10006.32}
          y2={354.43}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-223"
          x1={10000.99}
          y1={339.89}
          x2={10005.76}
          y2={342.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-224"
          x1={10008.04}
          y1={367.13}
          x2={10013.98}
          y2={367.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-224"
          x1={10008.64}
          y1={360.4}
          x2={10013.41}
          y2={363.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-225"
          x1={10004.73}
          y1={356.92}
          x2={10010.67}
          y2={356.92}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-225"
          x1={10005.33}
          y1={342.39}
          x2={10010.1}
          y2={345.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-226"
          x1={10012.38}
          y1={369.62}
          x2={10018.32}
          y2={369.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-226"
          x1={10012.98}
          y1={362.9}
          x2={10017.76}
          y2={365.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-227"
          x1={10009.07}
          y1={359.42}
          x2={10015.01}
          y2={359.42}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-227"
          x1={10009.67}
          y1={344.88}
          x2={10014.45}
          y2={347.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-228"
          x1={10016.72}
          y1={372.12}
          x2={10022.66}
          y2={372.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-228"
          x1={10017.32}
          y1={365.4}
          x2={10022.1}
          y2={368.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-229"
          x1={10013.41}
          y1={361.92}
          x2={10019.35}
          y2={361.92}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-229"
          x1={10014.01}
          y1={347.38}
          x2={10018.79}
          y2={350.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-230"
          x1={10021.06}
          y1={374.62}
          x2={10027}
          y2={374.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-230"
          x1={10021.66}
          y1={367.9}
          x2={10026.44}
          y2={370.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-231"
          x1={10017.75}
          y1={364.42}
          x2={10023.69}
          y2={364.42}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-231"
          x1={10018.35}
          y1={349.88}
          x2={10023.13}
          y2={352.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-232"
          x1={10025.41}
          y1={377.12}
          x2={10031.34}
          y2={377.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-232"
          x1={10026.01}
          y1={370.4}
          x2={10030.78}
          y2={373.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-233"
          x1={10022.09}
          y1={366.92}
          x2={10028.03}
          y2={366.92}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-233"
          x1={10022.69}
          y1={352.38}
          x2={10027.47}
          y2={355.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-234"
          x1={10029.75}
          y1={379.62}
          x2={10035.69}
          y2={379.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-234"
          x1={10030.35}
          y1={372.9}
          x2={10035.12}
          y2={375.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-235"
          x1={10026.44}
          y1={369.42}
          x2={10032.37}
          y2={369.42}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-235"
          x1={10027.04}
          y1={354.88}
          x2={10031.81}
          y2={357.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-236"
          x1={10034.09}
          y1={382.12}
          x2={10040.03}
          y2={382.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-236"
          x1={10034.69}
          y1={375.39}
          x2={10039.46}
          y2={378.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-237"
          x1={10030.78}
          y1={371.91}
          x2={10036.72}
          y2={371.91}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-237"
          x1={10031.38}
          y1={357.38}
          x2={10036.15}
          y2={360.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-238"
          x1={10038.43}
          y1={384.61}
          x2={10044.37}
          y2={384.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-238"
          x1={10039.03}
          y1={377.89}
          x2={10043.81}
          y2={380.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-239"
          x1={10035.12}
          y1={374.41}
          x2={10041.06}
          y2={374.41}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-239"
          x1={10035.72}
          y1={359.87}
          x2={10040.49}
          y2={362.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-240"
          x1={10042.77}
          y1={387.11}
          x2={10048.71}
          y2={387.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-240"
          x1={10043.37}
          y1={380.39}
          x2={10048.15}
          y2={383.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-241"
          x1={10039.46}
          y1={376.91}
          x2={10045.4}
          y2={376.91}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-241"
          x1={10040.06}
          y1={362.37}
          x2={10044.84}
          y2={365.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-242"
          x1={10047.11}
          y1={389.61}
          x2={10053.05}
          y2={389.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-242"
          x1={10047.71}
          y1={382.89}
          x2={10052.49}
          y2={385.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-243"
          x1={10043.8}
          y1={379.41}
          x2={10049.74}
          y2={379.41}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-243"
          x1={10044.4}
          y1={364.87}
          x2={10049.18}
          y2={367.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-244"
          x1={10051.45}
          y1={392.11}
          x2={10057.39}
          y2={392.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-244"
          x1={10052.06}
          y1={385.39}
          x2={10056.83}
          y2={388.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-245"
          x1={10048.14}
          y1={381.91}
          x2={10054.08}
          y2={381.91}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-245"
          x1={10048.74}
          y1={367.37}
          x2={10053.52}
          y2={370.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-246"
          x1={10055.8}
          y1={394.61}
          x2={10061.73}
          y2={394.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-246"
          x1={10056.4}
          y1={387.89}
          x2={10061.17}
          y2={390.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-247"
          x1={10052.49}
          y1={384.41}
          x2={10058.42}
          y2={384.41}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-247"
          x1={10053.09}
          y1={369.87}
          x2={10057.86}
          y2={372.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-248"
          x1={10060.14}
          y1={397.11}
          x2={10066.08}
          y2={397.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-248"
          x1={10060.74}
          y1={390.38}
          x2={10065.51}
          y2={393.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-249"
          x1={10056.83}
          y1={386.9}
          x2={10062.77}
          y2={386.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-249"
          x1={10057.43}
          y1={372.37}
          x2={10062.2}
          y2={375.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-250"
          x1={10064.48}
          y1={399.6}
          x2={10070.42}
          y2={399.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-250"
          x1={10065.08}
          y1={392.88}
          x2={10069.86}
          y2={395.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-251"
          x1={10061.17}
          y1={389.4}
          x2={10067.11}
          y2={389.4}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-251"
          x1={10061.77}
          y1={374.86}
          x2={10066.54}
          y2={377.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-252"
          x1={10068.82}
          y1={402.1}
          x2={10074.76}
          y2={402.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-252"
          x1={10069.42}
          y1={395.38}
          x2={10074.2}
          y2={398.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-253"
          x1={10065.51}
          y1={391.9}
          x2={10071.45}
          y2={391.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-253"
          x1={10066.11}
          y1={377.36}
          x2={10070.89}
          y2={380.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_23-254"
          x1={10073.16}
          y1={404.6}
          x2={10079.1}
          y2={404.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_23"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_35-254"
          x1={10073.76}
          y1={397.88}
          x2={10078.54}
          y2={400.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6"
          data-name="New Gradient Swatch copy 6"
          x1={-3498.59}
          y1={185.87}
          x2={-3298.26}
          y2={125.89}
          gradientTransform="matrix(-1 0 0 1 -2805.38 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#940f0f" />
          <stop offset={0.28} stopColor="#a2131e" />
          <stop offset={0.75} stopColor="#c71e47" />
          <stop offset={1} stopColor="#de255f" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-2"
          x1={-3553.61}
          y1={201.01}
          x2={-3466}
          y2={181.37}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-3"
          x1={-3382.07}
          y1={-9.74}
          x2={-3478.05}
          y2={173.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12"
          data-name="New Gradient Swatch copy 12"
          x1={-3498.59}
          y1={154.85}
          x2={-3298.26}
          y2={94.86}
          gradientTransform="matrix(-1 0 0 1 -2805.38 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#2d2d2d" />
          <stop offset={0.42} stopColor="#3c3c3c" />
          <stop offset={1} stopColor="#5b5b5b" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-2"
          x1={-3553.61}
          y1={169.98}
          x2={-3466}
          y2={150.34}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-3"
          x1={-3382.07}
          y1={-40.76}
          x2={-3478.05}
          y2={142.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-4"
          x1={-3404.95}
          y1={68.28}
          x2={-3204.61}
          y2={8.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-5"
          x1={-3459.97}
          y1={83.41}
          x2={-3372.35}
          y2={63.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-6"
          x1={-3288.43}
          y1={-127.33}
          x2={-3384.41}
          y2={55.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-4"
          x1={-3498.59}
          y1={122.58}
          x2={-3298.26}
          y2={62.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-5"
          x1={-3553.61}
          y1={137.71}
          x2={-3466}
          y2={118.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-6"
          x1={-3382.07}
          y1={-73.03}
          x2={-3478.05}
          y2={109.89}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-7"
          x1={-3471.13}
          y1={201.54}
          x2={-3270.8}
          y2={141.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-8"
          x1={-3526.15}
          y1={216.68}
          x2={-3438.54}
          y2={197.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-9"
          x1={-3354.61}
          y1={5.93}
          x2={-3450.59}
          y2={188.85}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-10"
          x1={-3471.13}
          y1={170.52}
          x2={-3270.8}
          y2={110.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-11"
          x1={-3526.15}
          y1={185.65}
          x2={-3438.54}
          y2={166.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-12"
          x1={-3354.61}
          y1={-25.1}
          x2={-3450.59}
          y2={157.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11"
          data-name="New Gradient Swatch copy 11"
          x1={-3377.49}
          y1={83.95}
          x2={-3177.15}
          y2={23.96}
          gradientTransform="matrix(-1 0 0 1 -2805.38 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#1e1e1e" />
          <stop offset={0.49} stopColor="#2d2d2d" />
          <stop offset={1} stopColor="#444" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-2"
          x1={-3432.5}
          y1={99.08}
          x2={-3344.89}
          y2={79.44}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-3"
          x1={-3260.97}
          y1={-111.66}
          x2={-3356.95}
          y2={71.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-13"
          x1={-3471.13}
          y1={138.25}
          x2={-3270.8}
          y2={78.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-14"
          x1={-3526.15}
          y1={153.38}
          x2={-3438.54}
          y2={133.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-15"
          x1={-3354.61}
          y1={-57.37}
          x2={-3450.59}
          y2={125.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-4"
          x1={-3443.83}
          y1={216.9}
          x2={-3243.49}
          y2={156.92}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-5"
          x1={-3498.84}
          y1={232.04}
          x2={-3411.23}
          y2={212.4}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-6"
          x1={-3327.31}
          y1={21.29}
          x2={-3423.29}
          y2={204.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-16"
          x1={-3443.83}
          y1={185.87}
          x2={-3243.49}
          y2={125.89}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-17"
          x1={-3498.84}
          y1={201.01}
          x2={-3411.23}
          y2={181.37}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-18"
          x1={-3327.31}
          y1={-9.74}
          x2={-3423.29}
          y2={173.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-19"
          x1={-3350.18}
          y1={99.31}
          x2={-3149.85}
          y2={39.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-20"
          x1={-3405.2}
          y1={114.44}
          x2={-3317.59}
          y2={94.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-21"
          x1={-3233.66}
          y1={-96.3}
          x2={-3329.64}
          y2={86.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-22"
          x1={-3443.83}
          y1={153.6}
          x2={-3243.49}
          y2={93.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-23"
          x1={-3498.84}
          y1={168.74}
          x2={-3411.23}
          y2={149.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-24"
          x1={-3327.31}
          y1={-42.01}
          x2={-3423.29}
          y2={140.91}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-25"
          x1={-3295.72}
          y1={194.02}
          x2={-3095.38}
          y2={134.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-26"
          x1={-3350.73}
          y1={209.15}
          x2={-3263.12}
          y2={189.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-27"
          x1={-3179.19}
          y1={-1.59}
          x2={-3275.17}
          y2={181.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-28"
          x1={-3416.74}
          y1={232.58}
          x2={-3216.41}
          y2={172.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-29"
          x1={-3471.76}
          y1={247.71}
          x2={-3384.15}
          y2={228.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-30"
          x1={-3300.22}
          y1={36.96}
          x2={-3396.2}
          y2={219.88}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-31"
          x1={-3389.36}
          y1={248.31}
          x2={-3189.02}
          y2={188.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-32"
          x1={-3444.38}
          y1={263.45}
          x2={-3356.76}
          y2={243.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-33"
          x1={-3272.84}
          y1={52.7}
          x2={-3368.82}
          y2={235.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-7"
          x1={-3295.72}
          y1={162.99}
          x2={-3095.38}
          y2={103}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-8"
          x1={-3350.73}
          y1={178.12}
          x2={-3263.12}
          y2={158.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-9"
          x1={-3179.19}
          y1={-32.62}
          x2={-3275.17}
          y2={150.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-34"
          x1={-3416.74}
          y1={201.55}
          x2={-3216.41}
          y2={141.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-35"
          x1={-3471.76}
          y1={216.68}
          x2={-3384.15}
          y2={197.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-36"
          x1={-3300.22}
          y1={5.94}
          x2={-3396.2}
          y2={188.86}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-7"
          x1={-3389.36}
          y1={217.28}
          x2={-3189.02}
          y2={157.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-8"
          x1={-3444.38}
          y1={232.42}
          x2={-3356.76}
          y2={212.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-9"
          x1={-3272.84}
          y1={21.67}
          x2={-3368.82}
          y2={204.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-37"
          x1={-3323.1}
          y1={114.98}
          x2={-3122.76}
          y2={54.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-38"
          x1={-3378.12}
          y1={130.12}
          x2={-3290.5}
          y2={110.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-39"
          x1={-3206.58}
          y1={-80.63}
          x2={-3302.56}
          y2={102.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-10"
          x1={-3295.72}
          y1={130.72}
          x2={-3095.38}
          y2={70.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-11"
          x1={-3350.73}
          y1={145.85}
          x2={-3263.12}
          y2={126.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_12-12"
          x1={-3179.19}
          y1={-64.89}
          x2={-3275.17}
          y2={118.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_12"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-10"
          x1={-3416.74}
          y1={169.28}
          x2={-3216.41}
          y2={109.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-11"
          x1={-3471.76}
          y1={184.41}
          x2={-3384.15}
          y2={164.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_11-12"
          x1={-3300.22}
          y1={-26.33}
          x2={-3396.2}
          y2={156.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_11"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-40"
          x1={-3389.36}
          y1={185.01}
          x2={-3189.02}
          y2={125.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-41"
          x1={-3444.38}
          y1={200.15}
          x2={-3356.76}
          y2={180.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_6-42"
          x1={-3272.84}
          y1={-10.6}
          x2={-3368.82}
          y2={172.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19"
          data-name="New Gradient Swatch copy 19"
          x1={9650.39}
          y1={231.17}
          x2={9905.54}
          y2={154.77}
          gradientTransform="translate(-8990.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c10000" />
          <stop offset={0.11} stopColor="#c20707" />
          <stop offset={0.27} stopColor="#c41919" />
          <stop offset={0.46} stopColor="#c73737" />
          <stop offset={0.58} stopColor="#c94f4f" />
          <stop offset={0.72} stopColor="#d83f3f" />
          <stop offset={1} stopColor="#ff1717" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-2"
          x1={9521.07}
          y1={256.29}
          x2={9719.12}
          y2={211.89}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-3"
          x1={9756.87}
          y1={-21.56}
          x2={9652.78}
          y2={176.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-4"
          x1={9664.04}
          y1={232.42}
          x2={9874.27}
          y2={169.47}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-5"
          x1={9610.68}
          y1={252.8}
          x2={9702.16}
          y2={232.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-6"
          x1={9761.86}
          y1={34.03}
          x2={9682.44}
          y2={185.4}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-7"
          x1={9642.23}
          y1={163.79}
          x2={9959.53}
          y2={68.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-8"
          x1={9558.8}
          y1={189.96}
          x2={9638.65}
          y2={172.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-9"
          x1={9816.69}
          y1={-137.49}
          x2={9653.75}
          y2={173.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-10"
          x1={9664.43}
          y1={149.13}
          x2={9832.77}
          y2={98.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-11"
          x1={9578.84}
          y1={165.59}
          x2={9687.13}
          y2={141.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-12"
          x1={9745.66}
          y1={-17.55}
          x2={9660.35}
          y2={145.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-13"
          x1={-11680.21}
          y1={6327.75}
          x2={-11538.84}
          y2={6285.41}
          gradientTransform="rotate(180 -5495.925 3220.115)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_19-14"
          x1={9706.69}
          y1={131.52}
          x2={9703.29}
          y2={77.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_19"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_9"
          data-name="New Gradient Swatch copy 9"
          x1={-3641.77}
          y1={268.8}
          x2={-3441.43}
          y2={208.81}
          gradientTransform="matrix(-1 0 0 1 -2805.38 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#9f19ab" />
          <stop offset={0.24} stopColor="#ae25b1" />
          <stop offset={0.65} stopColor="#d744bf" />
          <stop offset={1} stopColor="#ff63ce" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_9-2"
          x1={-3696.78}
          y1={283.93}
          x2={-3609.17}
          y2={264.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_9"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_9-3"
          x1={-3525.24}
          y1={73.19}
          x2={-3621.23}
          y2={256.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_9"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_7"
          data-name="New Gradient Swatch copy 7"
          x1={-3614.46}
          y1={284.16}
          x2={-3414.12}
          y2={224.17}
          gradientTransform="matrix(-1 0 0 1 -2805.38 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#138bbd" />
          <stop offset={0.23} stopColor="#169ac7" />
          <stop offset={0.59} stopColor="#1fc3df" />
          <stop offset={1} stopColor="#2af6ff" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_7-2"
          x1={-3669.48}
          y1={299.29}
          x2={-3581.87}
          y2={279.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_7"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_7-3"
          x1={-3497.94}
          y1={88.55}
          x2={-3593.92}
          y2={271.47}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_7"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21"
          data-name="New Gradient Swatch copy 21"
          x1={-3587.38}
          y1={299.83}
          x2={-3387.04}
          y2={239.84}
          gradientTransform="matrix(-1 0 0 1 -2805.38 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} />
          <stop offset={0.21} stopColor="#0f0f0f" />
          <stop offset={0.54} stopColor="#383838" />
          <stop offset={0.99} stopColor="#797979" />
          <stop offset={1} stopColor="#7a7a7a" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-2"
          x1={-3642.39}
          y1={314.96}
          x2={-3554.78}
          y2={295.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-3"
          x1={-3470.85}
          y1={104.22}
          x2={-3566.84}
          y2={287.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-4"
          x1={-3669.23}
          y1={220.15}
          x2={-3468.89}
          y2={160.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-5"
          x1={-3724.24}
          y1={235.29}
          x2={-3636.63}
          y2={215.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-6"
          x1={-3552.7}
          y1={24.54}
          x2={-3648.69}
          y2={207.46}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2"
          data-name="New Gradient Swatch copy 2"
          x1={-3641.77}
          y1={235.82}
          x2={-3441.43}
          y2={175.84}
          gradientTransform="matrix(-1 0 0 1 -2805.38 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#7d0d38" />
          <stop offset={0.31} stopColor="#8c113e" />
          <stop offset={0.84} stopColor="#b51c4e" />
          <stop offset={1} stopColor="#c22053" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-2"
          x1={-3696.78}
          y1={250.96}
          x2={-3609.17}
          y2={231.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-3"
          x1={-3525.24}
          y1={40.21}
          x2={-3621.23}
          y2={223.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-4"
          x1={-3614.46}
          y1={251.18}
          x2={-3414.12}
          y2={191.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-5"
          x1={-3669.48}
          y1={266.32}
          x2={-3581.87}
          y2={246.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-6"
          x1={-3497.94}
          y1={55.57}
          x2={-3593.92}
          y2={238.49}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-7"
          x1={-3559.99}
          y1={345.89}
          x2={-3359.66}
          y2={285.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-8"
          x1={-3615.01}
          y1={361.02}
          x2={-3527.4}
          y2={341.38}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-9"
          x1={-3443.47}
          y1={150.28}
          x2={-3539.45}
          y2={333.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-10"
          x1={-3559.99}
          y1={314.86}
          x2={-3359.66}
          y2={254.87}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-11"
          x1={-3615.01}
          y1={330}
          x2={-3527.4}
          y2={310.36}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-12"
          x1={-3443.47}
          y1={119.25}
          x2={-3539.45}
          y2={302.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-7"
          x1={-3587.38}
          y1={266.86}
          x2={-3387.04}
          y2={206.87}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-8"
          x1={-3642.39}
          y1={281.99}
          x2={-3554.78}
          y2={262.35}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-9"
          x1={-3470.85}
          y1={71.24}
          x2={-3566.84}
          y2={254.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-10"
          x1={-3559.99}
          y1={282.59}
          x2={-3359.66}
          y2={222.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-11"
          x1={-3615.01}
          y1={297.73}
          x2={-3527.4}
          y2={278.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-12"
          x1={-3443.47}
          y1={86.98}
          x2={-3539.45}
          y2={269.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-13"
          x1={-3856.3}
          y1={392.71}
          x2={-3655.97}
          y2={332.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-14"
          x1={-3911.32}
          y1={407.84}
          x2={-3823.71}
          y2={388.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-15"
          x1={-3739.78}
          y1={197.1}
          x2={-3835.76}
          y2={380.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-16"
          x1={-3856.3}
          y1={361.68}
          x2={-3655.97}
          y2={301.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-17"
          x1={-3911.32}
          y1={376.81}
          x2={-3823.71}
          y2={357.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-18"
          x1={-3739.78}
          y1={166.07}
          x2={-3835.76}
          y2={348.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-19"
          x1={-3746.24}
          y1={296.23}
          x2={-3545.9}
          y2={236.24}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-20"
          x1={-3801.26}
          y1={311.36}
          x2={-3713.65}
          y2={291.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-21"
          x1={-3629.72}
          y1={100.62}
          x2={-3725.7}
          y2={283.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-13"
          x1={-3753}
          y1={275.1}
          x2={-3552.66}
          y2={215.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-14"
          x1={-3808.02}
          y1={290.23}
          x2={-3720.41}
          y2={270.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-15"
          x1={-3636.48}
          y1={79.49}
          x2={-3732.46}
          y2={262.41}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-22"
          x1={-3735.26}
          y1={323.29}
          x2={-3534.93}
          y2={263.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-23"
          x1={-3790.28}
          y1={338.42}
          x2={-3702.67}
          y2={318.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-24"
          x1={-3618.74}
          y1={127.68}
          x2={-3714.72}
          y2={310.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-25"
          x1={-3828.84}
          y1={408.38}
          x2={-3628.5}
          y2={348.39}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-26"
          x1={-3883.86}
          y1={423.51}
          x2={-3796.25}
          y2={403.87}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-27"
          x1={-3712.32}
          y1={212.76}
          x2={-3808.3}
          y2={395.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-16"
          x1={-3828.84}
          y1={377.35}
          x2={-3628.5}
          y2={317.36}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-17"
          x1={-3883.86}
          y1={392.48}
          x2={-3796.25}
          y2={372.84}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-18"
          x1={-3712.32}
          y1={181.74}
          x2={-3808.3}
          y2={364.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_3"
          data-name="New Gradient Swatch copy 3"
          x1={-3801.54}
          y1={423.74}
          x2={-3601.2}
          y2={363.75}
          gradientTransform="matrix(-1 0 0 1 -2805.38 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#850000" />
          <stop offset={0.3} stopColor="#7c070f" />
          <stop offset={0.81} stopColor="#631b38" />
          <stop offset={1} stopColor="#592348" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_3-2"
          x1={-3856.55}
          y1={438.87}
          x2={-3768.94}
          y2={419.23}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_3"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_3-3"
          x1={-3685.01}
          y1={228.12}
          x2={-3781}
          y2={411.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_3"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-28"
          x1={-3653.43}
          y1={400.85}
          x2={-3453.09}
          y2={340.86}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-29"
          x1={-3708.44}
          y1={415.98}
          x2={-3620.83}
          y2={396.34}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-30"
          x1={-3536.9}
          y1={205.24}
          x2={-3632.88}
          y2={388.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-31"
          x1={-3774.45}
          y1={439.41}
          x2={-3574.12}
          y2={379.42}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-32"
          x1={-3829.47}
          y1={454.54}
          x2={-3741.86}
          y2={434.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-33"
          x1={-3657.93}
          y1={243.8}
          x2={-3753.91}
          y2={426.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-34"
          x1={-3747.07}
          y1={455.15}
          x2={-3546.73}
          y2={395.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-35"
          x1={-3802.08}
          y1={470.28}
          x2={-3714.47}
          y2={450.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-36"
          x1={-3630.55}
          y1={259.53}
          x2={-3726.53}
          y2={442.45}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-37"
          x1={-3707.89}
          y1={338.46}
          x2={-3507.56}
          y2={278.47}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-38"
          x1={-3762.91}
          y1={353.59}
          x2={-3675.3}
          y2={333.95}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-39"
          x1={-3591.37}
          y1={142.85}
          x2={-3687.35}
          y2={325.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-40"
          x1={-3680.81}
          y1={354.13}
          x2={-3480.47}
          y2={294.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-41"
          x1={-3735.82}
          y1={369.26}
          x2={-3648.21}
          y2={349.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-42"
          x1={-3564.29}
          y1={158.52}
          x2={-3660.27}
          y2={341.44}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-19"
          x1={-3653.43}
          y1={369.87}
          x2={-3453.09}
          y2={309.88}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-20"
          x1={-3708.44}
          y1={385}
          x2={-3620.83}
          y2={365.36}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-21"
          x1={-3536.9}
          y1={174.26}
          x2={-3632.88}
          y2={357.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-22"
          x1={-3801.54}
          y1={392.71}
          x2={-3601.2}
          y2={332.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-23"
          x1={-3856.55}
          y1={407.84}
          x2={-3768.94}
          y2={388.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-24"
          x1={-3685.01}
          y1={197.1}
          x2={-3781}
          y2={380.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-43"
          x1={-3774.45}
          y1={408.38}
          x2={-3574.12}
          y2={348.39}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-44"
          x1={-3829.47}
          y1={423.51}
          x2={-3741.86}
          y2={403.87}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_2-45"
          x1={-3657.93}
          y1={212.77}
          x2={-3753.91}
          y2={395.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_2"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-25"
          x1={-3747.07}
          y1={424.12}
          x2={-3546.73}
          y2={364.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-26"
          x1={-3802.08}
          y1={439.25}
          x2={-3714.47}
          y2={419.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_21-27"
          x1={-3630.55}
          y1={228.51}
          x2={-3726.53}
          y2={411.43}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_21"
        />
        <linearGradient
          id="prefix__Linear_Gradient_1"
          data-name="Linear Gradient 1"
          x1={-3717.76}
          y1={332.81}
          x2={-3743.73}
          y2={280}
          gradientTransform="matrix(-1 0 0 1 -2877.72 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_22"
          data-name="New Gradient Swatch copy 22"
          x1={-3687.44}
          y1={303.46}
          x2={-3487.1}
          y2={243.48}
          gradientTransform="matrix(-1 0 0 1 -2805.38 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#ab1515" />
          <stop offset={0.27} stopColor="#ba1111" />
          <stop offset={0.72} stopColor="#e30707" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_22-2"
          x1={-3742.46}
          y1={318.6}
          x2={-3654.85}
          y2={298.96}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_22"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_22-3"
          x1={-3570.92}
          y1={107.85}
          x2={-3666.9}
          y2={290.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_22"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-5"
          x1={9984.32}
          y1={393.55}
          x2={10007.6}
          y2={457.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-6"
          x1={9963.12}
          y1={460.63}
          x2={10272.31}
          y2={368.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4"
          data-name="New Gradient Swatch copy 4"
          x1={9367.62}
          y1={164.99}
          x2={9373.56}
          y2={164.99}
          gradientTransform="translate(-8990.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} stopColor="#b0b0b0" />
          <stop offset={0.05} stopColor="#bababa" />
          <stop offset={0.13} stopColor="#d5d5d5" />
          <stop offset={0.22} stopColor="#fff" />
          <stop offset={0.26} stopColor="#f5f5f5" />
          <stop offset={0.31} stopColor="#dbdbdb" />
          <stop offset={0.36} stopColor="#c2c2c2" />
          <stop offset={0.42} stopColor="#a9a9a9" />
          <stop offset={0.49} stopColor="#919191" />
          <stop offset={0.56} stopColor="#828282" />
          <stop offset={0.61} stopColor="#7d7d7d" />
          <stop offset={0.79} stopColor="#888" />
          <stop offset={1} stopColor="#9a9a9a" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-7"
          x1={9368.22}
          y1={150.45}
          x2={9373}
          y2={153.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-2"
          x1={9375.28}
          y1={177.69}
          x2={9381.21}
          y2={177.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-8"
          x1={9375.88}
          y1={170.97}
          x2={9380.65}
          y2={173.73}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-3"
          x1={9371.97}
          y1={167.49}
          x2={9377.9}
          y2={167.49}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-9"
          x1={9372.57}
          y1={152.95}
          x2={9377.34}
          y2={155.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-4"
          x1={9379.62}
          y1={180.19}
          x2={9385.56}
          y2={180.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-10"
          x1={9380.22}
          y1={173.47}
          x2={9384.99}
          y2={176.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-5"
          x1={9376.31}
          y1={169.99}
          x2={9382.25}
          y2={169.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-11"
          x1={9376.91}
          y1={155.45}
          x2={9381.68}
          y2={158.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-6"
          x1={9383.96}
          y1={182.69}
          x2={9389.9}
          y2={182.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-12"
          x1={9384.56}
          y1={175.97}
          x2={9389.34}
          y2={178.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-7"
          x1={9380.65}
          y1={172.49}
          x2={9386.59}
          y2={172.49}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-13"
          x1={9381.25}
          y1={157.95}
          x2={9386.02}
          y2={160.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-8"
          x1={9388.3}
          y1={185.19}
          x2={9394.24}
          y2={185.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-14"
          x1={9388.9}
          y1={178.46}
          x2={9393.68}
          y2={181.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-9"
          x1={9384.99}
          y1={174.98}
          x2={9390.93}
          y2={174.98}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-15"
          x1={9385.59}
          y1={160.45}
          x2={9390.37}
          y2={163.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-10"
          x1={9392.64}
          y1={187.68}
          x2={9398.58}
          y2={187.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-16"
          x1={9393.24}
          y1={180.96}
          x2={9398.02}
          y2={183.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-11"
          x1={9389.33}
          y1={177.48}
          x2={9395.27}
          y2={177.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-17"
          x1={9389.93}
          y1={162.94}
          x2={9394.71}
          y2={165.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-12"
          x1={9396.98}
          y1={190.18}
          x2={9402.92}
          y2={190.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-18"
          x1={9397.58}
          y1={183.46}
          x2={9402.36}
          y2={186.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-13"
          x1={9393.67}
          y1={179.98}
          x2={9399.61}
          y2={179.98}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-19"
          x1={9394.27}
          y1={165.44}
          x2={9399.05}
          y2={168.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-14"
          x1={9401.33}
          y1={192.68}
          x2={9407.26}
          y2={192.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-20"
          x1={9401.93}
          y1={185.96}
          x2={9406.7}
          y2={188.72}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-15"
          x1={9398.01}
          y1={182.48}
          x2={9403.95}
          y2={182.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-21"
          x1={9398.62}
          y1={167.94}
          x2={9403.39}
          y2={170.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-16"
          x1={9405.67}
          y1={195.18}
          x2={9411.61}
          y2={195.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-22"
          x1={9406.27}
          y1={188.46}
          x2={9411.04}
          y2={191.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-17"
          x1={9402.36}
          y1={184.98}
          x2={9408.29}
          y2={184.98}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-23"
          x1={9402.96}
          y1={170.44}
          x2={9407.73}
          y2={173.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-18"
          x1={9410.01}
          y1={197.68}
          x2={9415.95}
          y2={197.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-24"
          x1={9410.61}
          y1={190.96}
          x2={9415.39}
          y2={193.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-19"
          x1={9406.7}
          y1={187.48}
          x2={9412.64}
          y2={187.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-25"
          x1={9407.3}
          y1={172.94}
          x2={9412.07}
          y2={175.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-20"
          x1={9414.35}
          y1={200.18}
          x2={9420.29}
          y2={200.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-26"
          x1={9414.95}
          y1={193.45}
          x2={9419.73}
          y2={196.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-21"
          x1={9411.04}
          y1={189.97}
          x2={9416.98}
          y2={189.97}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-27"
          x1={9411.64}
          y1={175.44}
          x2={9416.42}
          y2={178.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-22"
          x1={9418.69}
          y1={202.67}
          x2={9424.63}
          y2={202.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-28"
          x1={9419.29}
          y1={195.95}
          x2={9424.07}
          y2={198.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-23"
          x1={9415.38}
          y1={192.47}
          x2={9421.32}
          y2={192.47}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-29"
          x1={9415.98}
          y1={177.93}
          x2={9420.76}
          y2={180.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-24"
          x1={9423.03}
          y1={205.17}
          x2={9428.97}
          y2={205.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-30"
          x1={9423.63}
          y1={198.45}
          x2={9428.41}
          y2={201.21}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-25"
          x1={9419.72}
          y1={194.97}
          x2={9425.66}
          y2={194.97}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-31"
          x1={9420.32}
          y1={180.43}
          x2={9425.1}
          y2={183.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-26"
          x1={9427.38}
          y1={207.67}
          x2={9433.31}
          y2={207.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-32"
          x1={9427.98}
          y1={200.95}
          x2={9432.75}
          y2={203.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-27"
          x1={9424.06}
          y1={197.47}
          x2={9430}
          y2={197.47}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-33"
          x1={9424.66}
          y1={182.93}
          x2={9429.44}
          y2={185.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-28"
          x1={9431.72}
          y1={210.17}
          x2={9437.66}
          y2={210.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-34"
          x1={9432.32}
          y1={203.45}
          x2={9437.09}
          y2={206.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-29"
          x1={9428.41}
          y1={199.97}
          x2={9434.34}
          y2={199.97}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-35"
          x1={9429.01}
          y1={185.43}
          x2={9433.78}
          y2={188.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-30"
          x1={9436.06}
          y1={212.67}
          x2={9442}
          y2={212.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-36"
          x1={9436.66}
          y1={205.95}
          x2={9441.43}
          y2={208.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-31"
          x1={9432.75}
          y1={202.47}
          x2={9438.69}
          y2={202.47}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-37"
          x1={9433.35}
          y1={187.93}
          x2={9438.12}
          y2={190.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-32"
          x1={9440.4}
          y1={215.17}
          x2={9446.34}
          y2={215.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-38"
          x1={9441}
          y1={208.44}
          x2={9445.78}
          y2={211.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-33"
          x1={9437.09}
          y1={204.96}
          x2={9443.03}
          y2={204.96}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-39"
          x1={9437.69}
          y1={190.43}
          x2={9442.47}
          y2={193.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-34"
          x1={9444.74}
          y1={217.66}
          x2={9450.68}
          y2={217.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-40"
          x1={9445.34}
          y1={210.94}
          x2={9450.12}
          y2={213.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-35"
          x1={9441.43}
          y1={207.46}
          x2={9447.37}
          y2={207.46}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-41"
          x1={9442.03}
          y1={192.92}
          x2={9446.81}
          y2={195.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-36"
          x1={9449.08}
          y1={220.16}
          x2={9455.02}
          y2={220.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-42"
          x1={9449.68}
          y1={213.44}
          x2={9454.46}
          y2={216.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-37"
          x1={9445.77}
          y1={209.96}
          x2={9451.71}
          y2={209.96}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-43"
          x1={9446.37}
          y1={195.42}
          x2={9451.15}
          y2={198.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-38"
          x1={9453.43}
          y1={222.66}
          x2={9459.36}
          y2={222.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-44"
          x1={9454.03}
          y1={215.94}
          x2={9458.8}
          y2={218.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-39"
          x1={9450.11}
          y1={212.46}
          x2={9456.05}
          y2={212.46}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-45"
          x1={9450.71}
          y1={197.92}
          x2={9455.49}
          y2={200.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-40"
          x1={9457.77}
          y1={225.16}
          x2={9463.71}
          y2={225.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-46"
          x1={9458.37}
          y1={218.44}
          x2={9463.14}
          y2={221.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-41"
          x1={9454.46}
          y1={214.96}
          x2={9460.39}
          y2={214.96}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-47"
          x1={9455.06}
          y1={200.42}
          x2={9459.83}
          y2={203.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-42"
          x1={9462.11}
          y1={227.66}
          x2={9468.05}
          y2={227.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-48"
          x1={9462.71}
          y1={220.94}
          x2={9467.48}
          y2={223.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-43"
          x1={9458.8}
          y1={217.46}
          x2={9464.74}
          y2={217.46}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-49"
          x1={9459.4}
          y1={202.92}
          x2={9464.17}
          y2={205.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-44"
          x1={9466.45}
          y1={230.16}
          x2={9472.39}
          y2={230.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-50"
          x1={9467.05}
          y1={223.43}
          x2={9471.83}
          y2={226.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-45"
          x1={9463.14}
          y1={219.95}
          x2={9469.08}
          y2={219.95}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-51"
          x1={9463.74}
          y1={205.42}
          x2={9468.51}
          y2={208.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-46"
          x1={9470.79}
          y1={232.65}
          x2={9476.73}
          y2={232.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-52"
          x1={9471.39}
          y1={225.93}
          x2={9476.17}
          y2={228.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-47"
          x1={9467.48}
          y1={222.45}
          x2={9473.42}
          y2={222.45}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-53"
          x1={9468.08}
          y1={207.91}
          x2={9472.86}
          y2={210.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-48"
          x1={9475.13}
          y1={235.15}
          x2={9481.07}
          y2={235.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-54"
          x1={9475.73}
          y1={228.43}
          x2={9480.51}
          y2={231.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-49"
          x1={9471.82}
          y1={224.95}
          x2={9477.76}
          y2={224.95}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-55"
          x1={9472.42}
          y1={210.41}
          x2={9477.2}
          y2={213.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-50"
          x1={9479.47}
          y1={237.65}
          x2={9485.41}
          y2={237.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-56"
          x1={9480.08}
          y1={230.93}
          x2={9484.85}
          y2={233.69}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-51"
          x1={9476.16}
          y1={227.45}
          x2={9482.1}
          y2={227.45}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-57"
          x1={9476.76}
          y1={212.91}
          x2={9481.54}
          y2={215.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-52"
          x1={9483.82}
          y1={240.15}
          x2={9489.75}
          y2={240.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-58"
          x1={9484.42}
          y1={233.43}
          x2={9489.19}
          y2={236.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-53"
          x1={9480.5}
          y1={229.95}
          x2={9486.44}
          y2={229.95}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-59"
          x1={9481.11}
          y1={215.41}
          x2={9485.88}
          y2={218.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-54"
          x1={9488.16}
          y1={242.65}
          x2={9494.1}
          y2={242.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-60"
          x1={9488.76}
          y1={235.93}
          x2={9493.53}
          y2={238.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-55"
          x1={9484.85}
          y1={232.45}
          x2={9490.78}
          y2={232.45}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-61"
          x1={9485.45}
          y1={217.91}
          x2={9490.22}
          y2={220.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-56"
          x1={9492.5}
          y1={245.15}
          x2={9498.44}
          y2={245.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-62"
          x1={9493.1}
          y1={238.42}
          x2={9497.88}
          y2={241.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-57"
          x1={9489.19}
          y1={234.94}
          x2={9495.13}
          y2={234.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-63"
          x1={9489.79}
          y1={220.41}
          x2={9494.56}
          y2={223.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-58"
          x1={9496.84}
          y1={247.64}
          x2={9502.78}
          y2={247.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-64"
          x1={9497.44}
          y1={240.92}
          x2={9502.22}
          y2={243.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-59"
          x1={9493.53}
          y1={237.44}
          x2={9499.47}
          y2={237.44}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-65"
          x1={9494.13}
          y1={222.9}
          x2={9498.91}
          y2={225.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-60"
          x1={9501.18}
          y1={250.14}
          x2={9507.12}
          y2={250.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-66"
          x1={9501.78}
          y1={243.42}
          x2={9506.56}
          y2={246.18}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-61"
          x1={9497.87}
          y1={239.94}
          x2={9503.81}
          y2={239.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-67"
          x1={9498.47}
          y1={225.4}
          x2={9503.25}
          y2={228.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-62"
          x1={9505.52}
          y1={252.64}
          x2={9511.46}
          y2={252.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-68"
          x1={9506.12}
          y1={245.92}
          x2={9510.9}
          y2={248.68}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-63"
          x1={9502.21}
          y1={242.44}
          x2={9508.15}
          y2={242.44}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-69"
          x1={9502.81}
          y1={227.9}
          x2={9507.59}
          y2={230.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-64"
          x1={9509.87}
          y1={255.14}
          x2={9515.8}
          y2={255.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-70"
          x1={9510.47}
          y1={248.42}
          x2={9515.24}
          y2={251.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-65"
          x1={9506.55}
          y1={244.94}
          x2={9512.49}
          y2={244.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-71"
          x1={9507.15}
          y1={230.4}
          x2={9511.93}
          y2={233.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-66"
          x1={9514.21}
          y1={257.64}
          x2={9520.15}
          y2={257.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-72"
          x1={9514.81}
          y1={250.92}
          x2={9519.58}
          y2={253.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-67"
          x1={9510.9}
          y1={247.44}
          x2={9516.83}
          y2={247.44}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-73"
          x1={9511.5}
          y1={232.9}
          x2={9516.27}
          y2={235.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-68"
          x1={9518.55}
          y1={260.14}
          x2={9524.49}
          y2={260.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-74"
          x1={9519.15}
          y1={253.41}
          x2={9523.92}
          y2={256.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-69"
          x1={9515.24}
          y1={249.93}
          x2={9521.18}
          y2={249.93}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-75"
          x1={9515.84}
          y1={235.4}
          x2={9520.61}
          y2={238.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-70"
          x1={9522.89}
          y1={262.63}
          x2={9528.83}
          y2={262.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-76"
          x1={9523.49}
          y1={255.91}
          x2={9528.27}
          y2={258.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-71"
          x1={9519.58}
          y1={252.43}
          x2={9525.52}
          y2={252.43}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-77"
          x1={9520.18}
          y1={237.89}
          x2={9524.96}
          y2={240.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-72"
          x1={9527.23}
          y1={265.13}
          x2={9533.17}
          y2={265.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-78"
          x1={9527.83}
          y1={258.41}
          x2={9532.61}
          y2={261.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-73"
          x1={9523.92}
          y1={254.93}
          x2={9529.86}
          y2={254.93}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-79"
          x1={9524.52}
          y1={240.39}
          x2={9529.3}
          y2={243.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-74"
          x1={9531.57}
          y1={267.63}
          x2={9537.51}
          y2={267.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-80"
          x1={9532.17}
          y1={260.91}
          x2={9536.95}
          y2={263.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-75"
          x1={9528.26}
          y1={257.43}
          x2={9534.2}
          y2={257.43}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-81"
          x1={9528.86}
          y1={242.89}
          x2={9533.64}
          y2={245.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-76"
          x1={9535.92}
          y1={270.13}
          x2={9541.85}
          y2={270.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-82"
          x1={9536.52}
          y1={263.41}
          x2={9541.29}
          y2={266.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-77"
          x1={9532.6}
          y1={259.93}
          x2={9538.54}
          y2={259.93}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-83"
          x1={9533.2}
          y1={245.39}
          x2={9537.98}
          y2={248.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-78"
          x1={9540.26}
          y1={272.63}
          x2={9546.2}
          y2={272.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-84"
          x1={9540.86}
          y1={265.91}
          x2={9545.63}
          y2={268.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-79"
          x1={9536.95}
          y1={262.43}
          x2={9542.88}
          y2={262.43}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-85"
          x1={9537.55}
          y1={247.89}
          x2={9542.32}
          y2={250.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-80"
          x1={9544.6}
          y1={275.13}
          x2={9550.54}
          y2={275.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-86"
          x1={9545.2}
          y1={268.4}
          x2={9549.97}
          y2={271.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-81"
          x1={9541.29}
          y1={264.92}
          x2={9547.23}
          y2={264.92}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-87"
          x1={9541.89}
          y1={250.39}
          x2={9546.66}
          y2={253.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-82"
          x1={9548.94}
          y1={277.62}
          x2={9554.88}
          y2={277.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-88"
          x1={9549.54}
          y1={270.9}
          x2={9554.32}
          y2={273.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-83"
          x1={9545.63}
          y1={267.42}
          x2={9551.57}
          y2={267.42}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-89"
          x1={9546.23}
          y1={252.88}
          x2={9551}
          y2={255.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-84"
          x1={9553.28}
          y1={280.12}
          x2={9559.22}
          y2={280.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-90"
          x1={9553.88}
          y1={273.4}
          x2={9558.66}
          y2={276.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-85"
          x1={9549.97}
          y1={269.92}
          x2={9555.91}
          y2={269.92}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-91"
          x1={9550.57}
          y1={255.38}
          x2={9555.35}
          y2={258.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-86"
          x1={9557.62}
          y1={282.62}
          x2={9563.56}
          y2={282.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-92"
          x1={9558.22}
          y1={275.9}
          x2={9563}
          y2={278.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-87"
          x1={9554.31}
          y1={272.42}
          x2={9560.25}
          y2={272.42}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-93"
          x1={9554.91}
          y1={257.88}
          x2={9559.69}
          y2={260.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-88"
          x1={9561.96}
          y1={285.12}
          x2={9567.9}
          y2={285.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-94"
          x1={9562.57}
          y1={278.4}
          x2={9567.34}
          y2={281.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-89"
          x1={9558.65}
          y1={274.92}
          x2={9564.59}
          y2={274.92}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-95"
          x1={9559.25}
          y1={260.38}
          x2={9564.03}
          y2={263.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-90"
          x1={9566.31}
          y1={287.62}
          x2={9572.24}
          y2={287.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-96"
          x1={9566.91}
          y1={280.9}
          x2={9571.68}
          y2={283.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-91"
          x1={9563}
          y1={277.42}
          x2={9568.93}
          y2={277.42}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-97"
          x1={9563.6}
          y1={262.88}
          x2={9568.37}
          y2={265.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-92"
          x1={9570.65}
          y1={290.12}
          x2={9576.59}
          y2={290.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-98"
          x1={9571.25}
          y1={283.39}
          x2={9576.02}
          y2={286.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-93"
          x1={9567.34}
          y1={279.91}
          x2={9573.28}
          y2={279.91}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-99"
          x1={9567.94}
          y1={265.38}
          x2={9572.71}
          y2={268.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-94"
          x1={9574.99}
          y1={292.61}
          x2={9580.93}
          y2={292.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-100"
          x1={9575.59}
          y1={285.89}
          x2={9580.37}
          y2={288.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-95"
          x1={9571.68}
          y1={282.41}
          x2={9577.62}
          y2={282.41}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-101"
          x1={9572.28}
          y1={267.87}
          x2={9577.05}
          y2={270.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-96"
          x1={9579.33}
          y1={295.11}
          x2={9585.27}
          y2={295.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-102"
          x1={9579.93}
          y1={288.39}
          x2={9584.71}
          y2={291.15}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-97"
          x1={9576.02}
          y1={284.91}
          x2={9581.96}
          y2={284.91}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-103"
          x1={9576.62}
          y1={270.37}
          x2={9581.4}
          y2={273.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-98"
          x1={9583.67}
          y1={297.61}
          x2={9589.61}
          y2={297.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-104"
          x1={9584.27}
          y1={290.89}
          x2={9589.05}
          y2={293.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-99"
          x1={9580.36}
          y1={287.41}
          x2={9586.3}
          y2={287.41}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-105"
          x1={9580.96}
          y1={272.87}
          x2={9585.74}
          y2={275.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-100"
          x1={9588.01}
          y1={300.11}
          x2={9593.95}
          y2={300.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-106"
          x1={9588.61}
          y1={293.39}
          x2={9593.39}
          y2={296.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-101"
          x1={9584.7}
          y1={289.91}
          x2={9590.64}
          y2={289.91}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-107"
          x1={9585.3}
          y1={275.37}
          x2={9590.08}
          y2={278.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-102"
          x1={9592.36}
          y1={302.61}
          x2={9598.29}
          y2={302.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-108"
          x1={9592.96}
          y1={295.89}
          x2={9597.73}
          y2={298.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-103"
          x1={9589.04}
          y1={292.41}
          x2={9594.98}
          y2={292.41}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-109"
          x1={9589.64}
          y1={277.87}
          x2={9594.42}
          y2={280.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-104"
          x1={9596.7}
          y1={305.11}
          x2={9602.64}
          y2={305.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-110"
          x1={9597.3}
          y1={298.38}
          x2={9602.07}
          y2={301.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-105"
          x1={9593.39}
          y1={294.9}
          x2={9599.32}
          y2={294.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-111"
          x1={9593.99}
          y1={280.37}
          x2={9598.76}
          y2={283.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-106"
          x1={9601.04}
          y1={307.6}
          x2={9606.98}
          y2={307.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-112"
          x1={9601.64}
          y1={300.88}
          x2={9606.42}
          y2={303.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-107"
          x1={9597.73}
          y1={297.4}
          x2={9603.67}
          y2={297.4}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-113"
          x1={9598.33}
          y1={282.86}
          x2={9603.1}
          y2={285.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-108"
          x1={9605.38}
          y1={310.1}
          x2={9611.32}
          y2={310.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-114"
          x1={9605.98}
          y1={303.38}
          x2={9610.76}
          y2={306.14}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-109"
          x1={9602.07}
          y1={299.9}
          x2={9608.01}
          y2={299.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-115"
          x1={9602.67}
          y1={285.36}
          x2={9607.45}
          y2={288.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-110"
          x1={9609.72}
          y1={312.6}
          x2={9615.66}
          y2={312.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-116"
          x1={9610.32}
          y1={305.88}
          x2={9615.1}
          y2={308.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-111"
          x1={9606.41}
          y1={302.4}
          x2={9612.35}
          y2={302.4}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-117"
          x1={9607.01}
          y1={287.86}
          x2={9611.79}
          y2={290.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-112"
          x1={9614.06}
          y1={315.1}
          x2={9620}
          y2={315.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-118"
          x1={9614.66}
          y1={308.38}
          x2={9619.44}
          y2={311.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-113"
          x1={9610.75}
          y1={304.9}
          x2={9616.69}
          y2={304.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-119"
          x1={9611.35}
          y1={290.36}
          x2={9616.13}
          y2={293.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-114"
          x1={9618.41}
          y1={317.6}
          x2={9624.34}
          y2={317.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-120"
          x1={9619.01}
          y1={310.88}
          x2={9623.78}
          y2={313.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-115"
          x1={9615.09}
          y1={307.4}
          x2={9621.03}
          y2={307.4}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-121"
          x1={9615.69}
          y1={292.86}
          x2={9620.47}
          y2={295.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-116"
          x1={9622.75}
          y1={320.1}
          x2={9628.69}
          y2={320.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-122"
          x1={9623.35}
          y1={313.37}
          x2={9628.12}
          y2={316.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-117"
          x1={9619.44}
          y1={309.89}
          x2={9625.37}
          y2={309.89}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-123"
          x1={9620.04}
          y1={295.36}
          x2={9624.81}
          y2={298.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-118"
          x1={9627.09}
          y1={322.59}
          x2={9633.03}
          y2={322.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-124"
          x1={9627.69}
          y1={315.87}
          x2={9632.46}
          y2={318.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-119"
          x1={9623.78}
          y1={312.39}
          x2={9629.72}
          y2={312.39}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-125"
          x1={9624.38}
          y1={297.85}
          x2={9629.15}
          y2={300.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-120"
          x1={9631.43}
          y1={325.09}
          x2={9637.37}
          y2={325.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-126"
          x1={9632.03}
          y1={318.37}
          x2={9636.81}
          y2={321.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-121"
          x1={9628.12}
          y1={314.89}
          x2={9634.06}
          y2={314.89}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-127"
          x1={9628.72}
          y1={300.35}
          x2={9633.5}
          y2={303.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-122"
          x1={9635.77}
          y1={327.59}
          x2={9641.71}
          y2={327.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-128"
          x1={9636.37}
          y1={320.87}
          x2={9641.15}
          y2={323.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-123"
          x1={9632.46}
          y1={317.39}
          x2={9638.4}
          y2={317.39}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-129"
          x1={9633.06}
          y1={302.85}
          x2={9637.84}
          y2={305.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-124"
          x1={9640.11}
          y1={330.09}
          x2={9646.05}
          y2={330.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-130"
          x1={9640.71}
          y1={323.37}
          x2={9645.49}
          y2={326.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-125"
          x1={9636.8}
          y1={319.89}
          x2={9642.74}
          y2={319.89}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-131"
          x1={9637.4}
          y1={305.35}
          x2={9642.18}
          y2={308.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-126"
          x1={9644.46}
          y1={332.59}
          x2={9650.39}
          y2={332.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-132"
          x1={9645.06}
          y1={325.87}
          x2={9649.83}
          y2={328.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-127"
          x1={9641.14}
          y1={322.39}
          x2={9647.08}
          y2={322.39}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-133"
          x1={9641.74}
          y1={307.85}
          x2={9646.52}
          y2={310.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-128"
          x1={9648.8}
          y1={335.09}
          x2={9654.73}
          y2={335.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-134"
          x1={9649.4}
          y1={328.36}
          x2={9654.17}
          y2={331.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-129"
          x1={9645.49}
          y1={324.88}
          x2={9651.42}
          y2={324.88}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-135"
          x1={9646.09}
          y1={310.35}
          x2={9650.86}
          y2={313.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-130"
          x1={9653.14}
          y1={337.58}
          x2={9659.08}
          y2={337.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-136"
          x1={9653.74}
          y1={330.86}
          x2={9658.51}
          y2={333.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-131"
          x1={9649.83}
          y1={327.38}
          x2={9655.77}
          y2={327.38}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-137"
          x1={9650.43}
          y1={312.84}
          x2={9655.2}
          y2={315.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-132"
          x1={9657.48}
          y1={340.08}
          x2={9663.42}
          y2={340.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-138"
          x1={9658.08}
          y1={333.36}
          x2={9662.86}
          y2={336.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-133"
          x1={9654.17}
          y1={329.88}
          x2={9660.11}
          y2={329.88}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-139"
          x1={9654.77}
          y1={315.34}
          x2={9659.54}
          y2={318.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-134"
          x1={9661.82}
          y1={342.58}
          x2={9667.76}
          y2={342.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-140"
          x1={9662.42}
          y1={335.86}
          x2={9667.2}
          y2={338.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-135"
          x1={9658.51}
          y1={332.38}
          x2={9664.45}
          y2={332.38}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-141"
          x1={9659.11}
          y1={317.84}
          x2={9663.89}
          y2={320.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-136"
          x1={9666.16}
          y1={345.08}
          x2={9672.1}
          y2={345.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-142"
          x1={9666.76}
          y1={338.36}
          x2={9671.54}
          y2={341.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-137"
          x1={9662.85}
          y1={334.88}
          x2={9668.79}
          y2={334.88}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-143"
          x1={9663.45}
          y1={320.34}
          x2={9668.23}
          y2={323.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-138"
          x1={9670.5}
          y1={347.58}
          x2={9676.44}
          y2={347.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-144"
          x1={9671.1}
          y1={340.86}
          x2={9675.88}
          y2={343.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-139"
          x1={9667.19}
          y1={337.38}
          x2={9673.13}
          y2={337.38}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-145"
          x1={9667.79}
          y1={322.84}
          x2={9672.57}
          y2={325.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-140"
          x1={9674.85}
          y1={350.08}
          x2={9680.78}
          y2={350.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-146"
          x1={9675.45}
          y1={343.35}
          x2={9680.22}
          y2={346.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-141"
          x1={9671.53}
          y1={339.87}
          x2={9677.47}
          y2={339.87}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-147"
          x1={9672.14}
          y1={325.34}
          x2={9676.91}
          y2={328.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-142"
          x1={9679.19}
          y1={352.57}
          x2={9685.13}
          y2={352.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-148"
          x1={9679.79}
          y1={345.85}
          x2={9684.56}
          y2={348.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-143"
          x1={9675.88}
          y1={342.37}
          x2={9681.81}
          y2={342.37}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-149"
          x1={9676.48}
          y1={327.83}
          x2={9681.25}
          y2={330.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-144"
          x1={9683.53}
          y1={355.07}
          x2={9689.47}
          y2={355.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-150"
          x1={9684.13}
          y1={348.35}
          x2={9688.91}
          y2={351.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-145"
          x1={9680.22}
          y1={344.87}
          x2={9686.16}
          y2={344.87}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-151"
          x1={9680.82}
          y1={330.33}
          x2={9685.59}
          y2={333.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-146"
          x1={9687.87}
          y1={357.57}
          x2={9693.81}
          y2={357.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-152"
          x1={9688.47}
          y1={350.85}
          x2={9693.25}
          y2={353.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-147"
          x1={9684.56}
          y1={347.37}
          x2={9690.5}
          y2={347.37}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-153"
          x1={9685.16}
          y1={332.83}
          x2={9689.94}
          y2={335.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-148"
          x1={9692.21}
          y1={360.07}
          x2={9698.15}
          y2={360.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-154"
          x1={9692.81}
          y1={353.35}
          x2={9697.59}
          y2={356.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-149"
          x1={9688.9}
          y1={349.87}
          x2={9694.84}
          y2={349.87}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-155"
          x1={9689.5}
          y1={335.33}
          x2={9694.28}
          y2={338.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-150"
          x1={9696.55}
          y1={362.57}
          x2={9702.49}
          y2={362.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-156"
          x1={9697.15}
          y1={355.85}
          x2={9701.93}
          y2={358.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-151"
          x1={9693.24}
          y1={352.37}
          x2={9699.18}
          y2={352.37}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-157"
          x1={9693.84}
          y1={337.83}
          x2={9698.62}
          y2={340.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-152"
          x1={9700.9}
          y1={365.07}
          x2={9706.83}
          y2={365.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-158"
          x1={9701.5}
          y1={358.34}
          x2={9706.27}
          y2={361.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-153"
          x1={9697.58}
          y1={354.86}
          x2={9703.52}
          y2={354.86}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-159"
          x1={9698.18}
          y1={340.33}
          x2={9702.96}
          y2={343.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-154"
          x1={9705.24}
          y1={367.56}
          x2={9711.18}
          y2={367.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-160"
          x1={9705.84}
          y1={360.84}
          x2={9710.61}
          y2={363.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-155"
          x1={9701.93}
          y1={357.36}
          x2={9707.86}
          y2={357.36}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-161"
          x1={9702.53}
          y1={342.82}
          x2={9707.3}
          y2={345.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-156"
          x1={9709.58}
          y1={370.06}
          x2={9715.52}
          y2={370.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-162"
          x1={9710.18}
          y1={363.34}
          x2={9714.95}
          y2={366.1}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-157"
          x1={9706.27}
          y1={359.86}
          x2={9712.21}
          y2={359.86}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-163"
          x1={9706.87}
          y1={345.32}
          x2={9711.64}
          y2={348.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-158"
          x1={9713.92}
          y1={372.56}
          x2={9719.86}
          y2={372.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-164"
          x1={9714.52}
          y1={365.84}
          x2={9719.3}
          y2={368.6}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-159"
          x1={9710.61}
          y1={362.36}
          x2={9716.55}
          y2={362.36}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-165"
          x1={9711.21}
          y1={347.82}
          x2={9715.99}
          y2={350.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-160"
          x1={9718.26}
          y1={375.06}
          x2={9724.2}
          y2={375.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-166"
          x1={9718.86}
          y1={368.34}
          x2={9723.64}
          y2={371.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-161"
          x1={9714.95}
          y1={364.86}
          x2={9720.89}
          y2={364.86}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-167"
          x1={9715.55}
          y1={350.32}
          x2={9720.33}
          y2={353.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-162"
          x1={9722.6}
          y1={377.56}
          x2={9728.54}
          y2={377.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-168"
          x1={9723.2}
          y1={370.84}
          x2={9727.98}
          y2={373.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-163"
          x1={9719.29}
          y1={367.36}
          x2={9725.23}
          y2={367.36}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-169"
          x1={9719.89}
          y1={352.82}
          x2={9724.67}
          y2={355.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-164"
          x1={9726.95}
          y1={380.06}
          x2={9732.88}
          y2={380.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-170"
          x1={9727.55}
          y1={373.33}
          x2={9732.32}
          y2={376.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-165"
          x1={9723.63}
          y1={369.85}
          x2={9729.57}
          y2={369.85}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-171"
          x1={9724.23}
          y1={355.32}
          x2={9729.01}
          y2={358.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-166"
          x1={9731.29}
          y1={382.55}
          x2={9737.23}
          y2={382.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-172"
          x1={9731.89}
          y1={375.83}
          x2={9736.66}
          y2={378.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-167"
          x1={9727.98}
          y1={372.35}
          x2={9733.91}
          y2={372.35}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-173"
          x1={9728.58}
          y1={357.81}
          x2={9733.35}
          y2={360.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-168"
          x1={9735.63}
          y1={385.05}
          x2={9741.57}
          y2={385.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-174"
          x1={9736.23}
          y1={378.33}
          x2={9741}
          y2={381.09}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-169"
          x1={9732.32}
          y1={374.85}
          x2={9738.26}
          y2={374.85}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-175"
          x1={9732.92}
          y1={360.31}
          x2={9737.69}
          y2={363.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-170"
          x1={9739.97}
          y1={387.55}
          x2={9745.91}
          y2={387.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-176"
          x1={9740.57}
          y1={380.83}
          x2={9745.35}
          y2={383.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-171"
          x1={9736.66}
          y1={377.35}
          x2={9742.6}
          y2={377.35}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-177"
          x1={9737.26}
          y1={362.81}
          x2={9742.03}
          y2={365.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-172"
          x1={9744.31}
          y1={390.05}
          x2={9750.25}
          y2={390.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-178"
          x1={9744.91}
          y1={383.33}
          x2={9749.69}
          y2={386.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-173"
          x1={9741}
          y1={379.85}
          x2={9746.94}
          y2={379.85}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-179"
          x1={9741.6}
          y1={365.31}
          x2={9746.38}
          y2={368.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-174"
          x1={9748.65}
          y1={392.55}
          x2={9754.59}
          y2={392.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-180"
          x1={9749.25}
          y1={385.83}
          x2={9754.03}
          y2={388.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-175"
          x1={9745.34}
          y1={382.35}
          x2={9751.28}
          y2={382.35}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-181"
          x1={9745.94}
          y1={367.81}
          x2={9750.72}
          y2={370.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-176"
          x1={9752.99}
          y1={395.05}
          x2={9758.93}
          y2={395.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-182"
          x1={9753.6}
          y1={388.32}
          x2={9758.37}
          y2={391.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-177"
          x1={9749.68}
          y1={384.84}
          x2={9755.62}
          y2={384.84}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-183"
          x1={9750.28}
          y1={370.31}
          x2={9755.06}
          y2={373.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-178"
          x1={9757.34}
          y1={397.54}
          x2={9763.27}
          y2={397.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-184"
          x1={9757.94}
          y1={390.82}
          x2={9762.71}
          y2={393.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-179"
          x1={9754.03}
          y1={387.34}
          x2={9759.96}
          y2={387.34}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-185"
          x1={9754.63}
          y1={372.8}
          x2={9759.4}
          y2={375.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-180"
          x1={9761.68}
          y1={400.04}
          x2={9767.62}
          y2={400.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-186"
          x1={9762.28}
          y1={393.32}
          x2={9767.05}
          y2={396.08}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-181"
          x1={9758.37}
          y1={389.84}
          x2={9764.31}
          y2={389.84}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-187"
          x1={9758.97}
          y1={375.3}
          x2={9763.74}
          y2={378.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-182"
          x1={9766.02}
          y1={402.54}
          x2={9771.96}
          y2={402.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-188"
          x1={9766.62}
          y1={395.82}
          x2={9771.4}
          y2={398.58}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-183"
          x1={9762.71}
          y1={392.34}
          x2={9768.65}
          y2={392.34}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-189"
          x1={9763.31}
          y1={377.8}
          x2={9768.08}
          y2={380.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-184"
          x1={9770.36}
          y1={405.04}
          x2={9776.3}
          y2={405.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-190"
          x1={9770.96}
          y1={398.32}
          x2={9775.74}
          y2={401.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-185"
          x1={9767.05}
          y1={394.84}
          x2={9772.99}
          y2={394.84}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-191"
          x1={9767.65}
          y1={380.3}
          x2={9772.43}
          y2={383.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-186"
          x1={9774.7}
          y1={407.54}
          x2={9780.64}
          y2={407.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-192"
          x1={9775.3}
          y1={400.82}
          x2={9780.08}
          y2={403.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-187"
          x1={9771.39}
          y1={397.34}
          x2={9777.33}
          y2={397.34}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-193"
          x1={9771.99}
          y1={382.8}
          x2={9776.77}
          y2={385.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-188"
          x1={9779.04}
          y1={410.04}
          x2={9784.98}
          y2={410.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-194"
          x1={9779.64}
          y1={403.31}
          x2={9784.42}
          y2={406.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-189"
          x1={9775.73}
          y1={399.83}
          x2={9781.67}
          y2={399.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-195"
          x1={9776.33}
          y1={385.3}
          x2={9781.11}
          y2={388.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-190"
          x1={9783.39}
          y1={412.53}
          x2={9789.32}
          y2={412.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-196"
          x1={9783.99}
          y1={405.81}
          x2={9788.76}
          y2={408.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-191"
          x1={9780.07}
          y1={402.33}
          x2={9786.01}
          y2={402.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-197"
          x1={9780.67}
          y1={387.79}
          x2={9785.45}
          y2={390.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-192"
          x1={9787.73}
          y1={415.03}
          x2={9793.67}
          y2={415.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-198"
          x1={9788.33}
          y1={408.31}
          x2={9793.1}
          y2={411.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-193"
          x1={9784.42}
          y1={404.83}
          x2={9790.35}
          y2={404.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-199"
          x1={9785.02}
          y1={390.29}
          x2={9789.79}
          y2={393.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-194"
          x1={9792.07}
          y1={417.53}
          x2={9798.01}
          y2={417.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-200"
          x1={9792.67}
          y1={410.81}
          x2={9797.45}
          y2={413.57}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-195"
          x1={9788.76}
          y1={407.33}
          x2={9794.7}
          y2={407.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-201"
          x1={9789.36}
          y1={392.79}
          x2={9794.13}
          y2={395.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-196"
          x1={9796.41}
          y1={420.03}
          x2={9802.35}
          y2={420.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-202"
          x1={9797.01}
          y1={413.31}
          x2={9801.79}
          y2={416.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-197"
          x1={9793.1}
          y1={409.83}
          x2={9799.04}
          y2={409.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-203"
          x1={9793.7}
          y1={395.29}
          x2={9798.48}
          y2={398.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-198"
          x1={9800.75}
          y1={422.53}
          x2={9806.69}
          y2={422.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-204"
          x1={9801.35}
          y1={415.81}
          x2={9806.13}
          y2={418.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-199"
          x1={9797.44}
          y1={412.33}
          x2={9803.38}
          y2={412.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-205"
          x1={9798.04}
          y1={397.79}
          x2={9802.82}
          y2={400.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-200"
          x1={9805.09}
          y1={425.03}
          x2={9811.03}
          y2={425.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-206"
          x1={9805.69}
          y1={418.3}
          x2={9810.47}
          y2={421.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-201"
          x1={9801.78}
          y1={414.82}
          x2={9807.72}
          y2={414.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-207"
          x1={9802.38}
          y1={400.29}
          x2={9807.16}
          y2={403.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-202"
          x1={9809.44}
          y1={427.52}
          x2={9815.37}
          y2={427.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-208"
          x1={9810.04}
          y1={420.8}
          x2={9814.81}
          y2={423.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-203"
          x1={9806.12}
          y1={417.32}
          x2={9812.06}
          y2={417.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-209"
          x1={9806.72}
          y1={402.78}
          x2={9811.5}
          y2={405.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-204"
          x1={9813.78}
          y1={430.02}
          x2={9819.72}
          y2={430.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-210"
          x1={9814.38}
          y1={423.3}
          x2={9819.15}
          y2={426.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-205"
          x1={9810.47}
          y1={419.82}
          x2={9816.4}
          y2={419.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-211"
          x1={9811.07}
          y1={405.28}
          x2={9815.84}
          y2={408.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-206"
          x1={9818.12}
          y1={432.52}
          x2={9824.06}
          y2={432.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-212"
          x1={9818.72}
          y1={425.8}
          x2={9823.49}
          y2={428.56}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-207"
          x1={9814.81}
          y1={422.32}
          x2={9820.75}
          y2={422.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-213"
          x1={9815.41}
          y1={407.78}
          x2={9820.18}
          y2={410.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-208"
          x1={9822.46}
          y1={435.02}
          x2={9828.4}
          y2={435.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-214"
          x1={9823.06}
          y1={428.3}
          x2={9827.84}
          y2={431.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-209"
          x1={9819.15}
          y1={424.82}
          x2={9825.09}
          y2={424.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-215"
          x1={9819.75}
          y1={410.28}
          x2={9824.52}
          y2={413.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-210"
          x1={9826.8}
          y1={437.52}
          x2={9832.74}
          y2={437.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-216"
          x1={9827.4}
          y1={430.8}
          x2={9832.18}
          y2={433.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-211"
          x1={9823.49}
          y1={427.32}
          x2={9829.43}
          y2={427.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-217"
          x1={9824.09}
          y1={412.78}
          x2={9828.87}
          y2={415.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-212"
          x1={9831.14}
          y1={440.02}
          x2={9837.08}
          y2={440.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-218"
          x1={9831.74}
          y1={433.29}
          x2={9836.52}
          y2={436.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-213"
          x1={9827.83}
          y1={429.81}
          x2={9833.77}
          y2={429.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-219"
          x1={9828.43}
          y1={415.28}
          x2={9833.21}
          y2={418.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-214"
          x1={9835.49}
          y1={442.51}
          x2={9841.42}
          y2={442.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-220"
          x1={9836.09}
          y1={435.79}
          x2={9840.86}
          y2={438.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-215"
          x1={9832.17}
          y1={432.31}
          x2={9838.11}
          y2={432.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-221"
          x1={9832.77}
          y1={417.77}
          x2={9837.55}
          y2={420.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-216"
          x1={9839.83}
          y1={445.01}
          x2={9845.76}
          y2={445.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-222"
          x1={9840.43}
          y1={438.29}
          x2={9845.2}
          y2={441.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-217"
          x1={9836.52}
          y1={434.81}
          x2={9842.45}
          y2={434.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-223"
          x1={9837.12}
          y1={420.27}
          x2={9841.89}
          y2={423.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-218"
          x1={9844.17}
          y1={447.51}
          x2={9850.11}
          y2={447.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-224"
          x1={9844.77}
          y1={440.79}
          x2={9849.54}
          y2={443.55}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-219"
          x1={9840.86}
          y1={437.31}
          x2={9846.8}
          y2={437.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-225"
          x1={9841.46}
          y1={422.77}
          x2={9846.23}
          y2={425.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-220"
          x1={9848.51}
          y1={450.01}
          x2={9854.45}
          y2={450.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-226"
          x1={9849.11}
          y1={443.29}
          x2={9853.89}
          y2={446.05}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-221"
          x1={9845.2}
          y1={439.81}
          x2={9851.14}
          y2={439.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-227"
          x1={9845.8}
          y1={425.27}
          x2={9850.57}
          y2={428.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-222"
          x1={9852.85}
          y1={452.51}
          x2={9858.79}
          y2={452.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-228"
          x1={9853.45}
          y1={445.79}
          x2={9858.23}
          y2={448.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-223"
          x1={9849.54}
          y1={442.31}
          x2={9855.48}
          y2={442.31}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-229"
          x1={9850.14}
          y1={427.77}
          x2={9854.92}
          y2={430.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-224"
          x1={9857.19}
          y1={455.01}
          x2={9863.13}
          y2={455.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-230"
          x1={9857.79}
          y1={448.28}
          x2={9862.57}
          y2={451.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-225"
          x1={9853.88}
          y1={444.8}
          x2={9859.82}
          y2={444.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-231"
          x1={9854.48}
          y1={430.27}
          x2={9859.26}
          y2={433.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-226"
          x1={9861.53}
          y1={457.5}
          x2={9867.47}
          y2={457.5}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-232"
          x1={9862.13}
          y1={450.78}
          x2={9866.91}
          y2={453.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-227"
          x1={9858.22}
          y1={447.3}
          x2={9864.16}
          y2={447.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-233"
          x1={9858.82}
          y1={432.76}
          x2={9863.6}
          y2={435.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-228"
          x1={9865.88}
          y1={460}
          x2={9871.81}
          y2={460}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-234"
          x1={9866.48}
          y1={453.28}
          x2={9871.25}
          y2={456.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-229"
          x1={9862.56}
          y1={449.8}
          x2={9868.5}
          y2={449.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-235"
          x1={9863.17}
          y1={435.26}
          x2={9867.94}
          y2={438.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-230"
          x1={9870.22}
          y1={462.5}
          x2={9876.16}
          y2={462.5}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-236"
          x1={9870.82}
          y1={455.78}
          x2={9875.59}
          y2={458.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-231"
          x1={9866.91}
          y1={452.3}
          x2={9872.84}
          y2={452.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-237"
          x1={9867.51}
          y1={437.76}
          x2={9872.28}
          y2={440.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-232"
          x1={9874.56}
          y1={465}
          x2={9880.5}
          y2={465}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-238"
          x1={9875.16}
          y1={458.28}
          x2={9879.94}
          y2={461.04}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-233"
          x1={9871.25}
          y1={454.8}
          x2={9877.19}
          y2={454.8}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-239"
          x1={9871.85}
          y1={440.26}
          x2={9876.62}
          y2={443.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-234"
          x1={9878.9}
          y1={467.5}
          x2={9884.84}
          y2={467.5}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-240"
          x1={9879.5}
          y1={460.78}
          x2={9884.28}
          y2={463.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-235"
          x1={9875.59}
          y1={457.3}
          x2={9881.53}
          y2={457.3}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-241"
          x1={9876.19}
          y1={442.76}
          x2={9880.97}
          y2={445.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-236"
          x1={9883.24}
          y1={470}
          x2={9889.18}
          y2={470}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-242"
          x1={9883.84}
          y1={463.27}
          x2={9888.62}
          y2={466.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-237"
          x1={9879.93}
          y1={459.79}
          x2={9885.87}
          y2={459.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-243"
          x1={9880.53}
          y1={445.26}
          x2={9885.31}
          y2={448.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-238"
          x1={9887.58}
          y1={472.49}
          x2={9893.52}
          y2={472.49}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-244"
          x1={9888.18}
          y1={465.77}
          x2={9892.96}
          y2={468.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-239"
          x1={9884.27}
          y1={462.29}
          x2={9890.21}
          y2={462.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-245"
          x1={9884.87}
          y1={447.75}
          x2={9889.65}
          y2={450.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-240"
          x1={9891.93}
          y1={474.99}
          x2={9897.86}
          y2={474.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-246"
          x1={9892.53}
          y1={468.27}
          x2={9897.3}
          y2={471.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-241"
          x1={9888.61}
          y1={464.79}
          x2={9894.55}
          y2={464.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-247"
          x1={9889.21}
          y1={450.25}
          x2={9893.99}
          y2={453.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-242"
          x1={9896.27}
          y1={477.49}
          x2={9902.21}
          y2={477.49}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-248"
          x1={9896.87}
          y1={470.77}
          x2={9901.64}
          y2={473.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-243"
          x1={9892.96}
          y1={467.29}
          x2={9898.89}
          y2={467.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-249"
          x1={9893.56}
          y1={452.75}
          x2={9898.33}
          y2={455.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-244"
          x1={9900.61}
          y1={479.99}
          x2={9906.55}
          y2={479.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-250"
          x1={9901.21}
          y1={473.27}
          x2={9905.98}
          y2={476.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-245"
          x1={9897.3}
          y1={469.79}
          x2={9903.24}
          y2={469.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-251"
          x1={9897.9}
          y1={455.25}
          x2={9902.67}
          y2={458.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-246"
          x1={9904.95}
          y1={482.49}
          x2={9910.89}
          y2={482.49}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-252"
          x1={9905.55}
          y1={475.77}
          x2={9910.33}
          y2={478.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-247"
          x1={9901.64}
          y1={472.29}
          x2={9907.58}
          y2={472.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-253"
          x1={9902.24}
          y1={457.75}
          x2={9907.02}
          y2={460.51}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-248"
          x1={9909.29}
          y1={484.99}
          x2={9915.23}
          y2={484.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-254"
          x1={9909.89}
          y1={478.26}
          x2={9914.67}
          y2={481.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-249"
          x1={9905.98}
          y1={474.78}
          x2={9911.92}
          y2={474.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-255"
          x1={9906.58}
          y1={460.25}
          x2={9911.36}
          y2={463}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-250"
          x1={9913.63}
          y1={487.48}
          x2={9919.57}
          y2={487.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-256"
          x1={9914.23}
          y1={480.76}
          x2={9919.01}
          y2={483.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-251"
          x1={9910.32}
          y1={477.28}
          x2={9916.26}
          y2={477.28}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-257"
          x1={9910.92}
          y1={462.74}
          x2={9915.7}
          y2={465.5}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-252"
          x1={9917.98}
          y1={489.98}
          x2={9923.91}
          y2={489.98}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-258"
          x1={9918.58}
          y1={483.26}
          x2={9923.35}
          y2={486.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-253"
          x1={9914.66}
          y1={479.78}
          x2={9920.6}
          y2={479.78}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-259"
          x1={9915.26}
          y1={465.24}
          x2={9920.04}
          y2={468}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-254"
          x1={9922.32}
          y1={492.48}
          x2={9928.26}
          y2={492.48}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-260"
          x1={9922.92}
          y1={485.76}
          x2={9927.69}
          y2={488.52}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-255"
          x1={10040.63}
          y1={458.66}
          x2={10047.96}
          y2={458.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-261"
          x1={10041.37}
          y1={432.72}
          x2={10047.26}
          y2={436.12}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-256"
          x1={10035.8}
          y1={433.98}
          x2={10052.72}
          y2={433.98}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-262"
          x1={10037.51}
          y1={426.46}
          x2={10051.12}
          y2={434.32}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-257"
          x1={10038.64}
          y1={439.97}
          x2={10038.64}
          y2={439.97}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-258"
          x1={10049.99}
          y1={439.96}
          x2={10049.99}
          y2={439.96}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-259"
          x1={10038.69}
          y1={439.87}
          x2={10049.87}
          y2={439.87}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-260"
          x1={10038.69}
          y1={443.9}
          x2={10049.87}
          y2={443.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-261"
          x1={10038.69}
          y1={447.94}
          x2={10049.87}
          y2={447.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-262"
          x1={10038.69}
          y1={451.97}
          x2={10049.87}
          y2={451.97}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-263"
          x1={10038.69}
          y1={456}
          x2={10049.87}
          y2={456}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-264"
          x1={10038.69}
          y1={460.03}
          x2={10049.87}
          y2={460.03}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-265"
          x1={10104.13}
          y1={508.29}
          x2={10110.73}
          y2={508.29}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-263"
          x1={10104.8}
          y1={496.01}
          x2={10110.1}
          y2={499.07}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-266"
          x1={10022.29}
          y1={497.27}
          x2={10028.88}
          y2={497.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-264"
          x1={10022.95}
          y1={481.6}
          x2={10028.25}
          y2={484.66}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-267"
          x1={10093.78}
          y1={456.67}
          x2={10100.37}
          y2={456.67}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-265"
          x1={10094.45}
          y1={441}
          x2={10099.75}
          y2={444.06}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-268"
          x1={10040.63}
          y1={421.74}
          x2={10047.96}
          y2={421.74}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-266"
          x1={10041.37}
          y1={409.39}
          x2={10047.26}
          y2={412.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-269"
          x1={10083.01}
          y1={493.54}
          x2={10075.42}
          y2={483.37}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-267"
          x1={10072.85}
          y1={489.79}
          x2={10081.51}
          y2={489.79}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__linear-gradient-3"
          x1={10077.24}
          y1={496.49}
          x2={10070.39}
          y2={487.31}
          gradientTransform="translate(-8990.36)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.06} stopColor="#2f2f2f" />
          <stop offset={0.16} stopColor="#474747" />
          <stop offset={0.25} stopColor="#606060" />
          <stop offset={0.48} stopColor="#1f1f1f" />
          <stop offset={1} stopColor="#484848" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-270"
          x1={10071.94}
          y1={500.08}
          x2={10064.35}
          y2={489.91}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-268"
          x1={10061.78}
          y1={496.33}
          x2={10070.44}
          y2={496.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-271"
          x1={10127.92}
          y1={499.37}
          x2={10123.47}
          y2={493.41}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-269"
          x1={10121.97}
          y1={497.17}
          x2={10127.04}
          y2={497.17}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__linear-gradient-4"
          x1={10124.54}
          y1={501.1}
          x2={10120.52}
          y2={495.72}
          xlinkHref="#prefix__linear-gradient-3"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-272"
          x1={10121.43}
          y1={503.21}
          x2={10116.98}
          y2={497.25}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-270"
          x1={10115.48}
          y1={501.01}
          x2={10120.55}
          y2={501.01}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-273"
          x1={10086.64}
          y1={523.07}
          x2={10082.19}
          y2={517.11}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-271"
          x1={10080.69}
          y1={520.87}
          x2={10085.76}
          y2={520.87}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__linear-gradient-5"
          x1={10083.26}
          y1={524.8}
          x2={10079.24}
          y2={519.42}
          xlinkHref="#prefix__linear-gradient-3"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_4-274"
          x1={10080.15}
          y1={526.9}
          x2={10075.7}
          y2={520.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_4"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-272"
          x1={10074.2}
          y1={524.7}
          x2={10079.27}
          y2={524.7}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__linear-gradient-6"
          x1={-3076.37}
          y1={186.21}
          x2={-3067.52}
          y2={183.56}
          gradientTransform="matrix(-1 0 0 1 -3073.2 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#fff" />
          <stop offset={1} stopColor="#7e878e" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-7"
          x1={-3092.18}
          y1={187.25}
          x2={-3008.96}
          y2={168.6}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-8"
          x1={-3044.82}
          y1={166.66}
          x2={-3050.33}
          y2={177.16}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-9"
          x1={-3086.11}
          y1={191.85}
          x2={-3077.26}
          y2={189.2}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-10"
          x1={-3101.92}
          y1={192.9}
          x2={-3018.7}
          y2={174.24}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-11"
          x1={-3054.56}
          y1={172.3}
          x2={-3060.07}
          y2={182.8}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-12"
          x1={-3095.84}
          y1={197.5}
          x2={-3087}
          y2={194.85}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-13"
          x1={-3111.66}
          y1={198.54}
          x2={-3028.44}
          y2={179.89}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-14"
          x1={-3064.3}
          y1={177.95}
          x2={-3069.81}
          y2={188.45}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-15"
          x1={-3105.58}
          y1={203.14}
          x2={-3096.73}
          y2={200.49}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-16"
          x1={-3121.39}
          y1={204.19}
          x2={-3038.18}
          y2={185.53}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-17"
          x1={-3074.04}
          y1={183.59}
          x2={-3079.54}
          y2={194.09}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-18"
          x1={-3115.32}
          y1={208.79}
          x2={-3106.47}
          y2={206.14}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-19"
          x1={-3131.13}
          y1={209.83}
          x2={-3047.91}
          y2={191.18}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-20"
          x1={-3083.77}
          y1={189.24}
          x2={-3089.28}
          y2={199.74}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-21"
          x1={-3125.05}
          y1={214.43}
          x2={-3116.21}
          y2={211.78}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-22"
          x1={-3140.87}
          y1={215.48}
          x2={-3057.65}
          y2={196.82}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-23"
          x1={-3093.51}
          y1={194.89}
          x2={-3099.02}
          y2={205.38}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-24"
          x1={-3134.79}
          y1={220.08}
          x2={-3125.94}
          y2={217.43}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-25"
          x1={-3150.61}
          y1={221.12}
          x2={-3067.39}
          y2={202.47}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-26"
          x1={-3103.25}
          y1={200.53}
          x2={-3108.76}
          y2={211.03}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-27"
          x1={-3144.53}
          y1={225.72}
          x2={-3135.68}
          y2={223.07}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-28"
          x1={-3160.34}
          y1={226.77}
          x2={-3077.12}
          y2={208.11}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-29"
          x1={-3112.98}
          y1={206.18}
          x2={-3118.49}
          y2={216.67}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-30"
          x1={-3154.26}
          y1={231.37}
          x2={-3145.42}
          y2={228.72}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-31"
          x1={-3170.08}
          y1={232.42}
          x2={-3086.86}
          y2={213.76}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-32"
          x1={-3122.72}
          y1={211.82}
          x2={-3128.23}
          y2={222.32}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-33"
          x1={-3164}
          y1={237.01}
          x2={-3155.16}
          y2={234.37}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-34"
          x1={-3179.82}
          y1={238.06}
          x2={-3096.6}
          y2={219.41}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-35"
          x1={-3132.46}
          y1={217.47}
          x2={-3137.97}
          y2={227.97}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-36"
          x1={-3173.74}
          y1={242.66}
          x2={-3164.89}
          y2={240.01}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-37"
          x1={-3189.55}
          y1={243.71}
          x2={-3106.33}
          y2={225.05}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-38"
          x1={-3142.19}
          y1={223.11}
          x2={-3147.7}
          y2={233.61}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-39"
          x1={-3183.48}
          y1={248.3}
          x2={-3174.63}
          y2={245.66}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-40"
          x1={-3199.29}
          y1={249.35}
          x2={-3116.07}
          y2={230.7}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-41"
          x1={-3151.93}
          y1={228.76}
          x2={-3157.44}
          y2={239.26}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-42"
          x1={-3193.21}
          y1={253.95}
          x2={-3184.37}
          y2={251.3}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-43"
          x1={-3209.03}
          y1={255}
          x2={-3125.81}
          y2={236.34}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-44"
          x1={-3161.67}
          y1={234.4}
          x2={-3167.18}
          y2={244.9}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-45"
          x1={-3202.95}
          y1={259.6}
          x2={-3194.1}
          y2={256.95}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-46"
          x1={-3218.76}
          y1={260.64}
          x2={-3135.54}
          y2={241.99}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-47"
          x1={-3171.41}
          y1={240.05}
          x2={-3176.91}
          y2={250.55}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-48"
          x1={-3212.69}
          y1={265.24}
          x2={-3203.84}
          y2={262.59}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-49"
          x1={-3228.5}
          y1={266.29}
          x2={-3145.28}
          y2={247.63}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-50"
          x1={-3181.14}
          y1={245.69}
          x2={-3186.65}
          y2={256.19}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-51"
          x1={-3222.42}
          y1={270.89}
          x2={-3213.58}
          y2={268.24}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-52"
          x1={-3238.24}
          y1={271.93}
          x2={-3155.02}
          y2={253.28}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-53"
          x1={-3190.88}
          y1={251.34}
          x2={-3196.39}
          y2={261.84}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-54"
          x1={-3232.16}
          y1={276.53}
          x2={-3223.31}
          y2={273.88}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-55"
          x1={-3247.98}
          y1={277.58}
          x2={-3164.76}
          y2={258.92}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-56"
          x1={-3200.62}
          y1={256.98}
          x2={-3206.13}
          y2={267.48}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-57"
          x1={-3241.9}
          y1={282.18}
          x2={-3233.05}
          y2={279.53}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-58"
          x1={-3257.71}
          y1={283.22}
          x2={-3174.49}
          y2={264.57}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-59"
          x1={-3210.35}
          y1={262.63}
          x2={-3215.86}
          y2={273.13}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-60"
          x1={-3251.63}
          y1={287.82}
          x2={-3242.79}
          y2={285.17}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-61"
          x1={-3267.45}
          y1={288.87}
          x2={-3184.23}
          y2={270.21}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-62"
          x1={-3220.09}
          y1={268.27}
          x2={-3225.6}
          y2={278.77}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-63"
          x1={-3261.37}
          y1={293.47}
          x2={-3252.53}
          y2={290.82}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-64"
          x1={-3277.19}
          y1={294.51}
          x2={-3193.97}
          y2={275.86}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-65"
          x1={-3229.83}
          y1={273.92}
          x2={-3235.34}
          y2={284.42}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-66"
          x1={-3271.11}
          y1={299.11}
          x2={-3262.26}
          y2={296.46}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-67"
          x1={-3286.92}
          y1={300.16}
          x2={-3203.7}
          y2={281.5}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-68"
          x1={-3239.56}
          y1={279.56}
          x2={-3245.07}
          y2={290.06}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-69"
          x1={-3280.85}
          y1={304.76}
          x2={-3272}
          y2={302.11}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-70"
          x1={-3296.66}
          y1={305.8}
          x2={-3213.44}
          y2={287.15}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-71"
          x1={-3249.3}
          y1={285.21}
          x2={-3254.81}
          y2={295.71}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-72"
          x1={-3290.58}
          y1={310.4}
          x2={-3281.74}
          y2={307.75}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-73"
          x1={-3306.4}
          y1={311.45}
          x2={-3223.18}
          y2={292.79}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-74"
          x1={-3259.04}
          y1={290.85}
          x2={-3264.55}
          y2={301.35}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-75"
          x1={-3300.32}
          y1={316.05}
          x2={-3291.47}
          y2={313.4}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-76"
          x1={-3316.13}
          y1={317.09}
          x2={-3232.91}
          y2={298.44}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-77"
          x1={-3268.78}
          y1={296.5}
          x2={-3274.28}
          y2={307}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-78"
          x1={-3310.06}
          y1={321.69}
          x2={-3301.21}
          y2={319.04}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-79"
          x1={-3325.87}
          y1={322.74}
          x2={-3242.65}
          y2={304.08}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-80"
          x1={-3278.51}
          y1={302.15}
          x2={-3284.02}
          y2={312.64}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-81"
          x1={-3319.79}
          y1={327.34}
          x2={-3310.95}
          y2={324.69}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-82"
          x1={-3335.61}
          y1={328.38}
          x2={-3252.39}
          y2={309.73}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-83"
          x1={-3288.25}
          y1={307.79}
          x2={-3293.76}
          y2={318.29}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-84"
          x1={-3329.53}
          y1={332.98}
          x2={-3320.68}
          y2={330.33}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-85"
          x1={-3345.35}
          y1={334.03}
          x2={-3262.13}
          y2={315.37}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-86"
          x1={-3297.99}
          y1={313.44}
          x2={-3303.5}
          y2={323.93}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-87"
          x1={-3339.27}
          y1={338.63}
          x2={-3330.42}
          y2={335.98}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-88"
          x1={-3355.08}
          y1={339.68}
          x2={-3271.86}
          y2={321.02}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-89"
          x1={-3307.72}
          y1={319.08}
          x2={-3313.23}
          y2={329.58}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-90"
          x1={-3349}
          y1={344.27}
          x2={-3340.16}
          y2={341.63}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-91"
          x1={-3364.82}
          y1={345.32}
          x2={-3281.6}
          y2={326.67}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-92"
          x1={-3317.46}
          y1={324.73}
          x2={-3322.97}
          y2={335.23}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-93"
          x1={-3358.74}
          y1={349.92}
          x2={-3349.9}
          y2={347.27}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-94"
          x1={-3374.56}
          y1={350.97}
          x2={-3291.34}
          y2={332.31}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-95"
          x1={-3327.2}
          y1={330.37}
          x2={-3332.71}
          y2={340.87}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-96"
          x1={-3368.48}
          y1={355.56}
          x2={-3359.63}
          y2={352.92}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-97"
          x1={-3384.29}
          y1={356.61}
          x2={-3301.07}
          y2={337.96}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-98"
          x1={-3336.93}
          y1={336.02}
          x2={-3342.44}
          y2={346.52}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-99"
          x1={-3378.22}
          y1={361.21}
          x2={-3369.37}
          y2={358.56}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-100"
          x1={-3394.03}
          y1={362.26}
          x2={-3310.81}
          y2={343.6}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-101"
          x1={-3346.67}
          y1={341.66}
          x2={-3352.18}
          y2={352.16}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-102"
          x1={-3387.95}
          y1={366.86}
          x2={-3379.11}
          y2={364.21}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-103"
          x1={-3403.77}
          y1={367.9}
          x2={-3320.55}
          y2={349.25}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-104"
          x1={-3356.41}
          y1={347.31}
          x2={-3361.92}
          y2={357.81}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-105"
          x1={-3397.69}
          y1={372.5}
          x2={-3388.84}
          y2={369.85}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-106"
          x1={-3413.5}
          y1={373.55}
          x2={-3330.28}
          y2={354.89}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-107"
          x1={-3366.14}
          y1={352.95}
          x2={-3371.65}
          y2={363.45}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-108"
          x1={-3407.43}
          y1={378.15}
          x2={-3398.58}
          y2={375.5}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-109"
          x1={-3423.24}
          y1={379.19}
          x2={-3340.02}
          y2={360.54}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-110"
          x1={-3375.88}
          y1={358.6}
          x2={-3381.39}
          y2={369.1}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-111"
          x1={-3417.16}
          y1={383.79}
          x2={-3408.32}
          y2={381.14}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-112"
          x1={-3432.98}
          y1={384.84}
          x2={-3349.76}
          y2={366.18}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-113"
          x1={-3385.62}
          y1={364.24}
          x2={-3391.13}
          y2={374.74}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-114"
          x1={-3426.9}
          y1={389.44}
          x2={-3418.05}
          y2={386.79}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-115"
          x1={-3442.72}
          y1={390.48}
          x2={-3359.5}
          y2={371.83}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-116"
          x1={-3395.36}
          y1={369.89}
          x2={-3400.87}
          y2={380.39}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-117"
          x1={-3436.64}
          y1={395.08}
          x2={-3427.79}
          y2={392.43}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-118"
          x1={-3452.45}
          y1={396.13}
          x2={-3369.23}
          y2={377.47}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-119"
          x1={-3405.09}
          y1={375.53}
          x2={-3410.6}
          y2={386.03}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-120"
          x1={-3446.37}
          y1={400.73}
          x2={-3437.53}
          y2={398.08}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-121"
          x1={-3462.19}
          y1={401.77}
          x2={-3378.97}
          y2={383.12}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-122"
          x1={-3414.83}
          y1={381.18}
          x2={-3420.34}
          y2={391.68}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-123"
          x1={-3456.11}
          y1={406.37}
          x2={-3447.26}
          y2={403.72}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-124"
          x1={-3471.93}
          y1={407.42}
          x2={-3388.71}
          y2={388.76}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-125"
          x1={-3424.57}
          y1={386.82}
          x2={-3430.08}
          y2={397.32}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-126"
          x1={-3465.85}
          y1={412.02}
          x2={-3457}
          y2={409.37}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-127"
          x1={-3481.66}
          y1={413.06}
          x2={-3398.44}
          y2={394.41}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-128"
          x1={-3434.3}
          y1={392.47}
          x2={-3439.81}
          y2={402.97}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-129"
          x1={-3475.59}
          y1={417.66}
          x2={-3466.74}
          y2={415.01}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-130"
          x1={-3491.4}
          y1={418.71}
          x2={-3408.18}
          y2={400.05}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-131"
          x1={-3444.04}
          y1={398.11}
          x2={-3449.55}
          y2={408.61}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-132"
          x1={-3485.32}
          y1={423.31}
          x2={-3476.48}
          y2={420.66}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-133"
          x1={-3501.14}
          y1={424.35}
          x2={-3417.92}
          y2={405.7}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-134"
          x1={-3453.78}
          y1={403.76}
          x2={-3459.29}
          y2={414.26}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-135"
          x1={-3495.06}
          y1={428.95}
          x2={-3486.21}
          y2={426.3}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-136"
          x1={-3510.87}
          y1={430}
          x2={-3427.65}
          y2={411.34}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-137"
          x1={-3463.51}
          y1={409.41}
          x2={-3469.02}
          y2={419.9}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-138"
          x1={-3504.8}
          y1={434.6}
          x2={-3495.95}
          y2={431.95}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-139"
          x1={-3520.61}
          y1={435.64}
          x2={-3437.39}
          y2={416.99}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-140"
          x1={-3473.25}
          y1={415.05}
          x2={-3478.76}
          y2={425.55}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-141"
          x1={-3514.53}
          y1={440.24}
          x2={-3505.69}
          y2={437.59}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-142"
          x1={-3530.35}
          y1={441.29}
          x2={-3447.13}
          y2={422.63}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-143"
          x1={-3482.99}
          y1={420.7}
          x2={-3488.5}
          y2={431.19}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-144"
          x1={-3524.27}
          y1={445.89}
          x2={-3515.42}
          y2={443.24}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-145"
          x1={-3540.09}
          y1={446.94}
          x2={-3456.87}
          y2={428.28}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-146"
          x1={-3492.73}
          y1={426.34}
          x2={-3498.23}
          y2={436.84}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-147"
          x1={-3534.01}
          y1={451.53}
          x2={-3525.16}
          y2={448.89}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-148"
          x1={-3549.82}
          y1={452.58}
          x2={-3466.6}
          y2={433.93}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-149"
          x1={-3502.46}
          y1={431.99}
          x2={-3507.97}
          y2={442.49}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-150"
          x1={-3543.74}
          y1={457.18}
          x2={-3534.9}
          y2={454.53}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-151"
          x1={-3559.56}
          y1={458.23}
          x2={-3476.34}
          y2={439.57}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-152"
          x1={-3512.2}
          y1={437.63}
          x2={-3517.71}
          y2={448.13}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-153"
          x1={-3553.48}
          y1={462.82}
          x2={-3544.63}
          y2={460.18}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-154"
          x1={-3569.3}
          y1={463.87}
          x2={-3486.08}
          y2={445.22}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-155"
          x1={-3521.94}
          y1={443.28}
          x2={-3527.45}
          y2={453.78}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-156"
          x1={-3563.22}
          y1={468.47}
          x2={-3554.37}
          y2={465.82}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-157"
          x1={-3579.03}
          y1={469.52}
          x2={-3495.81}
          y2={450.86}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-158"
          x1={-3531.67}
          y1={448.92}
          x2={-3537.18}
          y2={459.42}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-159"
          x1={-3572.96}
          y1={474.12}
          x2={-3564.11}
          y2={471.47}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-160"
          x1={-3588.77}
          y1={475.16}
          x2={-3505.55}
          y2={456.51}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-161"
          x1={-3541.41}
          y1={454.57}
          x2={-3546.92}
          y2={465.07}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-162"
          x1={-3582.69}
          y1={479.76}
          x2={-3573.85}
          y2={477.11}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-163"
          x1={-3598.51}
          y1={480.81}
          x2={-3515.29}
          y2={462.15}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-164"
          x1={-3551.15}
          y1={460.21}
          x2={-3556.66}
          y2={470.71}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-165"
          x1={-3592.43}
          y1={485.41}
          x2={-3583.58}
          y2={482.76}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-166"
          x1={-3608.24}
          y1={486.45}
          x2={-3525.02}
          y2={467.8}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-167"
          x1={-3560.88}
          y1={465.86}
          x2={-3566.39}
          y2={476.36}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-168"
          x1={-3602.17}
          y1={491.05}
          x2={-3593.32}
          y2={488.4}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-169"
          x1={-3617.98}
          y1={492.1}
          x2={-3534.76}
          y2={473.44}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-170"
          x1={-3570.62}
          y1={471.5}
          x2={-3576.13}
          y2={482}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-171"
          x1={-3611.9}
          y1={496.7}
          x2={-3603.06}
          y2={494.05}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-172"
          x1={-3627.72}
          y1={497.74}
          x2={-3544.5}
          y2={479.09}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-173"
          x1={-3580.36}
          y1={477.15}
          x2={-3585.87}
          y2={487.65}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-174"
          x1={-3621.64}
          y1={502.34}
          x2={-3612.79}
          y2={499.69}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-175"
          x1={-3637.46}
          y1={503.39}
          x2={-3554.24}
          y2={484.73}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-176"
          x1={-3590.1}
          y1={482.79}
          x2={-3595.6}
          y2={493.29}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-177"
          x1={-3631.38}
          y1={507.99}
          x2={-3622.53}
          y2={505.34}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-178"
          x1={-3647.19}
          y1={509.03}
          x2={-3563.97}
          y2={490.38}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-179"
          x1={-3599.83}
          y1={488.44}
          x2={-3605.34}
          y2={498.94}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-180"
          x1={-3641.11}
          y1={513.63}
          x2={-3632.27}
          y2={510.98}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-181"
          x1={-3656.93}
          y1={514.68}
          x2={-3573.71}
          y2={496.02}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-182"
          x1={-3609.57}
          y1={494.08}
          x2={-3615.08}
          y2={504.58}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-183"
          x1={-3650.85}
          y1={519.28}
          x2={-3642}
          y2={516.63}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-184"
          x1={-3666.67}
          y1={520.32}
          x2={-3583.45}
          y2={501.67}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-185"
          x1={-3619.31}
          y1={499.73}
          x2={-3624.82}
          y2={510.23}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-186"
          x1={-3660.59}
          y1={524.92}
          x2={-3651.74}
          y2={522.27}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-187"
          x1={-3676.4}
          y1={525.97}
          x2={-3593.18}
          y2={507.31}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-188"
          x1={-3629.04}
          y1={505.37}
          x2={-3634.55}
          y2={515.87}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-189"
          x1={-3670.32}
          y1={530.57}
          x2={-3661.48}
          y2={527.92}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-190"
          x1={-3686.14}
          y1={531.61}
          x2={-3602.92}
          y2={512.96}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-191"
          x1={-3638.78}
          y1={511.02}
          x2={-3644.29}
          y2={521.52}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-192"
          x1={-3680.06}
          y1={536.21}
          x2={-3671.22}
          y2={533.56}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-193"
          x1={-3695.88}
          y1={537.26}
          x2={-3612.66}
          y2={518.6}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-194"
          x1={-3648.52}
          y1={516.66}
          x2={-3654.03}
          y2={527.16}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-195"
          x1={-3689.8}
          y1={541.86}
          x2={-3680.95}
          y2={539.21}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-196"
          x1={-3705.61}
          y1={542.9}
          x2={-3622.39}
          y2={524.25}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-197"
          x1={-3658.25}
          y1={522.31}
          x2={-3663.76}
          y2={532.81}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-198"
          x1={-3699.54}
          y1={547.5}
          x2={-3690.69}
          y2={544.85}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-199"
          x1={-3715.35}
          y1={548.55}
          x2={-3632.13}
          y2={529.89}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-200"
          x1={-3667.99}
          y1={527.96}
          x2={-3673.5}
          y2={538.45}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-201"
          x1={-3709.27}
          y1={553.15}
          x2={-3700.43}
          y2={550.5}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-202"
          x1={-3725.09}
          y1={554.2}
          x2={-3641.87}
          y2={535.54}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-203"
          x1={-3677.73}
          y1={533.6}
          x2={-3683.24}
          y2={544.1}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-204"
          x1={-3719.01}
          y1={558.79}
          x2={-3710.16}
          y2={556.15}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-205"
          x1={-3734.82}
          y1={559.84}
          x2={-3651.61}
          y2={541.19}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-206"
          x1={-3687.47}
          y1={539.25}
          x2={-3692.97}
          y2={549.75}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-207"
          x1={-3728.75}
          y1={564.44}
          x2={-3719.9}
          y2={561.79}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-208"
          x1={-3744.56}
          y1={565.49}
          x2={-3661.34}
          y2={546.83}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-209"
          x1={-3697.2}
          y1={544.89}
          x2={-3702.71}
          y2={555.39}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-210"
          x1={-3738.48}
          y1={570.08}
          x2={-3729.64}
          y2={567.44}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-211"
          x1={-3754.3}
          y1={571.13}
          x2={-3671.08}
          y2={552.48}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-212"
          x1={-3706.94}
          y1={550.54}
          x2={-3712.45}
          y2={561.04}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-213"
          x1={-3748.22}
          y1={575.73}
          x2={-3739.37}
          y2={573.08}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-214"
          x1={-3764.04}
          y1={576.78}
          x2={-3680.82}
          y2={558.12}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-215"
          x1={-3716.68}
          y1={556.18}
          x2={-3722.19}
          y2={566.68}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-216"
          x1={-3757.96}
          y1={581.38}
          x2={-3749.11}
          y2={578.73}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-217"
          x1={-3773.77}
          y1={582.42}
          x2={-3690.55}
          y2={563.77}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-218"
          x1={-3726.41}
          y1={561.83}
          x2={-3731.92}
          y2={572.33}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-219"
          x1={-3767.69}
          y1={587.02}
          x2={-3758.85}
          y2={584.37}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-220"
          x1={-3783.51}
          y1={588.07}
          x2={-3700.29}
          y2={569.41}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-221"
          x1={-3736.15}
          y1={567.47}
          x2={-3741.66}
          y2={577.97}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-222"
          x1={-3777.43}
          y1={592.67}
          x2={-3768.59}
          y2={590.02}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-223"
          x1={-3793.25}
          y1={593.71}
          x2={-3710.03}
          y2={575.06}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-224"
          x1={-3745.89}
          y1={573.12}
          x2={-3751.4}
          y2={583.62}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-225"
          x1={-3787.17}
          y1={598.31}
          x2={-3778.32}
          y2={595.66}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-226"
          x1={-3802.98}
          y1={599.36}
          x2={-3719.76}
          y2={580.7}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-227"
          x1={-3755.62}
          y1={578.76}
          x2={-3761.13}
          y2={589.26}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-228"
          x1={-3796.91}
          y1={603.96}
          x2={-3788.06}
          y2={601.31}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-229"
          x1={-3812.72}
          y1={605}
          x2={-3729.5}
          y2={586.35}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-230"
          x1={-3765.36}
          y1={584.41}
          x2={-3770.87}
          y2={594.91}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-231"
          x1={-3806.64}
          y1={609.6}
          x2={-3797.8}
          y2={606.95}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-232"
          x1={-3822.46}
          y1={610.65}
          x2={-3739.24}
          y2={591.99}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-233"
          x1={-3775.1}
          y1={590.05}
          x2={-3780.61}
          y2={600.55}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-234"
          x1={-3816.38}
          y1={615.25}
          x2={-3807.53}
          y2={612.6}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-235"
          x1={-3832.19}
          y1={616.29}
          x2={-3748.98}
          y2={597.64}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-236"
          x1={-3784.84}
          y1={595.7}
          x2={-3790.34}
          y2={606.2}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-237"
          x1={-3826.12}
          y1={620.89}
          x2={-3817.27}
          y2={618.24}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-238"
          x1={-3841.93}
          y1={621.94}
          x2={-3758.71}
          y2={603.28}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-239"
          x1={-3794.57}
          y1={601.34}
          x2={-3800.08}
          y2={611.84}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-240"
          x1={-3835.85}
          y1={626.54}
          x2={-3827.01}
          y2={623.89}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-241"
          x1={-3851.67}
          y1={627.58}
          x2={-3768.45}
          y2={608.93}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-242"
          x1={-3804.31}
          y1={606.99}
          x2={-3809.82}
          y2={617.49}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-243"
          x1={-3845.59}
          y1={632.18}
          x2={-3836.74}
          y2={629.53}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-244"
          x1={-3861.41}
          y1={633.23}
          x2={-3778.19}
          y2={614.57}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-245"
          x1={-3814.05}
          y1={612.63}
          x2={-3819.56}
          y2={623.13}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-246"
          x1={-3855.33}
          y1={637.83}
          x2={-3846.48}
          y2={635.18}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-247"
          x1={-3871.14}
          y1={638.87}
          x2={-3787.92}
          y2={620.22}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-248"
          x1={-3823.78}
          y1={618.28}
          x2={-3829.29}
          y2={628.78}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-249"
          x1={-3865.06}
          y1={643.47}
          x2={-3856.22}
          y2={640.82}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-250"
          x1={-3880.88}
          y1={644.52}
          x2={-3797.66}
          y2={625.86}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-251"
          x1={-3833.52}
          y1={623.92}
          x2={-3839.03}
          y2={634.42}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-252"
          x1={-3874.8}
          y1={649.12}
          x2={-3865.96}
          y2={646.47}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-253"
          x1={-3890.62}
          y1={650.16}
          x2={-3807.4}
          y2={631.51}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-254"
          x1={-3843.26}
          y1={629.57}
          x2={-3848.77}
          y2={640.07}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-255"
          x1={-3884.54}
          y1={654.76}
          x2={-3875.69}
          y2={652.11}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-256"
          x1={-3900.35}
          y1={655.81}
          x2={-3817.13}
          y2={637.15}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-257"
          x1={-3852.99}
          y1={635.22}
          x2={-3858.5}
          y2={645.71}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-258"
          x1={-3894.28}
          y1={660.41}
          x2={-3885.43}
          y2={657.76}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-259"
          x1={-3910.09}
          y1={661.46}
          x2={-3826.87}
          y2={642.8}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-260"
          x1={-3862.73}
          y1={640.86}
          x2={-3868.24}
          y2={651.36}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-261"
          x1={-3904.01}
          y1={666.05}
          x2={-3895.17}
          y2={663.41}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-262"
          x1={-3919.83}
          y1={667.1}
          x2={-3836.61}
          y2={648.45}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-263"
          x1={-3872.47}
          y1={646.51}
          x2={-3877.98}
          y2={657.01}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-264"
          x1={-3913.75}
          y1={671.7}
          x2={-3904.9}
          y2={669.05}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-265"
          x1={-3929.56}
          y1={672.75}
          x2={-3846.34}
          y2={654.09}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-266"
          x1={-3882.21}
          y1={652.15}
          x2={-3887.71}
          y2={662.65}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-267"
          x1={-3923.49}
          y1={677.34}
          x2={-3914.64}
          y2={674.7}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-268"
          x1={-3939.3}
          y1={678.39}
          x2={-3856.08}
          y2={659.74}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-269"
          x1={-3891.94}
          y1={657.8}
          x2={-3897.45}
          y2={668.3}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-270"
          x1={-3933.22}
          y1={682.99}
          x2={-3924.38}
          y2={680.34}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-271"
          x1={-3949.04}
          y1={684.04}
          x2={-3865.82}
          y2={665.38}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-272"
          x1={-3901.68}
          y1={663.44}
          x2={-3907.19}
          y2={673.94}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-273"
          x1={-3942.96}
          y1={688.64}
          x2={-3934.11}
          y2={685.99}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-274"
          x1={-3958.78}
          y1={689.68}
          x2={-3875.56}
          y2={671.03}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-275"
          x1={-3911.42}
          y1={669.09}
          x2={-3916.93}
          y2={679.59}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-276"
          x1={-3952.7}
          y1={694.28}
          x2={-3943.85}
          y2={691.63}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-277"
          x1={-3968.51}
          y1={695.33}
          x2={-3885.29}
          y2={676.67}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-278"
          x1={-3921.15}
          y1={674.73}
          x2={-3926.66}
          y2={685.23}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-279"
          x1={-3962.43}
          y1={699.93}
          x2={-3953.59}
          y2={697.28}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-280"
          x1={-3978.25}
          y1={700.97}
          x2={-3895.03}
          y2={682.32}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-281"
          x1={-3930.89}
          y1={680.38}
          x2={-3936.4}
          y2={690.88}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-282"
          x1={-3972.17}
          y1={705.57}
          x2={-3963.33}
          y2={702.92}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-283"
          x1={-3987.99}
          y1={706.62}
          x2={-3904.77}
          y2={687.96}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-284"
          x1={-3940.63}
          y1={686.02}
          x2={-3946.14}
          y2={696.52}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-285"
          x1={-3981.91}
          y1={711.22}
          x2={-3973.06}
          y2={708.57}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-286"
          x1={-3997.72}
          y1={712.26}
          x2={-3914.5}
          y2={693.61}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-287"
          x1={-3950.36}
          y1={691.67}
          x2={-3955.87}
          y2={702.17}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-288"
          x1={-3991.65}
          y1={716.86}
          x2={-3982.8}
          y2={714.21}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-289"
          x1={-4007.46}
          y1={717.91}
          x2={-3924.24}
          y2={699.25}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-290"
          x1={-3960.1}
          y1={697.31}
          x2={-3965.61}
          y2={707.81}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-291"
          x1={-4001.38}
          y1={722.51}
          x2={-3992.54}
          y2={719.86}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-292"
          x1={-4017.2}
          y1={723.55}
          x2={-3933.98}
          y2={704.9}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-293"
          x1={-3969.84}
          y1={702.96}
          x2={-3975.35}
          y2={713.46}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-294"
          x1={-4011.12}
          y1={728.15}
          x2={-4002.27}
          y2={725.5}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-295"
          x1={-4026.93}
          y1={729.2}
          x2={-3943.71}
          y2={710.54}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-296"
          x1={-3979.57}
          y1={708.6}
          x2={-3985.08}
          y2={719.1}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-297"
          x1={-4020.86}
          y1={733.8}
          x2={-4012.01}
          y2={731.15}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-298"
          x1={-4036.67}
          y1={734.84}
          x2={-3953.45}
          y2={716.19}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-299"
          x1={-3989.31}
          y1={714.25}
          x2={-3994.82}
          y2={724.75}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-300"
          x1={-4030.59}
          y1={739.44}
          x2={-4021.75}
          y2={736.79}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-301"
          x1={-4046.41}
          y1={740.49}
          x2={-3963.19}
          y2={721.83}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-302"
          x1={-3999.05}
          y1={719.89}
          x2={-4004.56}
          y2={730.39}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-303"
          x1={-4040.33}
          y1={745.09}
          x2={-4031.48}
          y2={742.44}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-304"
          x1={-4056.15}
          y1={746.13}
          x2={-3972.93}
          y2={727.48}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-305"
          x1={-4008.79}
          y1={725.54}
          x2={-4014.3}
          y2={736.04}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-306"
          x1={-4050.07}
          y1={750.73}
          x2={-4041.22}
          y2={748.08}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-307"
          x1={-4065.88}
          y1={751.78}
          x2={-3982.66}
          y2={733.12}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-308"
          x1={-4018.52}
          y1={731.18}
          x2={-4024.03}
          y2={741.68}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-309"
          x1={-4059.8}
          y1={756.38}
          x2={-4050.96}
          y2={753.73}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-310"
          x1={-4075.62}
          y1={757.42}
          x2={-3992.4}
          y2={738.77}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-311"
          x1={-4028.26}
          y1={736.83}
          x2={-4033.77}
          y2={747.33}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-312"
          x1={-4069.54}
          y1={762.02}
          x2={-4060.7}
          y2={759.37}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-313"
          x1={-4085.36}
          y1={763.07}
          x2={-4002.14}
          y2={744.41}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-314"
          x1={-4038}
          y1={742.48}
          x2={-4043.51}
          y2={752.97}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-315"
          x1={-4079.28}
          y1={767.67}
          x2={-4070.43}
          y2={765.02}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-316"
          x1={-4095.09}
          y1={768.72}
          x2={-4011.87}
          y2={750.06}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-317"
          x1={-4047.73}
          y1={748.12}
          x2={-4053.24}
          y2={758.62}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-318"
          x1={-4089.02}
          y1={773.31}
          x2={-4080.17}
          y2={770.67}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-319"
          x1={-4104.83}
          y1={774.36}
          x2={-4021.61}
          y2={755.71}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-320"
          x1={-4057.47}
          y1={753.77}
          x2={-4062.98}
          y2={764.27}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-321"
          x1={-4098.75}
          y1={778.96}
          x2={-4089.91}
          y2={776.31}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-322"
          x1={-4114.57}
          y1={780.01}
          x2={-4031.35}
          y2={761.35}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-323"
          x1={-4067.21}
          y1={759.41}
          x2={-4072.72}
          y2={769.91}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-324"
          x1={-4108.49}
          y1={784.6}
          x2={-4099.64}
          y2={781.96}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-325"
          x1={-4124.3}
          y1={785.65}
          x2={-4041.08}
          y2={767}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-326"
          x1={-4076.94}
          y1={765.06}
          x2={-4082.45}
          y2={775.56}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-327"
          x1={-4118.23}
          y1={790.25}
          x2={-4109.38}
          y2={787.6}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-328"
          x1={-4134.04}
          y1={791.3}
          x2={-4050.82}
          y2={772.64}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-329"
          x1={-4086.68}
          y1={770.7}
          x2={-4092.19}
          y2={781.2}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-330"
          x1={-4127.96}
          y1={795.9}
          x2={-4119.12}
          y2={793.25}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-331"
          x1={-4143.78}
          y1={796.94}
          x2={-4060.56}
          y2={778.29}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-332"
          x1={-4096.42}
          y1={776.35}
          x2={-4101.93}
          y2={786.85}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-333"
          x1={-4137.7}
          y1={801.54}
          x2={-4128.85}
          y2={798.89}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-334"
          x1={-4153.52}
          y1={802.59}
          x2={-4070.3}
          y2={783.93}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-335"
          x1={-4106.16}
          y1={781.99}
          x2={-4111.66}
          y2={792.49}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-336"
          x1={-4147.44}
          y1={807.19}
          x2={-4138.59}
          y2={804.54}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-337"
          x1={-4163.25}
          y1={808.23}
          x2={-4080.03}
          y2={789.58}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-338"
          x1={-4115.89}
          y1={787.64}
          x2={-4121.4}
          y2={798.14}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-339"
          x1={-4157.17}
          y1={812.83}
          x2={-4148.33}
          y2={810.18}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-340"
          x1={-4172.99}
          y1={813.88}
          x2={-4089.77}
          y2={795.22}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-341"
          x1={-4125.63}
          y1={793.28}
          x2={-4131.14}
          y2={803.78}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-342"
          x1={-4166.91}
          y1={818.48}
          x2={-4158.06}
          y2={815.83}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-343"
          x1={-4182.73}
          y1={819.52}
          x2={-4099.51}
          y2={800.87}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-344"
          x1={-4135.37}
          y1={798.93}
          x2={-4140.88}
          y2={809.43}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-345"
          x1={-4176.65}
          y1={824.12}
          x2={-4167.8}
          y2={821.47}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-346"
          x1={-4192.46}
          y1={825.17}
          x2={-4109.24}
          y2={806.51}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-347"
          x1={-4145.1}
          y1={804.57}
          x2={-4150.61}
          y2={815.07}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-348"
          x1={-4186.39}
          y1={829.77}
          x2={-4177.54}
          y2={827.12}
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-349"
          x1={-4202.2}
          y1={830.81}
          x2={-4118.98}
          y2={812.16}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__linear-gradient-350"
          x1={-4154.84}
          y1={810.22}
          x2={-4160.35}
          y2={820.72}
          gradientTransform="matrix(-1 0 0 1 -3032.76 0)"
          xlinkHref="#prefix__linear-gradient-6"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37"
          data-name="New Gradient Swatch copy 37"
          x1={-2690.62}
          y1={376.46}
          x2={-2379.32}
          y2={377.78}
          gradientTransform="matrix(-1 0 0 1 -1882.17 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="silver" />
          <stop offset={0.45} stopColor="#cfcfcf" />
          <stop offset={1} stopColor="#eaeaea" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-2"
          x1={-2625.5}
          y1={371.9}
          x2={-2132.19}
          y2={398.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-3"
          x1={-2609.03}
          y1={515.45}
          x2={-2487.24}
          y2={566.02}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38"
          data-name="New Gradient Swatch copy 38"
          x1={-2644.46}
          y1={517.53}
          x2={-2535.3}
          y2={517.53}
          gradientTransform="matrix(-1 0 0 1 -1882.17 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#171717" />
          <stop offset={1} stopColor="#2d2d2d" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-4"
          x1={-2609.03}
          y1={469.29}
          x2={-2487.24}
          y2={519.85}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-2"
          x1={-2644.46}
          y1={471.36}
          x2={-2535.3}
          y2={471.36}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-5"
          x1={-2336.25}
          y1={386.5}
          x2={-2314.35}
          y2={379.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-3"
          x1={-2726.24}
          y1={270.61}
          x2={-2330.48}
          y2={270.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-6"
          x1={-2755.73}
          y1={223.52}
          x2={-2731.99}
          y2={266.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__linear-gradient-351"
          x1={-2731.76}
          y1={245.56}
          x2={-2731.76}
          y2={346.4}
          gradientTransform="matrix(-1 0 0 1 -1882.17 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c1272d" />
          <stop offset={0.01} stopColor="#bd262c" />
          <stop offset={0.15} stopColor="#8b1c20" />
          <stop offset={0.28} stopColor="#601316" />
          <stop offset={0.42} stopColor="#3e0c0e" />
          <stop offset={0.56} stopColor="#230708" />
          <stop offset={0.7} stopColor="#0f0304" />
          <stop offset={0.85} stopColor="#040101" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-4"
          x1={-2500.41}
          y1={394.42}
          x2={-2425.25}
          y2={377.58}
          gradientTransform="matrix(-1 0 0 1 -1809.83 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-5"
          x1={-2476.77}
          y1={377.19}
          x2={-2425.46}
          y2={365.69}
          gradientTransform="matrix(-1 0 0 1 -1809.83 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-6"
          x1={336.02}
          y1={418.62}
          x2={344.63}
          y2={416.69}
          gradientTransform="translate(186.36)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-7"
          x1={301.61}
          y1={398.06}
          x2={310.23}
          y2={396.13}
          gradientTransform="translate(186.36)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-8"
          x1={-2661.74}
          y1={280.44}
          x2={-2525.4}
          y2={280.44}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-7"
          x1={-2627.56}
          y1={409.34}
          x2={-2134.24}
          y2={436.38}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24"
          data-name="New Gradient Swatch copy 24"
          x1={-2826.16}
          y1={191.13}
          x2={-2710.18}
          y2={191.13}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-8"
          x1={-3005.44}
          y1={274.49}
          x2={-2461.75}
          y2={152.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-9"
          x1={-2641.06}
          y1={22.35}
          x2={-2746.98}
          y2={224.22}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-2"
          data-name="New Gradient Swatch copy 24"
          x1={-2726.1}
          y1={147.4}
          x2={-2534.3}
          y2={147.4}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-10"
          x1={-2516.32}
          y1={364.2}
          x2={-2379.97}
          y2={364.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-9"
          x1={-2689.84}
          y1={193.18}
          x2={-2378.55}
          y2={194.5}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-10"
          x1={-2615.49}
          y1={189.16}
          x2={-2122.17}
          y2={216.2}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-11"
          x1={-2827.29}
          y1={322.86}
          x2={-2595.8}
          y2={240.42}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-11"
          x1={-2661.74}
          y1={170.54}
          x2={-2525.4}
          y2={170.54}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-12"
          x1={-2617.54}
          y1={226.6}
          x2={-2124.22}
          y2={253.64}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-12"
          x1={-2478.71}
          y1={450.46}
          x2={-2707.76}
          y2={108.63}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-13"
          x1={-2739.22}
          y1={342.36}
          x2={-2416.15}
          y2={269.94}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-13"
          x1={-2347.35}
          y1={399.66}
          x2={-2576.39}
          y2={57.83}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-14"
          x1={-2620.07}
          y1={273.33}
          x2={-2296.99}
          y2={200.91}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__linear-gradient-352"
          x1={6616.67}
          y1={269.18}
          x2={6611.73}
          y2={262.55}
          gradientTransform="translate(-6439.07)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#171717" />
          <stop offset={0.42} stopColor="#2d2d2d" />
          <stop offset={0.89} stopColor="#1b1b1b" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-3"
          data-name="New Gradient Swatch copy 24"
          x1={6609.36}
          y1={267.18}
          x2={6614.83}
          y2={267.18}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-353"
          x1={6624.06}
          y1={273.47}
          x2={6619.12}
          y2={266.85}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-4"
          data-name="New Gradient Swatch copy 24"
          x1={6616.75}
          y1={271.47}
          x2={6622.22}
          y2={271.47}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-5"
          data-name="New Gradient Swatch copy 24"
          x1={6496.25}
          y1={271.83}
          x2={6518.28}
          y2={266.89}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-354"
          x1={6686.11}
          y1={309.74}
          x2={6681.17}
          y2={303.12}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-6"
          data-name="New Gradient Swatch copy 24"
          x1={6678.79}
          y1={307.74}
          x2={6684.27}
          y2={307.74}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-355"
          x1={6693.5}
          y1={314.03}
          x2={6688.56}
          y2={307.41}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-7"
          data-name="New Gradient Swatch copy 24"
          x1={6686.18}
          y1={312.03}
          x2={6691.66}
          y2={312.03}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-356"
          x1={-6266.4}
          y1={299.08}
          x2={-6080.6}
          y2={243.44}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#b3b3b3" />
          <stop offset={1} stopColor="#f2f2f2" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-8"
          data-name="New Gradient Swatch copy 24"
          x1={-6315.28}
          y1={313.07}
          x2={-6246.69}
          y2={297.69}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-9"
          data-name="New Gradient Swatch copy 24"
          x1={6565.68}
          y1={312.39}
          x2={6587.71}
          y2={307.45}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-10"
          data-name="New Gradient Swatch copy 24"
          x1={-6285.9}
          y1={308.37}
          x2={-6274.58}
          y2={304.98}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-11"
          data-name="New Gradient Swatch copy 24"
          x1={-6289.01}
          y1={309.23}
          x2={-6284.06}
          y2={308.12}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-12"
          data-name="New Gradient Swatch copy 24"
          x1={-6279.31}
          y1={297.31}
          x2={-6284.74}
          y2={307.66}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-13"
          data-name="New Gradient Swatch copy 24"
          x1={-6286.58}
          y1={309.58}
          x2={-6283.42}
          y2={308.64}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-14"
          data-name="New Gradient Swatch copy 24"
          x1={-6290.71}
          y1={309.84}
          x2={-6283.75}
          y2={308.28}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-15"
          data-name="New Gradient Swatch copy 24"
          x1={-6285.92}
          y1={305.86}
          x2={-6286.98}
          y2={307.87}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-357"
          x1={6712.37}
          y1={325.58}
          x2={6707.43}
          y2={318.96}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-16"
          data-name="New Gradient Swatch copy 24"
          x1={6705.06}
          y1={323.58}
          x2={6710.53}
          y2={323.58}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-358"
          x1={6719.76}
          y1={329.88}
          x2={6714.82}
          y2={323.25}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-17"
          data-name="New Gradient Swatch copy 24"
          x1={6712.45}
          y1={327.88}
          x2={6717.92}
          y2={327.88}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-18"
          data-name="New Gradient Swatch copy 24"
          x1={6591.95}
          y1={328.24}
          x2={6613.98}
          y2={323.3}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-359"
          x1={6781.81}
          y1={366.15}
          x2={6776.87}
          y2={359.52}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-19"
          data-name="New Gradient Swatch copy 24"
          x1={6774.5}
          y1={364.15}
          x2={6779.97}
          y2={364.15}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-360"
          x1={6789.2}
          y1={370.44}
          x2={6784.26}
          y2={363.82}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-20"
          data-name="New Gradient Swatch copy 24"
          x1={6781.89}
          y1={368.44}
          x2={6787.36}
          y2={368.44}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-361"
          x1={-6362.1}
          y1={355.48}
          x2={-6176.3}
          y2={299.85}
          xlinkHref="#prefix__linear-gradient-356"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-21"
          data-name="New Gradient Swatch copy 24"
          x1={-6410.98}
          y1={369.47}
          x2={-6342.39}
          y2={354.1}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-22"
          data-name="New Gradient Swatch copy 24"
          x1={6661.39}
          y1={368.8}
          x2={6683.41}
          y2={363.86}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-23"
          data-name="New Gradient Swatch copy 24"
          x1={-6381.6}
          y1={364.78}
          x2={-6370.28}
          y2={361.39}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-24"
          data-name="New Gradient Swatch copy 24"
          x1={-6384.72}
          y1={365.64}
          x2={-6379.76}
          y2={364.53}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-25"
          data-name="New Gradient Swatch copy 24"
          x1={-6375.02}
          y1={353.72}
          x2={-6380.44}
          y2={364.06}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-26"
          data-name="New Gradient Swatch copy 24"
          x1={-6382.28}
          y1={365.99}
          x2={-6379.12}
          y2={365.05}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-27"
          data-name="New Gradient Swatch copy 24"
          x1={-6386.41}
          y1={366.25}
          x2={-6379.45}
          y2={364.69}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-28"
          data-name="New Gradient Swatch copy 24"
          x1={-6381.62}
          y1={362.27}
          x2={-6382.68}
          y2={364.28}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-362"
          x1={6807.85}
          y1={381.87}
          x2={6802.91}
          y2={375.25}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-29"
          data-name="New Gradient Swatch copy 24"
          x1={6800.54}
          y1={379.87}
          x2={6806.01}
          y2={379.87}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-363"
          x1={6815.24}
          y1={386.16}
          x2={6810.3}
          y2={379.54}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-30"
          data-name="New Gradient Swatch copy 24"
          x1={6807.93}
          y1={384.16}
          x2={6813.4}
          y2={384.16}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-31"
          data-name="New Gradient Swatch copy 24"
          x1={6687.43}
          y1={384.52}
          x2={6709.45}
          y2={379.59}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-364"
          x1={6877.28}
          y1={422.43}
          x2={6872.34}
          y2={415.81}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-32"
          data-name="New Gradient Swatch copy 24"
          x1={6869.97}
          y1={420.43}
          x2={6875.44}
          y2={420.43}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-365"
          x1={6884.68}
          y1={426.73}
          x2={6879.73}
          y2={420.1}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-33"
          data-name="New Gradient Swatch copy 24"
          x1={6877.36}
          y1={424.73}
          x2={6882.84}
          y2={424.73}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-366"
          x1={-6457.58}
          y1={411.77}
          x2={-6271.78}
          y2={356.13}
          xlinkHref="#prefix__linear-gradient-356"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-34"
          data-name="New Gradient Swatch copy 24"
          x1={-6506.46}
          y1={425.76}
          x2={-6437.87}
          y2={410.39}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-35"
          data-name="New Gradient Swatch copy 24"
          x1={6756.86}
          y1={425.09}
          x2={6778.89}
          y2={420.15}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-36"
          data-name="New Gradient Swatch copy 24"
          x1={-6477.08}
          y1={421.07}
          x2={-6465.75}
          y2={417.68}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-37"
          data-name="New Gradient Swatch copy 24"
          x1={-6480.19}
          y1={421.92}
          x2={-6475.24}
          y2={420.81}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-38"
          data-name="New Gradient Swatch copy 24"
          x1={-6470.49}
          y1={410.01}
          x2={-6475.92}
          y2={420.35}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-39"
          data-name="New Gradient Swatch copy 24"
          x1={-6477.75}
          y1={422.28}
          x2={-6474.6}
          y2={421.33}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-40"
          data-name="New Gradient Swatch copy 24"
          x1={-6481.89}
          y1={422.54}
          x2={-6474.92}
          y2={420.98}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-41"
          data-name="New Gradient Swatch copy 24"
          x1={-6477.1}
          y1={418.56}
          x2={-6478.15}
          y2={420.57}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-367"
          x1={6903.44}
          y1={438.22}
          x2={6898.5}
          y2={431.6}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-42"
          data-name="New Gradient Swatch copy 24"
          x1={6896.13}
          y1={436.22}
          x2={6901.6}
          y2={436.22}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-368"
          x1={6910.83}
          y1={442.51}
          x2={6905.89}
          y2={435.89}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-43"
          data-name="New Gradient Swatch copy 24"
          x1={6903.52}
          y1={440.51}
          x2={6908.99}
          y2={440.51}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-44"
          data-name="New Gradient Swatch copy 24"
          x1={6783.02}
          y1={440.87}
          x2={6805.04}
          y2={435.93}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-369"
          x1={6972.87}
          y1={478.78}
          x2={6967.93}
          y2={472.16}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-45"
          data-name="New Gradient Swatch copy 24"
          x1={6965.56}
          y1={476.78}
          x2={6971.03}
          y2={476.78}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-370"
          x1={6980.26}
          y1={483.07}
          x2={6975.32}
          y2={476.45}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-46"
          data-name="New Gradient Swatch copy 24"
          x1={6972.95}
          y1={481.07}
          x2={6978.42}
          y2={481.07}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-371"
          x1={-6553.17}
          y1={468.12}
          x2={-6367.37}
          y2={412.48}
          xlinkHref="#prefix__linear-gradient-356"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-47"
          data-name="New Gradient Swatch copy 24"
          x1={-6602.04}
          y1={482.11}
          x2={-6533.46}
          y2={466.73}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-48"
          data-name="New Gradient Swatch copy 24"
          x1={6852.45}
          y1={481.43}
          x2={6874.48}
          y2={476.5}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-49"
          data-name="New Gradient Swatch copy 24"
          x1={-6572.67}
          y1={477.42}
          x2={-6561.34}
          y2={474.02}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-50"
          data-name="New Gradient Swatch copy 24"
          x1={-6575.78}
          y1={478.27}
          x2={-6570.83}
          y2={477.16}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-51"
          data-name="New Gradient Swatch copy 24"
          x1={-6566.08}
          y1={466.35}
          x2={-6571.51}
          y2={476.7}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-52"
          data-name="New Gradient Swatch copy 24"
          x1={-6573.34}
          y1={478.62}
          x2={-6570.19}
          y2={477.68}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-53"
          data-name="New Gradient Swatch copy 24"
          x1={-6577.48}
          y1={478.88}
          x2={-6570.51}
          y2={477.32}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-54"
          data-name="New Gradient Swatch copy 24"
          x1={-6572.69}
          y1={474.9}
          x2={-6573.74}
          y2={476.91}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-372"
          x1={6999.14}
          y1={494.62}
          x2={6994.2}
          y2={488}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-55"
          data-name="New Gradient Swatch copy 24"
          x1={6991.83}
          y1={492.62}
          x2={6997.3}
          y2={492.62}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-373"
          x1={7006.53}
          y1={498.92}
          x2={7001.59}
          y2={492.3}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-56"
          data-name="New Gradient Swatch copy 24"
          x1={6999.22}
          y1={496.92}
          x2={7004.69}
          y2={496.92}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-57"
          data-name="New Gradient Swatch copy 24"
          x1={6878.72}
          y1={497.28}
          x2={6900.74}
          y2={492.34}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-374"
          x1={7068.57}
          y1={535.19}
          x2={7063.63}
          y2={528.56}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-58"
          data-name="New Gradient Swatch copy 24"
          x1={7061.26}
          y1={533.19}
          x2={7066.73}
          y2={533.19}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-375"
          x1={7075.97}
          y1={539.48}
          x2={7071.02}
          y2={532.86}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-59"
          data-name="New Gradient Swatch copy 24"
          x1={7068.65}
          y1={537.48}
          x2={7074.13}
          y2={537.48}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-376"
          x1={-6648.87}
          y1={524.52}
          x2={-6463.07}
          y2={468.89}
          xlinkHref="#prefix__linear-gradient-356"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-60"
          data-name="New Gradient Swatch copy 24"
          x1={-6697.75}
          y1={538.51}
          x2={-6629.16}
          y2={523.14}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-61"
          data-name="New Gradient Swatch copy 24"
          x1={6948.15}
          y1={537.84}
          x2={6970.18}
          y2={532.9}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-62"
          data-name="New Gradient Swatch copy 24"
          x1={-6668.37}
          y1={533.82}
          x2={-6657.04}
          y2={530.43}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-63"
          data-name="New Gradient Swatch copy 24"
          x1={-6671.48}
          y1={534.68}
          x2={-6666.53}
          y2={533.57}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-64"
          data-name="New Gradient Swatch copy 24"
          x1={-6661.78}
          y1={522.76}
          x2={-6667.21}
          y2={533.1}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-65"
          data-name="New Gradient Swatch copy 24"
          x1={-6669.04}
          y1={535.03}
          x2={-6665.89}
          y2={534.09}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-66"
          data-name="New Gradient Swatch copy 24"
          x1={-6673.18}
          y1={535.29}
          x2={-6666.21}
          y2={533.73}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-67"
          data-name="New Gradient Swatch copy 24"
          x1={-6668.39}
          y1={531.31}
          x2={-6669.44}
          y2={533.32}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-377"
          x1={7094.62}
          y1={550.91}
          x2={7089.67}
          y2={544.29}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-68"
          data-name="New Gradient Swatch copy 24"
          x1={7087.3}
          y1={548.91}
          x2={7092.78}
          y2={548.91}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-378"
          x1={7102.01}
          y1={555.21}
          x2={7097.06}
          y2={548.58}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-69"
          data-name="New Gradient Swatch copy 24"
          x1={7094.69}
          y1={553.2}
          x2={7100.17}
          y2={553.2}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-70"
          data-name="New Gradient Swatch copy 24"
          x1={6974.19}
          y1={553.56}
          x2={6996.22}
          y2={548.63}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-379"
          x1={7164.05}
          y1={591.47}
          x2={7159.11}
          y2={584.85}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-71"
          data-name="New Gradient Swatch copy 24"
          x1={7156.74}
          y1={589.47}
          x2={7162.21}
          y2={589.47}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-380"
          x1={7171.44}
          y1={595.77}
          x2={7166.5}
          y2={589.14}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-72"
          data-name="New Gradient Swatch copy 24"
          x1={7164.13}
          y1={593.77}
          x2={7169.6}
          y2={593.77}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-381"
          x1={-6744.35}
          y1={580.81}
          x2={-6558.54}
          y2={525.17}
          xlinkHref="#prefix__linear-gradient-356"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-73"
          data-name="New Gradient Swatch copy 24"
          x1={-6793.22}
          y1={594.8}
          x2={-6724.63}
          y2={579.43}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-74"
          data-name="New Gradient Swatch copy 24"
          x1={7043.63}
          y1={594.13}
          x2={7065.65}
          y2={589.19}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-75"
          data-name="New Gradient Swatch copy 24"
          x1={-6631.16}
          y1={399.94}
          x2={-6727.01}
          y2={582.61}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-76"
          data-name="New Gradient Swatch copy 24"
          x1={-6763.85}
          y1={590.11}
          x2={-6752.52}
          y2={586.72}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-77"
          data-name="New Gradient Swatch copy 24"
          x1={-6766.96}
          y1={590.96}
          x2={-6762}
          y2={589.85}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-78"
          data-name="New Gradient Swatch copy 24"
          x1={-6757.26}
          y1={579.05}
          x2={-6762.68}
          y2={589.39}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-79"
          data-name="New Gradient Swatch copy 24"
          x1={-6764.52}
          y1={591.32}
          x2={-6761.36}
          y2={590.37}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-80"
          data-name="New Gradient Swatch copy 24"
          x1={-6768.65}
          y1={591.58}
          x2={-6761.69}
          y2={590.02}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-81"
          data-name="New Gradient Swatch copy 24"
          x1={-6763.87}
          y1={587.6}
          x2={-6764.92}
          y2={589.61}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-382"
          x1={7190.32}
          y1={607.32}
          x2={7185.38}
          y2={600.7}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-82"
          data-name="New Gradient Swatch copy 24"
          x1={7183}
          y1={605.32}
          x2={7188.48}
          y2={605.32}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-383"
          x1={7197.71}
          y1={611.61}
          x2={7192.77}
          y2={604.99}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-83"
          data-name="New Gradient Swatch copy 24"
          x1={7190.39}
          y1={609.61}
          x2={7195.87}
          y2={609.61}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-84"
          data-name="New Gradient Swatch copy 24"
          x1={7069.89}
          y1={609.97}
          x2={7091.92}
          y2={605.03}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-384"
          x1={7259.75}
          y1={647.88}
          x2={7254.81}
          y2={641.26}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-85"
          data-name="New Gradient Swatch copy 24"
          x1={7252.44}
          y1={645.88}
          x2={7257.91}
          y2={645.88}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-385"
          x1={7267.14}
          y1={652.17}
          x2={7262.2}
          y2={645.55}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-86"
          data-name="New Gradient Swatch copy 24"
          x1={7259.83}
          y1={650.17}
          x2={7265.3}
          y2={650.17}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-386"
          x1={-6840.05}
          y1={637.22}
          x2={-6654.24}
          y2={581.58}
          xlinkHref="#prefix__linear-gradient-356"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-87"
          data-name="New Gradient Swatch copy 24"
          x1={-6888.92}
          y1={651.21}
          x2={-6820.33}
          y2={635.83}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-88"
          data-name="New Gradient Swatch copy 24"
          x1={7139.33}
          y1={650.53}
          x2={7161.36}
          y2={645.6}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-89"
          data-name="New Gradient Swatch copy 24"
          x1={-6726.87}
          y1={456.35}
          x2={-6822.72}
          y2={639.02}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-90"
          data-name="New Gradient Swatch copy 24"
          x1={-6859.55}
          y1={646.52}
          x2={-6848.22}
          y2={643.12}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-91"
          data-name="New Gradient Swatch copy 24"
          x1={-6862.66}
          y1={647.37}
          x2={-6857.7}
          y2={646.26}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-92"
          data-name="New Gradient Swatch copy 24"
          x1={-6852.96}
          y1={635.45}
          x2={-6858.39}
          y2={645.8}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-93"
          data-name="New Gradient Swatch copy 24"
          x1={-6860.22}
          y1={647.72}
          x2={-6857.07}
          y2={646.78}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-94"
          data-name="New Gradient Swatch copy 24"
          x1={-6864.36}
          y1={647.98}
          x2={-6857.39}
          y2={646.42}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-95"
          data-name="New Gradient Swatch copy 24"
          x1={-6859.57}
          y1={644.01}
          x2={-6860.62}
          y2={646.01}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-387"
          x1={7284.07}
          y1={662.83}
          x2={7279.13}
          y2={656.21}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-96"
          data-name="New Gradient Swatch copy 24"
          x1={7276.75}
          y1={660.83}
          x2={7282.23}
          y2={660.83}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-388"
          x1={7291.46}
          y1={667.12}
          x2={7286.52}
          y2={660.5}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-97"
          data-name="New Gradient Swatch copy 24"
          x1={7284.15}
          y1={665.12}
          x2={7289.62}
          y2={665.12}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-98"
          data-name="New Gradient Swatch copy 24"
          x1={7163.65}
          y1={665.48}
          x2={7185.67}
          y2={660.55}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-389"
          x1={7353.5}
          y1={703.39}
          x2={7348.56}
          y2={696.77}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-99"
          data-name="New Gradient Swatch copy 24"
          x1={7346.19}
          y1={701.39}
          x2={7351.66}
          y2={701.39}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-390"
          x1={7360.89}
          y1={707.69}
          x2={7355.95}
          y2={701.06}
          xlinkHref="#prefix__linear-gradient-352"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-100"
          data-name="New Gradient Swatch copy 24"
          x1={7353.58}
          y1={705.68}
          x2={7359.05}
          y2={705.68}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-391"
          x1={-6932.57}
          y1={692.18}
          x2={-6749.41}
          y2={637.33}
          xlinkHref="#prefix__linear-gradient-356"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-101"
          data-name="New Gradient Swatch copy 24"
          x1={-6981.4}
          y1={705.97}
          x2={-6912.81}
          y2={690.6}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-102"
          data-name="New Gradient Swatch copy 24"
          x1={-6985.69}
          y1={710.85}
          x2={-6910.02}
          y2={693.89}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-103"
          data-name="New Gradient Swatch copy 24"
          x1={7233.08}
          y1={706.04}
          x2={7255.11}
          y2={701.11}
          gradientTransform="translate(-6330.5)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-104"
          data-name="New Gradient Swatch copy 24"
          x1={-6821.17}
          y1={513.76}
          x2={-6915.66}
          y2={693.83}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-105"
          data-name="New Gradient Swatch copy 24"
          x1={-6952.24}
          y1={700.71}
          x2={-6940.92}
          y2={697.32}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-106"
          data-name="New Gradient Swatch copy 24"
          x1={-6955.36}
          y1={701.56}
          x2={-6950.4}
          y2={700.45}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-107"
          data-name="New Gradient Swatch copy 24"
          x1={-6945.66}
          y1={689.65}
          x2={-6951.08}
          y2={699.99}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-108"
          data-name="New Gradient Swatch copy 24"
          x1={-6952.92}
          y1={701.92}
          x2={-6949.76}
          y2={700.97}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-109"
          data-name="New Gradient Swatch copy 24"
          x1={-6957.05}
          y1={702.18}
          x2={-6950.09}
          y2={700.62}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-110"
          data-name="New Gradient Swatch copy 24"
          x1={-6952.26}
          y1={698.2}
          x2={-6953.32}
          y2={700.21}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-392"
          x1={14654.79}
          y1={458.24}
          x2={14639.71}
          y2={466.5}
          gradientTransform="translate(-14307.95)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__linear-gradient-393"
          x1={14653.34}
          y1={465.5}
          x2={14647.31}
          y2={455.89}
          gradientTransform="translate(-14307.95)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} stopColor="#c1a1d5" />
          <stop offset={0.23} stopColor="#c9abdc" />
          <stop offset={0.36} stopColor="#d1b4e3" />
          <stop offset={0.43} stopColor="#b395d6" />
          <stop offset={0.49} stopColor="#9c7dcb" />
          <stop offset={0.56} stopColor="#8e6ec5" />
          <stop offset={0.61} stopColor="#8969c3" />
          <stop offset={0.76} stopColor="#9471c6" />
          <stop offset={1} stopColor="#b086cc" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-394"
          x1={-15882.66}
          y1={456.79}
          x2={-15871.55}
          y2={462.55}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#8770b8" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-395"
          x1={-15874.07}
          y1={457.3}
          x2={-15876.71}
          y2={462.65}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-396"
          x1={14668.79}
          y1={466.49}
          x2={14653.71}
          y2={474.75}
          gradientTransform="rotate(30.31 7512.66 -25944.239)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__linear-gradient-397"
          x1={14667.33}
          y1={473.74}
          x2={14661.3}
          y2={464.14}
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-398"
          x1={-15896.66}
          y1={465.04}
          x2={-15885.55}
          y2={470.8}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-399"
          x1={-15888.06}
          y1={465.55}
          x2={-15890.7}
          y2={470.9}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-400"
          x1={-15896.4}
          y1={433.8}
          x2={-15918.97}
          y2={476.82}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#744ca1" />
          <stop offset={0.53} stopColor="#9f79c8" />
          <stop offset={0.64} stopColor="#a580cb" />
          <stop offset={0.8} stopColor="#b492d3" />
          <stop offset={0.98} stopColor="#cdb0e1" />
          <stop offset={1} stopColor="#d1b4e3" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-401"
          x1={-15977.52}
          y1={507.57}
          x2={-15966.41}
          y2={502.59}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-402"
          x1={14850.2}
          y1={567.55}
          x2={14835.12}
          y2={575.81}
          gradientTransform="translate(-14307.95)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__linear-gradient-403"
          x1={14848.75}
          y1={574.81}
          x2={14842.72}
          y2={565.2}
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-404"
          x1={-16078.07}
          y1={566.1}
          x2={-16066.96}
          y2={571.86}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-405"
          x1={-16069.48}
          y1={566.61}
          x2={-16072.12}
          y2={571.96}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-406"
          x1={14864.2}
          y1={575.8}
          x2={14849.12}
          y2={584.06}
          gradientTransform="rotate(30.31 7708.1 -25834.912)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__linear-gradient-407"
          x1={14862.74}
          y1={583.05}
          x2={14856.71}
          y2={573.45}
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-408"
          x1={-16092.07}
          y1={574.35}
          x2={-16080.96}
          y2={580.11}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-409"
          x1={-16083.47}
          y1={574.86}
          x2={-16086.11}
          y2={580.21}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-410"
          x1={-16091.81}
          y1={543.11}
          x2={-16114.38}
          y2={586.13}
          xlinkHref="#prefix__linear-gradient-400"
        />
        <linearGradient
          id="prefix__linear-gradient-411"
          x1={-16172.93}
          y1={616.88}
          x2={-16161.82}
          y2={611.9}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-412"
          x1={15041.87}
          y1={679.63}
          x2={15026.79}
          y2={687.89}
          gradientTransform="translate(-14307.95)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__linear-gradient-413"
          x1={15040.41}
          y1={686.88}
          x2={15034.38}
          y2={677.28}
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-414"
          x1={-16269.74}
          y1={678.18}
          x2={-16258.63}
          y2={683.94}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-415"
          x1={-16261.14}
          y1={678.68}
          x2={-16263.78}
          y2={684.03}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-416"
          x1={15055.87}
          y1={687.88}
          x2={15040.78}
          y2={696.14}
          gradientTransform="translate(-14307.95)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__linear-gradient-417"
          x1={15054.41}
          y1={695.13}
          x2={15048.38}
          y2={685.53}
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-418"
          x1={-16283.73}
          y1={686.42}
          x2={-16272.62}
          y2={692.18}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-419"
          x1={-16275.14}
          y1={686.93}
          x2={-16277.78}
          y2={692.28}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-420"
          x1={-16283.47}
          y1={655.18}
          x2={-16306.05}
          y2={698.2}
          xlinkHref="#prefix__linear-gradient-400"
        />
        <linearGradient
          id="prefix__linear-gradient-421"
          x1={-16364.59}
          y1={728.96}
          x2={-16353.49}
          y2={723.98}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-422"
          x1={14475.01}
          y1={353.59}
          x2={14459.93}
          y2={361.85}
          gradientTransform="translate(-14307.95)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__linear-gradient-423"
          x1={14473.55}
          y1={360.84}
          x2={14467.52}
          y2={351.24}
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-424"
          x1={-15702.88}
          y1={352.14}
          x2={-15691.77}
          y2={357.9}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-425"
          x1={-15694.28}
          y1={352.65}
          x2={-15696.92}
          y2={358}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-426"
          x1={14489.01}
          y1={361.84}
          x2={14473.92}
          y2={370.1}
          gradientTransform="translate(-14307.95)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_35"
        />
        <linearGradient
          id="prefix__linear-gradient-427"
          x1={14487.55}
          y1={369.09}
          x2={14481.52}
          y2={359.49}
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-428"
          x1={-15716.87}
          y1={360.39}
          x2={-15705.76}
          y2={366.15}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__linear-gradient-429"
          x1={-15708.28}
          y1={360.89}
          x2={-15710.92}
          y2={366.24}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          xlinkHref="#prefix__linear-gradient-393"
        />
        <linearGradient
          id="prefix__linear-gradient-430"
          x1={-15716.61}
          y1={329.14}
          x2={-15739.19}
          y2={372.17}
          xlinkHref="#prefix__linear-gradient-400"
        />
        <linearGradient
          id="prefix__linear-gradient-431"
          x1={-15797.73}
          y1={402.92}
          x2={-15786.63}
          y2={397.94}
          xlinkHref="#prefix__linear-gradient-394"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_27"
          data-name="New Gradient Swatch copy 27"
          x1={-6652.05}
          y1={474}
          x2={-6451.99}
          y2={414.09}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#ab1919" />
          <stop offset={0.27} stopColor="#ba1414" />
          <stop offset={0.72} stopColor="#e30808" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_27-2"
          x1={-6706.99}
          y1={489.11}
          x2={-6619.5}
          y2={469.5}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_27"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_27-3"
          x1={-6535.69}
          y1={278.66}
          x2={-6631.54}
          y2={461.33}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_27"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_28"
          data-name="New Gradient Swatch copy 28"
          x1={-2546.59}
          y1={565.22}
          x2={-2346.25}
          y2={505.23}
          gradientTransform="matrix(-1 0 0 1 -1809.83 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#0d0202" />
          <stop offset={0.3} stopColor="#1a1111" />
          <stop offset={0.82} stopColor="#3c3a3a" />
          <stop offset={1} stopColor="#494949" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_28-2"
          x1={-2601.6}
          y1={580.35}
          x2={-2513.99}
          y2={560.71}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_28"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_28-3"
          x1={-2430.06}
          y1={369.6}
          x2={-2526.04}
          y2={552.53}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_28"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_27-4"
          x1={-2642.47}
          y1={621.8}
          x2={-2442.13}
          y2={561.81}
          gradientTransform="matrix(-1 0 0 1 -1809.83 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_27"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_27-5"
          x1={-2697.48}
          y1={636.93}
          x2={-2609.87}
          y2={617.29}
          gradientTransform="matrix(-1 0 0 1 -1809.83 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_27"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_27-6"
          x1={-2525.94}
          y1={426.19}
          x2={-2621.93}
          y2={609.11}
          gradientTransform="matrix(-1 0 0 1 -1809.83 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_27"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-273"
          x1={9533.49}
          y1={669.93}
          x2={9685.22}
          y2={635.92}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_16"
          data-name="New Gradient Swatch copy 16"
          x1={9537.09}
          y1={674.4}
          x2={9681.45}
          y2={642.04}
          gradientTransform="translate(-8741.19)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#515151" />
          <stop offset={0.29} stopColor="#606060" />
          <stop offset={0.77} stopColor="#898989" />
          <stop offset={1} stopColor="#9e9e9e" />
        </linearGradient>
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-274"
          x1={-6917.15}
          y1={664.01}
          x2={-6875.65}
          y2={651.58}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_16-2"
          x1={-6916.33}
          y1={669.03}
          x2={-6880.39}
          y2={658.27}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_16"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-275"
          x1={-6840.45}
          y1={546.73}
          x2={-6893.07}
          y2={647}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-276"
          x1={9607.15}
          y1={681.45}
          x2={9705.81}
          y2={659.34}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-277"
          x1={9637.95}
          y1={672.49}
          x2={9648.85}
          y2={650.96}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_16-3"
          x1={9605.3}
          y1={689.26}
          x2={9681.54}
          y2={672.16}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_16"
        />
        <linearGradient
          id="prefix__linear-gradient-432"
          x1={9632.95}
          y1={663.63}
          x2={9641.75}
          y2={663.63}
          gradientTransform="translate(-8741.19)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#171717" />
          <stop offset={1} stopColor="#222" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-433"
          x1={9625.95}
          y1={666.18}
          x2={9649.26}
          y2={660.96}
          gradientTransform="translate(-8741.19)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#252525" />
          <stop offset={1} stopColor="#2d2d2d" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-434"
          x1={9642.97}
          y1={669.43}
          x2={9651.77}
          y2={669.43}
          xlinkHref="#prefix__linear-gradient-432"
        />
        <linearGradient
          id="prefix__linear-gradient-435"
          x1={9635.97}
          y1={671.98}
          x2={9659.28}
          y2={666.76}
          xlinkHref="#prefix__linear-gradient-433"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-278"
          x1={9656.49}
          y1={677.84}
          x2={9717.16}
          y2={659.67}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_16-4"
          x1={9657.6}
          y1={683.75}
          x2={9712.92}
          y2={667.19}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_16"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-279"
          x1={9666.82}
          y1={621.54}
          x2={9650.3}
          y2={661.26}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-280"
          x1={9649.28}
          y1={653.15}
          x2={9689.83}
          y2={641.01}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__linear-gradient-436"
          x1={9666.57}
          y1={664.02}
          x2={9675.36}
          y2={664.02}
          xlinkHref="#prefix__linear-gradient-432"
        />
        <linearGradient
          id="prefix__linear-gradient-437"
          x1={-11302.85}
          y1={666.58}
          x2={-11279.53}
          y2={661.35}
          gradientTransform="matrix(-1 0 0 1 -10361.67 0)"
          xlinkHref="#prefix__linear-gradient-433"
        />
        <linearGradient
          id="prefix__linear-gradient-438"
          x1={9656.55}
          y1={669.82}
          x2={9665.34}
          y2={669.82}
          xlinkHref="#prefix__linear-gradient-432"
        />
        <linearGradient
          id="prefix__linear-gradient-439"
          x1={-11292.83}
          y1={672.38}
          x2={-11269.51}
          y2={667.15}
          gradientTransform="matrix(-1 0 0 1 -10361.67 0)"
          xlinkHref="#prefix__linear-gradient-433"
        />
        <linearGradient
          id="prefix__linear-gradient-440"
          x1={-11303.29}
          y1={664.58}
          x2={-11279.98}
          y2={659.35}
          gradientTransform="matrix(-1 0 0 1 -10361.67 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#353535" />
          <stop offset={0.39} stopColor="#191919" />
          <stop offset={0.75} stopColor="#070707" />
          <stop offset={1} />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-441"
          x1={-11293.28}
          y1={670.38}
          x2={-11269.96}
          y2={665.15}
          xlinkHref="#prefix__linear-gradient-440"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_16-5"
          x1={9630.41}
          y1={668.9}
          x2={9676.63}
          y2={703.26}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_16"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_16-6"
          x1={9630.91}
          y1={685.64}
          x2={9701.73}
          y2={669.76}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_16"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_16-7"
          x1={9644.64}
          y1={678.64}
          x2={9710.42}
          y2={663.9}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_16"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-281"
          x1={9584.53}
          y1={633.47}
          x2={9615.18}
          y2={626.6}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-282"
          x1={-6882.66}
          y1={630.81}
          x2={-6865.15}
          y2={625.56}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-283"
          x1={-6864.31}
          y1={606.21}
          x2={-6876.14}
          y2={628.77}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-284"
          x1={9610.64}
          y1={643.49}
          x2={9633.03}
          y2={638.47}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-285"
          x1={-6900.58}
          y1={641.71}
          x2={-6888.35}
          y2={638.04}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-286"
          x1={-6887.71}
          y1={624.29}
          x2={-6895.93}
          y2={639.96}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-287"
          x1={9634.44}
          y1={657.54}
          x2={9669.6}
          y2={649.66}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-288"
          x1={-6933.01}
          y1={655.81}
          x2={-6920.77}
          y2={652.15}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_17-289"
          x1={-6913.22}
          y1={628.29}
          x2={-6926.63}
          y2={653.84}
          gradientTransform="matrix(-1 0 0 1 -6010.71 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_17"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_16-8"
          x1={9664.75}
          y1={685.96}
          x2={9721.7}
          y2={692.81}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_16"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-111"
          data-name="New Gradient Swatch copy 24"
          x1={-11301.2}
          y1={671.97}
          x2={-11278.48}
          y2={688.86}
          gradientTransform="matrix(-1 0 0 1 -10350.67 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__linear-gradient-442"
          x1={11296.79}
          y1={6289.85}
          x2={11305.1}
          y2={6290.85}
          gradientTransform="matrix(.87 -.5 0 1.15 -8848.39 -933.26)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#bebebe" />
          <stop offset={0.71} stopColor="#ebebeb" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-443"
          x1={11296.79}
          y1={6293.39}
          x2={11305.1}
          y2={6294.39}
          xlinkHref="#prefix__linear-gradient-442"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_14"
          data-name="New Gradient Swatch copy 14"
          x1={10938.18}
          y1={680.86}
          x2={10939.91}
          y2={680.86}
          gradientTransform="translate(-10002.79)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_14-2"
          data-name="New Gradient Swatch copy 14"
          x1={-12402.26}
          y1={680.92}
          x2={-12401.02}
          y2={680.92}
          gradientTransform="matrix(-1 0 0 1 -11464.87 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_14-3"
          data-name="New Gradient Swatch copy 14"
          x1={10938.01}
          y1={684.99}
          x2={10939.74}
          y2={684.99}
          gradientTransform="translate(-10002.79)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_14-4"
          data-name="New Gradient Swatch copy 14"
          x1={-12402.08}
          y1={685.05}
          x2={-12400.84}
          y2={685.05}
          gradientTransform="matrix(-1 0 0 1 -11464.87 0)"
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__linear-gradient-444"
          x1={-7122.89}
          y1={679.75}
          x2={-7142.16}
          y2={689.79}
          gradientTransform="matrix(-1 0 0 1 -6186.33 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#d4d4d4" />
          <stop offset={0.05} stopColor="#d6d6d6" />
          <stop offset={0.51} stopColor="#e1e1e1" />
          <stop offset={1} stopColor="#e5e5e5" />
        </linearGradient>
        <linearGradient
          id="prefix__linear-gradient-445"
          x1={-7122.89}
          y1={683.8}
          x2={-7142.16}
          y2={693.83}
          xlinkHref="#prefix__linear-gradient-444"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-15"
          x1={-2396.25}
          y1={464.39}
          x2={-2242.23}
          y2={418.27}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-16"
          x1={-2463.61}
          y1={599.21}
          x2={-2341.82}
          y2={649.77}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_24-112"
          data-name="New Gradient Swatch copy 24"
          x1={-2541.97}
          y1={561.77}
          x2={-2419.96}
          y2={612.99}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-17"
          x1={-2463.61}
          y1={553.04}
          x2={-2341.82}
          y2={603.61}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-14"
          x1={-2541.97}
          y1={515.6}
          x2={-2419.96}
          y2={566.82}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-18"
          x1={-2436.17}
          y1={444.54}
          x2={-2414.26}
          y2={437.98}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-15"
          x1={-2826.16}
          y1={328.65}
          x2={-2430.4}
          y2={328.65}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-19"
          x1={-2474.36}
          y1={504.27}
          x2={-2074.78}
          y2={384.62}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_37-20"
          x1={-2371.55}
          y1={438.42}
          x2={-2347.33}
          y2={482.59}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_37"
        />
        <linearGradient
          id="prefix__New_Gradient_Swatch_copy_38-16"
          x1={-2446.37}
          y1={407.97}
          x2={-2330.39}
          y2={407.97}
          xlinkHref="#prefix__New_Gradient_Swatch_copy_38"
        />
        <radialGradient
          id="prefix__radial-gradient"
          cx={-2437.84}
          cy={473.65}
          r={621.85}
          gradientTransform="matrix(1 0 0 -1 2993.51 947.3)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.59} stopColor="#fff" />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="prefix__Radial_Gradient_1"
          data-name="Radial Gradient 1"
          cx={10078.07}
          cy={489.64}
          r={4.67}
          gradientTransform="translate(-8990.36)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-2"
          data-name="Radial Gradient 1"
          cx={10067}
          cy={496.18}
          r={4.67}
          gradientTransform="translate(-8990.36)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-3"
          data-name="Radial Gradient 1"
          cx={10125.02}
          cy={497.09}
          r={2.74}
          gradientTransform="translate(-8990.36)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-4"
          data-name="Radial Gradient 1"
          cx={10118.54}
          cy={500.92}
          r={2.74}
          gradientTransform="translate(-8990.36)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-5"
          data-name="Radial Gradient 1"
          cx={10083.74}
          cy={520.79}
          r={2.74}
          gradientTransform="translate(-8990.36)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-6"
          data-name="Radial Gradient 1"
          cx={10077.26}
          cy={524.62}
          r={2.74}
          gradientTransform="translate(-8990.36)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_2"
          data-name="Radial Gradient 2"
          cx={-2478.84}
          cy={337.69}
          r={42.67}
          gradientTransform="matrix(-1 0 0 1 -1882.17 0)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_2-2"
          data-name="Radial Gradient 2"
          cx={-2624.27}
          cy={140.65}
          r={25.56}
          gradientTransform="matrix(-1 0 0 1 -1882.17 0)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-7"
          data-name="Radial Gradient 1"
          cx={6612.66}
          cy={267.08}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-8"
          data-name="Radial Gradient 1"
          cx={6620.05}
          cy={271.38}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-9"
          data-name="Radial Gradient 1"
          cx={6682.09}
          cy={307.65}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-10"
          data-name="Radial Gradient 1"
          cx={6689.48}
          cy={311.94}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-11"
          data-name="Radial Gradient 1"
          cx={6708.36}
          cy={323.49}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-12"
          data-name="Radial Gradient 1"
          cx={6715.75}
          cy={327.78}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-13"
          data-name="Radial Gradient 1"
          cx={6777.8}
          cy={364.05}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-14"
          data-name="Radial Gradient 1"
          cx={6785.19}
          cy={368.35}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-15"
          data-name="Radial Gradient 1"
          cx={6803.84}
          cy={379.78}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-16"
          data-name="Radial Gradient 1"
          cx={6811.23}
          cy={384.07}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-17"
          data-name="Radial Gradient 1"
          cx={6873.27}
          cy={420.34}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-18"
          data-name="Radial Gradient 1"
          cx={6880.66}
          cy={424.63}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-19"
          data-name="Radial Gradient 1"
          cx={6899.43}
          cy={436.13}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-20"
          data-name="Radial Gradient 1"
          cx={6906.82}
          cy={440.42}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-21"
          data-name="Radial Gradient 1"
          cx={6968.86}
          cy={476.69}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-22"
          data-name="Radial Gradient 1"
          cx={6976.25}
          cy={480.98}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-23"
          data-name="Radial Gradient 1"
          cx={6995.13}
          cy={492.53}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-24"
          data-name="Radial Gradient 1"
          cx={7002.52}
          cy={496.83}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-25"
          data-name="Radial Gradient 1"
          cx={7064.56}
          cy={533.09}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-26"
          data-name="Radial Gradient 1"
          cx={7071.95}
          cy={537.39}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-27"
          data-name="Radial Gradient 1"
          cx={7090.6}
          cy={548.82}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-28"
          data-name="Radial Gradient 1"
          cx={7097.99}
          cy={553.11}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-29"
          data-name="Radial Gradient 1"
          cx={7160.04}
          cy={589.38}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-30"
          data-name="Radial Gradient 1"
          cx={7167.43}
          cy={593.67}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-31"
          data-name="Radial Gradient 1"
          cx={7186.3}
          cy={605.23}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-32"
          data-name="Radial Gradient 1"
          cx={7193.69}
          cy={609.52}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-33"
          data-name="Radial Gradient 1"
          cx={7255.74}
          cy={645.79}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-34"
          data-name="Radial Gradient 1"
          cx={7263.13}
          cy={650.08}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-35"
          data-name="Radial Gradient 1"
          cx={7280.06}
          cy={660.74}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-36"
          data-name="Radial Gradient 1"
          cx={7287.45}
          cy={665.03}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-37"
          data-name="Radial Gradient 1"
          cx={7349.49}
          cy={701.3}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-38"
          data-name="Radial Gradient 1"
          cx={7356.88}
          cy={705.59}
          r={2.96}
          gradientTransform="translate(-6439.07)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__radial-gradient-2"
          cx={-15875.33}
          cy={459.68}
          r={1.41}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#eeebf2" />
          <stop offset={0.58} stopColor="#d4d1d8" />
          <stop offset={0.94} stopColor="#a5a6b9" />
          <stop offset={1} stopColor="#9d9eb3" />
        </radialGradient>
        <radialGradient
          id="prefix__radial-gradient-3"
          cx={-15889.51}
          cy={467.94}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-4"
          cx={-15930.55}
          cy={491.81}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-5"
          cx={-15972.68}
          cy={516.19}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-6"
          cx={-16012.89}
          cy={505.38}
          r={0.51}
          gradientTransform="matrix(-1 0 0 1 -15531.42 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#9f19ab" />
          <stop offset={0.01} stopColor="#9c19a8" />
          <stop offset={0.13} stopColor="#73127c" />
          <stop offset={0.25} stopColor="#500d56" />
          <stop offset={0.38} stopColor="#330836" />
          <stop offset={0.52} stopColor="#1c041e" />
          <stop offset={0.66} stopColor="#0c020d" />
          <stop offset={0.81} stopColor="#030003" />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="prefix__radial-gradient-7"
          cx={-15997.85}
          cy={514.03}
          r={13.18}
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-8"
          cx={-22424.99}
          cy={-11935.46}
          r={11.03}
          gradientTransform="matrix(-.09 -.18 -.88 .47 -12174 2155.94)"
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-9"
          cx={-16070.74}
          cy={568.99}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-10"
          cx={-16084.92}
          cy={577.25}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-11"
          cx={-16125.96}
          cy={601.12}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-12"
          cx={-16168.09}
          cy={625.5}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-13"
          cx={-16208.3}
          cy={614.69}
          r={0.51}
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-14"
          cx={-16193.26}
          cy={623.34}
          r={13.18}
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-15"
          cx={-23366.15}
          cy={-12056.68}
          r={11.03}
          gradientTransform="matrix(-.09 -.18 -.88 .47 -12174 2155.94)"
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-16"
          cx={-16262.41}
          cy={681.06}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-17"
          cx={-16276.58}
          cy={689.32}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-18"
          cx={-16317.62}
          cy={713.2}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-19"
          cx={-16359.76}
          cy={737.57}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-20"
          cx={-16399.97}
          cy={726.76}
          r={0.51}
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-21"
          cx={-16384.92}
          cy={735.42}
          r={13.18}
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-22"
          cx={-24310.73}
          cy={-12173.29}
          r={11.03}
          gradientTransform="matrix(-.09 -.18 -.88 .47 -12174 2155.94)"
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-23"
          cx={-15695.55}
          cy={355.03}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-24"
          cx={-15709.72}
          cy={363.28}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-25"
          cx={-15750.76}
          cy={387.16}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-26"
          cx={-15792.9}
          cy={411.53}
          r={1.41}
          xlinkHref="#prefix__radial-gradient-2"
        />
        <radialGradient
          id="prefix__radial-gradient-27"
          cx={-15833.11}
          cy={400.73}
          r={0.51}
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-28"
          cx={-15818.06}
          cy={409.38}
          r={13.18}
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__radial-gradient-29"
          cx={-21541.06}
          cy={-11825.85}
          r={11.03}
          gradientTransform="matrix(-.09 -.18 -.88 .47 -12174 2155.94)"
          xlinkHref="#prefix__radial-gradient-6"
        />
        <radialGradient
          id="prefix__Radial_Gradient_1-39"
          data-name="Radial Gradient 1"
          cx={9668.9}
          cy={679.61}
          r={11.99}
          gradientTransform="translate(-8741.19)"
          xlinkHref="#prefix__Linear_Gradient_1"
        />
        <radialGradient
          id="prefix__radial-gradient-30"
          cx={-12367.82}
          cy={3718.61}
          r={4.51}
          gradientTransform="matrix(-.75 .33 .26 .6 -9328.26 2512.25)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#e5e5e5" />
          <stop offset={0.02} stopColor="#dcdcdc" />
          <stop offset={0.12} stopColor="#a9a9a9" />
          <stop offset={0.23} stopColor="#7c7c7c" />
          <stop offset={0.34} stopColor="#565656" />
          <stop offset={0.45} stopColor="#363636" />
          <stop offset={0.57} stopColor="#1e1e1e" />
          <stop offset={0.7} stopColor="#0d0d0d" />
          <stop offset={0.83} stopColor="#030303" />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="prefix__radial-gradient-31"
          cx={-12365.85}
          cy={3724.3}
          r={4.51}
          xlinkHref="#prefix__radial-gradient-30"
        />
        <radialGradient
          id="prefix__radial-gradient-32"
          cx={9653.87}
          cy={659.96}
          r={15.44}
          gradientTransform="translate(-8741.19)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#515151" />
          <stop offset={0.16} stopColor="#363636" />
          <stop offset={0.34} stopColor="#1e1e1e" />
          <stop offset={0.53} stopColor="#0d0d0d" />
          <stop offset={0.74} stopColor="#030303" />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="prefix__radial-gradient-33"
          cx={9655.44}
          cy={654.65}
          r={7.85}
          xlinkHref="#prefix__radial-gradient-32"
        />
        <clipPath id="prefix__clip-path">
          <path
            fill="none"
            d="M1120.82 826.91v2.44l3.66-2.1v-2.44l-3.66 2.1z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-2">
          <path
            fill="none"
            d="M1120.83 826.91v2.44L0 179.66v-2.44l1120.83 649.69z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-3">
          <path
            fill="none"
            d="M0 177.22l1120.83 649.69 3.65-2.1L3.65 175.12 0 177.22z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-4">
          <path
            fill="none"
            d="M1137.84 817.09v2.45l3.66-2.11v-2.44l-3.66 2.1z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-5">
          <path
            fill="none"
            d="M1137.85 817.09v2.45L17.02 169.84v-2.43l1120.83 649.68z"
          />
        </clipPath>
        <clipPath id="prefix__clip-path-6">
          <path
            fill="none"
            d="M17.02 167.41l1120.83 649.68 3.65-2.1L20.66 165.3l-3.64 2.11z"
          />
        </clipPath>
        <mask
          id="prefix__mask"
          x={0}
          y={146.68}
          width={1143.76}
          height={684.97}
          maskUnits="userSpaceOnUse"
        >
          <path
            transform="rotate(-180 555.67 473.65)"
            fill="url(#prefix__radial-gradient)"
            d="M.71 146.68h1109.92v653.94H.71z"
          />
        </mask>
      </defs>
      <g style={{ isolation: "isolate" }}>
        <g id="prefix__Layer_2" data-name="Layer 2">
          <g id="prefix__Layer_1-2" data-name="Layer 1">
            <g id="prefix__drayagbackground">
              <g id="prefix__ship">
                <path
                  d="M361.75 162.21v55.71s434.33 248.69 528 302.6c104.64 60.25 227.45 31.37 250.56-2.77 27.23-40.29-778.56-355.54-778.56-355.54z"
                  fill="url(#prefix__linear-gradient)"
                />
                <path
                  d="M361.75 169.67v23C442 239.2 843.63 471.9 899.5 502.59c57.67 31.68 107.75 46 147.16 51.71 24 3.51 48.73.32 70.34-10.73 10.84-5.54 18.87-11.85 23.28-18.36 27.26-40.29-778.53-355.54-778.53-355.54z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_5)"
                />
                <path
                  d="M361.75 162.21v55.71s434.33 248.69 528 302.6c104.64 60.25 227.45 31.37 250.56-2.77 27.23-40.29-778.56-355.54-778.56-355.54z"
                  style={{ mixBlendMode: "multiply" }}
                  fill="url(#prefix__linear-gradient-2)"
                />
                <path
                  d="M361.75 169.67l162.4-94.45s453.9 261.6 551.74 318.68c43.26 25.23 63.81 56.18 73.27 82.85 11.56 32.57-13.18 66.51-47.74 66-42.56-.64-108-11.17-187.92-55.08-62.13-34.13-551.75-318-551.75-318z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_17)"
                />
                <path
                  d="M1101.41 532.54c-41.17-.62-107.25-10.69-188.23-55.17-61.42-33.73-546.86-315.16-551.75-318l-1-.57 163.72-95.22.33.2C529 66.39 979.5 326 1076.23 382.46c36.84 21.49 61.59 49.48 73.55 83.2a49.34 49.34 0 01-6.29 45.49 49.94 49.94 0 01-41.29 21.39zM363.07 158.8c24.69 14.31 490.66 284.42 550.74 317.42 80.75 44.35 146.6 54.39 187.62 55h.77a48.65 48.65 0 0040.21-20.83 48 48 0 006.13-44.29c-11.85-33.41-36.41-61.17-73-82.5C980 327.82 538.65 73.47 524.16 65.11z"
                  fill="#9a9a9a"
                />
                <path
                  d="M1101.41 537.52c-41.17-.62-107.25-10.68-188.23-55.16-61.42-33.74-546.86-315.17-551.75-318l-1-.57 163.72-95.22.33.19C529 71.38 979.5 331 1076.23 387.44c36.84 21.5 61.59 49.49 73.55 83.2a49.33 49.33 0 01-6.29 45.49 49.94 49.94 0 01-41.29 21.39zM363.07 163.78c24.69 14.31 490.66 284.42 550.74 317.42 80.75 44.35 146.6 54.39 187.62 55h.77a48.65 48.65 0 0040.21-20.83 48 48 0 006.13-44.29c-11.85-33.41-36.41-61.17-73-82.5C980 332.8 538.65 78.45 524.16 70.09z"
                  fill="#9a9a9a"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_17-2)"
                  d="M369.89 139.09v34.32L529.59 81V46.39l-159.7 92.7z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_17-3)"
                  d="M369.91 139.09v34.32l-5.66-3.65v-34.47l5.66 3.8z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_17-4)"
                  d="M364.25 135.29l5.66 3.8 159.68-92.7-5.7-3.74-159.64 92.64z"
                />
                <path
                  d="M534.11 63.94h-6V88.5c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23)"
                />
                <path
                  d="M534.11 64c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .76 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35)"
                />
                <path
                  d="M541.76 84.46h-6v8.92c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-2)"
                />
                <path
                  d="M541.76 84.47c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-2)"
                />
                <path
                  d="M538.45 66.44h-6V91c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-3)"
                />
                <path
                  d="M538.45 66.45c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-3)"
                />
                <path
                  d="M546.11 87h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-4)"
                />
                <path
                  d="M546.11 87c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .76 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-4)"
                />
                <path
                  d="M542.79 68.94h-6V93.5c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-5)"
                />
                <path
                  d="M542.79 69c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .76 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-5)"
                />
                <path
                  d="M550.45 89.45h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-6)"
                />
                <path
                  d="M550.45 89.46c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-6)"
                />
                <path
                  d="M547.14 71.43h-6V96c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-7)"
                />
                <path
                  d="M547.14 71.45c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-7)"
                />
                <path
                  d="M554.79 92h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-8)"
                />
                <path
                  d="M554.79 92c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .75 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-8)"
                />
                <path
                  d="M551.48 73.93h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-9)"
                />
                <path
                  d="M551.48 73.94c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .81 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-9)"
                />
                <path
                  d="M559.13 94.45h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-10)"
                />
                <path
                  d="M559.13 94.46c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-10)"
                />
                <path
                  d="M555.82 76.43h-6V101c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-11)"
                />
                <path
                  d="M555.82 76.44c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-11)"
                />
                <path
                  d="M563.47 97h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-12)"
                />
                <path
                  d="M563.47 97c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .76 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-12)"
                />
                <path
                  d="M560.16 78.93h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-13)"
                />
                <path
                  d="M560.16 78.94c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .82 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-13)"
                />
                <path
                  d="M567.81 99.45h-6v8.92c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-14)"
                />
                <path
                  d="M567.81 99.46c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-14)"
                />
                <path
                  d="M564.5 81.43h-6V106c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-15)"
                />
                <path
                  d="M564.5 81.44c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-15)"
                />
                <path
                  d="M572.16 101.94h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-16)"
                />
                <path
                  d="M572.16 102c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .76 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-16)"
                />
                <path
                  d="M568.84 83.93h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-17)"
                />
                <path
                  d="M568.84 83.94c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .82 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-17)"
                />
                <path
                  d="M576.5 104.44h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-18)"
                />
                <path
                  d="M576.5 104.45c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-18)"
                />
                <path
                  d="M573.19 86.42h-6V111c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-19)"
                />
                <path
                  d="M573.19 86.44c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-19)"
                />
                <path
                  d="M580.84 106.94h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-20)"
                />
                <path
                  d="M580.84 107c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .75 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-20)"
                />
                <path
                  d="M577.53 88.92h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-21)"
                />
                <path
                  d="M577.53 88.93c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .82 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-21)"
                />
                <path
                  d="M585.18 109.44h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-22)"
                />
                <path
                  d="M585.18 109.45c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-22)"
                />
                <path
                  d="M581.87 91.42h-6V116c0 1 1.36 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-23)"
                />
                <path
                  d="M581.87 91.43c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-23)"
                />
                <path
                  d="M589.52 111.94h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-24)"
                />
                <path
                  d="M589.52 112c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .76 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-24)"
                />
                <path
                  d="M586.21 93.92h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-25)"
                />
                <path
                  d="M586.21 93.93c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .83 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-25)"
                />
                <path
                  d="M593.86 114.44h-6v8.92c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-26)"
                />
                <path
                  d="M593.86 114.45c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-26)"
                />
                <path
                  d="M590.55 96.42h-6V121c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-27)"
                />
                <path
                  d="M590.55 96.43c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-27)"
                />
                <path
                  d="M598.2 116.93h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-28)"
                />
                <path
                  d="M598.2 117c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .76 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-28)"
                />
                <path
                  d="M594.89 98.92h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-29)"
                />
                <path
                  d="M594.89 98.93c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .83 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-29)"
                />
                <path
                  d="M602.55 119.43h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-30)"
                />
                <path
                  d="M602.55 119.44c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-30)"
                />
                <path
                  d="M599.24 101.41h-6V126c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-31)"
                />
                <path
                  d="M599.24 101.43c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-31)"
                />
                <path
                  d="M606.89 121.93h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-32)"
                />
                <path
                  d="M606.89 121.94c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .81 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-32)"
                />
                <path
                  d="M603.58 103.91h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-33)"
                />
                <path
                  d="M603.58 103.92c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .83 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-33)"
                />
                <path
                  d="M611.23 124.43h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-34)"
                />
                <path
                  d="M611.23 124.44c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-34)"
                />
                <path
                  d="M607.92 106.41h-6V131c0 1 1.36 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-35)"
                />
                <path
                  d="M607.92 106.42c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-35)"
                />
                <path
                  d="M615.57 126.93h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-36)"
                />
                <path
                  d="M615.57 126.94c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .82 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-36)"
                />
                <path
                  d="M612.26 108.91h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-37)"
                />
                <path
                  d="M612.26 108.92c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .84 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-37)"
                />
                <path
                  d="M619.91 129.43h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-38)"
                />
                <path
                  d="M619.91 129.44c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-38)"
                />
                <path
                  d="M616.6 111.41h-6V136c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-39)"
                />
                <path
                  d="M616.6 111.42c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-39)"
                />
                <path
                  d="M624.25 131.92h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-40)"
                />
                <path
                  d="M624.25 131.94c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .82 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-40)"
                />
                <path
                  d="M620.94 113.91h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-41)"
                />
                <path
                  d="M620.94 113.92c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .84 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-41)"
                />
                <path
                  d="M628.6 134.42h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-42)"
                />
                <path
                  d="M628.6 134.43c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-42)"
                />
                <path
                  d="M625.28 116.4h-6V141c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-43)"
                />
                <path
                  d="M625.28 116.42c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-43)"
                />
                <path
                  d="M632.94 136.92h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-44)"
                />
                <path
                  d="M632.94 136.93c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .82 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-44)"
                />
                <path
                  d="M629.63 118.9h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-45)"
                />
                <path
                  d="M629.63 118.91c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-45)"
                />
                <path
                  d="M637.28 139.42h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-46)"
                />
                <path
                  d="M637.28 139.43c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-46)"
                />
                <path
                  d="M634 121.4h-6V146c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-47)"
                />
                <path
                  d="M634 121.41c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-47)"
                />
                <path
                  d="M641.62 141.92h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-48)"
                />
                <path
                  d="M641.62 141.93c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .83 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-48)"
                />
                <path
                  d="M638.31 123.9h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-49)"
                />
                <path
                  d="M638.31 123.91c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-49)"
                />
                <path
                  d="M646 144.42h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-50)"
                />
                <path
                  d="M646 144.43c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-50)"
                />
                <path
                  d="M642.65 126.4h-6V151c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-51)"
                />
                <path
                  d="M642.65 126.41c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-51)"
                />
                <path
                  d="M650.3 146.91h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-52)"
                />
                <path
                  d="M650.3 146.93c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .83 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-52)"
                />
                <path
                  d="M647 128.9h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-53)"
                />
                <path
                  d="M647 128.91c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-53)"
                />
                <path
                  d="M654.65 149.41h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-54)"
                />
                <path
                  d="M654.65 149.42c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-54)"
                />
                <path
                  d="M651.33 131.39h-6V156c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-55)"
                />
                <path
                  d="M651.33 131.41c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-55)"
                />
                <path
                  d="M659 151.91h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-56)"
                />
                <path
                  d="M659 151.92c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .83 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-56)"
                />
                <path
                  d="M655.68 133.89h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-57)"
                />
                <path
                  d="M655.68 133.9c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-57)"
                />
                <path
                  d="M663.33 154.41h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-58)"
                />
                <path
                  d="M663.33 154.42c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-58)"
                />
                <path
                  d="M660 136.39h-6V161c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-59)"
                />
                <path
                  d="M660 136.4c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-59)"
                />
                <path
                  d="M667.67 156.91h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-60)"
                />
                <path
                  d="M667.67 156.92c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .84 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-60)"
                />
                <path
                  d="M664.36 138.89h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-61)"
                />
                <path
                  d="M664.36 138.9c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-61)"
                />
                <path
                  d="M672 159.41h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-62)"
                />
                <path
                  d="M672 159.42c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-62)"
                />
                <path
                  d="M668.7 141.39h-6V166c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-63)"
                />
                <path
                  d="M668.7 141.4c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-63)"
                />
                <path
                  d="M676.35 161.9h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_23-64)"
                />
                <path
                  d="M676.35 161.92c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .84 3 1.76z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_35-64)"
                />
                <g>
                  <path
                    d="M673 143.89h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-65)"
                  />
                  <path
                    d="M673 143.9c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-65)"
                  />
                  <g>
                    <path
                      d="M680.7 164.4h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-66)"
                    />
                    <path
                      d="M680.7 164.41c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-66)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M677.38 146.38h-6V171c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-67)"
                  />
                  <path
                    d="M677.38 146.4c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-67)"
                  />
                  <g>
                    <path
                      d="M685 166.9h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-68)"
                    />
                    <path
                      d="M685 166.91c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-68)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M681.73 148.88h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-69)"
                  />
                  <path
                    d="M681.73 148.89c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-69)"
                  />
                  <g>
                    <path
                      d="M689.38 169.4h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-70)"
                    />
                    <path
                      d="M689.38 169.41c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-70)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M686.07 151.38h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-71)"
                  />
                  <path
                    d="M686.07 151.39c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-71)"
                  />
                  <g>
                    <path
                      d="M693.72 171.9h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-72)"
                    />
                    <path
                      d="M693.72 171.91c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-72)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M690.41 153.88h-6v24.56c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-73)"
                  />
                  <path
                    d="M690.41 153.89c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-73)"
                  />
                  <g>
                    <path
                      d="M698.06 174.4h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-74)"
                    />
                    <path
                      d="M698.06 174.41c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-74)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M694.75 156.38h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-75)"
                  />
                  <path
                    d="M694.75 156.39c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-75)"
                  />
                  <g>
                    <path
                      d="M702.4 176.89h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-76)"
                    />
                    <path
                      d="M702.4 176.91c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-76)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M699.09 158.88h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-77)"
                  />
                  <path
                    d="M699.09 158.89c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-77)"
                  />
                  <g>
                    <path
                      d="M706.74 179.39h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-78)"
                    />
                    <path
                      d="M706.74 179.4c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-78)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M703.43 161.37h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-79)"
                  />
                  <path
                    d="M703.43 161.39c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-79)"
                  />
                  <g>
                    <path
                      d="M711.09 181.89h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-80)"
                    />
                    <path
                      d="M711.09 181.9c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-80)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M707.78 163.87h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-81)"
                  />
                  <path
                    d="M707.78 163.88c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-81)"
                  />
                  <g>
                    <path
                      d="M715.43 184.39h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-82)"
                    />
                    <path
                      d="M715.43 184.4c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-82)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M712.12 166.37h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-83)"
                  />
                  <path
                    d="M712.12 166.38c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-83)"
                  />
                  <g>
                    <path
                      d="M719.77 186.89h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-84)"
                    />
                    <path
                      d="M719.77 186.9c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-84)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M716.46 168.87h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-85)"
                  />
                  <path
                    d="M716.46 168.88c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-85)"
                  />
                  <g>
                    <path
                      d="M724.11 189.39h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-86)"
                    />
                    <path
                      d="M724.11 189.4c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-86)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M720.8 171.37h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-87)"
                  />
                  <path
                    d="M720.8 171.38c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-87)"
                  />
                  <g>
                    <path
                      d="M728.45 191.88h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-88)"
                    />
                    <path
                      d="M728.45 191.9c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-88)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M725.14 173.87h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-89)"
                  />
                  <path
                    d="M725.14 173.88c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-89)"
                  />
                  <g>
                    <path
                      d="M732.79 194.38h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-90)"
                    />
                    <path
                      d="M732.79 194.39c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-90)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M729.48 176.36h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-91)"
                  />
                  <path
                    d="M729.48 176.38c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-91)"
                  />
                  <g>
                    <path
                      d="M737.14 196.88h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-92)"
                    />
                    <path
                      d="M737.14 196.89c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-92)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M733.82 178.86h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-93)"
                  />
                  <path
                    d="M733.82 178.87c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-93)"
                  />
                  <g>
                    <path
                      d="M741.48 199.38h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-94)"
                    />
                    <path
                      d="M741.48 199.39c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-94)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M738.17 181.36h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-95)"
                  />
                  <path
                    d="M738.17 181.37c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-95)"
                  />
                  <g>
                    <path
                      d="M745.82 201.88h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-96)"
                    />
                    <path
                      d="M745.82 201.89c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-96)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M742.51 183.86h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-97)"
                  />
                  <path
                    d="M742.51 183.87c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-97)"
                  />
                  <g>
                    <path
                      d="M750.16 204.38h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-98)"
                    />
                    <path
                      d="M750.16 204.39c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-98)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M746.85 186.36h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-99)"
                  />
                  <path
                    d="M746.85 186.37c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-99)"
                  />
                  <g>
                    <path
                      d="M754.5 206.87h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-100)"
                    />
                    <path
                      d="M754.5 206.89c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-100)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M751.19 188.86h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-101)"
                  />
                  <path
                    d="M751.19 188.87c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-101)"
                  />
                  <g>
                    <path
                      d="M758.84 209.37h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-102)"
                    />
                    <path
                      d="M758.84 209.38c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-102)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M755.53 191.35h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-103)"
                  />
                  <path
                    d="M755.53 191.37c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-103)"
                  />
                  <g>
                    <path
                      d="M763.19 211.87h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-104)"
                    />
                    <path
                      d="M763.19 211.88c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-104)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M759.87 193.85h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-105)"
                  />
                  <path
                    d="M759.87 193.86c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-105)"
                  />
                  <g>
                    <path
                      d="M767.53 214.37h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-106)"
                    />
                    <path
                      d="M767.53 214.38c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-106)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M764.22 196.35h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-107)"
                  />
                  <path
                    d="M764.22 196.36c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-107)"
                  />
                  <g>
                    <path
                      d="M771.87 216.87h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-108)"
                    />
                    <path
                      d="M771.87 216.88c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-108)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M768.56 198.85h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-109)"
                  />
                  <path
                    d="M768.56 198.86c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-109)"
                  />
                  <g>
                    <path
                      d="M776.21 219.37h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-110)"
                    />
                    <path
                      d="M776.21 219.38c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-110)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M772.9 201.35h-6v24.56c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-111)"
                  />
                  <path
                    d="M772.9 201.36c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-111)"
                  />
                  <g>
                    <path
                      d="M780.55 221.86h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-112)"
                    />
                    <path
                      d="M780.55 221.88c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-112)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M777.24 203.85h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-113)"
                  />
                  <path
                    d="M777.24 203.86c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-113)"
                  />
                  <g>
                    <path
                      d="M784.89 224.36h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-114)"
                    />
                    <path
                      d="M784.89 224.37c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-114)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M781.58 206.34h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-115)"
                  />
                  <path
                    d="M781.58 206.36c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-115)"
                  />
                  <g>
                    <path
                      d="M789.23 226.86h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-116)"
                    />
                    <path
                      d="M789.23 226.87c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-116)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M785.92 208.84h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-117)"
                  />
                  <path
                    d="M785.92 208.85c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-117)"
                  />
                  <g>
                    <path
                      d="M793.58 229.36h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-118)"
                    />
                    <path
                      d="M793.58 229.37c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-118)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M790.27 211.34h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-119)"
                  />
                  <path
                    d="M790.27 211.35c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-119)"
                  />
                  <g>
                    <path
                      d="M797.92 231.86h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-120)"
                    />
                    <path
                      d="M797.92 231.87c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-120)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M794.61 213.84h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-121)"
                  />
                  <path
                    d="M794.61 213.85c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-121)"
                  />
                  <g>
                    <path
                      d="M802.26 234.36h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-122)"
                    />
                    <path
                      d="M802.26 234.37c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-122)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M799 216.34h-6v24.56c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-123)"
                  />
                  <path
                    d="M799 216.35c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-123)"
                  />
                  <g>
                    <path
                      d="M806.6 236.85h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-124)"
                    />
                    <path
                      d="M806.6 236.87c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-124)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M803.29 218.84h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-125)"
                  />
                  <path
                    d="M803.29 218.85c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-125)"
                  />
                  <g>
                    <path
                      d="M810.94 239.35h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-126)"
                    />
                    <path
                      d="M810.94 239.36c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-126)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M807.63 221.33h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-127)"
                  />
                  <path
                    d="M807.63 221.35c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-127)"
                  />
                  <g>
                    <path
                      d="M815.28 241.85h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-128)"
                    />
                    <path
                      d="M815.28 241.86c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-128)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M812 223.83h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-129)"
                  />
                  <path
                    d="M812 223.84c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-129)"
                  />
                  <g>
                    <path
                      d="M819.63 244.35h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-130)"
                    />
                    <path
                      d="M819.63 244.36c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-130)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M816.31 226.33h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-131)"
                  />
                  <path
                    d="M816.31 226.34c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-131)"
                  />
                  <g>
                    <path
                      d="M824 246.85h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-132)"
                    />
                    <path
                      d="M824 246.86c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-132)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M820.66 228.83h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-133)"
                  />
                  <path
                    d="M820.66 228.84c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-133)"
                  />
                  <g>
                    <path
                      d="M828.31 249.35h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-134)"
                    />
                    <path
                      d="M828.31 249.36c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-134)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M825 231.33h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-135)"
                  />
                  <path
                    d="M825 231.34c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-135)"
                  />
                  <g>
                    <path
                      d="M832.65 251.84h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-136)"
                    />
                    <path
                      d="M832.65 251.86c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-136)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M829.34 233.83h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-137)"
                  />
                  <path
                    d="M829.34 233.84c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-137)"
                  />
                  <g>
                    <path
                      d="M837 254.34h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-138)"
                    />
                    <path
                      d="M837 254.35c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-138)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M833.68 236.32h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-139)"
                  />
                  <path
                    d="M833.68 236.34c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-139)"
                  />
                  <g>
                    <path
                      d="M841.33 256.84h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-140)"
                    />
                    <path
                      d="M841.33 256.85c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-140)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M838 238.82h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-141)"
                  />
                  <path
                    d="M838 238.83c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-141)"
                  />
                  <g>
                    <path
                      d="M845.68 259.34h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-142)"
                    />
                    <path
                      d="M845.68 259.35c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-142)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M842.36 241.32h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-143)"
                  />
                  <path
                    d="M842.36 241.33c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-143)"
                  />
                  <g>
                    <path
                      d="M850 261.84h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-144)"
                    />
                    <path
                      d="M850 261.85c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-144)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M846.71 243.82h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-145)"
                  />
                  <path
                    d="M846.71 243.83c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-145)"
                  />
                  <g>
                    <path
                      d="M854.36 264.34h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-146)"
                    />
                    <path
                      d="M854.36 264.35c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-146)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M851.05 246.32h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-147)"
                  />
                  <path
                    d="M851.05 246.33c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-147)"
                  />
                  <g>
                    <path
                      d="M858.7 266.83h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-148)"
                    />
                    <path
                      d="M858.7 266.85c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-148)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M855.39 248.82h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-149)"
                  />
                  <path
                    d="M855.39 248.83c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-149)"
                  />
                  <g>
                    <path
                      d="M863 269.33h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-150)"
                    />
                    <path
                      d="M863 269.34c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-150)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M859.73 251.31h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-151)"
                  />
                  <path
                    d="M859.73 251.33c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-151)"
                  />
                  <g>
                    <path
                      d="M867.38 271.83h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-152)"
                    />
                    <path
                      d="M867.38 271.84c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-152)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M864.07 253.81h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-153)"
                  />
                  <path
                    d="M864.07 253.82c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-153)"
                  />
                  <g>
                    <path
                      d="M871.73 274.33h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-154)"
                    />
                    <path
                      d="M871.73 274.34c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-154)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M868.41 256.31h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-155)"
                  />
                  <path
                    d="M868.41 256.32c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-155)"
                  />
                  <g>
                    <path
                      d="M876.07 276.83h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-156)"
                    />
                    <path
                      d="M876.07 276.84c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-156)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M872.76 258.81h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-157)"
                  />
                  <path
                    d="M872.76 258.82c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-157)"
                  />
                  <g>
                    <path
                      d="M880.41 279.33h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-158)"
                    />
                    <path
                      d="M880.41 279.34c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-158)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M877.1 261.31h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-159)"
                  />
                  <path
                    d="M877.1 261.32c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-159)"
                  />
                  <g>
                    <path
                      d="M884.75 281.82h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-160)"
                    />
                    <path
                      d="M884.75 281.84c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-160)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M881.44 263.81h-6v24.56c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-161)"
                  />
                  <path
                    d="M881.44 263.82c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-161)"
                  />
                  <g>
                    <path
                      d="M889.09 284.32h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-162)"
                    />
                    <path
                      d="M889.09 284.33c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-162)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M885.78 266.3h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-163)"
                  />
                  <path
                    d="M885.78 266.32c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-163)"
                  />
                  <g>
                    <path
                      d="M893.43 286.82h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-164)"
                    />
                    <path
                      d="M893.43 286.83c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-164)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M890.12 268.8h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-165)"
                  />
                  <path
                    d="M890.12 268.81c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-165)"
                  />
                  <g>
                    <path
                      d="M897.77 289.32h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-166)"
                    />
                    <path
                      d="M897.77 289.33c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-166)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M894.46 271.3h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-167)"
                  />
                  <path
                    d="M894.46 271.31c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-167)"
                  />
                  <g>
                    <path
                      d="M902.12 291.82h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-168)"
                    />
                    <path
                      d="M902.12 291.83c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-168)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M898.81 273.8h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-169)"
                  />
                  <path
                    d="M898.81 273.81c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-169)"
                  />
                  <g>
                    <path
                      d="M906.46 294.32h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-170)"
                    />
                    <path
                      d="M906.46 294.33c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-170)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M903.15 276.3h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-171)"
                  />
                  <path
                    d="M903.15 276.31c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-171)"
                  />
                  <g>
                    <path
                      d="M910.8 296.81h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-172)"
                    />
                    <path
                      d="M910.8 296.83c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-172)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M907.49 278.8h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-173)"
                  />
                  <path
                    d="M907.49 278.81c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-173)"
                  />
                  <g>
                    <path
                      d="M915.14 299.31h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-174)"
                    />
                    <path
                      d="M915.14 299.32c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-174)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M911.83 281.29h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-175)"
                  />
                  <path
                    d="M911.83 281.31c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-175)"
                  />
                  <g>
                    <path
                      d="M919.48 301.81h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-176)"
                    />
                    <path
                      d="M919.48 301.82c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-176)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M916.17 283.79h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-177)"
                  />
                  <path
                    d="M916.17 283.8c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-177)"
                  />
                  <g>
                    <path
                      d="M923.82 304.31h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-178)"
                    />
                    <path
                      d="M923.82 304.32c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-178)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M920.51 286.29h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-179)"
                  />
                  <path
                    d="M920.51 286.3c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-179)"
                  />
                  <g>
                    <path
                      d="M928.17 306.81h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-180)"
                    />
                    <path
                      d="M928.17 306.82c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-180)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M924.85 288.79h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-181)"
                  />
                  <path
                    d="M924.85 288.8c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-181)"
                  />
                  <g>
                    <path
                      d="M932.51 309.31h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-182)"
                    />
                    <path
                      d="M932.51 309.32c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-182)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M929.2 291.29h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-183)"
                  />
                  <path
                    d="M929.2 291.3c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-183)"
                  />
                  <g>
                    <path
                      d="M936.85 311.8h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-184)"
                    />
                    <path
                      d="M936.85 311.82c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-184)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M933.54 293.79h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-185)"
                  />
                  <path
                    d="M933.54 293.8c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-185)"
                  />
                  <g>
                    <path
                      d="M941.19 314.3h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-186)"
                    />
                    <path
                      d="M941.19 314.31c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-186)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M937.88 296.28h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-187)"
                  />
                  <path
                    d="M937.88 296.3c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-187)"
                  />
                  <g>
                    <path
                      d="M945.53 316.8h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-188)"
                    />
                    <path
                      d="M945.53 316.81c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-188)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M942.22 298.78h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-189)"
                  />
                  <path
                    d="M942.22 298.79c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-189)"
                  />
                  <g>
                    <path
                      d="M949.87 319.3h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-190)"
                    />
                    <path
                      d="M949.87 319.31c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-190)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M946.56 301.28h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-191)"
                  />
                  <path
                    d="M946.56 301.29c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-191)"
                  />
                  <g>
                    <path
                      d="M954.22 321.8h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-192)"
                    />
                    <path
                      d="M954.22 321.81c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-192)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M950.9 303.78h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-193)"
                  />
                  <path
                    d="M950.9 303.79c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-193)"
                  />
                  <g>
                    <path
                      d="M958.56 324.3h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-194)"
                    />
                    <path
                      d="M958.56 324.31c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-194)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M955.25 306.28h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-195)"
                  />
                  <path
                    d="M955.25 306.29c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-195)"
                  />
                  <g>
                    <path
                      d="M962.9 326.79h-6v8.93c0 1 1.36 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-196)"
                    />
                    <path
                      d="M962.9 326.81c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-196)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M959.59 308.78h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-197)"
                  />
                  <path
                    d="M959.59 308.79c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-197)"
                  />
                  <g>
                    <path
                      d="M967.24 329.29h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-198)"
                    />
                    <path
                      d="M967.24 329.3c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-198)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M963.93 311.27h-6v24.57c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-199)"
                  />
                  <path
                    d="M963.93 311.29c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-199)"
                  />
                  <g>
                    <path
                      d="M971.58 331.79h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-200)"
                    />
                    <path
                      d="M971.58 331.8c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-200)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M968.27 313.77h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-201)"
                  />
                  <path
                    d="M968.27 313.78c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-201)"
                  />
                  <g>
                    <path
                      d="M975.92 334.29h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-202)"
                    />
                    <path
                      d="M975.92 334.3c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-202)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M972.61 316.27h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-203)"
                  />
                  <path
                    d="M972.61 316.28c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-203)"
                  />
                  <g>
                    <path
                      d="M980.26 336.79h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-204)"
                    />
                    <path
                      d="M980.26 336.8c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-204)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M977 318.77h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-205)"
                  />
                  <path
                    d="M977 318.78c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-205)"
                  />
                  <g>
                    <path
                      d="M984.61 339.29h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-206)"
                    />
                    <path
                      d="M984.61 339.3c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-206)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M981.3 321.27h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-207)"
                  />
                  <path
                    d="M981.3 321.28c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-207)"
                  />
                  <g>
                    <path
                      d="M989 341.78h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-208)"
                    />
                    <path
                      d="M989 341.8c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-208)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M985.64 323.77h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-209)"
                  />
                  <path
                    d="M985.64 323.78c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-209)"
                  />
                  <g>
                    <path
                      d="M993.29 344.28h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-210)"
                    />
                    <path
                      d="M993.29 344.29c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-210)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M990 326.26h-6v24.57c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-211)"
                  />
                  <path
                    d="M990 326.28c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-211)"
                  />
                  <g>
                    <path
                      d="M997.63 346.78h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-212)"
                    />
                    <path
                      d="M997.63 346.79c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-212)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M994.32 328.76h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-213)"
                  />
                  <path
                    d="M994.32 328.77c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-213)"
                  />
                  <g>
                    <path
                      d="M1002 349.28h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-214)"
                    />
                    <path
                      d="M1002 349.29c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-214)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M998.66 331.26h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-215)"
                  />
                  <path
                    d="M998.66 331.27c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-215)"
                  />
                  <g>
                    <path
                      d="M1006.31 351.78h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-216)"
                    />
                    <path
                      d="M1006.31 351.79c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-216)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1003 333.76h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-217)"
                  />
                  <path
                    d="M1003 333.77c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-217)"
                  />
                  <g>
                    <path
                      d="M1010.66 354.28h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-218)"
                    />
                    <path
                      d="M1010.66 354.29c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-218)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1007.34 336.26h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-219)"
                  />
                  <path
                    d="M1007.34 336.27c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-219)"
                  />
                  <g>
                    <path
                      d="M1015 356.77h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-220)"
                    />
                    <path
                      d="M1015 356.79c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-220)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1011.69 338.76h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-221)"
                  />
                  <path
                    d="M1011.69 338.77c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-221)"
                  />
                  <g>
                    <path
                      d="M1019.34 359.27h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-222)"
                    />
                    <path
                      d="M1019.34 359.28c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-222)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1016 341.25h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-223)"
                  />
                  <path
                    d="M1016 341.27c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-223)"
                  />
                  <g>
                    <path
                      d="M1023.68 361.77h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-224)"
                    />
                    <path
                      d="M1023.68 361.78c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-224)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1020.37 343.75h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-225)"
                  />
                  <path
                    d="M1020.37 343.76c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-225)"
                  />
                  <g>
                    <path
                      d="M1028 364.27h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-226)"
                    />
                    <path
                      d="M1028 364.28c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-226)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1024.71 346.25h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-227)"
                  />
                  <path
                    d="M1024.71 346.26c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-227)"
                  />
                  <g>
                    <path
                      d="M1032.36 366.77h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-228)"
                    />
                    <path
                      d="M1032.36 366.78c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-228)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1029.05 348.75h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-229)"
                  />
                  <path
                    d="M1029.05 348.76c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-229)"
                  />
                  <g>
                    <path
                      d="M1036.71 369.27h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-230)"
                    />
                    <path
                      d="M1036.71 369.28c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-230)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1033.39 351.25h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-231)"
                  />
                  <path
                    d="M1033.39 351.26c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-231)"
                  />
                  <g>
                    <path
                      d="M1041.05 371.76h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-232)"
                    />
                    <ellipse
                      cx={1038.03}
                      cy={371.78}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-232)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1037.74 353.75h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-233)"
                  />
                  <path
                    d="M1037.74 353.76c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-233)"
                  />
                  <g>
                    <path
                      d="M1045.39 374.26h-6v8.93c0 1 1.36 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-234)"
                    />
                    <ellipse
                      cx={1042.37}
                      cy={374.27}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-234)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1042.08 356.24h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-235)"
                  />
                  <path
                    d="M1042.08 356.26c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-235)"
                  />
                  <g>
                    <path
                      d="M1049.73 376.76h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-236)"
                    />
                    <ellipse
                      cx={1046.71}
                      cy={376.77}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-236)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1046.42 358.74h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-237)"
                  />
                  <path
                    d="M1046.42 358.75c0 1-1.35 1.76-3 1.76s-3-.78-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-237)"
                  />
                  <g>
                    <path
                      d="M1054.07 379.26h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-238)"
                    />
                    <ellipse
                      cx={1051.05}
                      cy={379.27}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-238)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1050.76 361.24h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-239)"
                  />
                  <path
                    d="M1050.76 361.25c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-239)"
                  />
                  <g>
                    <path
                      d="M1058.41 381.76h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-240)"
                    />
                    <ellipse
                      cx={1055.4}
                      cy={381.77}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-240)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1055.1 363.74h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-241)"
                  />
                  <path
                    d="M1055.1 363.75c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-241)"
                  />
                  <g>
                    <path
                      d="M1062.76 384.26h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-242)"
                    />
                    <ellipse
                      cx={1059.74}
                      cy={384.27}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-242)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1059.44 366.24h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-243)"
                  />
                  <path
                    d="M1059.44 366.25c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-243)"
                  />
                  <g>
                    <path
                      d="M1067.1 386.75h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-244)"
                    />
                    <ellipse
                      cx={1064.08}
                      cy={386.77}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-244)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1063.79 368.74h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-245)"
                  />
                  <path
                    d="M1063.79 368.75c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-245)"
                  />
                  <g>
                    <path
                      d="M1071.44 389.25h-6v8.93c0 1 1.36 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-246)"
                    />
                    <ellipse
                      cx={1068.42}
                      cy={389.26}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-246)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1068.13 371.23h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-247)"
                  />
                  <path
                    d="M1068.13 371.25c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-247)"
                  />
                  <g>
                    <path
                      d="M1075.78 391.75h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-248)"
                    />
                    <ellipse
                      cx={1072.76}
                      cy={391.76}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-248)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1072.47 373.73h-6v24.56c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-249)"
                  />
                  <path
                    d="M1072.47 373.74c0 1-1.35 1.76-3 1.76s-3-.78-3-1.76 1.36-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-249)"
                  />
                  <g>
                    <path
                      d="M1080.12 394.25h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-250)"
                    />
                    <ellipse
                      cx={1077.1}
                      cy={394.26}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-250)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1076.81 376.23h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-251)"
                  />
                  <path
                    d="M1076.81 376.24c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-251)"
                  />
                  <g>
                    <path
                      d="M1084.46 396.75h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-252)"
                    />
                    <ellipse
                      cx={1081.45}
                      cy={396.76}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-252)"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M1081.15 378.73h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_23-253)"
                  />
                  <path
                    d="M1081.15 378.74c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_35-253)"
                  />
                  <g>
                    <path
                      d="M1088.8 399.25h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_23-254)"
                    />
                    <ellipse
                      cx={1085.79}
                      cy={399.26}
                      rx={3.02}
                      ry={1.76}
                      fill="url(#prefix__New_Gradient_Swatch_copy_35-254)"
                    />
                  </g>
                </g>
                <g id="prefix__containercollection2">
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_6)"
                    d="M693.14 183.37v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#eedede"
                    d="M691.03 184.59l-85.01-49.35v25.07l85.01 49.43v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M688.33 183.37l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M689.49 184.01l.42-.02v25.16l-.42.01v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M684.68 181.25l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M685.84 181.89l.43-.02v25.16l-.43.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M681.03 179.14l1.11.73v25.16l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M682.2 179.77l.42-.02v25.16l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M677.38 177.02l1.12.73v25.16l-1.12-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M678.55 177.65l.42-.02v25.16l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M673.74 174.9l1.11.73v25.16l-1.11-.73V174.9z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M674.9 175.53l.42-.02v25.16l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M670.09 172.78l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M671.25 173.41l.42-.01v25.15l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M666.44 170.66l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M667.61 171.3l.42-.02v25.16l-.42.01V171.3z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M662.79 168.54l1.12.74v25.16l-1.12-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M663.96 169.18l.42-.02v25.16l-.42.01v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M659.15 166.42l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M660.31 167.06l.42-.02v25.16l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M655.5 164.31l1.11.73v25.16l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M656.66 164.94l.43-.02v25.16l-.43.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M651.85 162.19l1.11.73v25.16l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M653.01 162.82l.43-.02v25.16l-.43.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#7d0000"
                    d="M648.2 160.07l1.12.73v25.16l-1.12-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3a8a8"
                    d="M649.37 160.7l.42-.02v25.16l-.42.02V160.7z"
                  />
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M644.55 157.95l1.12.74v25.15l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M645.72 158.58l.42-.02v25.16l-.42.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M640.91 155.83l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M642.07 156.47l.42-.02v25.15l-.42.02v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M637.26 153.71l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M638.42 154.35l.43-.02v25.16l-.43.01v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M633.61 151.59l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M634.78 152.23l.42-.02v25.16l-.42.01v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M629.97 149.47l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M631.13 150.11l.42-.02v25.16l-.42.01v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M626.32 147.35l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M627.48 147.99l.42-.02v25.16l-.42.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M622.67 145.24l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M623.83 145.87l.43-.02v25.16l-.43.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M619.02 143.12l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M620.18 143.75l.43-.02v25.16l-.43.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M615.37 141l1.12.74v25.15l-1.12-.73V141z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M616.54 141.63l.42-.01v25.15l-.42.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M611.73 138.88l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M612.89 139.51l.42-.01v25.16l-.42.01v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M608.08 136.76l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M609.24 137.4l.42-.02v25.16l-.42.01V137.4z"
                    />
                  </g>
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e1c4c4"
                    d="M691.03 184.59v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_6-2)"
                    d="M693.13 183.37v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#eedede"
                    d="M715.5 198.26v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_6-3)"
                    d="M717.61 169.38l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                  />
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M697.3 183.82l-1.11.74v25.16l1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M696.14 184.46l-.42-.02v25.16l.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M700.6 181.89l-1.11.73v25.16l1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M699.43 182.52l-.42-.02v25.16l.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M703.9 179.95l-1.12.74v25.15l1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M702.73 180.58l-.42-.02v25.16l.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M707.19 178.01l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M706.03 178.65l-.42-.02v25.16l.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M710.49 176.07l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M709.33 176.71l-.43-.02v25.16l.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M713.79 174.14l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M712.62 174.77l-.42-.02v25.16l.42.02v-25.16z"
                      />
                    </g>
                  </g>
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e1c4c4"
                    d="M694.6 185.04v2.21l20.51-12v22.86l.74-.43v-25.07l-21.25 12.43z"
                  />
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12)"
                      d="M693.14 152.34v30.05L603.9 130.5v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M691.03 153.56l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M688.33 152.34l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M689.49 152.98l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M684.68 150.23l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M685.84 150.86l.43-.02V176l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M681.03 148.11l1.11.73V174l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M682.2 148.74l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M677.38 145.99l1.12.73v25.16l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M678.55 146.62l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M673.74 143.87l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M674.9 144.5l.42-.01v25.16l-.42.01V144.5z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M670.09 141.75l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M671.25 142.39l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M666.44 139.63l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M667.61 140.27l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M662.79 137.51l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M663.96 138.15l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M659.15 135.4l1.11.73v25.16l-1.11-.74V135.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M660.31 136.03l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M655.5 133.28l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M656.66 133.91l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M651.85 131.16l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M653.01 131.79l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M648.2 129.04l1.12.74v25.15l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M649.37 129.67l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M644.55 126.92l1.12.74v25.15l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M645.72 127.56l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M640.91 124.8l1.11.74v25.16l-1.11-.74V124.8z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M642.07 125.44l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M637.26 122.68l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M638.42 123.32l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M633.61 120.56l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M634.78 121.2l.42-.02v25.16l-.42.01V121.2z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M629.97 118.44l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M631.13 119.08l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M626.32 116.33l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M627.48 116.96l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M622.67 114.21l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M623.83 114.84l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M619.02 112.09l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M620.18 112.72l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M615.37 109.97l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M616.54 110.61l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M611.73 107.85l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M612.89 108.49l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M608.08 105.73l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M609.24 106.37l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M691.03 153.56v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-2)"
                      d="M693.13 152.34v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M715.5 167.24v-25.25l-20.25 11.58v25.18l20.25-11.51z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-3)"
                      d="M717.61 138.35l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M697.3 152.8l-1.11.73v25.16l1.11-.74V152.8z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M696.14 153.43l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M700.6 150.86l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M699.43 151.49l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M703.9 148.92l-1.12.74v25.15l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M702.73 149.56l-.42-.02v25.15l.42.02v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M707.19 146.98l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M706.03 147.62l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M710.49 145.04l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M709.33 145.68l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M713.79 143.11l-1.12.73V169l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M712.62 143.74l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M694.6 154.01v2.21l20.51-12v22.87l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-4)"
                      d="M599.5 65.78v30.05l-89.24-51.9V13.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M597.38 66.99l-85.01-49.35v25.08l85.01 49.43V66.99z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M594.68 65.78l1.11.74v25.16l-1.11-.74V65.78z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M595.85 66.42l.42-.02v25.16l-.42.01V66.42z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M591.03 63.66l1.12.74v25.16l-1.12-.74V63.66z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M592.2 64.3l.42-.02v25.16l-.42.01V64.3z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M587.39 61.54l1.11.74v25.16l-1.11-.74V61.54z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M588.55 62.18l.42-.02v25.16l-.42.01V62.18z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M583.74 59.42l1.11.74v25.16l-1.11-.74V59.42z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M584.9 60.06l.43-.02V85.2l-.43.02V60.06z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M580.09 57.31l1.11.73V83.2l-1.11-.74V57.31z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M581.26 57.94l.42-.02v25.16l-.42.02V57.94z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M576.44 55.19l1.12.73v25.16l-1.12-.74V55.19z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M577.61 55.82l.42-.02v25.16l-.42.02V55.82z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M572.8 53.07l1.11.73v25.16l-1.11-.74V53.07z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M573.96 53.7l.42-.01v25.15l-.42.02V53.7z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M569.15 50.95l1.11.74v25.15l-1.11-.73V50.95z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M570.31 51.59l.43-.02v25.15l-.43.02V51.59z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M565.5 48.83l1.11.74v25.16l-1.11-.74V48.83z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M566.66 49.47l.43-.02v25.16l-.43.01V49.47z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M561.85 46.71l1.12.74v25.16l-1.12-.74V46.71z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M563.02 47.35l.42-.02v25.16l-.42.01V47.35z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M558.21 44.59l1.11.74v25.16l-1.11-.74V44.59z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M559.37 45.23l.42-.02v25.16l-.42.02V45.23z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M554.56 42.48l1.11.73v25.16l-1.11-.74V42.48z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M555.72 43.11l.42-.02v25.16l-.42.02V43.11z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M550.91 40.36l1.11.73v25.16l-1.11-.74V40.36z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M552.07 40.99l.43-.02v25.16l-.43.02V40.99z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M547.26 38.24l1.12.73v25.16l-1.12-.74V38.24z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M548.43 38.87l.42-.02v25.16l-.42.02V38.87z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M543.62 36.12l1.11.73v25.16l-1.11-.74V36.12z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M544.78 36.75l.42-.02v25.16l-.42.02V36.75z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M539.97 34l1.11.74V59.9l-1.11-.74V34z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M541.13 34.63l.42-.01v25.15l-.42.02V34.63z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M536.32 31.88l1.11.74v25.16l-1.11-.74V31.88z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M537.48 32.52l.43-.02v25.16l-.43.01V32.52z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M532.67 29.76l1.11.74v25.16l-1.11-.74V29.76z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M533.84 30.4l.42-.02v25.16l-.42.01V30.4z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M529.02 27.64l1.12.74v25.16l-1.12-.74V27.64z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M530.19 28.28l.42-.02v25.16l-.42.02V28.28z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M525.38 25.52l1.11.74v25.16l-1.11-.74V25.52z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M526.54 26.16l.42-.02V51.3l-.42.02V26.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M521.73 23.41l1.11.73V49.3l-1.11-.74V23.41z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M522.89 24.04l.43-.02v25.16l-.43.02V24.04z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M518.08 21.29l1.11.73v25.16l-1.11-.74V21.29z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M519.25 21.92l.42-.02v25.16l-.42.02V21.92z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M514.43 19.17l1.12.74v25.15l-1.12-.73V19.17z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M515.6 19.8l.42-.01v25.15l-.42.02V19.8z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M597.38 66.99v2.22l-84.27-48.92v22.86l-.74-.43V17.64l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-5)"
                      d="M599.49 65.78v30.05l24.48-13.93V51.78l-24.48 14z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M621.85 80.67V55.43L601.6 67v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-6)"
                      d="M623.97 51.78l-24.48 14-89.23-51.81L534.75 0l89.22 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M603.66 66.23l-1.12.74v25.16l1.12-.74V66.23z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M602.49 66.87l-.42-.02v25.16l.42.01V66.87z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M606.96 64.29l-1.12.74v25.16l1.12-.74V64.29z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M605.79 64.93l-.42-.02v25.16l.42.01V64.93z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M610.25 62.36l-1.11.73v25.16l1.11-.74V62.36z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M609.09 62.99l-.43-.02v25.16l.43.02V62.99z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M613.55 60.42l-1.11.74v25.15l1.11-.73V60.42z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M612.38 61.05l-.42-.02v25.16l.42.02V61.05z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M616.85 58.48l-1.12.74v25.16l1.12-.74V58.48z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M615.68 59.12l-.42-.02v25.16l.42.01V59.12z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M620.14 56.54l-1.11.74v25.16l1.11-.74V56.54z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M618.98 57.18l-.42-.02v25.16l.42.01V57.18z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M600.96 67.44v2.22l20.5-12v22.86l.74-.43V55.02l-21.24 12.42z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-4)"
                      d="M693.14 120.07v30.05L603.9 98.23V68.26l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M691.03 121.29l-85.01-49.35v25.07l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M688.33 120.08l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M689.49 120.71l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M684.68 117.96l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M685.84 118.59l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M681.03 115.84l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M682.2 116.47l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M677.38 113.72l1.12.73v25.16l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M678.55 114.35l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M673.74 111.6l1.11.74v25.16l-1.11-.74V111.6z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M674.9 112.23l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M670.09 109.48l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M671.25 110.12l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M666.44 107.36l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M667.61 108l.42-.02v25.16l-.42.01V108z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M662.79 105.24l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M663.96 105.88l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M659.15 103.13l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M660.31 103.76l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M655.5 101.01l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M656.66 101.64l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M651.85 98.89l1.11.73v25.16l-1.11-.74V98.89z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M653.01 99.52l.43-.02v25.16l-.43.02V99.52z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M648.2 96.77l1.12.73v25.16l-1.12-.74V96.77z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M649.37 97.4l.42-.01v25.15l-.42.02V97.4z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M644.55 94.65l1.12.74v25.16l-1.12-.74V94.65z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M645.72 95.28l.42-.01v25.15l-.42.02V95.28z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M640.91 92.53l1.11.74v25.16l-1.11-.74V92.53z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M642.07 93.17l.42-.02v25.16l-.42.01V93.17z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M637.26 90.41l1.11.74v25.16l-1.11-.74V90.41z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M638.42 91.05l.43-.02v25.16l-.43.01V91.05z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M633.61 88.29l1.12.74v25.16l-1.12-.74V88.29z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M634.78 88.93l.42-.02v25.16l-.42.01V88.93z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M629.97 86.17l1.11.74v25.16l-1.11-.74V86.17z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M631.13 86.81l.42-.02v25.16l-.42.02V86.81z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M626.32 84.06l1.11.73v25.16l-1.11-.74V84.06z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M627.48 84.69l.42-.02v25.16l-.42.02V84.69z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M622.67 81.94l1.11.73v25.16l-1.11-.74V81.94z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M623.83 82.57l.43-.02v25.16l-.43.02V82.57z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M619.02 79.82l1.11.74v25.15l-1.11-.74V79.82z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M620.18 80.45l.43-.01v25.15l-.43.02V80.45z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M615.37 77.7l1.12.74v25.15l-1.12-.73V77.7z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M616.54 78.33l.42-.01v25.15l-.42.02V78.33z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M611.73 75.58l1.11.74v25.16l-1.11-.74V75.58z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M612.89 76.22l.42-.02v25.16l-.42.01V76.22z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M608.08 73.46l1.11.74v25.16l-1.11-.74V73.46z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M609.24 74.1l.42-.02v25.16l-.42.01V74.1z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M691.03 121.29v2.21l-84.27-48.92v22.86l-.74-.43V71.94l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-5)"
                      d="M693.13 120.07v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M715.5 134.97v-25.25l-20.25 11.58v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-6)"
                      d="M717.61 106.08l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M697.3 120.53l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M696.14 121.16l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M700.6 118.59l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M699.43 119.22l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M703.9 116.65l-1.12.74v25.15l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M702.73 117.28l-.42-.01v25.16l.42.01v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M707.19 114.71l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M706.03 115.35l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M710.49 112.78l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M709.33 113.41l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M713.79 110.84l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M712.62 111.47l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M694.6 121.74v2.21l20.51-12v22.87l.74-.44v-25.07l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-7)"
                      d="M665.68 199.04v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M663.57 200.25l-85.01-49.35v25.08l85.01 49.43v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M660.87 199.04l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M662.03 199.68l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M657.22 196.92l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M658.38 197.56l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M653.57 194.81l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M654.74 195.44l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M649.92 192.69l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M651.09 193.32l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M646.28 190.57l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M647.44 191.2l.42-.02v25.16l-.42.02V191.2z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M642.63 188.45l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M643.79 189.08l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M638.98 186.33l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M640.14 186.97l.43-.02v25.15l-.43.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M635.33 184.21l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M636.5 184.85l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M631.68 182.09l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M632.85 182.73l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M628.04 179.97l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M629.2 180.61l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M624.39 177.86l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M625.55 178.49l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M620.74 175.74l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M621.91 176.37l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M617.1 173.62l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M618.26 174.25l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M613.45 171.5l1.11.74v25.15l-1.11-.73V171.5z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M614.61 172.13l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M609.8 169.38l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M610.96 170.01l.43-.01v25.16l-.43.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M606.15 167.26l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M607.32 167.9l.42-.02v25.16l-.42.01V167.9z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M602.5 165.14l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M603.67 165.78l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M598.86 163.03l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M600.02 163.66l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M595.21 160.91l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M596.37 161.54l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M591.56 158.79l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M592.73 159.42l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M587.91 156.67l1.12.74v25.15l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M589.08 157.3l.42-.02v25.16l-.42.02V157.3z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M584.27 154.55l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M585.43 155.19l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M580.62 152.43l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M581.78 153.07l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M663.57 200.25v2.22l-84.27-48.92v22.86l-.74-.43V150.9l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-8)"
                      d="M665.67 199.04v30.05l24.48-13.93v-30.11l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M688.04 213.93v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-9)"
                      d="M690.15 185.05l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M669.84 199.49l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M668.68 200.13l-.43-.02v25.16l.43.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M673.14 197.56l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M671.98 198.19l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M676.44 195.62l-1.12.73v25.16l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M675.27 196.25l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M679.73 193.68l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M678.57 194.31l-.42-.01v25.16l.42.01v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M683.03 191.74l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M681.87 192.38l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M686.33 189.81l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M685.16 190.44l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M667.14 200.71v2.21l20.51-12v22.86l.74-.43v-25.07l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-10)"
                      d="M665.68 168.01v30.05l-89.24-51.89V116.2l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M663.57 169.23l-85.01-49.35v25.07l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M660.87 168.01l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M662.03 168.65l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M657.22 165.9l1.11.73v25.16l-1.11-.74V165.9z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M658.38 166.53l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M653.57 163.78l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M654.74 164.41l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M649.92 161.66l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M651.09 162.29l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M646.28 159.54l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M647.44 160.17l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M642.63 157.42l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M643.79 158.06l.43-.02v25.15l-.43.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M638.98 155.3l1.11.74v25.16l-1.11-.74V155.3z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M640.14 155.94l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M635.33 153.18l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M636.5 153.82l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M631.68 151.06l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M632.85 151.7l.42-.02v25.16l-.42.01V151.7z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M628.04 148.95l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M629.2 149.58l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M624.39 146.83l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M625.55 147.46l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M620.74 144.71l1.12.73v25.16l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M621.91 145.34l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M617.1 142.59l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M618.26 143.22l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M613.45 140.47l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M614.61 141.1l.42-.01v25.16l-.42.01V141.1z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M609.8 138.35l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M610.96 138.99l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M606.15 136.23l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M607.32 136.87l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M602.5 134.12l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M603.67 134.75l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M598.86 132l1.11.73v25.16l-1.11-.74V132z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M600.02 132.63l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M595.21 129.88l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M596.37 130.51l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M591.56 127.76l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M592.73 128.39l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M587.91 125.64l1.12.74v25.15l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M589.08 126.27l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M584.27 123.52l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M585.43 124.16l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M580.62 121.4l1.11.74v25.16l-1.11-.74V121.4z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M581.78 122.04l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M663.57 169.23v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-11)"
                      d="M665.67 168.01v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M688.04 182.91v-25.25l-20.25 11.58v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-12)"
                      d="M690.15 154.02l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M669.84 168.47l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M668.68 169.1l-.43-.02v25.16l.43.02V169.1z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M673.14 166.53l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M671.98 167.16l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M676.44 164.59l-1.12.74v25.15l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M675.27 165.22l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M679.73 162.65l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M678.57 163.29l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M683.03 160.71l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M681.87 161.35l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M686.33 158.78l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M685.16 159.41l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M667.14 169.68v2.21l20.51-12v22.86l.74-.43v-25.07l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11)"
                      d="M572.04 81.44v30.06L482.8 59.6V29.64l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M569.92 82.66l-85.01-49.35v25.08l85.01 49.43V82.66z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M567.22 81.45l1.11.74v25.15l-1.11-.73V81.45z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M568.39 82.08l.42-.01v25.15l-.42.02V82.08z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M563.58 79.33l1.11.74v25.16l-1.11-.74V79.33z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M564.74 79.97l.42-.02v25.16l-.42.01V79.97z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M559.93 77.21l1.11.74v25.16l-1.11-.74V77.21z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M561.09 77.85l.42-.02v25.16l-.42.01V77.85z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M556.28 75.09l1.11.74v25.16l-1.11-.74V75.09z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M557.44 75.73l.43-.02v25.16l-.43.02V75.73z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M552.63 72.98l1.11.73v25.16l-1.11-.74V72.98z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M553.79 73.61l.43-.02v25.16l-.43.02V73.61z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M548.98 70.86l1.12.73v25.16l-1.12-.74V70.86z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M550.15 71.49l.42-.02v25.16l-.42.02V71.49z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M545.34 68.74l1.11.73v25.16l-1.11-.74V68.74z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M546.5 69.37l.42-.01v25.15l-.42.02V69.37z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M541.69 66.62l1.11.74v25.15l-1.11-.73V66.62z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M542.85 67.25l.42-.01v25.15l-.42.02V67.25z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M538.04 64.5l1.11.74v25.15l-1.11-.73V64.5z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M539.21 65.14l.42-.02v25.16l-.42.01V65.14z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M534.39 62.38l1.12.74v25.16l-1.12-.74V62.38z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M535.56 63.02l.42-.02v25.16l-.42.01V63.02z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M530.75 60.26l1.11.74v25.16l-1.11-.74V60.26z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M531.91 60.9l.42-.02v25.16l-.42.01V60.9z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M527.1 58.15l1.11.73v25.16l-1.11-.74V58.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M528.26 58.78l.42-.02v25.16l-.42.02V58.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M523.45 56.03l1.11.73v25.16l-1.11-.74V56.03z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M524.61 56.66l.43-.02V81.8l-.43.02V56.66z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M519.8 53.91l1.11.73V79.8l-1.11-.74V53.91z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M520.97 54.54l.42-.02v25.16l-.42.02V54.54z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M516.15 51.79l1.12.73v25.16l-1.12-.74V51.79z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M517.32 52.42l.42-.01v25.15l-.42.02V52.42z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M512.51 49.67l1.11.74v25.15l-1.11-.73V49.67z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M513.67 50.3l.42-.01v25.15l-.42.02V50.3z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M508.86 47.55l1.11.74v25.16l-1.11-.74V47.55z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M510.02 48.19l.43-.02v25.16l-.43.01V48.19z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M505.21 45.43l1.11.74v25.16l-1.11-.74V45.43z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M506.38 46.07l.42-.02v25.16l-.42.01V46.07z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M501.56 43.31l1.12.74v25.16l-1.12-.74V43.31z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M502.73 43.95l.42-.02v25.16l-.42.01V43.95z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M497.92 41.2l1.11.73v25.16l-1.11-.74V41.2z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M499.08 41.83l.42-.02v25.16l-.42.02V41.83z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M494.27 39.08l1.11.73v25.16l-1.11-.74V39.08z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M495.43 39.71l.43-.02v25.16l-.43.02V39.71z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M490.62 36.96l1.11.73v25.16l-1.11-.74V36.96z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M491.79 37.59l.42-.02v25.16l-.42.02V37.59z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M486.97 34.84l1.12.74v25.15l-1.12-.74V34.84z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M488.14 35.47l.42-.02v25.16l-.42.02V35.47z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M569.92 82.66v2.22l-84.27-48.92v22.86l-.74-.43V33.31l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-2)"
                      d="M572.03 81.44v30.06l24.48-13.93V67.45l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M594.39 96.34V71.1l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-3)"
                      d="M596.51 67.45l-24.48 13.99-89.23-51.8 24.49-13.97 89.22 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M576.2 81.9l-1.11.74v25.16l1.11-.74V81.9z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M575.03 82.54l-.42-.02v25.16l.42.01V82.54z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M579.49 79.96l-1.11.74v25.16l1.11-.74V79.96z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M578.33 80.6l-.42-.02v25.16l.42.01V80.6z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M582.79 78.03l-1.11.73v25.16l1.11-.74V78.03z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M581.63 78.66l-.42-.02v25.16l.42.02V78.66z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M586.09 76.09l-1.11.73v25.16l1.11-.74V76.09z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M584.92 76.72l-.42-.02v25.16l.42.02V76.72z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M589.38 74.15l-1.11.74v25.16l1.11-.74V74.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M588.22 74.78l-.42-.01v25.15l.42.02V74.78z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M592.68 72.21l-1.11.74v25.16l1.11-.74V72.21z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M591.52 72.85l-.42-.02v25.16l.42.01V72.85z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M573.5 83.11v2.22l20.5-12v22.86l.74-.43V70.69L573.5 83.11z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-13)"
                      d="M665.68 135.74v30.05l-89.24-51.89V83.93l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M663.57 136.96l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M660.87 135.74l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M662.03 136.38l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M657.22 133.63l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M658.38 134.26l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M653.57 131.51l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M654.74 132.14l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M649.92 129.39l1.12.74v25.15l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M651.09 130.02l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M646.28 127.27l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M647.44 127.9l.42-.01v25.15l-.42.02V127.9z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M642.63 125.15l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M643.79 125.78l.43-.01v25.16l-.43.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M638.98 123.03l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M640.14 123.67l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M635.33 120.91l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M636.5 121.55l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M631.68 118.8l1.12.73v25.16l-1.12-.74V118.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M632.85 119.43l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M628.04 116.68l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M629.2 117.31l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M624.39 114.56l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M625.55 115.19l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M620.74 112.44l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M621.91 113.07l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M617.1 110.32l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M618.26 110.95l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M613.45 108.2l1.11.74v25.16l-1.11-.74V108.2z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M614.61 108.84l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M609.8 106.08l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M610.96 106.72l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M606.15 103.96l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M607.32 104.6l.42-.02v25.16l-.42.01V104.6z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M602.5 101.84l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M603.67 102.48l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M598.86 99.73l1.11.73v25.16l-1.11-.74V99.73z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M600.02 100.36l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M595.21 97.61l1.11.73v25.16l-1.11-.74V97.61z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M596.37 98.24l.42-.02v25.16l-.42.02V98.24z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M591.56 95.49l1.11.73v25.16l-1.11-.74V95.49z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M592.73 96.12l.42-.01v25.15l-.42.02V96.12z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M587.91 93.37l1.12.74v25.15l-1.12-.73V93.37z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M589.08 94l.42-.01v25.15l-.42.02V94z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M584.27 91.25l1.11.74v25.16l-1.11-.74V91.25z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M585.43 91.89l.42-.02v25.16l-.42.01V91.89z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M580.62 89.13l1.11.74v25.16l-1.11-.74V89.13z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M581.78 89.77l.43-.02v25.16l-.43.01V89.77z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M663.57 136.96v2.21L579.3 90.25v22.86l-.74-.43V87.61l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-14)"
                      d="M665.67 135.74v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M688.04 150.63v-25.24l-20.25 11.58v25.19l20.25-11.53z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-15)"
                      d="M690.15 121.75l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M669.84 136.19l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M668.68 136.83l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M673.14 134.26l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M671.98 134.89l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M676.44 132.32l-1.12.74v25.15l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M675.27 132.95l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M679.73 130.38l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M678.57 131.02l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M683.03 128.44l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M681.87 129.08l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M686.33 126.51l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M685.16 127.14l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M667.14 137.41v2.21l20.51-12v22.87l.74-.44v-25.07l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-4)"
                      d="M638.38 214.4v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M636.26 215.62l-85.01-49.36v25.08l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M633.56 214.4l1.11.74v25.16l-1.11-.74V214.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M634.73 215.04l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M629.91 212.28l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M631.08 212.92l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M626.27 210.16l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M627.43 210.8l.42-.02v25.16l-.42.02V210.8z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M622.62 208.05l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M623.78 208.68l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M618.97 205.93l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M620.13 206.56l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M615.32 203.81l1.12.73v25.16l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M616.49 204.44l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M611.67 201.69l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M612.84 202.32l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M608.03 199.57l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M609.19 200.21l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M604.38 197.45l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M605.54 198.09l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M600.73 195.33l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M601.9 195.97l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M597.09 193.22l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M598.25 193.85l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M593.44 191.1l1.11.73v25.16l-1.11-.74V191.1z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M594.6 191.73l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M589.79 188.98l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M590.95 189.61l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M586.14 186.86l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M587.3 187.49l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M582.49 184.74l1.12.74v25.15l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M583.66 185.38l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M578.85 182.62l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M580.01 183.26l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M575.2 180.5l1.11.74v25.16l-1.11-.74V180.5z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M576.36 181.14l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M571.55 178.38l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M572.72 179.02l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M567.9 176.26l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M569.07 176.9l.42-.02v25.16l-.42.02V176.9z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M564.26 174.15l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M565.42 174.78l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M560.61 172.03l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M561.77 172.66l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M556.96 169.91l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M558.12 170.54l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M553.31 167.79l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M554.48 168.43l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M636.26 215.62v2.21l-84.27-48.92v22.86l-.74-.43v-25.08l85.01 49.36z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-5)"
                      d="M638.37 214.4v30.05l24.48-13.93v-30.11l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M660.73 229.29v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-6)"
                      d="M662.85 200.41l-24.48 13.99-89.23-51.81 24.49-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M642.54 214.85l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M641.37 215.49l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M645.83 212.91l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M644.67 213.55l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M649.13 210.98l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M647.97 211.61l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M652.43 209.04l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M651.26 209.67l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M655.72 207.1l-1.11.74V233l1.11-.74V207.1z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M654.56 207.74l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M659.02 205.16l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M657.86 205.8l-.43-.02v25.16l.43.02V205.8z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M639.84 216.06v2.22l20.5-12v22.86l.74-.43v-25.07l-21.24 12.42z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-16)"
                      d="M638.38 183.37v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M636.26 184.59l-85.01-49.35v25.07l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M633.56 183.37l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M634.73 184.01l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M629.91 181.25l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M631.08 181.89l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M626.27 179.14l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M627.43 179.77l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M622.62 177.02l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M623.78 177.65l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M618.97 174.9l1.11.73v25.16l-1.11-.73V174.9z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M620.13 175.53l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M615.32 172.78l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M616.49 173.41l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M611.67 170.66l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M612.84 171.3l.42-.02v25.16l-.42.01V171.3z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M608.03 168.54l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M609.19 169.18l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M604.38 166.42l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M605.54 167.06l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M600.73 164.31l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M601.9 164.94l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M597.09 162.19l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M598.25 162.82l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M593.44 160.07l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M594.6 160.7l.42-.02v25.16l-.42.02V160.7z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M589.79 157.95l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M590.95 158.58l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M586.14 155.83l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M587.3 156.47l.43-.02v25.15l-.43.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M582.49 153.71l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M583.66 154.35l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M578.85 151.59l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M580.01 152.23l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M575.2 149.47l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M576.36 150.11l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M571.55 147.35l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M572.72 147.99l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M567.9 145.24l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M569.07 145.87l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M564.26 143.12l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M565.42 143.75l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M560.61 141l1.11.74v25.15l-1.11-.73V141z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M561.77 141.63l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M556.96 138.88l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M558.12 139.51l.43-.01v25.16l-.43.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M553.31 136.76l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M554.48 137.4l.42-.02v25.16l-.42.01V137.4z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M636.26 184.59v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-17)"
                      d="M638.37 183.37v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M660.73 198.26v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-18)"
                      d="M662.85 169.38l-24.48 13.99-89.23-51.81 24.49-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M642.54 183.82l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M641.37 184.46l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M645.83 181.89l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M644.67 182.52l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M649.13 179.95l-1.11.74v25.15l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M647.97 180.58l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M652.43 178.01l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M651.26 178.65l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M655.72 176.07l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M654.56 176.71l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M659.02 174.14l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M657.86 174.77l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M639.84 185.04v2.21l20.5-12v22.86l.74-.43v-25.07l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-19)"
                      d="M544.73 96.8v30.06l-89.24-51.9V45l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M542.62 98.02l-85.01-49.35v25.08l85.01 49.43V98.02z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M539.92 96.81l1.11.74v25.15l-1.11-.73V96.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M541.08 97.44l.42-.01v25.15l-.42.02V97.44z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M536.27 94.69l1.11.74v25.16l-1.11-.74V94.69z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M537.43 95.33l.43-.02v25.16l-.43.01V95.33z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M532.62 92.57l1.11.74v25.16l-1.11-.74V92.57z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M533.78 93.21l.43-.02v25.16l-.43.01V93.21z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M528.97 90.45l1.12.74v25.16l-1.12-.74V90.45z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M530.14 91.09l.42-.02v25.16l-.42.01V91.09z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M525.33 88.33l1.11.74v25.16l-1.11-.74V88.33z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M526.49 88.97l.42-.02v25.16l-.42.02V88.97z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M521.68 86.22l1.11.73v25.16l-1.11-.74V86.22z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M522.84 86.85l.42-.02v25.16l-.42.02V86.85z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M518.03 84.1l1.11.73v25.16l-1.11-.74V84.1z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M519.2 84.73l.42-.02v25.16l-.42.02V84.73z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M514.38 81.98l1.12.74v25.15l-1.12-.73V81.98z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M515.55 82.61l.42-.02v25.16l-.42.02V82.61z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M510.74 79.86l1.11.74v25.15l-1.11-.73V79.86z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M511.9 80.5l.42-.02v25.15l-.42.02V80.5z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M507.09 77.74l1.11.74v25.16l-1.11-.74V77.74z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M508.25 78.38l.43-.02v25.16l-.43.01V78.38z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M503.44 75.62l1.11.74v25.16l-1.11-.74V75.62z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M504.6 76.26l.43-.02v25.16l-.43.01V76.26z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M499.79 73.5l1.11.74V99.4l-1.11-.74V73.5z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M500.96 74.14l.42-.02v25.16l-.42.02V74.14z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M496.14 71.39l1.12.73v25.16l-1.12-.74V71.39z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M497.31 72.02l.42-.02v25.16l-.42.02V72.02z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M492.5 69.27l1.11.73v25.16l-1.11-.74V69.27z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M493.66 69.9l.42-.02v25.16l-.42.02V69.9z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M488.85 67.15l1.11.73v25.16l-1.11-.74V67.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M490.01 67.78l.43-.02v25.16l-.43.02V67.78z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M485.2 65.03l1.11.74v25.15l-1.11-.73V65.03z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M486.37 65.66l.42-.02V90.8l-.42.02V65.66z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M481.55 62.91l1.12.74v25.16l-1.12-.74V62.91z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M482.72 63.55l.42-.02v25.16l-.42.01V63.55z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M477.91 60.79l1.11.74v25.16l-1.11-.74V60.79z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M479.07 61.43l.42-.02v25.16l-.42.01V61.43z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M474.26 58.67l1.11.74v25.16l-1.11-.74V58.67z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M475.42 59.31l.43-.02v25.16l-.43.01V59.31z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M470.61 56.55l1.11.74v25.16l-1.11-.74V56.55z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M471.77 57.19l.43-.02v25.16l-.43.01V57.19z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M466.96 54.44l1.12.73v25.16l-1.12-.74V54.44z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M468.13 55.07l.42-.02v25.16l-.42.02V55.07z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M463.32 52.32l1.11.73v25.16l-1.11-.74V52.32z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M464.48 52.95l.42-.02v25.16l-.42.02V52.95z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M459.67 50.2l1.11.73v25.16l-1.11-.74V50.2z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M460.83 50.83l.42-.02v25.16l-.42.02V50.83z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M542.62 98.02v2.22l-84.27-48.93v22.87l-.74-.43V48.67l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-20)"
                      d="M544.72 96.8v30.06l24.48-13.93V82.81L544.72 96.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M567.09 111.7V86.46l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-21)"
                      d="M569.2 82.81L544.72 96.8 455.49 45l24.5-13.97 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M548.89 97.26l-1.11.74v25.16l1.11-.74V97.26z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M547.73 97.89l-.43-.01v25.15l.43.02V97.89z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M552.19 95.32l-1.11.74v25.16l1.11-.74V95.32z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M551.02 95.96l-.42-.02v25.16l.42.01V95.96z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M555.49 93.39l-1.12.73v25.16l1.12-.74V93.39z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M554.32 94.02l-.42-.02v25.16l.42.02V94.02z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M558.78 91.45l-1.11.73v25.16l1.11-.74V91.45z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M557.62 92.08l-.42-.02v25.16l.42.02V92.08z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M562.08 89.51l-1.11.74v25.15l1.11-.73V89.51z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M560.92 90.14l-.43-.01v25.15l.43.02V90.14z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M565.38 87.57l-1.12.74v25.16l1.12-.74V87.57z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M564.21 88.21l-.42-.02v25.16l.42.01V88.21z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M546.19 98.47v2.22l20.51-12v22.86l.74-.43V86.05l-21.25 12.42z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-22)"
                      d="M638.38 151.1v30.05l-89.24-51.89V99.29l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M636.26 152.32l-85.01-49.35v25.07l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M633.56 151.1l1.11.74V177l-1.11-.74V151.1z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M634.73 151.74l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M629.91 148.99l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M631.08 149.62l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M626.27 146.87l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M627.43 147.5l.42-.02v25.16l-.42.02V147.5z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M622.62 144.75l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M623.78 145.38l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M618.97 142.63l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M620.13 143.26l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M615.32 140.51l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M616.49 141.15l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M611.67 138.39l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M612.84 139.03l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M608.03 136.27l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M609.19 136.91l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M604.38 134.15l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M605.54 134.79l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M600.73 132.03l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M601.9 132.67l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M597.09 129.92l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M598.25 130.55l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M593.44 127.8l1.11.73v25.16l-1.11-.74V127.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M594.6 128.43l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M589.79 125.68l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M590.95 126.31l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M586.14 123.56l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M587.3 124.19l.43-.01v25.16l-.43.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M582.49 121.44l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M583.66 122.08l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M578.85 119.32l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M580.01 119.96l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M575.2 117.2l1.11.74v25.16l-1.11-.74V117.2z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M576.36 117.84l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M571.55 115.08l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M572.72 115.72l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M567.9 112.97l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M569.07 113.6l.42-.02v25.16l-.42.02V113.6z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M564.26 110.85l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M565.42 111.48l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M560.61 108.73l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M561.77 109.36l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M556.96 106.61l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M558.12 107.25l.43-.02v25.15l-.43.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M553.31 104.49l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M554.48 105.13l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M636.26 152.32v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-23)"
                      d="M638.37 151.1v30.05l24.48-13.93v-30.11l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M660.73 166v-25.25l-20.25 11.57v25.19L660.73 166z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-24)"
                      d="M662.85 137.11l-24.48 13.99-89.23-51.81 24.49-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M642.54 151.55l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M641.37 152.19l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M645.83 149.62l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M644.67 150.25l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M649.13 147.68l-1.11.73v25.16l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M647.97 148.31l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M652.43 145.74l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M651.26 146.38l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M655.72 143.8l-1.11.74v25.16l1.11-.74V143.8z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M654.56 144.44l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M659.02 141.87l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M657.86 142.5l-.43-.02v25.16l.43.02V142.5z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M639.84 152.77v2.21l20.5-12v22.86l.74-.43v-25.07l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-25)"
                      d="M490.26 191.51v30.06l-89.24-51.9v-29.96l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M488.15 192.73l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M485.45 191.52l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M486.61 192.15l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M481.8 189.4l1.11.74v25.15l-1.11-.73V189.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M482.96 190.03l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M478.15 187.28l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M479.32 187.91l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M474.51 185.16l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M475.67 185.8l.42-.02v25.16l-.42.01V185.8z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M470.86 183.04l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M472.02 183.68l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M467.21 180.93l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M468.37 181.56l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M463.56 178.81l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M464.73 179.44l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M459.92 176.69l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M461.08 177.32l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M456.27 174.57l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M457.43 175.2l.42-.01v25.15l-.42.02V175.2z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M452.62 172.45l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M453.78 173.08l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M448.97 170.33l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M450.14 170.97l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M445.32 168.21l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M446.49 168.85l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M441.68 166.09l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M442.84 166.73l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M438.03 163.97l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M439.19 164.61l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M434.38 161.86l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M435.55 162.49l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M430.73 159.74l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M431.9 160.37l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M427.09 157.62l1.11.73v25.16l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M428.25 158.25l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M423.44 155.5l1.11.74v25.16l-1.11-.74V155.5z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M424.6 156.13l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M419.79 153.38l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M420.95 154.02l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M416.14 151.26l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M417.31 151.9l.42-.02v25.16l-.42.01V151.9z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M412.5 149.14l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M413.66 149.78l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M408.85 147.03l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M410.01 147.66l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M405.2 144.91l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M406.36 145.54l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M488.15 192.73v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-26)"
                      d="M490.25 191.51v30.06l24.48-13.93v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M512.62 206.41v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-27)"
                      d="M514.73 177.52l-24.48 13.99-89.23-51.8 24.5-13.97 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M494.42 191.97l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M493.26 192.6l-.42-.01v25.15l.42.02V192.6z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M497.72 190.03l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M496.56 190.67l-.43-.02v25.16l.43.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M501.02 188.09l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M499.85 188.73l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M504.31 186.16l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M503.15 186.79l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M507.61 184.22l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M506.45 184.85l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M510.91 182.28l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M509.75 182.91l-.43-.01v25.16l.43.01v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M491.73 193.18v2.22l20.5-12v22.86l.74-.43v-25.08l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-28)"
                      d="M611.29 230.07v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M609.18 231.29l-85.01-49.35v25.07l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M606.48 230.07l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M607.64 230.71l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M602.83 227.96l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M603.99 228.59l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M599.18 225.84l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M600.35 226.47l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M595.53 223.72l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M596.7 224.35l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M591.89 221.6l1.11.74v25.15l-1.11-.74V221.6z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M593.05 222.23l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M588.24 219.48l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M589.4 220.12l.43-.02v25.15l-.43.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M584.59 217.36l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M585.75 218l.43-.02v25.16l-.43.01V218z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M580.94 215.24l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M582.11 215.88l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M577.3 213.12l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M578.46 213.76l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M573.65 211.01l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M574.81 211.64l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M570 208.89l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M571.16 209.52l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M566.35 206.77l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M567.52 207.4l.42-.02v25.16l-.42.02V207.4z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M562.71 204.65l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M563.87 205.28l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M559.06 202.53l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M560.22 203.16l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M555.41 200.41l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M556.57 201.05l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M551.76 198.29l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M552.93 198.93l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M548.12 196.17l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M549.28 196.81l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M544.47 194.06l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M545.63 194.69l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M540.82 191.94l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M541.98 192.57l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M537.17 189.82l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M538.34 190.45l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M533.52 187.7l1.12.74v25.15l-1.12-.73V187.7z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M534.69 188.33l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M529.88 185.58l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M531.04 186.22l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M526.23 183.46l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M527.39 184.1l.43-.02v25.16l-.43.01V184.1z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M609.18 231.29v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-29)"
                      d="M611.28 230.07v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M633.65 244.97v-25.25L613.4 231.3v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-30)"
                      d="M635.76 216.08l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M615.45 230.53l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M614.29 231.16l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M618.75 228.59l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M617.59 229.22l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M622.05 226.65l-1.12.74v25.15l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M620.88 227.28l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M625.34 224.71l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M624.18 225.35l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M628.64 222.77l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M627.48 223.41l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M631.94 220.84l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M630.77 221.47l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M612.75 231.74v2.21l20.51-12v22.86l.74-.43v-25.07l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-31)"
                      d="M583.91 245.81v30.05l-89.24-51.89V194l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M581.79 247.02l-85.01-49.35v25.08l85.01 49.43v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M579.09 245.81l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M580.26 246.45l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M575.45 243.69l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M576.61 244.33l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M571.8 241.57l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M572.96 242.21l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M568.15 239.46l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M569.31 240.09l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M564.5 237.34l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M565.67 237.97l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M560.86 235.22l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M562.02 235.85l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M557.21 233.1l1.11.74v25.15l-1.11-.73V233.1z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M558.37 233.73l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M553.56 230.98l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M554.72 231.62l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M549.91 228.86l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M551.08 229.5l.42-.02v25.16l-.42.01V229.5z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M546.26 226.74l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M547.43 227.38l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M542.62 224.63l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M543.78 225.26l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M538.97 222.51l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M540.13 223.14l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M535.32 220.39l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M536.49 221.02l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M531.67 218.27l1.12.73v25.16l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M532.84 218.9l.42-.02v25.16l-.42.02V218.9z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M528.03 216.15l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M529.19 216.78l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M524.38 214.03l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M525.54 214.67l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M520.73 211.91l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M521.89 212.55l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M517.08 209.79l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M518.25 210.43l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M513.43 207.68l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M514.6 208.31l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M509.79 205.56l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M510.95 206.19l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M506.14 203.44l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M507.3 204.07l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M502.49 201.32l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M503.66 201.95l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M498.85 199.2l1.11.74v25.15l-1.11-.73V199.2z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M500.01 199.84l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M581.79 247.02v2.22l-84.27-48.92v22.86l-.74-.43v-25.08l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-32)"
                      d="M583.9 245.81v30.05l24.48-13.93v-30.12l-24.48 14z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M606.26 260.7v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-33)"
                      d="M608.38 231.81l-24.48 14L494.67 194l24.49-13.97 89.22 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M588.07 246.26l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M586.9 246.9l-.42-.02v25.16l.42.01V246.9z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M591.37 244.32l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M590.2 244.96l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M594.66 242.39l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M593.5 243.02l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M597.96 240.45l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M596.79 241.08l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M601.26 238.51l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M600.09 239.15l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M604.55 236.57l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M603.39 237.21l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M585.37 247.47v2.22l20.5-12v22.86l.74-.43v-25.07l-21.24 12.42z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-7)"
                      d="M490.26 160.48v30.06l-89.24-51.9v-29.96l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M488.15 161.7l-85.01-49.35v25.08l85.01 49.43V161.7z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M485.45 160.49l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M486.61 161.12l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M481.8 158.37l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M482.96 159.01l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M478.15 156.25l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M479.32 156.89l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M474.51 154.13l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M475.67 154.77l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M470.86 152.01l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M472.02 152.65l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M467.21 149.9l1.11.73v25.16l-1.11-.74V149.9z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M468.37 150.53l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M463.56 147.78l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M464.73 148.41l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M459.92 145.66l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M461.08 146.29l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M456.27 143.54l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M457.43 144.17l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M452.62 141.42l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M453.78 142.06l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M448.97 139.3l1.11.74v25.16l-1.11-.74V139.3z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M450.14 139.94l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M445.32 137.18l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M446.49 137.82l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M441.68 135.06l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M442.84 135.7l.42-.02v25.16l-.42.02V135.7z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M438.03 132.95l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M439.19 133.58l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M434.38 130.83l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M435.55 131.46l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M430.73 128.71l1.12.73v25.16l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M431.9 129.34l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M427.09 126.59l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M428.25 127.22l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M423.44 124.47l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M424.6 125.11l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M419.79 122.35l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M420.95 122.99l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M416.14 120.23l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M417.31 120.87l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M412.5 118.11l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M413.66 118.75l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M408.85 116l1.11.73v25.16l-1.11-.74V116z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M410.01 116.63l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M405.2 113.88l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M406.36 114.51l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M488.15 161.7v2.22L403.88 115v22.86l-.74-.43v-25.08l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-8)"
                      d="M490.25 160.48v30.06l24.48-13.93v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M512.62 175.38v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-9)"
                      d="M514.73 146.49l-24.48 13.99-89.23-51.8 24.5-13.97 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M494.42 160.94l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M493.26 161.57l-.42-.01v25.16l.42.01v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M497.72 159l-1.11.74v25.16l1.11-.74V159z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M496.56 159.64l-.43-.02v25.16l.43.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M501.02 157.06l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M499.85 157.7l-.42-.02v25.16l.42.02V157.7z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M504.31 155.13l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M503.15 155.76l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M507.61 153.19l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M506.45 153.82l-.43-.01v25.15l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M510.91 151.25l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M509.75 151.89l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M491.73 162.15v2.22l20.5-12v22.86l.74-.43v-25.08l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-34)"
                      d="M611.29 199.04v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M609.18 200.26l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M606.48 199.04l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M607.64 199.68l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M602.83 196.93l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M603.99 197.56l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M599.18 194.81l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M600.35 195.44l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M595.53 192.69l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M596.7 193.32l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M591.89 190.57l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M593.05 191.21l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M588.24 188.45l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M589.4 189.09l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M584.59 186.33l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M585.75 186.97l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M580.94 184.21l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M582.11 184.85l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M577.3 182.1l1.11.73v25.16l-1.11-.74V182.1z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M578.46 182.73l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M573.65 179.98l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M574.81 180.61l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M570 177.86l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M571.16 178.49l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M566.35 175.74l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M567.52 176.37l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M562.71 173.62l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M563.87 174.25l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M559.06 171.5l1.11.74v25.16l-1.11-.74V171.5z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M560.22 172.14l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M555.41 169.38l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M556.57 170.02l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M551.76 167.26l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M552.93 167.9l.42-.02v25.16l-.42.02V167.9z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M548.12 165.15l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M549.28 165.78l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M544.47 163.03l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M545.63 163.66l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M540.82 160.91l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M541.98 161.54l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M537.17 158.79l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M538.34 159.42l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M533.52 156.67l1.12.74v25.15l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M534.69 157.31l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M529.88 154.55l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M531.04 155.19l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M526.23 152.43l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M527.39 153.07l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M609.18 200.26v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-35)"
                      d="M611.28 199.04v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M633.65 213.94v-25.25l-20.25 11.58v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-36)"
                      d="M635.76 185.05l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M615.45 199.5l-1.11.73v25.16l1.11-.74V199.5z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M614.29 200.13l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M618.75 197.56l-1.11.73v25.16l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M617.59 198.19l-.43-.01v25.15l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M622.05 195.62l-1.12.74v25.15l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M620.88 196.25l-.42-.01v25.16l.42.01v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M625.34 193.68l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M624.18 194.32l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M628.64 191.75l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M627.48 192.38l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M631.94 189.81l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M630.77 190.44l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M612.75 200.71v2.21l20.51-11.99v22.86l.74-.44v-25.07l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-7)"
                      d="M583.91 214.78v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M581.79 216l-85.01-49.35v25.07l85.01 49.43V216z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M579.09 214.78l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M580.26 215.42l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M575.45 212.66l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M576.61 213.3l.42-.02v25.16l-.42.02V213.3z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M571.8 210.55l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M572.96 211.18l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M568.15 208.43l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M569.31 209.06l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M564.5 206.31l1.12.73v25.16l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M565.67 206.94l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M560.86 204.19l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M562.02 204.82l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M557.21 202.07l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M558.37 202.71l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M553.56 199.95l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M554.72 200.59l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M549.91 197.83l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M551.08 198.47l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M546.26 195.72l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M547.43 196.35l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M542.62 193.6l1.11.73v25.16l-1.11-.74V193.6z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M543.78 194.23l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M538.97 191.48l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M540.13 192.11l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M535.32 189.36l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M536.49 189.99l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M531.67 187.24l1.12.74v25.15l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M532.84 187.88l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M528.03 185.12l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M529.19 185.76l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M524.38 183l1.11.74v25.16l-1.11-.74V183z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M525.54 183.64l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M520.73 180.88l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M521.89 181.52l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M517.08 178.76l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M518.25 179.4l.42-.02v25.16l-.42.02V179.4z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M513.43 176.65l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M514.6 177.28l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M509.79 174.53l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M510.95 175.16l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M506.14 172.41l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M507.3 173.04l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M502.49 170.29l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M503.66 170.93l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M498.85 168.17l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M500.01 168.81l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M581.79 216v2.21l-84.27-48.92v22.86l-.74-.43v-25.07L581.79 216z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-8)"
                      d="M583.9 214.78v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M606.26 229.68v-25.25L586.01 216v25.19l20.25-11.51z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-9)"
                      d="M608.38 200.79l-24.48 13.99-89.23-51.81L519.16 149l89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M588.07 215.23l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M586.9 215.87l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M591.37 213.3l-1.12.73v25.16l1.12-.74V213.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M590.2 213.93l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M594.66 211.36l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M593.5 211.99l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M597.96 209.42l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M596.79 210.06l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M601.26 207.48l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M600.09 208.12l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M604.55 205.55l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M603.39 206.18l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M585.37 216.45v2.21l20.5-12v22.86l.74-.43v-25.07l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-37)"
                      d="M517.65 112.48v30.05l-89.24-51.9V60.67l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M515.53 113.69l-85.01-49.35v25.08l85.01 49.43v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M512.83 112.48l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M514 113.12l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M509.19 110.36l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M510.35 111l.42-.02v25.16l-.42.01V111z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M505.54 108.24l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M506.7 108.88l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M501.89 106.13l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M503.05 106.76l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M498.24 104.01l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M499.41 104.64l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M494.59 101.89l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M495.76 102.52l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M490.95 99.77l1.11.74v25.15l-1.11-.74V99.77z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M492.11 100.4l.42-.01v25.15l-.42.02V100.4z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M487.3 97.65l1.11.74v25.16l-1.11-.74V97.65z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M488.46 98.28l.43-.01v25.15l-.43.02V98.28z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M483.65 95.53l1.11.74v25.16l-1.11-.74V95.53z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M484.81 96.17l.43-.02v25.16l-.43.01V96.17z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M480 93.41l1.12.74v25.16l-1.12-.74V93.41z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M481.17 94.05l.42-.02v25.16l-.42.01V94.05z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M476.36 91.29l1.11.74v25.16l-1.11-.74V91.29z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M477.52 91.93l.42-.02v25.16l-.42.01V91.93z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M472.71 89.18l1.11.73v25.16l-1.11-.74V89.18z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M473.87 89.81l.43-.02v25.16l-.43.02V89.81z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M469.06 87.06l1.11.73v25.16l-1.11-.74V87.06z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M470.22 87.69l.43-.02v25.16l-.43.02V87.69z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M465.41 84.94l1.12.73v25.16l-1.12-.74V84.94z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M466.58 85.57l.42-.01v25.15l-.42.02V85.57z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M461.76 82.82l1.12.74v25.15l-1.12-.73V82.82z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M462.93 83.45l.42-.01v25.15l-.42.02V83.45z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M458.12 80.7l1.11.74v25.15l-1.11-.73V80.7z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M459.28 81.33l.42-.01v25.15l-.42.02V81.33z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M454.47 78.58l1.11.74v25.16l-1.11-.74V78.58z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M455.63 79.22l.43-.02v25.16l-.43.01V79.22z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M450.82 76.46l1.12.74v25.16l-1.12-.74V76.46z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M451.99 77.1l.42-.02v25.16l-.42.01V77.1z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M447.18 74.34l1.11.74v25.16l-1.11-.74V74.34z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M448.34 74.98l.42-.02v25.16l-.42.02V74.98z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M443.53 72.23l1.11.73v25.16l-1.11-.74V72.23z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M444.69 72.86l.42-.02V98l-.42.02V72.86z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M439.88 70.11l1.11.73V96l-1.11-.74V70.11z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M441.04 70.74l.43-.02v25.16l-.43.02V70.74z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M436.23 67.99l1.11.73v25.16l-1.11-.74V67.99z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M437.4 68.62l.42-.01v25.15l-.42.02V68.62z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M432.58 65.87l1.12.74v25.15l-1.12-.73V65.87z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M433.75 66.5l.42-.01v25.15l-.42.02V66.5z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M515.53 113.69v2.22l-84.27-48.92v22.86l-.74-.43V64.34l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-38)"
                      d="M517.64 112.48v30.05l24.48-13.93V98.48l-24.48 14z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M540 127.37v-25.24l-20.25 11.57v25.19L540 127.37z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-39)"
                      d="M542.12 98.48l-24.48 14-89.23-51.81L452.9 46.7l89.22 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M521.81 112.93l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M520.64 113.57l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M525.11 111l-1.12.73v25.16l1.12-.74V111z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M523.94 111.63l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M528.4 109.06l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M527.24 109.69l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M531.7 107.12l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M530.53 107.75l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M535 105.18l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M533.83 105.82l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M538.29 103.24l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M537.13 103.88l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M519.11 114.14v2.22l20.5-12v22.86l.74-.43v-25.07l-21.24 12.42z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-10)"
                      d="M490.26 128.21v30.06l-89.24-51.9V76.41l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M488.15 129.43l-85.01-49.35v25.08l85.01 49.43v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M485.45 128.22l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M486.61 128.85l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M481.8 126.1l1.11.74V152l-1.11-.74V126.1z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M482.96 126.73l.43-.01v25.16l-.43.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M478.15 123.98l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M479.32 124.62l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M474.51 121.86l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M475.67 122.5l.42-.02v25.16l-.42.01V122.5z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M470.86 119.75l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M472.02 120.38l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M467.21 117.63l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M468.37 118.26l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M463.56 115.51l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M464.73 116.14l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M459.92 113.39l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M461.08 114.02l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M456.27 111.27l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M457.43 111.91l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M452.62 109.15l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M453.78 109.79l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M448.97 107.03l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M450.14 107.67l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M445.32 104.91l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M446.49 105.55l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M441.68 102.8l1.11.73v25.16l-1.11-.74V102.8z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M442.84 103.43l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M438.03 100.68l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M439.19 101.31l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M434.38 98.56l1.11.73v25.16l-1.11-.74V98.56z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M435.55 99.19l.42-.02v25.16l-.42.02V99.19z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M430.73 96.44l1.12.73v25.16l-1.12-.74V96.44z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M431.9 97.07l.42-.01v25.15l-.42.02V97.07z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M427.09 94.32l1.11.74v25.16l-1.11-.74V94.32z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M428.25 94.95l.42-.01v25.15l-.42.02V94.95z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M423.44 92.2l1.11.74v25.16l-1.11-.74V92.2z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M424.6 92.84l.42-.02v25.16l-.42.01V92.84z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M419.79 90.08l1.11.74v25.16l-1.11-.74V90.08z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M420.95 90.72l.43-.02v25.16l-.43.01V90.72z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M416.14 87.96l1.11.74v25.16l-1.11-.74V87.96z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M417.31 88.6l.42-.02v25.16l-.42.01V88.6z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M412.5 85.84l1.11.74v25.16l-1.11-.74V85.84z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M413.66 86.48l.42-.02v25.16l-.42.02V86.48z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M408.85 83.73l1.11.73v25.16l-1.11-.74V83.73z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M410.01 84.36l.42-.02v25.16l-.42.02V84.36z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M405.2 81.61l1.11.73v25.16l-1.11-.74V81.61z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M406.36 82.24l.43-.02v25.16l-.43.02V82.24z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M488.15 129.43v2.22l-84.27-48.93v22.87l-.74-.43V80.08l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-11)"
                      d="M490.25 128.21v30.06l24.48-13.93v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M512.62 143.11v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_12-12)"
                      d="M514.73 114.22l-24.48 13.99-89.23-51.8 24.5-13.97 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M494.42 128.67l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M493.26 129.31l-.42-.02v25.15l.42.02v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M497.72 126.73l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M496.56 127.37l-.43-.02v25.16l.43.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M501.02 124.8l-1.12.73v25.16l1.12-.74V124.8z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M499.85 125.43l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M504.31 122.86l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M503.15 123.49l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M507.61 120.92l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M506.45 121.55l-.43-.01v25.15l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M510.91 118.98l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M509.75 119.62l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M491.73 129.88v2.22l20.5-12v22.86l.74-.43v-25.08l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-10)"
                      d="M611.29 166.77v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M609.18 167.99l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M606.48 166.78l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M607.64 167.41l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M602.83 164.66l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M603.99 165.29l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M599.18 162.54l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M600.35 163.17l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M595.53 160.42l1.12.74v25.15l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M596.7 161.05l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M591.89 158.3l1.11.74v25.15l-1.11-.73V158.3z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M593.05 158.94l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M588.24 156.18l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M589.4 156.82l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M584.59 154.06l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M585.75 154.7l.43-.02v25.16l-.43.01V154.7z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M580.94 151.94l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M582.11 152.58l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M577.3 149.83l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M578.46 150.46l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M573.65 147.71l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M574.81 148.34l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M570 145.59l1.11.73v25.16l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M571.16 146.22l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M566.35 143.47l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M567.52 144.1l.42-.01v25.15l-.42.02V144.1z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M562.71 141.35l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M563.87 141.99l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M559.06 139.23l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M560.22 139.87l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M555.41 137.11l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M556.57 137.75l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M551.76 135l1.12.73v25.16l-1.12-.74V135z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M552.93 135.63l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M548.12 132.88l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M549.28 133.51l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M544.47 130.76l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M545.63 131.39l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M540.82 128.64l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M541.98 129.27l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M537.17 126.52l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M538.34 127.15l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M533.52 124.4l1.12.74v25.16l-1.12-.74V124.4z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M534.69 125.04l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M529.88 122.28l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M531.04 122.92l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M526.23 120.16l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M527.39 120.8l.43-.02v25.16l-.43.02V120.8z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M609.18 167.99v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-11)"
                      d="M611.28 166.77v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M633.65 181.67v-25.25L613.4 168v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_11-12)"
                      d="M635.76 152.78l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M615.45 167.23l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M614.29 167.86l-.42-.02V193l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M618.75 165.29l-1.11.74v25.15l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M617.59 165.92l-.43-.01v25.15l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M622.05 163.35l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M620.88 163.99l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M625.34 161.41l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M624.18 162.05l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M628.64 159.47l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M627.48 160.11l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M631.94 157.54l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M630.77 158.17l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M612.75 168.44v2.21l20.51-11.99v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-40)"
                      d="M583.91 182.51v30.05l-89.24-51.89V130.7l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M581.79 183.73l-85.01-49.35v25.07l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M579.09 182.51l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M580.26 183.15l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M575.45 180.4l1.11.73v25.16l-1.11-.74V180.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M576.61 181.03l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M571.8 178.28l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M572.96 178.91l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M568.15 176.16l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M569.31 176.79l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M564.5 174.04l1.12.74v25.15l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M565.67 174.67l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M560.86 171.92l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M562.02 172.56l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M557.21 169.8l1.11.74v25.16l-1.11-.74V169.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M558.37 170.44l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M553.56 167.68l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M554.72 168.32l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M549.91 165.56l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M551.08 166.2l.42-.02v25.16l-.42.01V166.2z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M546.26 163.44l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M547.43 164.08l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M542.62 161.33l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M543.78 161.96l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#7d0000"
                      d="M538.97 159.21l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d3a8a8"
                      d="M540.13 159.84l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M535.32 157.09l1.11.73v25.16l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M536.49 157.72l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M531.67 154.97l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M532.84 155.6l.42-.01v25.16l-.42.01V155.6z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M528.03 152.85l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M529.19 153.49l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M524.38 150.73l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M525.54 151.37l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M520.73 148.61l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M521.89 149.25l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M517.08 146.5l1.12.73v25.16l-1.12-.74V146.5z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M518.25 147.13l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M513.43 144.38l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M514.6 145.01l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M509.79 142.26l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M510.95 142.89l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M506.14 140.14l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M507.3 140.77l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M502.49 138.02l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M503.66 138.66l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M498.85 135.9l1.11.74v25.16l-1.11-.74V135.9z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M500.01 136.54l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M581.79 183.73v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-41)"
                      d="M583.9 182.51v30.05l24.48-13.93v-30.11l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eedede"
                      d="M606.26 197.41v-25.25l-20.25 11.58v25.18l20.25-11.51z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_6-42)"
                      d="M608.38 168.52l-24.48 13.99-89.23-51.81 24.49-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M588.07 182.96l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M586.9 183.6l-.42-.02v25.16l.42.01V183.6z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M591.37 181.03l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M590.2 181.66l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#7d0000"
                        d="M594.66 179.09l-1.11.73v25.16l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#d3a8a8"
                        d="M593.5 179.72l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M597.96 177.15l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M596.79 177.79l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M601.26 175.21l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M600.09 175.85l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#7d0000"
                          d="M604.55 173.28l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#d3a8a8"
                          d="M603.39 173.91l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e1c4c4"
                      d="M585.37 184.18v2.21l20.5-12v22.86l.74-.43v-25.07l-21.24 12.43z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_19)"
                    d="M650 193v109.26l95.52-55.23V137.48L650 193z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_19-2)"
                    d="M650.02 193v109.26l-44.84-25.98V166.87L650.02 193z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_19-3)"
                    d="M605.18 166.87L650.02 193l95.5-55.52-44.88-26.09-95.46 55.48z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_19-4)"
                    d="M667.89 190.03V299.3l73.75-42.81V146.94l-73.75 43.09z"
                  />
                  <path
                    fill="#9b0a11"
                    d="M679 194.18V201l6.21-3.6v-6.84l-6.21 3.62zM690.6 187.57v6.82l6.2-3.6v-6.84l-6.2 3.62zM702.11 180.79v6.82l6.2-3.6v-6.84l-6.2 3.62zM713.7 174.18V181l6.2-3.6v-6.84l-6.2 3.62zM679 204.75v6.82l6.21-3.6v-6.84l-6.21 3.62zM690.6 198.14v6.82l6.2-3.6v-6.84l-6.2 3.62zM702.11 191.36v6.82l6.2-3.6v-6.84l-6.2 3.62zM713.7 184.75v6.82l6.2-3.6v-6.84l-6.2 3.62zM725.36 167.62v6.81l6.2-3.6v-6.84l-6.2 3.63zM725.36 178.18V185l6.2-3.6v-6.84l-6.2 3.62z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_19-5)"
                    d="M667.91 190.03V299.3l-5.66-3.65V186.23l5.66 3.8z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_19-6)"
                    d="M662.25 186.23l5.66 3.8 73.73-43.09-5.7-3.75-73.69 43.04z"
                  />
                  <path
                    fill="#9b0a11"
                    d="M642.17 199.39v6.82l-6.2-3.6v-6.84l6.2 3.62zM630.58 192.78v6.81l-6.2-3.59v-6.84l6.2 3.62zM619.07 186v6.82l-6.2-3.6v-6.84l6.2 3.62zM642.17 209.96v6.82l-6.2-3.6v-6.84l6.2 3.62zM630.58 203.35v6.81l-6.2-3.6v-6.83l6.2 3.62zM619.07 196.57v6.82l-6.2-3.6v-6.84l6.2 3.62z"
                  />
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_19-7)"
                      d="M621.48 186.57v5.85l28.52.29 95.52-55.24 28.14-32.09v-6.41l-152.18 87.6z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_19-8)"
                      d="M621.5 186.57v5.85l-27.91-16.14v-6.31l27.91 16.6z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_19-9)"
                      d="M593.59 169.97l27.91 16.6 152.16-87.6-27.95-16.55-152.12 87.55z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_19-10)"
                      d="M659.81 158.29v12.27l78.29-45.27v-12.51l-78.29 45.51z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_19-11)"
                      d="M659.83 158.29v12.27l-36.75-21.29v-12.4l36.75 21.42z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_19-12)"
                      d="M623.08 136.87l36.75 21.42 78.27-45.51-36.78-21.37-78.24 45.46z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_19-13)"
                      d="M700.99 103.07V96.7l-68.41 39.62 5.66 3.17 62.75-36.42z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_19-14)"
                      d="M700.97 103.07V96.7l27.77 16.31-5.22 3.26-22.55-13.2z"
                    />
                  </g>
                </g>
                <g id="prefix__containercollection1">
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_9)"
                    d="M836.31 266.29v30.06l-89.23-51.9v-29.96l89.23 51.8z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e6e7e8"
                    d="M834.2 267.51l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M831.5 266.3l1.11.74v25.15l-1.11-.74V266.3z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M832.66 266.93l.43-.01v25.15l-.43.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M827.85 264.18l1.12.74v25.15l-1.12-.73v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M829.02 264.81l.42-.01v25.15l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M824.21 262.06l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M825.37 262.69l.42-.01v25.16l-.42.01v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M820.56 259.94l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M821.72 260.58l.42-.02v25.16l-.42.01v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M816.91 257.82l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M818.07 258.46l.43-.02v25.16l-.43.01v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M813.26 255.71l1.11.73v25.16l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M814.42 256.34l.43-.02v25.16l-.43.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M809.61 253.59l1.12.73v25.16l-1.12-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M810.78 254.22l.42-.02v25.16l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M805.97 251.47l1.11.73v25.16l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M807.13 252.1l.42-.02v25.16l-.42.02V252.1z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M802.32 249.35l1.11.74v25.15l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M803.48 249.98l.42-.01v25.15l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M798.67 247.23l1.11.74v25.15l-1.11-.73v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M799.83 247.87l.43-.02V273l-.43.02v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M795.02 245.11l1.12.74v25.16l-1.12-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M796.19 245.75l.42-.02v25.16l-.42.01v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M791.38 242.99l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M792.54 243.63l.42-.02v25.16l-.42.01v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414042"
                    d="M787.73 240.87l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bcbec0"
                    d="M788.89 241.51l.43-.02v25.16l-.43.02v-25.16z"
                  />
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M784.08 238.75l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M785.24 239.39l.43-.02v25.16l-.43.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M780.43 236.64l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M781.6 237.27l.42-.02v25.16l-.42.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M776.78 234.52l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M777.95 235.15l.42-.02v25.16l-.42.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M773.14 232.4l1.11.73v25.16l-1.11-.73V232.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M774.3 233.03l.42-.02v25.16l-.42.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M769.49 230.28l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M770.65 230.91l.43-.01v25.16l-.43.01v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M765.84 228.16l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M767.01 228.8l.42-.02v25.16l-.42.01V228.8z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M762.19 226.04l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M763.36 226.68l.42-.02v25.16l-.42.01v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M758.55 223.92l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M759.71 224.56l.42-.02v25.16l-.42.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M754.9 221.81l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M756.06 222.44l.43-.02v25.16l-.43.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M751.25 219.69l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M752.41 220.32l.43-.02v25.16l-.43.02v-25.16z"
                    />
                  </g>
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d1d3d4"
                    d="M834.2 267.51v2.22l-84.27-48.93v22.86l-.74-.43v-25.07l85.01 49.35z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_9-2)"
                    d="M836.3 266.29v30.06l24.48-13.93V252.3l-24.48 13.99z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e6e7e8"
                    d="M858.67 281.19v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_9-3)"
                    d="M860.78 252.3l-24.48 13.99-89.22-51.8 24.49-13.97 89.21 51.78z"
                  />
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M840.48 266.75l-1.12.74v25.15l1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M839.31 267.38l-.42-.01v25.15l.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M843.77 264.81l-1.11.74v25.16l1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M842.61 265.45l-.43-.02v25.16l.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M847.07 262.87l-1.11.74v25.16l1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M845.9 263.51l-.42-.02v25.16l.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M850.37 260.94l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M849.2 261.57l-.42-.02v25.16l.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M853.66 259l-1.11.74v25.15l1.11-.74V259z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M852.5 259.63l-.42-.01v25.15l.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M856.96 257.06l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M855.8 257.69l-.43-.01v25.15l.43.02v-25.16z"
                      />
                    </g>
                  </g>
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d1d3d4"
                    d="M837.78 267.96v2.22l20.5-12v22.86l.74-.43v-25.08l-21.24 12.43z"
                  />
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_7)"
                      d="M809.01 281.65v30.06l-89.24-51.9v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e6e7e8"
                      d="M806.89 282.87l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M804.2 281.66l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M805.36 282.29l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M800.55 279.54l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M801.71 280.17l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M796.9 277.42l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M798.06 278.06l.43-.02v25.15l-.43.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M793.25 275.3l1.11.74v25.16l-1.11-.74V275.3z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M794.41 275.94l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M789.6 273.18l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M790.77 273.82l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M785.96 271.06l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M787.12 271.7l.42-.02v25.16l-.42.02V271.7z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M782.31 268.94l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M783.47 269.58l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M778.66 266.83l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M779.83 267.46l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M775.01 264.71l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M776.18 265.34l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M771.37 262.59l1.11.73v25.16l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M772.53 263.22l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M767.72 260.47l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M768.88 261.11l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#414042"
                      d="M764.07 258.35l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#bcbec0"
                      d="M765.23 258.99l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M760.42 256.23l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M761.59 256.87l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M756.77 254.11l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M757.94 254.75l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M753.13 252l1.11.73v25.16l-1.11-.74V252z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M754.29 252.63l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M749.48 249.88l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M750.64 250.51l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M745.83 247.76l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M747 248.39l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M742.18 245.64l1.12.74v25.15l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M743.35 246.27l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M738.54 243.52l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M739.7 244.16l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M734.89 241.4l1.11.74v25.16l-1.11-.74V241.4z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M736.05 242.04l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M731.24 239.28l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M732.4 239.92l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M727.59 237.16l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M728.76 237.8l.42-.02v25.16l-.42.01V237.8z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M723.95 235.05l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M725.11 235.68l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d1d3d4"
                      d="M806.89 282.87v2.21l-84.26-48.92v22.86l-.75-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_7-2)"
                      d="M809 281.65v30.06l24.48-13.93v-30.12L809 281.65z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e6e7e8"
                      d="M831.36 296.55v-25.24l-20.24 11.57v25.19l20.24-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_7-3)"
                      d="M833.48 267.66L809 281.65l-89.23-51.81 24.49-13.96 89.22 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M813.17 282.11l-1.11.74V308l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M812.01 282.74l-.43-.01v25.15l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M816.47 280.17l-1.12.74v25.15l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M815.3 280.81l-.42-.02v25.15l.42.02v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#414042"
                        d="M819.76 278.23l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#bcbec0"
                        d="M818.6 278.87l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#414042"
                          d="M823.06 276.3l-1.11.73v25.16l1.11-.74V276.3z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#bcbec0"
                          d="M821.9 276.93l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#414042"
                          d="M826.36 274.36l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#bcbec0"
                          d="M825.19 274.99l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#414042"
                          d="M829.65 272.42l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#bcbec0"
                          d="M828.49 273.06l-.42-.02v25.15l.42.02v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d1d3d4"
                      d="M810.47 283.32v2.22l20.51-12v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21)"
                      d="M781.92 297.32v30.06l-89.24-51.9v-29.96l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M779.81 298.54l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M777.11 297.33l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M778.27 297.96l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M773.46 295.21l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M774.63 295.85l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M769.82 293.09l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M770.98 293.73l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M766.17 290.97l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M767.33 291.61l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M762.52 288.85l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M763.68 289.49l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M758.87 286.74l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M760.04 287.37l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M755.22 284.62l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M756.39 285.25l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M751.58 282.5l1.11.74v25.15l-1.11-.74V282.5z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M752.74 283.13l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M747.93 280.38l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M749.09 281.01l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M744.28 278.26l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M745.45 278.89l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M740.63 276.14l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M741.8 276.78l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M736.99 274.02l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M738.15 274.66l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M733.34 271.9l1.11.74v25.16l-1.11-.74V271.9z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M734.5 272.54l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M729.69 269.79l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M730.86 270.42l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M726.04 267.67l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M727.21 268.3l.42-.02v25.16l-.42.02V268.3z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M722.4 265.55l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M723.56 266.18l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M718.75 263.43l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M719.91 264.06l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M715.1 261.31l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M716.26 261.95l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M711.45 259.19l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M712.62 259.83l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M707.8 257.07l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M708.97 257.71l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M704.16 254.96l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M705.32 255.59l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M700.51 252.84l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M701.67 253.47l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M696.86 250.72l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M698.03 251.35l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M779.81 298.54v2.22l-84.27-48.92v22.86l-.74-.44v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-2)"
                      d="M781.92 297.32v30.06l24.48-13.93v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M804.28 312.22v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-3)"
                      d="M806.4 283.33l-24.48 13.99-89.24-51.8 24.5-13.97 89.22 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M786.09 297.78l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M784.92 298.42l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M789.38 295.84l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M788.22 296.48l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M792.68 293.9l-1.11.74v25.16l1.11-.74V293.9z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M791.52 294.54l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M795.98 291.97l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M794.81 292.6l-.42-.02v25.16l.42.02V292.6z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M799.27 290.03l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M798.11 290.66l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M802.57 288.09l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M801.41 288.73l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M783.39 298.99v2.22l20.5-12v22.86l.74-.43v-25.08l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-4)"
                      d="M863.77 217.65v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M861.66 218.87l-85.01-49.36v25.08l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M858.96 217.65l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M860.12 218.29l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M855.31 215.53l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M856.48 216.17l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M851.66 213.42l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M852.83 214.05l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M848.02 211.3l1.11.73v25.16l-1.11-.74V211.3z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M849.18 211.93l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M844.37 209.18l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M845.53 209.81l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M840.72 207.06l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M841.89 207.69l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M837.07 204.94l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M838.24 205.57l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M833.43 202.82l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M834.59 203.46l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M829.78 200.7l1.11.74v25.16l-1.11-.74V200.7z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M830.94 201.34l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M826.13 198.59l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M827.29 199.22l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M822.48 196.47l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M823.65 197.1l.42-.02v25.16l-.42.02V197.1z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M818.84 194.35l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M820 194.98l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M815.19 192.23l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M816.35 192.86l.42-.02V218l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M811.54 190.11l1.11.74V216l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M812.7 190.74l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M807.89 187.99l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M809.06 188.63l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M804.25 185.87l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M805.41 186.51l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M800.6 183.75l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M801.76 184.39l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M796.95 181.63l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M798.11 182.27l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M793.3 179.52l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M794.47 180.15l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M789.65 177.4l1.12.73v25.16l-1.12-.74V177.4z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M790.82 178.03l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M786.01 175.28l1.11.73v25.16l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M787.17 175.91l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M782.36 173.16l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M783.52 173.79l.43-.01v25.16l-.43.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M778.71 171.04l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M779.88 171.68l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M861.66 218.87v2.21l-84.27-48.92v22.86l-.74-.43v-25.08l85.01 49.36z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-5)"
                      d="M863.76 217.65v30.05l24.49-13.92v-30.12l-24.49 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M886.13 232.54V207.3l-20.25 11.58v25.18l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-6)"
                      d="M888.25 203.66l-24.49 13.99-89.23-51.81 24.5-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M867.93 218.1l-1.11.74V244l1.11-.74V218.1z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M866.77 218.74l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M871.23 216.17l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M870.07 216.8l-.42-.02v25.16l.42.02V216.8z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M874.53 214.23l-1.11.74v25.15l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M873.37 214.86l-.43-.02V240l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M877.83 212.29l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M876.66 212.93l-.42-.02v25.15l.42.02v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M881.12 210.35l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M879.96 210.99l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M884.42 208.41l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M883.26 209.05l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M865.24 219.32v2.21l20.5-12v22.86l.74-.43v-25.07l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2)"
                      d="M836.31 233.32v30.05l-89.23-51.9v-29.96l89.23 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M834.2 234.53l-85.01-49.35v25.08l85.01 49.43v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M831.5 233.32l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M832.66 233.96l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M827.85 231.2l1.12.74v25.16l-1.12-.74V231.2z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M829.02 231.84l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M824.21 229.09l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M825.37 229.72l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M820.56 226.97l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M821.72 227.6l.42-.02v25.16l-.42.02V227.6z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M816.91 224.85l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M818.07 225.48l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M813.26 222.73l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M814.42 223.36l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M809.61 220.61l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M810.78 221.25l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M805.97 218.49l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M807.13 219.13l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M802.32 216.37l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M803.48 217.01l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M798.67 214.25l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M799.83 214.89l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M795.02 212.13l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M796.19 212.77l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M791.38 210.02l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M792.54 210.65l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M787.73 207.9l1.11.73v25.16l-1.11-.74V207.9z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M788.89 208.53l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M784.08 205.78l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M785.24 206.41l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M780.43 203.66l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M781.6 204.29l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M776.78 201.54l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M777.95 202.18l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M773.14 199.42l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M774.3 200.06l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M769.49 197.3l1.11.74v25.16l-1.11-.74V197.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M770.65 197.94l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M765.84 195.19l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M767.01 195.82l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M762.19 193.07l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M763.36 193.7l.42-.02v25.16l-.42.02V193.7z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M758.55 190.95l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M759.71 191.58l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M754.9 188.83l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M756.06 189.46l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M751.25 186.71l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M752.41 187.34l.43-.01v25.16l-.43.01v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M834.2 234.53v2.22l-84.27-48.92v22.86l-.74-.43v-25.08l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-2)"
                      d="M836.3 233.32v30.05l24.48-13.93v-30.11l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M858.67 248.21v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-3)"
                      d="M860.78 219.33l-24.48 13.99-89.22-51.81 24.49-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M840.48 233.77l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M839.31 234.41l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M843.77 231.84l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M842.61 232.47l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M847.07 229.9l-1.11.73v25.16l1.11-.74V229.9z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M845.9 230.53l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M850.37 227.96l-1.12.74v25.15l1.12-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M849.2 228.59l-.42-.01v25.16l.42.01v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M853.66 226.02l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M852.5 226.66l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M856.96 224.09l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M855.8 224.72l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M837.78 234.99v2.21l20.5-12v22.86l.74-.43v-25.07l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-4)"
                      d="M809.01 248.68v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M806.89 249.89l-85.01-49.35v25.08l85.01 49.43v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M804.2 248.68l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M805.36 249.32l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M800.55 246.56l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M801.71 247.2l.42-.02v25.16l-.42.01V247.2z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M796.9 244.44l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M798.06 245.08l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M793.25 242.33l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M794.41 242.96l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M789.6 240.21l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M790.77 240.84l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M785.96 238.09l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M787.12 238.72l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M782.31 235.97l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M783.47 236.6l.42-.01v25.15l-.42.02V236.6z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M778.66 233.85l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M779.83 234.49l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M775.01 231.73l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M776.18 232.37l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M771.37 229.61l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M772.53 230.25l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M767.72 227.49l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M768.88 228.13l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M764.07 225.38l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M765.23 226.01l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M760.42 223.26l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M761.59 223.89l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M756.77 221.14l1.12.74v25.15l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M757.94 221.77l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M753.13 219.02l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M754.29 219.65l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M749.48 216.9l1.11.74v25.16l-1.11-.74V216.9z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M750.64 217.54l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M745.83 214.78l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M747 215.42l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M742.18 212.66l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M743.35 213.3l.42-.02v25.16l-.42.02V213.3z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M738.54 210.54l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M739.7 211.18l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M734.89 208.43l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M736.05 209.06l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M731.24 206.31l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M732.4 206.94l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M727.59 204.19l1.12.74v25.15l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M728.76 204.82l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M723.95 202.07l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M725.11 202.71l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M806.89 249.89v2.22l-84.26-48.92v22.86l-.75-.43v-25.08l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-5)"
                      d="M809 248.68v30.05l24.48-13.93v-30.11L809 248.68z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M831.36 263.57v-25.24l-20.24 11.57v25.19l20.24-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-6)"
                      d="M833.48 234.69L809 248.68l-89.23-51.81 24.49-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M813.17 249.13l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M812.01 249.77l-.43-.02v25.16l.43.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M816.47 247.19l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M815.3 247.83l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M819.76 245.26l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M818.6 245.89l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M823.06 243.32l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M821.9 243.95l-.43-.01v25.15l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M826.36 241.38l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M825.19 242.02l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M829.65 239.44l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M828.49 240.08l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M810.47 250.34v2.22l20.51-12v22.86l.74-.43v-25.07l-21.25 12.42z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-7)"
                      d="M754.54 343.38v30.06l-89.24-51.9v-29.96l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M752.42 344.6l-85-49.35v25.08l85 49.43V344.6z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M749.73 343.39l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M750.89 344.02l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M746.08 341.27l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M747.24 341.91l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M742.43 339.15l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M743.6 339.79l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M738.78 337.03l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M739.95 337.67l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M735.14 334.92l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M736.3 335.55l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M731.49 332.8l1.11.73v25.16l-1.11-.74V332.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M732.65 333.43l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M727.84 330.68l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M729 331.31l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M724.19 328.56l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M725.36 329.19l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M720.54 326.44l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M721.71 327.07l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M716.9 324.32l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M718.06 324.96l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M713.25 322.2l1.11.74v25.16l-1.11-.74V322.2z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M714.41 322.84l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M709.6 320.08l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M710.77 320.72l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M705.95 317.97l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M707.12 318.6l.42-.02v25.16l-.42.02V318.6z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M702.31 315.85l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M703.47 316.48l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M698.66 313.73l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M699.82 314.36l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M695.01 311.61l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M696.17 312.24l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M691.36 309.49l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M692.53 310.13l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M687.72 307.37l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M688.88 308.01l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M684.07 305.25l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M685.23 305.89l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M680.42 303.13l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M681.58 303.77l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M676.77 301.02l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M677.94 301.65l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M673.13 298.9l1.11.73v25.16l-1.11-.74V298.9z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M674.29 299.53l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M669.48 296.78l1.11.73v25.16l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M670.64 297.41l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M752.42 344.6v2.22l-84.26-48.92v22.86l-.74-.43v-25.08l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-8)"
                      d="M754.53 343.38v30.06l24.48-13.93v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M776.9 358.28v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-9)"
                      d="M779.01 329.39l-24.48 13.99-89.23-51.8 24.5-13.97 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M758.7 343.84l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M757.54 344.48l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M762 341.9l-1.11.74v25.16l1.11-.74V341.9z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M760.84 342.54l-.43-.02v25.16l.43.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M765.29 339.97l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M764.13 340.6l-.42-.02v25.16l.42.02V340.6z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M768.59 338.03l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M767.43 338.66l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M771.89 336.09l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M770.73 336.73l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M775.19 334.15l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M774.02 334.79l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M756 345.05v2.22l20.51-12v22.86l.74-.43v-25.07L756 345.05z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-10)"
                      d="M754.54 312.36v30.05l-89.24-51.9v-29.96l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M752.42 313.57l-85-49.35v25.08l85 49.43v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M749.73 312.36l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M750.89 313l.42-.02v25.16l-.42.01V313z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M746.08 310.24l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M747.24 310.88l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M742.43 308.12l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M743.6 308.76l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M738.78 306l1.12.74v25.16l-1.12-.74V306z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M739.95 306.64l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M735.14 303.89l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M736.3 304.52l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M731.49 301.77l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M732.65 302.4l.43-.02v25.16l-.43.02V302.4z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M727.84 299.65l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M729 300.28l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M724.19 297.53l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M725.36 298.17l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M720.54 295.41l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M721.71 296.05l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M716.9 293.29l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M718.06 293.93l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M713.25 291.17l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M714.41 291.81l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M709.6 289.06l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M710.77 289.69l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M705.95 286.94l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M707.12 287.57l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M702.31 284.82l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M703.47 285.45l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M698.66 282.7l1.11.74v25.15l-1.11-.73V282.7z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M699.82 283.33l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M695.01 280.58l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M696.17 281.21l.43-.01v25.16l-.43.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M691.36 278.46l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M692.53 279.1l.42-.02v25.16l-.42.01V279.1z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M687.72 276.34l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M688.88 276.98l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M684.07 274.23l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M685.23 274.86l.42-.02V300l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M680.42 272.11l1.11.73V298l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M681.58 272.74l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M676.77 269.99l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M677.94 270.62l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M673.13 267.87l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M674.29 268.5l.42-.01v25.15l-.42.02V268.5z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M669.48 265.75l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M670.64 266.38l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M752.42 313.57v2.22l-84.26-48.92v22.86l-.74-.43v-25.08l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-11)"
                      d="M754.53 312.36v30.05l24.48-13.93v-30.11l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M776.9 327.25v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-12)"
                      d="M779.01 298.37l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M758.7 312.81l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M757.54 313.45l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M762 310.88l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M760.84 311.51l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M765.29 308.94l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M764.13 309.57l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M768.59 307l-1.11.74v25.15l1.11-.74V307z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M767.43 307.63l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M771.89 305.06l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M770.73 305.7l-.43-.02v25.16l.43.01V305.7z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M775.19 303.12l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M774.02 303.76l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M756 314.02v2.22l20.51-12v22.86l.74-.43V301.6L756 314.02z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-7)"
                      d="M781.92 264.35v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M779.81 265.57l-85.01-49.35v25.07l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M777.11 264.35l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M778.27 264.99l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M773.46 262.24l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M774.63 262.87l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M769.82 260.12l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M770.98 260.75l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M766.17 258l1.11.73v25.16l-1.11-.74V258z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M767.33 258.63l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M762.52 255.88l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M763.68 256.51l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M758.87 253.76l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M760.04 254.4l.42-.02v25.16l-.42.01V254.4z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M755.22 251.64l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M756.39 252.28l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M751.58 249.52l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M752.74 250.16l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M747.93 247.4l1.11.74v25.16l-1.11-.74V247.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M749.09 248.04l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M744.28 245.28l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M745.45 245.92l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M740.63 243.17l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M741.8 243.8l.42-.02v25.16l-.42.02V243.8z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M736.99 241.05l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M738.15 241.68l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M733.34 238.93l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M734.5 239.56l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M729.69 236.81l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M730.86 237.44l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M726.04 234.69l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M727.21 235.33l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M722.4 232.57l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M723.56 233.21l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M718.75 230.45l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M719.91 231.09l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M715.1 228.34l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M716.26 228.97l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M711.45 226.22l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M712.62 226.85l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M707.8 224.1l1.12.73v25.16l-1.12-.74V224.1z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M708.97 224.73l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M704.16 221.98l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M705.32 222.61l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M700.51 219.86l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M701.67 220.5l.43-.02v25.15l-.43.02V220.5z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M696.86 217.74l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M698.03 218.38l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M779.81 265.57v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-8)"
                      d="M781.92 264.35v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M804.28 279.25V254l-20.25 11.58v25.18l20.25-11.51z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-9)"
                      d="M806.4 250.36l-24.48 13.99-89.24-51.81 24.5-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M786.09 264.81l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M784.92 265.44l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M789.38 262.87l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M788.22 263.5l-.42-.02v25.16l.42.02V263.5z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M792.68 260.93l-1.11.74v25.15l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M791.52 261.56l-.43-.01v25.15l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M795.98 258.99l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M794.81 259.63l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M799.27 257.05l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M798.11 257.69l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M802.57 255.12l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M801.41 255.75l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M783.39 266.02v2.21l20.5-12v22.86l.74-.43v-25.07l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-10)"
                      d="M754.54 280.09v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M752.42 281.3l-85-49.35v25.08l85 49.43V281.3z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M749.73 280.09l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M750.89 280.73l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M746.08 277.97l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M747.24 278.61l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M742.43 275.85l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M743.6 276.49l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M738.78 273.74l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M739.95 274.37l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M735.14 271.62l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M736.3 272.25l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M731.49 269.5l1.11.73v25.16l-1.11-.74V269.5z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M732.65 270.13l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M727.84 267.38l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M729 268.01l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M724.19 265.26l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M725.36 265.89l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M720.54 263.14l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M721.71 263.78l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M716.9 261.02l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M718.06 261.66l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M713.25 258.9l1.11.74v25.16l-1.11-.74V258.9z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M714.41 259.54l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M709.6 256.79l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M710.77 257.42l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M705.95 254.67l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M707.12 255.3l.42-.02v25.16l-.42.02V255.3z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M702.31 252.55l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M703.47 253.18l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M698.66 250.43l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M699.82 251.06l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M695.01 248.31l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M696.17 248.95l.43-.02v25.15l-.43.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M691.36 246.19l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M692.53 246.83l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M687.72 244.07l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M688.88 244.71l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M684.07 241.96l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M685.23 242.59l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M680.42 239.84l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M681.58 240.47l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M676.77 237.72l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M677.94 238.35l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M673.13 235.6l1.11.74v25.15l-1.11-.74V235.6z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M674.29 236.23l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M669.48 233.48l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M670.64 234.11l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M752.42 281.3v2.22l-84.26-48.92v22.86l-.74-.43v-25.08l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-11)"
                      d="M754.53 280.09v30.05l24.48-13.93V266.1l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M776.9 294.98v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-12)"
                      d="M779.01 266.1l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M758.7 280.54l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M757.54 281.18l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M762 278.61l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M760.84 279.24l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M765.29 276.67l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M764.13 277.3l-.42-.02v25.16l.42.02V277.3z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M768.59 274.73l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M767.43 275.36l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M771.89 272.79l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M770.73 273.43l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M775.19 270.85l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M774.02 271.49l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M756 281.75v2.22l20.51-12v22.86l.74-.43v-25.07L756 281.75z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-13)"
                      d="M1050.85 390.2v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M1048.73 391.42l-85-49.35v25.07l85 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1046.04 390.2l1.11.74v25.16l-1.11-.74V390.2z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1047.2 390.84l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1042.39 388.09l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1043.55 388.72l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1038.74 385.97l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1039.9 386.6l.43-.02v25.16l-.43.02V386.6z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1035.09 383.85l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1036.26 384.48l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1031.44 381.73l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1032.61 382.37l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1027.8 379.61l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1028.96 380.25l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1024.15 377.49l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1025.31 378.13l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1020.5 375.37l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1021.67 376.01l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1016.86 373.25l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1018.02 373.89l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1013.21 371.14l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1014.37 371.77l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1009.56 369.02l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1010.72 369.65l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1005.91 366.9l1.11.74v25.15l-1.11-.73V366.9z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1007.08 367.53l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1002.26 364.78l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1003.43 365.42l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M998.62 362.66l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M999.78 363.3l.42-.02v25.16l-.42.01V363.3z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M994.97 360.54l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M996.13 361.18l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M991.32 358.42l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M992.49 359.06l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M987.67 356.31l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M988.84 356.94l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M984.03 354.19l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M985.19 354.82l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M980.38 352.07l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M981.54 352.7l.43-.02v25.16l-.43.02V352.7z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M976.73 349.95l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M977.89 350.58l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M973.08 347.83l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M974.25 348.46l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M969.43 345.71l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M970.6 346.35l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M965.79 343.59l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M966.95 344.23l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M1048.73 391.42v2.21l-84.26-48.92v22.86l-.74-.43v-25.07l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-14)"
                      d="M1050.84 390.2v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M1073.21 405.1v-25.25l-20.25 11.58v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-15)"
                      d="M1075.32 376.21l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1055.01 390.66l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1053.85 391.29l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1058.31 388.72l-1.11.73v25.16l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1057.14 389.35l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1061.61 386.78l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1060.44 387.42l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M1064.9 384.84l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M1063.74 385.48l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M1068.2 382.9l-1.11.74v25.16l1.11-.74V382.9z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M1067.04 383.54l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M1071.5 380.97l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M1070.33 381.6l-.42-.02v25.16l.42.02V381.6z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M1052.31 391.87v2.21l20.51-12v22.87l.74-.44v-25.07l-21.25 12.43z"
                    />
                  </g>
                  <g id="prefix__collection1container1">
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-16)"
                      d="M1050.85 359.17v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M1048.73 360.39l-85-49.35v25.07l85 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1046.04 359.18l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1047.2 359.81l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1042.39 357.06l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1043.55 357.69l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1038.74 354.94l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1039.9 355.57l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1035.09 352.82l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1036.26 353.45l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1031.44 350.7l1.12.74v25.16l-1.12-.74V350.7z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1032.61 351.34l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1027.8 348.58l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1028.96 349.22l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1024.15 346.46l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1025.31 347.1l.43-.02v25.16l-.43.01V347.1z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1020.5 344.35l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1021.67 344.98l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1016.86 342.23l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1018.02 342.86l.42-.02V368l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1013.21 340.11l1.11.73V366l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1014.37 340.74l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1009.56 337.99l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1010.72 338.62l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1005.91 335.87l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1007.08 336.5l.42-.01v25.15l-.42.02V336.5z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1002.26 333.75l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1003.43 334.39l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M998.62 331.63l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M999.78 332.27l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M994.97 329.51l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M996.13 330.15l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M991.32 327.4l1.11.73v25.16l-1.11-.74V327.4z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M992.49 328.03l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M987.67 325.28l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M988.84 325.91l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M984.03 323.16l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M985.19 323.79l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M980.38 321.04l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M981.54 321.67l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M976.73 318.92l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M977.89 319.56l.43-.02v25.15l-.43.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M973.08 316.8l1.12.74v25.16l-1.12-.74V316.8z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M974.25 317.44l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M969.43 314.68l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M970.6 315.32l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M965.79 312.56l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M966.95 313.2l.42-.02v25.16l-.42.02V313.2z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M1048.73 360.39v2.21l-84.26-48.92v22.86l-.74-.43v-25.07l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-17)"
                      d="M1050.84 359.17v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M1073.21 374.07v-25.25l-20.25 11.58v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-18)"
                      d="M1075.32 345.18l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1055.01 359.63l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1053.85 360.26l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1058.31 357.69l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1057.14 358.32l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1061.61 355.75l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1060.44 356.39l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M1064.9 353.81l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M1063.74 354.45l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M1068.2 351.88l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M1067.04 352.51l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M1071.5 349.94l-1.12.74v25.15l1.12-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M1070.33 350.57l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M1052.31 360.84v2.21l20.51-11.99v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-19)"
                      d="M940.79 293.72v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M938.67 294.94l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M935.97 293.73l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M937.14 294.36l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M932.33 291.61l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M933.49 292.24l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M928.68 289.49l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M929.84 290.12l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M925.03 287.37l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M926.19 288l.43-.01v25.15l-.43.02V288z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M921.38 285.25l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M922.55 285.89l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M917.74 283.13l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M918.9 283.77l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M914.09 281.01l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M915.25 281.65l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M910.44 278.89l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M911.6 279.53l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M906.79 276.78l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M907.96 277.41l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M903.14 274.66l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M904.31 275.29l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M899.5 272.54l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M900.66 273.17l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M895.85 270.42l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M897.01 271.06l.43-.02v25.15l-.43.02v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M892.2 268.3l1.11.74v25.16l-1.11-.74V268.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M893.37 268.94l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M888.55 266.18l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M889.72 266.82l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M884.91 264.06l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M886.07 264.7l.42-.02v25.16l-.42.02V264.7z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M881.26 261.95l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M882.42 262.58l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M877.61 259.83l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M878.77 260.46l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M873.96 257.71l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M875.13 258.34l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M870.32 255.59l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M871.48 256.22l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M866.67 253.47l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M867.83 254.1l.42-.01v25.16l-.42.01V254.1z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M863.02 251.35l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M864.18 251.99l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M859.37 249.23l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M860.54 249.87l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M855.73 247.12l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M856.89 247.75l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M938.67 294.94v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-20)"
                      d="M940.78 293.72v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M963.14 308.62v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-21)"
                      d="M965.26 279.73l-24.48 13.99-89.23-51.81 24.49-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M944.95 294.18l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M943.78 294.81l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M948.25 292.24l-1.12.74v25.15l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M947.08 292.87l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M951.54 290.3l-1.11.74v25.16l1.11-.74V290.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M950.38 290.94l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M954.84 288.36l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M953.68 289l-.43-.02v25.16l.43.01V289z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M958.14 286.43l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M956.97 287.06l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M961.43 284.49l-1.11.74v25.15l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M960.27 285.12l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M942.25 295.39v2.21l20.5-11.99v22.86l.75-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-13)"
                      d="M947.55 272.59v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M945.43 273.81l-85-49.35v25.07l85 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M942.74 272.6l1.11.73v25.16l-1.11-.74V272.6z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M943.9 273.23l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M939.09 270.48l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M940.25 271.11l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M935.44 268.36l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M936.6 268.99l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M931.79 266.24l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M932.96 266.88l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M928.14 264.12l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M929.31 264.76l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M924.5 262l1.11.74v25.16l-1.11-.74V262z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M925.66 262.64l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M920.85 259.88l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M922.01 260.52l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M917.2 257.77l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M918.37 258.4l.42-.02v25.16l-.42.02V258.4z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M913.55 255.65l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M914.72 256.28l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M909.91 253.53l1.11.73v25.16l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M911.07 254.16l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M906.26 251.41l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M907.42 252.04l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M902.61 249.29l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M903.77 249.93l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M898.96 247.17l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M900.13 247.81l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M895.32 245.05l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M896.48 245.69l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M891.67 242.94l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M892.83 243.57l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M888.02 240.82l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M889.18 241.45l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M884.37 238.7l1.12.73v25.16l-1.12-.74V238.7z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M885.54 239.33l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M880.73 236.58l1.11.73v25.16l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M881.89 237.21l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M877.08 234.46l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M878.24 235.09l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M873.43 232.34l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M874.59 232.98l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M869.78 230.22l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M870.95 230.86l.42-.02V256l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M866.13 228.1l1.12.74V254l-1.12-.74V228.1z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M867.3 228.74l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M862.49 225.99l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M863.65 226.62l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M945.43 273.81v2.21l-84.26-48.92v22.86l-.74-.43v-25.07l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-14)"
                      d="M947.54 272.59v30.05l24.48-13.92V258.6l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M969.9 287.49v-25.24l-20.24 11.57v25.19l20.24-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-15)"
                      d="M972.02 258.6l-24.48 13.99-89.23-51.81 24.5-13.96 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M951.71 273.05l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M950.55 273.68l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M955.01 271.11l-1.12.74V297l1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M953.84 271.75l-.42-.02v25.15l.42.02v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M958.3 269.17l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M957.14 269.81l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M961.6 267.24l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M960.44 267.87l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M964.9 265.3l-1.11.73v25.16l1.11-.74V265.3z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M963.73 265.93l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M968.2 263.36l-1.12.74v25.15l1.12-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M967.03 263.99l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M949.01 274.26v2.22l20.51-12v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-22)"
                      d="M929.81 320.78v30.06l-89.24-51.9v-29.96l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M927.7 322l-85.01-49.35v25.08l85.01 49.43V322z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M925 320.79l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M926.16 321.42l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M921.35 318.67l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M922.51 319.31l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M917.7 316.55l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M918.86 317.19l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M914.05 314.43l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M915.22 315.07l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M910.4 312.31l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M911.57 312.95l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M906.76 310.19l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M907.92 310.83l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M903.11 308.08l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M904.27 308.71l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M899.46 305.96l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M900.63 306.59l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M895.81 303.84l1.12.73v25.16l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M896.98 304.47l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M892.17 301.72l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M893.33 302.36l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M888.52 299.6l1.11.74v25.16l-1.11-.74V299.6z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M889.68 300.24l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M884.87 297.48l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M886.03 298.12l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M881.22 295.36l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M882.39 296l.42-.02v25.16l-.42.01V296z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M877.58 293.25l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M878.74 293.88l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M873.93 291.13l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M875.09 291.76l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M870.28 289.01l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M871.44 289.64l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M866.63 286.89l1.12.74v25.15l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M867.8 287.52l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M862.99 284.77l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M864.15 285.4l.42-.01v25.16l-.42.01V285.4z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M859.34 282.65l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M860.5 283.29l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M855.69 280.53l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M856.85 281.17l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M852.04 278.41l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M853.21 279.05l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M848.39 276.3l1.12.73v25.16l-1.12-.74V276.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M849.56 276.93l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M844.75 274.18l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M845.91 274.81l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M927.7 322v2.22l-84.27-48.92v22.85l-.74-.42v-25.08L927.7 322z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-23)"
                      d="M929.8 320.78v30.06l24.48-13.93v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M952.16 335.68v-25.24l-20.24 11.57v25.19l20.24-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-24)"
                      d="M954.28 306.79l-24.48 13.99-89.23-51.8 24.5-13.97 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M933.97 321.24l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M932.81 321.87l-.43-.01v25.15l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M937.27 319.3l-1.12.74v25.16l1.12-.74V319.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M936.1 319.94l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M940.57 317.36l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M939.4 318l-.42-.02v25.16l.42.01V318z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M943.86 315.43l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M942.7 316.06l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M947.16 313.49l-1.11.74v25.15l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M946 314.12l-.43-.01v25.15l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M950.46 311.55l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M949.29 312.19l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M931.27 322.45v2.22l20.51-12v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-25)"
                      d="M1023.39 405.87v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M1021.27 407.09l-85-49.35v25.07l85 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1018.58 405.88l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1019.74 406.51l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1014.93 403.76l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1016.09 404.39l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1011.28 401.64l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1012.44 402.27l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1007.63 399.52l1.12.73v25.16l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1008.8 400.15l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1003.99 397.4l1.11.74v25.15l-1.11-.73V397.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1005.15 398.03l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M1000.34 395.28l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M1001.5 395.92l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M996.69 393.16l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M997.85 393.8l.43-.02v25.16l-.43.01V393.8z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M993.04 391.04l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M994.21 391.68l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M989.39 388.93l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M990.56 389.56l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M985.75 386.81l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M986.91 387.44l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M982.1 384.69l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M983.26 385.32l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M978.45 382.57l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M979.62 383.2l.42-.01v25.15l-.42.02V383.2z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M974.8 380.45l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M975.97 381.08l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M971.16 378.33l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M972.32 378.97l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M967.51 376.21l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M968.67 376.85l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M963.86 374.09l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M965.02 374.73l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M960.21 371.98l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M961.38 372.61l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M956.57 369.86l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M957.73 370.49l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M952.92 367.74l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M954.08 368.37l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M949.27 365.62l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M950.43 366.25l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M945.62 363.5l1.12.74v25.15l-1.12-.73V363.5z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M946.79 364.13l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M941.98 361.38l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M943.14 362.02l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M938.33 359.26l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M939.49 359.9l.42-.02v25.16l-.42.01V359.9z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M1021.27 407.09v2.21l-84.26-48.92v22.86l-.74-.43v-25.07l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-26)"
                      d="M1023.38 405.87v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M1045.74 420.77v-25.25l-20.24 11.58v25.19l20.24-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-27)"
                      d="M1047.86 391.88l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1027.55 406.33l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1026.39 406.96l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1030.85 404.39l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1029.68 405.02l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M1034.14 402.45l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M1032.98 403.08l-.42-.01v25.16l.42.01v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M1037.44 400.51l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M1036.28 401.15l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M1040.74 398.57l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M1039.58 399.21l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M1044.04 396.64l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M1042.87 397.27l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M1024.85 407.54v2.21l20.51-12v22.87l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g id="prefix__collection1container2">
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-16)"
                      d="M1023.39 374.84v30.05L934.15 353v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M1021.27 376.06l-85-49.35v25.07l85 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M1018.58 374.85l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M1019.74 375.48l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M1014.93 372.73l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M1016.09 373.36l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M1011.28 370.61l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M1012.44 371.24l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M1007.63 368.49l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M1008.8 369.12l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M1003.99 366.37l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M1005.15 367.01l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M1000.34 364.25l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M1001.5 364.89l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M996.69 362.13l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M997.85 362.77l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M993.04 360.01l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M994.21 360.65l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M989.39 357.9l1.12.73v25.16l-1.12-.74V357.9z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M990.56 358.53l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M985.75 355.78l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M986.91 356.41l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M982.1 353.66l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M983.26 354.29l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M978.45 351.54l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M979.62 352.18l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M974.8 349.42l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M975.97 350.06l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M971.16 347.3l1.11.74v25.16l-1.11-.74V347.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M972.32 347.94l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M967.51 345.18l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M968.67 345.82l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M963.86 343.06l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M965.02 343.7l.43-.02v25.16l-.43.02V343.7z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M960.21 340.95l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M961.38 341.58l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M956.57 338.83l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M957.73 339.46l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M952.92 336.71l1.11.73v25.16l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M954.08 337.34l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M949.27 334.59l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M950.43 335.23l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M945.62 332.47l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M946.79 333.11l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M941.98 330.35l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M943.14 330.99l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M938.33 328.23l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M939.49 328.87l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M1021.27 376.06v2.21l-84.26-48.92v22.86l-.74-.43v-25.07l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-17)"
                      d="M1023.38 374.84v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M1045.74 389.74V364.5l-20.24 11.57v25.19l20.24-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-18)"
                      d="M1047.86 360.85l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M1027.55 375.3l-1.11.73v25.16l1.11-.74V375.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M1026.39 375.93l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M1030.85 373.36l-1.12.74v25.15l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M1029.68 373.99l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M1034.14 371.42l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M1032.98 372.06l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M1037.44 369.48l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M1036.28 370.12l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M1040.74 367.55l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M1039.58 368.18l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M1044.04 365.61l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M1042.87 366.24l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M1024.85 376.51v2.21l20.51-11.99v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_3)"
                      d="M996.09 421.23v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e8dede"
                      d="M993.97 422.45l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M991.27 421.23l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M992.43 421.87l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M987.62 419.12l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M988.79 419.75l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M983.98 417l1.11.73v25.16l-1.11-.74V417z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M985.14 417.63l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M980.33 414.88l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M981.49 415.51l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M976.68 412.76l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M977.84 413.39l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M973.03 410.64l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M974.2 411.27l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M969.38 408.52l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M970.55 409.16l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M965.74 406.4l1.11.74v25.16l-1.11-.74V406.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M966.9 407.04l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M962.09 404.28l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M963.25 404.92l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M958.44 402.17l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M959.61 402.8l.42-.02v25.16l-.42.02V402.8z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M954.79 400.05l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M955.96 400.68l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#4f0004"
                      d="M951.15 397.93l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c3a8aa"
                      d="M952.31 398.56l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M947.5 395.81l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M948.66 396.44l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M943.85 393.69l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M945.01 394.32l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M940.2 391.57l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M941.37 392.21l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M936.56 389.45l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M937.72 390.09l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M932.91 387.33l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M934.07 387.97l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M929.26 385.22l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M930.42 385.85l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M925.61 383.1l1.12.73v25.16l-1.12-.74V383.1z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M926.78 383.73l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M921.97 380.98l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M923.13 381.61l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M918.32 378.86l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M919.48 379.49l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M914.67 376.74l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M915.83 377.38l.43-.02v25.15l-.43.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M911.02 374.62l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M912.18 375.26l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d7c4c5"
                      d="M993.97 422.45v2.21l-84.27-48.92v22.86l-.74-.43V373.1l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_3-2)"
                      d="M996.08 421.23v30.05l24.47-13.92v-30.12l-24.47 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e8dede"
                      d="M1018.44 436.13v-25.25l-20.25 11.58v25.18l20.25-11.51z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_3-3)"
                      d="M1020.55 407.24l-24.47 13.99-89.23-51.81 24.49-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M1000.25 421.69l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M999.08 422.32l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M1003.54 419.75l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M1002.38 420.38l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#4f0004"
                        d="M1006.84 417.81l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c3a8aa"
                        d="M1005.68 418.44l-.43-.01v25.15l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#4f0004"
                          d="M1010.14 415.87l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#c3a8aa"
                          d="M1008.97 416.51l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#4f0004"
                          d="M1013.43 413.93l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#c3a8aa"
                          d="M1012.27 414.57l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#4f0004"
                          d="M1016.73 412l-1.11.73v25.16l1.11-.74V412z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#c3a8aa"
                          d="M1015.57 412.63l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d7c4c5"
                      d="M997.55 422.9v2.21l20.5-12v22.86l.74-.43v-25.07l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-28)"
                      d="M847.97 398.34v30.06l-89.24-51.9v-29.96l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M845.86 399.56l-85.01-49.35v25.08l85.01 49.43v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M843.16 398.35l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M844.32 398.99l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M839.51 396.23l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M840.67 396.87l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M835.86 394.11l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M837.03 394.75l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M832.22 391.99l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M833.38 392.63l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M828.57 389.88l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M829.73 390.51l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M824.92 387.76l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M826.08 388.39l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M821.27 385.64l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M822.43 386.27l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M817.62 383.52l1.12.73v25.16l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M818.79 384.15l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M813.98 381.4l1.11.74v25.16l-1.11-.74V381.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M815.14 382.04l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M810.33 379.28l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M811.49 379.92l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M806.68 377.16l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M807.84 377.8l.43-.02v25.16l-.43.01V377.8z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M803.03 375.04l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M804.2 375.68l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M799.39 372.93l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M800.55 373.56l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M795.74 370.81l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M796.9 371.44l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M792.09 368.69l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M793.25 369.32l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M788.44 366.57l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M789.61 367.2l.42-.01v25.15l-.42.02V367.2z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M784.79 364.45l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M785.96 365.08l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M781.15 362.33l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M782.31 362.97l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M777.5 360.21l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M778.66 360.85l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M773.85 358.09l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M775.02 358.73l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M770.2 355.98l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M771.37 356.61l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M766.56 353.86l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M767.72 354.49l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M762.91 351.74l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M764.07 352.37l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M845.86 399.56v2.22l-84.27-48.92v22.86l-.74-.43v-25.08l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-29)"
                      d="M847.96 398.34v30.06l24.48-13.93v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M870.33 413.24V388l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-30)"
                      d="M872.44 384.35l-24.48 13.99-89.23-51.8 24.5-13.97 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M852.13 398.8l-1.11.74v25.15l1.11-.73V398.8z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M850.97 399.44l-.42-.02v25.15l.42.02v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M855.43 396.86l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M854.27 397.5l-.43-.02v25.16l.43.01V397.5z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M858.73 394.93l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M857.56 395.56l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M862.02 392.99l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M860.86 393.62l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M865.32 391.05l-1.11.74v25.15l1.11-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M864.16 391.69l-.43-.02v25.15l.43.02v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M868.62 389.11l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M867.45 389.75l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M849.43 400.01v2.22l20.51-12v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-31)"
                      d="M969 436.9v30.05l-89.24-51.89v-29.97L969 436.9z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M966.88 438.12l-85-49.35v25.07l85 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M964.19 436.91l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M965.35 437.54l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M960.54 434.79l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M961.7 435.42l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M956.89 432.67l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M958.05 433.3l.43-.01v25.15l-.43.02V433.3z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M953.24 430.55l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M954.41 431.18l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M949.6 428.43l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M950.76 429.07l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M945.95 426.31l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M947.11 426.95l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M942.3 424.19l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M943.46 424.83l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M938.65 422.07l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M939.82 422.71l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M935 419.96l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M936.17 420.59l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M931.36 417.84l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M932.52 418.47l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M927.71 415.72l1.11.73v25.16l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M928.87 416.35l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M924.06 413.6l1.11.74v25.15l-1.11-.73V413.6z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M925.23 414.23l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M920.41 411.48l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M921.58 412.12l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M916.77 409.36l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M917.93 410l.42-.02v25.16l-.42.01V410z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M913.12 407.24l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M914.28 407.88l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M909.47 405.13l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M910.63 405.76l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M905.82 403.01l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M906.99 403.64l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M902.18 400.89l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M903.34 401.52l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M898.53 398.77l1.11.73v25.16l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M899.69 399.4l.43-.02v25.16l-.43.02V399.4z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M894.88 396.65l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M896.04 397.29l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M891.23 394.53l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M892.4 395.17l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M887.59 392.41l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M888.75 393.05l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M883.94 390.29l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M885.1 390.93l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M966.88 438.12v2.21l-84.26-48.92v22.86l-.74-.43v-25.07l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-32)"
                      d="M968.99 436.9v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M991.36 451.8v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-33)"
                      d="M993.47 422.91l-24.48 13.99-89.23-51.81 24.5-13.96 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M973.16 437.36l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M972 437.99l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M976.46 435.42l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M975.29 436.05l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M979.75 433.48l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M978.59 434.12l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M983.05 431.54l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M981.89 432.18l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M986.35 429.61l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M985.18 430.24l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M989.65 427.67l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M988.48 428.3l-.42-.02v25.16l.42.02V428.3z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M970.46 438.57v2.21l20.51-11.99v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-34)"
                      d="M941.62 452.64v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M939.5 453.86l-85.01-49.36v25.08l85.01 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M936.8 452.64l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M937.97 453.28l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M933.15 450.52l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M934.32 451.16l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M929.51 448.41l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M930.67 449.04l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M925.86 446.29l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M927.02 446.92l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M922.21 444.17l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M923.38 444.8l.42-.01v25.15l-.42.02V444.8z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M918.56 442.05l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M919.73 442.69l.42-.02v25.15l-.42.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M914.92 439.93l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M916.08 440.57l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M911.27 437.81l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M912.43 438.45l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M907.62 435.69l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M908.78 436.33l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M903.97 433.57l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M905.14 434.21l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M900.33 431.46l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M901.49 432.09l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M896.68 429.34l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M897.84 429.97l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M893.03 427.22l1.11.73v25.16l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M894.19 427.85l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M889.38 425.1l1.12.74V451l-1.12-.74V425.1z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M890.55 425.74l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M885.74 422.98l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M886.9 423.62l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M882.09 420.86l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M883.25 421.5l.42-.02v25.16l-.42.01V421.5z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M878.44 418.74l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M879.6 419.38l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M874.79 416.63l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M875.96 417.26l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M871.14 414.51l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M872.31 415.14l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M867.5 412.39l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M868.66 413.02l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M863.85 410.27l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M865.01 410.9l.42-.01v25.15l-.42.02V410.9z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M860.2 408.15l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M861.37 408.79l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M856.55 406.03l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M857.72 406.67l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M939.5 453.86v2.21l-84.27-48.92v22.86l-.74-.43V404.5l85.01 49.36z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-35)"
                      d="M941.61 452.64v30.05l24.48-13.93v-30.11l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M963.97 467.54v-25.25l-20.25 11.58v25.18l20.25-11.51z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-36)"
                      d="M966.09 438.65l-24.48 13.99-89.23-51.81 24.49-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M945.78 453.1l-1.12.73v25.16l1.12-.74V453.1z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M944.61 453.73l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M949.07 451.16l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M947.91 451.79l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M952.37 449.22l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M951.21 449.85l-.43-.01v25.15l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M955.67 447.28l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M954.5 447.92l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M958.97 445.34l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M957.8 445.98l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M962.26 443.41l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M961.1 444.04l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M943.08 454.31v2.21l20.5-12v22.86l.74-.43v-25.07l-21.24 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-37)"
                      d="M902.44 335.95V366l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M900.33 337.17l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M897.63 335.96l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M898.79 336.59l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M893.98 333.84l1.11.73v25.16l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M895.14 334.47l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M890.33 331.72l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M891.49 332.36l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M886.68 329.6l1.11.74v25.16l-1.11-.74V329.6z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M887.85 330.24l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M883.03 327.48l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M884.2 328.12l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M879.39 325.36l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M880.55 326l.42-.02v25.16l-.42.01V326z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M875.74 323.25l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M876.9 323.88l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M872.09 321.13l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M873.26 321.76l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M868.45 319.01l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M869.61 319.64l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M864.8 316.89l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M865.96 317.52l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M861.15 314.77l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M862.31 315.4l.43-.01v25.16l-.43.01V315.4z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M857.5 312.65l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M858.66 313.29l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M853.85 310.53l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M855.02 311.17l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M850.21 308.41l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M851.37 309.05l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M846.56 306.3l1.11.73v25.16l-1.11-.74V306.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M847.72 306.93l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M842.91 304.18l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M844.07 304.81l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M839.26 302.06l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M840.43 302.69l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M835.62 299.94l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M836.78 300.57l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M831.97 297.82l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M833.13 298.45l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M828.32 295.7l1.11.74v25.16l-1.11-.74V295.7z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M829.48 296.34l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M824.67 293.58l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M825.84 294.22l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M821.02 291.46l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M822.19 292.1l.42-.02v25.16l-.42.01V292.1z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M817.38 289.35l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M818.54 289.98l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M900.33 337.17v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-38)"
                      d="M902.43 335.95V366l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M924.79 350.85v-25.24l-20.24 11.57v25.19l20.24-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-39)"
                      d="M926.91 321.96l-24.48 13.99-89.23-51.81 24.5-13.96 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M906.6 336.41l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M905.44 337.04l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M909.9 334.47l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M908.73 335.11l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M913.2 332.53l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M912.03 333.17l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M916.49 330.6l-1.11.73v25.16l1.11-.74V330.6z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M915.33 331.23l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M919.79 328.66l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M918.63 329.29l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M923.09 326.72l-1.12.74v25.15l1.12-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M921.92 327.35l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M903.9 337.62v2.21l20.51-11.99v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-40)"
                      d="M875.36 351.62v30.06l-89.24-51.9v-29.96l89.24 51.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M873.24 352.84l-85.01-49.35v25.07L873.24 378v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M870.54 351.63l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M871.71 352.26l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M866.89 349.51l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M868.06 350.14l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M863.25 347.39l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M864.41 348.03l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M859.6 345.27l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M860.76 345.91l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M855.95 343.15l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M857.12 343.79l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M852.3 341.04l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M853.47 341.67l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M848.66 338.92l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M849.82 339.55l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M845.01 336.8l1.11.74v25.15l-1.11-.74V336.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M846.17 337.43l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M841.36 334.68l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M842.52 335.31l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M837.71 332.56l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M838.88 333.2l.42-.02v25.16l-.42.01V333.2z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M834.07 330.44l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M835.23 331.08l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M830.42 328.32l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M831.58 328.96l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M826.77 326.2l1.11.74v25.16l-1.11-.74V326.2z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M827.93 326.84l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M823.12 324.09l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M824.29 324.72l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M819.48 321.97l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M820.64 322.6l.42-.02v25.16l-.42.02V322.6z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M815.83 319.85l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M816.99 320.48l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M812.18 317.73l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M813.34 318.37l.43-.02v25.15l-.43.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M808.53 315.61l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M809.7 316.25l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M804.88 313.49l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M806.05 314.13l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M801.24 311.37l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M802.4 312.01l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M797.59 309.25l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M798.75 309.89l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M793.94 307.14l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M795.1 307.77l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M790.29 305.02l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M791.46 305.65l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M873.24 352.84v2.22l-84.27-48.93V329l-.74-.44v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-41)"
                      d="M875.35 351.62v30.06l24.48-13.93v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M897.71 366.52v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-42)"
                      d="M899.83 337.63l-24.48 13.99-89.23-51.8 24.49-13.97 89.22 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M879.52 352.08l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M878.35 352.71l-.42-.01v25.16l.42.01v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M882.81 350.14l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M881.65 350.78l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M886.11 348.2l-1.11.74v25.16l1.11-.74V348.2z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M884.95 348.84l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M889.41 346.27l-1.12.73v25.16l1.12-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M888.24 346.9l-.42-.02v25.16l.42.02V346.9z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M892.71 344.33l-1.12.74v25.15l1.12-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M891.54 344.96l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M896 342.39l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M894.84 343.03l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M876.82 353.29v2.22l20.5-12v22.86l.74-.43v-25.07l-21.24 12.42z"
                    />
                  </g>
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-19)"
                      d="M847.97 367.36v30.06l-89.24-51.9v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M845.86 368.58l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M843.16 367.37l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M844.32 368l.42-.02v25.16l-.42.02V368z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M839.51 365.25l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M840.67 365.88l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M835.86 363.13l1.12.74v25.15l-1.12-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M837.03 363.76l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M832.22 361.01l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M833.38 361.65l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M828.57 358.89l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M829.73 359.53l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M824.92 356.77l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M826.08 357.41l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M821.27 354.65l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M822.43 355.29l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M817.62 352.54l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M818.79 353.17l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M813.98 350.42l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M815.14 351.05l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M810.33 348.3l1.11.74v25.15l-1.11-.74V348.3z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M811.49 348.93l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M806.68 346.18l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M807.84 346.81l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M803.03 344.06l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M804.2 344.7l.42-.02v25.16l-.42.01V344.7z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M799.39 341.94l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M800.55 342.58l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M795.74 339.82l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M796.9 340.46l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M792.09 337.7l1.11.74v25.16l-1.11-.74V337.7z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M793.25 338.34l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M788.44 335.59l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M789.61 336.22l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M784.79 333.47l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M785.96 334.1l.42-.02v25.16l-.42.02V334.1z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M781.15 331.35l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M782.31 331.98l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M777.5 329.23l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M778.66 329.87l.43-.02V355l-.43.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M773.85 327.11l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M775.02 327.75l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M770.2 324.99l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M771.37 325.63l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M766.56 322.87l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M767.72 323.51l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M762.91 320.76l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M764.07 321.39l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M845.86 368.58v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-20)"
                      d="M847.96 367.36v30.06l24.48-13.93v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M870.33 382.26v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-21)"
                      d="M872.44 353.37l-24.48 13.99-89.23-51.81 24.5-13.96 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M852.13 367.82l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M850.97 368.45l-.42-.01v25.15l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M855.43 365.88l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M854.27 366.51l-.43-.01v25.15l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M858.73 363.94l-1.12.74v25.16l1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M857.56 364.58l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M862.02 362l-1.11.74v25.16l1.11-.74V362z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M860.86 362.64l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M865.32 360.07l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M864.16 360.7l-.43-.02v25.16l.43.02V360.7z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M868.62 358.13l-1.12.74v25.15l1.12-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M867.45 358.76l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M849.43 369.03v2.22l20.51-12v22.86l.74-.43V356.6l-21.25 12.43z"
                    />
                  </g>
                  <g id="prefix__collection1container3">
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-22)"
                      d="M996.09 390.2v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M993.97 391.42l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M991.27 390.2l1.11.74v25.16l-1.11-.74V390.2z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M992.43 390.84l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M987.62 388.09l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M988.79 388.72l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M983.98 385.97l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M985.14 386.6l.42-.02v25.16l-.42.02V386.6z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M980.33 383.85l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M981.49 384.48l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M976.68 381.73l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M977.84 382.37l.43-.02v25.15l-.43.02v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M973.03 379.61l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M974.2 380.25l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M969.38 377.49l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M970.55 378.13l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M965.74 375.37l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M966.9 376.01l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M962.09 373.25l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M963.25 373.89l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M958.44 371.14l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M959.61 371.77l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M954.79 369.02l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M955.96 369.65l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M951.15 366.9l1.11.74v25.15l-1.11-.73V366.9z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M952.31 367.53l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M947.5 364.78l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M948.66 365.42l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M943.85 362.66l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M945.01 363.3l.43-.02v25.16l-.43.01V363.3z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M940.2 360.54l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M941.37 361.18l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M936.56 358.42l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M937.72 359.06l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M932.91 356.31l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M934.07 356.94l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M929.26 354.19l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M930.42 354.82l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M925.61 352.07l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M926.78 352.7l.42-.02v25.16l-.42.02V352.7z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M921.97 349.95l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M923.13 350.58l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M918.32 347.83l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M919.48 348.46l.42-.01v25.16l-.42.01v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M914.67 345.71l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M915.83 346.35l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M911.02 343.59l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M912.18 344.23l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M993.97 391.42v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-23)"
                      d="M996.08 390.2v30.05l24.47-13.92v-30.12l-24.47 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M1018.44 405.1v-25.25l-20.25 11.58v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-24)"
                      d="M1020.55 376.21l-24.47 13.99-89.23-51.81 24.49-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M1000.25 390.66l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M999.08 391.29l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M1003.54 388.72l-1.11.73v25.16l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M1002.38 389.35l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M1006.84 386.78l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M1005.68 387.42l-.43-.02v25.16l.43.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M1010.14 384.84l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M1008.97 385.48l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M1013.43 382.9l-1.11.74v25.16l1.11-.74V382.9z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M1012.27 383.54l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M1016.73 380.97l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M1015.57 381.6l-.43-.02v25.16l.43.02V381.6z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M997.55 391.87v2.21l20.5-12v22.87l.74-.44v-25.07l-21.24 12.43z"
                    />
                  </g>
                  <g id="prefix__collection1container4">
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-43)"
                      d="M969 405.87v30.06l-89.24-51.9v-29.97L969 405.87z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M966.88 407.09l-85-49.35v25.07l85 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M964.19 405.88l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M965.35 406.51l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M960.54 403.76l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M961.7 404.39l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M956.89 401.64l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M958.05 402.27l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M953.24 399.52l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M954.41 400.15l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M949.6 397.4l1.11.74v25.16l-1.11-.74V397.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M950.76 398.04l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M945.95 395.28l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M947.11 395.92l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M942.3 393.17l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M943.46 393.8l.43-.02v25.16l-.43.01V393.8z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M938.65 391.05l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M939.82 391.68l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M935 388.93l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M936.17 389.56l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M931.36 386.81l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M932.52 387.44l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M927.71 384.69l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M928.87 385.32l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#330007"
                      d="M924.06 382.57l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#baa8ab"
                      d="M925.23 383.21l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M920.41 380.45l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M921.58 381.09l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M916.77 378.33l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M917.93 378.97l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M913.12 376.21l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M914.28 376.85l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M909.47 374.1l1.11.73v25.16l-1.11-.74V374.1z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M910.63 374.73l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M905.82 371.98l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M906.99 372.61l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M902.18 369.86l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M903.34 370.49l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M898.53 367.74l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M899.69 368.38l.43-.02v25.15l-.43.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M894.88 365.62l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M896.04 366.26l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M891.23 363.5l1.12.74v25.16l-1.12-.74V363.5z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M892.4 364.14l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M887.59 361.38l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M888.75 362.02l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M883.94 359.26l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M885.1 359.9l.42-.02v25.16l-.42.02V359.9z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M966.88 407.09v2.21l-84.26-48.92v22.86l-.74-.43v-25.07l85 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-44)"
                      d="M968.99 405.87v30.06L993.47 422v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e4dedf"
                      d="M991.36 420.77v-25.24l-20.25 11.57v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_2-45)"
                      d="M993.47 391.88l-24.48 13.99-89.23-51.81 24.5-13.96 89.21 51.78z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M973.16 406.33l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M972 406.96l-.43-.02v25.16l.43.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M976.46 404.39l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M975.29 405.02l-.42-.01v25.16l.42.01v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#330007"
                        d="M979.75 402.45l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#baa8ab"
                        d="M978.59 403.09l-.42-.02v25.16l.42.01v-25.15z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M983.05 400.51l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M981.89 401.15l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M986.35 398.58l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M985.18 399.21l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#330007"
                          d="M989.65 396.64l-1.12.74v25.15l1.12-.73v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#baa8ab"
                          d="M988.48 397.27l-.42-.01v25.15l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#d0c4c6"
                      d="M970.46 407.54v2.21l20.51-11.99v22.86l.74-.43v-25.08l-21.25 12.43z"
                    />
                  </g>
                  <g id="prefix__collection1container5">
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-25)"
                      d="M941.62 421.61v30.05l-89.24-51.89V369.8l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M939.5 422.83l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M936.8 421.62l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M937.97 422.25l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M933.15 419.5l1.12.73v25.16l-1.12-.74V419.5z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M934.32 420.13l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M929.51 417.38l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M930.67 418.01l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M925.86 415.26l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M927.02 415.89l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M922.21 413.14l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M923.38 413.77l.42-.01v25.16l-.42.01v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M918.56 411.02l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M919.73 411.66l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M914.92 408.9l1.11.74v25.16l-1.11-.74V408.9z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M916.08 409.54l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M911.27 406.78l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M912.43 407.42l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M907.62 404.67l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M908.78 405.3l.43-.02v25.16l-.43.02V405.3z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M903.97 402.55l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M905.14 403.18l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M900.33 400.43l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M901.49 401.06l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#646566"
                      d="M896.68 398.31l1.11.74v25.15l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cacbcb"
                      d="M897.84 398.94l.42-.01v25.15l-.42.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M893.03 396.19l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M894.19 396.82l.43-.01v25.15l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M889.38 394.07l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M890.55 394.71l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M885.74 391.95l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M886.9 392.59l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M882.09 389.83l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M883.25 390.47l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M878.44 387.71l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M879.6 388.35l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M874.79 385.6l1.11.73v25.16l-1.11-.74V385.6z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M875.96 386.23l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M871.14 383.48l1.12.73v25.16l-1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M872.31 384.11l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M867.5 381.36l1.11.74v25.15l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M868.66 381.99l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M863.85 379.24l1.11.74v25.15l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M865.01 379.88l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M860.2 377.12l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M861.37 377.76l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M856.55 375l1.12.74v25.16l-1.12-.74V375z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M857.72 375.64l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M939.5 422.83v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-26)"
                      d="M941.61 421.61v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#ebebeb"
                      d="M963.97 436.51v-25.25l-20.25 11.58v25.19l20.25-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_21-27)"
                      d="M966.09 407.62l-24.48 13.99-89.23-51.81 24.49-13.97 89.22 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M945.78 422.07l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M944.61 422.7l-.42-.02v25.16l.42.02V422.7z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M949.07 420.13l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M947.91 420.76l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#646566"
                        d="M952.37 418.19l-1.11.74v25.15l1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#cacbcb"
                        d="M951.21 418.82l-.43-.01v25.15l.43.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M955.67 416.25l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M954.5 416.89l-.42-.02v25.16l.42.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M958.97 414.31l-1.12.74v25.16l1.12-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M957.8 414.95l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#646566"
                          d="M962.26 412.38l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#cacbcb"
                          d="M961.1 413.01l-.43-.02v25.16l.43.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbdcdc"
                      d="M943.08 423.28v2.21l20.5-11.99v22.86l.74-.43v-25.08l-21.24 12.43z"
                    />
                  </g>
                  <g id="prefix__collection1container6">
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#prefix__Linear_Gradient_1)"
                      d="M892.15 336.36l-19.93 11.39-72.63-42.17 19.94-11.37 72.62 42.15z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_22)"
                      d="M881.99 300.96v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eae2e2"
                      d="M879.87 302.18l-85-49.36v25.08l85 49.43v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M877.17 300.96l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M878.34 301.6l.42-.02v25.16l-.42.01V301.6z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M873.53 298.84l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M874.69 299.48l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M869.88 296.73l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M871.04 297.36l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M866.23 294.61l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M867.39 295.24l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M862.58 292.49l1.12.73v25.16l-1.12-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M863.75 293.12l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M858.94 290.37l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M860.1 291l.42-.01v25.15l-.42.02V291z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M855.29 288.25l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M856.45 288.88l.43-.01v25.15l-.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M851.64 286.13l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M852.8 286.77l.43-.02v25.16l-.43.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M847.99 284.01l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M849.16 284.65l.42-.02v25.16l-.42.01v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M844.35 281.89l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M845.51 282.53l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M840.7 279.78l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M841.86 280.41l.42-.02v25.16l-.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#612121"
                      d="M837.05 277.66l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c9b4b4"
                      d="M838.21 278.29l.43-.02v25.16l-.43.02v-25.16z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M833.4 275.54l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M834.57 276.17l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M829.75 273.42l1.12.74v25.15l-1.12-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M830.92 274.05l.42-.01v25.15l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M826.11 271.3l1.11.74v25.16l-1.11-.74V271.3z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M827.27 271.94l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M822.46 269.18l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M823.62 269.82l.43-.02v25.16l-.43.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M818.81 267.06l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M819.98 267.7l.42-.02v25.16l-.42.01V267.7z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M815.16 264.94l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M816.33 265.58l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M811.52 262.83l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M812.68 263.46l.42-.02v25.16l-.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M807.87 260.71l1.11.73v25.16l-1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M809.03 261.34l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M804.22 258.59l1.11.73v25.16l-1.11-.73v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M805.38 259.22l.43-.02v25.16l-.43.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M800.57 256.47l1.12.74v25.16l-1.12-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M801.74 257.11l.42-.02v25.15l-.42.02v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M796.93 254.35l1.11.74v25.16l-1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M798.09 254.99l.42-.02v25.16l-.42.01v-25.15z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbcccc"
                      d="M879.87 302.18v2.21l-84.26-48.92v22.86l-.74-.43v-25.08l85 49.36z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_22-2)"
                      d="M881.98 300.96v30.05l24.48-13.93v-30.11l-24.48 13.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#eae2e2"
                      d="M904.34 315.85v-25.24l-20.24 11.58v25.18l20.24-11.52z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_22-3)"
                      d="M906.46 286.97l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M886.15 301.41l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M884.99 302.05l-.43-.02v25.16l.43.01v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M889.45 299.48l-1.12.73v25.16l1.12-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M888.28 300.11l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#612121"
                        d="M892.74 297.54l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c9b4b4"
                        d="M891.58 298.17l-.42-.02v25.16l.42.02v-25.16z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#612121"
                          d="M896.04 295.6l-1.11.74v25.16l1.11-.74V295.6z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#c9b4b4"
                          d="M894.88 296.24l-.43-.02v25.16l.43.01v-25.15z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#612121"
                          d="M899.34 293.66l-1.11.74v25.16l1.11-.74v-25.16z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#c9b4b4"
                          d="M898.17 294.3l-.42-.02v25.16l.42.01V294.3z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "screen" }}
                          fill="#612121"
                          d="M902.63 291.73l-1.11.73v25.16l1.11-.74v-25.15z"
                        />
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          fill="#c9b4b4"
                          d="M901.47 292.36l-.42-.02v25.16l.42.02v-25.16z"
                        />
                      </g>
                    </g>
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#dbcccc"
                      d="M883.45 302.63v2.21l20.51-12v22.86l.74-.43V290.2l-21.25 12.43z"
                    />
                  </g>
                </g>
                <g>
                  <path
                    d="M941.41 498.2l5.81 3.31 143.1-89.51a18.59 18.59 0 00-27.91-16l-93.33 54.18a55.56 55.56 0 00-27.67 48.02z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-5)"
                  />
                  <path
                    d="M947.07 501.6L1096 415.43a18.58 18.58 0 00-27.9-16.07l-93.34 54.18a55.56 55.56 0 00-27.69 48.06z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-6)"
                  />
                </g>
                <g>
                  <path
                    d="M383.27 151.82h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4)"
                  />
                  <path
                    d="M383.27 151.83c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-7)"
                  />
                  <path
                    d="M390.92 172.34h-6v8.93c0 1 1.36 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-2)"
                  />
                  <path
                    d="M390.92 172.35c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-8)"
                  />
                  <path
                    d="M387.61 154.32h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-3)"
                  />
                  <path
                    d="M387.61 154.33c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-9)"
                  />
                  <path
                    d="M395.26 174.83h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-4)"
                  />
                  <path
                    d="M395.26 174.85c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-10)"
                  />
                  <path
                    d="M392 156.82h-6v24.56c0 1 1.36 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-5)"
                  />
                  <path
                    d="M392 156.83c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-11)"
                  />
                  <path
                    d="M399.6 177.33h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-6)"
                  />
                  <path
                    d="M399.6 177.34c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-12)"
                  />
                  <path
                    d="M396.29 159.31h-6v24.57c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-7)"
                  />
                  <path
                    d="M396.29 159.33c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-13)"
                  />
                  <path
                    d="M403.94 179.83h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-8)"
                  />
                  <path
                    d="M403.94 179.84c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-14)"
                  />
                  <path
                    d="M400.63 161.81h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-9)"
                  />
                  <path
                    d="M400.63 161.82c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-15)"
                  />
                  <path
                    d="M408.28 182.33h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-10)"
                  />
                  <path
                    d="M408.28 182.34c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-16)"
                  />
                  <path
                    d="M405 164.31h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-11)"
                  />
                  <path
                    d="M405 164.32c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-17)"
                  />
                  <path
                    d="M412.63 184.83h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-12)"
                  />
                  <path
                    d="M412.63 184.84c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-18)"
                  />
                  <path
                    d="M409.32 166.81h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-13)"
                  />
                  <path
                    d="M409.32 166.82c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-19)"
                  />
                  <path
                    d="M417 187.33h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-14)"
                  />
                  <path
                    d="M417 187.34c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-20)"
                  />
                  <path
                    d="M413.66 169.31h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-15)"
                  />
                  <path
                    d="M413.66 169.32c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-21)"
                  />
                  <path
                    d="M421.31 189.82h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-16)"
                  />
                  <path
                    d="M421.31 189.84c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-22)"
                  />
                  <path
                    d="M418 171.81h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-17)"
                  />
                  <path
                    d="M418 171.82c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-23)"
                  />
                  <path
                    d="M425.65 192.32h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-18)"
                  />
                  <path
                    d="M425.65 192.33c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-24)"
                  />
                  <path
                    d="M422.34 174.3h-6v24.57c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-19)"
                  />
                  <path
                    d="M422.34 174.32c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-25)"
                  />
                  <path
                    d="M430 194.82h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-20)"
                  />
                  <path
                    d="M430 194.83c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-26)"
                  />
                  <path
                    d="M426.68 176.8h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-21)"
                  />
                  <path
                    d="M426.68 176.81c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-27)"
                  />
                  <path
                    d="M434.33 197.32h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-22)"
                  />
                  <path
                    d="M434.33 197.33c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-28)"
                  />
                  <path
                    d="M431 179.3h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-23)"
                  />
                  <path
                    d="M431 179.31c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-29)"
                  />
                  <path
                    d="M438.68 199.82h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-24)"
                  />
                  <path
                    d="M438.68 199.83c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-30)"
                  />
                  <path
                    d="M435.36 181.8h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-25)"
                  />
                  <path
                    d="M435.36 181.81c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-31)"
                  />
                  <path
                    d="M443 202.32h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-26)"
                  />
                  <path
                    d="M443 202.33c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-32)"
                  />
                  <path
                    d="M439.71 184.3h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-27)"
                  />
                  <path
                    d="M439.71 184.31c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-33)"
                  />
                  <path
                    d="M447.36 204.81h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-28)"
                  />
                  <path
                    d="M447.36 204.83c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-34)"
                  />
                  <path
                    d="M444.05 186.8h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-29)"
                  />
                  <path
                    d="M444.05 186.81c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-35)"
                  />
                  <path
                    d="M451.7 207.31h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-30)"
                  />
                  <path
                    d="M451.7 207.32c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-36)"
                  />
                  <path
                    d="M448.39 189.29h-6v24.57c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-31)"
                  />
                  <path
                    d="M448.39 189.31c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-37)"
                  />
                  <path
                    d="M456 209.81h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-32)"
                  />
                  <path
                    d="M456 209.82c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-38)"
                  />
                  <path
                    d="M452.73 191.79h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-33)"
                  />
                  <path
                    d="M452.73 191.8c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-39)"
                  />
                  <path
                    d="M460.38 212.31h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-34)"
                  />
                  <path
                    d="M460.38 212.32c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-40)"
                  />
                  <path
                    d="M457.07 194.29h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-35)"
                  />
                  <path
                    d="M457.07 194.3c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-41)"
                  />
                  <path
                    d="M464.73 214.81h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-36)"
                  />
                  <path
                    d="M464.73 214.82c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-42)"
                  />
                  <path
                    d="M461.41 196.79h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-37)"
                  />
                  <path
                    d="M461.41 196.8c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-43)"
                  />
                  <path
                    d="M469.07 217.31h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-38)"
                  />
                  <path
                    d="M469.07 217.32c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-44)"
                  />
                  <path
                    d="M465.76 199.29h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-39)"
                  />
                  <path
                    d="M465.76 199.3c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-45)"
                  />
                  <path
                    d="M473.41 219.8h-6v8.93c0 1 1.36 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-40)"
                  />
                  <path
                    d="M473.41 219.82c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-46)"
                  />
                  <path
                    d="M470.1 201.79h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-41)"
                  />
                  <path
                    d="M470.1 201.8c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-47)"
                  />
                  <path
                    d="M477.75 222.3h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-42)"
                  />
                  <path
                    d="M477.75 222.31c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-48)"
                  />
                  <path
                    d="M474.44 204.28h-6v24.57c0 1 1.36 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-43)"
                  />
                  <path
                    d="M474.44 204.3c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-49)"
                  />
                  <path
                    d="M482.09 224.8h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-44)"
                  />
                  <path
                    d="M482.09 224.81c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-50)"
                  />
                  <path
                    d="M478.78 206.78h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-45)"
                  />
                  <path
                    d="M478.78 206.79c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-51)"
                  />
                  <path
                    d="M486.43 227.3h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-46)"
                  />
                  <path
                    d="M486.43 227.31c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-52)"
                  />
                  <path
                    d="M483.12 209.28h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-47)"
                  />
                  <path
                    d="M483.12 209.29c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-53)"
                  />
                  <path
                    d="M490.77 229.8h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-48)"
                  />
                  <path
                    d="M490.77 229.81c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-54)"
                  />
                  <path
                    d="M487.46 211.78h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-49)"
                  />
                  <path
                    d="M487.46 211.79c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-55)"
                  />
                  <path
                    d="M495.12 232.3h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-50)"
                  />
                  <path
                    d="M495.12 232.31c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-56)"
                  />
                  <path
                    d="M491.81 214.28h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-51)"
                  />
                  <path
                    d="M491.81 214.29c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-57)"
                  />
                  <path
                    d="M499.46 234.79h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-52)"
                  />
                  <path
                    d="M499.46 234.81c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-58)"
                  />
                  <path
                    d="M496.15 216.78h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-53)"
                  />
                  <path
                    d="M496.15 216.79c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-59)"
                  />
                  <path
                    d="M503.8 237.29h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-54)"
                  />
                  <path
                    d="M503.8 237.3c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-60)"
                  />
                  <path
                    d="M500.49 219.27h-6v24.57c0 1 1.36 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-55)"
                  />
                  <path
                    d="M500.49 219.29c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.36-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-61)"
                  />
                  <path
                    d="M508.14 239.79h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-56)"
                  />
                  <path
                    d="M508.14 239.8c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-62)"
                  />
                  <path
                    d="M504.83 221.77h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-57)"
                  />
                  <path
                    d="M504.83 221.78c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-63)"
                  />
                  <path
                    d="M512.48 242.29h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-58)"
                  />
                  <path
                    d="M512.48 242.3c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-64)"
                  />
                  <path
                    d="M509.17 224.27h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-59)"
                  />
                  <path
                    d="M509.17 224.28c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-65)"
                  />
                  <path
                    d="M516.82 244.79h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-60)"
                  />
                  <path
                    d="M516.82 244.8c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-66)"
                  />
                  <path
                    d="M513.51 226.77h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-61)"
                  />
                  <path
                    d="M513.51 226.78c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-67)"
                  />
                  <path
                    d="M521.17 247.29h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-62)"
                  />
                  <path
                    d="M521.17 247.3c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-68)"
                  />
                  <path
                    d="M517.85 229.27h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-63)"
                  />
                  <path
                    d="M517.85 229.28c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-69)"
                  />
                  <path
                    d="M525.51 249.78h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-64)"
                  />
                  <path
                    d="M525.51 249.8c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-70)"
                  />
                  <g>
                    <path
                      d="M522.2 231.77h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-65)"
                    />
                    <path
                      d="M522.2 231.78c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-71)"
                    />
                    <g>
                      <path
                        d="M529.85 252.28h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-66)"
                      />
                      <path
                        d="M529.85 252.29c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-72)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M526.54 234.26h-6v24.57c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-67)"
                    />
                    <path
                      d="M526.54 234.28c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-73)"
                    />
                    <g>
                      <path
                        d="M534.19 254.78h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-68)"
                      />
                      <path
                        d="M534.19 254.79c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-74)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M530.88 236.76h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-69)"
                    />
                    <path
                      d="M530.88 236.77c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-75)"
                    />
                    <g>
                      <path
                        d="M538.53 257.28h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-70)"
                      />
                      <path
                        d="M538.53 257.29c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-76)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M535.22 239.26h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-71)"
                    />
                    <path
                      d="M535.22 239.27c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-77)"
                    />
                    <g>
                      <path
                        d="M542.87 259.78h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-72)"
                      />
                      <path
                        d="M542.87 259.79c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-78)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M539.56 241.76h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-73)"
                    />
                    <path
                      d="M539.56 241.77c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-79)"
                    />
                    <g>
                      <path
                        d="M547.22 262.28h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-74)"
                      />
                      <path
                        d="M547.22 262.29c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-80)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M543.9 244.26h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-75)"
                    />
                    <path
                      d="M543.9 244.27c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-81)"
                    />
                    <g>
                      <path
                        d="M551.56 264.77h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-76)"
                      />
                      <path
                        d="M551.56 264.79c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-82)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M548.25 246.76h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-77)"
                    />
                    <path
                      d="M548.25 246.77c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-83)"
                    />
                    <g>
                      <path
                        d="M555.9 267.27h-6v8.93c0 1 1.36 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-78)"
                      />
                      <path
                        d="M555.9 267.28c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-84)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M552.59 249.25h-6v24.57c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-79)"
                    />
                    <path
                      d="M552.59 249.27c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-85)"
                    />
                    <g>
                      <path
                        d="M560.24 269.77h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-80)"
                      />
                      <path
                        d="M560.24 269.78c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-86)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M556.93 251.75h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-81)"
                    />
                    <path
                      d="M556.93 251.76c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-87)"
                    />
                    <g>
                      <path
                        d="M564.58 272.27h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-82)"
                      />
                      <path
                        d="M564.58 272.28c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-88)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M561.27 254.25h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-83)"
                    />
                    <path
                      d="M561.27 254.26c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-89)"
                    />
                    <g>
                      <path
                        d="M568.92 274.77h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-84)"
                      />
                      <path
                        d="M568.92 274.78c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-90)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M565.61 256.75h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-85)"
                    />
                    <path
                      d="M565.61 256.76c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-91)"
                    />
                    <g>
                      <path
                        d="M573.27 277.27h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-86)"
                      />
                      <path
                        d="M573.27 277.28c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-92)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M570 259.25h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-87)"
                    />
                    <path
                      d="M570 259.26c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-93)"
                    />
                    <g>
                      <path
                        d="M577.61 279.76h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-88)"
                      />
                      <path
                        d="M577.61 279.78c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-94)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M574.3 261.75h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-89)"
                    />
                    <path
                      d="M574.3 261.76c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-95)"
                    />
                    <g>
                      <path
                        d="M582 282.26h-6v8.93c0 1 1.36 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-90)"
                      />
                      <path
                        d="M582 282.27c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-96)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M578.64 264.24h-6v24.57c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-91)"
                    />
                    <path
                      d="M578.64 264.26c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-97)"
                    />
                    <g>
                      <path
                        d="M586.29 284.76h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-92)"
                      />
                      <path
                        d="M586.29 284.77c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-98)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M583 266.74h-6v24.56c0 1 1.36 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-93)"
                    />
                    <path
                      d="M583 266.75c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-99)"
                    />
                    <g>
                      <path
                        d="M590.63 287.26h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-94)"
                      />
                      <path
                        d="M590.63 287.27c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-100)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M587.32 269.24h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-95)"
                    />
                    <path
                      d="M587.32 269.25c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-101)"
                    />
                    <g>
                      <path
                        d="M595 289.76h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-96)"
                      />
                      <path
                        d="M595 289.77c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-102)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M591.66 271.74h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-97)"
                    />
                    <path
                      d="M591.66 271.75c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-103)"
                    />
                    <g>
                      <path
                        d="M599.31 292.26h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-98)"
                      />
                      <path
                        d="M599.31 292.27c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-104)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M596 274.24h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-99)"
                    />
                    <path
                      d="M596 274.25c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-105)"
                    />
                    <g>
                      <path
                        d="M603.66 294.75h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-100)"
                      />
                      <path
                        d="M603.66 294.77c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-106)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M600.35 276.74h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-101)"
                    />
                    <path
                      d="M600.35 276.75c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-107)"
                    />
                    <g>
                      <path
                        d="M608 297.25h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-102)"
                      />
                      <path
                        d="M608 297.26c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-108)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M604.69 279.23h-6v24.57c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-103)"
                    />
                    <path
                      d="M604.69 279.25c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-109)"
                    />
                    <g>
                      <path
                        d="M612.34 299.75h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-104)"
                      />
                      <path
                        d="M612.34 299.76c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-110)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M609 281.73h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-105)"
                    />
                    <path
                      d="M609 281.74c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-111)"
                    />
                    <g>
                      <path
                        d="M616.68 302.25h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-106)"
                      />
                      <path
                        d="M616.68 302.26c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-112)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M613.37 284.23h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-107)"
                    />
                    <path
                      d="M613.37 284.24c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-113)"
                    />
                    <g>
                      <path
                        d="M621 304.75h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-108)"
                      />
                      <path
                        d="M621 304.76c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-114)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M617.71 286.73h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-109)"
                    />
                    <path
                      d="M617.71 286.74c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-115)"
                    />
                    <g>
                      <path
                        d="M625.36 307.25h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-110)"
                      />
                      <path
                        d="M625.36 307.26c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-116)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M622.05 289.23h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-111)"
                    />
                    <path
                      d="M622.05 289.24c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-117)"
                    />
                    <g>
                      <path
                        d="M629.71 309.74h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-112)"
                      />
                      <path
                        d="M629.71 309.76c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-118)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M626.39 291.73h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-113)"
                    />
                    <path
                      d="M626.39 291.74c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-119)"
                    />
                    <g>
                      <path
                        d="M634.05 312.24h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-114)"
                      />
                      <path
                        d="M634.05 312.25c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-120)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M630.74 294.22h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-115)"
                    />
                    <path
                      d="M630.74 294.24c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-121)"
                    />
                    <g>
                      <path
                        d="M638.39 314.74h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-116)"
                      />
                      <path
                        d="M638.39 314.75c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-122)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M635.08 296.72h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-117)"
                    />
                    <path
                      d="M635.08 296.73c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-123)"
                    />
                    <g>
                      <path
                        d="M642.73 317.24h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-118)"
                      />
                      <path
                        d="M642.73 317.25c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-124)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M639.42 299.22h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-119)"
                    />
                    <path
                      d="M639.42 299.23c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-125)"
                    />
                    <g>
                      <path
                        d="M647.07 319.74h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-120)"
                      />
                      <path
                        d="M647.07 319.75c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-126)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M643.76 301.72h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-121)"
                    />
                    <path
                      d="M643.76 301.73c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-127)"
                    />
                    <g>
                      <path
                        d="M651.41 322.24h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-122)"
                      />
                      <path
                        d="M651.41 322.25c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-128)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M648.1 304.22h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-123)"
                    />
                    <path
                      d="M648.1 304.23c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-129)"
                    />
                    <g>
                      <path
                        d="M655.76 324.73h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-124)"
                      />
                      <path
                        d="M655.76 324.75c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-130)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M652.44 306.72h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-125)"
                    />
                    <path
                      d="M652.44 306.73c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-131)"
                    />
                    <g>
                      <path
                        d="M660.1 327.23h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-126)"
                      />
                      <path
                        d="M660.1 327.24c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-132)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M656.79 309.21h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-127)"
                    />
                    <path
                      d="M656.79 309.23c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-133)"
                    />
                    <g>
                      <path
                        d="M664.44 329.73h-6v8.93c0 1 1.36 1.76 3 1.76s3-.78 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-128)"
                      />
                      <path
                        d="M664.44 329.74c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-134)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M661.13 311.71h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-129)"
                    />
                    <path
                      d="M661.13 311.72c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-135)"
                    />
                    <g>
                      <path
                        d="M668.78 332.23h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-130)"
                      />
                      <path
                        d="M668.78 332.24c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-136)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M665.47 314.21h-6v24.56c0 1 1.36 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-131)"
                    />
                    <path
                      d="M665.47 314.22c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-137)"
                    />
                    <g>
                      <path
                        d="M673.12 334.73h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-132)"
                      />
                      <path
                        d="M673.12 334.74c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-138)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M669.81 316.71h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-133)"
                    />
                    <path
                      d="M669.81 316.72c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-139)"
                    />
                    <g>
                      <path
                        d="M677.46 337.23h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-134)"
                      />
                      <path
                        d="M677.46 337.24c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-140)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M674.15 319.21h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-135)"
                    />
                    <path
                      d="M674.15 319.22c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-141)"
                    />
                    <g>
                      <path
                        d="M681.8 339.72h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-136)"
                      />
                      <path
                        d="M681.8 339.74c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-142)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M678.49 321.71h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-137)"
                    />
                    <path
                      d="M678.49 321.72c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-143)"
                    />
                    <g>
                      <path
                        d="M686.15 342.22h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-138)"
                      />
                      <path
                        d="M686.15 342.23c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-144)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M682.84 324.2h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-139)"
                    />
                    <path
                      d="M682.84 324.22c0 1-1.36 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-145)"
                    />
                    <g>
                      <path
                        d="M690.49 344.72h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-140)"
                      />
                      <path
                        d="M690.49 344.73c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-146)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M687.18 326.7h-6v24.56c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-141)"
                    />
                    <path
                      d="M687.18 326.71c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-147)"
                    />
                    <g>
                      <path
                        d="M694.83 347.22h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-142)"
                      />
                      <path
                        d="M694.83 347.23c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-148)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M691.52 329.2h-6v24.56c0 1 1.36 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-143)"
                    />
                    <path
                      d="M691.52 329.21c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-149)"
                    />
                    <g>
                      <path
                        d="M699.17 349.72h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-144)"
                      />
                      <path
                        d="M699.17 349.73c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-150)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M695.86 331.7h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-145)"
                    />
                    <path
                      d="M695.86 331.71c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-151)"
                    />
                    <g>
                      <path
                        d="M703.51 352.22h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-146)"
                      />
                      <path
                        d="M703.51 352.23c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-152)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M700.2 334.2h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-147)"
                    />
                    <path
                      d="M700.2 334.21c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-153)"
                    />
                    <g>
                      <path
                        d="M707.85 354.71h-6v8.93c0 1 1.35 1.77 3 1.77s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-148)"
                      />
                      <path
                        d="M707.85 354.73c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-154)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M704.54 336.7h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-149)"
                    />
                    <path
                      d="M704.54 336.71c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-155)"
                    />
                    <g>
                      <path
                        d="M712.2 357.21h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-150)"
                      />
                      <path
                        d="M712.2 357.22c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-156)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M708.88 339.19h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-151)"
                    />
                    <path
                      d="M708.88 339.21c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-157)"
                    />
                    <g>
                      <path
                        d="M716.54 359.71h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-152)"
                      />
                      <path
                        d="M716.54 359.72c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-158)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M713.23 341.69h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-153)"
                    />
                    <path
                      d="M713.23 341.7c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-159)"
                    />
                    <g>
                      <path
                        d="M720.88 362.21h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-154)"
                      />
                      <path
                        d="M720.88 362.22c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-160)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M717.57 344.19h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-155)"
                    />
                    <path
                      d="M717.57 344.2c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-161)"
                    />
                    <g>
                      <path
                        d="M725.22 364.71h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-156)"
                      />
                      <path
                        d="M725.22 364.72c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-162)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M721.91 346.69h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-157)"
                    />
                    <path
                      d="M721.91 346.7c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-163)"
                    />
                    <g>
                      <path
                        d="M729.56 367.21h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-158)"
                      />
                      <path
                        d="M729.56 367.22c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-164)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M726.25 349.19h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-159)"
                    />
                    <path
                      d="M726.25 349.2c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-165)"
                    />
                    <g>
                      <path
                        d="M733.9 369.7h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-160)"
                      />
                      <path
                        d="M733.9 369.72c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-166)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M730.59 351.69h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-161)"
                    />
                    <path
                      d="M730.59 351.7c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-167)"
                    />
                    <g>
                      <path
                        d="M738.25 372.2h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-162)"
                      />
                      <path
                        d="M738.25 372.21c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-168)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M734.93 354.18h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-163)"
                    />
                    <path
                      d="M734.93 354.2c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-169)"
                    />
                    <g>
                      <path
                        d="M742.59 374.7h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-164)"
                      />
                      <path
                        d="M742.59 374.71c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-170)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M739.28 356.68h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-165)"
                    />
                    <path
                      d="M739.28 356.69c0 1-1.36 1.76-3 1.76s-3-.78-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-171)"
                    />
                    <g>
                      <path
                        d="M746.93 377.2h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-166)"
                      />
                      <path
                        d="M746.93 377.21c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-172)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M743.62 359.18h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-167)"
                    />
                    <path
                      d="M743.62 359.19c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-173)"
                    />
                    <g>
                      <path
                        d="M751.27 379.7h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-168)"
                      />
                      <path
                        d="M751.27 379.71c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-174)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M748 361.68h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-169)"
                    />
                    <path
                      d="M748 361.69c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-175)"
                    />
                    <g>
                      <path
                        d="M755.61 382.2h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-170)"
                      />
                      <path
                        d="M755.61 382.21c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-176)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M752.3 364.18h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-171)"
                    />
                    <path
                      d="M752.3 364.19c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-177)"
                    />
                    <g>
                      <path
                        d="M760 384.69h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-172)"
                      />
                      <path
                        d="M760 384.71c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-178)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M756.64 366.68h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-173)"
                    />
                    <path
                      d="M756.64 366.69c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-179)"
                    />
                    <g>
                      <path
                        d="M764.29 387.19h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-174)"
                      />
                      <path
                        d="M764.29 387.2c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-180)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M761 369.17h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-175)"
                    />
                    <path
                      d="M761 369.19c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-181)"
                    />
                    <g>
                      <path
                        d="M768.64 389.69h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-176)"
                      />
                      <path
                        d="M768.64 389.7c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-182)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M765.33 371.67h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-177)"
                    />
                    <path
                      d="M765.33 371.68c0 1-1.36 1.76-3 1.76s-3-.78-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-183)"
                    />
                    <g>
                      <path
                        d="M773 392.19h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-178)"
                      />
                      <path
                        d="M773 392.2c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-184)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M769.67 374.17h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-179)"
                    />
                    <path
                      d="M769.67 374.18c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-185)"
                    />
                    <g>
                      <path
                        d="M777.32 394.69h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-180)"
                      />
                      <path
                        d="M777.32 394.7c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-186)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M774 376.67h-6v24.56c0 1 1.36 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-181)"
                    />
                    <path
                      d="M774 376.68c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-187)"
                    />
                    <g>
                      <path
                        d="M781.66 397.19h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-182)"
                      />
                      <path
                        d="M781.66 397.2c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-188)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M778.35 379.17h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-183)"
                    />
                    <path
                      d="M778.35 379.18c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-189)"
                    />
                    <g>
                      <path
                        d="M786 399.68h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-184)"
                      />
                      <path
                        d="M786 399.7c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-190)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M782.69 381.67h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-185)"
                    />
                    <path
                      d="M782.69 381.68c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-191)"
                    />
                    <g>
                      <path
                        d="M790.34 402.18h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-186)"
                      />
                      <path
                        d="M790.34 402.19c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-192)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M787 384.16h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-187)"
                    />
                    <path
                      d="M787 384.18c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-193)"
                    />
                    <g>
                      <path
                        d="M794.69 404.68h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-188)"
                      />
                      <path
                        d="M794.69 404.69c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-194)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M791.37 386.66h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-189)"
                    />
                    <path
                      d="M791.37 386.67c0 1-1.35 1.76-3 1.76s-3-.78-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-195)"
                    />
                    <g>
                      <path
                        d="M799 407.18h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-190)"
                      />
                      <path
                        d="M799 407.19c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-196)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M795.72 389.16h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-191)"
                    />
                    <path
                      d="M795.72 389.17c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-197)"
                    />
                    <g>
                      <path
                        d="M803.37 409.68h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-192)"
                      />
                      <path
                        d="M803.37 409.69c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-198)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M800.06 391.66h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-193)"
                    />
                    <path
                      d="M800.06 391.67c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-199)"
                    />
                    <g>
                      <path
                        d="M807.71 412.18h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-194)"
                      />
                      <path
                        d="M807.71 412.19c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-200)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M804.4 394.16h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-195)"
                    />
                    <path
                      d="M804.4 394.17c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-201)"
                    />
                    <g>
                      <path
                        d="M812.05 414.67h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-196)"
                      />
                      <path
                        d="M812.05 414.69c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-202)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M808.74 396.66h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-197)"
                    />
                    <path
                      d="M808.74 396.67c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-203)"
                    />
                    <g>
                      <path
                        d="M816.39 417.17h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-198)"
                      />
                      <path
                        d="M816.39 417.18c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-204)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M813.08 399.15h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-199)"
                    />
                    <path
                      d="M813.08 399.17c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-205)"
                    />
                    <g>
                      <path
                        d="M820.74 419.67h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-200)"
                      />
                      <path
                        d="M820.74 419.68c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-206)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M817.42 401.65h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-201)"
                    />
                    <path
                      d="M817.42 401.66c0 1-1.35 1.76-3 1.76s-3-.78-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-207)"
                    />
                    <g>
                      <path
                        d="M825.08 422.17h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-202)"
                      />
                      <path
                        d="M825.08 422.18c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-208)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M821.77 404.15h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-203)"
                    />
                    <path
                      d="M821.77 404.16c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-209)"
                    />
                    <g>
                      <path
                        d="M829.42 424.67h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-204)"
                      />
                      <path
                        d="M829.42 424.68c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-210)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M826.11 406.65h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-205)"
                    />
                    <path
                      d="M826.11 406.66c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-211)"
                    />
                    <g>
                      <path
                        d="M833.76 427.17h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-206)"
                      />
                      <path
                        d="M833.76 427.18c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-212)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M830.45 409.15h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-207)"
                    />
                    <path
                      d="M830.45 409.16c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-213)"
                    />
                    <g>
                      <path
                        d="M838.1 429.66h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-208)"
                      />
                      <path
                        d="M838.1 429.68c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-214)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M834.79 411.65h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-209)"
                    />
                    <path
                      d="M834.79 411.66c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-215)"
                    />
                    <g>
                      <path
                        d="M842.44 432.16h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-210)"
                      />
                      <path
                        d="M842.44 432.17c0 1-1.35 1.76-3 1.76s-3-.78-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-216)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M839.13 414.14h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-211)"
                    />
                    <path
                      d="M839.13 414.16c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-217)"
                    />
                    <g>
                      <path
                        d="M846.79 434.66h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-212)"
                      />
                      <path
                        d="M846.79 434.67c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-218)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M843.47 416.64h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-213)"
                    />
                    <path
                      d="M843.47 416.65c0 1-1.35 1.76-3 1.76s-3-.78-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-219)"
                    />
                    <g>
                      <path
                        d="M851.13 437.16h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-214)"
                      />
                      <path
                        d="M851.13 437.17c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-220)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M847.82 419.14h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-215)"
                    />
                    <path
                      d="M847.82 419.15c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-221)"
                    />
                    <g>
                      <path
                        d="M855.47 439.66h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-216)"
                      />
                      <path
                        d="M855.47 439.67c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-222)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M852.16 421.64h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-217)"
                    />
                    <path
                      d="M852.16 421.65c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-223)"
                    />
                    <g>
                      <path
                        d="M859.81 442.16h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-218)"
                      />
                      <path
                        d="M859.81 442.17c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-224)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M856.5 424.14h-6v24.56c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-219)"
                    />
                    <path
                      d="M856.5 424.15c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.36-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-225)"
                    />
                    <g>
                      <path
                        d="M864.15 444.65h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-220)"
                      />
                      <path
                        d="M864.15 444.67c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-226)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M860.84 426.64h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-221)"
                    />
                    <path
                      d="M860.84 426.65c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-227)"
                    />
                    <g>
                      <path
                        d="M868.49 447.15h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-222)"
                      />
                      <path
                        d="M868.49 447.16c0 1-1.35 1.76-3 1.76s-3-.78-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-228)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M865.18 429.13h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-223)"
                    />
                    <path
                      d="M865.18 429.15c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-229)"
                    />
                    <g>
                      <path
                        d="M872.83 449.65h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-224)"
                      />
                      <path
                        d="M872.83 449.66c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-230)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M869.52 431.63h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-225)"
                    />
                    <path
                      d="M869.52 431.64c0 1-1.35 1.76-3 1.76s-3-.78-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-231)"
                    />
                    <g>
                      <path
                        d="M877.18 452.15h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-226)"
                      />
                      <path
                        d="M877.18 452.16c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-232)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M873.87 434.13h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-227)"
                    />
                    <path
                      d="M873.87 434.14c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-233)"
                    />
                    <g>
                      <path
                        d="M881.52 454.65h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-228)"
                      />
                      <path
                        d="M881.52 454.66c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-234)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M878.21 436.63h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-229)"
                    />
                    <path
                      d="M878.21 436.64c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-235)"
                    />
                    <g>
                      <path
                        d="M885.86 457.15h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-230)"
                      />
                      <path
                        d="M885.86 457.16c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-236)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M882.55 439.13h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-231)"
                    />
                    <path
                      d="M882.55 439.14c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-237)"
                    />
                    <g>
                      <path
                        d="M890.2 459.64h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-232)"
                      />
                      <ellipse
                        cx={887.18}
                        cy={459.66}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-238)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M886.89 441.63h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-233)"
                    />
                    <path
                      d="M886.89 441.64c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-239)"
                    />
                    <g>
                      <path
                        d="M894.54 462.14h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-234)"
                      />
                      <ellipse
                        cx={891.53}
                        cy={462.15}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-240)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M891.23 444.12h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-235)"
                    />
                    <path
                      d="M891.23 444.14c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-241)"
                    />
                    <g>
                      <path
                        d="M898.88 464.64h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-236)"
                      />
                      <ellipse
                        cx={895.87}
                        cy={464.65}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-242)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M895.57 446.62h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-237)"
                    />
                    <path
                      d="M895.57 446.64c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .78 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-243)"
                    />
                    <g>
                      <path
                        d="M903.23 467.14h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-238)"
                      />
                      <ellipse
                        cx={900.21}
                        cy={467.15}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-244)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M899.91 449.12h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-239)"
                    />
                    <path
                      d="M899.91 449.13c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-245)"
                    />
                    <g>
                      <path
                        d="M907.57 469.64h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-240)"
                      />
                      <ellipse
                        cx={904.55}
                        cy={469.65}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-246)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M904.26 451.62h-6v24.56c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-241)"
                    />
                    <path
                      d="M904.26 451.63c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-247)"
                    />
                    <g>
                      <path
                        d="M911.91 472.14h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-242)"
                      />
                      <ellipse
                        cx={908.89}
                        cy={472.15}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-248)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M908.6 454.12h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-243)"
                    />
                    <path
                      d="M908.6 454.13c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-249)"
                    />
                    <g>
                      <path
                        d="M916.25 474.63h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-244)"
                      />
                      <ellipse
                        cx={913.23}
                        cy={474.65}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-250)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M912.94 456.62h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-245)"
                    />
                    <path
                      d="M912.94 456.63c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-251)"
                    />
                    <g>
                      <path
                        d="M920.59 477.13h-6v8.93c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-246)"
                      />
                      <ellipse
                        cx={917.58}
                        cy={477.14}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-252)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M917.28 459.11h-6v24.57c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-247)"
                    />
                    <path
                      d="M917.28 459.13c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-253)"
                    />
                    <g>
                      <path
                        d="M924.93 479.63h-6v8.93c0 1 1.35 1.76 3 1.76s3-.78 3-1.75z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-248)"
                      />
                      <ellipse
                        cx={921.92}
                        cy={479.64}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-254)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M921.62 461.61h-6v24.56c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-249)"
                    />
                    <path
                      d="M921.62 461.63c0 1-1.35 1.75-3 1.75s-3-.78-3-1.75 1.35-1.76 3-1.76 3 .78 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-255)"
                    />
                    <g>
                      <path
                        d="M929.28 482.13h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-250)"
                      />
                      <ellipse
                        cx={926.26}
                        cy={482.14}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-256)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M926 464.11h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-251)"
                    />
                    <path
                      d="M926 464.12c0 1-1.35 1.76-3 1.76s-3-.79-3-1.76 1.35-1.75 3-1.75 3 .78 3 1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-257)"
                    />
                    <g>
                      <path
                        d="M933.62 484.63h-6v8.93c0 1 1.35 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-252)"
                      />
                      <ellipse
                        cx={930.6}
                        cy={484.64}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-258)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M930.31 466.61h-6v24.56c0 1 1.35 1.75 3 1.75s3-.78 3-1.75z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-253)"
                    />
                    <path
                      d="M930.31 466.62c0 1-1.36 1.76-3 1.76s-3-.79-3-1.76 1.35-1.76 3-1.76 3 .79 3 1.76z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-259)"
                    />
                    <g>
                      <path
                        d="M938 487.13h-6v8.93c0 1 1.36 1.76 3 1.76s3-.79 3-1.76z"
                        fill="url(#prefix__New_Gradient_Swatch_copy_4-254)"
                      />
                      <ellipse
                        cx={934.94}
                        cy={487.14}
                        rx={3.02}
                        ry={1.76}
                        fill="url(#prefix__New_Gradient_Swatch_copy_17-260)"
                      />
                    </g>
                  </g>
                </g>
                <g>
                  <path
                    d="M1057.68 434.4h-7.45v46.32c0 1.2 1.67 2.17 3.72 2.17s3.73-1 3.73-2.17z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-255)"
                  />
                  <path
                    d="M1057.68 434.42c0 1.19-1.67 2.17-3.73 2.17s-3.72-1-3.72-2.17 1.67-2.17 3.72-2.17 3.73.97 3.73 2.17z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-261)"
                  />
                  <path
                    d="M1062.55 430.35h-17.19v2.26c0 2.76 3.85 5 8.59 5s8.6-2.24 8.6-5v-.07z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-256)"
                  />
                  <path
                    d="M1062.55 430.39c0 2.76-3.85 5-8.6 5s-8.59-2.24-8.59-5 3.85-5 8.59-5 8.6 2.23 8.6 5z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-262)"
                  />
                  <path
                    d="M1048.28 440z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-257)"
                  />
                  <path
                    d="M1059.63 440z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-258)"
                  />
                  <path
                    d="M1054 440.57c-3.13 0-5.67-1.48-5.67-3.31V439.18c0 1.82 2.54 3.3 5.67 3.3s5.68-1.48 5.68-3.3V437.26c-.05 1.83-2.59 3.31-5.68 3.31z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-259)"
                  />
                  <path
                    d="M1054 444.6c-3.13 0-5.67-1.48-5.67-3.31v1.87c0 1.82 2.54 3.3 5.67 3.3s5.68-1.48 5.68-3.3v-1.87c-.05 1.83-2.59 3.31-5.68 3.31z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-260)"
                  />
                  <path
                    d="M1054 448.63c-3.13 0-5.67-1.48-5.67-3.3V447.24c0 1.83 2.54 3.31 5.67 3.31s5.68-1.48 5.68-3.31v-1.86c-.05 1.77-2.59 3.25-5.68 3.25z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-261)"
                  />
                  <path
                    d="M1054 452.66c-3.13 0-5.67-1.48-5.67-3.3v1.87c0 1.83 2.54 3.31 5.67 3.31s5.68-1.48 5.68-3.31v-1.87c-.05 1.82-2.59 3.3-5.68 3.3z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-262)"
                  />
                  <path
                    d="M1054 456.69c-3.13 0-5.67-1.48-5.67-3.3v1.87c0 1.83 2.54 3.31 5.67 3.31s5.68-1.48 5.68-3.31v-1.87c-.05 1.82-2.59 3.3-5.68 3.3z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-263)"
                  />
                  <path
                    d="M1054 460.73c-3.13 0-5.67-1.48-5.67-3.31v1.87c0 1.83 2.54 3.31 5.67 3.31s5.68-1.48 5.68-3.31v-1.87c-.05 1.83-2.59 3.31-5.68 3.31z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_4-264)"
                  />
                  <g>
                    <path
                      d="M1120.44 497.53h-6.7v19.53c0 1.08 1.5 1.95 3.35 1.95s3.35-.87 3.35-1.95z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-265)"
                    />
                    <path
                      d="M1120.44 497.54c0 1.08-1.5 1.95-3.35 1.95s-3.35-.87-3.35-1.95 1.5-2 3.35-2 3.35.93 3.35 2z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-263)"
                    />
                  </g>
                  <g>
                    <path
                      d="M1038.59 483.12h-6.7v26.33c0 1.07 1.5 1.95 3.35 1.95s3.35-.88 3.35-1.95z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-266)"
                    />
                    <ellipse
                      cx={1035.24}
                      cy={483.13}
                      rx={3.35}
                      ry={1.95}
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-264)"
                    />
                  </g>
                  <g>
                    <path
                      d="M1110.09 442.51h-6.7v26.33c0 1.08 1.5 1.95 3.35 1.95s3.35-.87 3.35-1.95z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-267)"
                    />
                    <path
                      d="M1110.09 442.53c0 1.08-1.5 2-3.35 2s-3.35-.87-3.35-2 1.5-1.95 3.35-1.95 3.35.87 3.35 1.95z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-265)"
                    />
                  </g>
                  <g>
                    <path
                      d="M1057.68 411.07h-7.45v19.14c0 1.21 1.67 2.18 3.72 2.18s3.73-1 3.73-2.17z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-268)"
                    />
                    <path
                      d="M1057.68 411.09c0 1.2-1.67 2.17-3.73 2.17s-3.72-1-3.72-2.17 1.67-2.17 3.72-2.17 3.73.97 3.73 2.17z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-266)"
                    />
                  </g>
                  <g>
                    <path
                      d="M1093.65 490.86a3 3 0 01-1.21 2.75L1090 495c-.78.43-.28-.56-1.47-1.24a9.53 9.53 0 01-4.33-7.46c0-1.36-1.25-1.37-.48-1.82l2.54-1.41a3.08 3.08 0 013 .33 9.53 9.53 0 014.39 7.46z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-269)"
                    />
                    <path
                      d="M1091.14 492.29c0 2.74-1.93 3.84-4.32 2.46a9.53 9.53 0 01-4.33-7.46c0-2.74 1.94-3.85 4.33-2.47a9.53 9.53 0 014.32 7.47z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-267)"
                    />
                    <path
                      d="M1090 492.52a2.64 2.64 0 01-1 2.38l-6.77 4.11c-.7.39-.27-.47-1.29-1.06a8.24 8.24 0 01-3.74-6.45c0-1.18-1.08-1.18-.42-1.58l6.92-4.14a2.67 2.67 0 012.61.29 8.22 8.22 0 013.69 6.45z"
                      fill="url(#prefix__linear-gradient-3)"
                    />
                    <path
                      d="M1087 484.72a3.1 3.1 0 00-3-.36 3.39 3.39 0 012.58.5 9.55 9.55 0 014.33 7.47 3 3 0 01-1.37 2.83c1.06-.3 1.74-1.35 1.74-3a9.53 9.53 0 00-4.28-7.44z"
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#prefix__Radial_Gradient_1)"
                    />
                    <path
                      d="M1082.58 497.4a3 3 0 01-1.22 2.75l-2.39 1.39c-.78.43-.28-.56-1.47-1.24a9.53 9.53 0 01-4.33-7.46c0-1.36-1.25-1.37-.48-1.82l2.52-1.4a3.09 3.09 0 013 .32 9.53 9.53 0 014.37 7.46z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-270)"
                    />
                    <path
                      d="M1080.07 498.83c0 2.74-1.93 3.84-4.32 2.46a9.53 9.53 0 01-4.33-7.46c0-2.74 1.94-3.85 4.33-2.47a9.53 9.53 0 014.32 7.47z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-268)"
                    />
                    <path
                      d="M1076 491.26a3.1 3.1 0 00-3-.36 3.39 3.39 0 012.58.5 9.55 9.55 0 014.33 7.47 3 3 0 01-1.37 2.83c1.06-.3 1.74-1.35 1.74-3a9.53 9.53 0 00-4.28-7.44z"
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#prefix__Radial_Gradient_1-2)"
                    />
                  </g>
                  <g>
                    <path
                      d="M1138.14 497.8a1.76 1.76 0 01-.71 1.61l-1.41.82c-.45.25-.16-.33-.86-.73a5.59 5.59 0 01-2.53-4.37c0-.8-.74-.8-.29-1.07 0 0 1.48-.82 1.5-.82a1.82 1.82 0 011.77.19 5.59 5.59 0 012.53 4.37z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-271)"
                    />
                    <path
                      d="M1136.67 498.64c0 1.6-1.13 2.25-2.53 1.44a5.57 5.57 0 01-2.54-4.37c0-1.61 1.14-2.25 2.54-1.44a5.56 5.56 0 012.53 4.37z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-269)"
                    />
                    <path
                      d="M1136 498.78a1.55 1.55 0 01-.62 1.39l-4 2.41c-.39.23-.14-.28-.74-.62a4.83 4.83 0 01-2.19-3.78c0-.69-.64-.7-.25-.93l4.06-2.42a1.53 1.53 0 011.53.17 4.83 4.83 0 012.21 3.78z"
                      fill="url(#prefix__linear-gradient-4)"
                    />
                    <path
                      d="M1134.26 494.21a1.86 1.86 0 00-1.74-.22 2 2 0 011.52.3 5.59 5.59 0 012.53 4.37 1.71 1.71 0 01-.8 1.66c.62-.17 1-.79 1-1.74a5.56 5.56 0 00-2.51-4.37z"
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#prefix__Radial_Gradient_1-3)"
                    />
                    <path
                      d="M1131.65 501.64a1.79 1.79 0 01-.71 1.61l-1.4.81c-.46.26-.17-.33-.86-.73a5.58 5.58 0 01-2.54-4.37c0-.79-.73-.8-.28-1.07l1.49-.82a1.82 1.82 0 011.77.19 5.59 5.59 0 012.53 4.38z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-272)"
                    />
                    <path
                      d="M1130.19 502.47c0 1.61-1.14 2.25-2.54 1.45a5.59 5.59 0 01-2.53-4.38c0-1.6 1.13-2.25 2.53-1.44a5.58 5.58 0 012.54 4.37z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-270)"
                    />
                    <path
                      d="M1127.77 498a1.84 1.84 0 00-1.73-.22 2 2 0 011.51.3 5.6 5.6 0 012.54 4.37 1.72 1.72 0 01-.8 1.66c.62-.17 1-.79 1-1.74a5.58 5.58 0 00-2.52-4.37z"
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#prefix__Radial_Gradient_1-4)"
                    />
                  </g>
                  <g>
                    <path
                      d="M1096.86 521.5a1.78 1.78 0 01-.71 1.61l-1.4.81c-.46.26-.17-.32-.86-.72a5.6 5.6 0 01-2.54-4.37c0-.8-.73-.81-.28-1.07l1.47-.82a1.83 1.83 0 011.79.19 5.59 5.59 0 012.53 4.37z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-273)"
                    />
                    <path
                      d="M1095.39 522.34c0 1.6-1.13 2.25-2.53 1.44a5.6 5.6 0 01-2.54-4.37c0-1.61 1.14-2.26 2.54-1.45a5.59 5.59 0 012.53 4.38z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-271)"
                    />
                    <path
                      d="M1094.7 522.47a1.53 1.53 0 01-.61 1.39l-4 2.41c-.41.23-.16-.27-.76-.62a4.83 4.83 0 01-2.19-3.78c0-.68-.63-.69-.25-.92l4.06-2.42a1.55 1.55 0 011.53.16 4.83 4.83 0 012.22 3.78z"
                      fill="url(#prefix__linear-gradient-5)"
                    />
                    <path
                      d="M1093 517.9a1.81 1.81 0 00-1.73-.21 2 2 0 011.51.3 5.58 5.58 0 012.54 4.37 1.73 1.73 0 01-.8 1.66c.61-.18 1-.79 1-1.75a5.59 5.59 0 00-2.52-4.37z"
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#prefix__Radial_Gradient_1-5)"
                    />
                    <path
                      d="M1090.37 525.33a1.78 1.78 0 01-.71 1.61l-1.4.82c-.46.25-.17-.33-.86-.73a5.6 5.6 0 01-2.54-4.37c0-.8-.73-.8-.28-1.07l1.49-.82a1.79 1.79 0 011.77.19 5.59 5.59 0 012.53 4.37z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_4-274)"
                    />
                    <path
                      d="M1088.91 526.17c0 1.6-1.14 2.25-2.54 1.44a5.57 5.57 0 01-2.53-4.37c0-1.61 1.13-2.25 2.53-1.44a5.57 5.57 0 012.54 4.37z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-272)"
                    />
                    <path
                      d="M1086.49 521.73a1.83 1.83 0 00-1.73-.21 2 2 0 011.51.3 5.6 5.6 0 012.54 4.37 1.73 1.73 0 01-.8 1.66c.62-.17 1-.79 1-1.74a5.6 5.6 0 00-2.52-4.38z"
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#prefix__Radial_Gradient_1-6)"
                    />
                  </g>
                </g>
                <path
                  d="M1101.41 532.8v-1.85c-41-.61-106.81-10.64-187.5-55-61.41-33.73-546.83-315.15-551.72-318l-.93 1.6c4.9 2.83 490.34 284.27 551.76 318 81.06 44.56 147.19 54.64 188.39 55.25z"
                  fill="#9a9a9a"
                />
                <path
                  d="M1101.41 537.52v-1.32c-41-.61-106.87-10.65-187.62-55-61.4-33.73-546.83-315.15-551.73-318l-.65 1.14c4.89 2.84 490.33 284.27 551.75 318 81 44.5 147.08 54.56 188.25 55.18z"
                  fill="#9a9a9a"
                />
              </g>
              <g mask="url(#prefix__mask)">
                <path
                  fill="url(#prefix__linear-gradient-6)"
                  d="M3.69 185.64v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-7)"
                  d="M3.69 185.64v2.45l30.05-17.49v-2.43L3.69 185.64z"
                />
                <path
                  fill="url(#prefix__linear-gradient-8)"
                  d="M33.74 168.17L3.69 185.64l-3.66-2.1 30.07-17.48 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-9)"
                  d="M13.43 191.29v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-10)"
                  d="M13.42 191.29v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-11)"
                  d="M43.48 173.81l-30.06 17.48-3.65-2.1 30.07-17.48 3.64 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-12)"
                  d="M23.16 196.94v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-13)"
                  d="M23.16 196.94v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-14)"
                  d="M53.22 179.46l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-15)"
                  d="M32.9 202.58v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-16)"
                  d="M32.9 202.58v2.44l30.05-17.48v-2.44L32.9 202.58z"
                />
                <path
                  fill="url(#prefix__linear-gradient-17)"
                  d="M62.95 185.1L32.9 202.58l-3.66-2.1L59.31 183l3.64 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-18)"
                  d="M42.64 208.22v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-19)"
                  d="M42.63 208.22v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-20)"
                  d="M72.69 190.75l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-21)"
                  d="M52.38 213.87v2.44l-3.67-2.1v-2.44l3.67 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-22)"
                  d="M52.37 213.87v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-23)"
                  d="M82.43 196.39l-30.06 17.48-3.66-2.1 30.07-17.48 3.65 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-24)"
                  d="M62.11 219.52v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-25)"
                  d="M62.11 219.52v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-26)"
                  d="M92.17 202.04l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-27)"
                  d="M71.85 225.16v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-28)"
                  d="M71.84 225.16v2.44l30.06-17.48v-2.43l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-29)"
                  d="M101.9 207.69l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-30)"
                  d="M81.59 230.81v2.44l-3.67-2.1v-2.45l3.67 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-31)"
                  d="M81.58 230.81v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-32)"
                  d="M111.64 213.33l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-33)"
                  d="M91.32 236.45v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-34)"
                  d="M91.32 236.45v2.45l30.06-17.49v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-35)"
                  d="M121.38 218.97l-30.06 17.48-3.66-2.1 30.07-17.48 3.65 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-36)"
                  d="M101.06 242.1v2.44l-3.66-2.1V240l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-37)"
                  d="M101.06 242.1v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-38)"
                  d="M131.11 224.62l-30.05 17.48-3.66-2.1 30.07-17.49 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-39)"
                  d="M110.8 247.74v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-40)"
                  d="M110.79 247.74v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-41)"
                  d="M140.85 230.27l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-42)"
                  d="M120.53 253.39v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-43)"
                  d="M120.53 253.39v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-44)"
                  d="M150.59 235.91l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-45)"
                  d="M130.27 259.03v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-46)"
                  d="M130.26 259.03v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-47)"
                  d="M160.32 241.56l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-48)"
                  d="M140.01 264.68v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-49)"
                  d="M140 264.68v2.44l30.06-17.48v-2.44L140 264.68z"
                />
                <path
                  fill="url(#prefix__linear-gradient-50)"
                  d="M170.06 247.2L140 264.68l-3.65-2.1 30.07-17.49 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-51)"
                  d="M149.75 270.32v2.45l-3.67-2.1v-2.45l3.67 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-52)"
                  d="M149.74 270.32v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-53)"
                  d="M179.8 252.85l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-54)"
                  d="M159.48 275.97v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-55)"
                  d="M159.48 275.97v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-56)"
                  d="M189.53 258.49l-30.05 17.48-3.66-2.1 30.07-17.49 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-57)"
                  d="M169.22 281.61v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-58)"
                  d="M169.21 281.61v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-59)"
                  d="M199.27 264.14l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-60)"
                  d="M178.96 287.26v2.44l-3.67-2.1v-2.44l3.67 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-61)"
                  d="M178.95 287.26v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-62)"
                  d="M209.01 269.78l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-63)"
                  d="M188.69 292.9v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-64)"
                  d="M188.69 292.9v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-65)"
                  d="M218.75 275.43l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-66)"
                  d="M198.43 298.55v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-67)"
                  d="M198.42 298.55v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-68)"
                  d="M228.48 281.07l-30.06 17.48-3.65-2.1 30.07-17.49 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-69)"
                  d="M208.17 304.19v2.45l-3.67-2.1v-2.45l3.67 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-70)"
                  d="M208.16 304.19v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-71)"
                  d="M238.22 286.72l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-72)"
                  d="M217.9 309.84v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-73)"
                  d="M217.9 309.84v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-74)"
                  d="M247.96 292.36l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-75)"
                  d="M227.64 315.49v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-76)"
                  d="M227.63 315.49v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-77)"
                  d="M257.69 298.01l-30.06 17.48-3.65-2.11 30.07-17.48 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-78)"
                  d="M237.38 321.13v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-79)"
                  d="M237.37 321.13v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-80)"
                  d="M267.43 303.65l-30.06 17.48-3.65-2.1 30.07-17.48 3.64 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-81)"
                  d="M247.12 326.78v2.44l-3.67-2.1v-2.45l3.67 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-82)"
                  d="M247.11 326.78v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-83)"
                  d="M277.17 309.3l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-84)"
                  d="M256.85 332.42v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-85)"
                  d="M256.85 332.42v2.45l30.05-17.49v-2.44l-30.05 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-86)"
                  d="M286.9 314.94l-30.05 17.48-3.66-2.1 30.07-17.48 3.64 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-87)"
                  d="M266.59 338.07v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-88)"
                  d="M266.58 338.07v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-89)"
                  d="M296.64 320.59l-30.06 17.48-3.65-2.11L293 318.48l3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-90)"
                  d="M276.33 343.71v2.44l-3.67-2.1v-2.44l3.67 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-91)"
                  d="M276.32 343.71v2.44l30.06-17.48v-2.43l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-92)"
                  d="M306.38 326.24l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-93)"
                  d="M286.06 349.36v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-94)"
                  d="M286.06 349.36v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-95)"
                  d="M316.12 331.88l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-96)"
                  d="M295.8 355v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-97)"
                  d="M295.79 355v2.45l30.06-17.49v-2.43L295.79 355z"
                />
                <path
                  fill="url(#prefix__linear-gradient-98)"
                  d="M325.85 337.53L295.79 355l-3.65-2.1 30.07-17.48 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-99)"
                  d="M305.54 360.65v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-100)"
                  d="M305.53 360.65v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-101)"
                  d="M335.59 343.17l-30.06 17.48-3.65-2.1 30.06-17.49 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-102)"
                  d="M315.27 366.29v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-103)"
                  d="M315.27 366.29v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-104)"
                  d="M345.33 348.82l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-105)"
                  d="M325.01 371.94v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-106)"
                  d="M325 371.94v2.44l30.06-17.48v-2.44L325 371.94z"
                />
                <path
                  fill="url(#prefix__linear-gradient-107)"
                  d="M355.06 354.46L325 371.94l-3.65-2.1 30.07-17.49 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-108)"
                  d="M334.75 377.58v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-109)"
                  d="M334.74 377.58v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-110)"
                  d="M364.8 360.11l-30.06 17.47-3.65-2.1L361.15 358l3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-111)"
                  d="M344.49 383.23v2.44l-3.67-2.1v-2.44l3.67 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-112)"
                  d="M344.48 383.23v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-113)"
                  d="M374.54 365.75l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-114)"
                  d="M354.22 388.87v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-115)"
                  d="M354.22 388.87v2.45l30.05-17.49v-2.43l-30.05 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-116)"
                  d="M384.27 371.4l-30.05 17.47-3.66-2.1 30.07-17.48 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-117)"
                  d="M363.96 394.52v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-118)"
                  d="M363.95 394.52v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                />
                <path
                  fill="url(#prefix__linear-gradient-119)"
                  d="M394.01 377.04l-30.06 17.48-3.65-2.1 30.07-17.49 3.64 2.11z"
                />
                <path
                  fill="url(#prefix__linear-gradient-120)"
                  d="M373.7 400.16v2.45l-3.67-2.1v-2.45l3.67 2.1z"
                />
                <path
                  fill="url(#prefix__linear-gradient-121)"
                  d="M373.69 400.16v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                />
                <path
                  fill="url(#prefix__linear-gradient-122)"
                  d="M403.75 382.69l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                />
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-123)"
                    d="M383.43 405.81v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-124)"
                    d="M383.43 405.81v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-125)"
                    d="M413.49 388.33l-30.06 17.48-3.66-2.1 30.07-17.48 3.65 2.1z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-126)"
                    d="M393.17 411.45v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-127)"
                    d="M393.16 411.45v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-128)"
                    d="M423.22 393.98l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-129)"
                    d="M402.91 417.1v2.44l-3.66-2.1V415l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-130)"
                    d="M402.9 417.1v2.44l30.06-17.48v-2.44L402.9 417.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-131)"
                    d="M432.96 399.62L402.9 417.1l-3.65-2.1 30.06-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-132)"
                    d="M412.64 422.75v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-133)"
                    d="M412.64 422.75v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-134)"
                    d="M442.7 405.27l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-135)"
                    d="M422.38 428.39v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-136)"
                    d="M422.38 428.39v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-137)"
                    d="M452.43 410.91l-30.05 17.48-3.66-2.1 30.07-17.48 3.64 2.1z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-138)"
                    d="M432.12 434.04v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-139)"
                    d="M432.11 434.04v2.44L462.17 419v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-140)"
                    d="M462.17 416.56l-30.06 17.48-3.65-2.11 30.06-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-141)"
                    d="M441.86 439.68v2.45l-3.67-2.11v-2.44l3.67 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-142)"
                    d="M441.85 439.68v2.45l30.06-17.49v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-143)"
                    d="M471.91 422.2l-30.06 17.48-3.66-2.1 30.07-17.48 3.65 2.1z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-144)"
                    d="M451.59 445.33v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-145)"
                    d="M451.59 445.33v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-146)"
                    d="M481.64 427.85l-30.05 17.48-3.66-2.11L478 425.74l3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-147)"
                    d="M461.33 450.97v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-148)"
                    d="M461.32 450.97v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-149)"
                    d="M491.38 433.5l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-150)"
                    d="M471.07 456.62v2.44l-3.67-2.1v-2.45l3.67 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-151)"
                    d="M471.06 456.62v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-152)"
                    d="M501.12 439.14l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-153)"
                    d="M480.8 462.26v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-154)"
                    d="M480.8 462.26v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-155)"
                    d="M510.86 444.79l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-156)"
                    d="M490.54 467.91v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-157)"
                    d="M490.53 467.91v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-158)"
                    d="M520.59 450.43l-30.06 17.48-3.65-2.1 30.07-17.49 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-159)"
                    d="M500.28 473.55V476l-3.66-2.11v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-160)"
                    d="M500.27 473.55V476l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-161)"
                    d="M530.33 456.08l-30.06 17.47-3.65-2.1 30.06-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-162)"
                    d="M510.01 479.2v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-163)"
                    d="M510.01 479.2v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-164)"
                    d="M540.07 461.72l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-165)"
                    d="M519.75 484.84v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-166)"
                    d="M519.75 484.84v2.45l30.05-17.49v-2.43l-30.05 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-167)"
                    d="M549.8 467.37l-30.05 17.47-3.66-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-168)"
                    d="M529.49 490.49v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-169)"
                    d="M529.48 490.49v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-170)"
                    d="M559.54 473.01l-30.06 17.48-3.65-2.1 30.06-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-171)"
                    d="M539.23 496.13v2.45l-3.67-2.1v-2.45l3.67 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-172)"
                    d="M539.22 496.13v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-173)"
                    d="M569.28 478.66l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-174)"
                    d="M548.96 501.78v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-175)"
                    d="M548.96 501.78v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-176)"
                    d="M579.01 484.3l-30.05 17.48-3.66-2.1 30.07-17.49 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-177)"
                    d="M558.7 507.42v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-178)"
                    d="M558.69 507.42v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-179)"
                    d="M588.75 489.95l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-180)"
                    d="M568.44 513.07v2.44l-3.67-2.1v-2.44l3.67 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-181)"
                    d="M568.43 513.07v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-182)"
                    d="M598.49 495.59l-30.06 17.48-3.66-2.1 30.07-17.48 3.65 2.1z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-183)"
                    d="M578.17 518.72v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-184)"
                    d="M578.17 518.72v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-185)"
                    d="M608.23 501.24l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-186)"
                    d="M587.91 524.36v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-187)"
                    d="M587.9 524.36v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-188)"
                    d="M617.96 506.88l-30.06 17.48-3.65-2.1 30.07-17.49 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-189)"
                    d="M597.65 530v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-190)"
                    d="M597.64 530v2.45l30.06-17.48v-2.44L597.64 530z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-191)"
                    d="M627.7 512.53L597.64 530l-3.65-2.1 30.06-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-192)"
                    d="M607.38 535.65v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-193)"
                    d="M607.38 535.65v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-194)"
                    d="M637.44 518.17l-30.06 17.48-3.66-2.1 30.07-17.48 3.65 2.1z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-195)"
                    d="M617.12 541.3v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-196)"
                    d="M617.12 541.3v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-197)"
                    d="M647.17 523.82l-30.05 17.48-3.66-2.11 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-198)"
                    d="M626.86 546.94v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-199)"
                    d="M626.85 546.94v2.44l30.06-17.48v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-200)"
                    d="M656.91 529.47l-30.06 17.47-3.65-2.1 30.06-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-201)"
                    d="M636.59 552.59v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-202)"
                    d="M636.59 552.59v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-203)"
                    d="M666.65 535.11l-30.06 17.48-3.66-2.11L663 533l3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-204)"
                    d="M646.33 558.23v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-205)"
                    d="M646.33 558.23v2.44l30.05-17.48v-2.43l-30.05 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-206)"
                    d="M676.38 540.76l-30.05 17.47-3.66-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-207)"
                    d="M656.07 563.88v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-208)"
                    d="M656.06 563.88v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-209)"
                    d="M686.12 546.4l-30.06 17.48-3.65-2.11 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-210)"
                    d="M665.8 569.52v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-211)"
                    d="M665.8 569.52v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-212)"
                    d="M695.86 552.05l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-213)"
                    d="M675.54 575.17v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-214)"
                    d="M675.54 575.17v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-215)"
                    d="M705.6 557.69l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-216)"
                    d="M685.28 580.81v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-217)"
                    d="M685.27 580.81v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-218)"
                    d="M715.33 563.34l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-219)"
                    d="M695.02 586.46v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-220)"
                    d="M695.01 586.46v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-221)"
                    d="M725.07 568.98l-30.06 17.48-3.65-2.1 30.06-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-222)"
                    d="M704.75 592.1v2.45l-3.66-2.1V590l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-223)"
                    d="M704.75 592.1v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-224)"
                    d="M734.81 574.63l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-225)"
                    d="M714.49 597.75v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-226)"
                    d="M714.49 597.75v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-227)"
                    d="M744.54 580.27l-30.05 17.48-3.66-2.1 30.07-17.49 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-228)"
                    d="M724.23 603.39v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-229)"
                    d="M724.22 603.39v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-230)"
                    d="M754.28 585.92l-30.06 17.47-3.65-2.1 30.06-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-231)"
                    d="M733.96 609.04v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-232)"
                    d="M733.96 609.04v2.44L764.02 594v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-233)"
                    d="M764.02 591.56l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-234)"
                    d="M743.7 614.68v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-235)"
                    d="M743.7 614.68v2.45l30.05-17.49v-2.43l-30.05 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-236)"
                    d="M773.75 597.21l-30.05 17.47-3.66-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-237)"
                    d="M753.44 620.33v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-238)"
                    d="M753.43 620.33v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-239)"
                    d="M783.49 602.85l-30.06 17.48-3.65-2.1 30.07-17.48 3.64 2.1z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-240)"
                    d="M763.17 625.98v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-241)"
                    d="M763.17 625.98v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-242)"
                    d="M793.23 608.5l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-243)"
                    d="M772.91 631.62v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-244)"
                    d="M772.91 631.62v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-245)"
                    d="M802.97 614.14l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-246)"
                    d="M782.65 637.26v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-247)"
                    d="M782.64 637.26v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-248)"
                    d="M812.7 619.79l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-249)"
                    d="M792.39 642.91v2.44l-3.67-2.1v-2.44l3.67 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-250)"
                    d="M792.38 642.91v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-251)"
                    d="M822.44 625.43l-30.06 17.48-3.66-2.1 30.07-17.48 3.65 2.1z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-252)"
                    d="M802.12 648.56V651l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-253)"
                    d="M802.12 648.56V651l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-254)"
                    d="M832.18 631.08l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-255)"
                    d="M811.86 654.2v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-256)"
                    d="M811.85 654.2v2.44l30.06-17.48v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-257)"
                    d="M841.91 636.73l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-258)"
                    d="M821.6 659.85v2.44l-3.67-2.1v-2.45l3.67 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-259)"
                    d="M821.59 659.85v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-260)"
                    d="M851.65 642.37l-30.06 17.48-3.66-2.11L848 640.26l3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-261)"
                    d="M831.33 665.49v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-262)"
                    d="M831.33 665.49v2.44l30.06-17.48v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-263)"
                    d="M861.39 648.02l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-264)"
                    d="M841.07 671.14v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-265)"
                    d="M841.07 671.14v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-266)"
                    d="M871.12 653.66l-30.05 17.48-3.66-2.11 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-267)"
                    d="M850.81 676.78v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-268)"
                    d="M850.8 676.78v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-269)"
                    d="M880.86 659.31l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-270)"
                    d="M860.54 682.43v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-271)"
                    d="M860.54 682.43v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-272)"
                    d="M890.6 664.95l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-273)"
                    d="M870.28 688.07v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-274)"
                    d="M870.28 688.07v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-275)"
                    d="M900.34 670.6l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-276)"
                    d="M880.02 693.72v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-277)"
                    d="M880.01 693.72v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-278)"
                    d="M910.07 676.24l-30.06 17.48-3.65-2.1 30.07-17.49 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-279)"
                    d="M889.76 699.36v2.45l-3.67-2.1v-2.45l3.67 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-280)"
                    d="M889.75 699.36v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-281)"
                    d="M919.81 681.89l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-282)"
                    d="M899.49 705.01v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-283)"
                    d="M899.49 705.01v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-284)"
                    d="M929.54 687.53l-30.05 17.48-3.66-2.1 30.07-17.49 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-285)"
                    d="M909.23 710.65v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-286)"
                    d="M909.22 710.65v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-287)"
                    d="M939.28 693.18l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-288)"
                    d="M918.97 716.3v2.44l-3.67-2.1v-2.44l3.67 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-289)"
                    d="M918.96 716.3v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-290)"
                    d="M949.02 698.82l-30.06 17.48-3.66-2.1 30.08-17.49 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-291)"
                    d="M928.7 721.94v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-292)"
                    d="M928.7 721.94v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-293)"
                    d="M958.76 704.47l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-294)"
                    d="M938.44 727.59v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-295)"
                    d="M938.43 727.59v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-296)"
                    d="M968.49 710.11l-30.06 17.48-3.65-2.1L964.85 708l3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-297)"
                    d="M948.18 733.24v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-298)"
                    d="M948.17 733.24v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-299)"
                    d="M978.23 715.76l-30.06 17.48-3.65-2.11 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-300)"
                    d="M957.91 738.88v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-301)"
                    d="M957.91 738.88v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-302)"
                    d="M987.97 721.4l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-303)"
                    d="M967.65 744.52v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-304)"
                    d="M967.65 744.52v2.45l30.05-17.48v-2.44l-30.05 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-305)"
                    d="M997.7 727.05l-30.05 17.47-3.66-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-306)"
                    d="M977.39 750.17v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-307)"
                    d="M977.38 750.17v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-308)"
                    d="M1007.44 732.69l-30.06 17.48-3.65-2.1 30.07-17.48 3.64 2.1z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-309)"
                    d="M987.13 755.82v2.44l-3.67-2.1v-2.45l3.67 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-310)"
                    d="M987.12 755.82v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-311)"
                    d="M1017.18 738.34l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-312)"
                    d="M996.86 761.46v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-313)"
                    d="M996.86 761.46v2.44l30.05-17.48v-2.43l-30.05 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-314)"
                    d="M1026.91 743.99l-30.05 17.47-3.66-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-315)"
                    d="M1006.6 767.11v2.44l-3.66-2.1V765l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-316)"
                    d="M1006.59 767.11v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-317)"
                    d="M1036.65 749.63l-30.06 17.48-3.65-2.11 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-318)"
                    d="M1016.34 772.75v2.45l-3.67-2.11v-2.44l3.67 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-319)"
                    d="M1016.33 772.75v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-320)"
                    d="M1046.39 755.28l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-321)"
                    d="M1026.07 778.4v2.44l-3.66-2.1v-2.45l3.66 2.11z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-322)"
                    d="M1026.07 778.4v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-323)"
                    d="M1056.13 760.92l-30.06 17.48-3.66-2.11 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-324)"
                    d="M1035.81 784.04v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-325)"
                    d="M1035.81 784.04v2.45l30.05-17.49v-2.43l-30.05 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-326)"
                    d="M1065.86 766.57l-30.05 17.47-3.66-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-327)"
                    d="M1045.55 789.69v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-328)"
                    d="M1045.54 789.69v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-329)"
                    d="M1075.6 772.21l-30.06 17.48-3.65-2.1 30.06-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-330)"
                    d="M1055.29 795.33v2.45l-3.67-2.11v-2.44l3.67 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-331)"
                    d="M1055.28 795.33v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-332)"
                    d="M1085.34 777.86l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-333)"
                    d="M1065.02 800.98v2.44l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-334)"
                    d="M1065.02 800.98v2.44l30.05-17.48v-2.44l-30.05 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-335)"
                    d="M1095.07 783.5l-30.05 17.48-3.66-2.1 30.07-17.49 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-336)"
                    d="M1074.76 806.62v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-337)"
                    d="M1074.75 806.62v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-338)"
                    d="M1104.81 789.15l-30.06 17.47-3.65-2.1 30.07-17.48 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-339)"
                    d="M1084.5 812.27v2.44l-3.67-2.1v-2.44l3.67 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-340)"
                    d="M1084.49 812.27v2.44l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-341)"
                    d="M1114.55 794.79l-30.06 17.48-3.66-2.1 30.07-17.49 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-342)"
                    d="M1094.23 817.91v2.45l-3.66-2.11v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-343)"
                    d="M1094.23 817.91v2.45l30.06-17.48v-2.44l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-344)"
                    d="M1124.29 800.44l-30.06 17.47-3.66-2.1 30.07-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-345)"
                    d="M1103.97 823.56V826l-3.66-2.1v-2.44l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-346)"
                    d="M1103.96 823.56V826l30.06-17.48v-2.44l-30.06 17.48z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-347)"
                    d="M1134.02 806.08l-30.06 17.48-3.65-2.1 30.07-17.49 3.64 2.11z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__linear-gradient-348)"
                    d="M1113.71 829.2v2.45l-3.66-2.1v-2.45l3.66 2.1z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-349)"
                    d="M1113.7 829.2v2.45l30.06-17.49v-2.43l-30.06 17.47z"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-350)"
                    d="M1143.76 811.73l-30.06 17.47-3.65-2.1 30.06-17.48 3.65 2.11z"
                  />
                </g>
                <g>
                  <g clipPath="url(#prefix__clip-path)">
                    <path
                      fill="#8d9195"
                      d="M1120.82 825.73l1.09 3.62h-1.09v-3.62zM1120.82 825.4l1.19 3.95h-.1l-1.09-3.62v-.33z"
                    />
                    <path
                      fill="#8e9296"
                      d="M1120.82 825.07l1.28 4.28h-.09l-1.19-3.95v-.33z"
                    />
                    <path
                      fill="#8f9397"
                      d="M1120.84 824.81l1.36 4.54h-.1l-1.28-4.28v-.26h.02z"
                    />
                    <path
                      fill="#909498"
                      d="M1120.94 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#919599"
                      d="M1121.04 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#92969a"
                      d="M1121.14 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#93979b"
                      d="M1121.23 824.81l1.37 4.54h-.1l-1.36-4.54h.09z"
                    />
                    <path
                      fill="#93989c"
                      d="M1121.33 824.81l1.36 4.54h-.09l-1.37-4.54h.1z"
                    />
                    <path
                      fill="#94999d"
                      d="M1121.43 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#959a9e"
                      d="M1121.53 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#969b9f"
                      d="M1121.63 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#979ca0"
                      d="M1121.73 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#989da1"
                      d="M1121.82 824.81l1.36 4.54h-.09l-1.36-4.54h.09z"
                    />
                    <path
                      fill="#999ea2"
                      d="M1121.92 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#9a9fa3"
                      d="M1122.02 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#9ba0a4"
                      d="M1122.12 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#9ca1a5"
                      d="M1122.22 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#9da2a6"
                      d="M1122.32 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#9ea3a7"
                      d="M1122.41 824.81l1.37 4.54h-.1l-1.36-4.54h.09z"
                    />
                    <path
                      fill="#9fa4a8"
                      d="M1122.51 824.81l1.36 4.54h-.09l-1.37-4.54h.1z"
                    />
                    <path
                      fill="#a0a5a9"
                      d="M1122.61 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#a0a6aa"
                      d="M1122.71 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#a1a7ab"
                      d="M1122.81 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#a2a8ac"
                      d="M1122.91 824.81l1.36 4.54h-.1l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#a3a9ad"
                      d="M1123 824.81l1.37 4.54h-.1l-1.36-4.54h.09z"
                    />
                    <path
                      fill="#a4aaae"
                      d="M1123.1 824.81l1.36 4.54h-.09l-1.37-4.54h.1z"
                    />
                    <path
                      fill="#a5abaf"
                      d="M1123.2 824.81l1.28 4.28v.26h-.02l-1.36-4.54h.1z"
                    />
                    <path
                      fill="#a6acb0"
                      d="M1123.3 824.81l1.18 3.95v.33l-1.28-4.28h.1z"
                    />
                    <path
                      fill="#a7adb1"
                      d="M1123.4 824.81l1.08 3.62v.33l-1.18-3.95h.1z"
                    />
                    <path
                      fill="#a8aeb2"
                      d="M1123.5 824.81l.98 3.29v.33l-1.08-3.62h.1z"
                    />
                    <path
                      fill="#a9afb3"
                      d="M1123.6 824.81l.88 2.96v.33l-.98-3.29h.1z"
                    />
                    <path
                      fill="#aab0b4"
                      d="M1123.69 824.81l.79 2.63v.33l-.88-2.96h.09z"
                    />
                    <path
                      fill="#abb1b5"
                      d="M1123.79 824.81l.69 2.31v.32l-.79-2.63h.1z"
                    />
                    <path
                      fill="#acb2b6"
                      d="M1123.89 824.81l.59 1.98v.33l-.69-2.31h.1z"
                    />
                    <path
                      fill="#adb3b7"
                      d="M1123.99 824.81l.49 1.65v.33l-.59-1.98h.1z"
                    />
                    <path
                      fill="#adb4b8"
                      d="M1124.09 824.81l.39 1.32v.33l-.49-1.65h.1z"
                    />
                    <path
                      fill="#aeb5b9"
                      d="M1124.18 824.81l.3.99v.33l-.39-1.32h.09z"
                    />
                    <path
                      fill="#afb6ba"
                      d="M1124.28 824.81l.2.66v.33l-.3-.99h.1z"
                    />
                    <path
                      fill="#b0b7bb"
                      d="M1124.38 824.81l.1.33v.33l-.2-.66h.1z"
                    />
                    <path fill="#b1b8bc" d="M1124.48 824.81v.33l-.1-.33h.1z" />
                    <path fill="#b2b9bd" d="M1124.48 824.82v-.01.01z" />
                  </g>
                  <g clipPath="url(#prefix__clip-path-2)">
                    <path fill="#a7adb1" d="M0 757.86l16.03 71.49H0v-71.49z" />
                    <path
                      fill="#a8aeb2"
                      d="M0 612.39l48.63 216.96h-32.6L0 757.86V612.39z"
                    />
                    <path
                      fill="#a9afb3"
                      d="M0 466.93l81.24 362.42H48.63L0 612.39V466.93z"
                    />
                    <path
                      fill="#aab0b4"
                      d="M0 321.47l113.85 507.88H81.24L0 466.93V321.47z"
                    />
                    <path
                      fill="#abb1b5"
                      d="M.27 177.22l146.19 652.13h-32.61L0 321.47V177.22h.27z"
                    />
                    <path
                      fill="#acb2b6"
                      d="M32.88 177.22l146.19 652.13h-32.61L.27 177.22h32.61z"
                    />
                    <path
                      fill="#adb3b7"
                      d="M65.49 177.22l146.19 652.13h-32.61L32.88 177.22h32.61z"
                    />
                    <path
                      fill="#adb4b8"
                      d="M98.1 177.22l146.18 652.13h-32.6L65.49 177.22H98.1z"
                    />
                    <path
                      fill="#aeb5b9"
                      d="M130.71 177.22l146.18 652.13h-32.61L98.1 177.22h32.61z"
                    />
                    <path
                      fill="#afb6ba"
                      d="M163.31 177.22L309.5 829.35h-32.61L130.71 177.22h32.6z"
                    />
                    <path
                      fill="#b0b7bb"
                      d="M195.92 177.22l146.19 652.13H309.5L163.31 177.22h32.61z"
                    />
                    <path
                      fill="#b1b8bc"
                      d="M228.53 177.22l146.19 652.13h-32.61L195.92 177.22h32.61z"
                    />
                    <path
                      fill="#b2b9bd"
                      d="M261.14 177.22l146.18 652.13h-32.6L228.53 177.22h32.61z"
                    />
                    <path
                      fill="#b3babe"
                      d="M293.75 177.22l146.18 652.13h-32.61L261.14 177.22h32.61z"
                    />
                    <path
                      fill="#b4bbbf"
                      d="M326.35 177.22l146.19 652.13h-32.61L293.75 177.22h32.6z"
                    />
                    <path
                      fill="#b5bcc0"
                      d="M358.96 177.22l146.19 652.13h-32.61L326.35 177.22h32.61z"
                    />
                    <path
                      fill="#b6bdc1"
                      d="M391.57 177.22l146.19 652.13h-32.61L358.96 177.22h32.61z"
                    />
                    <path
                      fill="#b7bec2"
                      d="M424.18 177.22l146.19 652.13h-32.61L391.57 177.22h32.61z"
                    />
                    <path
                      fill="#b8bfc3"
                      d="M456.79 177.22l146.18 652.13h-32.6L424.18 177.22h32.61z"
                    />
                    <path
                      fill="#b9c0c4"
                      d="M489.39 177.22l146.19 652.13h-32.61L456.79 177.22h32.6z"
                    />
                    <path
                      fill="#bac1c5"
                      d="M522 177.22l146.19 652.13h-32.61L489.39 177.22H522z"
                    />
                    <path
                      fill="#bac1c7"
                      d="M554.61 177.22L700.8 829.35h-32.61L522 177.22h32.61z"
                    />
                    <path
                      fill="#bbc2c8"
                      d="M587.22 177.22l146.19 652.13H700.8L554.61 177.22h32.61z"
                    />
                    <path
                      fill="#bcc3c9"
                      d="M619.83 177.22l146.18 652.13h-32.6L587.22 177.22h32.61z"
                    />
                    <path
                      fill="#bdc4ca"
                      d="M652.43 177.22l146.19 652.13h-32.61L619.83 177.22h32.6z"
                    />
                    <path
                      fill="#bec5cb"
                      d="M685.04 177.22l146.19 652.13h-32.61L652.43 177.22h32.61z"
                    />
                    <path
                      fill="#bfc6cc"
                      d="M717.65 177.22l146.19 652.13h-32.61L685.04 177.22h32.61z"
                    />
                    <path
                      fill="#c0c7cd"
                      d="M750.26 177.22l146.19 652.13h-32.61L717.65 177.22h32.61z"
                    />
                    <path
                      fill="#c1c8ce"
                      d="M782.87 177.22l146.18 652.13h-32.6L750.26 177.22h32.61z"
                    />
                    <path
                      fill="#c2c9cf"
                      d="M815.48 177.22l146.18 652.13h-32.61L782.87 177.22h32.61z"
                    />
                    <path
                      fill="#c3cad0"
                      d="M848.08 177.22l146.19 652.13h-32.61L815.48 177.22h32.6z"
                    />
                    <path
                      fill="#c4cbd1"
                      d="M880.69 177.22l146.19 652.13h-32.61L848.08 177.22h32.61z"
                    />
                    <path
                      fill="#c5ccd2"
                      d="M913.3 177.22l146.19 652.13h-32.61L880.69 177.22h32.61z"
                    />
                    <path
                      fill="#c6cdd3"
                      d="M945.91 177.22l146.19 652.13h-32.61L913.3 177.22h32.61z"
                    />
                    <path
                      fill="#c7ced4"
                      d="M978.52 177.22l142.31 634.84v17.29h-28.73L945.91 177.22h32.61z"
                    />
                    <path
                      fill="#c7cfd5"
                      d="M1011.13 177.22l109.7 489.38v145.46L978.52 177.22h32.61z"
                    />
                    <path
                      fill="#c8d0d6"
                      d="M1043.73 177.22l77.1 343.91V666.6l-109.7-489.38h32.6z"
                    />
                    <path
                      fill="#c9d1d7"
                      d="M1076.34 177.22l44.49 198.45v145.46l-77.1-343.91h32.61z"
                    />
                    <path
                      fill="#cad2d8"
                      d="M1108.95 177.22l11.88 52.99v145.46l-44.49-198.45h32.61z"
                    />
                    <path
                      fill="#cbd3d9"
                      d="M1120.83 230.21l-11.88-52.99h11.88v52.99z"
                    />
                  </g>
                  <g clipPath="url(#prefix__clip-path-3)">
                    <path
                      fill="#8d9195"
                      d="M1124.48 661.66L197.22 175.12h927.26v486.54z"
                    />
                    <path
                      fill="#8d9195"
                      d="M1124.48 663.23L194.24 175.12h2.98l927.26 486.54v1.57z"
                    />
                    <path
                      fill="#8e9296"
                      d="M1124.48 664.79L191.26 175.12h2.98l930.24 488.11v1.56z"
                    />
                    <path
                      fill="#8f9397"
                      d="M1124.48 666.36l-936.2-491.24h2.98l933.22 489.67v1.57z"
                    />
                    <path
                      fill="#909498"
                      d="M1124.48 667.92l-939.19-492.8h2.99l936.2 491.24v1.56z"
                    />
                    <path
                      fill="#919599"
                      d="M1124.48 669.49L182.31 175.12h2.98l939.19 492.8v1.57z"
                    />
                    <path
                      fill="#92969a"
                      d="M1124.48 671.05L179.32 175.12h2.99l942.17 494.37v1.56z"
                    />
                    <path
                      fill="#93979b"
                      d="M1124.48 672.62l-948.14-497.5h2.98l945.16 495.93v1.57z"
                    />
                    <path
                      fill="#93989c"
                      d="M1124.48 674.19L173.36 175.12h2.98l948.14 497.5v1.57z"
                    />
                    <path
                      fill="#94999d"
                      d="M1124.48 675.75l-954.1-500.63h2.98l951.12 499.07v1.56z"
                    />
                    <path
                      fill="#959a9e"
                      d="M1124.48 677.32l-957.09-502.2h2.99l954.1 500.63v1.57z"
                    />
                    <path
                      fill="#969b9f"
                      d="M1124.48 678.88L164.41 175.12h2.98l957.09 502.2v1.56z"
                    />
                    <path
                      fill="#979ca0"
                      d="M1124.48 680.45L161.43 175.12h2.98l960.07 503.76v1.57z"
                    />
                    <path
                      fill="#989da1"
                      d="M1124.48 682.01L158.44 175.12h2.99l963.05 505.33v1.56z"
                    />
                    <path
                      fill="#999ea2"
                      d="M1124.48 683.58L155.46 175.12h2.98l966.04 506.89v1.57z"
                    />
                    <path
                      fill="#9a9fa3"
                      d="M1124.48 685.14L152.47 175.12h2.99l969.02 508.46v1.56z"
                    />
                    <path
                      fill="#9ba0a4"
                      d="M1124.48 686.71L149.49 175.12h2.98l972.01 510.02v1.57z"
                    />
                    <path
                      fill="#9ca1a5"
                      d="M1124.48 688.27L146.51 175.12h2.98l974.99 511.59v1.56z"
                    />
                    <path
                      fill="#9da2a6"
                      d="M1124.48 689.84L143.52 175.12h2.99l977.97 513.15v1.57z"
                    />
                    <path
                      fill="#9ea3a7"
                      d="M1124.48 691.4L140.54 175.12h2.98l980.96 514.72v1.56z"
                    />
                    <path
                      fill="#9fa4a8"
                      d="M1124.48 692.97L137.56 175.12h2.98l983.94 516.28v1.57z"
                    />
                    <path
                      fill="#a0a5a9"
                      d="M1124.48 694.54L134.57 175.12h2.99l986.92 517.85v1.57z"
                    />
                    <path
                      fill="#a0a6aa"
                      d="M1124.48 696.1L131.59 175.12h2.98l989.91 519.42v1.56z"
                    />
                    <path
                      fill="#a1a7ab"
                      d="M1124.48 697.67L128.61 175.12h2.98l992.89 520.98v1.57z"
                    />
                    <path
                      fill="#a2a8ac"
                      d="M1124.48 699.23L125.62 175.12h2.99l995.87 522.55v1.56z"
                    />
                    <path
                      fill="#a3a9ad"
                      d="M1124.48 700.8L122.64 175.12h2.98l998.86 524.11v1.57z"
                    />
                    <path
                      fill="#a4aaae"
                      d="M1124.48 702.36L119.66 175.12h2.98L1124.48 700.8v1.56z"
                    />
                    <path
                      fill="#a5abaf"
                      d="M1124.48 703.93L116.67 175.12h2.99l1004.82 527.24v1.57z"
                    />
                    <path
                      fill="#a6acb0"
                      d="M1124.48 705.49L113.69 175.12h2.98l1007.81 528.81v1.56z"
                    />
                    <path
                      fill="#a7adb1"
                      d="M1124.48 707.06L110.71 175.12h2.98l1010.79 530.37v1.57z"
                    />
                    <path
                      fill="#a8aeb2"
                      d="M1124.48 708.63L107.72 175.12h2.99l1013.77 531.94v1.57z"
                    />
                    <path
                      fill="#a9afb3"
                      d="M1124.48 710.19L104.74 175.12h2.98l1016.76 533.51v1.56z"
                    />
                    <path
                      fill="#aab0b4"
                      d="M1124.48 711.76L101.76 175.12h2.98l1019.74 535.07v1.57z"
                    />
                    <path
                      fill="#abb1b5"
                      d="M1124.48 713.32L98.77 175.12h2.99l1022.72 536.64v1.56z"
                    />
                    <path
                      fill="#acb2b6"
                      d="M1124.48 714.89L95.79 175.12h2.98l1025.71 538.2v1.57z"
                    />
                    <path
                      fill="#adb3b7"
                      d="M1124.48 716.45L92.81 175.12h2.98l1028.69 539.77v1.56z"
                    />
                    <path
                      fill="#adb4b8"
                      d="M1124.48 718.02L89.82 175.12h2.99l1031.67 541.33v1.57z"
                    />
                    <path
                      fill="#aeb5b9"
                      d="M1124.48 719.58L86.84 175.12h2.98l1034.66 542.9v1.56z"
                    />
                    <path
                      fill="#afb6ba"
                      d="M1124.48 721.15L83.86 175.12h2.98l1037.64 544.46v1.57z"
                    />
                    <path
                      fill="#b0b7bb"
                      d="M1124.48 722.71L80.87 175.12h2.99l1040.62 546.03v1.56z"
                    />
                    <path
                      fill="#b1b8bc"
                      d="M1124.48 724.28L77.89 175.12h2.98l1043.61 547.59v1.57z"
                    />
                    <path
                      fill="#b2b9bd"
                      d="M1124.48 725.85L74.91 175.12h2.98l1046.59 549.16v1.57z"
                    />
                    <path
                      fill="#b3babe"
                      d="M1124.48 727.41L71.92 175.12h2.99l1049.57 550.73v1.56z"
                    />
                    <path
                      fill="#b4bbbf"
                      d="M1124.48 728.98L68.94 175.12h2.98l1052.56 552.29v1.57z"
                    />
                    <path
                      fill="#b5bcc0"
                      d="M1124.48 730.54L65.96 175.12h2.98l1055.54 553.86v1.56z"
                    />
                    <path
                      fill="#b6bdc1"
                      d="M1124.48 732.11L62.97 175.12h2.99l1058.52 555.42v1.57z"
                    />
                    <path
                      fill="#b7bec2"
                      d="M1124.48 733.67L59.99 175.12h2.98l1061.51 556.99v1.56z"
                    />
                    <path
                      fill="#b8bfc3"
                      d="M1124.48 735.24L57.01 175.12h2.98l1064.49 558.55v1.57z"
                    />
                    <path
                      fill="#b9c0c4"
                      d="M1124.48 736.8L54.02 175.12h2.99l1067.47 560.12v1.56z"
                    />
                    <path
                      fill="#bac1c5"
                      d="M1124.48 738.37L51.04 175.12h2.98L1124.48 736.8v1.57z"
                    />
                    <path
                      fill="#bac1c7"
                      d="M1124.48 739.93L48.06 175.12h2.98l1073.44 563.25v1.56z"
                    />
                    <path
                      fill="#bbc2c8"
                      d="M1124.48 741.5L45.07 175.12h2.99l1076.42 564.81v1.57z"
                    />
                    <path
                      fill="#bcc3c9"
                      d="M1124.48 743.06L42.09 175.12h2.98L1124.48 741.5v1.56z"
                    />
                    <path
                      fill="#bdc4ca"
                      d="M1124.48 744.63L39.11 175.12h2.98l1082.39 567.94v1.57z"
                    />
                    <path
                      fill="#bec5cb"
                      d="M1124.48 746.2L36.12 175.12h2.99l1085.37 569.51v1.57z"
                    />
                    <path
                      fill="#bfc6cc"
                      d="M1124.48 747.76L33.14 175.12h2.98L1124.48 746.2v1.56z"
                    />
                    <path
                      fill="#c0c7cd"
                      d="M1124.48 749.33L30.16 175.12h2.98l1091.34 572.64v1.57z"
                    />
                    <path
                      fill="#c1c8ce"
                      d="M1124.48 750.89L27.17 175.12h2.99l1094.32 574.21v1.56z"
                    />
                    <path
                      fill="#c2c9cf"
                      d="M1124.48 752.46L24.19 175.12h2.98l1097.31 575.77v1.57z"
                    />
                    <path
                      fill="#c3cad0"
                      d="M1124.48 754.02L21.21 175.12h2.98l1100.29 577.34v1.56z"
                    />
                    <path
                      fill="#c4cbd1"
                      d="M1124.48 755.59L18.22 175.12h2.99l1103.27 578.9v1.57z"
                    />
                    <path
                      fill="#c5ccd2"
                      d="M1124.48 757.15L15.24 175.12h2.98l1106.26 580.47v1.56z"
                    />
                    <path
                      fill="#c6cdd3"
                      d="M1124.48 758.72L12.26 175.12h2.98l1109.24 582.03v1.57z"
                    />
                    <path
                      fill="#c7ced4"
                      d="M1124.48 760.28L9.27 175.12h2.99l1112.22 583.6v1.56z"
                    />
                    <path
                      fill="#c7cfd5"
                      d="M1124.48 761.85L6.29 175.12h2.98l1115.21 585.16v1.57z"
                    />
                    <path
                      fill="#c8d0d6"
                      d="M1124.48 763.41L3.31 175.12h2.98l1118.19 586.73v1.56z"
                    />
                    <path
                      fill="#c9d1d7"
                      d="M1124.48 764.98L.32 175.12h2.99l1121.17 588.29v1.57z"
                    />
                    <path
                      fill="#cad2d8"
                      d="M1124.48 766.55L0 176.51v-1.39h.32l1124.16 589.86v1.57z"
                    />
                    <path
                      fill="#cbd3d9"
                      d="M1124.48 768.11L0 178.08v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#ccd4da"
                      d="M1124.48 769.68L0 179.64v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#cdd5db"
                      d="M1124.48 771.24L0 181.21v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#ced6dc"
                      d="M1124.48 772.81L0 182.77v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#cfd7dd"
                      d="M1124.48 774.37L0 184.34v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#d0d8de"
                      d="M1124.48 775.94L0 185.91v-1.57l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#d1d9df"
                      d="M1124.48 777.5L0 187.47v-1.56l1124.48 590.03v1.56z"
                    />
                    <path
                      fill="#d2dae0"
                      d="M1124.48 779.07L0 189.04v-1.57L1124.48 777.5v1.57z"
                    />
                    <path
                      fill="#d3dbe1"
                      d="M1124.48 780.63L0 190.6v-1.56l1124.48 590.03v1.56z"
                    />
                    <path
                      fill="#d4dce2"
                      d="M1124.48 782.2L0 192.17v-1.57l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#d4dde3"
                      d="M1124.48 783.76L0 193.73v-1.56L1124.48 782.2v1.56z"
                    />
                    <path
                      fill="#d5dee4"
                      d="M1124.48 785.33L0 195.3v-1.57l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#d6dfe5"
                      d="M1124.48 786.9L0 196.86v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#d7e0e6"
                      d="M1124.48 788.46L0 198.43v-1.57L1124.48 786.9v1.56z"
                    />
                    <path
                      fill="#d8e1e7"
                      d="M1124.48 790.03L0 199.99v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#d9e2e8"
                      d="M1124.48 791.59L0 201.56v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#dae3e9"
                      d="M1124.48 793.16L0 203.13v-1.57l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#dbe4ea"
                      d="M1124.48 794.72L0 204.69v-1.56l1124.48 590.03v1.56z"
                    />
                    <path
                      fill="#dce5eb"
                      d="M1124.48 796.29L0 206.25v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#dde6ec"
                      d="M1124.48 797.85L0 207.82v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#dee7ed"
                      d="M1124.48 799.42L0 209.39v-1.57l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#dfe8ee"
                      d="M1124.48 800.99L0 210.95v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#e0e9ef"
                      d="M1124.48 802.55L0 212.52v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#e1eaf0"
                      d="M1124.48 804.12L0 214.08v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#e1ebf1"
                      d="M1124.48 805.68L0 215.65v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#e2ecf2"
                      d="M1124.48 807.25L0 217.21v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#e3edf3"
                      d="M1124.48 808.81L0 218.78v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#e4eef4"
                      d="M1124.48 810.38L0 220.34v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#e5eff5"
                      d="M1124.48 811.94L0 221.91v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#e6f0f6"
                      d="M1124.48 813.51L0 223.47v-1.56l1124.48 590.03v1.57z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M1124.48 815.07L0 225.04v-1.57l1124.48 590.04v1.56z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M0 225.04l1124.48 590.03v11.84H0V225.04z"
                    />
                  </g>
                </g>
                <g>
                  <g clipPath="url(#prefix__clip-path-4)">
                    <path
                      fill="#8d9195"
                      d="M1137.84 815.91l1.09 3.63h-1.09v-3.63zM1137.84 815.58l1.19 3.96h-.1l-1.09-3.63v-.33z"
                    />
                    <path
                      fill="#8e9296"
                      d="M1137.84 815.25l1.28 4.29h-.09l-1.19-3.96v-.33z"
                    />
                    <path
                      fill="#8f9397"
                      d="M1137.86 814.99l1.36 4.55h-.1l-1.28-4.29v-.26h.02z"
                    />
                    <path
                      fill="#909498"
                      d="M1137.96 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#919599"
                      d="M1138.06 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#92969a"
                      d="M1138.16 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#93979b"
                      d="M1138.25 814.99l1.37 4.55h-.1l-1.36-4.55h.09z"
                    />
                    <path
                      fill="#93989c"
                      d="M1138.35 814.99l1.36 4.55h-.09l-1.37-4.55h.1z"
                    />
                    <path
                      fill="#94999d"
                      d="M1138.45 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#959a9e"
                      d="M1138.55 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#969b9f"
                      d="M1138.65 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#979ca0"
                      d="M1138.75 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#989da1"
                      d="M1138.84 814.99l1.37 4.55h-.1l-1.36-4.55h.09z"
                    />
                    <path
                      fill="#999ea2"
                      d="M1138.94 814.99l1.36 4.55h-.09l-1.37-4.55h.1z"
                    />
                    <path
                      fill="#9a9fa3"
                      d="M1139.04 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#9ba0a4"
                      d="M1139.14 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#9ca1a5"
                      d="M1139.24 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#9da2a6"
                      d="M1139.34 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#9ea3a7"
                      d="M1139.43 814.99l1.37 4.55h-.1l-1.36-4.55h.09z"
                    />
                    <path
                      fill="#9fa4a8"
                      d="M1139.53 814.99l1.36 4.55h-.09l-1.37-4.55h.1z"
                    />
                    <path
                      fill="#a0a5a9"
                      d="M1139.63 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#a0a6aa"
                      d="M1139.73 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#a1a7ab"
                      d="M1139.83 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#a2a8ac"
                      d="M1139.93 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#a3a9ad"
                      d="M1140.03 814.99l1.36 4.55h-.1l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#a4aaae"
                      d="M1140.12 814.99l1.36 4.55h-.09l-1.36-4.55h.09z"
                    />
                    <path
                      fill="#a5abaf"
                      d="M1140.22 814.99l1.28 4.28v.27h-.02l-1.36-4.55h.1z"
                    />
                    <path
                      fill="#a6acb0"
                      d="M1140.32 814.99l1.18 3.95v.33l-1.28-4.28h.1z"
                    />
                    <path
                      fill="#a7adb1"
                      d="M1140.42 814.99l1.08 3.62v.33l-1.18-3.95h.1z"
                    />
                    <path
                      fill="#a8aeb2"
                      d="M1140.52 814.99l.98 3.29v.33l-1.08-3.62h.1z"
                    />
                    <path
                      fill="#a9afb3"
                      d="M1140.62 814.99l.88 2.97v.32l-.98-3.29h.1z"
                    />
                    <path
                      fill="#aab0b4"
                      d="M1140.71 814.99l.79 2.64v.33l-.88-2.97h.09z"
                    />
                    <path
                      fill="#abb1b5"
                      d="M1140.81 814.99l.69 2.31v.33l-.79-2.64h.1z"
                    />
                    <path
                      fill="#acb2b6"
                      d="M1140.91 814.99l.59 1.98v.33l-.69-2.31h.1z"
                    />
                    <path
                      fill="#adb3b7"
                      d="M1141.01 814.99l.49 1.65v.33l-.59-1.98h.1z"
                    />
                    <path
                      fill="#adb4b8"
                      d="M1141.11 814.99l.39 1.32v.33l-.49-1.65h.1z"
                    />
                    <path
                      fill="#aeb5b9"
                      d="M1141.2 814.99l.3 1v.32l-.39-1.32h.09z"
                    />
                    <path
                      fill="#afb6ba"
                      d="M1141.3 814.99l.2.67v.33l-.3-1h.1z"
                    />
                    <path
                      fill="#b0b7bb"
                      d="M1141.4 814.99l.1.34v.33l-.2-.67h.1z"
                    />
                    <path fill="#b1b8bc" d="M1141.5 814.99v.34l-.1-.34h.1z" />
                    <path fill="#b2b9bd" d="M1141.5 815v-.01.01z" />
                  </g>
                  <g clipPath="url(#prefix__clip-path-5)">
                    <path
                      fill="#a7adb1"
                      d="M17.02 748.04l16.03 71.5H17.02v-71.5z"
                    />
                    <path
                      fill="#a8aeb2"
                      d="M17.02 602.58l48.64 216.96H33.05l-16.03-71.5V602.58z"
                    />
                    <path
                      fill="#a9afb3"
                      d="M17.02 457.12l81.24 362.42h-32.6L17.02 602.58V457.12z"
                    />
                    <path
                      fill="#aab0b4"
                      d="M17.02 311.65l113.85 507.89H98.26L17.02 457.12V311.65z"
                    />
                    <path
                      fill="#abb1b5"
                      d="M17.29 167.41l146.19 652.13h-32.61L17.02 311.65V167.41h.27z"
                    />
                    <path
                      fill="#acb2b6"
                      d="M49.9 167.41l146.19 652.13h-32.61L17.29 167.41H49.9z"
                    />
                    <path
                      fill="#adb3b7"
                      d="M82.51 167.41L228.7 819.54h-32.61L49.9 167.41h32.61z"
                    />
                    <path
                      fill="#adb4b8"
                      d="M115.12 167.41L261.3 819.54h-32.6L82.51 167.41h32.61z"
                    />
                    <path
                      fill="#aeb5b9"
                      d="M147.73 167.41l146.18 652.13H261.3L115.12 167.41h32.61z"
                    />
                    <path
                      fill="#afb6ba"
                      d="M180.33 167.41l146.19 652.13h-32.61L147.73 167.41h32.6z"
                    />
                    <path
                      fill="#b0b7bb"
                      d="M212.94 167.41l146.19 652.13h-32.61L180.33 167.41h32.61z"
                    />
                    <path
                      fill="#b1b8bc"
                      d="M245.55 167.41l146.19 652.13h-32.61L212.94 167.41h32.61z"
                    />
                    <path
                      fill="#b2b9bd"
                      d="M278.16 167.41l146.19 652.13h-32.61L245.55 167.41h32.61z"
                    />
                    <path
                      fill="#b3babe"
                      d="M310.77 167.41l146.18 652.13h-32.6L278.16 167.41h32.61z"
                    />
                    <path
                      fill="#b4bbbf"
                      d="M343.38 167.41l146.18 652.13h-32.61L310.77 167.41h32.61z"
                    />
                    <path
                      fill="#b5bcc0"
                      d="M375.98 167.41l146.19 652.13h-32.61L343.38 167.41h32.6z"
                    />
                    <path
                      fill="#b6bdc1"
                      d="M408.59 167.41l146.19 652.13h-32.61L375.98 167.41h32.61z"
                    />
                    <path
                      fill="#b7bec2"
                      d="M441.2 167.41l146.19 652.13h-32.61L408.59 167.41h32.61z"
                    />
                    <path
                      fill="#b8bfc3"
                      d="M473.81 167.41l146.18 652.13h-32.6L441.2 167.41h32.61z"
                    />
                    <path
                      fill="#b9c0c4"
                      d="M506.42 167.41L652.6 819.54h-32.61L473.81 167.41h32.61z"
                    />
                    <path
                      fill="#bac1c5"
                      d="M539.02 167.41l146.19 652.13H652.6L506.42 167.41h32.6z"
                    />
                    <path
                      fill="#bac1c7"
                      d="M571.63 167.41l146.19 652.13h-32.61L539.02 167.41h32.61z"
                    />
                    <path
                      fill="#bbc2c8"
                      d="M604.24 167.41l146.19 652.13h-32.61L571.63 167.41h32.61z"
                    />
                    <path
                      fill="#bcc3c9"
                      d="M636.85 167.41l146.18 652.13h-32.6L604.24 167.41h32.61z"
                    />
                    <path
                      fill="#bdc4ca"
                      d="M669.46 167.41l146.18 652.13h-32.61L636.85 167.41h32.61z"
                    />
                    <path
                      fill="#bec5cb"
                      d="M702.06 167.41l146.19 652.13h-32.61L669.46 167.41h32.6z"
                    />
                    <path
                      fill="#bfc6cc"
                      d="M734.67 167.41l146.19 652.13h-32.61L702.06 167.41h32.61z"
                    />
                    <path
                      fill="#c0c7cd"
                      d="M767.28 167.41l146.19 652.13h-32.61L734.67 167.41h32.61z"
                    />
                    <path
                      fill="#c1c8ce"
                      d="M799.89 167.41l146.19 652.13h-32.61L767.28 167.41h32.61z"
                    />
                    <path
                      fill="#c2c9cf"
                      d="M832.5 167.41l146.18 652.13h-32.6L799.89 167.41h32.61z"
                    />
                    <path
                      fill="#c3cad0"
                      d="M865.1 167.41l146.19 652.13h-32.61L832.5 167.41h32.6z"
                    />
                    <path
                      fill="#c4cbd1"
                      d="M897.71 167.41l146.19 652.13h-32.61L865.1 167.41h32.61z"
                    />
                    <path
                      fill="#c5ccd2"
                      d="M930.32 167.41l146.19 652.13h-32.61L897.71 167.41h32.61z"
                    />
                    <path
                      fill="#c6cdd3"
                      d="M962.93 167.41l146.19 652.13h-32.61L930.32 167.41h32.61z"
                    />
                    <path
                      fill="#c7ced4"
                      d="M995.54 167.41l142.31 634.83v17.3h-28.73L962.93 167.41h32.61z"
                    />
                    <path
                      fill="#c7cfd5"
                      d="M1028.14 167.41l109.71 489.37v145.46L995.54 167.41h32.6z"
                    />
                    <path
                      fill="#c8d0d6"
                      d="M1060.75 167.41l77.1 343.91v145.46l-109.71-489.37h32.61z"
                    />
                    <path
                      fill="#c9d1d7"
                      d="M1093.36 167.41l44.49 198.44v145.47l-77.1-343.91h32.61z"
                    />
                    <path
                      fill="#cad2d8"
                      d="M1125.97 167.41l11.88 52.98v145.46l-44.49-198.44h32.61z"
                    />
                    <path
                      fill="#cbd3d9"
                      d="M1137.85 220.39l-11.88-52.98h11.88v52.98z"
                    />
                  </g>
                  <g clipPath="url(#prefix__clip-path-6)">
                    <path
                      fill="#8d9195"
                      d="M1141.5 651.85L214.25 165.3h927.25v486.55z"
                    />
                    <path
                      fill="#8d9195"
                      d="M1141.5 653.41L211.26 165.3h2.99l927.25 486.55v1.56z"
                    />
                    <path
                      fill="#8e9296"
                      d="M1141.5 654.98L208.28 165.3h2.98l930.24 488.11v1.57z"
                    />
                    <path
                      fill="#8f9397"
                      d="M1141.5 656.54L205.29 165.3h2.99l933.22 489.68v1.56z"
                    />
                    <path
                      fill="#909498"
                      d="M1141.5 658.11L202.31 165.3h2.98l936.21 491.24v1.57z"
                    />
                    <path
                      fill="#919599"
                      d="M1141.5 659.67L199.33 165.3h2.98l939.19 492.81v1.56z"
                    />
                    <path
                      fill="#92969a"
                      d="M1141.5 661.24L196.34 165.3h2.99l942.17 494.37v1.57z"
                    />
                    <path
                      fill="#93979b"
                      d="M1141.5 662.8L193.36 165.3h2.98l945.16 495.94v1.56z"
                    />
                    <path
                      fill="#93989c"
                      d="M1141.5 664.37L190.38 165.3h2.98l948.14 497.5v1.57z"
                    />
                    <path
                      fill="#94999d"
                      d="M1141.5 665.93L187.4 165.3h2.98l951.12 499.07v1.56z"
                    />
                    <path
                      fill="#959a9e"
                      d="M1141.5 667.5L184.41 165.3h2.99l954.1 500.63v1.57z"
                    />
                    <path
                      fill="#969b9f"
                      d="M1141.5 669.07L181.43 165.3h2.98l957.09 502.2v1.57z"
                    />
                    <path
                      fill="#979ca0"
                      d="M1141.5 670.63L178.44 165.3h2.99l960.07 503.77v1.56z"
                    />
                    <path
                      fill="#989da1"
                      d="M1141.5 672.2L175.46 165.3h2.98l963.06 505.33v1.57z"
                    />
                    <path
                      fill="#999ea2"
                      d="M1141.5 673.76L172.48 165.3h2.98l966.04 506.9v1.56z"
                    />
                    <path
                      fill="#9a9fa3"
                      d="M1141.5 675.33l-972-510.03h2.98l969.02 508.46v1.57z"
                    />
                    <path
                      fill="#9ba0a4"
                      d="M1141.5 676.89L166.51 165.3h2.99l972 510.03v1.56z"
                    />
                    <path
                      fill="#9ca1a5"
                      d="M1141.5 678.46L163.53 165.3h2.98l974.99 511.59v1.57z"
                    />
                    <path
                      fill="#9da2a6"
                      d="M1141.5 680.02L160.54 165.3h2.99l977.97 513.16v1.56z"
                    />
                    <path
                      fill="#9ea3a7"
                      d="M1141.5 681.59L157.56 165.3h2.98l980.96 514.72v1.57z"
                    />
                    <path
                      fill="#9fa4a8"
                      d="M1141.5 683.15L154.58 165.3h2.98l983.94 516.29v1.56z"
                    />
                    <path
                      fill="#a0a5a9"
                      d="M1141.5 684.72L151.59 165.3h2.99l986.92 517.85v1.57z"
                    />
                    <path
                      fill="#a0a6aa"
                      d="M1141.5 686.28L148.61 165.3h2.98l989.91 519.42v1.56z"
                    />
                    <path
                      fill="#a1a7ab"
                      d="M1141.5 687.85L145.63 165.3h2.98l992.89 520.98v1.57z"
                    />
                    <path
                      fill="#a2a8ac"
                      d="M1141.5 689.41L142.64 165.3h2.99l995.87 522.55v1.56z"
                    />
                    <path
                      fill="#a3a9ad"
                      d="M1141.5 690.98L139.66 165.3h2.98l998.86 524.11v1.57z"
                    />
                    <path
                      fill="#a4aaae"
                      d="M1141.5 692.55L136.68 165.3h2.98L1141.5 690.98v1.57z"
                    />
                    <path
                      fill="#a5abaf"
                      d="M1141.5 694.11L133.69 165.3h2.99L1141.5 692.55v1.56z"
                    />
                    <path
                      fill="#a6acb0"
                      d="M1141.5 695.68L130.71 165.3h2.98L1141.5 694.11v1.57z"
                    />
                    <path
                      fill="#a7adb1"
                      d="M1141.5 697.24L127.73 165.3h2.98L1141.5 695.68v1.56z"
                    />
                    <path
                      fill="#a8aeb2"
                      d="M1141.5 698.81L124.74 165.3h2.99L1141.5 697.24v1.57z"
                    />
                    <path
                      fill="#a9afb3"
                      d="M1141.5 700.37L121.76 165.3h2.98L1141.5 698.81v1.56z"
                    />
                    <path
                      fill="#aab0b4"
                      d="M1141.5 701.94L118.78 165.3h2.98L1141.5 700.37v1.57z"
                    />
                    <path
                      fill="#abb1b5"
                      d="M1141.5 703.5L115.79 165.3h2.99L1141.5 701.94v1.56z"
                    />
                    <path
                      fill="#acb2b6"
                      d="M1141.5 705.07L112.81 165.3h2.98L1141.5 703.5v1.57z"
                    />
                    <path
                      fill="#adb3b7"
                      d="M1141.5 706.63L109.83 165.3h2.98L1141.5 705.07v1.56z"
                    />
                    <path
                      fill="#adb4b8"
                      d="M1141.5 708.2L106.84 165.3h2.99L1141.5 706.63v1.57z"
                    />
                    <path
                      fill="#aeb5b9"
                      d="M1141.5 709.77L103.86 165.3h2.98L1141.5 708.2v1.57z"
                    />
                    <path
                      fill="#afb6ba"
                      d="M1141.5 711.33L100.88 165.3h2.98L1141.5 709.77v1.56z"
                    />
                    <path
                      fill="#b0b7bb"
                      d="M1141.5 712.9L97.89 165.3h2.99L1141.5 711.33v1.57z"
                    />
                    <path
                      fill="#b1b8bc"
                      d="M1141.5 714.46L94.91 165.3h2.98L1141.5 712.9v1.56z"
                    />
                    <path
                      fill="#b2b9bd"
                      d="M1141.5 716.03L91.93 165.3h2.98L1141.5 714.46v1.57z"
                    />
                    <path
                      fill="#b3babe"
                      d="M1141.5 717.59L88.94 165.3h2.99L1141.5 716.03v1.56z"
                    />
                    <path
                      fill="#b4bbbf"
                      d="M1141.5 719.16L85.96 165.3h2.98L1141.5 717.59v1.57z"
                    />
                    <path
                      fill="#b5bcc0"
                      d="M1141.5 720.72L82.98 165.3h2.98L1141.5 719.16v1.56z"
                    />
                    <path
                      fill="#b6bdc1"
                      d="M1141.5 722.29L79.99 165.3h2.99L1141.5 720.72v1.57z"
                    />
                    <path
                      fill="#b7bec2"
                      d="M1141.5 723.86L77.01 165.3h2.98L1141.5 722.29v1.57z"
                    />
                    <path
                      fill="#b8bfc3"
                      d="M1141.5 725.42L74.03 165.3h2.98L1141.5 723.86v1.56z"
                    />
                    <path
                      fill="#b9c0c4"
                      d="M1141.5 726.99L71.04 165.3h2.99L1141.5 725.42v1.57z"
                    />
                    <path
                      fill="#bac1c5"
                      d="M1141.5 728.55L68.06 165.3h2.98L1141.5 726.99v1.56z"
                    />
                    <path
                      fill="#bac1c7"
                      d="M1141.5 730.12L65.08 165.3h2.98L1141.5 728.55v1.57z"
                    />
                    <path
                      fill="#bbc2c8"
                      d="M1141.5 731.68L62.09 165.3h2.99L1141.5 730.12v1.56z"
                    />
                    <path
                      fill="#bcc3c9"
                      d="M1141.5 733.25L59.11 165.3h2.98L1141.5 731.68v1.57z"
                    />
                    <path
                      fill="#bdc4ca"
                      d="M1141.5 734.81L56.13 165.3h2.98L1141.5 733.25v1.56z"
                    />
                    <path
                      fill="#bec5cb"
                      d="M1141.5 736.38L53.14 165.3h2.99L1141.5 734.81v1.57z"
                    />
                    <path
                      fill="#bfc6cc"
                      d="M1141.5 737.94L50.16 165.3h2.98L1141.5 736.38v1.56z"
                    />
                    <path
                      fill="#c0c7cd"
                      d="M1141.5 739.51L47.18 165.3h2.98L1141.5 737.94v1.57z"
                    />
                    <path
                      fill="#c1c8ce"
                      d="M1141.5 741.07L44.19 165.3h2.99L1141.5 739.51v1.56z"
                    />
                    <path
                      fill="#c2c9cf"
                      d="M1141.5 742.64L41.21 165.3h2.98L1141.5 741.07v1.57z"
                    />
                    <path
                      fill="#c3cad0"
                      d="M1141.5 744.21L38.23 165.3h2.98L1141.5 742.64v1.57z"
                    />
                    <path
                      fill="#c4cbd1"
                      d="M1141.5 745.77L35.24 165.3h2.99L1141.5 744.21v1.56z"
                    />
                    <path
                      fill="#c5ccd2"
                      d="M1141.5 747.34L32.26 165.3h2.98L1141.5 745.77v1.57z"
                    />
                    <path
                      fill="#c6cdd3"
                      d="M1141.5 748.9L29.28 165.3h2.98L1141.5 747.34v1.56z"
                    />
                    <path
                      fill="#c7ced4"
                      d="M1141.5 750.47L26.29 165.3h2.99L1141.5 748.9v1.57z"
                    />
                    <path
                      fill="#c7cfd5"
                      d="M1141.5 752.03L23.31 165.3h2.98L1141.5 750.47v1.56z"
                    />
                    <path
                      fill="#c8d0d6"
                      d="M1141.5 753.6L20.33 165.3h2.98L1141.5 752.03v1.57z"
                    />
                    <path
                      fill="#c9d1d7"
                      d="M1141.5 755.16L17.34 165.3h2.99L1141.5 753.6v1.56z"
                    />
                    <path
                      fill="#cad2d8"
                      d="M1141.5 756.73L17.02 166.69v-1.39h.32L1141.5 755.16v1.57z"
                    />
                    <path
                      fill="#cbd3d9"
                      d="M1141.5 758.29L17.02 168.26v-1.57L1141.5 756.73v1.56z"
                    />
                    <path
                      fill="#ccd4da"
                      d="M1141.5 759.86L17.02 169.83v-1.57L1141.5 758.29v1.57z"
                    />
                    <path
                      fill="#cdd5db"
                      d="M1141.5 761.42L17.02 171.39v-1.56L1141.5 759.86v1.56z"
                    />
                    <path
                      fill="#ced6dc"
                      d="M1141.5 762.99L17.02 172.96v-1.57L1141.5 761.42v1.57z"
                    />
                    <path
                      fill="#cfd7dd"
                      d="M1141.5 764.56L17.02 174.52v-1.56L1141.5 762.99v1.57z"
                    />
                    <path
                      fill="#d0d8de"
                      d="M1141.5 766.12L17.02 176.09v-1.57L1141.5 764.56v1.56z"
                    />
                    <path
                      fill="#d1d9df"
                      d="M1141.5 767.69L17.02 177.65v-1.56L1141.5 766.12v1.57z"
                    />
                    <path
                      fill="#d2dae0"
                      d="M1141.5 769.25L17.02 179.22v-1.57L1141.5 767.69v1.56z"
                    />
                    <path
                      fill="#d3dbe1"
                      d="M1141.5 770.82L17.02 180.78v-1.56L1141.5 769.25v1.57z"
                    />
                    <path
                      fill="#d4dce2"
                      d="M1141.5 772.38L17.02 182.35v-1.57L1141.5 770.82v1.56z"
                    />
                    <path
                      fill="#d4dde3"
                      d="M1141.5 773.95L17.02 183.91v-1.56L1141.5 772.38v1.57z"
                    />
                    <path
                      fill="#d5dee4"
                      d="M1141.5 775.51L17.02 185.48v-1.57L1141.5 773.95v1.56z"
                    />
                    <path
                      fill="#d6dfe5"
                      d="M1141.5 777.08L17.02 187.05v-1.57L1141.5 775.51v1.57z"
                    />
                    <path
                      fill="#d7e0e6"
                      d="M1141.5 778.64L17.02 188.61v-1.56L1141.5 777.08v1.56z"
                    />
                    <path
                      fill="#d8e1e7"
                      d="M1141.5 780.21L17.02 190.18v-1.57L1141.5 778.64v1.57z"
                    />
                    <path
                      fill="#d9e2e8"
                      d="M1141.5 781.77L17.02 191.74v-1.56L1141.5 780.21v1.56z"
                    />
                    <path
                      fill="#dae3e9"
                      d="M1141.5 783.34L17.02 193.31v-1.57L1141.5 781.77v1.57z"
                    />
                    <path
                      fill="#dbe4ea"
                      d="M1141.5 784.91L17.02 194.87v-1.56L1141.5 783.34v1.57z"
                    />
                    <path
                      fill="#dce5eb"
                      d="M1141.5 786.47L17.02 196.44v-1.57L1141.5 784.91v1.56z"
                    />
                    <path
                      fill="#dde6ec"
                      d="M1141.5 788.04L17.02 198v-1.56L1141.5 786.47v1.57z"
                    />
                    <path
                      fill="#dee7ed"
                      d="M1141.5 789.6L17.02 199.57V198L1141.5 788.04v1.56z"
                    />
                    <path
                      fill="#dfe8ee"
                      d="M1141.5 791.17L17.02 201.13v-1.56L1141.5 789.6v1.57z"
                    />
                    <path
                      fill="#e0e9ef"
                      d="M1141.5 792.73L17.02 202.7v-1.57L1141.5 791.17v1.56z"
                    />
                    <path
                      fill="#e1eaf0"
                      d="M1141.5 794.3L17.02 204.26v-1.56L1141.5 792.73v1.57z"
                    />
                    <path
                      fill="#e1ebf1"
                      d="M1141.5 795.86L17.02 205.83v-1.57L1141.5 794.3v1.56z"
                    />
                    <path
                      fill="#e2ecf2"
                      d="M1141.5 797.43L17.02 207.4v-1.57L1141.5 795.86v1.57z"
                    />
                    <path
                      fill="#e3edf3"
                      d="M1141.5 799L17.02 208.96v-1.56L1141.5 797.43V799z"
                    />
                    <path
                      fill="#e4eef4"
                      d="M1141.5 800.56L17.02 210.53v-1.57L1141.5 799v1.56z"
                    />
                    <path
                      fill="#e5eff5"
                      d="M1141.5 802.13L17.02 212.09v-1.56L1141.5 800.56v1.57z"
                    />
                    <path
                      fill="#e6f0f6"
                      d="M1141.5 803.69L17.02 213.66v-1.57L1141.5 802.13v1.56z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M1141.5 805.26L17.02 215.22v-1.56L1141.5 803.69v1.57z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M17.02 215.22L1141.5 805.26v11.83H17.02V215.22z"
                    />
                  </g>
                </g>
              </g>
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37)"
                d="M652.25 250.94v257.19l8.43-4.9V246.09l-8.43 4.85z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37-2)"
                d="M643.37 246.42v-.27l8.92 14.53v247.45l-9.06-5.31V246.35l.14.07z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37-3)"
                d="M762.29 550.58v7.81l-109.17-62.18v-7.79l109.17 62.16z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38)"
                d="M653.12 488.42l109.17 62.16-.22-7.92-102.19-58.18-6.76 3.94z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37-4)"
                d="M762.29 504.41v7.81l-109.17-62.17v-7.79l109.17 62.15z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38-2)"
                d="M653.12 442.26l109.17 62.15-.22-7.91-102.19-58.19-6.76 3.95z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37-5)"
                d="M456.51 383.66v9.41l-8.2-4.72v-9.41l8.2 4.72z"
              />
              <path
                d="M456.49 385.39v2.55a3 3 0 004.46 2.58l381.62-218a3 3 0 001.5-2.59v-2.52a3 3 0 00-4.46-2.58L458 382.8a3 3 0 00-1.51 2.59z"
                fill="#333"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38-3)"
                d="M844.07 162.31L456.49 383.66l-8.18-4.72 387.62-221.38 8.14 4.75z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37-6)"
                d="M936.03 224.84v9.4l-99.72-58.05v-9.41l99.72 58.06z"
              />
              <path
                style={{ mixBlendMode: "screen" }}
                fill="url(#prefix__linear-gradient-351)"
                d="M792.73 206.09l89.24 51.8 24.48-13.98v43.13l-24.48 13.92-89.24-51.89v-42.98z"
              />
              <path
                fill="#26262e"
                d="M881.98 245.92v12.24l-89.24-51.89v-12.16l89.24 51.81z"
              />
              <path
                fill="#3f3f3f"
                d="M881.97 245.92v12.24l24.48-13.92v-12.31l-24.48 13.99z"
              />
              <path
                fill="#9aa0a6"
                d="M906.45 231.93l-24.48 13.99-89.23-51.81 24.49-13.97 89.22 51.79z"
              />
              <path
                fill="#26262e"
                d="M873.28 233.71v3.68l-65.29-37.97v-3.62l65.29 37.91z"
              />
              <path
                fill="#3f3f3f"
                d="M873.28 233.71v3.68l17.91-10.19v-3.73l-17.91 10.24z"
              />
              <path
                fill="#8c9399"
                d="M891.19 223.47l-17.91 10.24-65.29-37.91 17.92-10.22 65.28 37.89z"
              />
              <path
                fill="#272727"
                d="M641.58 387.05v12.25l-89.24-51.9v-12.15l89.24 51.8z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38-4)"
                d="M641.57 387.05v12.25l24.48-13.93v-12.31l-24.48 13.99z"
              />
              <path
                fill="#9f9f9f"
                d="M666.05 373.06l-24.48 13.99-89.23-51.8 24.5-13.97 89.21 51.78z"
              />
              <path
                fill="#272727"
                d="M632.89 374.84v3.68l-65.29-37.96v-3.62l65.29 37.9z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38-5)"
                d="M632.88 374.84v3.68l17.91-10.18v-3.73l-17.91 10.23z"
              />
              <path
                fill="#929292"
                d="M650.79 364.61l-17.91 10.23-65.28-37.9 17.92-10.22 65.27 37.89z"
              />
              <path
                fill="#9f9f9f"
                d="M527.85 417.16v2.54l369.21-212.2v-2.54l-369.21 212.2z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38-6)"
                d="M527.86 417.16v2.54l-2.53-1.52v-2.53l2.53 1.51z"
              />
              <path
                fill="silver"
                d="M525.33 415.65l2.53 1.51 369.2-212.2-2.54-1.51-369.19 212.2z"
              />
              <path
                fill="#9f9f9f"
                d="M493.45 396.61v2.53l369.21-212.2v-2.53l-369.21 212.2z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38-7)"
                d="M493.45 396.61v2.53l-2.53-1.51v-2.53l2.53 1.51z"
              />
              <path
                fill="silver"
                d="M490.92 395.1l2.53 1.51 369.21-212.2-2.55-1.51-369.19 212.2z"
              />
              <path
                d="M771.14 321.25v250.29a3 3 0 004.47 2.57l2.48-1.43a3 3 0 001.48-2.58V319.83a3 3 0 00-4.47-2.59l-2.47 1.42a3 3 0 00-1.49 2.59z"
                fill="#333"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38-8)"
                d="M647.49 248.52l-4.26-2.17.24-.14-.1-.06 8.52-4.9 127.68 73.43-.09.04.09.05-8.43 4.86-1.52-.88-122.13-70.23z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37-7)"
                d="M643.37 246.42v-.27l127.81 73.37v257.2l-9.06-5.31v-247.6l-109.83-63.13-9.06-14.33.14.07z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_24)"
                d="M943.98 220.35l-7.97 4.49-108-62.9 8.02-4.51 107.95 62.92z"
              />
              <g>
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-8)"
                  d="M771.38 209.41l163.47 15.82 8.42-4.88-163.47-15.8-8.42 4.86z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_38-9)"
                  d="M779.8 204.55l163.05 15.63-7.98-4.7-163.02-15.65 7.95 4.72z"
                />
              </g>
              <g>
                <path
                  d="M660.27 143.2l174.64 24.07a3 3 0 001.9-.37 3 3 0 00-1.09-5.53l-174.66-24a3 3 0 00-1.9.37 3 3 0 001.11 5.46z"
                  fill="#333"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-2)"
                  d="M660.08 137.16l183.84 25.2-7.98-4.7-183.81-25.22 7.95 4.72z"
                />
              </g>
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38-10)"
                d="M502.06 332.27l-4.26-2.16.25-.15-.1-.05 8.52-4.9 127.67 73.42-.08.05.08.05-8.42 4.85-1.52-.87-122.14-70.24z"
              />
              <path
                style={{ mixBlendMode: "color-dodge" }}
                fill="url(#prefix__Radial_Gradient_2)"
                d="M498.55 330.27l127.06 71.77 8.53-3.61-7.39 5.58-.93 248.5-.93-248.61-126.34-73.63z"
              />
              <g>
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-9)"
                  d="M652.25 141.04v110.4l8.43-4.9V136.19l-8.43 4.85z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-10)"
                  d="M643.37 136.53v-.28l8.92 14.53v100.67l-9.06-5.31V136.45l.14.08z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-11)"
                  d="M771.14 209.63v110.4l8.43-4.9V204.78l-8.43 4.85z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_38-11)"
                  d="M647.49 138.62l-4.26-2.17.24-.14-.1-.06 8.52-4.9 127.68 73.43-.09.05.09.05-8.43 4.85-1.52-.87-122.13-70.24z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-12)"
                  d="M643.37 136.53v-.28l127.81 73.38V320.03l-9.06-5.3V213.92l-109.83-63.14-9.06-14.33.14.08z"
                />
                <path
                  style={{ mixBlendMode: "color-dodge" }}
                  fill="url(#prefix__Radial_Gradient_2-2)"
                  d="M643.97 136.61l127.06 71.77 8.54-3.6-7.4 5.57-.93 101.72-.93-101.82-126.34-73.64z"
                />
              </g>
              <g>
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_38-12)"
                  d="M771.21 209.94L625.56 403.63l-8.01-4.7 145.66-193.68 8 4.69z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-13)"
                  d="M771.19 209.94L625.54 403.63l8.42-4.88 137.07-181.96.16-6.85z"
                />
              </g>
              <g>
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_38-13)"
                  d="M652.06 140.91L506.41 334.6l-8.01-4.7 145.65-193.68 8.01 4.69z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-14)"
                  d="M652.04 140.91L506.39 334.6l8.42-4.88 137.06-181.96.17-6.85z"
                />
              </g>
              <motion.g id="prefix__train"
              
              variants={trainVariant}
              initial="start"
              animate={trainControls}
              
              >
                <path
                  opacity={0.2}
                  d="M169.68 268.89l24.46-13.95 89.09 51.71-12.09 6.91 4.02 2.33-1.39.79-4.01-2.33-10.98 6.27-89.1-51.73z"
                />
                <path
                  d="M179 266.92a1.91 1.91 0 01-.77 1.74l-3.13 1.8c-.51.29-.2-.34-1-.77a6 6 0 01-2.7-4.69c0-.86-.8-.87-.31-1.15l3.25-1.83a1.94 1.94 0 011.91.21 6 6 0 012.75 4.69z"
                  fill="url(#prefix__linear-gradient-352)"
                />
                <path
                  d="M175.76 268.76c0 1.73-1.22 2.43-2.74 1.56a6 6 0 01-2.73-4.72c0-1.74 1.22-2.44 2.73-1.56a6 6 0 012.74 4.72z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-3)"
                />
                <path
                  d="M174.33 268c0 .9-.64 1.27-1.43.81a3.14 3.14 0 01-1.42-2.46c0-.9.64-1.27 1.42-.81a3.12 3.12 0 011.43 2.46z"
                  fill="#7d7d7d"
                />
                <path
                  d="M173.15 264a2 2 0 00-1.87-.23 2.16 2.16 0 011.64.32 6.06 6.06 0 012.74 4.72 1.85 1.85 0 01-.87 1.79c.67-.18 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#prefix__Radial_Gradient_1-7)"
                />
                <path
                  d="M186.37 271.22a1.92 1.92 0 01-.76 1.73l-3.14 1.81c-.51.28-.2-.35-.95-.78a6 6 0 01-2.73-4.72c0-.86-.8-.86-.31-1.15l3.23-1.82a2 2 0 011.93.21 6 6 0 012.73 4.72z"
                  fill="url(#prefix__linear-gradient-353)"
                />
                <path
                  d="M183.15 273.05c0 1.73-1.22 2.43-2.74 1.56a6 6 0 01-2.73-4.72c0-1.73 1.22-2.43 2.73-1.56a6 6 0 012.74 4.72z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-4)"
                />
                <path
                  d="M181.72 272.33c0 .9-.64 1.27-1.42.81a3.12 3.12 0 01-1.43-2.46c0-.9.64-1.26 1.43-.81a3.17 3.17 0 011.42 2.46z"
                  fill="#7d7d7d"
                />
                <path
                  d="M180.55 268.26a2 2 0 00-1.88-.23 2.18 2.18 0 011.64.33 6 6 0 012.74 4.72 1.87 1.87 0 01-.87 1.79c.67-.19 1.1-.86 1.1-1.89a6 6 0 00-2.73-4.72z"
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#prefix__Radial_Gradient_1-8)"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-5)"
                  d="M180.31 270.56v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                />
                <path
                  d="M248.42 307.49a1.93 1.93 0 01-.77 1.73L244.5 311c-.5.28-.18-.35-.93-.78a6 6 0 01-2.74-4.72c0-.86-.79-.86-.31-1.15l3.25-1.83a2 2 0 011.91.21 6.06 6.06 0 012.74 4.76z"
                  fill="url(#prefix__linear-gradient-354)"
                />
                <path
                  d="M245.2 309.32c0 1.73-1.23 2.43-2.74 1.56a6 6 0 01-2.74-4.72c0-1.74 1.23-2.43 2.74-1.56a6 6 0 012.74 4.72z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-6)"
                />
                <path
                  d="M243.77 308.6c0 .9-.64 1.27-1.43.81a3.12 3.12 0 01-1.43-2.46c0-.9.64-1.26 1.43-.81a3.15 3.15 0 011.43 2.46z"
                  fill="#7d7d7d"
                />
                <path
                  d="M242.59 304.53a2 2 0 00-1.87-.23 2.19 2.19 0 011.63.32 6.06 6.06 0 012.74 4.73 1.87 1.87 0 01-.86 1.79 1.77 1.77 0 001.1-1.89 6.06 6.06 0 00-2.74-4.72z"
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#prefix__Radial_Gradient_1-9)"
                />
                <path
                  d="M255.81 311.78a1.91 1.91 0 01-.77 1.74l-3.15 1.81c-.5.27-.18-.35-.93-.79a6 6 0 01-2.74-4.72c0-.85-.79-.86-.31-1.15l3.24-1.82a2 2 0 011.92.21 6 6 0 012.74 4.72z"
                  fill="url(#prefix__linear-gradient-355)"
                />
                <path
                  d="M252.59 313.61c0 1.74-1.23 2.44-2.74 1.56a6 6 0 01-2.74-4.72c0-1.73 1.23-2.43 2.74-1.56a6 6 0 012.74 4.72z"
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-7)"
                />
                <path
                  d="M251.16 312.89c0 .91-.64 1.27-1.43.82a3.15 3.15 0 01-1.43-2.46c0-.91.64-1.27 1.43-.82a3.15 3.15 0 011.43 2.46z"
                  fill="#7d7d7d"
                />
                <path
                  d="M250 308.83a2 2 0 00-1.87-.23 2.1 2.1 0 011.63.32 6 6 0 012.74 4.72 1.87 1.87 0 01-.86 1.79 1.75 1.75 0 001.1-1.88 6 6 0 00-2.74-4.72z"
                  style={{ mixBlendMode: "screen" }}
                  fill="url(#prefix__Radial_Gradient_1-10)"
                />
                <path
                  fill="url(#prefix__linear-gradient-356)"
                  d="M258.79 311.05v2.97l-18.73-10.9-5.73-.91-45.95-26.72-5.48-5.61-13.22-7.69v-2.88l89.11 51.74z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-8)"
                  d="M258.78 311.05v2.97l24.45-13.91v-3.04l-24.45 13.98z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-9)"
                  d="M249.75 311.12v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                />
                <path
                  fill="#494949"
                  d="M283.23 297.07l-24.45 13.98-89.1-51.74 24.46-13.95 89.09 51.71z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-10)"
                  d="M275.18 308.23v1.7l-5.04-2.93v-1.7l5.04 2.93z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-11)"
                  d="M275.18 308.23v1.7l1.39-.79v-1.7l-1.39.79z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-12)"
                  d="M276.57 307.44l-1.39.79-5.04-2.93 1.38-.79 5.05 2.93z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-13)"
                  d="M275.62 308.44v2.39l-.92-.53v-2.38l.92.52z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-14)"
                  d="M275.62 308.44v2.39l1.94-1.11v-2.39l-1.94 1.11z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-15)"
                  d="M277.56 307.33l-1.94 1.11-.92-.52 1.94-1.11.92.52z"
                />
                <g>
                  <path
                    opacity={0.2}
                    d="M265.38 325.29l24.46-13.95 89.09 51.72-12.09 6.91 4.02 2.33-1.38.79-4.02-2.33-10.97 6.27-89.11-51.74z"
                  />
                  <path
                    d="M274.68 323.33a1.93 1.93 0 01-.76 1.74l-3.16 1.81c-.49.28-.18-.35-.93-.78a6 6 0 01-2.73-4.73c0-.85-.8-.86-.31-1.15l3.21-1.82a1.94 1.94 0 011.91.21 6 6 0 012.77 4.72z"
                    fill="url(#prefix__linear-gradient-357)"
                  />
                  <path
                    d="M271.46 325.16c0 1.74-1.22 2.44-2.74 1.56A6 6 0 01266 322c0-1.73 1.22-2.43 2.73-1.56a6 6 0 012.73 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-16)"
                  />
                  <path
                    d="M270 324.44c0 .91-.64 1.27-1.42.82a3.15 3.15 0 01-1.43-2.46c0-.91.64-1.27 1.43-.82a3.17 3.17 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M268.86 320.38a2 2 0 00-1.88-.23 2.16 2.16 0 011.64.32 6 6 0 012.74 4.72 1.87 1.87 0 01-.87 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-11)"
                  />
                  <path
                    d="M282.07 327.62a1.93 1.93 0 01-.76 1.74l-3.14 1.8c-.51.29-.19-.34-.95-.77a6 6 0 01-2.73-4.72c0-.86-.8-.87-.31-1.15l3.25-1.83a1.94 1.94 0 011.91.21 6 6 0 012.73 4.72z"
                    fill="url(#prefix__linear-gradient-358)"
                  />
                  <path
                    d="M278.85 329.46c0 1.73-1.22 2.43-2.73 1.56a6 6 0 01-2.74-4.72c0-1.74 1.22-2.44 2.74-1.56a6 6 0 012.73 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-17)"
                  />
                  <path
                    d="M277.42 328.74c0 .9-.64 1.27-1.42.81a3.14 3.14 0 01-1.43-2.46c0-.9.64-1.27 1.43-.81a3.14 3.14 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M276.25 324.67a2 2 0 00-1.88-.23 2.16 2.16 0 011.64.32 6 6 0 012.74 4.72 1.85 1.85 0 01-.87 1.79c.67-.18 1.1-.85 1.1-1.88a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-12)"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-18)"
                    d="M276.01 326.96v2.07l-7.56-4.29v-2.13l7.56 4.35z"
                  />
                  <path
                    d="M344.12 363.89a1.91 1.91 0 01-.77 1.74l-3.14 1.8c-.51.29-.19-.34-.94-.77a6.06 6.06 0 01-2.74-4.72c0-.86-.79-.87-.31-1.15l3.25-1.83a1.94 1.94 0 011.91.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-359)"
                  />
                  <path
                    d="M340.9 365.73c0 1.73-1.23 2.43-2.74 1.56a6.06 6.06 0 01-2.74-4.72c0-1.74 1.23-2.44 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-19)"
                  />
                  <path
                    d="M339.47 365c0 .9-.64 1.26-1.43.81a3.14 3.14 0 01-1.43-2.46c0-.9.64-1.27 1.43-.81a3.12 3.12 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M338.29 360.94a2 2 0 00-1.87-.23 2.15 2.15 0 011.63.32 6 6 0 012.74 4.72 1.85 1.85 0 01-.86 1.79c.67-.18 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-13)"
                  />
                  <path
                    d="M351.51 368.18a1.91 1.91 0 01-.77 1.74l-3.15 1.81c-.5.28-.18-.35-.93-.78a6 6 0 01-2.74-4.72c0-.86-.79-.87-.31-1.15l3.24-1.82a2 2 0 011.92.2 6.06 6.06 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-360)"
                  />
                  <path
                    d="M348.29 370c0 1.73-1.23 2.43-2.74 1.56a6 6 0 01-2.74-4.72c0-1.74 1.23-2.43 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-20)"
                  />
                  <path
                    d="M346.86 369.3c0 .9-.64 1.27-1.43.81a3.11 3.11 0 01-1.42-2.46c0-.9.63-1.26 1.42-.81a3.14 3.14 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M345.68 365.23a2 2 0 00-1.87-.23 2.19 2.19 0 011.63.32 6 6 0 012.74 4.73 1.87 1.87 0 01-.86 1.79c.67-.19 1.1-.86 1.1-1.89a6.06 6.06 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-14)"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-361)"
                    d="M354.49 367.45v2.97l-18.73-10.89-5.73-.91-45.95-26.72-5.48-5.61-13.22-7.69v-2.88l89.11 51.73z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-21)"
                    d="M354.49 367.45v2.97l24.44-13.9v-3.04l-24.44 13.97z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-22)"
                    d="M345.45 367.53v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                  />
                  <path
                    fill="#494949"
                    d="M378.93 353.48l-24.44 13.97-89.11-51.73 24.46-13.95 89.09 51.71z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-23)"
                    d="M370.88 364.64v1.7l-5.04-2.94v-1.69l5.04 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-24)"
                    d="M370.88 364.64v1.7l1.39-.79v-1.7l-1.39.79z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-25)"
                    d="M372.27 363.85l-1.39.79-5.04-2.93 1.38-.79 5.05 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-26)"
                    d="M371.32 364.85v2.39l-.92-.54v-2.38l.92.53z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-27)"
                    d="M371.32 364.85v2.39l1.94-1.11v-2.39l-1.94 1.11z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-28)"
                    d="M373.26 363.74l-1.94 1.11-.92-.53 1.95-1.11.91.53z"
                  />
                </g>
                <g>
                  <path
                    opacity={0.2}
                    d="M360.85 381.58l24.46-13.95 89.1 51.71-12.09 6.91 4.02 2.33-1.39.8-4.01-2.34-10.98 6.28-89.11-51.74z"
                  />
                  <path
                    d="M370.16 379.62a1.91 1.91 0 01-.77 1.73l-3.14 1.81c-.51.28-.19-.34-.94-.78a6 6 0 01-2.74-4.72c0-.86-.79-.86-.3-1.15l3.24-1.82a1.94 1.94 0 011.91.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-362)"
                  />
                  <path
                    d="M366.94 381.45c0 1.74-1.23 2.43-2.74 1.56a6 6 0 01-2.74-4.72c0-1.73 1.23-2.43 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-29)"
                  />
                  <path
                    d="M365.51 380.73c0 .91-.64 1.27-1.43.82a3.14 3.14 0 01-1.42-2.46c0-.91.63-1.27 1.42-.82a3.15 3.15 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M364.33 376.66a2 2 0 00-1.87-.23 2.18 2.18 0 011.64.33 6 6 0 012.73 4.72 1.87 1.87 0 01-.86 1.79c.67-.19 1.1-.85 1.1-1.88a6.06 6.06 0 00-2.74-4.73z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-15)"
                  />
                  <path
                    d="M377.55 383.91a1.91 1.91 0 01-.77 1.74l-3.14 1.8c-.5.29-.19-.34-.94-.77A6.06 6.06 0 01370 382c0-.85-.79-.86-.3-1.15l3.23-1.81a1.93 1.93 0 011.92.2 6 6 0 012.7 4.67z"
                    fill="url(#prefix__linear-gradient-363)"
                  />
                  <path
                    d="M374.33 385.74c0 1.74-1.23 2.44-2.74 1.56a6 6 0 01-2.74-4.72c0-1.73 1.23-2.43 2.74-1.56a6.06 6.06 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-30)"
                  />
                  <path
                    d="M372.9 385c0 .9-.64 1.26-1.43.81a3.17 3.17 0 01-1.42-2.46c0-.91.64-1.27 1.42-.81a3.12 3.12 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M371.72 381a2 2 0 00-1.87-.23 2.17 2.17 0 011.64.32 6 6 0 012.73 4.72 1.87 1.87 0 01-.86 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-16)"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-31)"
                    d="M371.49 383.25v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                  />
                  <path
                    d="M439.59 420.18a1.93 1.93 0 01-.76 1.74l-3.16 1.81c-.49.28-.17-.35-.93-.79a6 6 0 01-2.73-4.72c0-.85-.8-.86-.31-1.15 0 0 3.23-1.82 3.25-1.82a1.94 1.94 0 011.91.21 6 6 0 012.73 4.72z"
                    fill="url(#prefix__linear-gradient-364)"
                  />
                  <path
                    d="M436.37 422c0 1.74-1.22 2.44-2.73 1.56a6 6 0 01-2.74-4.72c0-1.73 1.22-2.43 2.74-1.56a6 6 0 012.73 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-32)"
                  />
                  <path
                    d="M434.94 421.29c0 .91-.64 1.27-1.42.82a3.15 3.15 0 01-1.43-2.46c0-.91.64-1.27 1.43-.82a3.17 3.17 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M433.77 417.23a2 2 0 00-1.88-.23 2.12 2.12 0 011.64.32 6 6 0 012.74 4.72 1.87 1.87 0 01-.87 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-17)"
                  />
                  <path
                    d="M447 424.47a1.93 1.93 0 01-.76 1.74l-3.18 1.79c-.49.28-.17-.35-.93-.78a6 6 0 01-2.73-4.72c0-.86-.79-.87-.31-1.15l3.23-1.82a2 2 0 011.93.2 6 6 0 012.75 4.74z"
                    fill="url(#prefix__linear-gradient-365)"
                  />
                  <path
                    d="M443.76 426.31c0 1.73-1.22 2.43-2.73 1.56a6 6 0 01-2.74-4.72c0-1.74 1.22-2.44 2.74-1.56a6 6 0 012.73 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-33)"
                  />
                  <path
                    d="M442.33 425.59c0 .9-.64 1.26-1.42.81a3.14 3.14 0 01-1.43-2.46c0-.9.64-1.27 1.43-.81a3.14 3.14 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M441.16 421.52a2 2 0 00-1.88-.23 2.16 2.16 0 011.64.32 6 6 0 012.74 4.72 1.83 1.83 0 01-.87 1.79c.67-.18 1.1-.85 1.1-1.88a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-18)"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-366)"
                    d="M449.97 423.74v2.97l-18.73-10.89-5.73-.91-45.95-26.72-5.48-5.61-13.23-7.69V372l89.12 51.74z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-34)"
                    d="M449.96 423.74v2.97l24.45-13.91v-3.03l-24.45 13.97z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-35)"
                    d="M440.93 423.81v2.07l-7.57-4.29v-2.13l7.57 4.35z"
                  />
                  <path
                    fill="#494949"
                    d="M474.41 409.77l-24.45 13.97L360.85 372l24.46-13.95 89.1 51.72z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-36)"
                    d="M466.36 420.93v1.7l-5.05-2.94V418l5.05 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-37)"
                    d="M466.36 420.93v1.7l1.39-.79v-1.71l-1.39.8z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-38)"
                    d="M467.75 420.13l-1.39.8-5.05-2.93 1.39-.79 5.05 2.92z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-39)"
                    d="M466.79 421.13v2.39l-.91-.53v-2.38l.91.52z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-40)"
                    d="M466.79 421.13v2.39l1.95-1.1v-2.4l-1.95 1.11z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-41)"
                    d="M468.74 420.02l-1.95 1.11-.91-.52 1.94-1.11.92.52z"
                  />
                </g>
                <g>
                  <path
                    opacity={0.2}
                    d="M456.44 437.93l24.46-13.95 89.1 51.71-12.09 6.91 4.01 2.33-1.38.79-4.02-2.33-10.97 6.27-89.11-51.73z"
                  />
                  <path
                    d="M465.75 436a1.91 1.91 0 01-.77 1.74l-3.14 1.81c-.51.28-.19-.35-.94-.78a6 6 0 01-2.74-4.72c0-.86-.79-.87-.31-1.15l3.25-1.9a2 2 0 011.91.21 6.06 6.06 0 012.74 4.79z"
                    fill="url(#prefix__linear-gradient-367)"
                  />
                  <path
                    d="M462.53 437.8c0 1.73-1.23 2.43-2.74 1.56a6 6 0 01-2.74-4.72c0-1.74 1.23-2.44 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-42)"
                  />
                  <path
                    d="M461.1 437.08c0 .9-.64 1.27-1.43.81a3.12 3.12 0 01-1.43-2.46c0-.9.64-1.27 1.43-.81a3.14 3.14 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M459.92 433a2 2 0 00-1.87-.23 2.15 2.15 0 011.63.32 6 6 0 012.74 4.72 1.85 1.85 0 01-.86 1.79c.67-.18 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-19)"
                  />
                  <path
                    d="M473.14 440.26a1.91 1.91 0 01-.77 1.73l-3.14 1.81c-.51.28-.19-.34-.94-.78a6 6 0 01-2.74-4.72c0-.86-.79-.86-.31-1.15l3.24-1.82a2 2 0 011.92.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-368)"
                  />
                  <path
                    d="M469.92 442.09c0 1.74-1.23 2.43-2.74 1.56a6 6 0 01-2.74-4.72c0-1.73 1.23-2.43 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-43)"
                  />
                  <path
                    d="M468.49 441.37c0 .91-.64 1.27-1.43.82a3.14 3.14 0 01-1.42-2.46c0-.91.63-1.27 1.42-.82a3.15 3.15 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M467.31 437.3a2 2 0 00-1.87-.23 2.15 2.15 0 011.63.33 6 6 0 012.74 4.72 1.87 1.87 0 01-.86 1.79c.67-.19 1.1-.85 1.1-1.88a6.06 6.06 0 00-2.74-4.73z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-20)"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-44)"
                    d="M467.08 439.6v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                  />
                  <path
                    d="M535.18 476.53a1.92 1.92 0 01-.76 1.73l-3.16 1.82c-.49.27-.18-.36-.93-.79a6 6 0 01-2.73-4.72c0-.86-.8-.86-.31-1.15l3.25-1.83a2 2 0 011.91.22 6 6 0 012.73 4.72z"
                    fill="url(#prefix__linear-gradient-369)"
                  />
                  <path
                    d="M532 478.36c0 1.73-1.22 2.43-2.74 1.56a6 6 0 01-2.73-4.72c0-1.73 1.22-2.43 2.73-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-45)"
                  />
                  <path
                    d="M530.53 477.64c0 .9-.64 1.27-1.42.81a3.12 3.12 0 01-1.43-2.46c0-.9.64-1.26 1.43-.81a3.17 3.17 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M529.36 473.57a2 2 0 00-1.88-.23 2.18 2.18 0 011.64.33 6 6 0 012.74 4.72 1.87 1.87 0 01-.87 1.79c.67-.19 1.1-.85 1.1-1.89a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-21)"
                  />
                  <path
                    d="M542.57 480.82a1.93 1.93 0 01-.76 1.74l-3.16 1.81c-.49.28-.18-.35-.93-.79a6 6 0 01-2.73-4.72c0-.85-.8-.86-.31-1.15l3.23-1.81a2 2 0 011.93.2 6 6 0 012.73 4.72z"
                    fill="url(#prefix__linear-gradient-370)"
                  />
                  <path
                    d="M539.35 482.65c0 1.74-1.22 2.44-2.74 1.56a6 6 0 01-2.73-4.72c0-1.73 1.22-2.43 2.73-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-46)"
                  />
                  <path
                    d="M537.92 481.93c0 .91-.64 1.27-1.42.82a3.15 3.15 0 01-1.43-2.46c0-.91.64-1.27 1.43-.82a3.17 3.17 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M536.75 477.87a2 2 0 00-1.88-.23 2.12 2.12 0 011.64.32 6 6 0 012.74 4.72 1.87 1.87 0 01-.87 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-22)"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-371)"
                    d="M545.56 480.09v2.97l-18.73-10.89-5.73-.92-45.95-26.72-5.48-5.61-13.23-7.69v-2.88l89.12 51.74z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-47)"
                    d="M545.55 480.09v2.97L570 469.15v-3.04l-24.45 13.98z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-48)"
                    d="M536.51 480.16v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                  />
                  <path
                    fill="#494949"
                    d="M570 466.11l-24.45 13.98-89.11-51.74 24.46-13.95 89.1 51.71z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-49)"
                    d="M561.95 477.27v1.7l-5.05-2.93v-1.7l5.05 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-50)"
                    d="M561.95 477.27v1.7l1.38-.78v-1.71l-1.38.79z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-51)"
                    d="M563.33 476.48l-1.38.79-5.05-2.93 1.39-.79 5.04 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-52)"
                    d="M562.38 477.48v2.39l-.92-.53v-2.38l.92.52z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-53)"
                    d="M562.38 477.48v2.39l1.95-1.11v-2.39l-1.95 1.11z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-54)"
                    d="M564.33 476.37l-1.95 1.11-.92-.52 1.95-1.11.92.52z"
                  />
                </g>
                <g>
                  <path
                    opacity={0.2}
                    d="M552.14 494.33l24.47-13.95 89.09 51.72-12.09 6.91 4.02 2.33-1.39.79-4.01-2.33-10.98 6.27-89.11-51.74z"
                  />
                  <path
                    d="M561.45 492.37a1.91 1.91 0 01-.77 1.74l-3.15 1.81c-.5.28-.18-.35-.93-.78a6.06 6.06 0 01-2.74-4.72c0-.86-.79-.87-.3-1.16l3.24-1.82a1.94 1.94 0 011.91.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-372)"
                  />
                  <path
                    d="M558.23 494.2c0 1.74-1.23 2.44-2.74 1.57a6.06 6.06 0 01-2.74-4.73c0-1.73 1.23-2.43 2.74-1.56a6.06 6.06 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-55)"
                  />
                  <path
                    d="M556.8 493.49c0 .9-.64 1.26-1.43.81a3.14 3.14 0 01-1.42-2.46c0-.9.63-1.27 1.42-.81a3.12 3.12 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M555.62 489.42a2 2 0 00-1.87-.23 2.17 2.17 0 011.64.32 6 6 0 012.73 4.72 1.86 1.86 0 01-.86 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-23)"
                  />
                  <path
                    d="M568.84 496.66a1.91 1.91 0 01-.77 1.74l-3.14 1.81c-.51.28-.19-.35-.94-.78a6 6 0 01-2.74-4.72c0-.86-.79-.87-.3-1.15l3.24-1.83a1.94 1.94 0 011.91.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-373)"
                  />
                  <path
                    d="M565.62 498.5c0 1.73-1.23 2.43-2.74 1.56a6 6 0 01-2.74-4.72c0-1.74 1.23-2.44 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-56)"
                  />
                  <path
                    d="M564.19 497.78c0 .9-.64 1.27-1.43.81a3.14 3.14 0 01-1.42-2.46c0-.9.64-1.27 1.42-.81a3.12 3.12 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M563 493.71a2 2 0 00-1.87-.23 2.17 2.17 0 011.64.32 6 6 0 012.73 4.72 1.85 1.85 0 01-.86 1.79c.67-.18 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-24)"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-57)"
                    d="M562.78 496v2.07l-7.56-4.29v-2.13l7.56 4.35z"
                  />
                  <path
                    d="M630.88 532.93a1.93 1.93 0 01-.76 1.74l-3.14 1.8c-.51.29-.19-.34-1-.77a6 6 0 01-2.68-4.7c0-.86-.8-.87-.31-1.15l3.25-1.83a1.94 1.94 0 011.91.21 6 6 0 012.73 4.7z"
                    fill="url(#prefix__linear-gradient-374)"
                  />
                  <path
                    d="M627.66 534.77c0 1.73-1.22 2.43-2.73 1.56a6 6 0 01-2.74-4.72c0-1.74 1.22-2.44 2.74-1.56a6 6 0 012.73 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-58)"
                  />
                  <path
                    d="M626.23 534.05c0 .9-.64 1.27-1.42.81a3.14 3.14 0 01-1.43-2.46c0-.9.64-1.27 1.43-.81a3.14 3.14 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M625.06 530a2 2 0 00-1.88-.23 2.16 2.16 0 011.64.32 6 6 0 012.74 4.72 1.85 1.85 0 01-.87 1.79c.67-.18 1.1-.85 1.1-1.88a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-25)"
                  />
                  <path
                    d="M638.27 537.23a1.92 1.92 0 01-.76 1.73l-3.16 1.82c-.49.27-.17-.36-.93-.79a6 6 0 01-2.73-4.72c0-.86-.8-.86-.31-1.15l3.23-1.82a2 2 0 011.93.21 6 6 0 012.73 4.72z"
                    fill="url(#prefix__linear-gradient-375)"
                  />
                  <path
                    d="M635.05 539.06c0 1.73-1.22 2.43-2.73 1.56a6 6 0 01-2.74-4.72c0-1.74 1.22-2.43 2.74-1.56a6 6 0 012.73 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-59)"
                  />
                  <path
                    d="M633.62 538.34c0 .9-.64 1.27-1.42.81a3.12 3.12 0 01-1.43-2.46c0-.9.64-1.26 1.43-.81a3.17 3.17 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M632.45 534.27a2 2 0 00-1.88-.23 2.18 2.18 0 011.64.33 6 6 0 012.74 4.72 1.85 1.85 0 01-.87 1.79c.67-.19 1.1-.86 1.1-1.89a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-26)"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-376)"
                    d="M641.26 536.49v2.97l-18.73-10.89-5.73-.91-45.95-26.72-5.48-5.61-13.23-7.69v-2.88l89.12 51.73z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-60)"
                    d="M641.25 536.49v2.97l24.45-13.9v-3.04l-24.45 13.97z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-61)"
                    d="M632.22 536.57v2.06l-7.57-4.28v-2.13l7.57 4.35z"
                  />
                  <path
                    fill="#494949"
                    d="M665.7 522.52l-24.45 13.97-89.11-51.73 24.47-13.95 89.09 51.71z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-62)"
                    d="M657.65 533.68v1.7l-5.04-2.93v-1.7l5.04 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-63)"
                    d="M657.65 533.68v1.7l1.38-.79v-1.7l-1.38.79z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-64)"
                    d="M659.03 532.89l-1.38.79-5.04-2.93 1.38-.79 5.04 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-65)"
                    d="M658.08 533.89v2.39l-.92-.53v-2.39l.92.53z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-66)"
                    d="M658.08 533.89v2.39l1.95-1.11v-2.39l-1.95 1.11z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-67)"
                    d="M660.03 532.78l-1.95 1.11-.92-.53 1.95-1.11.92.53z"
                  />
                </g>
                <g>
                  <path
                    opacity={0.2}
                    d="M647.62 550.62l24.46-13.95 89.09 51.71-12.09 6.91 4.02 2.34-1.38.79-4.02-2.33-10.97 6.27-89.11-51.74z"
                  />
                  <path
                    d="M656.93 548.66a1.94 1.94 0 01-.77 1.74l-3.14 1.8c-.51.28-.19-.34-.94-.78a6 6 0 01-2.74-4.72c0-.86-.79-.86-.31-1.15 0 0 3.23-1.82 3.25-1.82a1.94 1.94 0 011.91.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-377)"
                  />
                  <path
                    d="M653.7 550.49c0 1.74-1.22 2.44-2.73 1.56a6 6 0 01-2.74-4.72c0-1.73 1.23-2.43 2.74-1.56a6 6 0 012.73 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-68)"
                  />
                  <path
                    d="M652.27 549.77c0 .91-.63 1.27-1.42.82a3.15 3.15 0 01-1.43-2.46c0-.91.64-1.27 1.43-.82a3.14 3.14 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M651.1 545.71a2 2 0 00-1.87-.23 2.1 2.1 0 011.63.32 6 6 0 012.74 4.72 1.85 1.85 0 01-.87 1.79 1.75 1.75 0 001.11-1.88 6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-27)"
                  />
                  <path
                    d="M664.32 553a1.94 1.94 0 01-.77 1.74l-3.14 1.8c-.51.29-.19-.34-.94-.77a6 6 0 01-2.74-4.72c0-.86-.79-.87-.31-1.16l3.24-1.81a1.93 1.93 0 011.92.2 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-378)"
                  />
                  <path
                    d="M661.09 554.78c0 1.74-1.22 2.44-2.73 1.57a6.06 6.06 0 01-2.74-4.73c0-1.73 1.23-2.43 2.74-1.56a6 6 0 012.73 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-69)"
                  />
                  <path
                    d="M659.66 554.07c0 .9-.63 1.26-1.42.81a3.15 3.15 0 01-1.43-2.46c0-.9.64-1.27 1.43-.81a3.11 3.11 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M658.49 550a2 2 0 00-1.87-.23 2.15 2.15 0 011.63.32 6 6 0 012.74 4.72 1.84 1.84 0 01-.87 1.79 1.75 1.75 0 001.11-1.88 6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-28)"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-70)"
                    d="M658.26 552.29v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                  />
                  <path
                    d="M726.36 589.22a1.91 1.91 0 01-.77 1.74l-3.15 1.81c-.5.28-.18-.35-.93-.78a6.06 6.06 0 01-2.74-4.73c0-.85-.79-.86-.3-1.15l3.24-1.82a1.94 1.94 0 011.91.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-379)"
                  />
                  <path
                    d="M723.14 591.05c0 1.74-1.23 2.44-2.74 1.56a6 6 0 01-2.74-4.72c0-1.73 1.23-2.43 2.74-1.56a6.06 6.06 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-71)"
                  />
                  <path
                    d="M721.71 590.34c0 .9-.64 1.26-1.43.81a3.17 3.17 0 01-1.42-2.46c0-.91.64-1.27 1.42-.81a3.12 3.12 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M720.53 586.27a2 2 0 00-1.87-.23 2.17 2.17 0 011.64.32 6 6 0 012.73 4.72 1.87 1.87 0 01-.86 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-29)"
                  />
                  <path
                    d="M733.75 593.51a1.91 1.91 0 01-.77 1.74l-3.15 1.81c-.49.28-.18-.35-.93-.78a6 6 0 01-2.74-4.72c0-.86-.79-.87-.3-1.15l3.23-1.82a1.93 1.93 0 011.92.2 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-380)"
                  />
                  <path
                    d="M730.53 595.35c0 1.73-1.23 2.43-2.74 1.56a6 6 0 01-2.73-4.72c0-1.74 1.22-2.44 2.73-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-72)"
                  />
                  <path
                    d="M729.1 594.63c0 .9-.64 1.27-1.43.81a3.14 3.14 0 01-1.42-2.46c0-.9.64-1.27 1.42-.81a3.12 3.12 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M727.92 590.56a2 2 0 00-1.87-.23 2.17 2.17 0 011.64.32 6 6 0 012.73 4.72 1.85 1.85 0 01-.86 1.79c.67-.18 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-30)"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-381)"
                    d="M736.74 592.78v2.97l-18.73-10.89-5.73-.91-45.95-26.72-5.49-5.61-13.22-7.69v-2.89l89.12 51.74z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-73)"
                    d="M736.73 592.78v2.97l24.44-13.91v-3.03l-24.44 13.97z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-74)"
                    d="M727.69 592.85v2.07l-7.56-4.29v-2.13l7.56 4.35z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-75)"
                    d="M761.17 578.81l-24.44 13.97-89.11-51.74 24.46-13.94 89.09 51.71z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-76)"
                    d="M753.13 589.97v1.7l-5.05-2.94v-1.69l5.05 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-77)"
                    d="M753.13 589.97v1.7l1.38-.79v-1.7l-1.38.79z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-78)"
                    d="M754.51 589.18l-1.38.79-5.05-2.93 1.39-.79 5.04 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-79)"
                    d="M753.56 590.17v2.39l-.92-.53v-2.38l.92.52z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-80)"
                    d="M753.56 590.17v2.39l1.94-1.1v-2.4l-1.94 1.11z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-81)"
                    d="M755.5 589.06l-1.94 1.11-.92-.52 1.95-1.11.91.52z"
                  />
                </g>
                <g>
                  <path
                    opacity={0.2}
                    d="M743.32 607.03l24.46-13.95 89.09 51.71-12.08 6.91 4.01 2.33-1.38.79-4.02-2.33-10.97 6.27-89.11-51.73z"
                  />
                  <path
                    d="M752.63 605.06a1.94 1.94 0 01-.77 1.74l-3.15 1.81c-.5.28-.18-.35-.93-.78a6 6 0 01-2.74-4.72c0-.86-.79-.87-.31-1.15l3.25-1.83a2 2 0 011.91.21 6.06 6.06 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-382)"
                  />
                  <path
                    d="M749.41 606.9c0 1.73-1.23 2.43-2.74 1.56a6 6 0 01-2.74-4.72c0-1.74 1.23-2.43 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-82)"
                  />
                  <path
                    d="M748 606.18c0 .9-.64 1.27-1.43.81a3.12 3.12 0 01-1.43-2.46c0-.9.64-1.27 1.43-.81a3.14 3.14 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M746.8 602.11a2 2 0 00-1.87-.23 2.15 2.15 0 011.63.32 6.06 6.06 0 012.74 4.73 1.87 1.87 0 01-.86 1.79 1.77 1.77 0 001.1-1.89 6.06 6.06 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-31)"
                  />
                  <path
                    d="M760 609.36a1.91 1.91 0 01-.77 1.74l-3.14 1.8c-.51.28-.19-.34-.94-.78a6 6 0 01-2.74-4.72c0-.86-.79-.86-.31-1.15l3.25-1.82a1.94 1.94 0 011.91.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-383)"
                  />
                  <path
                    d="M756.8 611.19c0 1.74-1.23 2.43-2.74 1.56a6 6 0 01-2.74-4.72c0-1.73 1.23-2.43 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-83)"
                  />
                  <path
                    d="M755.37 610.47c0 .91-.64 1.27-1.43.82a3.15 3.15 0 01-1.43-2.46c0-.91.64-1.27 1.43-.82a3.15 3.15 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M754.19 606.41a2 2 0 00-1.87-.23 2.1 2.1 0 011.63.32 6 6 0 012.74 4.72 1.87 1.87 0 01-.86 1.79 1.75 1.75 0 001.1-1.88 6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-32)"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-84)"
                    d="M753.96 608.7v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                  />
                  <path
                    d="M822.06 645.63a1.91 1.91 0 01-.77 1.73l-3.13 1.81c-.51.28-.2-.34-.95-.78a6 6 0 01-2.74-4.72c0-.86-.79-.86-.3-1.15l3.24-1.82a1.94 1.94 0 011.91.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-384)"
                  />
                  <path
                    d="M818.84 647.46c0 1.74-1.23 2.43-2.74 1.56a6 6 0 01-2.73-4.72c0-1.73 1.22-2.43 2.73-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-85)"
                  />
                  <path
                    d="M817.41 646.74c0 .91-.64 1.27-1.43.82a3.17 3.17 0 01-1.42-2.46c0-.91.64-1.27 1.42-.82a3.15 3.15 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M816.23 642.67a2 2 0 00-1.87-.23 2.18 2.18 0 011.64.33 6 6 0 012.73 4.72 1.87 1.87 0 01-.86 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.73z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-33)"
                  />
                  <path
                    d="M829.45 649.92a1.91 1.91 0 01-.77 1.74l-3.15 1.81c-.49.28-.18-.35-.93-.78a6 6 0 01-2.73-4.73c0-.85-.8-.86-.31-1.15l3.23-1.81a2 2 0 011.93.2 6 6 0 012.73 4.72z"
                    fill="url(#prefix__linear-gradient-385)"
                  />
                  <path
                    d="M826.23 651.75c0 1.74-1.23 2.44-2.74 1.56a6 6 0 01-2.73-4.72c0-1.73 1.22-2.43 2.73-1.56a6.06 6.06 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-86)"
                  />
                  <path
                    d="M824.8 651c0 .91-.64 1.27-1.43.82a3.17 3.17 0 01-1.42-2.46c0-.91.64-1.27 1.42-.82a3.15 3.15 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M823.62 647a2 2 0 00-1.87-.23 2.17 2.17 0 011.64.32 6 6 0 012.73 4.72 1.87 1.87 0 01-.86 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-34)"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-386)"
                    d="M832.44 649.19v2.97l-18.73-10.89-5.73-.92-45.95-26.72-5.49-5.61-13.22-7.69v-2.88l89.12 51.74z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-87)"
                    d="M832.43 649.19v2.97l24.44-13.91v-3.03l-24.44 13.97z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-88)"
                    d="M823.39 649.26v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-89)"
                    d="M856.87 635.22l-24.44 13.97-89.11-51.74 24.46-13.95 89.09 51.72z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-90)"
                    d="M848.83 646.37v1.7l-5.05-2.93v-1.7l5.05 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-91)"
                    d="M848.83 646.37v1.7l1.38-.78v-1.71l-1.38.79z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-92)"
                    d="M850.21 645.58l-1.38.79-5.05-2.93 1.39-.79 5.04 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-93)"
                    d="M849.26 646.58v2.39l-.92-.53v-2.38l.92.52z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-94)"
                    d="M849.26 646.58v2.39l1.95-1.11v-2.39l-1.95 1.11z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-95)"
                    d="M851.21 645.47l-1.95 1.11-.92-.52 1.95-1.11.92.52z"
                  />
                </g>
                <g>
                  <path
                    opacity={0.2}
                    d="M837.07 662.54l24.46-13.95 87.82 50.96-12.09 6.91 4.02 2.34-1.39.79-4.01-2.34-10.98 6.28-87.83-50.99z"
                  />
                  <path
                    d="M846.38 660.58a1.91 1.91 0 01-.77 1.73l-3.15 1.82c-.5.27-.18-.36-.93-.79a6 6 0 01-2.74-4.72c0-.86-.79-.86-.31-1.15l3.25-1.83a2 2 0 011.91.22 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-387)"
                  />
                  <path
                    d="M843.16 662.41c0 1.73-1.23 2.43-2.74 1.56a6 6 0 01-2.74-4.72c0-1.73 1.23-2.43 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-96)"
                  />
                  <path
                    d="M841.73 661.69c0 .9-.64 1.27-1.43.81a3.12 3.12 0 01-1.43-2.46c0-.9.64-1.26 1.43-.81a3.15 3.15 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M840.55 657.62a2 2 0 00-1.87-.23 2.16 2.16 0 011.63.33 6 6 0 012.74 4.72 1.87 1.87 0 01-.86 1.79 1.77 1.77 0 001.1-1.89 6.06 6.06 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-35)"
                  />
                  <path
                    d="M853.77 664.87a1.91 1.91 0 01-.77 1.74l-3.15 1.81c-.5.28-.18-.35-.93-.79a6 6 0 01-2.74-4.72c0-.85-.79-.86-.31-1.15l3.25-1.82a1.94 1.94 0 011.91.21 6 6 0 012.74 4.72z"
                    fill="url(#prefix__linear-gradient-388)"
                  />
                  <path
                    d="M850.55 666.7c0 1.74-1.23 2.44-2.74 1.56a6 6 0 01-2.74-4.72c0-1.73 1.23-2.43 2.74-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-97)"
                  />
                  <path
                    d="M849.12 666c0 .91-.64 1.27-1.43.82a3.15 3.15 0 01-1.43-2.46c0-.91.64-1.27 1.43-.82a3.15 3.15 0 011.43 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M847.94 661.92a2 2 0 00-1.87-.23 2.1 2.1 0 011.63.32 6 6 0 012.74 4.72 1.87 1.87 0 01-.86 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.74-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-36)"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-98)"
                    d="M847.71 664.21v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                  />
                  <path
                    d="M915.81 701.14a1.93 1.93 0 01-.76 1.74s-3.14 1.8-3.16 1.81c-.49.27-.18-.35-.93-.79a6 6 0 01-2.73-4.72c0-.86-.8-.86-.31-1.15 0 0 3.23-1.82 3.25-1.82a1.94 1.94 0 011.91.21 6 6 0 012.73 4.72z"
                    fill="url(#prefix__linear-gradient-389)"
                  />
                  <path
                    d="M912.59 703c0 1.74-1.22 2.44-2.74 1.56a6 6 0 01-2.73-4.72c0-1.73 1.22-2.43 2.73-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-99)"
                  />
                  <path
                    d="M911.16 702.25c0 .91-.64 1.27-1.42.82a3.15 3.15 0 01-1.43-2.46c0-.91.64-1.27 1.43-.82a3.17 3.17 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M910 698.19a2 2 0 00-1.88-.23 2.12 2.12 0 011.64.32 6 6 0 012.74 4.72 1.87 1.87 0 01-.87 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-37)"
                  />
                  <path
                    d="M923.2 705.43a1.93 1.93 0 01-.76 1.74l-3.16 1.83c-.49.28-.18-.35-.93-.78a6 6 0 01-2.73-4.72c0-.86-.8-.87-.31-1.16l3.23-1.81a2 2 0 011.93.2 6 6 0 012.73 4.7z"
                    fill="url(#prefix__linear-gradient-390)"
                  />
                  <path
                    d="M920 707.26c0 1.74-1.22 2.44-2.74 1.57a6 6 0 01-2.73-4.73c0-1.73 1.22-2.43 2.73-1.56a6 6 0 012.74 4.72z"
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-100)"
                  />
                  <path
                    d="M918.55 706.55c0 .9-.64 1.26-1.42.81a3.15 3.15 0 01-1.43-2.46c0-.9.64-1.27 1.43-.81a3.14 3.14 0 011.42 2.46z"
                    fill="#7d7d7d"
                  />
                  <path
                    d="M917.38 702.48a2 2 0 00-1.88-.23 2.16 2.16 0 011.64.32 6 6 0 012.74 4.72 1.86 1.86 0 01-.87 1.79c.67-.19 1.1-.85 1.1-1.88a6 6 0 00-2.73-4.72z"
                    style={{ mixBlendMode: "screen" }}
                    fill="url(#prefix__Radial_Gradient_1-38)"
                  />
                  <path
                    fill="url(#prefix__linear-gradient-391)"
                    d="M924.91 703.95v2.97l-17.45-10.14-5.73-.91-45.95-26.73-5.49-5.61-13.22-7.68v-2.89l87.84 50.99z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-101)"
                    d="M924.9 703.95v2.97l24.45-13.91v-3.03l-24.45 13.97z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-102)"
                    d="M924.9 706.67l1.66 5.74L951 698.5l-1.65-5.8-24.45 13.97z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-103)"
                    d="M917.14 704.77v2.06l-7.56-4.28v-2.13l7.56 4.35z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-104)"
                    d="M949.35 689.98l-24.45 13.97-87.83-50.99 24.46-13.95 87.82 50.97z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-105)"
                    d="M941.52 700.57v1.7l-5.04-2.94v-1.69l5.04 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-106)"
                    d="M941.52 700.57v1.7l1.39-.79v-1.7l-1.39.79z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-107)"
                    d="M942.91 699.78l-1.39.79-5.04-2.93 1.38-.79 5.05 2.93z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-108)"
                    d="M941.96 700.77v2.39l-.92-.53v-2.38l.92.52z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-109)"
                    d="M941.96 700.77v2.39l1.94-1.1v-2.4l-1.94 1.11z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-110)"
                    d="M943.9 699.66l-1.94 1.11-.92-.52 1.95-1.11.91.52z"
                  />
                </g>
              </motion.g>
              <g id="prefix__trucks">
                <g id="prefix__truck4">
                  <path
                    fill="#b4c4cc"
                    d="M336.58 446.05v11.82l2.05-1.14v-11.9l-2.05 1.22z"
                  />
                  <path
                    d="M353.8 461.4c0 2-.69 3.45-1.83 4.14 0 0-3.58 2.08-3.61 2.1-1.18.66-.43-.84-2.22-1.87a14.38 14.38 0 01-6.52-11.26c0-2-1.89-2.06-.73-2.74l3.83-2.13c1.18-.63 2.79-.52 4.56.51a14.38 14.38 0 016.52 11.25z"
                    fill="#474756"
                  />
                  <path
                    d="M350 463.55c0 4.13-2.92 5.8-6.52 3.72A14.39 14.39 0 01337 456c0-4.13 2.93-5.8 6.53-3.72a14.38 14.38 0 016.47 11.27z"
                    fill="#545466"
                  />
                  <path
                    d="M343.45 455.27c-2.33-1.35-4.22-.27-4.22 2.4a9.28 9.28 0 004.22 7.27c2.32 1.35 4.21.27 4.21-2.4a9.3 9.3 0 00-4.21-7.27z"
                    fill="url(#prefix__linear-gradient-392)"
                  />
                  <path
                    d="M345 464a9.3 9.3 0 01-4.22-7.27 3.61 3.61 0 01.5-2c-1.2.16-2 1.22-2 2.95a9.28 9.28 0 004.22 7.27c1.61.93 3 .7 3.72-.41A3.52 3.52 0 01345 464z"
                    fill="url(#prefix__linear-gradient-393)"
                  />
                  <path
                    d="M346.37 462.17c0 1.65-1.16 2.31-2.6 1.48a5.73 5.73 0 01-2.6-4.49c0-1.64 1.16-2.31 2.6-1.48a5.73 5.73 0 012.6 4.49z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="url(#prefix__linear-gradient-394)"
                  />
                  <path
                    d="M346.16 461.22c0 1.4-1 2-2.2 1.26a4.86 4.86 0 01-2.2-3.8c0-1.4 1-2 2.2-1.26a4.85 4.85 0 012.2 3.8z"
                    fill="url(#prefix__linear-gradient-395)"
                  />
                  <path
                    d="M345.56 461.64c0 1.39-1 2-2.2 1.25a4.83 4.83 0 01-2.2-3.8c0-1.39 1-2 2.2-1.25a4.83 4.83 0 012.2 3.8z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M353.56 461.38c0 2-.7 3.47-1.84 4.16l-3.48 2c-1.18.66-.42-.84-2.23-1.88a14.48 14.48 0 01-6.56-11.32c0-2.06-1.9-2.08-.74-2.76l3.7-2.05c1.19-.64 2.8-.52 4.59.51a14.47 14.47 0 016.56 11.34z"
                    fill="#4c4c5e"
                  />
                  <path
                    d="M349.92 463.44c0 4.16-2.94 5.84-6.56 3.75a14.46 14.46 0 01-6.57-11.32c0-4.16 2.94-5.84 6.57-3.75a14.5 14.5 0 016.56 11.32z"
                    fill="#474756"
                  />
                  <path
                    d="M348 462.36c0 3-2.09 4.15-4.67 2.66a10.28 10.28 0 01-4.68-8.06c0-3 2.09-4.16 4.68-2.67a10.3 10.3 0 014.67 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M348 462.36a4 4 0 01-.59 2.27 3 3 0 01-3.27-.2 9.62 9.62 0 01-4.37-7.53c0-2 1-3.13 2.5-3.08a5.08 5.08 0 011.06.47 10.3 10.3 0 014.67 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M340.68 453.71a3.27 3.27 0 00-1.35 3 10.33 10.33 0 004.68 8.07 3.8 3.8 0 002.67.58 3.3 3.3 0 01-3.32-.33 10.28 10.28 0 01-4.68-8.06c0-1.84.79-2.97 2-3.26z"
                    fill="#474756"
                  />
                  <path
                    d="M345.14 460.39c0 .77-.55 1.08-1.23.69a2.7 2.7 0 01-1.22-2.11c0-.77.55-1.09 1.22-.7a2.7 2.7 0 011.23 2.12z"
                    fill="url(#prefix__radial-gradient-2)"
                  />
                  <path
                    d="M343.41 455.64a1.36 1.36 0 00.61 1.05c.34.2.61 0 .61-.35a1.34 1.34 0 00-.61-1c-.33-.25-.61-.09-.61.3zm0 7.15a1.38 1.38 0 00.61 1.06c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1.05c-.33-.2-.61-.04-.61.34zm-1.16-7.27c-.24-.41-.63-.64-.86-.5s-.24.58 0 1 .62.64.86.5.24-.59 0-1zm3.55 8.1c.23.41.62.63.86.5s.24-.58 0-1-.63-.64-.86-.5-.24.59 0 1zm-5.51-6.2a1.38 1.38 0 00.61 1.05c.34.19.62 0 .62-.35a1.33 1.33 0 00-.62-1.05c-.33-.2-.61-.07-.61.35zm6.24 3.6a1.34 1.34 0 00.61 1.05c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1.05c-.34-.2-.61-.04-.61.33zm-5.14.05c.23.41.62.64.86.5s.24-.58 0-1-.63-.64-.86-.5-.24.59 0 1zm4.41-2.51c.23.41.62.63.86.5s.24-.58 0-1-.63-.64-.86-.5-.24.59 0 1z"
                    fill="#474756"
                  />
                  <path
                    d="M345.91 460.39a1.29 1.29 0 01-.5 1.15l-.77.45c-.32.18-.11-.23-.61-.52a4 4 0 01-1.81-3.12c0-.56-.52-.57-.2-.76l.81-.46a1.28 1.28 0 011.27.14 4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <path
                    d="M345.1 460.86c0 1.14-.8 1.61-1.8 1a4 4 0 01-1.81-3.12c0-1.15.81-1.61 1.81-1a4 4 0 011.8 3.12z"
                    fill="#545466"
                  />
                  <path
                    d="M367.8 469.65c0 2-.7 3.45-1.83 4.14l-3.62 2.09c-1.17.66-.42-.83-2.21-1.87a14.39 14.39 0 01-6.53-11.25c0-2.05-1.89-2.06-.73-2.75l3.84-2.12c1.18-.64 2.78-.52 4.55.5a14.37 14.37 0 016.53 11.26z"
                    fill="#474756"
                  />
                  <path
                    d="M364 471.79c0 4.14-2.92 5.8-6.52 3.72a14.36 14.36 0 01-6.48-11.25c0-4.14 2.92-5.8 6.53-3.72a14.38 14.38 0 016.47 11.25z"
                    fill="#545466"
                  />
                  <ellipse
                    cx={357.44}
                    cy={468.35}
                    rx={3.43}
                    ry={5.95}
                    transform="rotate(-30.31 357.485 468.388)"
                    fill="url(#prefix__linear-gradient-396)"
                  />
                  <path
                    d="M358.94 472.23a9.33 9.33 0 01-4.21-7.28 3.75 3.75 0 01.49-2c-1.19.17-2 1.22-2 3a9.3 9.3 0 004.21 7.27c1.61.93 3 .7 3.72-.41a3.61 3.61 0 01-2.21-.58z"
                    fill="url(#prefix__linear-gradient-397)"
                  />
                  <path
                    d="M360.37 470.41c0 1.65-1.17 2.32-2.6 1.49a5.74 5.74 0 01-2.61-4.49c0-1.65 1.17-2.31 2.61-1.48a5.75 5.75 0 012.6 4.48z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="url(#prefix__linear-gradient-398)"
                  />
                  <path
                    d="M360.16 469.47c0 1.39-1 2-2.21 1.25a4.86 4.86 0 01-2.2-3.8c0-1.39 1-1.95 2.2-1.25a4.84 4.84 0 012.21 3.8z"
                    fill="url(#prefix__linear-gradient-399)"
                  />
                  <path
                    d="M359.56 469.88c0 1.4-1 2-2.21 1.26a4.86 4.86 0 01-2.2-3.8c0-1.4 1-2 2.2-1.26a4.87 4.87 0 012.21 3.8z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M367.74 469.63c0 2-.7 3.48-1.84 4.17l-3.48 2c-1.19.66-.43-.84-2.23-1.88a14.5 14.5 0 01-6.56-11.32c0-2.06-1.9-2.08-.74-2.76l3.7-2.05c1.18-.64 2.8-.52 4.58.5a14.48 14.48 0 016.57 11.34z"
                    fill="#4c4c5e"
                  />
                  <path
                    d="M364.1 471.7c0 4.16-2.94 5.84-6.57 3.74a14.47 14.47 0 01-6.53-11.32c0-4.16 2.94-5.83 6.56-3.74a14.45 14.45 0 016.54 11.32z"
                    fill="#474756"
                  />
                  <path
                    d="M362.21 470.61c0 3-2.09 4.16-4.68 2.67a10.3 10.3 0 01-4.67-8.07c0-3 2.09-4.15 4.67-2.66a10.28 10.28 0 014.68 8.06z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M362.21 470.61a4 4 0 01-.59 2.27 3 3 0 01-3.27-.19 9.62 9.62 0 01-4.37-7.53c0-2 1-3.13 2.5-3.08a4.79 4.79 0 011 .47 10.28 10.28 0 014.73 8.06z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M354.86 462a3.27 3.27 0 00-1.35 3 10.31 10.31 0 004.68 8.06 3.73 3.73 0 002.67.58 3.29 3.29 0 01-3.33-.32 10.3 10.3 0 01-4.67-8.07c0-1.86.79-3.01 2-3.25z"
                    fill="#474756"
                  />
                  <path
                    d="M359.31 468.64c0 .78-.54 1.09-1.22.7a2.7 2.7 0 01-1.22-2.11c0-.78.55-1.09 1.22-.7a2.69 2.69 0 011.22 2.11z"
                    fill="url(#prefix__radial-gradient-3)"
                  />
                  <path
                    d="M357.59 463.9a1.35 1.35 0 00.61 1.05c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1.05c-.34-.2-.61-.04-.61.35zm0 7.15a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.34-.19-.61-.04-.61.35zm-1.16-7.27c-.24-.41-.63-.64-.87-.5s-.24.58 0 1 .63.63.87.5.24-.59 0-1zm3.54 8.1c.24.41.63.63.87.49s.24-.58 0-1-.63-.63-.87-.5-.24.59.03 1.01zm-5.5-6.21a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.34-.19-.61-.03-.61.35zm6.24 3.6a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1.05c-.32-.2-.61-.04-.61.34zm-5.15.06c.24.41.63.63.87.5s.24-.58 0-1-.63-.64-.87-.5-.24.59 0 1zm4.41-2.51c.24.41.63.63.87.49s.24-.58 0-1-.63-.63-.87-.5-.24.59.03 1.01z"
                    fill="#474756"
                  />
                  <path
                    d="M360.09 468.64a1.26 1.26 0 01-.51 1.15l-.76.46c-.33.18-.12-.23-.61-.52a4 4 0 01-1.81-3.12c0-.56-.53-.57-.2-.76l.81-.46a1.28 1.28 0 011.27.14 4 4 0 011.81 3.11z"
                    fill="#545466"
                  />
                  <path
                    d="M359.28 469.12c0 1.14-.81 1.6-1.81 1a4 4 0 01-1.8-3.12c0-1.15.81-1.61 1.8-1a4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <g>
                    <path
                      fill="#9aa0a6"
                      d="M392.31 479.25v6.23l-21.01-12.19v-6.21l21.01 12.17z"
                    />
                    <path
                      fill="#9aa0a6"
                      d="M392.3 479.25v6.23l5.91-3.36v-6.25l-5.91 3.38z"
                    />
                    <path
                      fill="url(#prefix__linear-gradient-400)"
                      d="M398.21 475.87l-5.91 3.38-21-12.17 5.9-3.37 21.01 12.16z"
                    />
                  </g>
                  <g>
                    <path
                      fill="#8c9399"
                      d="M332.43 444.02v7.93l-.77-.61v-7.92l.77.6zM332.43 444.02v7.93l18.9-10.62v-7.94l-18.9 10.63z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M351.33 433.39l-18.9 10.63-.77-.6 18.9-10.63.77.6z"
                    />
                  </g>
                  <path
                    fill="#c5d4dd"
                    d="M367.35 463.8v11.82l-2.1-1.22V465l-28.65-16.54v9.4l-2.1-1.21v-11.82l32.85 18.97z"
                  />
                  <path
                    fill="#b4c4cc"
                    d="M367.37 463.82v11.82l2.04-1.14v-11.9l-2.04 1.22z"
                  />
                  <g>
                    <path
                      fill="#b4c4cc"
                      d="M388.84 484.45l-1.07.63-17.44-10.05 1.09-.62 17.42 10.04z"
                    />
                    <path
                      fill="#b4c4cc"
                      d="M370.31 468.64v6.4l1.11-.62v-6.44l-1.11.66z"
                    />
                    <path
                      d="M368.73 466.88v9l20.63 12v-9.09zm1.6 2.78l17.42 10v5.38L370.33 475z"
                      fill="#c5d4dd"
                    />
                    <path
                      fill="#b4c4cc"
                      d="M389.35 478.76v9.1l1.35-.69v-9.15l-1.35.74zM390.7 478.02l-1.35.74-20.62-11.88 1.36-.72 20.61 11.86z"
                    />
                  </g>
                  <g>
                    <path
                      d="M409 493.63c0 2-.69 3.45-1.83 4.15l-3.61 2.09c-1.18.66-.43-.84-2.22-1.87a14.38 14.38 0 01-6.52-11.26c0-2-1.89-2.06-.74-2.74l3.84-2.12c1.18-.64 2.79-.52 4.56.5a14.38 14.38 0 016.52 11.25z"
                      fill="#474756"
                    />
                    <path
                      d="M405.25 495.78c0 4.13-2.92 5.8-6.52 3.72a14.37 14.37 0 01-6.53-11.26c0-4.13 2.92-5.8 6.53-3.72a14.38 14.38 0 016.52 11.26z"
                      fill="#545466"
                    />
                    <path
                      d="M398.68 487.5c-2.33-1.35-4.22-.27-4.22 2.4a9.32 9.32 0 004.22 7.28c2.32 1.34 4.21.26 4.21-2.41a9.3 9.3 0 00-4.21-7.27z"
                      fill="#b4c4cc"
                    />
                    <path
                      d="M400.79 493.87c0 1.39-1 2-2.2 1.25a4.84 4.84 0 01-2.21-3.8c0-1.39 1-1.95 2.21-1.25a4.86 4.86 0 012.2 3.8z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M408.77 493.51c0 2-.7 3.47-1.84 4.17l-3.47 2c-1.19.67-.43-.84-2.23-1.88a14.44 14.44 0 01-6.56-11.32c0-2.05-1.9-2.07-.74-2.76l3.7-2c1.18-.64 2.8-.53 4.58.5a14.44 14.44 0 016.56 11.29z"
                      fill="#4c4c5e"
                    />
                    <path
                      d="M405.13 495.58c0 4.16-2.93 5.83-6.56 3.74A14.47 14.47 0 01392 488c0-4.16 2.94-5.83 6.56-3.74a14.44 14.44 0 016.57 11.32z"
                      fill="#474756"
                    />
                    <path
                      d="M403.25 494.49c0 3-2.1 4.16-4.68 2.67a10.33 10.33 0 01-4.68-8.07c0-3 2.1-4.16 4.68-2.67a10.34 10.34 0 014.68 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M403.25 494.49a4 4 0 01-.59 2.27 3 3 0 01-3.28-.19A9.61 9.61 0 01395 489c0-2 1-3.13 2.49-3.09a5.39 5.39 0 011.06.47 10.34 10.34 0 014.7 8.11z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M395.9 485.85a3.24 3.24 0 00-1.35 3 10.28 10.28 0 004.68 8.06 3.73 3.73 0 002.67.58 3.29 3.29 0 01-3.33-.32 10.33 10.33 0 01-4.68-8.07c0-1.83.8-2.98 2.01-3.25z"
                      fill="#474756"
                    />
                    <path
                      d="M400.35 492.52c0 .78-.55 1.09-1.22.7a2.7 2.7 0 01-1.23-2.11c0-.78.55-1.09 1.23-.7a2.72 2.72 0 011.22 2.11z"
                      fill="url(#prefix__radial-gradient-4)"
                    />
                    <path
                      d="M398.63 487.77a1.35 1.35 0 00.61 1.06c.33.19.61 0 .61-.35a1.38 1.38 0 00-.61-1.06c-.34-.19-.61-.03-.61.35zm0 7.16a1.32 1.32 0 00.61 1.05c.33.2.61 0 .61-.34a1.38 1.38 0 00-.61-1.06c-.34-.19-.61-.04-.61.35zm-1.17-7.28c-.23-.41-.62-.63-.86-.49s-.24.58 0 1 .63.63.86.5.24-.59 0-1.01zm3.55 8.1c.24.42.63.64.86.5s.24-.58 0-1-.62-.63-.86-.5-.24.59-.01 1zm-5.5-6.2a1.32 1.32 0 00.61 1.05c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.34-.2-.61-.04-.61.35zm6.23 3.6a1.36 1.36 0 00.62 1.06.37.37 0 00.61-.35 1.38 1.38 0 00-.61-1.06c-.36-.19-.62-.04-.62.35zm-5.14.06c.24.41.63.63.86.5s.24-.58 0-1-.62-.64-.86-.5-.24.59 0 1zm4.41-2.52c.24.41.63.64.86.5s.24-.58 0-1-.62-.63-.86-.5-.24.59-.01 1z"
                      fill="#474756"
                    />
                    <path
                      d="M401.13 492.52a1.28 1.28 0 01-.51 1.15l-.76.46c-.33.18-.12-.23-.62-.52a4 4 0 01-1.8-3.12c0-.57-.53-.57-.21-.76l.82-.46a1.3 1.3 0 011.27.13 4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                    <path
                      d="M400.32 493c0 1.15-.81 1.61-1.81 1a4 4 0 01-1.81-3.11c0-1.15.81-1.61 1.81-1a4 4 0 011.81 3.11z"
                      fill="#545466"
                    />
                  </g>
                  <g>
                    <path
                      fill="#cfdee8"
                      d="M432.76 504v9.1l-27.22-15.72v-9.02L432.76 504z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e7f1f7"
                      d="M413.29 492.88v8.77l-.43-.24v-8.78l.43.25z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M432.75 504v9.1l21.5-12.24v-9.15L432.75 504z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M454.25 491.71L432.75 504l-27.21-15.64 21.51-12.27 27.2 15.62z"
                    />
                  </g>
                  <g fill="#545466">
                    <path d="M429.1 497.94v3.02l-97.99-56.98v-2.93l97.99 56.89z" />
                    <path d="M429.09 497.94v3.02l26.88-15.29v-3.1l-26.88 15.37z" />
                    <path d="M455.97 482.57l-26.88 15.37-97.98-56.89L358 425.71l97.97 56.86z" />
                  </g>
                  <g>
                    <path
                      fill="#cfdee8"
                      d="M429.1 465.08v33l-97.99-56.98v-32.91l97.99 56.89z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e6e7e8"
                      d="M426.78 466.42l-93.35-54.2v27.54l93.35 54.28v-27.62z"
                    />
                    <g fill="#dce8f1">
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M423.81 465.08l1.22.81v27.63l-1.22-.81v-27.63zM425.09 465.78l.47-.02v27.63l-.47.01v-27.62zM419.81 462.76l1.22.81v27.62l-1.22-.81v-27.62zM421.08 463.45l.47-.01v27.62l-.47.02v-27.63zM415.8 460.43l1.22.81v27.63l-1.22-.81v-27.63zM417.08 461.13l.46-.02v27.63l-.46.01v-27.62zM411.8 458.11l1.22.8v27.63l-1.22-.81v-27.62zM413.07 458.8l.47-.02v27.63l-.47.02V458.8zM407.79 455.78l1.22.81v27.62l-1.22-.81v-27.62zM409.07 456.48l.46-.03v27.63l-.46.02v-27.62zM403.79 453.45l1.22.81v27.63l-1.22-.81v-27.63zM405.06 454.15l.47-.02v27.62l-.47.02v-27.62zM399.78 451.13l1.22.8v27.63l-1.22-.81v-27.62zM401.06 451.82l.46-.02v27.63l-.46.02v-27.63zM395.77 448.8l1.23.81v27.62l-1.23-.81V448.8zM397.05 449.5l.47-.02v27.62l-.47.02V449.5zM391.77 446.47l1.22.81v27.63l-1.22-.81v-27.63zM393.05 447.17l.46-.02v27.62l-.46.02v-27.62zM387.76 444.15l1.23.8v27.63l-1.23-.81v-27.62zM389.04 444.84l.47-.02v27.63l-.47.02v-27.63zM383.76 441.82l1.22.81v27.62l-1.22-.81v-27.62zM385.04 442.52l.46-.02v27.62l-.46.02v-27.62zM379.75 439.49l1.22.81v27.63l-1.22-.81v-27.63zM381.03 440.19l.47-.02v27.63l-.47.01v-27.62z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M375.75 437.17l1.22.81v27.62l-1.22-.81v-27.62zM377.02 437.86l.47-.02v27.63l-.47.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M371.74 434.84l1.22.81v27.62l-1.22-.81v-27.62zM373.02 435.54l.46-.02v27.62l-.46.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M367.74 432.51l1.22.81v27.63l-1.22-.81v-27.63zM369.01 433.21l.47-.02v27.63l-.47.01v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M363.73 430.19l1.22.81v27.62l-1.22-.81v-27.62zM365.01 430.88l.46-.02v27.63l-.46.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M359.73 427.86l1.22.81v27.63l-1.22-.82v-27.62zM361 428.56l.47-.02v27.62l-.47.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M355.72 425.53l1.22.81v27.63l-1.22-.81v-27.63zM357 426.23l.46-.02v27.63l-.46.01v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M351.71 423.21l1.23.81v27.62l-1.23-.81v-27.62zM352.99 423.9l.47-.02v27.63l-.47.02V423.9z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M347.71 420.88l1.22.81v27.62l-1.22-.81v-27.62zM348.99 421.58l.46-.02v27.62l-.46.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M343.7 418.55l1.23.81v27.63l-1.23-.81v-27.63zM344.98 419.25l.47-.02v27.63l-.47.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M339.7 416.23l1.22.81v27.62l-1.22-.81v-27.62zM340.98 416.93l.46-.03v27.63l-.46.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M335.69 413.9l1.22.81v27.63l-1.22-.82V413.9zM336.97 414.6l.47-.02v27.62l-.47.02V414.6z"
                        />
                      </g>
                    </g>
                    <path
                      fill="#dce8f1"
                      d="M429.09 465.08v33l26.88-15.29v-33.08l-26.88 15.37z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e6e7e8"
                      d="M453.65 481.44v-27.72l-22.24 12.71v27.66l22.24-12.65z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M455.97 449.71l-26.88 15.37-97.98-56.89L358 392.85l97.97 56.86z"
                    />
                    <g fill="#dce8f1">
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M433.67 465.58l-1.22.81v27.62l1.22-.81v-27.62zM432.39 466.28l-.46-.02v27.62l.46.02v-27.62zM437.29 463.45l-1.22.81v27.63l1.22-.81v-27.63zM436.01 464.15l-.46-.02v27.62l.46.02v-27.62zM440.91 461.32l-1.22.81v27.63l1.22-.81v-27.63zM439.63 462.02l-.46-.02v27.63l.46.01v-27.62z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M444.53 459.2l-1.22.8v27.63l1.22-.81V459.2zM443.25 459.89l-.46-.02v27.63l.46.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M448.15 457.07l-1.22.81v27.62l1.22-.81v-27.62zM446.87 457.77l-.46-.02v27.62l.46.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M451.77 454.94l-1.22.81v27.63l1.22-.82v-27.62zM450.49 455.64l-.46-.02v27.62l.46.02v-27.62z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <path
                      d="M455.86 462.49l17.67 10.29a9.2 9.2 0 014.35 5.7l2.48 9.8a36.6 36.6 0 011.12 9v11.48L456.28 523v-10.2a36.18 36.18 0 00-1.7-11l-3.21-10.18a9.13 9.13 0 00-4.2-5.19l-16.53-9.64z"
                      fill="red"
                    />
                    <path
                      d="M456.29 512.73V523l-13.5-7.74-1-5.95-11.12-6.62v-25.9l16.59 9.67a9.14 9.14 0 014.21 5.22l3.18 10.16a36.56 36.56 0 011.64 10.89z"
                      fill="#e50000"
                    />
                    <path
                      d="M449.91 492.16l3.18 10.17a.8.8 0 01-1.17.93l-10-5.82a2.17 2.17 0 01-1.07-1.86v-8.34a.81.81 0 011.22-.7l6.19 3.62a3.49 3.49 0 011.63 2z"
                      fill="#26262e"
                    />
                    <path
                      d="M452.32 503.09a.55.55 0 00.42-.22.49.49 0 00.07-.46l-3.18-10.16a3.29 3.29 0 00-1.5-1.85l-6.2-3.61h-.19c-.39 0-.59.19-.59.47v8.34a1.87 1.87 0 00.93 1.61l10 5.83z"
                      fill="#616173"
                    />
                    <path
                      d="M460.57 504.75v3.77a5.21 5.21 0 007.8 4.52L472 511a12.7 12.7 0 006.4-11v-5.44z"
                      fill="#e50000"
                    />
                    <path
                      d="M465.79 512.34a3.75 3.75 0 001.89-.51l3.61-2.06A11.33 11.33 0 00477 500v-3l-15 8.64v3a3.83 3.83 0 003.79 3.7z"
                      fill="#616173"
                    />
                    <path
                      d="M475.76 505.06L464 511.85a3.94 3.94 0 00.73.32l10.44-6a12.27 12.27 0 00.59-1.11zm1-2.9l-14.32 8.23a3.87 3.87 0 00.37.53l13.75-7.92c.08-.27.15-.55.21-.84zm.22-2.45l-15 8.64v.17a3.19 3.19 0 000 .54l15-8.61v-.5zM462 506v.74l15-8.64v-.73z"
                      fill="#474756"
                      opacity={0.5}
                    />
                    <path
                      d="M457.16 503.38a1.45 1.45 0 00.73-.19l20.45-11.6a2.5 2.5 0 001.19-2.79L477 478.71a9.1 9.1 0 00-.55-1.51.92.92 0 00-.82-.52.88.88 0 00-.44.12l-22.28 12.78a1.47 1.47 0 00-.64 1.73l3.48 11a1.49 1.49 0 001.43 1z"
                      fill="#26262e"
                    />
                    <path
                      d="M457.16 503.09a1.2 1.2 0 00.59-.15l20.45-11.6a2.22 2.22 0 001.05-2.47l-2.55-10.09a8.1 8.1 0 00-.53-1.45.62.62 0 00-.56-.36.6.6 0 00-.29.08L453 489.84a1.17 1.17 0 00-.52 1.39l3.48 11a1.22 1.22 0 001.2.86z"
                      fill="#616173"
                    />
                    <path
                      d="M434.45 483.08l3 1.74a1.39 1.39 0 01.68 1.19v7.9a.16.16 0 01-.16.17l-2.86-1.65a1.29 1.29 0 01-.64-1.11v-8.24z"
                      fill="#26262e"
                    />
                    <path
                      d="M437.83 486a1.09 1.09 0 00-.54-.94l-2.55-1.49v7.74a1 1 0 00.49.86l2.6 1.49z"
                      fill="#616173"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#prefix__linear-gradient-401)"
                      d="M441.6 501.95l-10.96.72 11.09 6.55-.13-7.27z"
                    />
                  </g>
                  <path
                    fill="#b4c4cc"
                    d="M433.98 507.54v4.99l3.8-2.13-.25-4.98-3.55 2.12z"
                  />
                  <g>
                    <path
                      d="M450.91 517.88c0 2-.7 3.47-1.84 4.17l-3.48 2c-1.18.66-.42-.85-2.23-1.89a14.47 14.47 0 01-6.56-11.32c0-2.05-1.9-2.07-.74-2.76 0 0 3.67-2 3.7-2 1.19-.64 2.8-.53 4.59.5a14.48 14.48 0 016.56 11.3z"
                      fill="#4c4c5e"
                    />
                    <path
                      d="M447.27 520c0 4.16-2.94 5.84-6.56 3.74a14.45 14.45 0 01-6.57-11.32c0-4.16 2.94-5.83 6.57-3.74a14.47 14.47 0 016.56 11.32z"
                      fill="#474756"
                    />
                    <path
                      d="M445.38 518.86c0 3-2.09 4.16-4.67 2.67a10.28 10.28 0 01-4.68-8.07c0-3 2.09-4.16 4.68-2.66a10.3 10.3 0 014.67 8.06z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M445.38 518.86a4 4 0 01-.59 2.27 3 3 0 01-3.27-.19 9.62 9.62 0 01-4.37-7.53c0-2 1-3.13 2.5-3.09a5.74 5.74 0 011.06.48 10.3 10.3 0 014.67 8.06z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M438 510.22a3.26 3.26 0 00-1.35 3 10.31 10.31 0 004.68 8.06 3.75 3.75 0 002.67.58 3.27 3.27 0 01-3.32-.32 10.28 10.28 0 01-4.68-8.07c0-1.83.82-2.98 2-3.25z"
                      fill="#474756"
                    />
                    <path
                      d="M442.49 516.89c0 .78-.55 1.09-1.23.7a2.72 2.72 0 01-1.22-2.11c0-.78.55-1.09 1.22-.7a2.7 2.7 0 011.23 2.11z"
                      fill="url(#prefix__radial-gradient-5)"
                    />
                    <path
                      d="M440.76 512.14a1.38 1.38 0 00.61 1.06c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1.05c-.37-.2-.61-.04-.61.34zm0 7.16a1.35 1.35 0 00.61 1.05c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.37-.19-.61-.04-.61.35zm-1.16-7.3c-.24-.42-.63-.64-.86-.5s-.24.58 0 1 .62.63.86.5.24-.56 0-1zm3.55 8.09c.23.42.62.64.86.5s.24-.58 0-1-.63-.63-.86-.5-.24.62 0 1.03zm-5.51-6.2a1.38 1.38 0 00.61 1.06c.34.19.62 0 .62-.35a1.36 1.36 0 00-.62-1.06.37.37 0 00-.61.38zm6.24 3.6a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.34-.14-.61 0-.61.38zm-5.14.06c.23.41.62.63.86.5s.24-.58 0-1-.63-.64-.86-.5-.24.62 0 1.03zm4.41-2.52c.23.42.62.64.86.5s.24-.58 0-1-.63-.63-.86-.5-.24.62 0 1.03z"
                      fill="#474756"
                    />
                    <path
                      d="M443.26 516.89a1.28 1.28 0 01-.5 1.15l-.77.46c-.32.18-.11-.23-.61-.52a4 4 0 01-1.81-3.12c0-.57-.52-.57-.2-.76l.81-.46a1.3 1.3 0 011.27.13 4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                    <path
                      d="M442.45 517.36c0 1.15-.8 1.61-1.8 1a4 4 0 01-1.81-3.12c0-1.15.81-1.61 1.81-1a4 4 0 011.8 3.12z"
                      fill="#545466"
                    />
                  </g>
                  <path
                    fill="#e7f1f7"
                    d="M448.93 523.39v-7.22l-4.71-8.12-6.62-3.82 4-2.28 6.65 3.84 4.7 8.11v7.23l-4.02 2.26z"
                  />
                  <path
                    fill="#dce8f1"
                    d="M448.93 516.17l-4.71-8.12 4.03-2.26 4.7 8.11-4.02 2.27z"
                  />
                  <path
                    fill="#cfdee8"
                    d="M447.82 522.79v-6.33l-4.41-7.59-5.84-3.38-3.59 2.05v4.99l-1.11-.64v-5l4.7-2.68 6.65 3.84 4.71 8.12v7.26l-1.11-.64z"
                  />
                  <g>
                    <path
                      d="M483.37 506.29l-25.21 15a4 4 0 01-4 0l-4.72-2.64v4.29l4.75 2.68a4.05 4.05 0 004 0l25.15-14.45z"
                      fill="#cfdee8"
                    />
                    <path
                      d="M482.94 507.1l-24.78 14.15a4 4 0 01-4 0l-4.72-2.64 1.29-.74 3.41 1.93a4 4 0 004 0l23.27-13.43v-1.71l1.52.93a.88.88 0 01.01 1.51z"
                      fill="#e7f1f7"
                    />
                    <g style={{ mixBlendMode: "color-dodge" }}>
                      <path
                        fill="url(#prefix__radial-gradient-6)"
                        d="M481.49 506.08l-.03.02-.01-1.44.04.02v1.4z"
                      />
                      <path
                        d="M449.75 518.46l4.47 2.5a4 4 0 004 0L483 506.81a.84.84 0 00.41-.52.85.85 0 01-.44.81l-24.78 14.15a4 4 0 01-4 0l-4.72-2.64z"
                        fill="url(#prefix__radial-gradient-7)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M430.6 469.14l24.8-14.22 9.35 5.61a13.16 13.16 0 016.53 11.36L446.5 486z"
                      fill="#ff4a4a"
                    />
                    <path
                      d="M446.53 485.94l-15.95-9.36v-7.48l9.42 5.48a13.15 13.15 0 016.53 11.36z"
                      fill="#e50000"
                    />
                  </g>
                  <path
                    d="M461.94 515l-4.7 2.65a.66.66 0 01-1-.57V515a1.77 1.77 0 01.95-1.56l1.47-.78a1.78 1.78 0 011.87.14l1.47 1.1a.66.66 0 01-.06 1.1zM481.49 502.47v1.71a.63.63 0 01-.34.55l-2 1a.48.48 0 01-.67-.59l.82-2.23a1.33 1.33 0 01.67-.73l.67-.32a.63.63 0 01.85.61z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e7f1f7"
                  />
                  <path
                    d="M461.94 514.65l-4.7 2.66a.66.66 0 01-1-.58v-2.05a1.78 1.78 0 01.95-1.56l1.47-.78a1.78 1.78 0 011.87.14l1.46 1.07a.66.66 0 01-.05 1.1zM481.49 502.2v1.7a.64.64 0 01-.34.56l-2 1.05a.48.48 0 01-.67-.59l.82-2.23a1.33 1.33 0 01.67-.73l.67-.32a.63.63 0 01.85.56z"
                    fill="#ffc950"
                  />
                  <path
                    d="M461.54 520.21l-3.53 2 1 .69a1.58 1.58 0 001.71.05l3.64-2.12-1.32-.68a1.63 1.63 0 00-1.5.06zM482.51 508.39l-3.79 2.18a1.58 1.58 0 00-.7.81l-.91 2.33 4-2.33a1.63 1.63 0 00.71-.88zM476.28 515.16v-.8a1.1 1.1 0 00-1.66-1l-7.24 4.16a3.65 3.65 0 00-1.82 3.14v.63z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e7f1f7"
                  />
                  <path
                    fill="#e7f1f7"
                    d="M467.6 518.2v1.94l7-3.95v-1.99l-7 4z"
                  />
                  <path
                    d="M469.68 511.74c-5.38 2.86-9.28 6-8.7 7.13s5.39-.37 10.77-3.23 9.28-6.05 8.71-7.13-5.4.37-10.78 3.23z"
                    style={{ mixBlendMode: "color-dodge" }}
                    fill="url(#prefix__radial-gradient-8)"
                  />
                </g>
                <g id="prefix__truck3">
                  <path
                    fill="#b4c4cc"
                    d="M532 555.36v11.82l2.04-1.15v-11.89l-2.04 1.22z"
                  />
                  <path
                    d="M549.21 570.71c0 2-.69 3.45-1.83 4.14 0 0-3.58 2.08-3.61 2.1-1.18.66-.43-.84-2.22-1.87a14.38 14.38 0 01-6.55-11.26c0-2-1.89-2.06-.73-2.74l3.83-2.13c1.18-.63 2.79-.52 4.56.51a14.38 14.38 0 016.55 11.25z"
                    fill="#474756"
                  />
                  <path
                    d="M545.43 572.86c0 4.13-2.92 5.8-6.52 3.72a14.39 14.39 0 01-6.53-11.26c0-4.13 2.93-5.8 6.53-3.72a14.38 14.38 0 016.52 11.26z"
                    fill="#545466"
                  />
                  <path
                    d="M538.86 564.58c-2.33-1.35-4.22-.27-4.22 2.4a9.28 9.28 0 004.22 7.27c2.32 1.35 4.21.27 4.21-2.4a9.3 9.3 0 00-4.21-7.27z"
                    fill="url(#prefix__linear-gradient-402)"
                  />
                  <path
                    d="M540.36 573.29a9.3 9.3 0 01-4.22-7.27 3.61 3.61 0 01.5-2c-1.2.16-2 1.22-2 3a9.28 9.28 0 004.22 7.27c1.61.93 3 .7 3.72-.41a3.52 3.52 0 01-2.22-.59z"
                    fill="url(#prefix__linear-gradient-403)"
                  />
                  <path
                    d="M541.78 571.48c0 1.65-1.16 2.31-2.6 1.48a5.73 5.73 0 01-2.6-4.49c0-1.64 1.16-2.31 2.6-1.48a5.73 5.73 0 012.6 4.49z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="url(#prefix__linear-gradient-404)"
                  />
                  <path
                    d="M541.57 570.53c0 1.4-1 2-2.2 1.26a4.85 4.85 0 01-2.2-3.8c0-1.4 1-2 2.2-1.26a4.85 4.85 0 012.2 3.8z"
                    fill="url(#prefix__linear-gradient-405)"
                  />
                  <path
                    d="M541 571c0 1.39-1 2-2.2 1.25a4.83 4.83 0 01-2.2-3.8c0-1.39 1-2 2.2-1.25a4.83 4.83 0 012.2 3.8z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M549 570.69c0 2-.7 3.47-1.84 4.16l-3.48 2c-1.18.66-.42-.84-2.22-1.88a14.46 14.46 0 01-6.57-11.32c0-2.06-1.9-2.08-.73-2.76l3.69-2c1.19-.64 2.8-.52 4.59.51a14.47 14.47 0 016.56 11.29z"
                    fill="#4c4c5e"
                  />
                  <path
                    d="M545.33 572.75c0 4.16-2.94 5.84-6.56 3.75a14.46 14.46 0 01-6.57-11.32c0-4.16 2.94-5.84 6.57-3.75a14.5 14.5 0 016.56 11.32z"
                    fill="#474756"
                  />
                  <path
                    d="M543.44 571.67c0 3-2.09 4.15-4.67 2.66a10.28 10.28 0 01-4.68-8.06c0-3 2.09-4.16 4.68-2.67a10.3 10.3 0 014.67 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M543.44 571.67a4 4 0 01-.59 2.27 3 3 0 01-3.27-.2 9.62 9.62 0 01-4.37-7.53c0-2 1-3.13 2.5-3.08a5.08 5.08 0 011.06.47 10.3 10.3 0 014.67 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M536.09 563a3.26 3.26 0 00-1.34 3 10.32 10.32 0 004.67 8.07 3.8 3.8 0 002.67.58 3.3 3.3 0 01-3.32-.33 10.28 10.28 0 01-4.68-8.06c0-1.82.79-2.97 2-3.26z"
                    fill="#474756"
                  />
                  <path
                    d="M540.55 569.7c0 .77-.55 1.08-1.23.69a2.7 2.7 0 01-1.22-2.11c0-.77.55-1.09 1.22-.7a2.7 2.7 0 011.23 2.12z"
                    fill="url(#prefix__radial-gradient-9)"
                  />
                  <path
                    d="M538.82 565a1.36 1.36 0 00.61 1c.34.2.61 0 .61-.35a1.34 1.34 0 00-.61-1c-.33-.25-.61-.09-.61.35zm0 7.15a1.38 1.38 0 00.61 1.06c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1c-.33-.3-.61-.14-.61.24zm-1.16-7.27c-.24-.41-.63-.64-.86-.5s-.24.58 0 1 .62.64.86.5.24-.64 0-1.05zm3.55 8.1c.23.41.62.63.86.5s.24-.58 0-1-.63-.64-.86-.5-.21.54 0 .95zm-5.51-6.2a1.38 1.38 0 00.61 1c.34.19.62 0 .62-.35a1.33 1.33 0 00-.62-1c-.31-.25-.61-.09-.61.3zm6.24 3.6a1.34 1.34 0 00.61 1c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1c-.34-.25-.61-.09-.61.3zm-5.14 0c.23.41.62.64.86.5s.24-.58 0-1-.63-.64-.86-.5-.24.62 0 1zm4.41-2.51c.23.41.62.63.86.5s.24-.58 0-1-.63-.64-.86-.5-.21.59 0 1z"
                    fill="#474756"
                  />
                  <path
                    d="M541.32 569.7a1.29 1.29 0 01-.5 1.15l-.77.45c-.32.18-.11-.23-.61-.52a4 4 0 01-1.81-3.12c0-.56-.52-.57-.2-.76l.81-.46a1.28 1.28 0 011.27.14 4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <path
                    d="M540.52 570.17c0 1.14-.81 1.61-1.81 1a4 4 0 01-1.81-3.12c0-1.15.81-1.61 1.81-1a4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <path
                    d="M563.21 579c0 2-.7 3.45-1.83 4.14l-3.62 2.09c-1.17.66-.42-.83-2.21-1.87a14.39 14.39 0 01-6.55-11.29c0-2.05-1.89-2.06-.73-2.75l3.84-2.12c1.18-.64 2.78-.52 4.55.5a14.37 14.37 0 016.55 11.3z"
                    fill="#474756"
                  />
                  <path
                    d="M559.43 581.1c0 4.14-2.92 5.8-6.52 3.72a14.36 14.36 0 01-6.53-11.25c0-4.14 2.92-5.8 6.53-3.72a14.38 14.38 0 016.52 11.25z"
                    fill="#545466"
                  />
                  <ellipse
                    cx={552.85}
                    cy={577.66}
                    rx={3.43}
                    ry={5.95}
                    transform="rotate(-30.31 552.914 577.693)"
                    fill="url(#prefix__linear-gradient-406)"
                  />
                  <path
                    d="M554.35 581.54a9.33 9.33 0 01-4.21-7.28 3.75 3.75 0 01.49-2c-1.19.17-2 1.22-2 3a9.3 9.3 0 004.21 7.27c1.61.93 3 .7 3.72-.41a3.61 3.61 0 01-2.21-.58z"
                    fill="url(#prefix__linear-gradient-407)"
                  />
                  <path
                    d="M555.78 579.72c0 1.65-1.17 2.32-2.6 1.49a5.73 5.73 0 01-2.6-4.49c0-1.65 1.16-2.31 2.6-1.48a5.75 5.75 0 012.6 4.48z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="url(#prefix__linear-gradient-408)"
                  />
                  <path
                    d="M555.57 578.78c0 1.39-1 2-2.21 1.25a4.86 4.86 0 01-2.2-3.8c0-1.39 1-2 2.2-1.25a4.84 4.84 0 012.21 3.8z"
                    fill="url(#prefix__linear-gradient-409)"
                  />
                  <path
                    d="M555 579.19c0 1.4-1 2-2.21 1.26a4.86 4.86 0 01-2.2-3.8c0-1.4 1-2 2.2-1.26a4.87 4.87 0 012.21 3.8z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M563.15 578.94c0 2-.7 3.48-1.84 4.17l-3.48 2c-1.18.66-.43-.84-2.23-1.88a14.5 14.5 0 01-6.6-11.3c0-2.06-1.9-2.08-.74-2.76l3.7-2c1.18-.64 2.8-.52 4.58.5a14.48 14.48 0 016.61 11.27z"
                    fill="#4c4c5e"
                  />
                  <path
                    d="M559.51 581c0 4.16-2.94 5.84-6.57 3.74a14.47 14.47 0 01-6.56-11.32c0-4.16 2.94-5.83 6.56-3.74a14.45 14.45 0 016.57 11.32z"
                    fill="#474756"
                  />
                  <path
                    d="M557.62 579.92c0 3-2.09 4.16-4.68 2.67a10.3 10.3 0 01-4.67-8.07c0-3 2.09-4.15 4.67-2.66a10.28 10.28 0 014.68 8.06z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M557.62 579.92a4 4 0 01-.59 2.27 3 3 0 01-3.27-.19 9.62 9.62 0 01-4.37-7.53c0-2 1-3.13 2.5-3.08a4.79 4.79 0 011.05.47 10.28 10.28 0 014.68 8.06z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M550.27 571.28a3.27 3.27 0 00-1.35 3 10.31 10.31 0 004.68 8.06 3.73 3.73 0 002.67.58 3.29 3.29 0 01-3.33-.32 10.3 10.3 0 01-4.67-8.07c0-1.83.79-2.98 2-3.25z"
                    fill="#474756"
                  />
                  <path
                    d="M554.73 578c0 .78-.55 1.09-1.23.7a2.7 2.7 0 01-1.22-2.11c0-.78.55-1.09 1.22-.7a2.7 2.7 0 011.23 2.11z"
                    fill="url(#prefix__radial-gradient-10)"
                  />
                  <path
                    d="M553 573.21a1.35 1.35 0 00.61 1c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1c-.34-.2-.61-.04-.61.35zm0 7.15a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.34-.19-.61-.01-.61.35zm-1.16-7.27c-.24-.41-.63-.64-.87-.5s-.24.58 0 1 .63.63.87.5.24-.59 0-1zm3.54 8.1c.24.41.63.63.87.49s.24-.58 0-1-.63-.63-.87-.5-.24.59 0 1.01zm-5.5-6.21a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.34-.19-.61-.03-.61.37zm6.24 3.6a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1c-.34-.29-.61-.09-.61.29zm-5.15.06c.24.41.63.63.87.5s.24-.58 0-1-.63-.64-.87-.5-.24.59.03 1zm4.41-2.51c.24.41.63.63.87.49s.24-.58 0-1-.63-.63-.87-.5-.24.59 0 1.01z"
                    fill="#474756"
                  />
                  <path
                    d="M555.5 578a1.26 1.26 0 01-.51 1.15l-.76.46c-.33.18-.12-.23-.61-.52a4 4 0 01-1.81-3.12c0-.56-.53-.57-.2-.76l.81-.46a1.28 1.28 0 011.27.14 4 4 0 011.81 3.11z"
                    fill="#545466"
                  />
                  <path
                    d="M554.69 578.43c0 1.14-.81 1.6-1.81 1a4 4 0 01-1.8-3.12c0-1.15.81-1.61 1.8-1a4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <g>
                    <path
                      fill="#9aa0a6"
                      d="M587.72 588.56v6.22l-21.01-12.18v-6.21l21.01 12.17zM587.72 588.56v6.22l5.9-3.35v-6.25l-5.9 3.38z"
                    />
                    <path
                      fill="url(#prefix__linear-gradient-410)"
                      d="M593.62 585.18l-5.9 3.38-21.01-12.17 5.9-3.37 21.01 12.16z"
                    />
                  </g>
                  <g>
                    <path
                      fill="#8c9399"
                      d="M527.84 553.33v7.93l-.77-.61v-7.92l.77.6zM527.84 553.33v7.93l18.91-10.62v-7.94l-18.91 10.63z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M546.75 542.7l-18.91 10.63-.77-.6 18.9-10.63.78.6z"
                    />
                  </g>
                  <path
                    fill="#c5d4dd"
                    d="M562.76 573.11v11.82l-2.11-1.22v-9.4l-28.64-16.54v9.4l-2.1-1.21v-11.82l32.85 18.97z"
                  />
                  <path
                    fill="#b4c4cc"
                    d="M562.78 573.13v11.82l2.04-1.14v-11.9l-2.04 1.22z"
                  />
                  <g>
                    <path
                      fill="#b4c4cc"
                      d="M584.25 593.76l-1.07.63-17.44-10.05 1.09-.62 17.42 10.04z"
                    />
                    <path
                      fill="#b4c4cc"
                      d="M565.72 577.95v6.4l1.11-.62v-6.44l-1.11.66z"
                    />
                    <path
                      d="M564.14 576.19v9l20.63 12v-9.09zm1.6 2.78l17.42 10v5.38l-17.42-10.1z"
                      fill="#c5d4dd"
                    />
                    <path
                      fill="#b4c4cc"
                      d="M584.76 588.08v9.09l1.35-.69v-9.15l-1.35.75z"
                    />
                    <path
                      fill="#b4c4cc"
                      d="M586.11 587.33l-1.35.75-20.62-11.89 1.36-.72 20.61 11.86z"
                    />
                  </g>
                  <g>
                    <path
                      d="M604.44 602.94c0 2-.69 3.45-1.83 4.15l-3.61 2.09c-1.18.66-.43-.84-2.22-1.87a14.38 14.38 0 01-6.52-11.26c0-2-1.89-2.06-.74-2.74l3.84-2.12c1.18-.64 2.79-.52 4.56.5a14.38 14.38 0 016.52 11.25z"
                      fill="#474756"
                    />
                    <path
                      d="M600.66 605.09c0 4.13-2.92 5.8-6.52 3.72a14.39 14.39 0 01-6.53-11.26c0-4.13 2.93-5.8 6.53-3.72a14.38 14.38 0 016.52 11.26z"
                      fill="#545466"
                    />
                    <path
                      d="M594.09 596.81c-2.33-1.34-4.22-.27-4.22 2.4a9.32 9.32 0 004.22 7.28c2.32 1.34 4.21.26 4.21-2.41a9.3 9.3 0 00-4.21-7.27z"
                      fill="#b4c4cc"
                    />
                    <path
                      d="M596.2 603.18c0 1.4-1 2-2.2 1.25a4.84 4.84 0 01-2.21-3.8c0-1.39 1-2 2.21-1.25a4.86 4.86 0 012.2 3.8z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M604.18 602.82c0 2-.7 3.47-1.84 4.17l-3.47 2c-1.19.67-.43-.84-2.23-1.88a14.47 14.47 0 01-6.56-11.32c0-2-1.9-2.07-.74-2.76l3.7-2c1.18-.64 2.8-.53 4.58.5a14.44 14.44 0 016.56 11.29z"
                      fill="#4c4c5e"
                    />
                    <path
                      d="M600.54 604.89c0 4.16-2.93 5.83-6.56 3.74a14.47 14.47 0 01-6.56-11.32c0-4.16 2.94-5.83 6.56-3.74a14.44 14.44 0 016.56 11.32z"
                      fill="#474756"
                    />
                    <path
                      d="M598.66 603.8c0 3-2.1 4.16-4.68 2.67a10.33 10.33 0 01-4.68-8.07c0-3 2.1-4.16 4.68-2.67a10.34 10.34 0 014.68 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M598.66 603.8a4 4 0 01-.59 2.27 3 3 0 01-3.28-.19 9.61 9.61 0 01-4.36-7.53c0-2 1-3.13 2.49-3.09a5.39 5.39 0 011.06.47 10.34 10.34 0 014.68 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M591.31 595.16a3.26 3.26 0 00-1.35 3 10.28 10.28 0 004.68 8.06 3.73 3.73 0 002.67.58 3.29 3.29 0 01-3.33-.32 10.33 10.33 0 01-4.68-8.07c0-1.83.8-2.98 2.01-3.25z"
                      fill="#474756"
                    />
                    <path
                      d="M595.76 601.83c0 .78-.55 1.09-1.22.7a2.69 2.69 0 01-1.22-2.11c0-.78.54-1.09 1.22-.7a2.72 2.72 0 011.22 2.11z"
                      fill="url(#prefix__radial-gradient-11)"
                    />
                    <path
                      d="M594 597.08a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.3-.19-.61-.03-.61.35zm0 7.16a1.32 1.32 0 00.61 1c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.3-.14-.61.01-.61.4zm-1.13-7.24c-.23-.41-.62-.63-.86-.49s-.24.58 0 1 .63.63.86.5.24-.63 0-1.01zm3.55 8.1c.24.42.63.64.86.5s.24-.58 0-1-.62-.63-.86-.5-.24.55 0 .96zm-5.5-6.2a1.32 1.32 0 00.61 1c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.34-.18-.61-.03-.61.36zm6.23 3.6a1.36 1.36 0 00.62 1.06.37.37 0 00.61-.35 1.38 1.38 0 00-.61-1.06c-.34-.23-.62-.08-.62.31zm-5.14.06c.24.41.63.63.86.5s.24-.58 0-1-.62-.64-.86-.5-.24.55-.01.96zm4.41-2.52c.24.41.63.64.86.5s.24-.58 0-1-.62-.63-.86-.5-.24.55 0 .96z"
                      fill="#474756"
                    />
                    <path
                      d="M596.54 601.83A1.28 1.28 0 01596 603l-.76.46c-.33.18-.12-.23-.62-.52a4 4 0 01-1.8-3.12c0-.57-.53-.57-.21-.76l.82-.46a1.3 1.3 0 011.27.13 4 4 0 011.84 3.1z"
                      fill="#545466"
                    />
                    <path
                      d="M595.73 602.3c0 1.15-.81 1.61-1.81 1a4 4 0 01-1.81-3.11c0-1.15.81-1.61 1.81-1a4 4 0 011.81 3.11z"
                      fill="#545466"
                    />
                  </g>
                  <g>
                    <path
                      fill="#cfdee8"
                      d="M628.17 613.31v9.09l-27.22-15.71v-9.02l27.22 15.64z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e7f1f7"
                      d="M608.7 602.19v8.77l-.44-.24v-8.78l.44.25z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M628.16 613.31v9.09l21.5-12.23v-9.15l-21.5 12.29z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M649.66 601.02l-21.5 12.29-27.21-15.64 21.51-12.27 27.2 15.62z"
                    />
                  </g>
                  <g fill="#545466">
                    <path d="M624.51 607.25v3.02l-97.99-56.98v-2.93l97.99 56.89z" />
                    <path d="M624.5 607.25v3.02l26.88-15.29v-3.1l-26.88 15.37z" />
                    <path d="M651.38 591.88l-26.88 15.37-97.98-56.89 26.9-15.34 97.96 56.86z" />
                  </g>
                  <g>
                    <path
                      d="M651.27 571.8l17.67 10.29a9.2 9.2 0 014.35 5.7l2.48 9.8a36.6 36.6 0 011.12 9V618l-25.2 14.26v-10.2a36.18 36.18 0 00-1.7-11l-3.21-10.18a9.13 9.13 0 00-4.2-5.19l-16.53-9.64z"
                      fill="red"
                    />
                    <path
                      d="M651.7 622v10.25l-13.5-7.74-1-5.95-11.15-6.56v-25.9l16.59 9.67a9.14 9.14 0 014.21 5.22l3.15 10.16a36.24 36.24 0 011.7 10.85z"
                      fill="#e50000"
                    />
                    <path
                      d="M645.32 601.47l3.18 10.17a.8.8 0 01-1.17.93l-10-5.82a2.17 2.17 0 01-1.07-1.86v-8.34a.81.81 0 011.22-.7l6.19 3.62a3.49 3.49 0 011.63 2z"
                      fill="#26262e"
                    />
                    <path
                      d="M647.73 612.4a.54.54 0 00.42-.22.47.47 0 00.07-.46L645 601.56a3.29 3.29 0 00-1.5-1.85l-6.2-3.61h-.19c-.39 0-.59.19-.59.47v8.34a1.87 1.87 0 00.93 1.61l10 5.83z"
                      fill="#616173"
                    />
                    <path
                      d="M656 614.06v3.77a5.21 5.21 0 007.8 4.52l3.61-2.06a12.7 12.7 0 006.4-11v-5.44z"
                      fill="#e50000"
                    />
                    <path
                      d="M661.2 621.65a3.75 3.75 0 001.89-.51l3.61-2.06a11.33 11.33 0 005.7-9.82v-3l-15 8.64v3a3.83 3.83 0 003.8 3.75z"
                      fill="#616173"
                    />
                    <path
                      d="M671.17 614.37l-11.81 6.79a3.94 3.94 0 00.73.32l10.44-6a12.27 12.27 0 00.64-1.11zm1-2.9l-14.32 8.23a3.87 3.87 0 00.37.53l13.78-7.91c.05-.32.12-.56.18-.85zm.22-2.45l-15 8.64v.17a3.19 3.19 0 000 .54l15-8.61v-.5zm-15 6.31v.74l15-8.64v-.73z"
                      fill="#474756"
                      opacity={0.5}
                    />
                    <path
                      d="M652.57 612.69a1.45 1.45 0 00.73-.19l20.45-11.6a2.5 2.5 0 001.19-2.79L672.39 588a9.1 9.1 0 00-.55-1.51.92.92 0 00-.84-.49.88.88 0 00-.44.12l-22.26 12.77a1.47 1.47 0 00-.64 1.73l3.48 11a1.49 1.49 0 001.43 1z"
                      fill="#26262e"
                    />
                    <path
                      d="M652.57 612.4a1.2 1.2 0 00.59-.15l20.45-11.6a2.24 2.24 0 001.06-2.47l-2.56-10.09a8.1 8.1 0 00-.53-1.45.62.62 0 00-.56-.36.6.6 0 00-.29.08l-22.28 12.79a1.17 1.17 0 00-.52 1.39l3.49 11a1.19 1.19 0 001.15.86z"
                      fill="#616173"
                    />
                    <path
                      d="M629.86 592.39l3 1.74a1.39 1.39 0 01.68 1.19v7.9a.16.16 0 01-.16.17l-2.86-1.65a1.29 1.29 0 01-.64-1.11v-8.24z"
                      fill="#26262e"
                    />
                    <path
                      d="M633.24 595.32a1.09 1.09 0 00-.54-.94l-2.55-1.49v7.74a1 1 0 00.49.86l2.6 1.49z"
                      fill="#616173"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#prefix__linear-gradient-411)"
                      d="M637.01 611.26l-10.96.72 11.09 6.55-.13-7.27z"
                    />
                  </g>
                  <path
                    fill="#b4c4cc"
                    d="M629.39 616.85v4.99l3.8-2.13-.25-4.98-3.55 2.12z"
                  />
                  <g>
                    <path
                      d="M646.32 627.19c0 2-.7 3.47-1.84 4.17l-3.48 2c-1.18.66-.42-.85-2.22-1.89a14.45 14.45 0 01-6.57-11.32c0-2-1.9-2.07-.73-2.76l3.69-2c1.19-.64 2.8-.53 4.59.5a14.48 14.48 0 016.56 11.3z"
                      fill="#4c4c5e"
                    />
                    <path
                      d="M642.68 629.26c0 4.16-2.94 5.84-6.56 3.74a14.45 14.45 0 01-6.57-11.32c0-4.16 2.94-5.83 6.57-3.74a14.47 14.47 0 016.56 11.32z"
                      fill="#474756"
                    />
                    <path
                      d="M640.79 628.17c0 3-2.09 4.16-4.67 2.67a10.28 10.28 0 01-4.68-8.07c0-3 2.09-4.16 4.68-2.66a10.3 10.3 0 014.67 8.06z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M640.79 628.17a4 4 0 01-.59 2.27 3 3 0 01-3.27-.19 9.62 9.62 0 01-4.37-7.53c0-2 1-3.13 2.5-3.09a5.74 5.74 0 011.06.48 10.3 10.3 0 014.67 8.06z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M633.44 619.53a3.25 3.25 0 00-1.34 3 10.3 10.3 0 004.67 8.06 3.75 3.75 0 002.67.58 3.27 3.27 0 01-3.32-.32 10.28 10.28 0 01-4.68-8.07c0-1.78.79-2.98 2-3.25z"
                      fill="#474756"
                    />
                    <path
                      d="M637.9 626.2c0 .78-.55 1.09-1.23.7a2.72 2.72 0 01-1.22-2.11c0-.78.55-1.09 1.22-.7a2.7 2.7 0 011.23 2.11z"
                      fill="url(#prefix__radial-gradient-12)"
                    />
                    <path
                      d="M636.17 621.45a1.38 1.38 0 00.61 1.06c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1c-.33-.25-.61-.09-.61.29zm0 7.16a1.35 1.35 0 00.61 1c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.33-.14-.61.01-.61.4zm-1.17-7.27c-.24-.42-.63-.64-.86-.5s-.24.58 0 1 .62.63.86.5.25-.59 0-1zm3.55 8.09c.23.42.62.64.86.5s.24-.58 0-1-.63-.63-.86-.5-.23.57.01 1zm-5.51-6.2a1.38 1.38 0 00.61 1.06c.34.19.62 0 .62-.35a1.36 1.36 0 00-.62-1.06.37.37 0 00-.6.35zm6.24 3.6a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.33-.19-.6-.03-.6.35zm-5.14.06c.23.41.62.63.86.5s.24-.58 0-1-.63-.64-.86-.5-.23.59.01 1zm4.41-2.52c.23.42.62.64.86.5s.24-.58 0-1-.63-.63-.86-.5-.23.63.01 1z"
                      fill="#474756"
                    />
                    <path
                      d="M638.67 626.2a1.28 1.28 0 01-.5 1.15l-.77.46c-.32.18-.11-.23-.61-.52a4 4 0 01-1.81-3.12c0-.57-.52-.57-.2-.76l.81-.46a1.32 1.32 0 011.28.13 4 4 0 011.8 3.12z"
                      fill="#545466"
                    />
                    <path
                      d="M637.87 626.67c0 1.15-.81 1.61-1.81 1a4 4 0 01-1.81-3.12c0-1.15.81-1.61 1.81-1a4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                  </g>
                  <path
                    fill="#e7f1f7"
                    d="M644.34 632.7v-7.22l-4.71-8.12-6.62-3.82 4-2.28 6.65 3.84 4.7 8.11v7.23l-4.02 2.26z"
                  />
                  <path
                    fill="#dce8f1"
                    d="M644.34 625.48l-4.71-8.12 4.03-2.26 4.7 8.11-4.02 2.27z"
                  />
                  <path
                    fill="#cfdee8"
                    d="M643.23 632.11v-6.34l-4.41-7.59-5.84-3.38-3.59 2.05v4.99l-1.11-.64v-4.99l4.7-2.69 6.65 3.84 4.71 8.12v7.26l-1.11-.63z"
                  />
                  <g>
                    <path
                      d="M678.78 615.6l-25.21 15a4 4 0 01-4 0l-4.72-2.64v4.29l4.75 2.68a4.05 4.05 0 004 0l25.15-14.45z"
                      fill="#cfdee8"
                    />
                    <path
                      d="M678.35 616.41l-24.78 14.15a4 4 0 01-4 0l-4.72-2.64 1.29-.74 3.41 1.93a4 4 0 004 0l23.27-13.43V614l1.52.93a.88.88 0 01.01 1.48z"
                      fill="#e7f1f7"
                    />
                    <g style={{ mixBlendMode: "color-dodge" }}>
                      <path
                        fill="url(#prefix__radial-gradient-13)"
                        d="M676.9 615.39l-.03.02-.01-1.44.03.02.01 1.4z"
                      />
                      <path
                        d="M645.16 627.77l4.47 2.5a4 4 0 004 0l24.78-14.15a.84.84 0 00.41-.52.85.85 0 01-.44.81l-24.78 14.15a4 4 0 01-4 0l-4.72-2.64z"
                        fill="url(#prefix__radial-gradient-14)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M626 578.45l24.8-14.22 9.35 5.61a13.16 13.16 0 016.53 11.36l-24.74 14.09z"
                      fill="#ff4a4a"
                    />
                    <path
                      d="M641.94 595.25l-16-9.36v-7.48l9.42 5.48a13.15 13.15 0 016.58 11.36z"
                      fill="#e50000"
                    />
                  </g>
                  <path
                    d="M657.35 624.31l-4.7 2.65a.65.65 0 01-1-.57v-2a1.76 1.76 0 01.94-1.56l1.47-.78a1.78 1.78 0 011.87.14l1.46 1.07a.66.66 0 01-.04 1.05zM676.9 611.78v1.71a.63.63 0 01-.34.55l-2 1.05a.48.48 0 01-.67-.59l.82-2.23a1.33 1.33 0 01.67-.73l.67-.32a.63.63 0 01.85.56z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e7f1f7"
                  />
                  <path
                    d="M657.35 624l-4.7 2.66a.65.65 0 01-1-.58v-2a1.77 1.77 0 01.94-1.56l1.47-.78a1.78 1.78 0 011.87.14l1.46 1.07a.66.66 0 01-.04 1.05zM676.9 611.51v1.7a.64.64 0 01-.34.56l-2 1.05a.48.48 0 01-.67-.59l.82-2.23a1.33 1.33 0 01.67-.73l.62-.27a.63.63 0 01.9.51z"
                    fill="#ffc950"
                  />
                  <path
                    d="M657 629.52l-3.53 2 1 .69a1.58 1.58 0 001.71 0l3.64-2.12-1.32-.68a1.63 1.63 0 00-1.5.11zM677.92 617.7l-3.79 2.18a1.58 1.58 0 00-.7.81l-.91 2.33 4-2.33a1.63 1.63 0 00.71-.88zM671.69 624.47v-.8a1.1 1.1 0 00-1.66-.95l-7.24 4.16A3.65 3.65 0 00661 630v.63z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e7f1f7"
                  />
                  <path
                    fill="#e7f1f7"
                    d="M663.01 627.51v1.94l7-3.95v-1.99l-7 4z"
                  />
                  <path
                    d="M665.09 621.05c-5.38 2.86-9.28 6.05-8.7 7.13s5.39-.37 10.77-3.23 9.28-6.05 8.71-7.13-5.4.37-10.78 3.23z"
                    style={{ mixBlendMode: "color-dodge" }}
                    fill="url(#prefix__radial-gradient-15)"
                  />
                </g>
                <motion.g id="prefix__truck2"
                
                variants={truckVariant}
                initial="start"
                animate={truckControls}
                
                
                >
                  <path
                    fill="#b4c4cc"
                    d="M723.66 667.43v11.82l2.04-1.14v-11.89l-2.04 1.21z"
                  />
                  <path
                    d="M740.88 682.79c0 2-.7 3.45-1.83 4.14l-3.62 2.07c-1.17.66-.42-.83-2.21-1.87a14.39 14.39 0 01-6.53-11.25c0-2-1.89-2.06-.73-2.75l3.84-2.13c1.18-.64 2.78-.52 4.56.5a14.38 14.38 0 016.52 11.29z"
                    fill="#474756"
                  />
                  <path
                    d="M737.1 684.93c0 4.14-2.92 5.8-6.52 3.72a14.36 14.36 0 01-6.53-11.25c0-4.14 2.92-5.8 6.53-3.72a14.38 14.38 0 016.52 11.25z"
                    fill="#545466"
                  />
                  <path
                    d="M730.52 676.65c-2.32-1.34-4.21-.26-4.21 2.41a9.31 9.31 0 004.21 7.27c2.33 1.34 4.22.26 4.22-2.41a9.3 9.3 0 00-4.22-7.27z"
                    fill="url(#prefix__linear-gradient-412)"
                  />
                  <path
                    d="M732 685.36a9.3 9.3 0 01-4.21-7.27 3.75 3.75 0 01.49-2c-1.19.17-2 1.22-2 2.95a9.32 9.32 0 004.21 7.28c1.61.93 3 .7 3.72-.42a3.48 3.48 0 01-2.21-.54z"
                    fill="url(#prefix__linear-gradient-413)"
                  />
                  <path
                    d="M733.45 683.55c0 1.65-1.17 2.32-2.6 1.49a5.73 5.73 0 01-2.6-4.49c0-1.65 1.16-2.31 2.6-1.48a5.73 5.73 0 012.6 4.48z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="url(#prefix__linear-gradient-414)"
                  />
                  <path
                    d="M733.24 682.61c0 1.39-1 1.95-2.2 1.25a4.84 4.84 0 01-2.21-3.8c0-1.39 1-2 2.21-1.25a4.86 4.86 0 012.2 3.8z"
                    fill="url(#prefix__linear-gradient-415)"
                  />
                  <path
                    d="M732.64 683c0 1.4-1 2-2.2 1.26a4.86 4.86 0 01-2.21-3.8c0-1.4 1-2 2.21-1.26a4.88 4.88 0 012.2 3.8z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M740.64 682.76c0 2-.71 3.47-1.85 4.17l-3.47 2c-1.19.67-.43-.84-2.23-1.88a14.47 14.47 0 01-6.56-11.32c0-2-1.9-2.07-.74-2.76l3.7-2.05c1.18-.64 2.8-.52 4.58.51a14.45 14.45 0 016.57 11.33z"
                    fill="#4c4c5e"
                  />
                  <path
                    d="M737 684.83c0 4.16-2.94 5.83-6.57 3.74a14.47 14.47 0 01-6.56-11.32c0-4.16 2.94-5.83 6.56-3.74a14.45 14.45 0 016.57 11.32z"
                    fill="#474756"
                  />
                  <path
                    d="M735.11 683.74c0 3-2.09 4.16-4.68 2.67a10.32 10.32 0 01-4.67-8.07c0-3 2.09-4.16 4.67-2.67a10.3 10.3 0 014.68 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M735.11 683.74a4.07 4.07 0 01-.59 2.27 3 3 0 01-3.27-.19 9.61 9.61 0 01-4.37-7.53c0-2 1-3.14 2.5-3.09a5.32 5.32 0 011 .47 10.3 10.3 0 014.73 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M727.76 675.09a3.28 3.28 0 00-1.35 3 10.31 10.31 0 004.68 8.07 3.78 3.78 0 002.67.58 3.29 3.29 0 01-3.33-.32 10.32 10.32 0 01-4.67-8.07c0-1.83.79-2.99 2-3.26z"
                    fill="#474756"
                  />
                  <path
                    d="M732.21 681.77c0 .78-.54 1.09-1.22.7a2.69 2.69 0 01-1.22-2.11c0-.78.54-1.09 1.22-.7a2.67 2.67 0 011.22 2.11z"
                    fill="url(#prefix__radial-gradient-16)"
                  />
                  <path
                    d="M730.49 677a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.34-.17-.61-.01-.61.35zm0 7.16a1.32 1.32 0 00.61 1.05c.34.2.61 0 .61-.35a1.35 1.35 0 00-.61-1c-.34-.22-.61-.07-.61.32zm-1.16-7.28c-.24-.41-.63-.63-.87-.5s-.24.58 0 1 .63.63.87.49.24-.55 0-.97zm3.54 8.1c.24.41.63.64.87.5s.24-.58 0-1-.63-.63-.87-.5-.24.61 0 1.02zm-5.5-6.2a1.32 1.32 0 00.61 1.05c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.34-.17-.61-.02-.61.37zm6.24 3.6a1.32 1.32 0 00.61 1.05c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.34-.17-.61-.03-.61.37zm-5.15.06c.24.41.63.63.87.5s.24-.58 0-1-.63-.63-.87-.49-.24.6 0 1.01zm4.41-2.52c.24.41.63.64.87.5s.24-.58 0-1-.63-.64-.87-.5-.24.61 0 1.02z"
                    fill="#474756"
                  />
                  <path
                    d="M733 681.77a1.26 1.26 0 01-.51 1.15l-.76.46c-.33.18-.12-.24-.61-.52a4 4 0 01-1.81-3.12c0-.57-.53-.57-.21-.76l.82-.46a1.3 1.3 0 011.27.13 4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <path
                    d="M732.18 682.24c0 1.15-.81 1.61-1.81 1a4 4 0 01-1.8-3.12c0-1.14.8-1.6 1.8-1a4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <path
                    d="M754.88 691c0 2-.7 3.45-1.83 4.15l-3.62 2.09c-1.18.66-.42-.84-2.21-1.87a14.37 14.37 0 01-6.53-11.26c0-2-1.89-2.06-.73-2.74l3.84-2.13c1.17-.63 2.78-.51 4.55.51a14.39 14.39 0 016.53 11.25z"
                    fill="#474756"
                  />
                  <path
                    d="M751.1 693.18c0 4.13-2.92 5.8-6.53 3.72a14.38 14.38 0 01-6.52-11.26c0-4.13 2.92-5.8 6.52-3.72a14.37 14.37 0 016.53 11.26z"
                    fill="#545466"
                  />
                  <path
                    d="M744.52 684.9c-2.33-1.35-4.22-.27-4.22 2.4a9.32 9.32 0 004.22 7.28c2.33 1.34 4.22.26 4.22-2.41a9.28 9.28 0 00-4.22-7.27z"
                    fill="url(#prefix__linear-gradient-416)"
                  />
                  <path
                    d="M746 693.61a9.28 9.28 0 01-4.22-7.27 3.61 3.61 0 01.5-2c-1.2.16-2 1.22-2 2.95a9.32 9.32 0 004.22 7.28c1.61.92 3 .69 3.72-.42a3.53 3.53 0 01-2.22-.54z"
                    fill="url(#prefix__linear-gradient-417)"
                  />
                  <path
                    d="M747.44 691.8c0 1.65-1.16 2.31-2.6 1.48a5.75 5.75 0 01-2.6-4.48c0-1.65 1.17-2.32 2.6-1.49a5.73 5.73 0 012.6 4.49z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="url(#prefix__linear-gradient-418)"
                  />
                  <path
                    d="M747.23 690.85c0 1.4-1 2-2.2 1.26a4.83 4.83 0 01-2.2-3.8c0-1.4 1-2 2.2-1.26a4.86 4.86 0 012.2 3.8z"
                    fill="url(#prefix__linear-gradient-419)"
                  />
                  <path
                    d="M746.63 691.27c0 1.39-1 2-2.2 1.25a4.83 4.83 0 01-2.2-3.8c0-1.39 1-2 2.2-1.25a4.83 4.83 0 012.2 3.8z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M754.81 691c0 2-.7 3.47-1.84 4.17l-3.47 2c-1.19.66-.43-.84-2.23-1.88a14.44 14.44 0 01-6.56-11.29c0-2.06-1.91-2.07-.74-2.76l3.7-2c1.18-.64 2.8-.52 4.58.51a14.44 14.44 0 016.56 11.25z"
                    fill="#4c4c5e"
                  />
                  <path
                    d="M751.17 693.09c0 4.16-2.94 5.83-6.56 3.74a14.47 14.47 0 01-6.56-11.32c0-4.16 2.94-5.84 6.56-3.74a14.47 14.47 0 016.56 11.32z"
                    fill="#474756"
                  />
                  <path
                    d="M749.29 692c0 3-2.1 4.16-4.68 2.66a10.31 10.31 0 01-4.68-8.06c0-3 2.1-4.16 4.68-2.67a10.31 10.31 0 014.68 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M749.29 692a4.07 4.07 0 01-.59 2.27 3 3 0 01-3.28-.2 9.61 9.61 0 01-4.36-7.52c0-2 1-3.14 2.49-3.09a5.39 5.39 0 011.06.47 10.31 10.31 0 014.68 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M741.94 683.35a3.25 3.25 0 00-1.35 3 10.28 10.28 0 004.68 8.07 3.78 3.78 0 002.67.58 3.29 3.29 0 01-3.33-.33 10.31 10.31 0 01-4.68-8.06c0-1.84.8-2.99 2.01-3.26z"
                    fill="#474756"
                  />
                  <path
                    d="M746.39 690c0 .77-.55 1.09-1.22.7a2.72 2.72 0 01-1.23-2.12c0-.77.55-1.08 1.23-.69a2.7 2.7 0 011.22 2.11z"
                    fill="url(#prefix__radial-gradient-17)"
                  />
                  <path
                    d="M744.67 685.28a1.32 1.32 0 00.61 1.05c.33.2.61 0 .61-.34a1.38 1.38 0 00-.61-1.06c-.34-.19-.61-.04-.61.35zm0 7.16a1.34 1.34 0 00.61 1c.33.2.61 0 .61-.35a1.35 1.35 0 00-.61-1c-.34-.2-.61-.04-.61.35zm-1.17-7.28c-.23-.41-.62-.63-.86-.5s-.24.58 0 1 .63.64.86.5.24-.59 0-1zm3.55 8.1c.24.41.63.64.86.5s.24-.58 0-1-.62-.64-.86-.5-.24.59 0 1zm-5.5-6.2a1.34 1.34 0 00.61 1.05c.34.2.61 0 .61-.35a1.34 1.34 0 00-.61-1c-.34-.25-.61-.09-.61.3zm6.23 3.6a1.33 1.33 0 00.62 1.05c.33.2.61 0 .61-.35a1.38 1.38 0 00-.61-1.05c-.34-.19-.62-.04-.62.35zm-5.14.05c.24.42.63.64.86.5s.24-.58 0-1-.62-.63-.86-.5-.24.59 0 1zm4.41-2.51c.24.41.63.63.86.5s.24-.58 0-1-.62-.64-.86-.5-.24.59 0 1z"
                    fill="#474756"
                  />
                  <path
                    d="M747.17 690a1.28 1.28 0 01-.51 1.15l-.76.45c-.33.19-.12-.23-.62-.51a4 4 0 01-1.8-3.12c0-.57-.53-.57-.21-.76l.82-.46a1.27 1.27 0 011.27.13 4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <path
                    d="M746.36 690.5c0 1.15-.81 1.61-1.81 1a4 4 0 01-1.81-3.12c0-1.14.81-1.61 1.81-1a4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <g>
                    <path
                      fill="#9aa0a6"
                      d="M779.38 700.63v6.23l-21.01-12.19v-6.21l21.01 12.17zM779.38 700.63v6.23l5.91-3.36v-6.24l-5.91 3.37z"
                    />
                    <path
                      fill="url(#prefix__linear-gradient-420)"
                      d="M785.29 697.26l-5.91 3.37-21.01-12.17 5.91-3.36 21.01 12.16z"
                    />
                  </g>
                  <g>
                    <path
                      fill="#8c9399"
                      d="M719.51 665.41v7.93l-.78-.62v-7.91l.78.6zM719.51 665.41v7.93l18.9-10.62v-7.95l-18.9 10.64z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M738.41 654.77l-18.9 10.64-.78-.6 18.91-10.63.77.59z"
                    />
                  </g>
                  <path
                    fill="#c5d4dd"
                    d="M754.43 685.18V697l-2.11-1.22v-9.4l-28.64-16.53v9.4l-2.11-1.22v-11.81l32.86 18.96z"
                  />
                  <path
                    fill="#b4c4cc"
                    d="M754.44 685.2v11.82l2.04-1.14v-11.89l-2.04 1.21z"
                  />
                  <g>
                    <path
                      fill="#b4c4cc"
                      d="M775.92 705.83l-1.07.63-17.44-10.05 1.08-.61 17.43 10.03zM757.39 690.03v6.39l1.1-.62v-6.43l-1.1.66z"
                    />
                    <path
                      d="M755.8 688.26v9l20.64 12v-9.09zm1.61 2.78l17.42 10v5.37l-17.42-10.1z"
                      fill="#c5d4dd"
                    />
                    <path
                      fill="#b4c4cc"
                      d="M776.43 700.15v9.09l1.35-.68v-9.15l-1.35.74zM777.78 699.41l-1.35.74-20.63-11.89 1.37-.72 20.61 11.87z"
                    />
                  </g>
                  <g>
                    <path
                      d="M796.11 715c0 2-.7 3.45-1.83 4.14l-3.62 2.09c-1.17.66-.42-.83-2.21-1.87a14.39 14.39 0 01-6.53-11.25c0-2-1.89-2.06-.73-2.75l3.84-2.12c1.18-.64 2.78-.52 4.55.5a14.37 14.37 0 016.53 11.26z"
                      fill="#474756"
                    />
                    <path
                      d="M792.33 717.16c0 4.14-2.92 5.8-6.52 3.72a14.36 14.36 0 01-6.53-11.25c0-4.14 2.92-5.8 6.53-3.72a14.38 14.38 0 016.52 11.25z"
                      fill="#545466"
                    />
                    <path
                      d="M785.75 708.88c-2.32-1.34-4.21-.26-4.21 2.41a9.3 9.3 0 004.21 7.27c2.33 1.34 4.22.26 4.22-2.4a9.32 9.32 0 00-4.22-7.28z"
                      fill="#b4c4cc"
                    />
                    <path
                      d="M787.87 715.25c0 1.4-1 2-2.21 1.26a4.86 4.86 0 01-2.2-3.8c0-1.4 1-2 2.2-1.26a4.87 4.87 0 012.21 3.8z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M795.85 714.9c0 2-.7 3.47-1.84 4.16 0 0-3.44 2-3.48 2-1.18.66-.42-.84-2.22-1.88a14.45 14.45 0 01-6.57-11.32c0-2.06-1.9-2.08-.73-2.76l3.7-2c1.18-.64 2.8-.52 4.58.51a14.47 14.47 0 016.56 11.29z"
                      fill="#4c4c5e"
                    />
                    <path
                      d="M792.21 717c0 4.16-2.94 5.84-6.56 3.75a14.46 14.46 0 01-6.57-11.32c0-4.16 2.94-5.84 6.57-3.75a14.5 14.5 0 016.56 11.32z"
                      fill="#474756"
                    />
                    <path
                      d="M790.32 715.88c0 3-2.09 4.15-4.67 2.66a10.28 10.28 0 01-4.68-8.06c0-3 2.09-4.16 4.68-2.67a10.3 10.3 0 014.67 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M790.32 715.88a4 4 0 01-.59 2.27 3 3 0 01-3.27-.2 9.61 9.61 0 01-4.36-7.53c0-2 1-3.13 2.49-3.08a5.08 5.08 0 011.06.47 10.3 10.3 0 014.67 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M783 707.23a3.25 3.25 0 00-1.35 3 10.32 10.32 0 004.67 8.07 3.8 3.8 0 002.67.58 3.3 3.3 0 01-3.32-.33 10.28 10.28 0 01-4.68-8.06c.01-1.84.77-2.99 2.01-3.26z"
                      fill="#474756"
                    />
                    <path
                      d="M787.43 713.91c0 .77-.55 1.08-1.22.69a2.68 2.68 0 01-1.23-2.11c0-.77.55-1.09 1.23-.69a2.7 2.7 0 011.22 2.11z"
                      fill="url(#prefix__radial-gradient-18)"
                    />
                    <path
                      d="M785.7 709.16a1.36 1.36 0 00.61 1.05c.34.2.62 0 .62-.35a1.35 1.35 0 00-.62-1.05c-.31-.2-.61-.04-.61.35zm0 7.15a1.38 1.38 0 00.61 1.06c.34.19.62 0 .62-.35a1.33 1.33 0 00-.62-1c-.31-.25-.61-.09-.61.29zm-1.16-7.31c-.24-.41-.62-.64-.86-.5s-.24.58 0 1 .62.64.86.5.24-.55 0-1zm3.55 8.1c.24.41.62.63.86.5s.24-.58 0-1-.62-.64-.86-.5-.24.63 0 1.04zm-5.51-6.2a1.36 1.36 0 00.62 1c.33.19.61 0 .61-.35a1.35 1.35 0 00-.61-1c-.34-.16-.62 0-.62.39zm6.24 3.6a1.36 1.36 0 00.61 1.05c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1c-.33-.2-.61-.05-.61.34zm-5.14.05c.24.41.62.64.86.5s.24-.58 0-1-.62-.64-.86-.5-.24.63 0 1.04zm4.41-2.51c.24.41.62.63.86.5s.24-.58 0-1-.62-.64-.86-.5-.24.63 0 1.04z"
                      fill="#474756"
                    />
                    <path
                      d="M788.2 713.91a1.29 1.29 0 01-.5 1.15l-.77.45c-.32.18-.11-.23-.61-.52a4 4 0 01-1.81-3.12c0-.56-.52-.57-.2-.76l.81-.46a1.3 1.3 0 011.28.14 4 4 0 011.8 3.12z"
                      fill="#545466"
                    />
                    <path
                      d="M787.4 714.38c0 1.14-.81 1.61-1.81 1a4 4 0 01-1.81-3.12c0-1.15.81-1.61 1.81-1a4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                  </g>
                  <g>
                    <path
                      fill="#cfdee8"
                      d="M819.84 725.39v9.09l-27.23-15.72v-9.01l27.23 15.64z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e7f1f7"
                      d="M800.37 714.26v8.77l-.44-.23v-8.79l.44.25z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M819.83 725.39v9.09l21.5-12.23v-9.15l-21.5 12.29z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M841.33 713.1l-21.5 12.29-27.22-15.64 21.52-12.27 27.2 15.62z"
                    />
                  </g>
                  <g fill="#545466">
                    <path d="M816.18 719.32v3.03l-98-56.99v-2.93l98 56.89z" />
                    <path d="M816.17 719.32v3.03l26.88-15.29v-3.1l-26.88 15.36z" />
                    <path d="M843.05 703.96l-26.88 15.36-97.99-56.89 26.9-15.34 97.97 56.87z" />
                  </g>
                  <g>
                    <path
                      fill="#cfdee8"
                      d="M816.18 686.46v33.01l-98-56.99v-32.9l98 56.88z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e6e7e8"
                      d="M813.85 687.8l-93.34-54.19v27.53l93.34 54.29V687.8z"
                    />
                    <g fill="#dce8f1">
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M810.89 686.47l1.22.81v27.62l-1.22-.81v-27.62zM812.17 687.17l.46-.02v27.62l-.46.02v-27.62zM806.88 684.14l1.23.81v27.63l-1.23-.81v-27.63zM808.16 684.84l.47-.02v27.63l-.47.01v-27.62zM802.88 681.82l1.22.81v27.62l-1.22-.81v-27.62zM804.16 682.51l.46-.02v27.63l-.46.02v-27.63zM798.87 679.49l1.22.81v27.62l-1.22-.81v-27.62zM800.15 680.19l.47-.02v27.62l-.47.02v-27.62zM794.87 677.16l1.22.81v27.63l-1.22-.81v-27.63zM796.14 677.86l.47-.02v27.63l-.47.01v-27.62zM790.86 674.84l1.22.8v27.63l-1.22-.81v-27.62zM792.14 675.53l.46-.02v27.63l-.46.02v-27.63zM786.86 672.51l1.22.81v27.63l-1.22-.82v-27.62zM788.13 673.21l.47-.02v27.62l-.47.02v-27.62zM782.85 670.18l1.22.81v27.63l-1.22-.81v-27.63zM784.13 670.88l.46-.02v27.63l-.46.01v-27.62zM778.85 667.86l1.22.81v27.62l-1.22-.81v-27.62zM780.12 668.55l.47-.02v27.63l-.47.02v-27.63zM774.84 665.53l1.22.81v27.63l-1.22-.82v-27.62zM776.12 666.23l.46-.02v27.62l-.46.02v-27.62zM770.84 663.2l1.22.81v27.63l-1.22-.81V663.2zM772.11 663.9l.47-.02v27.63l-.47.01V663.9zM766.83 660.88l1.22.81v27.62l-1.22-.81v-27.62zM768.11 661.58l.46-.03v27.63l-.46.02v-27.62z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M762.82 658.55l1.22.81v27.63l-1.22-.82v-27.62zM764.1 659.25l.47-.02v27.62l-.47.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M758.82 656.23l1.22.8v27.63l-1.22-.81v-27.62zM760.1 656.92l.46-.02v27.63l-.46.01v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M754.81 653.9l1.22.81v27.62l-1.22-.81V653.9zM756.09 654.6l.47-.02v27.62l-.47.02V654.6z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M750.81 651.57l1.22.81v27.63l-1.22-.81v-27.63zM752.09 652.27l.46-.02v27.62l-.46.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M746.8 649.25l1.22.8v27.63l-1.22-.81v-27.62zM748.08 649.94l.46-.02v27.63l-.46.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M742.8 646.92l1.22.81v27.62l-1.22-.81v-27.62zM744.08 647.62l.46-.02v27.62l-.46.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M738.79 644.59l1.22.81v27.63l-1.22-.81v-27.63zM740.07 645.29l.46-.02v27.62l-.46.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M734.79 642.26l1.22.81v27.63l-1.22-.81v-27.63zM736.06 642.96l.47-.02v27.63l-.47.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M730.78 639.94l1.22.81v27.62l-1.22-.81v-27.62zM732.06 640.64l.46-.02v27.62l-.46.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M726.77 637.61l1.23.81v27.63l-1.23-.81v-27.63zM728.05 638.31l.47-.02v27.62l-.47.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M722.77 635.29l1.22.8v27.63l-1.22-.81v-27.62zM724.05 635.98l.46-.02v27.63l-.46.02v-27.63z"
                        />
                      </g>
                    </g>
                    <path
                      fill="#dce8f1"
                      d="M816.17 686.46v33.01l26.88-15.3V671.1l-26.88 15.36z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e6e7e8"
                      d="M840.72 702.82V675.1l-22.23 12.71v27.66l22.23-12.65z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M843.05 671.1l-26.88 15.36-97.99-56.88 26.9-15.34 97.97 56.86z"
                    />
                    <g fill="#dce8f1">
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M820.74 686.96l-1.22.81v27.63l1.22-.81v-27.63zM819.47 687.66l-.47-.02v27.63l.47.01v-27.62zM824.37 684.84l-1.23.8v27.63l1.23-.81v-27.62zM823.09 685.53l-.47-.02v27.63l.47.02v-27.63zM827.99 682.71l-1.23.81v27.62l1.23-.81v-27.62zM826.71 683.41l-.47-.02v27.62l.47.02v-27.62z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M831.61 680.58l-1.23.81v27.63l1.23-.81v-27.63zM830.33 681.28l-.47-.02v27.62l.47.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M835.23 678.45l-1.23.81v27.63l1.23-.81v-27.63zM833.95 679.15l-.47-.02v27.63l.47.01v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M838.85 676.33l-1.22.8v27.63l1.22-.81v-27.62zM837.57 677.02l-.47-.02v27.63l.47.02v-27.63z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <path
                      d="M842.93 683.87l17.68 10.29a9.14 9.14 0 014.34 5.71l2.49 9.8a36.6 36.6 0 011.12 9v11.43l-25.2 14.27v-10.21a36.54 36.54 0 00-1.7-11L838.45 713a9.17 9.17 0 00-4.21-5.2l-16.52-9.64z"
                      fill="red"
                    />
                    <path
                      d="M843.36 734.11v10.26l-13.49-7.75-1-6-11.11-6.62v-25.83l16.59 9.67a9.19 9.19 0 014.21 5.23l3.18 10.15a36.6 36.6 0 011.62 10.89z"
                      fill="#e50000"
                    />
                    <path
                      d="M837 713.55l3.19 10.16a.81.81 0 01-1.18.94l-10-5.83a2.15 2.15 0 01-1.07-1.86v-8.34a.8.8 0 011.21-.69l6.2 3.61a3.6 3.6 0 011.63 2z"
                      fill="#26262e"
                    />
                    <path
                      d="M839.4 724.47a.52.52 0 00.42-.22.47.47 0 00.07-.45l-3.18-10.17a3.3 3.3 0 00-1.5-1.84l-6.2-3.62h-.2c-.38 0-.58.19-.58.46V717a1.88 1.88 0 00.93 1.62l10 5.82z"
                      fill="#616173"
                    />
                    <path
                      d="M847.65 726.14v3.76a5.21 5.21 0 007.8 4.53l3.61-2.06a12.72 12.72 0 006.4-11v-5.47z"
                      fill="#e50000"
                    />
                    <path
                      d="M852.87 733.73a3.78 3.78 0 001.89-.51l3.61-2.06a11.36 11.36 0 005.7-9.83v-3l-15 8.63v3a3.84 3.84 0 003.8 3.77z"
                      fill="#616173"
                    />
                    <path
                      d="M862.84 726.45L851 733.24a4.3 4.3 0 00.74.31l10.44-6c.25-.35.46-.72.66-1.1zm1-2.91l-14.32 8.24a3.71 3.71 0 00.36.52l13.75-7.9c.09-.28.16-.57.22-.86zm.22-2.45l-15 8.64v.17a4.56 4.56 0 000 .54l15-8.6v-.51zm-15 6.32v.74l15-8.64v-.74z"
                      fill="#474756"
                      opacity={0.5}
                    />
                    <path
                      d="M844.24 724.77a1.49 1.49 0 00.73-.2L865.42 713a2.49 2.49 0 001.19-2.79l-2.56-10.08a8 8 0 00-.54-1.51.91.91 0 00-.82-.52.93.93 0 00-.44.11L840 711a1.47 1.47 0 00-.65 1.73l3.49 11a1.48 1.48 0 001.43 1z"
                      fill="#26262e"
                    />
                    <path
                      d="M844.24 724.48a1.16 1.16 0 00.59-.16l20.45-11.6a2.23 2.23 0 001.05-2.47l-2.56-10.08a7.7 7.7 0 00-.52-1.46.64.64 0 00-.56-.36.61.61 0 00-.3.08l-22.28 12.79a1.18 1.18 0 00-.51 1.39l3.48 11a1.2 1.2 0 001.16.87z"
                      fill="#616173"
                    />
                    <path
                      d="M821.53 704.47l3 1.74a1.37 1.37 0 01.68 1.18v7.91a.16.16 0 01-.16.16l-2.86-1.64a1.29 1.29 0 01-.64-1.12v-8.23z"
                      fill="#26262e"
                    />
                    <path
                      d="M824.9 707.39a1.06 1.06 0 00-.54-.93l-2.55-1.46v7.73a1 1 0 00.5.87l2.59 1.49z"
                      fill="#616173"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#prefix__linear-gradient-421)"
                      d="M828.67 723.33l-10.95.72 11.08 6.55-.13-7.27z"
                    />
                  </g>
                  <path
                    fill="#b4c4cc"
                    d="M821.06 728.92v4.99l3.8-2.13-.26-4.97-3.54 2.11z"
                  />
                  <g>
                    <path
                      d="M838 739.27c0 2-.71 3.47-1.85 4.17l-3.47 2c-1.19.66-.43-.84-2.23-1.88a14.47 14.47 0 01-6.56-11.32c0-2.06-1.9-2.07-.74-2.76l3.7-2c1.18-.64 2.8-.52 4.58.51a14.45 14.45 0 016.57 11.28z"
                      fill="#4c4c5e"
                    />
                    <path
                      d="M834.35 741.34c0 4.16-2.94 5.83-6.57 3.74a14.47 14.47 0 01-6.56-11.32c0-4.16 2.94-5.84 6.56-3.74a14.45 14.45 0 016.57 11.32z"
                      fill="#474756"
                    />
                    <path
                      d="M832.46 740.25c0 3-2.09 4.15-4.68 2.66a10.3 10.3 0 01-4.67-8.06c0-3 2.09-4.16 4.67-2.67a10.28 10.28 0 014.68 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M832.46 740.25a4.07 4.07 0 01-.59 2.27 2.94 2.94 0 01-3.27-.2 9.62 9.62 0 01-4.37-7.52c0-2 1-3.14 2.5-3.09a5.32 5.32 0 011 .47 10.28 10.28 0 014.73 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M825.11 731.6a3.27 3.27 0 00-1.35 3 10.31 10.31 0 004.68 8.07 3.78 3.78 0 002.67.58 3.32 3.32 0 01-3.33-.33 10.3 10.3 0 01-4.67-8.06c0-1.86.79-2.99 2-3.26z"
                      fill="#474756"
                    />
                    <path
                      d="M829.56 738.28c0 .77-.54 1.08-1.22.69a2.7 2.7 0 01-1.22-2.11c0-.77.55-1.08 1.22-.69a2.67 2.67 0 011.22 2.11z"
                      fill="url(#prefix__radial-gradient-19)"
                    />
                    <path
                      d="M827.84 733.53a1.32 1.32 0 00.61 1.05c.34.2.61 0 .61-.35a1.35 1.35 0 00-.61-1.05c-.34-.18-.61-.04-.61.35zm0 7.16a1.35 1.35 0 00.61 1c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1c-.34-.2-.61-.04-.61.35zm-1.16-7.28c-.24-.41-.63-.63-.87-.5s-.24.58 0 1 .63.64.87.5.24-.59 0-1zm3.54 8.1c.24.41.63.63.87.5s.24-.58 0-1-.63-.64-.87-.5-.22.59 0 1zm-5.5-6.2a1.34 1.34 0 00.61 1.05c.34.2.61 0 .61-.35a1.34 1.34 0 00-.61-1c-.33-.25-.61-.09-.61.3zm6.24 3.6a1.34 1.34 0 00.61 1.05c.34.2.61 0 .61-.35a1.34 1.34 0 00-.61-1.05c-.34-.2-.57-.04-.57.35zm-5.15.05c.24.42.63.64.87.5s.24-.58 0-1-.63-.63-.87-.5-.24.59 0 1.04zm4.41-2.51c.24.41.63.63.87.5s.24-.58 0-1-.63-.64-.87-.5-.22.55 0 1z"
                      fill="#474756"
                    />
                    <path
                      d="M830.34 738.28a1.27 1.27 0 01-.51 1.15l-.76.45c-.33.19-.12-.23-.61-.52a4 4 0 01-1.81-3.11c0-.57-.53-.58-.21-.76l.82-.47a1.3 1.3 0 011.27.14 4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                    <path
                      d="M829.53 738.75c0 1.15-.81 1.61-1.81 1a4 4 0 01-1.8-3.12c0-1.14.8-1.61 1.8-1a4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                  </g>
                  <path
                    fill="#e7f1f7"
                    d="M836 744.78v-7.23l-4.7-8.11-6.63-3.83 4-2.28 6.66 3.84 4.7 8.11v7.23l-4.03 2.27z"
                  />
                  <path
                    fill="#dce8f1"
                    d="M836 737.55l-4.7-8.11 4.03-2.27 4.7 8.11-4.03 2.27z"
                  />
                  <path
                    fill="#cfdee8"
                    d="M834.89 744.18v-6.33l-4.4-7.6-5.84-3.37-3.59 2.04v4.99l-1.11-.63v-5l4.7-2.68 6.65 3.84 4.7 8.11v7.27l-1.11-.64z"
                  />
                  <g>
                    <path
                      d="M870.45 727.67l-25.21 15a4 4 0 01-4 0l-4.68-2.67v4.29l4.75 2.67a4 4 0 004 0l25.16-14.44z"
                      fill="#cfdee8"
                    />
                    <path
                      d="M870 728.49l-24.78 14.14a4 4 0 01-4 0l-4.66-2.63 1.29-.74 3.41 1.93a4 4 0 004 0l23.28-13.42V726l1.46 1a.89.89 0 010 1.49z"
                      fill="#e7f1f7"
                    />
                    <g style={{ mixBlendMode: "color-dodge" }}>
                      <path
                        fill="url(#prefix__radial-gradient-20)"
                        d="M868.57 727.47l-.03.01-.01-1.44.03.02.01 1.41z"
                      />
                      <path
                        d="M836.83 739.84l4.47 2.51a4 4 0 004 0l24.78-14.14a.87.87 0 00.41-.52.89.89 0 01-.44.81l-24.78 14.14a4 4 0 01-4 0l-4.71-2.64z"
                        fill="url(#prefix__radial-gradient-21)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M817.67 690.53l24.81-14.23 9.35 5.61a13.17 13.17 0 016.53 11.37l-24.74 14.1z"
                      fill="#ff4a4a"
                    />
                    <path
                      d="M833.6 707.33L817.66 698v-7.48l9.41 5.48a13.15 13.15 0 016.53 11.33z"
                      fill="#e50000"
                    />
                  </g>
                  <path
                    d="M849 736.38l-4.7 2.66a.66.66 0 01-1-.57v-2.05a1.77 1.77 0 01.94-1.57l1.48-.77a1.74 1.74 0 011.86.14l1.46 1.07a.65.65 0 01-.04 1.09zM868.56 723.86v1.7a.63.63 0 01-.34.56l-2 1a.48.48 0 01-.67-.6l.81-2.22a1.35 1.35 0 01.67-.74l.68-.32a.62.62 0 01.85.62z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e7f1f7"
                  />
                  <path
                    d="M849 736l-4.7 2.65a.66.66 0 01-1-.57v-2a1.77 1.77 0 01.94-1.57l1.48-.77a1.76 1.76 0 011.86.14l1.46 1.07A.66.66 0 01849 736zM868.56 723.58v1.71a.64.64 0 01-.34.56l-2 1a.48.48 0 01-.67-.59l.81-2.22a1.32 1.32 0 01.67-.74l.68-.32a.62.62 0 01.85.6z"
                    fill="#ffc950"
                  />
                  <path
                    d="M848.61 741.59l-3.53 2 1 .69a1.62 1.62 0 001.71.05l3.64-2.13-1.33-.67a1.6 1.6 0 00-1.49.06zM869.59 729.78L865.8 732a1.58 1.58 0 00-.7.81l-.91 2.33 4-2.33a1.57 1.57 0 00.72-.88zM863.35 736.55v-.8a1.1 1.1 0 00-1.65-1l-7.21 4.25a3.63 3.63 0 00-1.82 3.15v.62z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e7f1f7"
                  />
                  <path
                    fill="#e7f1f7"
                    d="M854.68 739.58v1.94l7-3.95v-1.99l-7 4z"
                  />
                  <path
                    d="M856.76 733.13c-5.38 2.86-9.28 6-8.71 7.12s5.4-.37 10.78-3.23 9.28-6 8.71-7.13-5.4.38-10.78 3.24z"
                    style={{ mixBlendMode: "color-dodge" }}
                    fill="url(#prefix__radial-gradient-22)"
                  />
                </motion.g>
                <g id="prefix__truck1">
                  <path
                    fill="#b4c4cc"
                    d="M156.8 341.4v11.82l2.04-1.15v-11.89l-2.04 1.22z"
                  />
                  <path
                    d="M174 356.75c0 2-.7 3.45-1.83 4.14l-3.62 2.1c-1.18.65-.42-.84-2.21-1.88a14.35 14.35 0 01-6.53-11.25c0-2-1.89-2.06-.73-2.74l3.84-2.13c1.17-.64 2.78-.52 4.55.5a14.38 14.38 0 016.53 11.26z"
                    fill="#474756"
                  />
                  <path
                    d="M170.24 358.89c0 4.14-2.92 5.81-6.52 3.72a14.36 14.36 0 01-6.53-11.25c0-4.14 2.92-5.8 6.53-3.72a14.38 14.38 0 016.52 11.25z"
                    fill="#545466"
                  />
                  <path
                    d="M163.66 350.61c-2.32-1.34-4.21-.26-4.21 2.41a9.3 9.3 0 004.21 7.27c2.33 1.34 4.22.27 4.22-2.4a9.32 9.32 0 00-4.22-7.28z"
                    fill="url(#prefix__linear-gradient-422)"
                  />
                  <path
                    d="M165.16 359.33a9.32 9.32 0 01-4.21-7.28 3.75 3.75 0 01.49-2c-1.19.17-2 1.22-2 3a9.3 9.3 0 004.21 7.27c1.61.93 3 .7 3.72-.41a3.55 3.55 0 01-2.21-.58z"
                    fill="url(#prefix__linear-gradient-423)"
                  />
                  <path
                    d="M166.59 357.52c0 1.64-1.17 2.31-2.6 1.48a5.74 5.74 0 01-2.61-4.49c0-1.65 1.17-2.31 2.61-1.48a5.75 5.75 0 012.6 4.49z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="url(#prefix__linear-gradient-424)"
                  />
                  <path
                    d="M166.38 356.57c0 1.4-1 2-2.21 1.26A4.89 4.89 0 01162 354c0-1.4 1-2 2.2-1.26a4.84 4.84 0 012.18 3.83z"
                    fill="url(#prefix__linear-gradient-425)"
                  />
                  <path
                    d="M165.78 357c0 1.4-1 2-2.21 1.26a4.86 4.86 0 01-2.2-3.8c0-1.4 1-2 2.2-1.26a4.87 4.87 0 012.21 3.8z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M173.77 356.72c0 2-.7 3.48-1.84 4.17l-3.47 2c-1.19.66-.43-.84-2.23-1.88a14.5 14.5 0 01-6.56-11.32c0-2.06-1.9-2.08-.74-2.76l3.7-2.05c1.18-.64 2.8-.52 4.58.5a14.47 14.47 0 016.56 11.34z"
                    fill="#4c4c5e"
                  />
                  <path
                    d="M170.13 358.79c0 4.16-2.93 5.84-6.56 3.74a14.47 14.47 0 01-6.57-11.32c0-4.16 2.94-5.83 6.56-3.74a14.44 14.44 0 016.57 11.32z"
                    fill="#474756"
                  />
                  <path
                    d="M168.25 357.7c0 3-2.1 4.16-4.68 2.67a10.3 10.3 0 01-4.67-8.07c0-3 2.09-4.15 4.67-2.66a10.31 10.31 0 014.68 8.06z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M168.25 357.7a4 4 0 01-.59 2.27 3 3 0 01-3.28-.19 9.61 9.61 0 01-4.36-7.53c0-2 1-3.13 2.49-3.08a4.86 4.86 0 011.06.47 10.31 10.31 0 014.68 8.06z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M160.9 349.06a3.26 3.26 0 00-1.35 3 10.28 10.28 0 004.68 8.06 3.73 3.73 0 002.67.58 3.29 3.29 0 01-3.33-.32 10.3 10.3 0 01-4.67-8.07c0-1.83.79-2.98 2-3.25z"
                    fill="#474756"
                  />
                  <path
                    d="M165.35 355.73c0 .78-.54 1.09-1.22.7a2.67 2.67 0 01-1.22-2.11c0-.78.54-1.09 1.22-.7a2.69 2.69 0 011.22 2.11z"
                    fill="url(#prefix__radial-gradient-23)"
                  />
                  <path
                    d="M163.63 351a1.35 1.35 0 00.61 1.05c.34.19.61 0 .61-.35a1.32 1.32 0 00-.61-1.05c-.34-.21-.61-.05-.61.35zm0 7.15a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.34-.2-.61-.05-.61.34zm-1.16-7.27c-.24-.41-.63-.64-.87-.5s-.24.58 0 1 .63.63.87.5.23-.6 0-1.01zM166 359c.24.41.63.63.87.49s.23-.58 0-1-.63-.63-.87-.5-.23.56 0 1.01zm-5.5-6.21a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.33-.22-.6-.06-.6.32zm6.24 3.6a1.35 1.35 0 00.61 1.06c.33.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.05c-.35-.23-.6-.05-.6.31zm-5.15.06c.24.41.63.63.87.5s.23-.58 0-1-.63-.64-.87-.5-.23.55.01.97zm4.41-2.51c.24.41.63.63.87.49s.23-.58 0-1-.63-.63-.87-.5-.23.56 0 .98z"
                    fill="#474756"
                  />
                  <path
                    d="M166.13 355.73a1.26 1.26 0 01-.51 1.15l-.76.46c-.33.18-.12-.23-.62-.52a4 4 0 01-1.8-3.12c0-.56-.53-.57-.21-.76l.82-.46a1.28 1.28 0 011.27.14 4 4 0 011.81 3.11z"
                    fill="#545466"
                  />
                  <path
                    d="M165.32 356.21c0 1.14-.81 1.6-1.81 1a4 4 0 01-1.81-3.12c0-1.15.81-1.61 1.81-1a4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <path
                    d="M188 365c0 2-.69 3.45-1.83 4.14l-3.61 2.09c-1.18.66-.42-.83-2.22-1.87a14.38 14.38 0 01-6.52-11.25c0-2.05-1.89-2.06-.73-2.75l3.84-2.12c1.17-.64 2.78-.52 4.55.5A14.38 14.38 0 01188 365z"
                    fill="#474756"
                  />
                  <path
                    d="M184.24 367.14c0 4.14-2.93 5.8-6.53 3.72a14.38 14.38 0 01-6.52-11.25c0-4.14 2.92-5.8 6.52-3.72a14.39 14.39 0 016.53 11.25z"
                    fill="#545466"
                  />
                  <path
                    d="M177.66 358.86c-2.33-1.34-4.22-.26-4.22 2.4a9.3 9.3 0 004.22 7.28c2.32 1.34 4.22.26 4.22-2.41a9.32 9.32 0 00-4.22-7.27z"
                    fill="url(#prefix__linear-gradient-426)"
                  />
                  <path
                    d="M179.16 367.57a9.28 9.28 0 01-4.22-7.27 3.66 3.66 0 01.5-2c-1.2.16-2 1.22-2 2.95a9.3 9.3 0 004.22 7.28c1.61.93 3 .7 3.72-.42a3.52 3.52 0 01-2.22-.54z"
                    fill="url(#prefix__linear-gradient-427)"
                  />
                  <path
                    d="M180.58 365.76c0 1.65-1.16 2.31-2.6 1.49a5.75 5.75 0 01-2.6-4.49c0-1.65 1.17-2.31 2.6-1.48a5.69 5.69 0 012.6 4.48z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="url(#prefix__linear-gradient-428)"
                  />
                  <path
                    d="M180.37 364.82c0 1.39-1 1.95-2.2 1.25a4.83 4.83 0 01-2.2-3.8c0-1.39 1-2 2.2-1.25a4.83 4.83 0 012.2 3.8z"
                    fill="url(#prefix__linear-gradient-429)"
                  />
                  <path
                    d="M179.77 365.23c0 1.4-1 2-2.2 1.26a4.86 4.86 0 01-2.2-3.8c0-1.4 1-2 2.2-1.26a4.83 4.83 0 012.2 3.8z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M188 365c0 2-.7 3.47-1.84 4.17l-3.47 2c-1.19.67-.43-.84-2.23-1.88a14.45 14.45 0 01-6.62-11.29c0-2-1.9-2.07-.73-2.76 0 0 3.66-2 3.7-2 1.18-.64 2.8-.53 4.58.5A14.47 14.47 0 01188 365z"
                    fill="#4c4c5e"
                  />
                  <path
                    d="M184.31 367.05c0 4.16-2.94 5.83-6.56 3.74a14.44 14.44 0 01-6.56-11.32c0-4.16 2.93-5.83 6.56-3.74a14.47 14.47 0 016.56 11.32z"
                    fill="#474756"
                  />
                  <path
                    d="M182.43 366c0 3-2.1 4.16-4.68 2.67a10.33 10.33 0 01-4.68-8.07c0-3 2.1-4.16 4.68-2.67a10.34 10.34 0 014.68 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M182.43 366a4 4 0 01-.6 2.27 3 3 0 01-3.27-.19 9.61 9.61 0 01-4.36-7.53c0-2 1-3.13 2.49-3.09a5.39 5.39 0 011.06.47 10.34 10.34 0 014.68 8.07z"
                    fill="#e7f1f7"
                  />
                  <path
                    d="M175.08 357.32a3.24 3.24 0 00-1.35 3 10.3 10.3 0 004.67 8.06 3.76 3.76 0 002.68.58 3.29 3.29 0 01-3.33-.32 10.33 10.33 0 01-4.68-8.07c0-1.83.8-2.98 2.01-3.25z"
                    fill="#474756"
                  />
                  <path
                    d="M179.53 364c0 .78-.55 1.09-1.22.7a2.7 2.7 0 01-1.23-2.11c0-.78.55-1.09 1.23-.7a2.72 2.72 0 011.22 2.11z"
                    fill="url(#prefix__radial-gradient-24)"
                  />
                  <path
                    d="M177.8 359.24a1.36 1.36 0 00.62 1.06c.33.19.61 0 .61-.35a1.38 1.38 0 00-.61-1.06c-.34-.19-.62-.03-.62.35zm0 7.16a1.33 1.33 0 00.62 1.05c.33.2.61 0 .61-.34a1.38 1.38 0 00-.61-1.06c-.34-.19-.62-.05-.62.35zm-1.16-7.28c-.24-.41-.62-.63-.86-.49s-.24.58 0 1 .62.63.86.5.24-.59 0-1.01zm3.55 8.1c.24.42.62.64.86.5s.24-.58 0-1-.62-.63-.86-.5-.19.59 0 1zm-5.5-6.2a1.32 1.32 0 00.61 1.05c.33.2.61 0 .61-.34a1.38 1.38 0 00-.61-1.06c-.3-.19-.61-.04-.61.33zm6.23 3.6a1.38 1.38 0 00.61 1.06c.34.19.62 0 .62-.35a1.36 1.36 0 00-.62-1.06c-.33-.19-.61-.04-.61.35zm-5.14.06c.24.41.62.63.86.5s.24-.58 0-1-.62-.63-.86-.49-.24.58 0 .99zm4.41-2.52c.24.41.62.64.86.5s.24-.58 0-1-.62-.63-.86-.5-.19.59 0 1z"
                    fill="#474756"
                  />
                  <path
                    d="M180.31 364a1.28 1.28 0 01-.51 1.15l-.76.46c-.33.18-.12-.23-.62-.52a4 4 0 01-1.81-3.12c0-.57-.52-.57-.2-.76l.82-.46a1.3 1.3 0 011.27.13 4 4 0 011.81 3.12z"
                    fill="#545466"
                  />
                  <path
                    d="M179.5 364.46c0 1.15-.81 1.61-1.81 1a4 4 0 01-1.81-3.11c0-1.15.81-1.61 1.81-1a4 4 0 011.81 3.11z"
                    fill="#545466"
                  />
                  <g>
                    <path
                      fill="#9aa0a6"
                      d="M212.52 374.59v6.23l-21.01-12.19v-6.2l21.01 12.16zM212.52 374.59v6.23l5.91-3.36v-6.24l-5.91 3.37z"
                    />
                    <path
                      fill="url(#prefix__linear-gradient-430)"
                      d="M218.43 371.22l-5.91 3.37-21.01-12.16 5.91-3.37 21.01 12.16z"
                    />
                  </g>
                  <g>
                    <path
                      fill="#8c9399"
                      d="M152.65 339.37v7.93l-.78-.62v-7.91l.78.6zM152.65 339.37v7.93l18.9-10.62v-7.94l-18.9 10.63z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M171.55 328.74l-18.9 10.63-.78-.6 18.91-10.63.77.6z"
                    />
                  </g>
                  <path
                    fill="#c5d4dd"
                    d="M187.57 359.15v11.81l-2.11-1.21v-9.4l-28.64-16.54v9.4l-2.11-1.21v-11.82l32.86 18.97z"
                  />
                  <path
                    fill="#b4c4cc"
                    d="M187.58 359.17v11.82l2.04-1.15v-11.89l-2.04 1.22z"
                  />
                  <g>
                    <path
                      fill="#b4c4cc"
                      d="M209.06 379.79l-1.07.64-17.44-10.06 1.08-.61 17.43 10.03z"
                    />
                    <path
                      fill="#b4c4cc"
                      d="M190.53 363.99v6.39l1.1-.61v-6.44l-1.1.66z"
                    />
                    <path
                      d="M188.94 362.23v9l20.63 12v-9.1zm1.61 2.78L208 375v5.38l-17.42-10.1z"
                      fill="#c5d4dd"
                    />
                    <path
                      fill="#b4c4cc"
                      d="M209.57 374.11v9.1l1.35-.69v-9.15l-1.35.74zM210.92 373.37l-1.35.74-20.63-11.88 1.37-.73 20.61 11.87z"
                    />
                  </g>
                  <g>
                    <path
                      d="M229.25 389c0 2-.7 3.45-1.83 4.14l-3.62 2.1c-1.18.66-.42-.84-2.21-1.87a14.37 14.37 0 01-6.53-11.26c0-2-1.89-2.06-.73-2.74l3.84-2.13c1.17-.63 2.78-.52 4.55.51a14.36 14.36 0 016.53 11.25z"
                      fill="#474756"
                    />
                    <path
                      d="M225.47 391.13c0 4.13-2.92 5.8-6.53 3.72a14.38 14.38 0 01-6.52-11.26c0-4.13 2.92-5.8 6.52-3.72a14.37 14.37 0 016.53 11.26z"
                      fill="#545466"
                    />
                    <path
                      d="M218.89 382.85c-2.32-1.35-4.21-.27-4.21 2.4a9.3 9.3 0 004.21 7.27c2.33 1.35 4.22.27 4.22-2.4a9.28 9.28 0 00-4.22-7.27z"
                      fill="#b4c4cc"
                    />
                    <path
                      d="M221 389.22c0 1.39-1 2-2.21 1.25a4.86 4.86 0 01-2.2-3.8c0-1.39 1-2 2.2-1.25a4.84 4.84 0 012.21 3.8z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M229 388.86c0 2-.7 3.47-1.84 4.17l-3.48 2c-1.18.67-.42-.84-2.22-1.88a14.45 14.45 0 01-6.57-11.32c0-2.06-1.9-2.07-.73-2.76l3.69-2.05c1.19-.64 2.8-.52 4.59.51a14.47 14.47 0 016.56 11.33z"
                      fill="#4c4c5e"
                    />
                    <path
                      d="M225.35 390.93c0 4.16-2.94 5.83-6.56 3.74a14.45 14.45 0 01-6.57-11.32c0-4.16 2.94-5.83 6.57-3.74a14.47 14.47 0 016.56 11.32z"
                      fill="#474756"
                    />
                    <path
                      d="M223.46 389.84c0 3-2.09 4.16-4.67 2.66a10.28 10.28 0 01-4.68-8.06c0-3 2.09-4.16 4.68-2.67a10.3 10.3 0 014.67 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M223.46 389.84a4 4 0 01-.59 2.27 3 3 0 01-3.27-.19 9.65 9.65 0 01-4.37-7.53c0-2 1-3.14 2.5-3.09a5.66 5.66 0 011.06.47 10.3 10.3 0 014.67 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M216.11 381.19a3.26 3.26 0 00-1.34 3 10.3 10.3 0 004.67 8.07 3.8 3.8 0 002.67.58 3.25 3.25 0 01-3.32-.33 10.28 10.28 0 01-4.68-8.06c0-1.83.79-2.99 2-3.26z"
                      fill="#474756"
                    />
                    <path
                      d="M220.57 387.87c0 .77-.55 1.09-1.23.7a2.72 2.72 0 01-1.22-2.11c0-.78.55-1.09 1.22-.7a2.68 2.68 0 011.23 2.11z"
                      fill="url(#prefix__radial-gradient-25)"
                    />
                    <path
                      d="M218.84 383.12a1.35 1.35 0 00.61 1.05c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.33-.19-.61-.04-.61.35zm0 7.16a1.36 1.36 0 00.61 1.05c.34.2.61 0 .61-.35a1.34 1.34 0 00-.61-1.05c-.33-.2-.61-.04-.61.35zm-1.16-7.28c-.24-.41-.63-.63-.86-.5s-.24.58 0 1 .62.64.86.5.24-.59 0-1zm3.55 8.1c.23.41.62.64.86.5s.24-.58 0-1-.63-.64-.86-.5-.23.59 0 1zm-5.51-6.2a1.35 1.35 0 00.61 1.05c.34.2.62 0 .62-.35a1.36 1.36 0 00-.62-1.05c-.33-.19-.61-.04-.61.35zm6.24 3.6a1.32 1.32 0 00.61 1.05c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.34-.15-.57-.04-.57.35zm-5.14.06c.23.41.62.63.86.49s.24-.58 0-1-.63-.63-.86-.5-.24.59 0 1.01zm4.41-2.52c.23.41.62.64.86.5s.24-.58 0-1-.63-.64-.86-.5-.23.59 0 .96z"
                      fill="#474756"
                    />
                    <path
                      d="M221.34 387.87a1.28 1.28 0 01-.5 1.15l-.77.46c-.32.18-.11-.24-.61-.52a4 4 0 01-1.81-3.12c0-.57-.52-.57-.2-.76l.81-.46a1.3 1.3 0 011.27.13 4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                    <path
                      d="M220.54 388.34c0 1.15-.81 1.61-1.81 1a4 4 0 01-1.81-3.12c0-1.14.81-1.6 1.81-1a4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                  </g>
                  <g>
                    <path
                      fill="#cfdee8"
                      d="M252.97 399.35v9.09l-27.22-15.71v-9.02l27.22 15.64z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e7f1f7"
                      d="M233.51 388.23v8.76l-.44-.23v-8.78l.44.25z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M252.97 399.35v9.09l21.5-12.23v-9.15l-21.5 12.29z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M274.47 387.06l-21.5 12.29-27.22-15.64 21.52-12.27 27.2 15.62z"
                    />
                  </g>
                  <g fill="#545466">
                    <path d="M249.31 393.28v3.03l-97.99-56.98v-2.94l97.99 56.89zM249.31 393.28v3.03l26.88-15.29v-3.1l-26.88 15.36z" />
                    <path d="M276.19 377.92l-26.88 15.36-97.99-56.89 26.9-15.33 97.97 56.86z" />
                  </g>
                  <g>
                    <path
                      fill="#cfdee8"
                      d="M249.31 360.43v33l-97.99-56.99v-32.9l97.99 56.89z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e6e7e8"
                      d="M246.99 361.76l-93.34-54.19v27.54l93.34 54.28v-27.63z"
                    />
                    <g fill="#dce8f1">
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M244.03 360.43l1.22.81v27.63l-1.22-.81v-27.63zM245.31 361.13l.46-.02v27.63l-.46.01v-27.62zM240.02 358.11l1.22.81v27.62l-1.22-.81v-27.62zM241.3 358.8l.46-.02v27.63l-.46.02V358.8zM236.02 355.78l1.22.81v27.62l-1.22-.81v-27.62zM237.29 356.48l.47-.02v27.62l-.47.02v-27.62zM232.01 353.45l1.22.81v27.63l-1.22-.81v-27.63zM233.29 354.15l.46-.02v27.63l-.46.01v-27.62zM228.01 351.13l1.22.81v27.62l-1.22-.81v-27.62zM229.28 351.82l.47-.02v27.63l-.47.02v-27.63zM224 348.8l1.22.81v27.62l-1.22-.81V348.8zM225.28 349.5l.46-.02v27.62l-.46.02V349.5zM220 346.47l1.22.81v27.63l-1.22-.81v-27.63zM221.27 347.17l.47-.02v27.63l-.47.01v-27.62zM215.99 344.15l1.22.8v27.63l-1.22-.81v-27.62zM217.27 344.84l.46-.02v27.63l-.46.02v-27.63zM211.99 341.82l1.22.81v27.62l-1.22-.81v-27.62zM213.26 342.52l.47-.02v27.62l-.47.02v-27.62zM207.98 339.49l1.22.81v27.63l-1.22-.81v-27.63zM209.26 340.19l.46-.02v27.63l-.46.01v-27.62zM203.97 337.17l1.22.81v27.62l-1.22-.81v-27.62zM205.25 337.86l.47-.02v27.63l-.47.02v-27.63zM199.97 334.84l1.22.81v27.62l-1.22-.81v-27.62zM201.25 335.54l.46-.02v27.62l-.46.02v-27.62z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M195.96 332.51l1.22.81v27.63l-1.22-.81v-27.63zM197.24 333.21l.47-.02v27.63l-.47.01v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M191.96 330.19l1.22.81v27.62l-1.22-.81v-27.62zM193.24 330.88l.46-.01v27.62l-.46.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M187.95 327.86l1.22.81v27.63l-1.22-.81v-27.63zM189.23 328.56l.46-.02v27.62l-.46.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M183.95 325.54l1.22.8v27.63l-1.22-.81v-27.62zM185.22 326.23l.47-.02v27.63l-.47.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M179.94 323.21l1.22.81v27.62l-1.22-.81v-27.62zM181.22 323.9l.46-.02v27.63l-.46.02V323.9z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M175.94 320.88l1.22.81v27.63l-1.22-.82v-27.62zM177.21 321.58l.47-.02v27.62l-.47.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M171.93 318.56l1.22.8v27.63l-1.22-.81v-27.62zM173.21 319.25l.46-.02v27.63l-.46.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M167.93 316.23l1.22.81v27.62l-1.22-.81v-27.62zM169.2 316.93l.47-.03v27.63l-.47.02v-27.62z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M163.92 313.9l1.22.81v27.63l-1.22-.81V313.9zM165.2 314.6l.46-.02v27.62l-.46.02V314.6z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M159.91 311.57l1.23.81v27.63l-1.23-.81v-27.63zM161.19 312.27l.47-.02v27.63l-.47.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M155.91 309.25l1.22.81v27.62l-1.22-.81v-27.62zM157.19 309.95l.46-.02v27.62l-.46.02v-27.62z"
                        />
                      </g>
                    </g>
                    <path
                      fill="#dce8f1"
                      d="M249.31 360.43v33l26.88-15.3v-33.07l-26.88 15.37z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e6e7e8"
                      d="M273.86 376.78v-27.71l-22.23 12.7v27.66l22.23-12.65z"
                    />
                    <path
                      fill="#e7f1f7"
                      d="M276.19 345.06l-26.88 15.37-97.99-56.89 26.9-15.34 97.97 56.86z"
                    />
                    <g fill="#dce8f1">
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M253.88 360.93l-1.22.81v27.62l1.22-.81v-27.62zM252.61 361.62l-.47-.02v27.63l.47.02v-27.63zM257.5 358.8l-1.22.81v27.62l1.22-.81V358.8zM256.23 359.5l-.47-.02v27.62l.47.02V359.5zM261.12 356.67l-1.22.81v27.63l1.22-.81v-27.63zM259.85 357.37l-.47-.02v27.63l.47.01v-27.62z"
                      />
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M264.74 354.54l-1.22.81v27.63l1.22-.81v-27.63zM263.47 355.24l-.47-.02v27.63l.47.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M268.37 352.42l-1.23.81v27.62l1.23-.81v-27.62zM267.09 353.11l-.47-.02v27.63l.47.02v-27.63z"
                        />
                      </g>
                      <g>
                        <path
                          style={{ mixBlendMode: "multiply" }}
                          d="M271.99 350.29l-1.23.81v27.62l1.23-.81v-27.62zM270.71 350.99l-.47-.03v27.63l.47.02v-27.62z"
                        />
                      </g>
                    </g>
                  </g>
                  <g>
                    <path
                      d="M276.07 357.83l17.68 10.3a9.15 9.15 0 014.34 5.7l2.49 9.8a36.6 36.6 0 011.12 9v11.47l-25.21 14.27v-10.2a36.59 36.59 0 00-1.69-11L271.59 387a9.17 9.17 0 00-4.21-5.2l-16.52-9.63z"
                      fill="red"
                    />
                    <path
                      d="M276.5 408.08v10.25l-13.5-7.74-1-6-11.14-6.59v-25.86l16.58 9.67a9.12 9.12 0 014.22 5.22l3.18 10.16a36.5 36.5 0 011.66 10.89z"
                      fill="#e50000"
                    />
                    <path
                      d="M270.12 387.51l3.19 10.16a.81.81 0 01-1.18.94l-10-5.82a2.17 2.17 0 01-1.07-1.86v-8.35a.8.8 0 011.21-.69l6.2 3.61a3.64 3.64 0 011.63 2z"
                      fill="#26262e"
                    />
                    <path
                      d="M272.54 398.43a.54.54 0 00.42-.21.49.49 0 00.07-.46l-3.18-10.16a3.27 3.27 0 00-1.51-1.85l-6.19-3.61h-.2c-.38 0-.58.19-.58.46v8.35a1.86 1.86 0 00.92 1.61l10 5.82z"
                      fill="#616173"
                    />
                    <path
                      d="M280.79 400.1v3.77a5.21 5.21 0 007.8 4.52l3.61-2.06a12.7 12.7 0 006.4-11v-5.44z"
                      fill="#e50000"
                    />
                    <path
                      d="M286 407.69a3.78 3.78 0 001.89-.51l3.61-2.06a11.35 11.35 0 005.7-9.82v-3l-15 8.64v3a3.83 3.83 0 003.8 3.75z"
                      fill="#616173"
                    />
                    <path
                      d="M296 400.41l-11.82 6.79a4 4 0 00.74.32l10.44-6c.21-.36.42-.73.64-1.11zm1-2.9l-14.32 8.23a3.8 3.8 0 00.36.53l13.75-7.91c.07-.28.14-.56.21-.85zm.22-2.45l-15 8.64v.17a4.9 4.9 0 000 .54l15-8.61v-.5zm-15 6.31v.74l15-8.64v-.74z"
                      fill="#474756"
                      opacity={0.5}
                    />
                    <path
                      d="M277.37 398.73a1.49 1.49 0 00.74-.2l20.45-11.6a2.51 2.51 0 001.19-2.79l-2.56-10.08a8 8 0 00-.54-1.51.91.91 0 00-.82-.52.83.83 0 00-.44.12l-22.28 12.78a1.47 1.47 0 00-.65 1.73l3.49 11a1.48 1.48 0 001.42 1.05z"
                      fill="#26262e"
                    />
                    <path
                      d="M277.38 398.44a1.1 1.1 0 00.58-.16l20.45-11.6a2.22 2.22 0 001.06-2.47l-2.56-10.08a8.07 8.07 0 00-.52-1.45.64.64 0 00-.56-.36.52.52 0 00-.3.08l-22.28 12.78a1.19 1.19 0 00-.51 1.4l3.48 11a1.21 1.21 0 001.16.86z"
                      fill="#616173"
                    />
                    <path
                      d="M254.67 378.43l3 1.74a1.37 1.37 0 01.68 1.18v7.91a.17.17 0 01-.16.17l-2.86-1.65a1.28 1.28 0 01-.64-1.11v-8.24z"
                      fill="#26262e"
                    />
                    <path
                      d="M258 381.35a1.08 1.08 0 00-.54-.93l-2.46-1.49v7.74a1 1 0 00.5.86L258 389z"
                      fill="#616173"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="url(#prefix__linear-gradient-431)"
                      d="M261.81 397.3l-10.95.72 11.08 6.54-.13-7.26z"
                    />
                  </g>
                  <path
                    fill="#b4c4cc"
                    d="M254.2 402.89v4.99l3.8-2.13-.26-4.98-3.54 2.12z"
                  />
                  <g>
                    <path
                      d="M271.12 413.23c0 2-.7 3.47-1.84 4.17l-3.47 2c-1.19.67-.43-.84-2.23-1.88a14.47 14.47 0 01-6.58-11.31c0-2-1.9-2.07-.74-2.76l3.7-2.05c1.18-.64 2.8-.52 4.58.51a14.44 14.44 0 016.58 11.32z"
                      fill="#4c4c5e"
                    />
                    <path
                      d="M267.48 415.3c0 4.16-2.93 5.83-6.56 3.74a14.47 14.47 0 01-6.56-11.32c0-4.16 2.94-5.83 6.56-3.74a14.44 14.44 0 016.56 11.32z"
                      fill="#474756"
                    />
                    <path
                      d="M265.6 414.21c0 3-2.1 4.16-4.68 2.67a10.33 10.33 0 01-4.67-8.07c0-3 2.09-4.16 4.67-2.67a10.33 10.33 0 014.68 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M265.6 414.21a4.07 4.07 0 01-.59 2.27 3 3 0 01-3.28-.19 9.62 9.62 0 01-4.36-7.53c0-2 1-3.14 2.49-3.09a5.39 5.39 0 011.06.47 10.33 10.33 0 014.68 8.07z"
                      fill="#e7f1f7"
                    />
                    <path
                      d="M258.25 405.56a3.28 3.28 0 00-1.35 3 10.28 10.28 0 004.68 8.07 3.78 3.78 0 002.67.58 3.29 3.29 0 01-3.33-.32 10.33 10.33 0 01-4.67-8.07c0-1.82.75-2.99 2-3.26z"
                      fill="#474756"
                    />
                    <path
                      d="M262.7 412.24c0 .78-.54 1.09-1.22.7a2.69 2.69 0 01-1.22-2.11c0-.78.54-1.09 1.22-.7a2.67 2.67 0 011.22 2.11z"
                      fill="url(#prefix__radial-gradient-26)"
                    />
                    <path
                      d="M261 407.49a1.35 1.35 0 00.61 1.06c.34.19.61 0 .61-.35a1.35 1.35 0 00-.61-1.06c-.36-.14-.61-.04-.61.35zm0 7.16a1.32 1.32 0 00.61 1.05c.34.2.61 0 .61-.35a1.35 1.35 0 00-.61-1.05c-.36-.19-.61-.04-.61.35zm-1.16-7.28c-.24-.41-.63-.63-.87-.5s-.24.58 0 1 .63.63.87.49.21-.58-.02-.99zm3.54 8.1c.24.41.63.64.87.5s.23-.58 0-1-.63-.63-.87-.5-.26.59-.02 1zm-5.5-6.2a1.32 1.32 0 00.61 1.05c.34.2.61 0 .61-.34a1.35 1.35 0 00-.61-1.06c-.36-.19-.63-.04-.63.35zm6.24 3.6a1.32 1.32 0 00.61 1.05c.33.2.61 0 .61-.34a1.38 1.38 0 00-.61-1.06c-.36-.19-.63-.04-.63.35zm-5.15.06c.24.41.63.63.87.5s.23-.58 0-1-.63-.63-.87-.49-.26.58.03.99zm4.41-2.52c.24.41.63.64.87.5s.23-.58 0-1-.63-.64-.87-.5-.26.59-.02 1z"
                      fill="#474756"
                    />
                    <path
                      d="M263.48 412.24a1.26 1.26 0 01-.51 1.15l-.76.46c-.33.18-.12-.24-.62-.52a4 4 0 01-1.8-3.12c0-.57-.53-.57-.21-.76l.82-.46a1.3 1.3 0 011.27.13 4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                    <path
                      d="M262.67 412.71c0 1.15-.81 1.61-1.81 1a4 4 0 01-1.81-3.12c0-1.14.81-1.6 1.81-1a4 4 0 011.81 3.12z"
                      fill="#545466"
                    />
                  </g>
                  <path
                    fill="#e7f1f7"
                    d="M269.14 418.74v-7.23l-4.7-8.11-6.63-3.82 4-2.28 6.65 3.83 4.71 8.12v7.22l-4.03 2.27z"
                  />
                  <path
                    fill="#dce8f1"
                    d="M269.14 411.51l-4.7-8.11 4.02-2.27 4.71 8.12-4.03 2.26z"
                  />
                  <path
                    fill="#cfdee8"
                    d="M268.03 418.14v-6.33l-4.4-7.6-5.84-3.37-3.59 2.05v4.99l-1.11-.64v-5l4.7-2.68 6.65 3.84 4.7 8.11v7.27l-1.11-.64z"
                  />
                  <g>
                    <path
                      d="M303.59 401.63l-25.21 15a4 4 0 01-4 0L269.7 414v4.29l4.75 2.68a4 4 0 004 0l25.16-14.44z"
                      fill="#cfdee8"
                    />
                    <path
                      d="M303.16 402.45l-24.78 14.14a4 4 0 01-4 0L269.7 414l1.29-.74 3.41 1.93a4 4 0 004 0l23.28-13.43V400l1.51.93a.88.88 0 01-.03 1.52z"
                      fill="#e7f1f7"
                    />
                    <g style={{ mixBlendMode: "color-dodge" }}>
                      <path
                        fill="url(#prefix__radial-gradient-27)"
                        d="M301.71 401.43l-.03.02-.01-1.44.03.02.01 1.4z"
                      />
                      <path
                        d="M270 413.8l4.47 2.51a4 4 0 004 0l24.78-14.14a.87.87 0 00.41-.52.89.89 0 01-.44.81l-24.78 14.14a4 4 0 01-4 0l-4.74-2.6z"
                        fill="url(#prefix__radial-gradient-28)"
                      />
                    </g>
                  </g>
                  <g>
                    <path
                      d="M250.81 364.49l24.8-14.23 9.36 5.62a13.15 13.15 0 016.53 11.36l-24.74 14.09z"
                      fill="#ff4a4a"
                    />
                    <path
                      d="M266.74 381.29l-15.94-9.36v-7.49l9.41 5.49a13.12 13.12 0 016.53 11.36z"
                      fill="#e50000"
                    />
                  </g>
                  <path
                    d="M282.16 410.35l-4.7 2.65a.66.66 0 01-1-.57v-2.05a1.75 1.75 0 01.94-1.56l1.48-.78a1.76 1.76 0 011.86.14l1.46 1.07a.66.66 0 01-.04 1.1zM301.7 397.82v1.7a.63.63 0 01-.34.56l-2 1.05a.48.48 0 01-.67-.6l.81-2.22a1.3 1.3 0 01.67-.73l.68-.33a.63.63 0 01.85.57z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e7f1f7"
                  />
                  <path
                    d="M282.16 410l-4.7 2.65a.66.66 0 01-1-.57V410a1.75 1.75 0 01.94-1.56l1.48-.78a1.76 1.76 0 011.86.14l1.46 1.07a.65.65 0 01-.04 1.13zM301.7 397.55v1.7a.63.63 0 01-.34.56l-2 1.05a.48.48 0 01-.67-.6l.81-2.22a1.35 1.35 0 01.67-.74l.68-.32a.62.62 0 01.85.57z"
                    fill="#ffc950"
                  />
                  <path
                    d="M281.75 415.56l-3.53 2 1 .69a1.6 1.6 0 001.7.05l3.64-2.12-1.32-.68a1.6 1.6 0 00-1.49.06zM302.73 403.74l-3.79 2.17a1.61 1.61 0 00-.7.82l-.91 2.32 4-2.32a1.63 1.63 0 00.72-.89zM296.49 410.51v-.8a1.1 1.1 0 00-1.65-1l-7.24 4.17a3.61 3.61 0 00-1.82 3.14v.63z"
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e7f1f7"
                  />
                  <path
                    fill="#e7f1f7"
                    d="M287.82 413.55v1.94l6.99-3.96v-1.98l-6.99 4z"
                  />
                  <path
                    d="M289.9 407.09c-5.38 2.86-9.28 6.05-8.71 7.13s5.4-.37 10.78-3.23 9.28-6.06 8.71-7.13-5.4.37-10.78 3.23z"
                    style={{ mixBlendMode: "color-dodge" }}
                    fill="url(#prefix__radial-gradient-29)"
                  />
                </g>
              </g>
              <motion.g id="prefix__container1"
              
              variants={containerVariant}
              initial="start"
              animate={containerControls}
              
              >
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_27)"
                  d="M641.26 471.5v30.01l-89.12-51.83v-29.92l89.12 51.74z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#eaeaea"
                  d="M639.15 472.71l-84.9-49.28v25.04l84.9 49.36v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M636.45 471.5l1.11.74v25.12l-1.11-.74V471.5z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M637.61 472.13l.43-.01v25.12l-.43.02v-25.13z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M632.81 469.38l1.11.74v25.12l-1.11-.73v-25.13z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M633.97 470.02l.42-.02v25.12l-.42.02v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M629.17 467.27l1.11.73v25.13l-1.11-.74v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M630.33 467.9l.42-.02v25.13l-.42.01V467.9z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M625.52 465.15l1.11.74v25.12l-1.11-.74v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M626.69 465.79l.42-.02v25.12l-.42.02v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M621.88 463.04l1.11.73v25.13l-1.11-.74v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M623.04 463.67l.43-.02v25.13l-.43.01v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M618.24 460.92l1.11.74v25.12l-1.11-.74v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M619.4 461.56l.42-.02v25.12l-.42.02v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M614.6 458.81l1.11.73v25.13l-1.11-.74v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M615.76 459.44l.42-.02v25.13l-.42.01v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M610.95 456.69l1.11.74v25.12l-1.11-.74v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M612.12 457.32l.42-.01v25.12l-.42.01v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M607.31 454.57l1.11.74v25.12l-1.11-.73v-25.13z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M608.47 455.21l.42-.02v25.12l-.42.02v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M603.67 452.46l1.11.73v25.13l-1.11-.74v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M604.83 453.09l.42-.02v25.13l-.42.01v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M600.03 450.34l1.11.74v25.12l-1.11-.74v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M601.19 450.98l.42-.02v25.12l-.42.02v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#5c5d5e"
                  d="M596.38 448.23l1.11.73v25.13l-1.11-.74v-25.12z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#c8c8c8"
                  d="M597.54 448.86l.43-.02v25.13l-.43.01v-25.12z"
                />
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M592.74 446.11l1.11.74v25.12l-1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M593.9 446.75l.43-.02v25.12l-.43.02v-25.12z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M589.1 444l1.11.73v25.12l-1.11-.73V444z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M590.26 444.63l.42-.02v25.12l-.42.02v-25.12z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M585.46 441.88l1.11.73v25.13l-1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M586.62 442.51l.42-.01v25.12l-.42.01v-25.12z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M581.81 439.76l1.11.74v25.12l-1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M582.97 440.4l.43-.02v25.12l-.43.02V440.4z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M578.17 437.65l1.11.73v25.13l-1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M579.33 438.28l.42-.02v25.13l-.42.01v-25.12z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M574.53 435.53l1.11.74v25.12l-1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M575.69 436.17l.42-.02v25.12l-.42.02v-25.12z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M570.88 433.42l1.12.73v25.12l-1.12-.73v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M572.05 434.05l.42-.02v25.12l-.42.02v-25.12z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M567.24 431.3l1.11.74v25.12l-1.11-.74V431.3z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M568.4 431.93l.43-.01v25.12l-.43.02v-25.13z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M563.6 429.18l1.11.74v25.12l-1.11-.73v-25.13z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M564.76 429.82l.42-.02v25.12l-.42.02v-25.12z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M559.96 427.07l1.11.73v25.13l-1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M561.12 427.7l.42-.02v25.13l-.42.01V427.7z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M556.31 424.95l1.11.74v25.12l-1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M557.48 425.59l.42-.02v25.12l-.42.02v-25.12z"
                  />
                </g>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#dadada"
                  d="M639.15 472.71v2.21L555 426.07v22.83l-.75-.43v-25.04l84.9 49.28z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_27-2)"
                  d="M641.25 471.5v30.01l24.45-13.91v-30.08l-24.45 13.98z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#eaeaea"
                  d="M663.58 486.37v-25.21l-20.22 11.56v25.16l20.22-11.51z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_27-3)"
                  d="M665.7 457.52l-24.45 13.98-89.11-51.74 24.47-13.95 89.09 51.71z"
                />
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M645.41 471.95l-1.11.74v25.12l1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M644.25 472.58l-.42-.01v25.12l.42.02v-25.13z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M648.71 470.02l-1.11.73v25.13l1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M647.54 470.65l-.42-.02v25.12l.42.02v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M652 468.08l-1.11.74v25.12l1.11-.74v-25.12z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M650.84 468.71l-.43-.01v25.12l.43.02v-25.13z"
                  />
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M655.29 466.15l-1.11.73V492l1.11-.73v-25.12z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M654.13 466.78l-.42-.02v25.12l.42.02v-25.12z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M658.58 464.21l-1.11.74v25.12l1.11-.74v-25.12z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M657.42 464.84l-.42-.01v25.12l.42.02v-25.13z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M661.88 462.27l-1.11.74v25.12l1.11-.73v-25.13z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M660.72 462.91l-.43-.02v25.12l.43.02v-25.12z"
                    />
                  </g>
                </g>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#dadada"
                  d="M642.72 473.16v2.21l20.48-11.98v22.83l.73-.43v-25.04l-21.21 12.41z"
                />
              </motion.g>
              <motion.g id="prefix__fixedcontainers"
              
              variants={trainVariant}
              initial="start"
              animate={trainControls}
              
              
              >
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_28)"
                  d="M736.68 562.71v30.05l-89.24-51.89V510.9l89.24 51.81z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#eceded"
                  d="M734.57 563.93l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M731.87 562.72l1.11.73v25.16l-1.11-.74v-25.15z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M733.03 563.35l.42-.02v25.16l-.42.02v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M728.22 560.6l1.11.73v25.16l-1.11-.74V560.6z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M729.38 561.23l.43-.02v25.16l-.43.02v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M724.57 558.48l1.11.73v25.16l-1.11-.74v-25.15z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M725.74 559.11l.42-.02v25.16l-.42.02v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M720.92 556.36l1.12.74v25.15l-1.12-.74v-25.15z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M722.09 556.99l.42-.01v25.15l-.42.02v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M717.28 554.24l1.11.74v25.15l-1.11-.73v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M718.44 554.87l.42-.01v25.15l-.42.02v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M713.63 552.12l1.11.74v25.16l-1.11-.74v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M714.79 552.76l.43-.02v25.16l-.43.01v-25.15z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M709.98 550l1.11.74v25.16l-1.11-.74V550z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M711.14 550.64l.43-.02v25.16l-.43.01v-25.15z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M706.33 547.88l1.12.74v25.16l-1.12-.74v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M707.5 548.52l.42-.02v25.16l-.42.01v-25.15z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M702.69 545.76l1.11.74v25.16l-1.11-.74v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M703.85 546.4l.42-.02v25.16l-.42.02V546.4z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M699.04 543.65l1.11.73v25.16l-1.11-.74v-25.15z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M700.2 544.28l.43-.02v25.16l-.43.02v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M695.39 541.53l1.11.73v25.16l-1.11-.74v-25.15z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M696.55 542.16l.43-.02v25.16l-.43.02v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "screen" }}
                  fill="#707372"
                  d="M691.74 539.41l1.12.73v25.16l-1.12-.73v-25.16z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#cfcfcf"
                  d="M692.91 540.04l.42-.02v25.16l-.42.02v-25.16z"
                />
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M688.1 537.29l1.11.74v25.15l-1.11-.73v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M689.26 537.92l.42-.01v25.16l-.42.01v-25.16z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M684.45 535.17l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M685.61 535.81l.42-.02v25.16l-.42.01v-25.15z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M680.8 533.05l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M681.96 533.69l.43-.02v25.16l-.43.01v-25.15z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M677.15 530.93l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M678.32 531.57l.42-.02v25.16l-.42.02v-25.16z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M673.5 528.82l1.12.73v25.16l-1.12-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M674.67 529.45l.42-.02v25.16l-.42.02v-25.16z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M669.86 526.7l1.11.73v25.16l-1.11-.74V526.7z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M671.02 527.33l.42-.02v25.16l-.42.02v-25.16z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M666.21 524.58l1.11.73v25.16l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M667.37 525.21l.43-.02v25.16l-.43.02v-25.16z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M662.56 522.46l1.11.74v25.15l-1.11-.73v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M663.73 523.09l.42-.01v25.15l-.42.02v-25.16z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M658.91 520.34l1.12.74v25.16l-1.12-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M660.08 520.98l.42-.02v25.16l-.42.01v-25.15z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M655.27 518.22l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M656.43 518.86l.42-.02V544l-.42.01v-25.15z"
                  />
                </g>
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M651.62 516.1l1.11.74V542l-1.11-.74V516.1z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M652.78 516.74l.43-.02v25.16l-.43.01v-25.15z"
                  />
                </g>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#dedfdf"
                  d="M734.57 563.93v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_28-2)"
                  d="M736.67 562.71v30.05l24.48-13.92v-30.12l-24.48 13.99z"
                />
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#eceded"
                  d="M759.04 577.61v-25.25l-20.25 11.58v25.19l20.25-11.52z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_28-3)"
                  d="M761.15 548.72l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                />
                <g>
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M740.84 563.17l-1.11.73v25.16l1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M739.68 563.8l-.42-.02v25.16l.42.02V563.8z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M744.14 561.23l-1.11.73v25.16l1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M742.98 561.86l-.43-.02V587l.43.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#707372"
                    d="M747.44 559.29l-1.12.74v25.15l1.12-.73v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#cfcfcf"
                    d="M746.27 559.92l-.42-.01v25.16l.42.01v-25.16z"
                  />
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#707372"
                      d="M750.73 557.35l-1.11.74v25.16l1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cfcfcf"
                      d="M749.57 557.99l-.42-.02v25.16l.42.01v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#707372"
                      d="M754.03 555.41l-1.11.74v25.16l1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cfcfcf"
                      d="M752.87 556.05l-.43-.02v25.16l.43.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#707372"
                      d="M757.33 553.48l-1.11.73v25.16l1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#cfcfcf"
                      d="M756.16 554.11l-.42-.02v25.16l.42.02v-25.16z"
                    />
                  </g>
                </g>
                <path
                  style={{ mixBlendMode: "multiply" }}
                  fill="#dedfdf"
                  d="M738.14 564.38v2.21l20.51-11.99v22.86l.74-.44v-25.07l-21.25 12.43z"
                />
                <g>
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_27-4)"
                    d="M832.56 619.29v30.05l-89.24-51.89v-29.97l89.24 51.81z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#eaeaea"
                    d="M830.45 620.51l-85.01-49.35v25.07l85.01 49.44v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M827.75 619.29l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M828.91 619.93l.43-.02v25.16l-.43.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M824.1 617.18l1.11.73v25.16l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M825.26 617.81l.43-.02v25.16l-.43.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M820.45 615.06l1.12.73v25.16l-1.12-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M821.62 615.69l.42-.02v25.16l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M816.81 612.94l1.11.73v25.16l-1.11-.73v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M817.97 613.57l.42-.02v25.16l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M813.16 610.82l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M814.32 611.46l.43-.02v25.16l-.43.01v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M809.51 608.7l1.11.74v25.16l-1.11-.74V608.7z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M810.67 609.34l.43-.02v25.16l-.43.01v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M805.86 606.58l1.12.74v25.16l-1.12-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M807.03 607.22l.42-.02v25.16l-.42.01v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M802.22 604.46l1.11.74v25.16l-1.11-.74v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M803.38 605.1l.42-.02v25.16l-.42.01V605.1z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M798.57 602.35l1.11.73v25.16l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M799.73 602.98l.42-.02v25.16l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M794.92 600.23l1.11.73v25.16l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M796.08 600.86l.43-.02V626l-.43.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M791.27 598.11l1.11.73V624l-1.11-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M792.44 598.74l.42-.02v25.16l-.42.02v-25.16z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#5c5d5e"
                    d="M787.62 595.99l1.12.74v25.15l-1.12-.74v-25.15z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#c8c8c8"
                    d="M788.79 596.62l.42-.01v25.15l-.42.02v-25.16z"
                  />
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M783.98 593.87l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M785.14 594.5l.42-.01v25.15l-.42.02V594.5z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M780.33 591.75l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M781.49 592.39l.43-.02v25.16l-.43.01v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M776.68 589.63l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M777.85 590.27l.42-.02v25.16l-.42.01v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M773.03 587.51l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M774.2 588.15l.42-.02v25.16l-.42.01v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M769.39 585.4l1.11.73v25.16l-1.11-.74V585.4z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M770.55 586.03l.42-.02v25.16l-.42.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M765.74 583.28l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M766.9 583.91l.43-.02v25.16l-.43.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M762.09 581.16l1.11.73v25.16l-1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M763.25 581.79l.43-.02v25.16l-.43.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M758.44 579.04l1.11.74v25.15l-1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M759.61 579.67l.42-.01v25.15l-.42.02v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M754.79 576.92l1.12.74v25.16l-1.12-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M755.96 577.55l.42-.01v25.16l-.42.01v-25.16z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M751.15 574.8l1.11.74v25.16l-1.11-.74V574.8z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M752.31 575.44l.42-.02v25.16l-.42.01v-25.15z"
                    />
                  </g>
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M747.5 572.68l1.11.74v25.16l-1.11-.74v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M748.66 573.32l.43-.02v25.16l-.43.01v-25.15z"
                    />
                  </g>
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#dadada"
                    d="M830.45 620.51v2.21l-84.27-48.92v22.86l-.74-.43v-25.07l85.01 49.35z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_27-5)"
                    d="M832.55 619.29v30.05l24.48-13.92V605.3l-24.48 13.99z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#eaeaea"
                    d="M854.92 634.19v-25.25l-20.25 11.58v25.19l20.25-11.52z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_27-6)"
                    d="M857.03 605.3l-24.48 13.99-89.23-51.81 24.5-13.97 89.21 51.79z"
                  />
                  <g>
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M836.72 619.75l-1.11.73v25.16l1.11-.74v-25.15z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M835.56 620.38l-.42-.02v25.16l.42.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M840.02 617.81l-1.11.73v25.16l1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M838.86 618.44l-.43-.02v25.16l.43.02v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "screen" }}
                      fill="#5c5d5e"
                      d="M843.32 615.87l-1.11.74v25.15l1.11-.73v-25.16z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#c8c8c8"
                      d="M842.15 616.5l-.42-.01v25.15l.42.02V616.5z"
                    />
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#5c5d5e"
                        d="M846.61 613.93l-1.11.74v25.16l1.11-.74v-25.16z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c8c8c8"
                        d="M845.45 614.57l-.42-.02v25.16l.42.01v-25.15z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#5c5d5e"
                        d="M849.91 612l-1.11.73v25.16l1.11-.74V612z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c8c8c8"
                        d="M848.75 612.63l-.42-.02v25.16l.42.02v-25.16z"
                      />
                    </g>
                    <g>
                      <path
                        style={{ mixBlendMode: "screen" }}
                        fill="#5c5d5e"
                        d="M853.21 610.06l-1.11.73v25.16l1.11-.74v-25.15z"
                      />
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        fill="#c8c8c8"
                        d="M852.04 610.69l-.42-.02v25.16l.42.02v-25.16z"
                      />
                    </g>
                  </g>
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#dadada"
                    d="M834.02 620.96v2.21l20.51-12v22.87l.74-.43v-25.08l-21.25 12.43z"
                  />
                </g>
                <g>
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-273)"
                    d="M890.99 653.94v26.98l-48.96-28.34v-26.91l48.96 28.27z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_16)"
                    d="M890.99 664.48v16.44l-48.96-28.34v-16.36l48.96 28.26z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#e7e7e7"
                    d="M889.09 655.03l-45.16-26.06v22.52l45.16 26.13v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M885.09 653.17l1 .67v22.59l-1-.67v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M886.13 653.74l.38-.01v22.59l-.38.02v-22.6z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M881.81 651.27l1 .66v22.59l-1-.66v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M882.86 651.84l.38-.01v22.59l-.38.01v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M878.54 649.37l.99.66v22.59l-.99-.66v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M879.58 649.94l.38-.02v22.59l-.38.02v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M875.26 647.47l1 .66v22.59l-1-.66v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M876.31 648.04l.38-.02v22.59l-.38.02v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M871.99 645.56l.99.67v22.59l-.99-.67v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M873.03 646.13l.38-.01v22.59l-.38.01v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M868.71 643.66l1 .66v22.59l-1-.66v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M869.75 644.23l.38-.01v22.59l-.38.01v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M865.43 641.76l1 .66v22.59l-1-.66v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M866.48 642.33l.38-.02v22.59l-.38.02v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M862.16 639.86l1 .66v22.59l-1-.66v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M863.2 640.43l.38-.02V663l-.38.02v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M858.88 637.95l1 .67v22.59l-1-.67v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M859.93 638.52l.38-.01v22.59l-.38.01v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M855.61 636.05l1 .66v22.59l-1-.66v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M856.65 636.62l.38-.01v22.59l-.38.01v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M852.33 634.15l1 .66v22.59l-1-.66v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M853.38 634.72l.38-.02v22.59l-.38.02v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M849.06 632.25l.99.66v22.59l-.99-.66v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M850.1 632.82l.38-.02v22.59l-.38.02v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "screen" }}
                    fill="#414141"
                    d="M845.78 630.34l1 .66v22.6l-1-.67v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#bebebe"
                    d="M846.83 630.91l.38-.01v22.59l-.38.01v-22.59z"
                  />
                  <path
                    style={{ mixBlendMode: "multiply" }}
                    fill="#d3d3d3"
                    d="M889.09 655.03v1.99l-44.49-25.67v20.53l-.67-.39v-22.52l45.16 26.06z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-274)"
                    d="M890.98 653.94v26.98l18.62-10.46v-27.05l-18.62 10.53z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_16-2)"
                    d="M890.98 664.48v16.44l18.62-10.46v-16.5l-18.62 10.52z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-275)"
                    d="M909.6 643.41l-18.62 10.53-48.95-28.27 18.63-10.5 48.94 28.24z"
                  />
                  <g>
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-276)"
                      d="M917 663.88l-3.69 5.88v26.28l-23.83-13.8v-26.71l3.69-5.37L917 663.88z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-277)"
                      d="M917 663.88l-3.69 5.88-23.83-14.23 3.69-5.37L917 663.88z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_16-3)"
                      d="M913.31 679.47v16.57l-23.83-13.8v-16.48l23.83 13.71z"
                    />
                    <path
                      d="M900.56 663.72v4.43a.73.73 0 01-1.09.63l-7.34-4.25a.73.73 0 01-.36-.63v-4.8a.73.73 0 011.09-.63l7 4a1.38 1.38 0 01.7 1.25z"
                      fill="url(#prefix__linear-gradient-432)"
                    />
                    <path
                      d="M892.4 663.39v-3.95a.29.29 0 01.43-.25l6.61 3.8a1 1 0 01.49.85v4a.29.29 0 01-.44.25l-6.6-3.82a1 1 0 01-.49-.88z"
                      fill="url(#prefix__linear-gradient-433)"
                    />
                    <path
                      d="M910.58 669.52V674a.73.73 0 01-1.09.63l-7-4.06a1.39 1.39 0 01-.69-1.21v-4.46a.72.72 0 011.08-.63l7 4a1.4 1.4 0 01.7 1.25z"
                      fill="url(#prefix__linear-gradient-434)"
                    />
                    <path
                      d="M902.42 669.19v-4a.29.29 0 01.43-.25l6.61 3.8a1 1 0 01.49.85v4a.29.29 0 01-.44.25l-6.6-3.82a1 1 0 01-.49-.83z"
                      fill="url(#prefix__linear-gradient-435)"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-278)"
                      d="M916.99 663.88l-3.69 5.62v26.54l22.59-12.85v-26.78l-3.69-1.23-15.21 8.7z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_16-4)"
                      d="M913.3 679.47v16.57l22.59-12.85v-16.63l-22.59 12.91z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-279)"
                      d="M932.2 655.18l-15.21 8.7-23.82-13.72 15.22-8.68 23.81 13.7z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_17-280)"
                      d="M908.39 641.48l23.81 13.7 3.69 1.23-23.73-13.89-3.77-1.04z"
                    />
                    <path
                      d="M925.38 664.11v4.44a.72.72 0 001.09.62l7-4a1.39 1.39 0 00.69-1.21v-4.46a.73.73 0 00-1.09-.63l-7 4a1.4 1.4 0 00-.69 1.24z"
                      fill="url(#prefix__linear-gradient-436)"
                    />
                    <path
                      d="M933.54 663.78v-3.94a.28.28 0 00-.43-.25l-6.61 3.8a1 1 0 00-.49.85v4a.29.29 0 00.44.25l6.6-3.83a1 1 0 00.49-.88z"
                      fill="url(#prefix__linear-gradient-437)"
                    />
                    <path
                      d="M915.36 669.91v4.44a.72.72 0 001.09.62l7-4.05a1.4 1.4 0 00.7-1.21v-4.41a.73.73 0 00-1.09-.63l-7 4a1.4 1.4 0 00-.7 1.24z"
                      fill="url(#prefix__linear-gradient-438)"
                    />
                    <path
                      d="M923.52 669.58v-3.94a.28.28 0 00-.43-.25l-6.6 3.8a1 1 0 00-.5.85v4a.3.3 0 00.44.25l6.6-3.83a1 1 0 00.49-.88z"
                      fill="url(#prefix__linear-gradient-439)"
                    />
                    <path
                      d="M933.54 663.78v-3.94a.28.28 0 00-.43-.25l-6.61 3.8a1 1 0 00-.49.85l7 .39a1 1 0 00.53-.85z"
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#prefix__linear-gradient-440)"
                    />
                    <path
                      d="M923.52 669.58v-3.94a.28.28 0 00-.43-.25l-6.6 3.8a1 1 0 00-.5.85l7 .39a1 1 0 00.53-.85z"
                      style={{ mixBlendMode: "screen" }}
                      fill="url(#prefix__linear-gradient-441)"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_16-5)"
                      d="M923.94 685.54v16.56l-10.55-6.07v-16.48l10.55 5.99z"
                    />
                    <path
                      style={{ mixBlendMode: "multiply" }}
                      fill="#e7e7e7"
                      d="M915.49 694.81v-11.64l6.34 3.6v11.69l-6.34-3.65z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_16-6)"
                      d="M923.94 685.54l11.42-9.27-10.55-6.07-11.42 9.35 10.55 5.99z"
                    />
                    <path
                      fill="url(#prefix__New_Gradient_Swatch_copy_16-7)"
                      d="M946.44 672.59l-11.08 3.68-10.55-6.07 11.08-3.6 10.55 5.99z"
                    />
                    <g fill="#e7e7e7">
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M900.47 677.07l-8.88-5.12v9.07l8.88 5.14v-9.09zM901.79 677.82v9.11l9.41 5.45v-9.14l-9.41-5.42z"
                      />
                    </g>
                    <g fill="#bebebe">
                      <path
                        style={{ mixBlendMode: "multiply" }}
                        d="M900.47 681.29l-8.88-5.11v.62l8.88 5.15v-.66zM900.47 683.4l-8.88-5.11v.62l8.88 5.15v-.66zM900.47 685.51l-8.88-5.11v.62l8.88 5.14v-.65zM900.47 679.18l-8.88-5.11v.62l8.88 5.15v-.66zM900.47 677.07l-8.88-5.11v.63l8.88 5.14v-.66zM901.79 682.71l9.41 5.45v-.69l-9.41-5.42v.66zM901.79 677.83v.66l9.41 5.45v-.69l-9.41-5.42zM901.79 686.93l9.41 5.45v-.69l-9.41-5.42v.66zM901.79 680.6l9.41 5.45v-.69l-9.41-5.42v.66zM901.79 684.82l9.41 5.45v-.69l-9.41-5.42v.66z"
                      />
                    </g>
                    <path
                      style={{ mixBlendMode: "color-dodge" }}
                      fill="url(#prefix__Radial_Gradient_1-39)"
                      d="M913.39 679.55l10.11 6.34.44 16.21.35-16.43 11.07-9.4-11.69 8.92-10.28-5.64z"
                    />
                  </g>
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-281)"
                    d="M863.73 632.24v1.94l-10.8-6.25v-1.89l10.8 6.2z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-282)"
                    d="M863.72 632.24v1.94l11.26-6.33v-1.97l-11.26 6.36z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-283)"
                    d="M874.98 625.88l-11.26 6.36-10.79-6.2 11.27-6.35 10.78 6.19z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-284)"
                    d="M884.26 642.25v1.94l-7.73-4.42v-1.89l7.73 4.37z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-285)"
                    d="M884.26 642.25v1.94l7.59-4.08v-1.97l-7.59 4.11z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-286)"
                    d="M891.85 638.14l-7.59 4.11-7.73-4.37 7.6-4.1 7.72 4.36z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-287)"
                    d="M916.69 656.36v1.94l-12.48-7.32v-1.89l12.48 7.27z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-288)"
                    d="M916.69 656.36v1.94l7.59-4.08v-1.98l-7.59 4.12z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_17-289)"
                    d="M924.28 652.24l-7.59 4.12-12.48-7.27 7.6-4.11 12.47 7.26z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_16-8)"
                    d="M923.94 685.54v16.56l22.32-12.83v-16.68l-10.9 3.68-11.42 9.27z"
                  />
                  <path
                    fill="url(#prefix__New_Gradient_Swatch_copy_24-111)"
                    d="M933.77 679.39v9.31l4.58-2.63v-9.28l-4.58 2.6z"
                  />
                  <path
                    d="M937.52 679.8a3.16 3.16 0 01-1.43 2.47c-.79.46-1.43.09-1.43-.82a3.14 3.14 0 011.43-2.46c.79-.46 1.43-.09 1.43.81z"
                    fill="url(#prefix__linear-gradient-442)"
                  />
                  <path
                    d="M937.52 683.89a3.16 3.16 0 01-1.43 2.47c-.79.45-1.43.09-1.43-.82a3.16 3.16 0 011.43-2.47c.79-.45 1.43-.07 1.43.82z"
                    fill="url(#prefix__linear-gradient-443)"
                  />
                  <path
                    d="M878.15 665.64l11.08 6.36v-1.25l-47.61-27.34.54-.33-.12-1.19-1.47.92-1.86-1.07 3.45-2.08-.12-1.2-4.64 2.84a.53.53 0 00-.25.45v11.08l1.06.52v-7.26l12.48 7.17v7.47l1.05.53v-7.39l11.87 6.81v7.52l1 .53v-7.44l12.48 7.17v7.48l1 .52v-7.39l11.08 6.36v-1.25l-11.05-6.33zm-40-23l1.34.78-1.34.81zm.51 2.51l1.87-1.13 10.1 5.79V652zm13 7.48v-2.21l11.87 6.82v2.21zm12.92 7.42v-2.21L877.1 665v2.21z"
                    fill="#2d2d2d"
                  />
                  <g>
                    <path
                      d="M937.12 680.18l-.73 1.55s-1.19-1-1-1.51 1.73-.04 1.73-.04z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_14)"
                    />
                    <path
                      d="M936.15 681.28c0 .39.27.55.62.35a1.35 1.35 0 00.62-1.06c0-.4-.28-.55-.62-.36a1.36 1.36 0 00-.62 1.07z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_14-2)"
                    />
                  </g>
                  <g>
                    <path
                      d="M937 684.31l-.74 1.55s-1.19-1-1-1.51 1.74-.04 1.74-.04z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_14-3)"
                    />
                    <path
                      d="M936 685.41c0 .39.28.55.62.35a1.35 1.35 0 00.62-1.06c0-.4-.28-.55-.62-.36a1.38 1.38 0 00-.62 1.07z"
                      fill="url(#prefix__New_Gradient_Swatch_copy_14-4)"
                    />
                  </g>
                  <path
                    d="M932.94 681.78c.65 1.49 2.69 2 4.56 1.22s2.86-2.68 2.22-4.17-2.69-2-4.56-1.21-2.87 2.68-2.22 4.16z"
                    style={{ mixBlendMode: "color-dodge" }}
                    fill="url(#prefix__radial-gradient-30)"
                  />
                  <path
                    d="M932.94 685.83c.65 1.48 2.69 2 4.56 1.21s2.86-2.68 2.22-4.16-2.69-2-4.56-1.22-2.87 2.68-2.22 4.17z"
                    style={{ mixBlendMode: "color-dodge" }}
                    fill="url(#prefix__radial-gradient-31)"
                  />
                  <path
                    d="M947.44 680.38l-5-1.73a.57.57 0 00-.44 0l-12.08 7a.58.58 0 00-.18.17l-4 6.26a.52.52 0 00-.09.29v10.83l1.06-.68v-3.2.05l3.41-2v3.26l1-.68v-3.05l10.66-6.13V694l1.05-.69v-3.12l3.9-2.24v3.25l1-.68v-9.62a.53.53 0 00-.29-.52zm-.69 1.65l-3.9 2.24v-4.35l3.9 1.34zm-15.61 4.2L941.8 680v4.86L931.14 691zm-1 1v4.33l-3.43 2v-1zm-3.43 11v-3.53l3.41-2v3.47zm4.48-2.58v-3.46l10.66-6.13v3.47zm11.66-6.65v-3.46l3.9-2.24v3.46z"
                    fill="#2d2d2d"
                  />
                  <path
                    d="M960.81 686.82s-7.12 7.49-7.25 7.39-19.07-12-19-12.2l.83-2.25a1.32 1.32 0 01.67-.74l.69-.33c.44-.2 24.06 7.67 24.06 8.13z"
                    style={{ mixBlendMode: "screen" }}
                    opacity={0.5}
                    fill="url(#prefix__linear-gradient-444)"
                  />
                  <path
                    d="M960.81 690.87s-7.12 7.48-7.25 7.38-19.07-12-19-12.2l.83-2.25a1.35 1.35 0 01.67-.74l.69-.33c.44-.2 24.06 7.67 24.06 8.14z"
                    style={{ mixBlendMode: "screen" }}
                    opacity={0.5}
                    fill="url(#prefix__linear-gradient-445)"
                  />
                  <path
                    style={{ mixBlendMode: "color-dodge" }}
                    fill="url(#prefix__radial-gradient-32)"
                    d="M893.17 650.16l23.48 13.67-3.34 5.93 3.84-5.83 15.05-8.75-15.12 8.26-23.91-13.28z"
                  />
                  <path
                    style={{ mixBlendMode: "color-dodge" }}
                    fill="url(#prefix__radial-gradient-33)"
                    d="M904.21 649.09l12.44 7.06 7.63-3.91-7.46 4.18-.13 1.88-.17-2.01-12.42-7.25.11.05z"
                  />
                </g>
              </motion.g>
              <path
                fill="#333"
                d="M506.82 334.69v257.2l8.43-4.9V329.85l-8.43 4.84z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37-15)"
                d="M497.95 330.18v-.27l8.92 14.53v247.45l-9.07-5.31V330.11l.15.07z"
              />
              <g>
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-16)"
                  d="M616.87 634.34v7.8L507.7 579.97v-7.79l109.17 62.16z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_24-112)"
                  d="M507.7 572.18l109.17 62.16-.22-7.92-102.19-58.18-6.76 3.94z"
                />
              </g>
              <g>
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-17)"
                  d="M616.87 588.17v7.81L507.7 533.81v-7.79l109.17 62.15z"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_38-14)"
                  d="M507.7 526.02l109.17 62.15-.22-7.92-102.19-58.18-6.76 3.95z"
                />
              </g>
              <g>
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_37-18)"
                  d="M556.43 441.7v9.41l-8.2-4.73v-9.4l8.2 4.72z"
                />
                <path
                  d="M556.41 443.43V446a3 3 0 004.46 2.59l381.61-218A3 3 0 00944 228v-2.52a3 3 0 00-4.45-2.59l-381.62 218a3 3 0 00-1.52 2.54z"
                  fill="#333"
                />
                <path
                  fill="url(#prefix__New_Gradient_Swatch_copy_38-15)"
                  d="M943.98 220.35L556.41 441.7l-8.18-4.72L935.85 215.6l8.13 4.75z"
                />
              </g>
              <path
                fill="#333"
                d="M625.72 403.28v257.19l8.42-4.9V398.43l-8.42 4.85z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37-19)"
                d="M497.95 330.18v-.27l127.81 73.37v257.19l-9.07-5.3v-247.6l-109.82-63.13-9.07-14.33.15.07z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_37-20)"
                d="M556.24 441.67v9.41l-107.99-62.85v-9.41l107.99 62.85z"
              />
              <path
                fill="url(#prefix__New_Gradient_Swatch_copy_38-16)"
                d="M564.2 437.18l-7.98 4.49-108-62.89 8.02-4.52 107.96 62.92z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default SvgComponent;

