import React from "react";
import CarrierSignupBackground from "../carriersignup/CarrierSignupBackground";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
    };
  }
  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    return (
      <motion.div className="overflow-hidden" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
        
        <header>
          <NavBar color="black" hamcolor="black" className=" " />
        </header>

        <div
          className="w-1/2 absolute -z-10 top-0"
          style={{ marginTop: "15vh" }}
        >
          <CarrierSignupBackground />
        </div>
        <div className="w-full" style={{ marginTop: "25vh" }}>
          <div className="w-3/4 mx-auto bg-white flex carriersignupcard">
            <div className="w-100">
              <div className="w-4/5 mx-auto">
                <h1 className="text-2vw uppercase text-custom-red font-bold mb-2 text-center">
                    Privacy Policy
                </h1>
                <p className="text-1.4vw font-normal mb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p className="text-1.4vw font-normal mb-3">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer  />
      </motion.div>
    );
  }
}

export default PrivacyPolicy;
