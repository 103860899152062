import React from "react";
import CarrierSignupBackground from "../carriersignup/CarrierSignupBackground";
import NavBar from "../NavBar";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
class TermsConditions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
    };
  }
  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  render() {
    return (
      <motion.div className="overflow-hidden" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
        
        <header>
          <NavBar color="black" hamcolor="black" className=" " />
        </header>
        <div
          className="w-1/2 absolute -z-10 top-0"
          style={{ marginTop: "15vh" }}
        >
          <CarrierSignupBackground />
        </div>
        <div className="w-full" style={{ marginTop: "25vh" }}>
          <div className="w-3/4 mx-auto bg-white flex carriersignupcard">
            <div className="w-100">
              <div className="w-4/5 mx-auto">
                <h1 className="text-2vw uppercase text-custom-red font-bold mb-2 text-center">
                Terms & Conditions
                </h1>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Introduction</h3>
                <p className="text-1.4vw font-normal mb-3">
                These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Axelavate accessible at Axelavate.com
                </p>
                <p className="text-1.4vw font-normal mb-3">
                These Terms will be applied fully and affect your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions.
                </p>
                <p className="text-1.4vw font-normal mb-3">
                Minors or people below 18 years old are not allowed to use this Website.
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Intellectual Property Rights</h3>
                <p className="text-1.4vw font-normal mb-3">
                Other than the content you own, under these Terms, Axelavate and its licensors own all the intellectual property rights and materials contained in this Website.
                </p>
                <p className="text-1.4vw font-normal mb-3">
                You are granted a limited license only for purposes of viewing the material contained on this Website.
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Business Transaction Data</h3>
                <p className="text-1.4vw font-normal mb-3">
                Axelavate is a management application where the businesses use the application to manage their business. All such data collected belong exclusively to the company who has received the data, and we do not have rights or access to that information
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">No warranties</h3>
                <p className="text-1.4vw font-normal mb-3">
                This Website is provided “as is,” with all faults, and Axelavate expresses no representations or warranties of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Limitation of liability</h3>
                <p className="text-1.4vw font-normal mb-3">
                In no event shall Axelavate, nor any of its officers, directors, and employees shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract. Axelavate, including its officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this Website.
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Indemnification</h3>
                <p className="text-1.4vw font-normal mb-3">
                You hereby indemnify to the fullest extent Axelavate from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Severability</h3>
                <p className="text-1.4vw font-normal mb-3">
                If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Variation of Terms</h3>
                <p className="text-1.4vw font-normal mb-3">
                Axelavate is permitted to revise these Terms at any time as it sees fit, and by using this Website, you are expected to review these Terms regularly.
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Assignment</h3>
                <p className="text-1.4vw font-normal mb-3">
                Axelavate is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Entire Agreement</h3>
                <p className="text-1.4vw font-normal mb-3">
                These Terms constitute the entire agreement between Axelavate and you in relation to your use of this Website and supersede all prior agreements and understandings.
                </p>
                <h3 className="text-1.25vw uppercase font-bold mb-2">Governing Law & Jurisdiction</h3>
                <p className="text-1.4vw font-normal mb-3">
                These Terms will be governed by and interpreted in accordance with the laws of California USA, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Country for the resolution of any disputes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer  />
      </motion.div>
    );
  }
}

export default TermsConditions;
