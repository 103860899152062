import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={289.6} height={49.81} viewBox="0 0 289.6 49.81" {...props}>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <g id="prefix__Layer_1-2" data-name="Layer 1">
          <g id="prefix__Logo">
            <path
              id="prefix__redpath"
              fill="#b20101"
              d="M64.65 23.18L82.9 4.46l144.37-2.2L87.12.12 79.59 0l-2.15 2.21L60.6 19.48 44.84 2.64 42.45.09l-7.67.4L0 2.26l38.35 1.95 18.22 19.01-21.19 22.57L0 47.59l32.16 1.64 7.45.38 3.9-4.24 16.76-18.2 17.17 18.4 3.95 4.24 8.03-.12 137.85-2.1-141.83-2.16-20.79-22.25z"
            />
            <g id="prefix__text" fill={props.color}>
              <path d="M26 11.31a7.15 7.15 0 00-1.6-2.06 3.8 3.8 0 00-2.63-.82 3.86 3.86 0 00-2.65.82 7.13 7.13 0 00-1.58 2.06L2.91 38.39h6.46L13 31.64h17.38L34 38.39h6.5zM15.58 26.62l6.09-11.57 6.09 11.57zM89.21 32.85a8.82 8.82 0 01-2-.19A2.38 2.38 0 0186 32a2.93 2.93 0 01-.61-1.38 11.11 11.11 0 01-.17-2.19v-2.11h21.45v-5.2H85.18v-2a10.91 10.91 0 01.17-2.12 2.86 2.86 0 01.65-1.39 2.46 2.46 0 011.24-.75 6.84 6.84 0 012-.24h11.45V9.07H89.21a16.73 16.73 0 00-4.62.54 6.42 6.42 0 00-3 1.72 6.62 6.62 0 00-1.56 3.12 19.73 19.73 0 00-.48 4.69v9.2a19.65 19.65 0 00.48 4.7 6.56 6.56 0 001.56 3.09 6.26 6.26 0 003 1.72 16.35 16.35 0 004.62.54h17.56v-5.54zM120.36 32.8a8 8 0 01-2.2-.25 3.1 3.1 0 01-1.39-.84 3.39 3.39 0 01-.71-1.46 9.57 9.57 0 01-.21-2.18v-19h-5.57v19.27a19.7 19.7 0 00.47 4.7 6.65 6.65 0 001.56 3.09 6.31 6.31 0 003 1.72 16.4 16.4 0 004.62.54h15.43V32.8zM163.81 31.64h-17.43l-3.58 6.75h-6.46L151 11.31a7.11 7.11 0 011.59-2.06 4.66 4.66 0 015.28 0 7.26 7.26 0 011.58 2.06L174 38.39h-6.5zm-14.81-5h12.18l-6.09-11.56zM189.13 36.24a6.73 6.73 0 01-1.59 2.07 4.8 4.8 0 01-5.09 0 6.73 6.73 0 01-1.59-2.07L166.39 9.08h6.79l11.92 22.65L197 9.08h6.68zM223.6 31.64h-17.42l-3.58 6.75h-6.46l14.62-27.08a7.11 7.11 0 011.59-2.06 3.84 3.84 0 012.65-.82 3.78 3.78 0 012.62.82 7.11 7.11 0 011.59 2.06l14.55 27.08h-6.5zm-14.8-5H221l-6.09-11.56zM272 38.39a16.74 16.74 0 01-4.61-.53 6.48 6.48 0 01-2.95-1.72A6.75 6.75 0 01262.9 33a20.25 20.25 0 01-.46-4.71v-9.15a20.37 20.37 0 01.46-4.69 6.77 6.77 0 011.57-3.12 6.39 6.39 0 012.95-1.72 16.74 16.74 0 014.58-.53h11.13v5.54H272a6.89 6.89 0 00-2 .24 2.71 2.71 0 00-1.24.75 2.85 2.85 0 00-.62 1.38 11.61 11.61 0 00-.17 2.15v2h21.45v5.2H268v2.07a11.85 11.85 0 00.17 2.19 2.93 2.93 0 00.61 1.38 2.28 2.28 0 001.23.71 9.25 9.25 0 002 .19h17.59v5.54zM241.53 38.46V14.75h-11.07V9.11h28.08v5.64h-11.08v23.71z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;

