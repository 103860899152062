import React from "react";
import NavBar from "../NavBar";
import CarrierSignupCard from "./CarrierSignupCard";
import CarrierSignupBackground from "./CarrierSignupBackground";
import Footer from "../Footer";
import { motion } from "framer-motion";
import { Multiselect } from 'multiselect-react-dropdown';
import { Alert } from 'reactstrap';

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

class CarrierSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.multiselectLoadTypeRef = React.createRef();
    this.multiselectPortRef = React.createRef();
    this.baseState = this.state 
    this.style = {
      chips: {
        background: "#e50000",
        fontSize: "0.75vw"
      },
      searchBox: {
        border: "none",
        borderBottom: "1px solid #b3b3b3",
        borderRadius: "0px",
        fontSize: "0.75vw"
      },
      multiselectContainer: {
        color: "red"
      }
    };
  }
  get initialState() {
    return {
      carrierName: "",
      email: "",
      phone: "",
      address: "",
      address1: "",
      city: "",
      state: "",
      zip: "",
      website: "",
      mcNumber: "",
      dotNumber: "",
      password: "",
      optionsLoadType: [],
      optionsPorts : [],
      selectedLoadTypeList: [],
      selectedPortsList: [],
      alertVisible: false,
      alertContent:"",
      alertColor: "danger",
      isAlert: false,
      errors: {
        carrierName: '',
        email: '',
        password: '',
        optionsLoadType: [],
        optionsPorts : []
      },
      requiredFieldsFilled: {
        carrierName: false,
        email: false,
        password: false,
        optionsLoadType: false,
        optionsPorts: false
      },
      isRequiredFieldChanged: false,
      isFormSubmitted: false
    };
  }
  handleErrors = (event) => {
    
  }
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    this.setState({isRequiredFieldChanged: true});
    switch (name) {
      case 'carrierName': 
        errors.carrierName = 
          value.length < 5
            ? 'Carrier Name must be at least 5 characters long!'
            : '';
        this.state.requiredFieldsFilled.carrierName = true;
        break;
      case 'email': 
        errors.email = 
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
        this.state.requiredFieldsFilled.email = true;
        break;
      case 'password': 
        errors.password = 
          value.length < 8
            ? 'Password must be at least 8 characters long!'
            : '';
        this.state.requiredFieldsFilled.password = true;
        break;
      /*case 'optionsLoadType': 
        errors.optionsLoadType = 
          value.length < 8
            ? 'Load Type is required!'
            : '';
        this.state.requiredFieldsFilled.optionsLoadType = true;
        break;*/
      default:
        break;
    }
    this.setState({errors, [name]: value});
  }

  validateForm = (errors,requiredFieldsFilled) => {
    let inValidKeyCount = 0;
    Object.keys(requiredFieldsFilled).map(key => {
      
      let errors = this.state.errors;
      var err = "";
      if(key=='carrierName'){
        if(requiredFieldsFilled[key]==false || this.state.carrierName.length < 5){
          ++inValidKeyCount;
          errors.carrierName = 'Carrier Name must be at least 5 characters long!'
        }
      }
      else if(key=='email'){
        if(requiredFieldsFilled[key]==false || !validEmailRegex.test(this.state.email)){
          ++inValidKeyCount;
          errors.email = 'Email is not valid!'
        }
      }
      else if(key=='password'){
        if(requiredFieldsFilled[key]==false || this.state.password.length < 8){
          ++inValidKeyCount;
          errors.password = 'Password must be at least 8 characters long!'
        }
      }
      else if(key=='optionsLoadType'){
        if(requiredFieldsFilled[key]==false || this.state.selectedLoadTypeList.length == 0){
          ++inValidKeyCount;
          errors.optionsLoadType = 'Load Type is required!'
        }
      }
      else if(key=='optionsPorts'){
        if(requiredFieldsFilled[key]==false || this.state.selectedPortsList.length == 0){
          ++inValidKeyCount;
          errors.optionsPorts = 'Port is required!'
        }
      }
      this.setState({errors});
    });
    if(inValidKeyCount == 0) {
      Object.values(errors).forEach(val => (val.length>0)?(++inValidKeyCount):inValidKeyCount);
    }
    return ((inValidKeyCount > 0)? false : true);
  };

  
  async componentDidMount() {
    const responseLoad_types = await fetch('api/load_types.php');
    const dataLoad_types = await responseLoad_types.json();
    this.setState({ optionsLoadType: dataLoad_types.LOAD_TYPES })
    const responsePorts = await fetch('api/ports.php');
    const dataPorts = await responsePorts.json();
    this.setState({ optionsPorts: dataPorts.LOAD_TYPES })
  }
  changeHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if( this.validateForm(this.state.errors, this.state.requiredFieldsFilled)) {
      this.submitForm()
    }else{
      this.handleErrors(event);
    }
  }
  submitForm=()=>{
    
    this.setState({ isFormSubmitted: true})
    
    let submitInfo = {
      CARRIER_NAME: this.state.carrierName,
      MC_NO: this.state.mcNumber,
      DOT_NO: this.state.dotNumber,
      ADDRESS: this.state.address,
      ADDRESS_1: this.state.address1,
      CITY: this.state.city,
      STATE: this.state.state,
      ZIP: this.state.zip,
      EMAIL: this.state.email,
      WEBSITE: this.state.website,
      PHONE: this.state.phone,
      LOAD_TYPE: this.state.selectedLoadTypeList,
      PORT: this.state.selectedPortsList,
      PASSWORD: this.state.password
    };

    fetch('api/carrier_signup.php' ,{
      method:'POST',
      headers: {'content-type':'application/json'},
      body:JSON.stringify(submitInfo)
    }).then(r=>r.json()).then(res=>{
      this.onShowAlert()
      if (res.STATUS){
        this.setState({
          alertColor: "success",
          alertContent: res.MESSAGE,
          isAlert: true
        })
        
        setTimeout(
          () => {
            this.setState(this.initialState);
            this.componentDidMount();            
            this.multiselectLoadTypeRef.current.resetSelectedValues();
            this.multiselectPortRef.current.resetSelectedValues();
          }, 
          1200
        );
      }
      else {
        this.setState({
          alertColor: "danger",
          alertContent: res.MESSAGE,
          isAlert: true,
          isFormSubmitted: false
        })
      }
    })
  }
  onShowAlert = ()=>{
    this.setState({alertVisible:true},()=>{
      window.setTimeout(()=>{
        this.setState({alertVisible:false})
      },2000)
    });
  }
  HandleErrorsLoadType=()=>{
    let errors = this.state.errors;
    errors.optionsLoadType = ''
    if(this.state.selectedLoadTypeList.length==0) {
      errors.optionsLoadType = 'Load Type is required!'
    }
    this.setState({errors});
  }
  HandleErrorsPort=()=>{
    let errors = this.state.errors;
    errors.optionsPorts = ''
    if(this.state.selectedPortsList.length==0) {
      errors.optionsPorts = 'Port is required!'
    }
    this.setState({errors});
  }
  onSelectLoadType=(selectedList, selectedItem)=>{
    this.state.selectedLoadTypeList.push(selectedItem.ID)
    this.state.requiredFieldsFilled.optionsLoadType = true;
    this.HandleErrorsLoadType();
  }
  onSelectPorts=(selectedList, selectedItem)=>{
    this.state.selectedPortsList.push(selectedItem.ID)
    this.state.requiredFieldsFilled.optionsPorts = true;
    this.HandleErrorsPort()
  }
  onRemoveLoadType=(selectedList, removedItem)=>{
    this.state.selectedLoadTypeList.pop(removedItem.ID)
    this.HandleErrorsLoadType();
  }
  onRemovePorts=(selectedList, removedItem)=>{
    this.state.selectedPortsList.pop(removedItem.ID)
    this.HandleErrorsPort()
  }
  render() {
    return (
      <motion.div className="relative overflow-hidden" initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}}>
        {this.state.isAlert && (
          <Alert color={this.state.alertColor} isOpen={this.state.alertVisible}>
          <strong> {this.state.alertContent}</strong> 
        </Alert>
        )}
        <header>
          <NavBar color="black" hamcolor="black" />
        </header>
        <div
          className="w-1/2 absolute -z-10 top-0"
          style={{ marginTop: "15vh" }}
        >
          <CarrierSignupBackground />
        </div>

        <div className="w-full" style={{ marginTop: "25vh" }}>
          <div className="w-3/4 mx-auto bg-white flex carriersignupcard">
            <div className="w-1/2">
              <div className="w-3/4 mx-auto">
                <form onSubmit={this.handleSubmit} noValidate>
                  <h1 className="text-2vw uppercase text-custom-red font-bold">
                    welcome
                  </h1>
                  <p className="text-1vw text-black font-medium -mt-2 uppercase">
                    Carrier Signup
                  </p>
                  
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input 
                      placeholder="Carrier Name *"
                      type="text"
                      value={this.state.carrierName}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler,this.handleChange} noValidate 
                      name="carrierName"
                    ></input>
                    {this.state.errors.carrierName.length > 0 && 
                <span className='error animate__animated animate__shakeX animate__slow'>{this.state.errors.carrierName}</span>}
                  </label>
                  
                  <div className="mt-6 position-relative">
                    <Multiselect
                      name="optionsLoadType"
                      options={this.state.optionsLoadType} // Options to display in the dropdown
                      selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                      onSelect={this.onSelectLoadType} // Function will trigger on select event
                      onRemove={this.onRemoveLoadType} // Function will trigger on remove event
                      displayValue="NAME" // Property name to display in the dropdown options
                      style={ this.style }
                      placeholder="Select Load Type *"
                      ref={this.multiselectLoadTypeRef}
                      />
                      {this.state.errors.optionsLoadType.length > 0 && 
                <span className='error animate__animated animate__shakeX animate__slow'>{this.state.errors.optionsLoadType}</span>}
                    </div>
                    <div className="mt-6 position-relative">
                      <Multiselect
                      name="optionsPorts"
                      options={this.state.optionsPorts} // Options to display in the dropdown
                      selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                      onSelect={this.onSelectPorts} // Function will trigger on select event
                      onRemove={this.onRemovePorts} // Function will trigger on remove event
                      displayValue="NAME" // Property name to display in the dropdown options
                      style={ this.style }
                      placeholder="Select Port *"
                      ref={this.multiselectPortRef}
                      />
                      {this.state.errors.optionsPorts.length > 0 && 
                <span className='error animate__animated animate__shakeX animate__slow'>{this.state.errors.optionsPorts}</span>}
                    </div>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="Address"
                      type="text"
                      name="address"
                      value={this.state.address}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="Address1"
                      type="text"
                      name="address1"
                      value={this.state.address1}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="City"
                      type="text"
                      name="city"
                      value={this.state.city}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="State"
                      type="text"
                      name="state"
                      value={this.state.state}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="ZIP"
                      type="text"
                      name="zip"
                      value={this.state.zip}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="Website"
                      type="text"
                      name="website"
                      value={this.state.website}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="MC Number"
                      type="text"
                      name="mcNumber"
                      value={this.state.mcNumber}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="DOT Number"
                      type="text"
                      name="dotNumber"
                      value={this.state.dotNumber}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="Phone Number *"
                      type="text"
                      name="phone"
                      value={this.state.phone}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler}
                    ></input>
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="E-mail *"
                      type="email"
                      name="email"
                      value={this.state.email}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler,this.handleChange}
                    ></input>
                    {this.state.errors.email.length > 0 && 
                <span className='error animate__animated animate__shakeX animate__slow'>{this.state.errors.email}</span>}
                  </label>
                  <label className="text-black uppercase flex flex-col w-full text-0.75vw font-semibold mt-6 position-relative">
                    <input
                      placeholder="Password *"
                      type="password"
                      name="password"
                      value={this.state.password}
                      className=" w-full placeholder-custom-lightgray border-b border-custom-lightgray pb-1 text-0.75vw mt-2 focus:outline-none"
                      onChange={this.changeHandler,this.handleChange}
                    ></input>
                    {this.state.errors.password.length > 0 && 
                      <span className='error animate__animated animate__shakeX animate__slow'>{this.state.errors.password}</span>}                    
                  </label>
                  <button
                    className="w-full bg-custom-red mt-10 relative focus:outline-none hover:bg-custom-redlight"
                    style={{ paddingBottom: "12%", borderRadius: "1vw" }}
                    disabled={this.state.isFormSubmitted}
                  >
                    <span
                      className="text-white uppercase absolute text-1vw"
                      style={{
                        top: "50%",
                        left: "50%",
                        transform: "translateX(-50%) translateY(-50%)",
                      }}
                    >
                      sign up
                    </span>
                  </button>
                </form>
              </div>
            </div>
            <div className="w-1/2">
              <div className="w-4/5 mx-auto">
                <CarrierSignupCard />
              </div>
            </div>
          </div>
        </div>
        <Footer
          color="white"
          footerColor="black"
          className="absolute bottom-0"
        />
      </motion.div>
    );
  }
}

export default CarrierSignup;
