import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width={"100%"} viewBox="0 0 129.42 143.49" {...props}>
      <defs>
        <linearGradient
          id="servicescardphoto1__linear-gradient"
          x1={20069.97}
          y1={2833.65}
          x2={20061.75}
          y2={2838.15}
          gradientTransform="matrix(-1 0 0 1 20185.62 -2727.12)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#9f79c8" />
          <stop offset={0.14} stopColor="#a580cb" />
          <stop offset={0.33} stopColor="#b492d3" />
          <stop offset={0.55} stopColor="#cdb0e1" />
          <stop offset={0.58} stopColor="#d1b4e3" />
          <stop offset={0.76} stopColor="#dbc4e9" />
          <stop offset={1} stopColor="#ebdef3" />
        </linearGradient>
        <linearGradient
          id="servicescardphoto1__linear-gradient-2"
          x1={17906.33}
          y1={110.48}
          x2={17903.04}
          y2={105.26}
          gradientTransform="matrix(-1 0 0 1 18022.77 0)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} stopColor="#c1a1d5" />
          <stop offset={0.23} stopColor="#c9abdc" />
          <stop offset={0.36} stopColor="#d1b4e3" />
          <stop offset={0.43} stopColor="#b395d6" />
          <stop offset={0.49} stopColor="#9c7dcb" />
          <stop offset={0.56} stopColor="#8e6ec5" />
          <stop offset={0.61} stopColor="#8969c3" />
          <stop offset={0.76} stopColor="#9471c6" />
          <stop offset={1} stopColor="#b086cc" />
        </linearGradient>
        <linearGradient
          id="servicescardphoto1__linear-gradient-3"
          x1={-21445.6}
          y1={105.74}
          x2={-21439.56}
          y2={108.88}
          gradientTransform="translate(21558.86)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#8770b8" />
          <stop offset={1} stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="servicescardphoto1__linear-gradient-4"
          x1={-21440.93}
          y1={106.02}
          x2={-21442.36}
          y2={108.93}
          gradientTransform="translate(21558.86)"
          xlinkHref="#servicescardphoto1__linear-gradient-2"
        />
        <linearGradient
          id="servicescardphoto1__linear-gradient-5"
          x1={20077.59}
          y1={2838.14}
          x2={20069.37}
          y2={2842.64}
          xlinkHref="#servicescardphoto1__linear-gradient"
        />
        <linearGradient
          id="servicescardphoto1__linear-gradient-6"
          x1={17913.95}
          y1={114.97}
          x2={17910.66}
          y2={109.75}
          xlinkHref="#servicescardphoto1__linear-gradient-2"
        />
        <linearGradient
          id="servicescardphoto1__linear-gradient-7"
          x1={-21453.22}
          y1={110.23}
          x2={-21447.18}
          y2={113.37}
          xlinkHref="#servicescardphoto1__linear-gradient-3"
        />
        <linearGradient
          id="servicescardphoto1__linear-gradient-8"
          x1={-21448.55}
          y1={110.51}
          x2={-21449.98}
          y2={113.42}
          gradientTransform="translate(21558.86)"
          xlinkHref="#servicescardphoto1__linear-gradient-2"
        />
        <linearGradient
          id="servicescardphoto1__linear-gradient-9"
          x1={-21453.08}
          y1={93.22}
          x2={-21465.38}
          y2={116.65}
          gradientTransform="translate(21558.86)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.02} stopColor="#744ca1" />
          <stop offset={0.53} stopColor="#9f79c8" />
          <stop offset={0.64} stopColor="#a580cb" />
          <stop offset={0.8} stopColor="#b492d3" />
          <stop offset={0.98} stopColor="#cdb0e1" />
          <stop offset={1} stopColor="#d1b4e3" />
        </linearGradient>
        <linearGradient
          id="servicescardphoto1__linear-gradient-10"
          x1={-21497.25}
          y1={133.39}
          x2={-21491.21}
          y2={130.68}
          xlinkHref="#servicescardphoto1__linear-gradient-3"
        />
        <linearGradient
          id="servicescardphoto1__New_Gradient_Swatch_copy_27"
          data-name="New Gradient Swatch copy 27"
          x1={-11957.31}
          y1={101.77}
          x2={-11848.37}
          y2={69.15}
          gradientTransform="translate(12038.16)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.04} stopColor="#ab1919" />
          <stop offset={0.27} stopColor="#ba1414" />
          <stop offset={0.72} stopColor="#e30808" />
          <stop offset={1} stopColor="red" />
        </linearGradient>
        <linearGradient
          id="servicescardphoto1__New_Gradient_Swatch_copy_27-2"
          x1={-11987.22}
          y1={110}
          x2={-11939.58}
          y2={99.32}
          xlinkHref="#servicescardphoto1__New_Gradient_Swatch_copy_27"
        />
        <linearGradient
          id="servicescardphoto1__New_Gradient_Swatch_copy_27-3"
          x1={-11893.95}
          y1={-4.59}
          x2={-11946.14}
          y2={94.87}
          xlinkHref="#servicescardphoto1__New_Gradient_Swatch_copy_27"
        />
        <radialGradient
          id="servicescardphoto1__radial-gradient"
          cx={-21441.61}
          cy={107.32}
          r={0.77}
          gradientTransform="translate(21558.86)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#eeebf2" />
          <stop offset={0.58} stopColor="#d4d1d8" />
          <stop offset={0.94} stopColor="#a5a6b9" />
          <stop offset={1} stopColor="#9d9eb3" />
        </radialGradient>
        <radialGradient
          id="servicescardphoto1__radial-gradient-2"
          cx={-21449.33}
          cy={111.81}
          r={0.77}
          xlinkHref="#servicescardphoto1__radial-gradient"
        />
        <radialGradient
          id="servicescardphoto1__radial-gradient-3"
          cx={-21471.68}
          cy={124.81}
          r={0.77}
          xlinkHref="#servicescardphoto1__radial-gradient"
        />
        <radialGradient
          id="servicescardphoto1__radial-gradient-4"
          cx={-21494.62}
          cy={138.08}
          r={0.77}
          xlinkHref="#servicescardphoto1__radial-gradient"
        />
        <radialGradient
          id="servicescardphoto1__radial-gradient-5"
          cx={-21516.51}
          cy={132.2}
          r={0.28}
          gradientTransform="translate(21558.86)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#9f19ab" />
          <stop offset={0.01} stopColor="#9c19a8" />
          <stop offset={0.13} stopColor="#73127c" />
          <stop offset={0.25} stopColor="#500d56" />
          <stop offset={0.38} stopColor="#330836" />
          <stop offset={0.52} stopColor="#1c041e" />
          <stop offset={0.66} stopColor="#0c020d" />
          <stop offset={0.81} stopColor="#030003" />
          <stop offset={1} />
        </radialGradient>
        <radialGradient
          id="servicescardphoto1__radial-gradient-6"
          cx={-21508.32}
          cy={136.91}
          r={7.17}
          xlinkHref="#servicescardphoto1__radial-gradient-5"
        />
        <radialGradient
          id="servicescardphoto1__radial-gradient-7"
          cx={-26920.43}
          cy={-15328.98}
          r={6.01}
          gradientTransform="matrix(.09 -.18 .88 .47 16111.52 2578)"
          xlinkHref="#servicescardphoto1__radial-gradient-5"
        />
      </defs>
      <g
        style={{
          isolation: "isolate",
        }}
      >
        <g id="servicescardphoto1__Layer_2" data-name="Layer 2">
          <g id="servicescardphoto1__Layer_1-2" data-name="Layer 1">
            <path
              fill="#b4c4cc"
              d="M121.24 99.89v6.44l-1.11-.62v-6.48l1.11.66z"
            />
            <path
              d="M111.87 108.25a2.52 2.52 0 001 2.26l2 1.14c.64.36.23-.46 1.21-1a7.83 7.83 0 003.55-6.13c0-1.11 1-1.12.4-1.49l-2.09-1.16a2.52 2.52 0 00-2.48.28 7.81 7.81 0 00-3.59 6.1z"
              fill="#474756"
            />
            <path
              d="M113.92 109.42c0 2.25 1.59 3.16 3.56 2a7.85 7.85 0 003.55-6.13c0-2.25-1.59-3.16-3.55-2a7.83 7.83 0 00-3.56 6.13z"
              fill="#545466"
            />
            <path
              d="M117.51 104.91a5.06 5.06 0 00-2.3 4c0 1.46 1 2 2.3 1.31a5.06 5.06 0 002.29-4c0-1.45-1.03-2.04-2.29-1.31z"
              fill="url(#servicescardphoto1__linear-gradient)"
            />
            <path
              d="M116.69 109.66a5.08 5.08 0 002.29-4 2 2 0 00-.27-1.09c.66.09 1.09.67 1.09 1.61a5.06 5.06 0 01-2.29 4c-.88.51-1.64.38-2-.22a2 2 0 001.18-.3z"
              fill="url(#servicescardphoto1__linear-gradient-2)"
            />
            <path
              d="M115.91 108.67c0 .9.64 1.26 1.42.81a3.13 3.13 0 001.42-2.44c0-.9-.64-1.26-1.42-.81a3.12 3.12 0 00-1.42 2.44z"
              style={{
                mixBlendMode: "multiply",
              }}
              fill="url(#servicescardphoto1__linear-gradient-3)"
            />
            <path
              d="M116 108.16c0 .76.53 1.06 1.2.68a2.65 2.65 0 001.2-2.07c0-.76-.54-1.07-1.2-.68a2.62 2.62 0 00-1.2 2.07z"
              fill="url(#servicescardphoto1__linear-gradient-4)"
            />
            <path
              d="M116.35 108.38c0 .76.54 1.07 1.2.69a2.64 2.64 0 001.2-2.07c0-.76-.53-1.07-1.2-.69a2.66 2.66 0 00-1.2 2.07z"
              fill="#e7f1f7"
            />
            <path
              d="M112 108.24a2.51 2.51 0 001 2.27l1.89 1.1c.65.36.24-.46 1.22-1a7.88 7.88 0 003.57-6.16c0-1.12 1-1.13.4-1.5l-2-1.12a2.56 2.56 0 00-2.5.28 7.88 7.88 0 00-3.58 6.13z"
              fill="#4c4c5e"
            />
            <path
              d="M114 109.37c0 2.26 1.6 3.17 3.57 2a7.87 7.87 0 003.58-6.16c0-2.26-1.6-3.18-3.58-2a7.88 7.88 0 00-3.57 6.16z"
              fill="#474756"
            />
            <path
              d="M115 108.77c0 1.62 1.14 2.27 2.54 1.45a5.59 5.59 0 002.55-4.39c0-1.61-1.14-2.26-2.55-1.45a5.62 5.62 0 00-2.54 4.39z"
              fill="#e7f1f7"
            />
            <path
              d="M115 108.77a2.23 2.23 0 00.32 1.24 1.62 1.62 0 001.78-.11 5.23 5.23 0 002.38-4.1c0-1.08-.56-1.7-1.36-1.68a3.6 3.6 0 00-.58.26 5.62 5.62 0 00-2.54 4.39z"
              fill="#e7f1f7"
            />
            <path
              d="M119 104.07a1.76 1.76 0 01.73 1.62 5.64 5.64 0 01-2.54 4.4 2.08 2.08 0 01-1.46.31 1.79 1.79 0 001.81-.18 5.59 5.59 0 002.55-4.39c.01-.99-.42-1.62-1.09-1.76z"
              fill="#474756"
            />
            <path
              d="M116.59 107.7c0 .42.29.59.66.38a1.47 1.47 0 00.67-1.15c0-.42-.3-.59-.67-.38a1.46 1.46 0 00-.66 1.15z"
              fill="url(#servicescardphoto1__radial-gradient)"
            />
            <path
              d="M117.19 104.93a.73.73 0 00-.33.57c0 .21.15.3.33.19a.71.71 0 00.33-.57c0-.22-.14-.3-.33-.19zm0 3.89a.76.76 0 00-.33.58c0 .21.15.29.33.19a.73.73 0 00.33-.58c0-.21-.14-.29-.33-.19zm1-3.23c.13.07.34 0 .47-.27s.13-.47 0-.54-.34 0-.47.27-.19.47-.03.54zm-1.93 3.33c-.13-.07-.34 0-.47.27s-.13.47 0 .54.34 0 .47-.27.1-.46-.03-.54zm2.66-3a.76.76 0 00-.33.58c0 .21.15.29.33.19a.76.76 0 00.33-.58c-.03-.24-.18-.32-.36-.22zm-3.4 2a.76.76 0 00-.33.58c0 .21.15.29.33.19a.74.74 0 00.34-.58c-.03-.28-.18-.36-.37-.26zm3.14-.32c-.13-.07-.34 0-.47.28s-.13.46 0 .54.34 0 .47-.28.1-.53-.03-.61zm-2.4-1.36c-.13-.08-.34 0-.47.27s-.13.46 0 .54.34 0 .47-.27.1-.54-.03-.61z"
              fill="#474756"
            />
            <path
              d="M116.16 107.7a.71.71 0 00.28.63s.41.24.41.25c.18.09.07-.13.34-.29a2.16 2.16 0 001-1.7c0-.3.29-.31.11-.41l-.44-.25a.7.7 0 00-.69.07 2.18 2.18 0 00-1.01 1.7z"
              fill="#545466"
            />
            <path
              d="M116.6 108c0 .62.44.87 1 .56a2.18 2.18 0 001-1.7c0-.62-.44-.87-1-.56a2.15 2.15 0 00-1 1.7z"
              fill="#545466"
            />
            <path
              d="M104.25 112.74a2.52 2.52 0 001 2.26l2 1.14c.64.36.23-.46 1.21-1A7.83 7.83 0 00112 109c0-1.11 1-1.12.4-1.49l-2.09-1.16a2.52 2.52 0 00-2.48.28 7.81 7.81 0 00-3.58 6.11z"
              fill="#474756"
            />
            <path
              d="M106.3 113.91c0 2.25 1.59 3.16 3.56 2a7.85 7.85 0 003.55-6.13c0-2.25-1.59-3.16-3.55-2a7.83 7.83 0 00-3.56 6.13z"
              fill="#545466"
            />
            <path
              d="M109.88 109.4a5.08 5.08 0 00-2.29 4c0 1.46 1 2 2.29 1.31a5 5 0 002.3-4c0-1.45-1.03-2.04-2.3-1.31z"
              fill="url(#servicescardphoto1__linear-gradient-5)"
            />
            <path
              d="M109.07 114.15a5.08 5.08 0 002.29-4 2 2 0 00-.27-1.09c.65.09 1.09.67 1.09 1.61a5 5 0 01-2.3 4c-.87.51-1.63.38-2-.22a2 2 0 001.19-.3z"
              fill="url(#servicescardphoto1__linear-gradient-6)"
            />
            <path
              d="M108.29 113.16c0 .9.64 1.26 1.42.81a3.13 3.13 0 001.42-2.44c0-.9-.64-1.26-1.42-.81a3.12 3.12 0 00-1.42 2.44z"
              style={{
                mixBlendMode: "multiply",
              }}
              fill="url(#servicescardphoto1__linear-gradient-7)"
            />
            <path
              d="M108.41 112.65c0 .76.53 1.06 1.2.68a2.65 2.65 0 001.2-2.07c0-.76-.54-1.07-1.2-.68a2.62 2.62 0 00-1.2 2.07z"
              fill="url(#servicescardphoto1__linear-gradient-8)"
            />
            <path
              d="M108.73 112.87c0 .76.54 1.07 1.2.69a2.64 2.64 0 001.2-2.07c0-.76-.53-1.07-1.2-.69a2.66 2.66 0 00-1.2 2.07z"
              fill="#e7f1f7"
            />
            <path
              d="M104.28 112.74a2.52 2.52 0 001 2.27l1.89 1.09c.65.36.24-.46 1.22-1a7.88 7.88 0 003.57-6.17c0-1.12 1-1.12.4-1.5l-2-1.11a2.55 2.55 0 00-2.5.27 7.88 7.88 0 00-3.58 6.15z"
              fill="#4c4c5e"
            />
            <path
              d="M106.26 113.86c0 2.27 1.6 3.18 3.58 2a7.88 7.88 0 003.57-6.16c0-2.27-1.6-3.18-3.57-2a7.87 7.87 0 00-3.58 6.16z"
              fill="#474756"
            />
            <path
              d="M107.29 113.27c0 1.61 1.14 2.26 2.55 1.45a5.62 5.62 0 002.54-4.39c0-1.61-1.14-2.26-2.54-1.45a5.6 5.6 0 00-2.55 4.39z"
              fill="#e7f1f7"
            />
            <path
              d="M107.29 113.27a2.25 2.25 0 00.32 1.24 1.62 1.62 0 001.78-.11 5.22 5.22 0 002.38-4.1c0-1.09-.56-1.71-1.36-1.68a2.6 2.6 0 00-.57.26 5.6 5.6 0 00-2.55 4.39z"
              fill="#e7f1f7"
            />
            <path
              d="M111.29 108.56a1.79 1.79 0 01.73 1.63 5.62 5.62 0 01-2.54 4.39 2.09 2.09 0 01-1.46.32 1.81 1.81 0 001.82-.18 5.62 5.62 0 002.54-4.39c0-.99-.38-1.62-1.09-1.77z"
              fill="#474756"
            />
            <path
              d="M108.87 112.2c0 .42.29.59.66.38a1.48 1.48 0 00.67-1.15c0-.42-.3-.59-.67-.38a1.44 1.44 0 00-.66 1.15z"
              fill="url(#servicescardphoto1__radial-gradient-2)"
            />
            <path
              d="M109.47 109.42a.74.74 0 00-.33.58c0 .21.15.29.33.19a.74.74 0 00.34-.58c0-.21-.15-.29-.34-.19zm0 3.9a.73.73 0 00-.33.57c0 .21.15.3.33.19a.71.71 0 00.34-.57c0-.21-.15-.3-.34-.19zm1-3.23c.13.07.34 0 .47-.27s.13-.47 0-.54-.34 0-.47.27-.16.45-.03.54zm-1.93 3.33c-.13-.08-.34 0-.47.27s-.13.46 0 .54.34 0 .47-.27.1-.47-.03-.54zm2.66-3a.74.74 0 00-.33.57c0 .21.15.3.33.19a.73.73 0 00.33-.57c-.03-.24-.18-.33-.36-.22zm-3.4 2a.74.74 0 00-.33.57c0 .22.15.3.33.19a.71.71 0 00.34-.57c-.03-.28-.14-.37-.37-.26zm3.14-.32c-.13-.07-.34 0-.47.27s-.13.47 0 .54.34 0 .47-.27.06-.54-.03-.64zm-2.4-1.37c-.13-.07-.34 0-.47.27s-.13.47 0 .54.34 0 .47-.27.1-.53-.03-.61z"
              fill="#474756"
            />
            <path
              d="M108.44 112.2a.69.69 0 00.28.62l.41.25c.18.1.07-.12.34-.28a2.18 2.18 0 001-1.7c0-.31.29-.31.11-.41l-.44-.25a.68.68 0 00-.69.07 2.15 2.15 0 00-1.01 1.7z"
              fill="#545466"
            />
            <path
              d="M108.88 112.45c0 .63.44.88 1 .57a2.19 2.19 0 001-1.7c0-.63-.44-.88-1-.56a2.14 2.14 0 00-1 1.69z"
              fill="#545466"
            />
            <g>
              <path
                fill="#9aa0a6"
                d="M90.9 117.97v3.39l11.44-6.64v-3.38l-11.44 6.63zM90.9 117.97v3.39l-3.21-1.83v-3.4l3.21 1.84z"
              />
              <path
                fill="url(#servicescardphoto1__linear-gradient-9)"
                d="M87.69 116.13l3.21 1.84 11.44-6.63-3.21-1.83-11.44 6.62z"
              />
            </g>
            <g>
              <path
                fill="#8c9399"
                d="M123.5 98.79v4.32l.42-.34v-4.3l-.42.32zM123.5 98.79v4.32l-10.29-5.78V93l10.29 5.79z"
              />
              <path
                fill="#e7f1f7"
                d="M113.21 93l10.29 5.79.42-.32-10.29-5.79-.42.32z"
              />
            </g>
            <path
              fill="#c5d4dd"
              d="M104.49 109.56v6.43l1.15-.66v-5.12l15.59-9v5.12l1.15-.66v-6.44l-17.89 10.33z"
            />
            <path
              fill="#b4c4cc"
              d="M104.48 109.57v6.44l-1.11-.63v-6.47l1.11.66z"
            />
            <g>
              <path
                fill="#b4c4cc"
                d="M92.79 120.8l.58.34 9.5-5.47-.59-.33-9.49 5.46z"
              />
              <path fill="#b4c4cc" d="M102.88 112.2v3.48l-.6-.34v-3.5l.6.36z" />
              <path
                d="M92.51 117.71v5l11.23-6.51v-4.91zm10.36-2.07l-9.49 5.5v-2.93l9.49-5.46z"
                fill="#c5d4dd"
              />
              <path
                fill="#b4c4cc"
                d="M92.51 117.71v4.95l-.73-.37v-4.99l.73.41z"
              />
              <path
                fill="#b4c4cc"
                d="M91.78 117.3l.73.41 11.23-6.47-.74-.4-11.22 6.46z"
              />
            </g>
            <g>
              <path
                d="M81.79 125.8a2.5 2.5 0 001 2.26l2 1.14c.64.36.23-.46 1.21-1a7.83 7.83 0 003.55-6.13c0-1.11 1-1.12.4-1.49l-2.09-1.16a2.52 2.52 0 00-2.48.28 7.8 7.8 0 00-3.59 6.1z"
                fill="#474756"
              />
              <path
                d="M83.85 127c0 2.25 1.59 3.16 3.55 2a7.83 7.83 0 003.6-6.13c0-2.25-1.59-3.16-3.56-2a7.85 7.85 0 00-3.59 6.13z"
                fill="#545466"
              />
              <path
                d="M87.43 122.46a5.08 5.08 0 00-2.29 4c0 1.46 1 2 2.29 1.31a5 5 0 002.3-4c0-1.45-1.03-2.04-2.3-1.31z"
                fill="#b4c4cc"
              />
              <path
                d="M86.28 125.93c0 .76.54 1.07 1.2.69a2.66 2.66 0 001.2-2.07c0-.76-.54-1.07-1.2-.69a2.66 2.66 0 00-1.2 2.07z"
                fill="#e7f1f7"
              />
              <path
                d="M81.94 125.74a2.52 2.52 0 001 2.27l1.89 1.09c.65.37.23-.45 1.21-1a7.87 7.87 0 003.58-6.16c0-1.12 1-1.13.4-1.51l-2-1.11a2.53 2.53 0 00-2.49.27 7.88 7.88 0 00-3.59 6.15z"
                fill="#4c4c5e"
              />
              <path
                d="M83.92 126.86c0 2.27 1.6 3.18 3.57 2a7.88 7.88 0 003.57-6.16c0-2.27-1.6-3.18-3.57-2a7.88 7.88 0 00-3.57 6.16z"
                fill="#474756"
              />
              <path
                d="M84.94 126.27c0 1.61 1.14 2.26 2.55 1.45a5.6 5.6 0 002.51-4.39c0-1.61-1.14-2.26-2.55-1.45a5.6 5.6 0 00-2.51 4.39z"
                fill="#e7f1f7"
              />
              <path
                d="M84.94 126.27a2.17 2.17 0 00.33 1.24 1.62 1.62 0 001.78-.11 5.24 5.24 0 002.37-4.1c0-1.09-.55-1.7-1.35-1.68a2.66 2.66 0 00-.58.26 5.6 5.6 0 00-2.55 4.39z"
                fill="#e7f1f7"
              />
              <path
                d="M89 121.56a1.79 1.79 0 01.73 1.63 5.59 5.59 0 01-2.55 4.39 2 2 0 01-1.45.32 1.79 1.79 0 001.81-.18 5.6 5.6 0 002.46-4.39c0-.99-.39-1.62-1-1.77z"
                fill="#474756"
              />
              <path
                d="M86.52 125.2c0 .42.3.59.67.38a1.46 1.46 0 00.66-1.15c0-.42-.29-.59-.66-.38a1.47 1.47 0 00-.67 1.15z"
                fill="url(#servicescardphoto1__radial-gradient-3)"
              />
              <path
                d="M87.13 122.42a.73.73 0 00-.33.58c0 .21.14.29.33.19a.76.76 0 00.33-.58c0-.21-.15-.29-.33-.19zm0 3.9a.71.71 0 00-.33.57c0 .21.14.3.33.19a.73.73 0 00.33-.57c0-.21-.15-.3-.33-.19zm1-3.23c.13.07.34 0 .47-.27s.13-.47 0-.54-.34 0-.47.27-.13.45-.04.54zm-1.93 3.33c-.13-.08-.34 0-.47.27s-.13.47 0 .54.34 0 .47-.27.09-.47-.04-.54zm2.67-3a.73.73 0 00-.34.58c0 .21.15.29.34.18a.73.73 0 00.33-.57c-.04-.24-.2-.32-.37-.22zm-3.4 2a.74.74 0 00-.33.58c0 .21.15.29.33.19a.76.76 0 00.33-.58c-.04-.28-.19-.36-.37-.26zm3.13-.32c-.13-.07-.34 0-.47.27s-.13.47 0 .54.34 0 .47-.27.09-.53-.04-.64zm-2.4-1.37c-.13-.07-.34 0-.47.27s-.13.47 0 .54.34 0 .47-.27.09-.53-.04-.61z"
                fill="#474756"
              />
              <path
                d="M86.1 125.2a.68.68 0 00.27.62l.42.25c.18.1.06-.12.33-.28a2.15 2.15 0 001-1.7c0-.31.28-.31.11-.41l-.44-.25a.72.72 0 00-.7.07 2.18 2.18 0 00-.99 1.7z"
                fill="#545466"
              />
              <path
                d="M86.54 125.46c0 .62.44.87 1 .56a2.18 2.18 0 001-1.7c0-.63-.44-.88-1-.56a2.16 2.16 0 00-1 1.7z"
                fill="#545466"
              />
            </g>
            <g>
              <path
                fill="#cfdee8"
                d="M68.88 131.45v4.95l14.82-8.56v-4.91l-14.82 8.52z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#e7f1f7"
                d="M79.48 125.39v4.78l.23-.13v-4.78l-.23.13z"
              />
              <path
                fill="#e7f1f7"
                d="M68.88 131.45v4.95l-11.71-6.66v-4.98l11.71 6.69z"
              />
              <path
                fill="#e7f1f7"
                d="M57.17 124.76l11.71 6.69 14.82-8.52-11.72-6.68-14.81 8.51z"
              />
            </g>
            <g fill="#545466">
              <path d="M70.87 128.15v1.64l53.35-31.02v-1.6l-53.35 30.98zM70.87 128.15v1.64l-14.63-8.32v-1.69l14.63 8.37z" />
              <path d="M56.24 119.78l14.63 8.37 53.35-30.98-14.64-8.35-53.34 30.96z" />
            </g>
            <g>
              <path
                d="M56.3 108.84l-9.62 5.61a5 5 0 00-2.37 3.1L43 122.89a19.6 19.6 0 00-.61 4.88V134l13.72 7.76v-5.55a19.86 19.86 0 01.92-6l1.75-5.54a5 5 0 012.22-2.79l9-5.25z"
                fill="red"
              />
              <path
                d="M56.06 136.2v5.58l7.35-4.21.57-3.24 6.02-3.61v-14.09l-9 5.27a5 5 0 00-2.3 2.84l-1.7 5.53a19.89 19.89 0 00-.94 5.93z"
                fill="#e50000"
              />
              <path
                d="M59.54 125l-1.73 5.54a.43.43 0 00.63.51l5.44-3.17a1.18 1.18 0 00.58-1v-4.54a.44.44 0 00-.66-.38l-3.37 2a1.94 1.94 0 00-.89 1.08z"
                fill="#26262e"
              />
              <path
                d="M58.22 131a.28.28 0 01-.23-.12.27.27 0 010-.25l1.73-5.53a1.75 1.75 0 01.82-1l3.37-2H64c.21 0 .32.1.32.25v4.54a1 1 0 01-.51.88l-5.44 3.17z"
                fill="#616173"
              />
              <path
                d="M53.73 131.86v2a2.84 2.84 0 01-4.25 2.46l-2-1.12a6.91 6.91 0 01-3.49-6v-3z"
                fill="#e50000"
              />
              <path
                d="M50.89 136a2 2 0 01-1-.28l-2-1.12a6.16 6.16 0 01-3.11-5.35v-1.65l8.22 4.7v1.61a2.08 2.08 0 01-2.11 2.09z"
                fill="#616173"
              />
              <path
                d="M45.81 132.63l5.68 3.27a2.4 2.4 0 00.4-.17L45.46 132c.11.23.22.43.35.63zm-.81-1.72l7.48 4.31a3 3 0 00.2-.29l-7.8-4.48a3.9 3.9 0 00.12.46zm-.24-1.67v.28L53 134.2a2.64 2.64 0 000-.29v-.09l-8.18-4.71zm0-1.39v.4L53 133v-.4z"
                fill="#474756"
                opacity={0.5}
              />
              <path
                d="M55.59 131.11a.79.79 0 01-.4-.1l-11.13-6.32a1.36 1.36 0 01-.65-1.52l1.39-5.49a4 4 0 01.3-.82.5.5 0 01.44-.29.48.48 0 01.24.07l12.13 7a.79.79 0 01.35.94l-1.89 6a.82.82 0 01-.78.57z"
                fill="#26262e"
              />
              <path
                d="M55.59 131a.62.62 0 01-.32-.09l-11.14-6.32a1.2 1.2 0 01-.57-1.34l1.44-5.53a4.4 4.4 0 01.29-.79.33.33 0 01.3-.2.42.42 0 01.16 0l12.13 7a.63.63 0 01.28.76l-1.89 6a.67.67 0 01-.68.51z"
                fill="#616173"
              />
              <path
                d="M68 120.06v4.48a.72.72 0 01-.34.61l-1.56.9a.1.1 0 01-.1-.05v-4.31a.74.74 0 01.37-.64l1.62-1z"
                fill="#26262e"
              />
              <path
                d="M66.12 121.65a.6.6 0 01.29-.51l1.39-.81v4.21a.54.54 0 01-.27.47l-1.41.82z"
                fill="#616173"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="url(#servicescardphoto1__linear-gradient-10)"
                d="M64.06 130.33l5.97.39-6.04 3.57.07-3.96z"
              />
            </g>
            <path
              fill="#b4c4cc"
              d="M68.21 133.38v2.71l-2.07-1.16.14-2.71 1.93 1.16z"
            />
            <g>
              <path
                d="M59 139a2.5 2.5 0 001 2.27l1.89 1.09c.64.37.23-.45 1.21-1a7.88 7.88 0 003.57-6.16c0-1.12 1-1.13.41-1.51l-2-1.11a2.53 2.53 0 00-2.49.27A7.87 7.87 0 0059 139z"
                fill="#4c4c5e"
              />
              <path
                d="M61 140.13c0 2.27 1.6 3.18 3.58 2a7.88 7.88 0 003.54-6.13c0-2.27-1.6-3.18-3.57-2a7.87 7.87 0 00-3.55 6.13z"
                fill="#474756"
              />
              <path
                d="M62 139.54c0 1.61 1.14 2.26 2.55 1.45a5.62 5.62 0 002.54-4.39c0-1.61-1.14-2.26-2.54-1.45a5.6 5.6 0 00-2.55 4.39z"
                fill="#e7f1f7"
              />
              <path
                d="M62 139.54a2.25 2.25 0 00.32 1.24 1.64 1.64 0 001.79-.11 5.24 5.24 0 002.37-4.1c0-1.09-.55-1.7-1.36-1.68a2.83 2.83 0 00-.57.26 5.6 5.6 0 00-2.55 4.39z"
                fill="#e7f1f7"
              />
              <path
                d="M66 134.83a1.77 1.77 0 01.74 1.63 5.59 5.59 0 01-2.55 4.39 2 2 0 01-1.45.32 1.79 1.79 0 001.81-.18 5.62 5.62 0 002.54-4.39c0-.99-.43-1.6-1.09-1.77z"
                fill="#474756"
              />
              <path
                d="M63.58 138.47c0 .42.3.59.66.38a1.47 1.47 0 00.67-1.15c0-.42-.3-.59-.67-.38a1.48 1.48 0 00-.66 1.15z"
                fill="url(#servicescardphoto1__radial-gradient-4)"
              />
              <path
                d="M64.19 135.69a.74.74 0 00-.34.58c0 .21.15.29.34.19a.76.76 0 00.33-.58c0-.21-.15-.29-.33-.19zm0 3.9a.72.72 0 00-.34.57c0 .21.15.3.34.19a.73.73 0 00.33-.57c0-.21-.15-.3-.33-.19zm1-3.23c.13.07.34-.05.47-.27s.13-.47 0-.54-.34 0-.47.27-.19.46-.04.54zm-1.93 3.33c-.13-.08-.34.05-.47.27s-.13.47 0 .54.34-.05.47-.27.09-.47-.04-.54zm2.66-3a.74.74 0 00-.33.58c0 .21.15.29.33.18a.71.71 0 00.34-.57c-.04-.24-.19-.32-.38-.22zm-3.39 2a.73.73 0 00-.34.58c0 .21.15.29.34.19a.76.76 0 00.33-.58c-.04-.28-.19-.36-.37-.26zm3.13-.32c-.13-.07-.34 0-.47.27s-.13.47 0 .54.34 0 .47-.27.09-.53-.04-.61zm-2.4-1.37c-.13-.07-.34 0-.47.27s-.13.47 0 .54.34 0 .47-.27.09-.54-.04-.61z"
                fill="#474756"
              />
              <path
                d="M63.16 138.47a.68.68 0 00.27.62l.42.25c.18.1.06-.12.33-.28a2.15 2.15 0 001-1.7c0-.31.28-.31.11-.41l-.45-.25a.71.71 0 00-.69.07 2.18 2.18 0 00-.99 1.7z"
                fill="#545466"
              />
              <path
                d="M63.6 138.73c0 .62.44.87 1 .56a2.2 2.2 0 001-1.7c0-.63-.45-.88-1-.56a2.16 2.16 0 00-1 1.7z"
                fill="#545466"
              />
            </g>
            <path
              fill="#e7f1f7"
              d="M60.07 142.01v-3.94l2.56-4.41 3.61-2.09-2.18-1.24-3.62 2.09-2.56 4.42v3.93l2.19 1.24z"
            />
            <path
              fill="#dce8f1"
              d="M60.07 138.07l2.56-4.41-2.19-1.24-2.56 4.42 2.19 1.23z"
            />
            <path
              fill="#cfdee8"
              d="M60.68 141.68v-3.44l2.4-4.14 3.18-1.84 1.95 1.12v2.71l.6-.34v-2.72l-2.56-1.47-3.62 2.1-2.56 4.41v3.96l.61-.35z"
            />
            <g>
              <path
                d="M41.32 132.69L55 140.84a2.19 2.19 0 002.16 0l2.57-1.44v2.34l-2.59 1.46a2.21 2.21 0 01-2.17 0l-13.69-7.87z"
                fill="#cfdee8"
              />
              <path
                d="M41.55 133.14l13.45 7.7a2.19 2.19 0 002.16 0l2.57-1.44-.7-.4-1.86 1.05a2.18 2.18 0 01-2.18 0l-12.67-7.31v-.93l-.82.5a.48.48 0 00.05.83z"
                fill="#e7f1f7"
              />
              <g
                style={{
                  mixBlendMode: "color-dodge",
                }}
              >
                <path
                  fill="url(#servicescardphoto1__radial-gradient-5)"
                  d="M42.34 132.58l.02.01v-.78l-.02.01v.76z"
                />
                <path
                  d="M59.62 139.32l-2.43 1.36a2.19 2.19 0 01-2.16 0L41.53 133a.5.5 0 01-.22-.28.47.47 0 00.24.44L55 140.84a2.19 2.19 0 002.16 0l2.57-1.44z"
                  fill="url(#servicescardphoto1__radial-gradient-6)"
                />
              </g>
            </g>
            <g>
              <path
                d="M70.05 112.47l-13.5-7.75-5.09 3.06A7.15 7.15 0 0047.9 114l13.47 7.68z"
                fill="#ff4a4a"
              />
              <path
                d="M61.38 121.62l8.68-5.1v-4.08l-5.12 3a7.15 7.15 0 00-3.56 6.18z"
                fill="#e50000"
              />
            </g>
            <path
              d="M53 137.44l2.55 1.44a.36.36 0 00.54-.31v-1.12a1 1 0 00-.52-.85l-.8-.42a1 1 0 00-1 .08l-.8.58a.36.36 0 00.03.6zM42.34 130.62v.92a.36.36 0 00.19.31l1.11.57a.27.27 0 00.37-.33l-.45-1.21a.71.71 0 00-.36-.4l-.37-.17a.34.34 0 00-.49.31z"
              style={{
                mixBlendMode: "multiply",
              }}
              fill="#e7f1f7"
            />
            <path
              d="M53 137.25l2.55 1.44a.36.36 0 00.54-.31v-1.11a1 1 0 00-.52-.86l-.8-.42a1 1 0 00-1 .08l-.8.58a.36.36 0 00.03.6zM42.34 130.47v.93a.36.36 0 00.19.3l1.11.57A.26.26 0 0044 132l-.45-1.21a.68.68 0 00-.36-.4l-.37-.18a.34.34 0 00-.48.26z"
              fill="#ffc950"
            />
            <path
              d="M53.21 140.27l1.92 1.11-.57.37a.85.85 0 01-.92 0l-2-1.16.72-.37a.92.92 0 01.85.05zM41.78 133.84l2.07 1.16a.89.89 0 01.38.45l.5 1.26-2.17-1.26a.84.84 0 01-.39-.48zM45.18 137.53v-.44a.6.6 0 01.9-.52l3.92 2.27a2 2 0 011 1.71v.34z"
              style={{
                mixBlendMode: "multiply",
              }}
              fill="#e7f1f7"
            />
            <path
              fill="#e7f1f7"
              d="M49.9 139.18v1.06l-3.81-2.16V137l3.81 2.18z"
            />
            <path
              d="M48.77 135.66c2.93 1.56 5 3.3 4.74 3.88s-2.94-.2-5.87-1.75-5-3.3-4.74-3.89 2.94.21 5.87 1.76z"
              style={{
                mixBlendMode: "color-dodge",
              }}
              fill="url(#servicescardphoto1__radial-gradient-7)"
            />
            <path d="M94.23 74.86a1.62 1.62 0 010-.22.58.58 0 01.28-.44l1.3-.75a.59.59 0 00-.29.44 1.55 1.55 0 000 .22.76.76 0 000 .21 1.43 1.43 0 00.08.22 2.12 2.12 0 00.11.21l.13.19a1.22 1.22 0 00.15.17l.12.11h.06l.2.13a.76.76 0 00.18.09h.48a.45.45 0 00.16 0l.12-.06-1.29.75H95.27a.56.56 0 01-.17-.09.86.86 0 01-.2-.13h-.06l-.08.09-.1-.11h-.05a.35.35 0 010-.08v-.24a.25.25 0 000-.07.19.19 0 010-.07.36.36 0 010-.11.13.13 0 000-.06z" />
            <path
              d="M93.77 72.87l-.29.13 1.3-.75.16-.08h.12l.24-.06h.11l-1.29.75a1.86 1.86 0 00-.34.07z"
              fill="#021226"
            />
            <path d="M95.53 73.78v-2l1.29-.75v2a.26.26 0 01-.11.25l-1.29.72a.28.28 0 00.11-.22z" />
            <path
              fill="#031934"
              d="M95.53 71.8l1.29-.75-1.24-.72-1.29.75 1.24.72z"
            />
            <path
              d="M92.93 73.77a1.29 1.29 0 011.23-1c.11 0 .11-.13.12-.25v-1.44l1.24.72v2c0 .14 0 .28-.24.28-.5 0-1 .09-1.05.58a1.7 1.7 0 00.9 1.6 1.16 1.16 0 00.65.19c.42 0 .57-.4.64-.36a1 1 0 01-.44 1.3 1.37 1.37 0 01-1.17-.18 3.9 3.9 0 01-1.88-3.44z"
              fill="#1a1a1a"
            />
            <g>
              <path
                d="M94.11 67.13a2.68 2.68 0 012.45 0c.69.38.71 1 0 1.42a2.68 2.68 0 01-2.45 0c-.65-.38-.67-1.02 0-1.42z"
                fill="#900200"
              />
              <path
                d="M92.75 68.58a.71.71 0 000 .14v.14l.05.06.06.06h.05l.07.06.08.06.08.06h.07a.31.31 0 00.1 0 2.37 2.37 0 00.61.25h-.08l.24.06H96.75a1.69 1.69 0 00.27-.13 1.29 1.29 0 00.98-.8v-.12-.22-.06a.14.14 0 000-.06V68a.33.33 0 00-.07-.13l-.06-.11-.05-.06-.08-.1a.71.71 0 00-.13-.12 2.87 2.87 0 00-1.53-.64 4 4 0 00-.69 0H94.21l-.14.08a1.59 1.59 0 00-.85 1.19V68.35zm3.85 0a2.73 2.73 0 01-1.84.24h-.1l-.2-.06-.15-.06-.16-.08c-.57-.32-.67-.8-.32-1.19a1.64 1.64 0 01.28-.23 2.68 2.68 0 012.45 0c.69.29.71.93.04 1.33zm-3.83-.22z"
                fill="#730200"
              />
              <path
                d="M92.74 68.55v2.3l.06.14v.13a.21.21 0 00.06.08l.07.07.13.11.12.09.17.1.65.43h.16l.17.06h2.33l.16-.08.13-.08a2.32 2.32 0 00.28-.24l.09-.09.1-.13.07-.11.07-.14v-.11-.29a.86.86 0 010-.16v-2.09a1.29 1.29 0 01-.74 1.06 3.13 3.13 0 01-.41.2l-.41.13h-.08l-.41.07h-1.02a2.63 2.63 0 01-.39-.06l-.25-.06.15-.04h-.09a2.09 2.09 0 01-.37-.17l-.12-.07-.1-.06-.08-.06-.07-.07-.06-.05-.1-.11h-.05l-.06-.1a.59.59 0 010-.08V69v-.08a.64.64 0 010-.07v-.07c0-.07-.16-.2-.16-.23z"
                fill="#5c0200"
              />
            </g>
            <g
              style={{
                isolation: "isolate",
              }}
            >
              <path d="M95.42 68.69l1.3-.75V56.87l-1.29.75-.01 11.07z" />
              <path
                fill="#072c5e"
                d="M95.43 57.62l1.29-.75-1.42-.82-1.3.75 1.43.82z"
              />
              <path
                fill="#1a1a1a"
                d="M94 56.8v11.07l1.42.82.01-11.07L94 56.8z"
              />
            </g>
            <path
              d="M96.23 77.22l.08-.1.06-.13a1 1 0 00.06-.16.5.5 0 000-.12.45.45 0 000-.12.4.4 0 000-.15.31.31 0 00-.06-.13l1.29-.75v.1a.15.15 0 010 .07v.16a.49.49 0 010 .12 1 1 0 01-.05.16.54.54 0 01-.07.13l-.08.09-.14.11-1.29.75z"
              fill="#031329"
            />
            <g
              style={{
                isolation: "isolate",
              }}
            >
              <path
                d="M28.25 60.28H27.32L9.75 70.37a.75.75 0 01.18-.07h.23l.25.06a1.11 1.11 0 01.27.13L36 85.12l17.57-10.2z"
                fill="#333"
              />
              <path d="M37.25 87v3.66a.71.71 0 01-.07.34.54.54 0 01-.2.22L54.53 81a.58.58 0 00.18-.17V76.77z" />
              <path
                d="M36.89 86a2.46 2.46 0 01.26.51 1.51 1.51 0 01.09.5l17.57-10.2V76.19l-.06-.09-.06-.11a.61.61 0 00-.07-.1v-.13l-.05-.06v-.06l-.06-.05-.51-.39h-.06l-.06-.05h-.25L36 85.12a2.35 2.35 0 01.47.36 2.07 2.07 0 01.42.52z"
                fill="#4d4d4d"
              />
              <path
                d="M36 85.12A2.45 2.45 0 0137.25 87v3.66c0 .62-.55.83-1.22.44L10.67 76.44a2.48 2.48 0 01-1.21-1.85v-3.65c0-.63.55-.84 1.21-.45z"
                fill="#1a1a1a"
              />
            </g>
            <g
              style={{
                isolation: "isolate",
              }}
            >
              <path
                d="M60 96v3.66a1 1 0 01-.08.4.64.64 0 01-.22.24l10.5-6.18a.48.48 0 00.18-.17v-.06a.14.14 0 010-.06.09.09 0 010-.05v-3.95z"
                fill="#730200"
              />
              <path
                d="M60.87 83.12H60.31l-10.69 6.05a.56.56 0 01.32-.06.94.94 0 01.39.13l8.64 5 10.54-6.12z"
                fill="#d10000"
              />
              <path
                d="M59.67 95a2.26 2.26 0 01.21.48A1.79 1.79 0 0160 96l10.54-6.12v-.36a.59.59 0 010-.09v-.19l-.07-.12-.07-.09v-.06h-.05L59 94.23a1.9 1.9 0 01.39.31 2.61 2.61 0 01.28.46z"
                fill="#910000"
              />
              <path
                d="M59 94.23l-8.64-5c-.55-.32-1-.06-1 .57v3.66a2.16 2.16 0 001 1.72l8.64 5c.55.32 1 .06 1-.57V96a2.22 2.22 0 00-1-1.77z"
                fill="#910000"
              />
            </g>
            <g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#910000"
                  d="M38.29 46.47l-1.09-.64.07 23.14 1.09.63-.07-23.13z"
                />
                <path
                  fill="#d10000"
                  d="M39.27 45.9l-1.09-.63-.98.56 1.09.64.98-.57z"
                />
                <path
                  fill="#730200"
                  d="M38.29 46.47l.07 23.13.98-.57-.07-23.13-.98.57z"
                />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#910000"
                  d="M28.61 40.95l-1.09-.63.06 23.13 1.09.64-.06-23.14z"
                />
                <path
                  fill="#d10000"
                  d="M29.58 40.39l-1.09-.64-.97.57 1.09.63.97-.56z"
                />
                <path
                  fill="#730200"
                  d="M28.61 40.95v1.22l.06 21.92.98-.57-.07-23.13-.97.56z"
                />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#d10000"
                  d="M38.36 48.17l-1.1-.63-18.93 15.44 1.1.64 18.93-15.45z"
                />
                <path
                  fill="#5d6872"
                  d="M19.43 63.62l-1.1-.64.01 1.15 1.09.64v-1.15z"
                />
                <path
                  fill="#730200"
                  d="M38.36 48.17L19.43 63.62v1.15l18.93-15.44v-1.16z"
                />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#910000"
                  d="M18.42 47.03l-1.09-.63.07 23.13 1.09.64-.07-23.14z"
                />
                <path
                  fill="#d10000"
                  d="M19.4 46.47l-1.09-.64-.98.57 1.09.63.98-.56z"
                />
                <path
                  fill="#730200"
                  d="M18.42 47.03l.07 23.14.98-.57-.07-23.13-.98.56z"
                />
              </g>
              <path
                fill="#910000"
                d="M18.24 50.34l19.03 15.59v1.15L18.23 51.49l.01-1.15z"
              />
              <path
                fill="#d10000"
                d="M37.26 65.92L18.24 50.34l1.09-.63 17.93 14.68v1.53z"
              />
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#910000"
                  d="M28.11 52.55l-1.09-.64.07 23.14 1.09.63-.07-23.13z"
                />
                <path
                  fill="#d10000"
                  d="M29.09 51.98L28 51.35l-.98.56 1.09.64.98-.57z"
                />
                <path
                  fill="#730200"
                  d="M28.11 52.55l.07 23.13.98-.57-.07-23.13-.98.57z"
                />
              </g>
              <g>
                <path
                  fill="#910000"
                  d="M28.41 42.05l9.51 5.4.01-1.21-9.63-5.47.11 1.28z"
                />
                <path
                  fill="#d10000"
                  d="M28.37 40.81l9.38 5.36.81-.69-9.22-5.24-.97.57z"
                />
              </g>
              <g>
                <path
                  fill="#730200"
                  d="M38.35 47.63l-9.89 5.95-.23-1.09 10.23-6.14-.11 1.28z"
                />
                <path
                  fill="#d10000"
                  d="M38.39 46.39l-10.11 6.08-.76-.69 9.92-6.08.95.69z"
                />
              </g>
              <g>
                <path
                  fill="#910000"
                  d="M28.6 42.15l-9.89 5.82-.23-1.08 10.07-5.93.05 1.19z"
                />
                <path
                  fill="#d10000"
                  d="M28.71 40.86l-10.18 6.01-.82-.69 10.05-6.01.95.69z"
                />
              </g>
              <g>
                <path
                  fill="#910000"
                  d="M17.59 47.76l9.93 5.63.23-1.08-10.26-5.83.1 1.28z"
                />
                <path
                  fill="#d10000"
                  d="M17.55 46.52l10.28 5.86.76-.69-9.98-5.67-1.06.5z"
                />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#910000"
                    d="M38.26 23.32l-1.09-.64.07 23.14 1.09.63-.07-23.13z"
                  />
                  <path
                    fill="#d10000"
                    d="M39.24 22.75l-1.09-.63-.98.56 1.09.64.98-.57z"
                  />
                  <path
                    fill="#730200"
                    d="M38.26 23.32l.07 23.13.97-.56-.06-23.14-.98.57z"
                  />
                </g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#910000"
                    d="M28.57 17.8l-1.09-.63.07 23.14 1.09.63-.07-23.14z"
                  />
                  <path
                    fill="#d10000"
                    d="M29.55 17.24l-1.09-.63-.98.56 1.09.63.98-.56z"
                  />
                  <path
                    fill="#730200"
                    d="M28.57 17.8v1.22l.07 21.92.97-.57-.06-23.13-.98.56z"
                  />
                </g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#d10000"
                    d="M38.32 25.03l-1.09-.64L18.3 39.83l1.09.64 18.93-15.44z"
                  />
                  <path
                    fill="#5d6872"
                    d="M19.39 40.47l-1.09-.64v1.16l1.1.63-.01-1.15z"
                  />
                  <path
                    fill="#730200"
                    d="M38.32 25.03L19.39 40.47l.01 1.15 18.93-15.44-.01-1.15z"
                  />
                </g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#910000"
                    d="M18.39 23.89l-1.09-.64.06 23.14 1.09.63-.06-23.13z"
                  />
                  <path
                    fill="#d10000"
                    d="M19.37 23.32l-1.09-.63-.98.56 1.09.64.98-.57z"
                  />
                  <path
                    fill="#730200"
                    d="M18.39 23.89l.06 23.13.98-.57-.06-23.13-.98.57z"
                  />
                </g>
                <path
                  fill="#910000"
                  d="M18.2 27.2l19.04 15.58-.01 1.15L18.2 28.34V27.2z"
                />
                <path
                  fill="#d10000"
                  d="M37.23 42.78L18.2 27.2l1.09-.64 17.93 14.69.01 1.53z"
                />
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#910000"
                    d="M28.08 29.4l-1.09-.63.06 23.13 1.09.63-.06-23.13z"
                  />
                  <path
                    fill="#d10000"
                    d="M29.06 28.83l-1.09-.63-.98.57 1.09.63.98-.57z"
                  />
                  <path
                    fill="#730200"
                    d="M28.08 29.4l.06 23.13.98-.56-.06-23.14-.98.57z"
                  />
                </g>
                <g>
                  <path
                    fill="#910000"
                    d="M28.37 18.91l9.51 5.39.01-1.21-9.62-5.47.1 1.29z"
                  />
                  <path
                    fill="#d10000"
                    d="M28.34 17.66l9.38 5.36.8-.68-9.22-5.24-.96.56z"
                  />
                </g>
                <g>
                  <path
                    fill="#730200"
                    d="M38.32 24.48l-9.89 5.95-.23-1.09 10.23-6.14-.11 1.28z"
                  />
                  <path
                    fill="#d10000"
                    d="M38.36 23.24l-10.12 6.08-.76-.68 9.92-6.09.96.69z"
                  />
                </g>
                <g>
                  <path
                    fill="#910000"
                    d="M28.56 19.01l-9.88 5.82-.23-1.09 10.07-5.93.04 1.2z"
                  />
                  <path
                    fill="#d10000"
                    d="M28.68 17.72l-10.19 6-.81-.69 10.05-6.01.95.7z"
                  />
                </g>
                <g>
                  <path
                    fill="#910000"
                    d="M17.56 24.61l9.93 5.64.23-1.09-10.27-5.83.11 1.28z"
                  />
                  <path
                    fill="#d10000"
                    d="M17.52 23.37l10.28 5.86.76-.68-9.99-5.67-1.05.49z"
                  />
                </g>
              </g>
            </g>
            <g
              style={{
                isolation: "isolate",
              }}
            >
              <path
                fill="#1a1a1a"
                d="M39.08 78.55l-3.82-2.21-.14-50.21 3.81 2.21.15 50.21z"
              />
              <path
                fill="#333"
                d="M38.93 28.34l-3.81-2.21 3.44-1.99 3.81 2.22-3.44 1.98z"
              />
              <path d="M42.37 26.36l.14 50.21-3.43 1.98-.15-50.21 3.44-1.98z" />
            </g>
            <g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path d="M100.58 52.98l3.76-2.18-.02 4.4-3.75 2.18.01-4.4z" />
                <path
                  fill="#333"
                  d="M12.56 2.18L16.32 0l88.02 50.8-3.76 2.18-88.02-50.8z"
                />
                <path
                  fill="#1a1a1a"
                  d="M100.58 52.98l-.01 4.4-88.02-50.8.01-4.4 88.02 50.8z"
                />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#d10000"
                  d="M14.95 1.31l1.51-.87 50.01 7.52-1.5.88-50.02-7.53z"
                />
                <path
                  fill="#a50000"
                  d="M64.97 8.84l1.5-.88.23.04-1.51.87-.22-.03z"
                />
                <path
                  fill="#ef0000"
                  d="M65.19 8.87L66.7 8l.22.26-1.51.87-.22-.26z"
                />
                <path
                  fill="#d10000"
                  d="M65.41 9.13l1.51-.87 36.11 42.91-1.51.88L65.41 9.13z"
                />
                <path
                  fill="#910000"
                  d="M65.16 8.83l.22.26L101.49 52l-2.57-1.64-33.84-40.21-48.05-7.23-2.11-1.65 50.02 7.52.22.04z"
                />
              </g>
            </g>
            <g
              style={{
                isolation: "isolate",
              }}
            >
              <path
                fill="#730200"
                d="M59.98 13.72l3.29-1.91L63.1 89l-3.29 1.91.17-77.19z"
              />
              <path
                fill="#d10000"
                d="M56.72 11.84l3.29-1.91 3.26 1.88-3.29 1.91-3.26-1.88z"
              />
              <path
                fill="#910000"
                d="M59.98 13.72l-.17 77.19-3.26-1.88.17-77.19 3.26 1.88z"
              />
            </g>
            <g>
              <path
                d="M72.31 52.92v8.51a1.79 1.79 0 010 .34 1.15 1.15 0 01-.09.28.48.48 0 01-.09.14.91.91 0 01-.24.24l10.54-6.12a.91.91 0 00.24-.24l.06-.11v-.09a.19.19 0 000-.08v-.08-.07a.22.22 0 000-.08v-.07a.19.19 0 000-.08v-8.52z"
                fill="#730200"
              />
              <path
                d="M73 39.26H72.12l-.12.06-10.69 5.96a.92.92 0 01.23-.09.82.82 0 01.27 0 1.5 1.5 0 01.29.06 1.82 1.82 0 01.32.15l8.3 4.79 10.54-6.12z"
                fill="#b80000"
              />
              <path
                d="M71.34 50.68a4 4 0 01.26.32c.08.11.15.23.23.35.06.12.13.25.18.37a3.12 3.12 0 01.15.39 2.74 2.74 0 01.09.39 2.56 2.56 0 010 .39l10.54-6.13v-.14a.22.22 0 010-.08v-.08-.08a.22.22 0 010-.08v-.16-.15-.38a.3.3 0 010-.08v-.18l-.06-.1-.48-.25-.09-.14-.07-.09-.09-.06v-.07l-.06-.07-.06-.06-.07-.07-.07-.06h-.07l-.07-.05h-.13l-10.74 5.85a3.05 3.05 0 01.32.22 3.83 3.83 0 01.29.28z"
                fill="#910000"
              />
              <path
                d="M70.73 50.18l-8.3-4.79c-.87-.5-1.58-.1-1.59.91v8.51a3.51 3.51 0 001.57 2.73l8.31 4.79c.87.5 1.58.1 1.59-.9v-8.51a3.5 3.5 0 00-1.58-2.74z"
                fill="#910000"
              />
              <path
                d="M69.94 51.8a1.48 1.48 0 01.56 1.2v7.24c0 .48-.25.72-.56.54l-2.44-1.41a1.47 1.47 0 01-.5-1.18V51c0-.48.25-.72.56-.54z"
                fill="#e6e6e6"
              />
            </g>
            <g>
              <path
                fill="#730200"
                d="M65.19 10.33l-11.27 7.11-.37-1.36 11.58-7.24.06 1.49z"
              />
              <path
                fill="#d10000"
                d="M65.19 8.87l-11.52 7.22-1.02-.8 11.22-6.67 1.32.25z"
              />
            </g>
            <g>
              <path d="M101.76 56.68l-13.74 7.57v-4.49l13.36-7.24.38 4.16z" />
              <path
                fill="#333"
                d="M101.16 52.61L88.29 59.7l-2.35-1.88 12.17-6.92 3.05 1.71z"
              />
            </g>
            <g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path d="M88.03 59.85l3.76-2.18-.02 4.4-3.75 2.18.01-4.4z" />
                <path
                  fill="#333"
                  d="M.01 9.05l3.76-2.18 88.02 50.8-3.76 2.18L.01 9.05z"
                />
                <path
                  fill="#1a1a1a"
                  d="M88.03 59.85l-.01 4.4L0 13.46l.01-4.41 88.02 50.8z"
                />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#d10000"
                  d="M2.4 8.19l1.51-.88 50.01 7.52-1.5.88L2.4 8.19z"
                />
                <path
                  fill="#a50000"
                  d="M52.42 15.71l1.5-.88.23.04-1.5.87-.23-.03z"
                />
                <path
                  fill="#ef0000"
                  d="M52.65 15.74l1.5-.87.22.26-1.51.87-.21-.26z"
                />
                <path
                  fill="#d10000"
                  d="M52.86 16l1.51-.87 36.11 42.92-1.51.87L52.86 16z"
                />
                <path
                  fill="#910000"
                  d="M52.65 15.74l.21.26 36.11 42.92-2.57-1.64-33.83-40.21L4.51 9.84 2.4 8.19l50.02 7.52.23.03z"
                />
              </g>
            </g>
            <g>
              <path
                fill="url(#servicescardphoto1__New_Gradient_Swatch_copy_27)"
                d="M80.9 100.41v16.34l48.52-28.22V72.24L80.9 100.41z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#eaeaea"
                d="M82.05 101.07l46.22-26.84v13.64l-46.22 26.88v-13.68z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M83.51 100.41l-.6.4v13.68l.6-.4v-13.68z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M82.88 100.76l-.23-.01v13.67l.23.01v-13.67z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M85.5 99.26l-.61.4v13.68l.61-.4V99.26z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M84.86 99.6l-.22-.01v13.68l.22.01V99.6z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M87.48 98.11l-.6.4v13.68l.6-.41V98.11z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M86.85 98.45l-.23-.01v13.68l.23.01V98.45z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M89.47 96.95l-.61.41v13.67l.61-.4V96.95z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M88.83 97.3l-.23-.01v13.68l.23.01V97.3z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M91.45 95.8l-.61.4v13.68l.61-.4V95.8z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M90.81 96.15l-.23-.01v13.68l.23.01V96.15z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M93.43 94.65l-.6.4v13.68l.6-.4V94.65z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M92.8 95l-.23-.02v13.69h.23V95z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M95.42 93.5l-.61.4v13.68l.61-.4V93.5z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M94.78 93.84l-.23-.01v13.68l.23.01V93.84z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M97.4 92.35l-.61.4v13.68l.61-.4V92.35z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M96.77 92.69l-.24-.01v13.68l.24.01V92.69z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M99.38 91.19l-.6.4v13.68l.6-.4V91.19z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M98.75 91.54l-.23-.01v13.68l.23.01V91.54z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M101.36 90.04l-.6.4v13.68l.6-.4V90.04z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M100.73 90.39l-.23-.01v13.68l.23.01V90.39z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M103.35 88.89l-.61.4v13.68l.61-.4V88.89z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M102.72 89.23l-.23-.01v13.69h.23V89.23z"
              />
              <path
                style={{
                  mixBlendMode: "screen",
                }}
                fill="#5c5d5e"
                d="M105.33 87.74l-.6.4v13.68l.6-.4V87.74z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#c8c8c8"
                d="M104.7 88.08l-.23-.01v13.68l.23.01V88.08z"
              />
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M107.31 86.59l-.6.4v13.68l.6-.4V86.59z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M106.68 86.93l-.23-.01v13.68l.23.01V86.93z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M109.3 85.43l-.61.4v13.68l.61-.4V85.43z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M108.67 85.78l-.23-.01v13.68l.23.01V85.78z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M111.28 84.28l-.6.4v13.68l.6-.4V84.28z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M110.65 84.63l-.23-.01V98.3l.23.01V84.63z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M113.27 83.13l-.61.4v13.68l.61-.4V83.13z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M112.63 83.47h-.23v13.67l.23.01V83.47z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M115.25 81.98l-.61.4v13.68l.61-.4V81.98z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M114.62 82.32l-.23-.01v13.68l.23.01V82.32z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M117.23 80.83l-.6.4v13.68l.6-.41V80.83z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M116.6 81.17l-.23-.01v13.68l.23.01V81.17z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M119.22 79.67l-.61.4v13.68l.61-.4V79.67z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M118.58 80.02l-.23-.01v13.68l.23.01V80.02z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M121.2 78.52l-.61.4V92.6l.61-.4V78.52z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M120.57 78.87l-.23-.01v13.68l.23.01V78.87z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M123.18 77.37l-.6.4v13.68l.6-.4V77.37z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M122.55 77.72l-.23-.02v13.68l.23.01V77.72z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M125.17 76.22l-.61.4V90.3l.61-.4V76.22z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M124.53 76.56l-.23-.01v13.68l.23.01V76.56z"
                />
              </g>
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M127.15 75.06l-.61.41v13.67l.61-.4V75.06z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M126.52 75.41l-.23-.01v13.68l.23.01V75.41z"
                />
              </g>
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#dadada"
                d="M82.05 101.07v1.2l45.82-26.6V88.1l.4-.23V74.23l-46.22 26.84z"
              />
              <path
                fill="url(#servicescardphoto1__New_Gradient_Swatch_copy_27-2)"
                d="M80.9 100.41v16.34l-13.31-7.57V92.8l13.31 7.61z"
              />
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#eaeaea"
                d="M68.74 108.51V94.78l11.01 6.3v13.69l-11.01-6.26z"
              />
              <path
                fill="url(#servicescardphoto1__New_Gradient_Swatch_copy_27-3)"
                d="M67.59 92.8l13.31 7.61 48.52-28.17-13.32-7.6L67.59 92.8z"
              />
              <g>
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M78.63 100.66l.61.4v13.68l-.61-.41v-13.67z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M79.27 101l.23-.01v13.68l-.23.01V101z"
                />
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M76.84 99.6l.61.4v13.68l-.61-.4V99.6z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M77.47 99.95l.23-.01v13.68l-.23.01V99.95z"
                />
                <path
                  style={{
                    mixBlendMode: "screen",
                  }}
                  fill="#5c5d5e"
                  d="M75.05 98.55l.6.4v13.68l-.6-.4V98.55z"
                />
                <path
                  style={{
                    mixBlendMode: "multiply",
                  }}
                  fill="#c8c8c8"
                  d="M75.68 98.89l.23-.01v13.68l-.23.01V98.89z"
                />
                <g>
                  <path
                    style={{
                      mixBlendMode: "screen",
                    }}
                    fill="#5c5d5e"
                    d="M73.26 97.5l.6.39v13.69l-.6-.41V97.5z"
                  />
                  <path
                    style={{
                      mixBlendMode: "multiply",
                    }}
                    fill="#c8c8c8"
                    d="M73.89 97.84l.23-.01v13.68l-.23.01V97.84z"
                  />
                </g>
                <g>
                  <path
                    style={{
                      mixBlendMode: "screen",
                    }}
                    fill="#5c5d5e"
                    d="M71.46 96.44l.61.4v13.68l-.61-.4V96.44z"
                  />
                  <path
                    style={{
                      mixBlendMode: "multiply",
                    }}
                    fill="#c8c8c8"
                    d="M72.1 96.79l.23-.01v13.68l-.23.01V96.79z"
                  />
                </g>
                <g>
                  <path
                    style={{
                      mixBlendMode: "screen",
                    }}
                    fill="#5c5d5e"
                    d="M69.67 95.39l.61.4v13.68l-.61-.4V95.39z"
                  />
                  <path
                    style={{
                      mixBlendMode: "multiply",
                    }}
                    fill="#c8c8c8"
                    d="M70.3 95.73l.23-.01v13.68l-.23.01V95.73z"
                  />
                </g>
              </g>
              <path
                style={{
                  mixBlendMode: "multiply",
                }}
                fill="#dadada"
                d="M80.1 101.31v1.21L68.95 96v12.43l-.4-.24V94.56l11.55 6.75z"
              />
            </g>
            <path d="M95.35 75.39l-.02.09-.01.01-.08.26-.1.33-.27.89h-.01l-5.79 18.69-.71.42 5.79-18.7.23-.73.18-.6.26-.83.48.16.05.01z" />
            <path d="M114.33 81l-.66.38-17.31-5.28-.88-.27-.24-.08-.05-.01.07-.21.04-.15.06-.18.26.08h.01l.67.21.73.23 17.3 5.28z" />
            <path
              fill="#1a1a1a"
              d="M99.43 74.31l-1.16.68-1.97.5-.56.15-.1.02-.06-.29-.02-.07.07-.02 3.8-.97z"
            />
            <path
              fill="#1a1a1a"
              d="M95.76 75.62l-.02.02-.26.19-.34.25-.76.57-3.64 2.71-.01.01-2.76 1.6 6.59-4.92.7-.52.07-.05.21-.16.04.05v.01l.18.24z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;

