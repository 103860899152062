import * as React from "react";
import {motion} from "framer-motion"
function SvgComponent(props) {
  // const containerVariant = {
  //   start :{
  //     opacity:0,
  //     transition:{
        
  //     }
  //   },
  //   end : {
  //     opacity:1,
  //     transition:{
  //         duration: 1,
  //         staggerChildren: 0.1
  //     }
  //   }
  // }

  // const containerChildVariants = {
  //   start :{
  //     y:0
  //   },

  //   end : {
  //     y:-30
  //   }
  // }

  // const containerChildTransition = {
  //     duration:0.5,
  //     yoyo: Infinity,
  //     ease: "easeInOut"
  // }
  return (
    <svg
      width={"100%"}
      viewBox="0 0 1400 1100"
      {...props}
    >
      <motion.g

      initial={{x:0,y:0,opacity:0}}
      animate={{x:[0,0,1490],y:[0,0,778],opacity:[0,1,1]}}
      transition={{duration:10, loop:Infinity}}
        style={{
          isolation: "isolate",
        }}
      >
        <g id="ltlbackground__Layer_2" data-name="Layer 2">
          <g id="ltlbackground__Layer_1-2" data-name="Layer 1">
            <path
              fill="#232323"
              d="M480.04 574.7l9.47-5.1 17.94-22.75-30.17-12.28 2.76 40.13z"
            />
            <g id="ltlbackground__wheel1">
              <path
                d="M491.71 559.69l-1-1.82 22.69-13.5c5.52-3 13-2.7 21.29 1.89 17.29 9.59 31.73 34 32.09 54.15.18 9.93-3.09 17.06-8.51 20.62l-.13.07c-.22.15-.46.26-.69.4l-23.19 13.9-1.46-2.11a28.13 28.13 0 01-12.66-3.86c-17.3-9.58-31.74-33.94-32.1-54.14-.11-6.46 1.24-11.72 3.67-15.6z"
                fill="#3c3c3b"
              />
              <ellipse
                cx={512.57}
                cy={596.6}
                rx={25.69}
                ry={44.52}
                transform="rotate(-29.94 512.662 596.653)"
                fill="#575756"
              />
              <path
                d="M530.78 607.06c-.21-12.12-8.58-26.74-18.6-32.5s-18-.54-17.81 11.57 8.63 26.75 18.63 32.5 18 .55 17.78-11.57z"
                fill="#d9d9d9"
              />
              <path
                d="M525.88 598.18c-.08-4.46-3.16-9.85-6.85-12s-6.64-.2-6.56 4.26 3.16 9.85 6.85 12 6.68.21 6.56-4.26z"
                fill="#3c3c3b"
              />
            </g>
            <g id="ltlbackground__wheel2">
              <path
                d="M38 307.35l-1-1.82L59.69 292c5.53-3.05 13-2.7 21.29 1.89 17.3 9.59 31.74 33.95 32.1 54.15.17 9.93-3.1 17.06-8.51 20.62l-.12.07c-.23.15-.47.26-.7.4l-23.22 13.94-1.46-2.07a28.32 28.32 0 01-12.66-3.87c-17.29-9.62-31.74-33.98-32.09-54.13-.12-6.51 1.23-11.77 3.68-15.65z"
                fill="#3c3c3b"
              />
              <ellipse
                cx={58.85}
                cy={344.26}
                rx={25.69}
                ry={44.52}
                transform="rotate(-29.94 58.873 344.298)"
                fill="#575756"
              />
              <ellipse
                cx={58.85}
                cy={344.26}
                rx={14.99}
                ry={26.49}
                transform="rotate(-28.26 58.828 344.205)"
                fill="#d9d9d9"
              />
              <ellipse
                cx={65.45}
                cy={341.99}
                rx={5.52}
                ry={9.76}
                transform="rotate(-28.26 65.419 341.936)"
                fill="#3c3c3b"
              />
            </g>
            <g id="ltlbackground__wheel3">
              <path
                d="M109.77 347.59l-1-1.82 22.69-13.5c5.52-3.06 13-2.7 21.29 1.89 17.29 9.58 31.74 33.95 32.09 54.15.18 9.93-3.09 17.05-8.5 20.62l-.13.07c-.22.14-.46.26-.69.39l-23.2 13.91-1.46-2.11a28.13 28.13 0 01-12.66-3.86c-17.3-9.58-31.74-33.95-32.1-54.15-.1-6.45 1.24-11.71 3.67-15.59z"
                fill="#3c3c3b"
              />
              <ellipse
                cx={130.64}
                cy={384.5}
                rx={25.69}
                ry={44.52}
                transform="matrix(.87 -.5 .5 .87 -174.49 116.53)"
                fill="#575756"
              />
              <ellipse
                cx={130.64}
                cy={384.5}
                rx={14.99}
                ry={26.49}
                transform="rotate(-28.26 130.602 384.45)"
                fill="#d9d9d9"
              />
              <path
                d="M143.94 386.08c-.08-4.46-3.16-9.85-6.85-12s-6.64-.2-6.56 4.26 3.16 9.85 6.85 12 6.62.21 6.56-4.26z"
                fill="#3c3c3b"
              />
            </g>
            <g id="ltlbackground__wheel4">
              <path
                d="M181.56 387.83l-1-1.82 22.69-13.5c5.52-3.06 13-2.7 21.28 1.89 17.3 9.58 31.74 33.95 32.1 54.15.17 9.93-3.1 17.05-8.51 20.62l-.12.07c-.22.14-.46.26-.69.39l-23.21 13.91-1.45-2.11a28.13 28.13 0 01-12.65-3.86c-17.3-9.58-31.74-33.95-32.1-54.15-.12-6.42 1.22-11.71 3.66-15.59z"
                fill="#3c3c3b"
              />
              <ellipse
                cx={202.42}
                cy={424.74}
                rx={25.69}
                ry={44.52}
                transform="rotate(-29.94 202.47 424.79)"
                fill="#575756"
              />
              <ellipse
                cx={202.42}
                cy={424.74}
                rx={14.99}
                ry={26.49}
                transform="rotate(-28.26 202.381 424.675)"
                fill="#d9d9d9"
              />
              <path
                d="M215.73 426.32c-.08-4.47-3.16-9.85-6.85-12s-6.64-.2-6.57 4.26 3.17 9.85 6.85 12 6.65.2 6.57-4.26z"
                fill="#3c3c3b"
              />
            </g>
            <g id="ltlbackground__wheel5">
              <path
                d="M253.35 428.06l-1-1.81 22.68-13.5c5.53-3.06 13-2.7 21.29 1.89 17.29 9.58 31.74 33.95 32.09 54.15.18 9.93-3.09 17.05-8.5 20.62l-.12.07c-.23.14-.47.26-.7.39l-23.26 13.86-1.46-2.11a28.29 28.29 0 01-12.66-3.86c-17.29-9.58-31.74-33.95-32.09-54.15-.06-6.4 1.29-11.61 3.73-15.55z"
                fill="#3c3c3b"
              />
              <ellipse
                cx={274.21}
                cy={464.97}
                rx={25.69}
                ry={44.52}
                transform="rotate(-29.94 274.261 465.025)"
                fill="#575756"
              />
              <path
                d="M292.41 475.44c-.21-12.12-8.58-26.74-18.59-32.5s-18-.55-17.82 11.57 8.59 26.74 18.6 32.5 18.03.55 17.81-11.57z"
                fill="#d9d9d9"
              />
              <path
                d="M287.51 466.56c-.08-4.47-3.16-9.85-6.85-12s-6.64-.21-6.56 4.26 3.16 9.85 6.85 12 6.64.18 6.56-4.26z"
                fill="#3c3c3b"
              />
            </g>
            <path
              fill="#ed1c24"
              d="M647.89 619.2l126.88-76.44-652.61-355.77L0 265.21 647.89 619.2z"
            />
            <path
              fill="#575756"
              d="M725.59 516.4l-122.16 78.59L0 265.21l122.16-78.22L725.59 516.4z"
            />
            <path
              fill="#1a1a1a"
              d="M722.23 326.59l-60.35 162.74-539.72-302.34L123.5 0l598.73 326.59z"
            />
            <path
              d="M647.89 619.2l9.21 4.15 22.27 13 3.53 11.61s-18.77-12.9-18.41 7.42l.17 9.27a29.3 29.3 0 00-2.32 10.94l-13.59-7.62-95.08-52.57-.38-21.21c-.16-9.37-7-20.77-15.22-25.31l-43.8-24.27c-8.21-4.55-14.79-.59-14.62 8.78l.38 21.3L.86 314 0 265.21z"
              fill="#8e000b"
            />
            <path
              d="M.86 314L0 265.21l603.43 329.4.86 48.78-50.61-28-.38-21.21c-.16-9.37-7-20.77-15.22-25.31l-43.8-24.27c-8.21-4.55-14.79-.59-14.62 8.78l.38 21.3z"
              fill="#3c3c3b"
            />
            <ellipse
              cx={274.21}
              cy={464.97}
              rx={25.69}
              ry={44.52}
              transform="rotate(-29.94 274.261 465.025)"
              fill="#575756"
            />
            <path
              d="M292.41 475.44c-.21-12.12-8.58-26.74-18.59-32.5s-18-.55-17.82 11.57 8.59 26.74 18.6 32.5 18.03.55 17.81-11.57z"
              fill="#d9d9d9"
            />
            <path
              d="M287.51 466.56c-.08-4.47-3.16-9.85-6.85-12s-6.64-.21-6.56 4.26 3.16 9.85 6.85 12 6.64.18 6.56-4.26z"
              fill="#3c3c3b"
            />
            <ellipse
              cx={202.42}
              cy={424.74}
              rx={25.69}
              ry={44.52}
              transform="rotate(-29.94 202.47 424.79)"
              fill="#575756"
            />
            <ellipse
              cx={202.42}
              cy={424.74}
              rx={14.99}
              ry={26.49}
              transform="rotate(-28.26 202.381 424.675)"
              fill="#d9d9d9"
            />
            <path
              d="M215.73 426.32c-.08-4.47-3.16-9.85-6.85-12s-6.64-.2-6.57 4.26 3.17 9.85 6.85 12 6.65.2 6.57-4.26z"
              fill="#3c3c3b"
            />
            <ellipse
              cx={130.64}
              cy={384.5}
              rx={25.69}
              ry={44.52}
              transform="matrix(.87 -.5 .5 .87 -174.49 116.53)"
              fill="#575756"
            />
            <ellipse
              cx={130.64}
              cy={384.5}
              rx={14.99}
              ry={26.49}
              transform="rotate(-28.26 130.602 384.45)"
              fill="#d9d9d9"
            />
            <path
              d="M143.94 386.08c-.08-4.46-3.16-9.85-6.85-12s-6.64-.2-6.56 4.26 3.16 9.85 6.85 12 6.62.21 6.56-4.26z"
              fill="#3c3c3b"
            />
            <ellipse
              cx={58.85}
              cy={344.26}
              rx={25.69}
              ry={44.52}
              transform="rotate(-29.94 58.873 344.298)"
              fill="#575756"
            />
            <ellipse
              cx={58.85}
              cy={344.26}
              rx={14.99}
              ry={26.49}
              transform="rotate(-28.26 58.828 344.205)"
              fill="#d9d9d9"
            />
            <ellipse
              cx={65.45}
              cy={341.99}
              rx={5.52}
              ry={9.76}
              transform="rotate(-28.26 65.419 341.936)"
              fill="#3c3c3b"
            />
            <path
              style={{
                mixBlendMode: "overlay",
              }}
              fill="#094aa8"
              d="M646.23 663.65l2.47 1.37-.76-43.3-2.47-1.37.76 43.3z"
            />
            <path
              id="ltlbackground__gate"
              fill="#333"
              d="M122.16 186.99L123.5 0 1.25 78.6 0 265.21l122.16-78.22z"
            />
            <g id="ltlbackground__boxcollection5">
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M147.1 221.69l.67 45.44-50.06 30.29-.68-45.44 50.07-30.29z"
                />
                <path
                  fill="#e2af98"
                  d="M127.59 233.52l.15 9.88-3.46 2.09-3.97 2.4-.14-9.88 7.42-4.49z"
                />
                <path
                  fill="#fed0b5"
                  d="M97.03 251.98l.68 45.44-60.28-33.39-.67-45.44 60.27 33.39z"
                />
                <path
                  fill="#ffdcc5"
                  d="M147.1 221.69l-50.07 30.29-60.27-33.39 50.06-30.29 60.28 33.39z"
                />
                <path
                  fill="#fed0b5"
                  d="M127.59 233.52l-7.42 4.49-60.28-33.39 7.42-4.49 60.28 33.39z"
                />
              </g>
              <g fill="#616173">
                <path d="M56.82 263.14a5.11 5.11 0 01.18 1.06 3 3 0 01-1.47 2.89 1.61 1.61 0 00-.71 1.54l.71.39a.5.5 0 01.22.38c0 .15-.1.19-.21.13l-1.87-1a.47.47 0 01-.24-.38c0-.15.1-.19.23-.12l.7.39a4.91 4.91 0 00-.77-2.39c-1.3-2.09-1.8-4.05-1.49-5.49zM60.55 263.87l8.69 4.81.15 9.88-8.69-4.81zm8.41 14l-.13-8.93-7.83-4.36.13 8.93 7.87 4.35" />
                <path d="M64.94 267.39a.32.32 0 01.15.26v.11a7.55 7.55 0 013.09 4.79 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.33 1.33 0 00-.54-.73.34.34 0 00-.52.13 1.35 1.35 0 00-.55-.73c-.16-.08-.28-.09-.37 0v3.37c0 .66-.46.94-1.05.62a2.34 2.34 0 01-1.07-1.84v-.37c0-.1.07-.14.17-.09a.36.36 0 01.15.27v.38a1.61 1.61 0 00.75 1.26c.41.23.74 0 .74-.43v-3.37a1.05 1.05 0 00-.38-.4c-.24-.13-.43-.08-.53.13a1.31 1.31 0 00-.54-.73c-.24-.13-.43-.08-.53.14a1.34 1.34 0 00-.54-.74.34.34 0 00-.54.14 1.91 1.91 0 013-1.35v-.11c.02-.1.09-.14.19-.08zM46.74 264.04v.57l-5.33-2.95-.01-.58 5.34 2.96zM46.72 262.91l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                <path d="M45.47 256.92l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                <path d="M39.61 252.27l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.93L40 253l.13 8.92 7.87 4.33" />
                <path d="M42.47 255.26l1.33 2.84-.96-.54.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4zM50.08 258.07l8.68 4.81.15 9.88-8.69-4.76zm8.41 14l-.14-8.92-7.84-4.35.13 8.93 7.85 4.34" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M215.69 259.96l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                />
                <path
                  fill="#e2af98"
                  d="M196.18 271.79l.15 9.87-3.46 2.09-3.97 2.4-.14-9.87 7.42-4.49z"
                />
                <path
                  fill="#fed0b5"
                  d="M165.63 290.25l.67 45.44-60.28-33.4-.67-45.44 60.28 33.4z"
                />
                <path
                  fill="#ffdcc5"
                  d="M215.69 259.96l-50.06 30.29-60.28-33.4 50.06-30.29 60.28 33.4z"
                />
                <path
                  fill="#fed0b5"
                  d="M196.18 271.79l-7.42 4.49-60.28-33.4 7.42-4.49 60.28 33.4z"
                />
              </g>
              <g fill="#616173">
                <path d="M125.41 301.4a5.2 5.2 0 01.13 1.07 3 3 0 01-1.47 2.88 1.62 1.62 0 00-.71 1.54l.71.4a.48.48 0 01.22.38c0 .14-.1.19-.21.12l-1.87-1a.48.48 0 01-.24-.38c0-.15.1-.2.23-.13l.7.39a4.87 4.87 0 00-.77-2.38c-1.3-2.1-1.8-4.06-1.49-5.5zM129.14 302.13l8.69 4.82.15 9.88-8.69-4.83zm8.41 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                <path d="M133.53 305.65a.34.34 0 01.15.26v.09a7.55 7.55 0 013.09 4.79 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.31 1.31 0 00-.54-.72.33.33 0 00-.52.13 1.31 1.31 0 00-.55-.73c-.16-.09-.28-.1-.37 0v3.38c0 .66-.46.94-1.05.61a2.32 2.32 0 01-1.08-1.81v-.37c0-.11.07-.15.17-.1a.36.36 0 01.15.28v.37a1.61 1.61 0 00.75 1.26c.41.23.74 0 .74-.42v-3.38a1 1 0 00-.38-.39.34.34 0 00-.53.12 1.35 1.35 0 00-.54-.73c-.24-.13-.43-.08-.53.14a1.34 1.34 0 00-.54-.74c-.24-.13-.44-.08-.54.14a1.91 1.91 0 013-1.34v-.12c.03-.1.1-.14.2-.09zM115.33 302.3v.58l-5.33-2.96-.01-.58 5.34 2.96zM115.31 301.18l.01.57-5.34-2.96-.01-.57.83.46.68.37 2.32 1.29.68.37.83.47z" />
                <path d="M114.06 295.19l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.53 1.26-1.39z" />
                <path d="M108.2 290.53l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.92-7.85-4.35.13 8.93 7.85 4.34" />
                <path d="M111.06 293.53l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.53 1.26-1.39zM118.67 296.33l8.68 4.81.15 9.88-8.69-4.81zm8.41 14l-.14-8.93-7.84-4.4.13 8.93 7.85 4.35" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M284.28 298.22l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M264.77 310.05l.15 9.87-3.46 2.09-3.97 2.4-.14-9.87 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M234.22 328.51l.67 45.44-60.28-33.4-.67-45.44 60.28 33.4z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M284.28 298.22l-50.06 30.29-60.28-33.4L224 264.82l60.28 33.4z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M264.77 310.05l-7.42 4.49-60.28-33.4 7.42-4.49 60.28 33.4z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M194 339.67a5 5 0 01.13 1.06 3 3 0 01-1.47 2.88 1.64 1.64 0 00-.71 1.55l.71.39a.48.48 0 01.22.38c0 .14-.1.19-.21.13l-1.87-1a.48.48 0 01-.24-.38c0-.15.1-.2.23-.13l.7.39a4.9 4.9 0 00-.77-2.38c-1.3-2.09-1.8-4-1.49-5.5zM197.73 340.4l8.69 4.81.15 9.88-8.69-4.81zm8.41 14l-.13-8.92-7.85-4.35.13 8.93 7.85 4.34" />
                  <path d="M202.12 343.91a.35.35 0 01.15.26v.12a7.52 7.52 0 013.09 4.78 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.35 1.35 0 00-.54-.72.33.33 0 00-.52.13 1.31 1.31 0 00-.55-.73c-.16-.09-.28-.09-.37 0v3.37c0 .66-.46.94-1.05.61a2.32 2.32 0 01-1.08-1.81v-.37a.11.11 0 01.17-.1.36.36 0 01.15.28v.37a1.63 1.63 0 00.75 1.27c.41.23.74 0 .74-.43v-3.38a1 1 0 00-.38-.39c-.24-.13-.43-.08-.53.12a1.28 1.28 0 00-.54-.72c-.24-.13-.43-.08-.53.14a1.36 1.36 0 00-.54-.75c-.24-.13-.44-.08-.54.15a1.91 1.91 0 013-1.35V344c.08-.1.08-.14.2-.09zM183.92 340.56l.01.58-5.34-2.96-.01-.58 5.34 2.96zM183.9 339.44l.01.58-5.34-2.96-.01-.58.83.46.68.37 2.32 1.29.68.38.83.46z" />
                  <path d="M182.65 333.45l1.33 2.83-.96-.53.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                  <path d="M176.79 328.79l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.93-7.85-4.35.13 8.93 7.85 4.35" />
                  <path d="M179.65 331.79l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.52 1.26-1.4zM187.26 334.59l8.68 4.82.15 9.88-8.69-4.82zm8.41 14l-.14-8.93-7.84-4.34.13 8.92 7.85 4.35" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M352.87 336.48l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M333.36 348.31l.15 9.88-3.46 2.08-3.97 2.41-.14-9.88 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M302.81 366.77l.67 45.44-60.28-33.39-.67-45.44 60.28 33.39z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M352.87 336.48l-50.06 30.29-60.28-33.39 50.06-30.29 60.28 33.39z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M333.36 348.31l-7.42 4.49-60.28-33.39 7.42-4.49 60.28 33.39z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M262.59 377.93a5.11 5.11 0 01.13 1.06 3 3 0 01-1.47 2.89 1.61 1.61 0 00-.71 1.54l.71.39a.5.5 0 01.22.38c0 .15-.1.19-.21.13l-1.87-1a.47.47 0 01-.24-.38c0-.15.1-.19.23-.12l.7.39a4.91 4.91 0 00-.77-2.39c-1.3-2.09-1.8-4-1.49-5.49zM266.32 378.66l8.69 4.81.15 9.88-8.69-4.81zm8.41 14l-.13-8.93-7.85-4.35.13 8.93 7.85 4.35" />
                  <path d="M270.71 382.18a.32.32 0 01.15.26v.11a7.55 7.55 0 013.09 4.79 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.33 1.33 0 00-.54-.73.34.34 0 00-.52.13 1.35 1.35 0 00-.55-.73c-.16-.08-.28-.09-.37 0v3.37c0 .66-.46.94-1.05.62a2.34 2.34 0 01-1.08-1.82v-.37c0-.1.08-.14.17-.09a.36.36 0 01.15.27v.38a1.61 1.61 0 00.75 1.26c.41.23.74 0 .74-.43v-3.37a1.05 1.05 0 00-.38-.4c-.24-.13-.43-.08-.53.13a1.31 1.31 0 00-.54-.73c-.24-.13-.43-.08-.53.14a1.3 1.3 0 00-.54-.74.34.34 0 00-.54.14 1.91 1.91 0 013-1.35v-.11c.03-.12.1-.16.2-.1zM252.51 378.82v.58l-5.33-2.95-.01-.58 5.34 2.95zM252.49 377.7l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                  <path d="M251.24 371.71l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                  <path d="M245.38 367.05l8.68 4.82.15 9.88-8.69-4.81zm8.4 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                  <path d="M248.24 370.05l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4zM255.85 372.86l8.68 4.81.15 9.88-8.68-4.81zm8.41 14l-.14-8.92-7.84-4.35.13 8.93 7.85 4.34" />
                </g>
              </g>
            </g>
            <g id="ltlbackground__boxcollection4">
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M420.59 375.44l.68 45.44-50.06 30.28-.68-45.44 50.06-30.28z"
                />
                <path
                  fill="#e2af98"
                  d="M401.09 387.26l.14 9.88-3.45 2.09-3.97 2.4-.14-9.88 7.42-4.49z"
                />
                <path
                  fill="#fed0b5"
                  d="M370.53 405.72l.68 45.44-60.28-33.39-.68-45.44 60.28 33.39z"
                />
                <path
                  fill="#ffdcc5"
                  d="M420.59 375.44l-50.06 30.28-60.28-33.39 50.06-30.29 60.28 33.4z"
                />
                <path
                  fill="#fed0b5"
                  d="M401.09 387.26l-7.42 4.49-60.28-33.39 7.42-4.49 60.28 33.39z"
                />
              </g>
              <g fill="#616173">
                <path d="M330.32 416.88a5.11 5.11 0 01.13 1.06 3 3 0 01-1.47 2.89 1.61 1.61 0 00-.71 1.54l.71.39a.5.5 0 01.22.38c0 .15-.1.2-.22.13l-1.87-1a.5.5 0 01-.23-.39c0-.14.1-.19.23-.12l.7.39a4.84 4.84 0 00-.77-2.38c-1.3-2.1-1.8-4.06-1.49-5.5zM334.05 417.61l8.69 4.81.14 9.89-8.68-4.82zm8.41 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                <path d="M338.43 421.13a.32.32 0 01.16.26v.11a7.55 7.55 0 013.09 4.79 1.37 1.37 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.33 1.33 0 00-.54-.73.35.35 0 00-.53.13 1.38 1.38 0 00-.54-.73c-.16-.08-.28-.09-.37 0v3.38c0 .65-.47.93-1 .61a2.36 2.36 0 01-1.09-1.81v-.38c0-.1.07-.14.16-.09a.37.37 0 01.16.27v.38a1.62 1.62 0 00.76 1.26c.41.23.74 0 .74-.43v-3.37a1.05 1.05 0 00-.38-.4c-.24-.13-.43-.08-.53.13a1.37 1.37 0 00-.55-.73.34.34 0 00-.53.14 1.25 1.25 0 00-.54-.74.34.34 0 00-.53.14 1.91 1.91 0 013-1.35v-.11c-.02-.11.05-.15.14-.1zM320.23 417.78l.01.58-5.34-2.96-.01-.58 5.34 2.96zM320.22 416.65l.01.58-5.35-2.96v-.58l.82.46.69.38 2.31 1.28.68.38.84.46z" />
                <path d="M318.96 410.67l1.34 2.83-.96-.53.04 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.39z" />
                <path d="M313.1 406l8.69 4.81.14 9.88-8.68-4.81zm8.41 14l-.14-8.92-7.84-4.35.13 8.92 7.85 4.35" />
                <path d="M315.97 409l1.33 2.84-.96-.53.05 3.22-.69-.38-.04-3.22-.95-.53 1.26-1.4zM323.57 411.81l8.69 4.81.15 9.88-8.69-4.81zm8.41 14l-.13-8.92-7.85-4.37.14 8.93 7.84 4.34" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M489.18 413.7l.68 45.44-50.06 30.29-.68-45.44 50.06-30.29z"
                />
                <path
                  fill="#e2af98"
                  d="M469.68 425.53l.14 9.87-3.45 2.09-3.97 2.4-.15-9.87 7.43-4.49z"
                />
                <path
                  fill="#fed0b5"
                  d="M439.12 443.99l.68 45.44-60.28-33.4-.68-45.44 60.28 33.4z"
                />
                <path
                  fill="#ffdcc5"
                  d="M489.18 413.7l-50.06 30.29-60.28-33.4 50.06-30.29 60.28 33.4z"
                />
                <path
                  fill="#fed0b5"
                  d="M469.68 425.53l-7.43 4.49-60.27-33.4 7.42-4.49 60.28 33.4z"
                />
              </g>
              <g fill="#616173">
                <path d="M398.91 455.14a5.2 5.2 0 01.13 1.07 3 3 0 01-1.47 2.88 1.63 1.63 0 00-.71 1.55l.71.39a.48.48 0 01.22.38c0 .14-.1.19-.22.13l-1.87-1a.5.5 0 01-.23-.38c0-.15.1-.2.23-.13l.7.39a4.9 4.9 0 00-.77-2.38c-1.3-2.1-1.8-4.05-1.49-5.5zM402.64 455.87l8.69 4.82.14 9.88-8.68-4.81zm8.41 14l-.13-8.92-7.85-4.35.13 8.92 7.85 4.35" />
                <path d="M407 459.39a.34.34 0 01.16.26v.11a7.55 7.55 0 013.09 4.79 1.37 1.37 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.31 1.31 0 00-.54-.72c-.24-.13-.43-.08-.53.13a1.35 1.35 0 00-.54-.73c-.16-.09-.28-.1-.37 0v3.38c0 .66-.47.94-1.05.61a2.36 2.36 0 01-1.09-1.81v-.37c0-.11.07-.15.16-.1a.37.37 0 01.16.28v.37a1.66 1.66 0 00.76 1.27c.41.22.74 0 .74-.43v-3.38a1 1 0 00-.38-.39.34.34 0 00-.53.12 1.41 1.41 0 00-.55-.73.34.34 0 00-.53.14 1.25 1.25 0 00-.54-.74.34.34 0 00-.53.14 1.91 1.91 0 013-1.34v-.12c.05-.12.12-.16.19-.11zM388.82 456.04l.01.58-5.34-2.96-.01-.58 5.34 2.96zM388.81 454.92v.57l-5.33-2.95-.01-.58.83.46.68.37 2.31 1.29.68.37.84.47z" />
                <path d="M387.56 448.93l1.33 2.83-.96-.53.04 3.22-.68-.37-.04-3.23-.96-.52 1.27-1.4z" />
                <path d="M381.69 444.27l8.69 4.81.14 9.88-8.68-4.81zm8.41 14l-.14-8.92-7.84-4.35.13 8.93 7.85 4.34" />
                <path d="M384.56 447.27l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.52 1.26-1.4zM392.16 450.07l8.69 4.81.15 9.89-8.69-4.77zm8.41 14l-.13-8.93-7.85-4.34.14 8.92 7.84 4.35" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M557.77 451.96l.68 45.44-50.06 30.29-.68-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M538.27 463.79l.14 9.87-3.45 2.09-3.97 2.4-.14-9.87 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M507.71 482.25l.68 45.44-60.28-33.4-.68-45.43 60.28 33.39z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M557.77 451.96l-50.06 30.29-60.28-33.39 50.06-30.29 60.28 33.39z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M538.27 463.79l-7.42 4.49-60.28-33.4 7.42-4.49 60.28 33.4z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M467.5 493.41a5 5 0 01.13 1.06 3 3 0 01-1.47 2.88 1.64 1.64 0 00-.71 1.55l.71.39a.48.48 0 01.22.38c0 .14-.1.19-.22.13l-1.87-1a.48.48 0 01-.23-.38c0-.15.1-.2.23-.13l.7.39a4.9 4.9 0 00-.77-2.38c-1.3-2.09-1.8-4.05-1.49-5.5zM471.23 494.14l8.69 4.81.14 9.88-8.68-4.83zm8.41 14l-.13-8.92-7.85-4.35.13 8.93 7.85 4.34" />
                  <path d="M475.61 497.65a.34.34 0 01.16.27v.08a7.52 7.52 0 013.09 4.78 1.35 1.35 0 00-.54-.74.35.35 0 00-.54.12 1.33 1.33 0 00-.54-.73.35.35 0 00-.53.13 1.35 1.35 0 00-.54-.73c-.16-.09-.28-.09-.37 0v3.37c0 .66-.47.94-1.05.61a2.36 2.36 0 01-1.09-1.81v-.37c0-.1.07-.14.16-.09a.37.37 0 01.16.27v.37a1.64 1.64 0 00.76 1.27c.41.23.74 0 .74-.43v-3.38a1 1 0 00-.38-.39c-.24-.13-.43-.08-.53.12a1.34 1.34 0 00-.55-.72.34.34 0 00-.53.14 1.27 1.27 0 00-.54-.75.34.34 0 00-.53.15 1.91 1.91 0 013-1.35v-.12c.03-.08.1-.12.19-.07zM457.41 494.3l.01.58-5.34-2.96-.01-.57 5.34 2.95zM457.4 493.18l.01.58-5.35-2.96v-.58l.82.46.69.38 2.31 1.28.68.38.84.46z" />
                  <path d="M456.14 487.19l1.34 2.83-.96-.53.04 3.23-.68-.38-.04-3.22-.96-.53 1.26-1.4z" />
                  <path d="M450.28 482.53l8.69 4.81.14 9.89-8.68-4.82zm8.41 14l-.14-8.93-7.84-4.35.13 8.93 7.85 4.35" />
                  <path d="M453.15 485.53l1.33 2.83-.96-.53.05 3.23-.69-.38-.04-3.23-.95-.52 1.26-1.4zM460.75 488.33l8.69 4.82.15 9.88-8.69-4.81zm8.41 14l-.16-8.93-7.85-4.35.14 8.92 7.84 4.35" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M626.36 490.23l.68 45.43-50.06 30.29-.68-45.44 50.06-30.28z"
                  />
                  <path
                    fill="#e2af98"
                    d="M606.86 502.05l.14 9.88-3.45 2.09-3.97 2.4-.15-9.88 7.43-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M576.3 520.51l.68 45.44-60.28-33.39-.68-45.44 60.28 33.39z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M626.36 490.23l-50.06 30.28-60.28-33.39 50.06-30.29 60.28 33.4z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M606.86 502.05l-7.43 4.49-60.27-33.39 7.42-4.49 60.28 33.39z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M536.09 531.67a5.11 5.11 0 01.13 1.06 3 3 0 01-1.47 2.89 1.61 1.61 0 00-.71 1.54l.71.39a.5.5 0 01.22.38c0 .15-.1.2-.22.13l-1.87-1a.5.5 0 01-.23-.39c0-.14.1-.19.23-.12l.7.39a4.84 4.84 0 00-.77-2.38c-1.3-2.1-1.8-4.06-1.49-5.5zM539.82 532.4l8.69 4.81.14 9.89-8.65-4.82zm8.41 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                  <path d="M544.2 535.92a.32.32 0 01.16.26v.11a7.55 7.55 0 013.09 4.79 1.37 1.37 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.33 1.33 0 00-.54-.73.35.35 0 00-.53.13 1.38 1.38 0 00-.54-.73c-.16-.08-.28-.09-.37 0v3.38c0 .65-.47.93-1 .61a2.36 2.36 0 01-1.09-1.81v-.38c0-.1.07-.14.16-.09a.37.37 0 01.16.27v.38a1.65 1.65 0 00.76 1.26c.41.23.74 0 .74-.43v-3.37a1.05 1.05 0 00-.38-.4c-.24-.13-.43-.08-.53.13a1.37 1.37 0 00-.55-.73.34.34 0 00-.53.14 1.25 1.25 0 00-.54-.74.34.34 0 00-.53.14 1.91 1.91 0 013-1.35V536c-.06-.09.05-.14.14-.08zM526 532.57l.01.57-5.34-2.95-.01-.58 5.34 2.96zM525.99 531.44l.01.58-5.35-2.96v-.58l.83.46.68.38 2.31 1.28.68.38.84.46z" />
                  <path d="M524.74 525.46l1.33 2.83-.96-.53.04 3.22-.68-.38-.04-3.22-.96-.53 1.27-1.39z" />
                  <path d="M518.87 520.8l8.69 4.81.14 9.88-8.7-4.81zm8.41 14l-.14-8.92-7.84-4.35.13 8.92 7.85 4.35" />
                  <path d="M521.74 523.79l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4zM529.34 526.6l8.69 4.81.15 9.88-8.69-4.81zm8.41 14l-.13-8.92-7.85-4.35.14 8.93 7.84 4.34" />
                </g>
              </g>
            </g>
            <g id="ltlbackground__boxcollection3">
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M177.09 184.22l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                />
                <path
                  fill="#e2af98"
                  d="M157.58 196.05l.15 9.87-3.45 2.09-3.97 2.4-.15-9.87 7.42-4.49z"
                />
                <path
                  fill="#fed0b5"
                  d="M127.03 214.51l.67 45.44-60.28-33.4-.67-45.44 60.28 33.4z"
                />
                <path
                  fill="#ffdcc5"
                  d="M177.09 184.22l-50.06 30.29-60.28-33.4 50.06-30.28 60.28 33.39z"
                />
                <path
                  fill="#fed0b5"
                  d="M157.58 196.05l-7.42 4.49-60.28-33.39 7.43-4.5 60.27 33.4z"
                />
              </g>
              <g fill="#616173">
                <path d="M86.81 225.67a5.83 5.83 0 01.14 1.06 3 3 0 01-1.47 2.88 1.62 1.62 0 00-.71 1.55l.7.39a.47.47 0 01.23.38.14.14 0 01-.22.13l-1.87-1a.47.47 0 01-.24-.38c0-.15.11-.2.23-.13l.71.39a4.82 4.82 0 00-.78-2.38c-1.3-2.09-1.8-4.05-1.49-5.5zM90.55 226.4l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.92-7.82-4.37.13 8.93 7.87 4.34" />
                <path d="M94.93 229.91a.35.35 0 01.15.26v.12a7.51 7.51 0 013.08 4.78 1.26 1.26 0 00-.54-.74.34.34 0 00-.53.12 1.38 1.38 0 00-.54-.73.35.35 0 00-.53.13 1.31 1.31 0 00-.55-.73.32.32 0 00-.37 0v3.37c0 .66-.46.94-1 .61a2.32 2.32 0 01-1.1-1.83v-.37c0-.1.07-.15.16-.1a.35.35 0 01.15.28v.37a1.63 1.63 0 00.75 1.27c.41.23.74 0 .74-.43v-3.38a1 1 0 00-.37-.39c-.24-.13-.44-.08-.54.12a1.28 1.28 0 00-.54-.72.34.34 0 00-.53.14 1.32 1.32 0 00-.54-.75c-.24-.13-.44-.08-.53.15a1.91 1.91 0 013-1.35V230c.02-.1.09-.14.18-.09zM76.73 226.56l.01.58-5.34-2.96-.01-.58 5.34 2.96zM76.71 225.44l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                <path d="M75.46 219.45l1.33 2.83-.96-.53.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                <path d="M69.6 214.79l8.68 4.81.15 9.88-8.68-4.81zm8.4 14l-.13-8.93L70 215.5l.14 8.93 7.86 4.35" />
                <path d="M72.46 217.79l1.33 2.83-.96-.53.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4zM80.07 220.59l8.69 4.82.14 9.88-8.68-4.81zm8.41 14l-.13-8.92-7.85-4.35.13 8.92 7.85 4.35" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M245.68 222.49l.67 45.43-50.06 30.29-.67-45.44 50.06-30.28z"
                />
                <path
                  fill="#e2af98"
                  d="M226.17 234.31l.15 9.88-3.45 2.09-3.97 2.4-.15-9.88 7.42-4.49z"
                />
                <path
                  fill="#fed0b5"
                  d="M195.62 252.77l.67 45.44-60.28-33.39-.67-45.44 60.28 33.39z"
                />
                <path
                  fill="#ffdcc5"
                  d="M245.68 222.49l-50.06 30.28-60.28-33.39 50.06-30.29 60.28 33.4z"
                />
                <path
                  fill="#fed0b5"
                  d="M226.17 234.31l-7.42 4.49-60.28-33.39 7.43-4.49 60.27 33.39z"
                />
              </g>
              <g fill="#616173">
                <path d="M155.4 263.93a5.94 5.94 0 01.14 1.06 3 3 0 01-1.47 2.89 1.59 1.59 0 00-.71 1.54l.7.39a.48.48 0 01.23.38c0 .15-.11.2-.22.13l-1.87-1a.5.5 0 01-.24-.39c0-.15.11-.19.23-.12l.71.39a4.76 4.76 0 00-.78-2.38c-1.3-2.1-1.8-4.06-1.49-5.5zM159.14 264.66l8.68 4.81.15 9.89-8.69-4.82zm8.4 14l-.13-8.93-7.84-4.35.13 8.93 7.84 4.35" />
                <path d="M163.52 268.18a.32.32 0 01.15.26v.11a7.54 7.54 0 013.08 4.79 1.28 1.28 0 00-.54-.75c-.24-.13-.43-.08-.53.12a1.33 1.33 0 00-.54-.73.35.35 0 00-.53.13 1.35 1.35 0 00-.55-.73.34.34 0 00-.37 0v3.38c0 .65-.46.93-1 .61a2.34 2.34 0 01-1.08-1.82v-.37c0-.1.07-.14.16-.09a.33.33 0 01.15.27v.38a1.61 1.61 0 00.75 1.26c.41.23.74 0 .74-.43v-3.37a1 1 0 00-.37-.4c-.24-.13-.44-.08-.54.13a1.31 1.31 0 00-.54-.73.34.34 0 00-.53.14 1.29 1.29 0 00-.54-.74c-.24-.13-.44-.08-.53.14a1.91 1.91 0 013-1.35v-.11a.1.1 0 01.16-.1zM145.32 264.83l.01.57-5.34-2.95-.01-.58 5.34 2.96zM145.3 263.7l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                <path d="M144.05 257.71l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                <path d="M138.19 253.06l8.68 4.81.15 9.88-8.68-4.81zm8.4 14l-.13-8.93-7.85-4.34.14 8.92 7.84 4.35" />
                <path d="M141.05 256.05l1.33 2.84-.95-.53.04 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4zM148.66 258.86l8.69 4.81.14 9.88-8.68-4.81zm8.41 14l-.13-8.92-7.85-4.35.13 8.93 7.85 4.34" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M314.27 260.75l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M294.76 272.58l.15 9.87-3.46 2.09-3.96 2.4-.15-9.87 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M264.21 291.04l.67 45.44-60.28-33.4-.67-45.44 60.28 33.4z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M314.27 260.75l-50.06 30.29-60.28-33.4 50.06-30.29 60.28 33.4z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M294.76 272.58l-7.42 4.49-60.28-33.4 7.43-4.49 60.27 33.4z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M224 302.19a6 6 0 01.14 1.07 3 3 0 01-1.47 2.88 1.61 1.61 0 00-.71 1.55l.7.39a.47.47 0 01.23.38c0 .14-.1.19-.22.12l-1.87-1a.48.48 0 01-.24-.38c0-.15.11-.2.23-.13l.71.39a4.82 4.82 0 00-.78-2.38c-1.3-2.1-1.8-4.06-1.49-5.5zM227.73 302.92l8.68 4.82.15 9.88-8.69-4.81zm8.4 14L236 308l-7.84-4.35.13 8.92 7.84 4.35" />
                  <path d="M232.11 306.44a.35.35 0 01.15.26v.11a7.54 7.54 0 013.08 4.79 1.28 1.28 0 00-.54-.75c-.24-.13-.43-.08-.53.12a1.31 1.31 0 00-.54-.72c-.24-.13-.43-.08-.53.13a1.31 1.31 0 00-.55-.73.32.32 0 00-.37 0v3.38c0 .66-.46.94-1 .61a2.32 2.32 0 01-1.08-1.81v-.37c0-.11.07-.15.16-.1a.33.33 0 01.15.28v.36a1.65 1.65 0 00.75 1.27c.41.22.74 0 .74-.43v-3.38a.93.93 0 00-.37-.39.35.35 0 00-.54.12 1.35 1.35 0 00-.54-.73.34.34 0 00-.53.14 1.29 1.29 0 00-.54-.74c-.24-.13-.44-.08-.53.14a1.91 1.91 0 013-1.34v-.12c.05-.11.05-.15.16-.1zM213.91 303.09l.01.58-5.34-2.96-.01-.58 5.34 2.96zM213.89 301.96l.01.58-5.34-2.96-.01-.57.83.45.68.38 2.32 1.29.68.37.83.46z" />
                  <path d="M212.64 295.98l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.52 1.26-1.4z" />
                  <path d="M206.78 291.32l8.68 4.81.15 9.88-8.68-4.81zm8.4 14l-.13-8.92-7.85-4.4.14 8.93 7.84 4.34" />
                  <path d="M209.64 294.32l1.33 2.83-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.39zM217.25 297.12l8.69 4.81.14 9.88-8.68-4.81zm8.41 14l-.13-8.93-7.85-4.35.13 8.93 7.85 4.35" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M382.86 299.01l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M363.35 310.84l.15 9.87-3.45 2.09-3.97 2.4-.15-9.87 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M332.8 329.3l.67 45.44-60.28-33.4-.67-45.44 60.28 33.4z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M382.86 299.01L332.8 329.3l-60.28-33.4 50.06-30.28 60.28 33.39z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M363.35 310.84l-7.42 4.49-60.28-33.4 7.42-4.49 60.28 33.4z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M292.58 340.46a5.83 5.83 0 01.14 1.06 3 3 0 01-1.47 2.88 1.62 1.62 0 00-.71 1.55l.7.39a.47.47 0 01.23.38c0 .14-.1.19-.22.13l-1.87-1a.47.47 0 01-.24-.38c0-.15.11-.2.23-.13l.71.39a4.82 4.82 0 00-.78-2.38c-1.3-2.09-1.79-4.05-1.49-5.5zM296.32 341.19L305 346l.15 9.88-8.69-4.81zm8.4 14l-.13-8.92-7.84-4.35.13 8.93 7.84 4.34" />
                  <path d="M300.7 344.7a.35.35 0 01.15.26v.12a7.51 7.51 0 013.08 4.78 1.23 1.23 0 00-.54-.74.34.34 0 00-.53.12 1.33 1.33 0 00-.54-.73.35.35 0 00-.53.13 1.31 1.31 0 00-.55-.73.32.32 0 00-.37 0v3.37c0 .66-.46.94-1.05.61a2.32 2.32 0 01-1.08-1.81v-.37c0-.1.07-.15.16-.1a.33.33 0 01.15.28v.37a1.63 1.63 0 00.75 1.27c.41.23.74 0 .74-.43v-3.38a1 1 0 00-.37-.39c-.24-.13-.44-.08-.54.12a1.28 1.28 0 00-.54-.72.34.34 0 00-.53.14 1.32 1.32 0 00-.54-.75c-.24-.13-.44-.08-.53.15a1.91 1.91 0 013-1.35v-.12c.05-.11.12-.15.21-.1zM282.5 341.35l.01.58-5.34-2.96-.01-.58 5.34 2.96zM282.48 340.23l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                  <path d="M281.23 334.24l1.33 2.83-.96-.53.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                  <path d="M275.37 329.58l8.68 4.81.15 9.88-8.68-4.81zm8.4 14l-.13-8.93-7.85-4.35.14 8.93 7.84 4.35" />
                  <path d="M278.23 332.58l1.33 2.83-.95-.53.04 3.23-.68-.38-.05-3.23-.95-.52 1.26-1.4zM285.84 335.38l8.69 4.82.14 9.88-8.67-4.81zm8.41 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                </g>
              </g>
            </g>
            <g id="ltlbackground__boxcollection2">
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M450.59 337.96l.67 45.44-50.06 30.29-.68-45.44 50.07-30.29z"
                />
                <path
                  fill="#e2af98"
                  d="M431.08 349.79l.15 9.87-3.46 2.09-3.97 2.4-.14-9.87 7.42-4.49z"
                />
                <path
                  fill="#fed0b5"
                  d="M400.52 368.25l.68 45.44-60.28-33.39-.67-45.44 60.27 33.39z"
                />
                <path
                  fill="#ffdcc5"
                  d="M450.59 337.96l-50.07 30.29-60.27-33.39 50.06-30.29 60.28 33.39z"
                />
                <path
                  fill="#fed0b5"
                  d="M431.08 349.79l-7.42 4.49-60.28-33.39 7.42-4.5 60.28 33.4z"
                />
              </g>
              <g fill="#616173">
                <path d="M360.31 379.41a5 5 0 01.13 1.06 3 3 0 01-1.47 2.88 1.64 1.64 0 00-.71 1.55l.71.39a.48.48 0 01.22.38c0 .14-.1.19-.21.13l-1.87-1a.47.47 0 01-.24-.38c0-.15.1-.2.23-.13l.7.4a4.91 4.91 0 00-.77-2.39c-1.3-2.09-1.8-4.05-1.49-5.5zM364 380.14l8.69 4.81.15 9.88-8.65-4.83zm8.41 14l-.13-8.93-7.85-4.35.13 8.93 7.85 4.35" />
                <path d="M368.43 383.65a.36.36 0 01.15.27v.08a7.52 7.52 0 013.09 4.78 1.34 1.34 0 00-.54-.74.35.35 0 00-.54.12 1.33 1.33 0 00-.54-.73.34.34 0 00-.52.13 1.31 1.31 0 00-.55-.73c-.16-.09-.28-.09-.37 0v3.37c0 .66-.46.94-1.05.62a2.35 2.35 0 01-1.08-1.82v-.37c0-.1.07-.14.17-.09a.36.36 0 01.15.27v.37a1.63 1.63 0 00.75 1.27c.41.23.74 0 .74-.43v-3.37a1 1 0 00-.38-.4c-.24-.13-.43-.08-.53.12a1.28 1.28 0 00-.54-.72c-.24-.13-.43-.08-.53.14a1.36 1.36 0 00-.54-.75c-.24-.13-.44-.08-.54.15a1.91 1.91 0 013-1.35v-.12c.03-.08.1-.12.2-.07zM350.23 380.3l.01.58-5.35-2.96v-.57l5.34 2.95zM350.21 379.18l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                <path d="M348.96 373.19l1.33 2.84-.96-.54.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                <path d="M343.1 368.53l8.68 4.81.15 9.89-8.69-4.81zm8.4 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                <path d="M345.96 371.53l1.33 2.84-.96-.54.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4zM353.57 374.33l8.68 4.82.15 9.88-8.69-4.81zm8.41 14l-.14-8.92-7.84-4.36.13 8.92 7.85 4.35" />
              </g>
              <g
                style={{
                  isolation: "isolate",
                }}
              >
                <path
                  fill="#f2c0a7"
                  d="M519.18 376.23l.67 45.44-50.06 30.28-.67-45.44 50.06-30.28z"
                />
                <path
                  fill="#e2af98"
                  d="M499.67 388.05l.15 9.88-3.46 2.09-3.97 2.4-.14-9.88 7.42-4.49z"
                />
                <path
                  fill="#fed0b5"
                  d="M469.12 406.51l.67 45.44-60.28-33.39-.67-45.44 60.28 33.39z"
                />
                <path
                  fill="#ffdcc5"
                  d="M519.18 376.23l-50.06 30.28-60.28-33.39 50.06-30.29 60.28 33.4z"
                />
                <path
                  fill="#fed0b5"
                  d="M499.67 388.05l-7.42 4.49-60.28-33.39 7.42-4.49 60.28 33.39z"
                />
              </g>
              <g fill="#616173">
                <path d="M428.9 417.67a5.11 5.11 0 01.13 1.06 3 3 0 01-1.47 2.89 1.61 1.61 0 00-.71 1.54l.71.39a.5.5 0 01.22.38c0 .15-.1.2-.21.13l-1.87-1a.48.48 0 01-.24-.38c0-.15.1-.2.23-.13l.7.39a4.84 4.84 0 00-.77-2.38c-1.3-2.1-1.8-4.06-1.49-5.5zM432.63 418.4l8.69 4.81.15 9.89-8.69-4.82zm8.41 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                <path d="M437 421.92a.34.34 0 01.15.26v.11a7.55 7.55 0 013.09 4.79 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.27 1.27 0 00-.54-.72.33.33 0 00-.52.12 1.35 1.35 0 00-.55-.73c-.16-.08-.28-.09-.37 0v3.38c0 .65-.46.93-1.05.61a2.32 2.32 0 01-1.08-1.81v-.38c0-.1.07-.14.17-.09a.36.36 0 01.15.27v.38a1.61 1.61 0 00.75 1.26c.41.23.74 0 .74-.43v-3.37a1.05 1.05 0 00-.38-.4.35.35 0 00-.53.13 1.31 1.31 0 00-.54-.73c-.24-.13-.43-.08-.53.14a1.34 1.34 0 00-.54-.74c-.24-.13-.44-.08-.54.14a1.91 1.91 0 013-1.35V422c.05-.09.12-.13.2-.08zM418.82 418.57v.58l-5.33-2.96-.01-.58 5.34 2.96zM418.8 417.44l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                <path d="M417.55 411.45l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                <path d="M411.69 406.8l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.92-7.85-4.35.13 8.92 7.85 4.35" />
                <path d="M414.55 409.79l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4zM422.16 412.6l8.68 4.81.15 9.88-8.69-4.81zm8.41 14l-.14-8.92-7.84-4.35.13 8.93 7.85 4.34" />
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M587.77 414.49l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M568.26 426.32l.15 9.87-3.46 2.09-3.96 2.4-.15-9.87 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M537.71 444.78l.67 45.44-60.28-33.4-.67-45.44 60.28 33.4z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M587.77 414.49l-50.06 30.29-60.28-33.4 50.06-30.29 60.28 33.4z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M568.26 426.32l-7.42 4.49-60.28-33.4 7.42-4.49 60.28 33.4z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M497.49 455.93a5.2 5.2 0 01.13 1.07 3 3 0 01-1.47 2.88 1.63 1.63 0 00-.71 1.55l.71.39a.48.48 0 01.22.38c0 .14-.1.19-.21.13l-1.87-1a.48.48 0 01-.24-.38c0-.15.1-.2.23-.13l.7.39a4.9 4.9 0 00-.77-2.38c-1.3-2.09-1.8-4.05-1.49-5.5zM501.22 456.66l8.69 4.82.15 9.88-8.69-4.81zm8.41 14l-.13-8.92-7.85-4.35.13 8.92 7.85 4.35" />
                  <path d="M505.61 460.18a.35.35 0 01.15.26v.11a7.55 7.55 0 013.09 4.79 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.31 1.31 0 00-.54-.72.33.33 0 00-.52.13 1.31 1.31 0 00-.55-.73c-.16-.09-.28-.1-.37 0v3.38c0 .66-.46.94-1.05.61a2.32 2.32 0 01-1.08-1.81v-.37a.11.11 0 01.17-.1.36.36 0 01.15.28v.37a1.65 1.65 0 00.75 1.27c.41.22.74 0 .74-.43v-3.38a1 1 0 00-.38-.39c-.24-.13-.43-.08-.53.12a1.35 1.35 0 00-.54-.73.34.34 0 00-.53.14 1.37 1.37 0 00-.54-.74c-.24-.13-.44-.08-.54.14a1.91 1.91 0 013-1.34v-.12c.03-.12.1-.16.2-.11zM487.41 456.83l.01.58-5.35-2.96v-.58l5.34 2.96zM487.39 455.71l.01.57-5.34-2.95-.01-.58.83.46.68.37 2.32 1.29.68.38.83.46z" />
                  <path d="M486.14 449.72l1.33 2.83-.96-.53.05 3.23-.68-.38-.05-3.23-.95-.52 1.26-1.4z" />
                  <path d="M480.28 445.06l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.92-7.85-4.35.13 8.93 7.85 4.34" />
                  <path d="M483.14 448.06l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.53 1.26-1.39zM490.75 450.86l8.68 4.81.15 9.89-8.69-4.82zm8.41 14l-.14-8.93-7.84-4.34.13 8.92 7.85 4.35" />
                </g>
              </g>
              <g>
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M656.36 452.75l.67 45.44-50.06 30.29-.68-45.44 50.07-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M636.85 464.58l.15 9.87-3.46 2.09-3.96 2.4-.15-9.87 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M606.29 483.04l.68 45.44-60.28-33.4-.67-45.44 60.27 33.4z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M656.36 452.75l-50.07 30.29-60.27-33.4 50.06-30.28 60.28 33.39z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M636.85 464.58l-7.42 4.49-60.28-33.39 7.42-4.49 60.28 33.39z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M566.08 494.2a5 5 0 01.13 1.06 3 3 0 01-1.47 2.88 1.64 1.64 0 00-.71 1.55l.71.39a.48.48 0 01.22.38c0 .14-.1.19-.21.13l-1.87-1a.47.47 0 01-.24-.38c0-.15.1-.2.23-.13l.7.4a4.91 4.91 0 00-.77-2.39c-1.3-2.09-1.8-4.05-1.49-5.5zM569.81 494.93l8.69 4.81.15 9.88-8.65-4.81zm8.41 14l-.13-8.93-7.85-4.35.14 8.93 7.84 4.35" />
                  <path d="M574.2 498.44a.36.36 0 01.15.27v.11a7.52 7.52 0 013.09 4.78 1.34 1.34 0 00-.54-.74.35.35 0 00-.54.12 1.33 1.33 0 00-.54-.73.34.34 0 00-.52.13 1.31 1.31 0 00-.55-.73c-.16-.09-.28-.09-.37 0v3.37c0 .66-.46.94-1 .62a2.35 2.35 0 01-1.08-1.82v-.37c0-.1.08-.14.17-.09a.36.36 0 01.15.27v.37a1.63 1.63 0 00.75 1.27c.41.23.74 0 .74-.43v-3.38a1 1 0 00-.38-.39c-.24-.13-.43-.08-.53.12a1.28 1.28 0 00-.54-.72c-.24-.13-.43-.08-.53.14a1.36 1.36 0 00-.54-.75c-.24-.13-.44-.08-.54.15a1.91 1.91 0 013-1.35v-.12c-.05-.11.05-.15.15-.1zM556 495.09l.01.58-5.35-2.96v-.58l5.34 2.96zM555.98 493.97l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                  <path d="M554.73 487.98l1.33 2.84-.96-.54.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                  <path d="M548.87 483.32l8.68 4.81.15 9.89-8.7-4.82zm8.4 14l-.13-8.93-7.85-4.39.13 8.92 7.85 4.35" />
                  <path d="M551.73 486.32l1.33 2.83-.96-.53.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4zM559.34 489.12l8.68 4.82.15 9.88-8.69-4.82zm8.41 14l-.14-8.92-7.84-4.35.13 8.92 7.85 4.35" />
                </g>
              </g>
            </g>
            <motion.g 
            
            
            
            >
              <motion.g id="ltlbackground__box1"
              
              
              
              
              >
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M210.85 137.25l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M191.34 149.08l.15 9.87-3.46 2.09-3.96 2.4-.15-9.87 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M160.79 167.54l.67 45.44-60.28-33.39-.67-45.44 60.28 33.39z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M210.85 137.25l-50.06 30.29-60.28-33.39 50.06-30.29 60.28 33.39z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M191.34 149.08l-7.42 4.49-60.28-33.39 7.42-4.49 60.28 33.39z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M120.57 178.7a5.94 5.94 0 01.14 1.06 3 3 0 01-1.47 2.88 1.62 1.62 0 00-.71 1.55l.7.39a.47.47 0 01.23.38c0 .15-.1.19-.22.13l-1.87-1a.47.47 0 01-.24-.38c0-.15.11-.2.23-.12l.71.39a4.82 4.82 0 00-.78-2.39c-1.3-2.09-1.79-4.05-1.49-5.49zM124.31 179.43l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.93-7.84-4.35.13 8.93 7.84 4.35" />
                  <path d="M128.69 183a.36.36 0 01.16.26v.11a7.51 7.51 0 013.08 4.78 1.29 1.29 0 00-.54-.74c-.24-.13-.43-.08-.53.12a1.33 1.33 0 00-.54-.73.35.35 0 00-.53.13 1.31 1.31 0 00-.55-.73.34.34 0 00-.37 0v3.37c0 .66-.46.94-1.05.62a2.35 2.35 0 01-1.08-1.82V188c0-.1.07-.14.16-.09a.33.33 0 01.15.27v.37a1.63 1.63 0 00.75 1.27c.41.23.74 0 .74-.43v-3.37a1 1 0 00-.37-.4c-.24-.13-.44-.08-.53.12a1.34 1.34 0 00-.55-.72.34.34 0 00-.53.14 1.26 1.26 0 00-.54-.74c-.24-.14-.44-.08-.53.14a1.91 1.91 0 013-1.35V183c.04-.07.11-.11.2 0zM110.49 179.59l.01.58-5.34-2.96-.01-.57 5.34 2.95zM110.47 178.47l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                  <path d="M109.22 172.48l1.33 2.84-.96-.54.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                  <path d="M103.36 167.82l8.68 4.81.15 9.89-8.68-4.81zm8.4 14l-.13-8.93-7.84-4.34.13 8.92 7.84 4.35" />
                  <path d="M106.22 170.82l1.34 2.84-.97-.54.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4zM113.83 173.63l8.69 4.81.14 9.88-8.66-4.81zm8.41 14l-.13-8.92-7.85-4.35.13 8.93 7.85 4.34" />
                </g>
              </motion.g>
              <motion.g id="ltlbackground__box2"
              
              
              
              
              >
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M279.44 175.52l.67 45.44-50.06 30.28-.67-45.43 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M259.93 187.34l.15 9.88-3.45 2.09-3.97 2.4-.15-9.87 7.42-4.5z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M229.38 205.81l.67 45.43-60.28-33.39-.67-45.44 60.28 33.4z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M279.44 175.52l-50.06 30.29-60.28-33.4 50.06-30.29 60.28 33.4z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M259.93 187.34l-7.42 4.5-60.28-33.4 7.43-4.49 60.27 33.39z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M189.16 217a5.93 5.93 0 01.14 1.07 3 3 0 01-1.47 2.88 1.6 1.6 0 00-.71 1.54l.7.39a.48.48 0 01.23.38c0 .15-.1.2-.22.13l-1.87-1a.48.48 0 01-.24-.38c0-.15.11-.2.23-.13l.71.39a4.76 4.76 0 00-.78-2.38c-1.3-2.1-1.79-4.06-1.49-5.5zM192.9 217.69l8.68 4.81.15 9.89-8.73-4.82zm8.4 14l-.13-8.93-7.84-4.34.13 8.92 7.84 4.35" />
                  <path d="M197.28 221.21a.38.38 0 01.16.26v.11a7.54 7.54 0 013.08 4.79 1.28 1.28 0 00-.54-.75c-.24-.13-.43-.08-.53.12a1.27 1.27 0 00-.54-.72.34.34 0 00-.53.12 1.29 1.29 0 00-.55-.72.32.32 0 00-.37 0v3.38c0 .65-.46.93-1.05.61a2.32 2.32 0 01-1.08-1.81v-.37c0-.11.07-.15.16-.1a.33.33 0 01.15.28v.37a1.61 1.61 0 00.75 1.26c.41.23.74 0 .74-.43v-3.37a.93.93 0 00-.37-.39c-.24-.14-.44-.08-.53.12a1.37 1.37 0 00-.55-.73.34.34 0 00-.53.14 1.29 1.29 0 00-.54-.74c-.24-.13-.44-.08-.53.14a1.91 1.91 0 013-1.35v-.11c.04-.12.11-.16.2-.11zM179.08 217.86l.01.58-5.34-2.96-.01-.58 5.34 2.96zM179.06 216.73l.01.58-5.34-2.96-.01-.57.83.45.68.38 2.32 1.29.68.37.83.46z" />
                  <path d="M177.81 210.75l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.53 1.26-1.39z" />
                  <path d="M172 206.09l8.68 4.81.15 9.88-8.73-4.78zm8.4 14l-.13-8.92-7.84-4.35.13 8.92 7.84 4.35" />
                  <path d="M174.81 209.09l1.34 2.83-.96-.53.04 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.39zM182.42 211.89l8.69 4.81.14 9.88-8.68-4.81zm8.41 14l-.13-8.89-7.85-4.35.13 8.93 7.85 4.35" />
                </g>
              </motion.g>
              <motion.g id="ltlbackground__box3"
              
              
              
              
              >
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M348.03 213.78l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M328.52 225.61l.15 9.87-3.45 2.09-3.97 2.4-.15-9.87 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M297.97 244.07l.67 45.44-60.28-33.4-.67-45.44 60.28 33.4z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M348.03 213.78l-50.06 30.29-60.28-33.4 50.06-30.29 60.28 33.4z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M328.52 225.61l-7.42 4.49-60.28-33.4 7.43-4.49 60.27 33.4z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M257.75 255.22a6 6 0 01.14 1.07 3 3 0 01-1.47 2.88 1.62 1.62 0 00-.71 1.55l.7.39a.47.47 0 01.23.38c0 .14-.1.19-.22.13l-1.87-1a.48.48 0 01-.24-.38c0-.15.11-.2.23-.13l.71.39a4.82 4.82 0 00-.78-2.38c-1.3-2.09-1.79-4-1.49-5.5zM261.49 256l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.92-7.84-4.35.13 8.93 7.84 4.34" />
                  <path d="M265.87 259.47a.4.4 0 01.16.26v.11a7.54 7.54 0 013.08 4.79 1.28 1.28 0 00-.54-.75c-.24-.13-.43-.08-.53.12a1.35 1.35 0 00-.54-.72c-.24-.13-.43-.08-.53.13a1.31 1.31 0 00-.55-.73.34.34 0 00-.37 0v3.38c0 .66-.46.94-1.05.61a2.32 2.32 0 01-1.08-1.81v-.37c0-.1.07-.15.16-.1a.33.33 0 01.15.28v.33a1.65 1.65 0 00.75 1.27c.41.22.75 0 .74-.43v-3.38a1 1 0 00-.37-.39c-.24-.13-.44-.08-.53.12a1.41 1.41 0 00-.55-.73.34.34 0 00-.53.14 1.32 1.32 0 00-.54-.74c-.24-.13-.44-.08-.53.14a1.91 1.91 0 013-1.34v-.12c.04-.08.11-.12.2-.07zM247.67 256.12l.01.58-5.34-2.96-.01-.58 5.34 2.96zM247.65 255l.01.57-5.34-2.95-.01-.58.83.46.68.37 2.32 1.29.68.37.83.47z" />
                  <path d="M246.4 249.01l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.52 1.26-1.4z" />
                  <path d="M240.54 244.35l8.68 4.81.15 9.88-8.68-4.81zm8.4 14l-.13-8.93-7.81-4.36.13 8.93 7.84 4.35" />
                  <path d="M243.4 247.35l1.34 2.83-.96-.53.04 3.22-.68-.37-.05-3.23-.95-.52 1.26-1.4zM251 250.15l8.7 4.85.14 9.89-8.68-4.89zm8.41 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                </g>
              </motion.g>
              <motion.g id="ltlbackground__box4"
              
              
              
              
              >
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M416.62 252.04l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M397.11 263.87l.15 9.87-3.45 2.09-3.97 2.41-.15-9.88 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M366.56 282.33l.67 45.44-60.28-33.39-.67-45.44 60.28 33.39z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M416.62 252.04l-50.06 30.29-60.28-33.39 50.06-30.29 60.28 33.39z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M397.11 263.87l-7.42 4.49-60.28-33.39 7.43-4.5 60.27 33.4z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M326.34 293.49a5.94 5.94 0 01.14 1.06 3 3 0 01-1.47 2.88 1.62 1.62 0 00-.71 1.55l.7.39a.47.47 0 01.23.38c0 .15-.1.19-.22.13l-1.87-1a.49.49 0 01-.24-.38c0-.15.11-.2.23-.13l.71.4a4.82 4.82 0 00-.78-2.39c-1.3-2.09-1.79-4-1.49-5.5zM330.08 294.22l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.93-7.84-4.35.13 8.93 7.84 4.35" />
                  <path d="M334.46 297.73a.4.4 0 01.16.27v.11a7.51 7.51 0 013.08 4.78 1.29 1.29 0 00-.54-.74c-.24-.13-.43-.08-.53.12a1.33 1.33 0 00-.54-.73.35.35 0 00-.53.13 1.31 1.31 0 00-.55-.73.32.32 0 00-.37 0v3.37c0 .66-.46.94-1.05.62a2.35 2.35 0 01-1.08-1.82v-.37c0-.1.07-.14.16-.09a.33.33 0 01.15.27v.37a1.63 1.63 0 00.75 1.27c.41.23.75 0 .74-.43v-3.37a1 1 0 00-.37-.4c-.24-.13-.44-.08-.53.12a1.34 1.34 0 00-.55-.72.34.34 0 00-.53.14 1.32 1.32 0 00-.54-.75c-.24-.13-.44-.07-.53.15a1.91 1.91 0 013-1.35v-.12c.04-.11.11-.15.2-.1zM316.26 294.38l.01.58-5.34-2.96-.01-.57 5.34 2.95zM316.24 293.26l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                  <path d="M314.99 287.27l1.33 2.84-.96-.54.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                  <path d="M309.13 282.61l8.68 4.81.15 9.89-8.68-4.81zm8.4 14l-.13-8.93-7.84-4.34.13 8.92 7.84 4.35" />
                  <path d="M311.99 285.61l1.33 2.83-.95-.53.04 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4zM319.6 288.41l8.69 4.82.14 9.88-8.68-4.81zm8.41 14l-.13-8.92-7.88-4.36.13 8.92 7.87 4.35" />
                </g>
              </motion.g>
              <motion.g id="ltlbackground__box5"
              
              
              
              
              >
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M484.35 290.99l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M464.84 302.82l.15 9.87-3.46 2.1-3.97 2.4-.14-9.88 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M434.29 321.28l.67 45.44-60.28-33.39-.67-45.44 60.28 33.39z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M484.35 290.99l-50.06 30.29-60.28-33.39 50.06-30.29 60.28 33.39z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M464.84 302.82l-7.42 4.49-60.28-33.39 7.42-4.49 60.28 33.39z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M394.07 332.44a5.11 5.11 0 01.13 1.06 3 3 0 01-1.47 2.89 1.61 1.61 0 00-.71 1.54l.71.39a.54.54 0 01.23.38c0 .15-.11.19-.22.13l-1.87-1a.47.47 0 01-.24-.38c0-.15.1-.19.23-.12l.7.39a4.91 4.91 0 00-.77-2.39c-1.3-2.09-1.8-4.05-1.49-5.49zM397.8 333.17l8.69 4.81.15 9.88-8.64-4.81zm8.41 14l-.13-8.93-7.85-4.35.14 8.93 7.84 4.35" />
                  <path d="M402.19 336.69a.32.32 0 01.15.26v.11a7.55 7.55 0 013.09 4.79 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.33 1.33 0 00-.54-.73.34.34 0 00-.52.13 1.35 1.35 0 00-.55-.73c-.16-.08-.28-.09-.37 0v3.37c0 .66-.46.94-1 .62a2.34 2.34 0 01-1.08-1.82v-.37c0-.1.08-.14.17-.09a.36.36 0 01.15.27v.38a1.61 1.61 0 00.75 1.26c.41.23.74 0 .74-.43v-3.37a1.05 1.05 0 00-.38-.4c-.24-.13-.43-.08-.53.13a1.31 1.31 0 00-.54-.73c-.24-.13-.43-.08-.53.14a1.3 1.3 0 00-.54-.74.34.34 0 00-.54.14 1.91 1.91 0 013-1.35v-.11c-.04-.12.05-.16.15-.1zM383.99 333.33l.01.58-5.34-2.96-.01-.57 5.34 2.95zM383.97 332.21l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                  <path d="M382.72 326.22l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                  <path d="M376.86 321.56l8.68 4.82.15 9.88-8.69-4.81zm8.4 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                  <path d="M379.72 324.56l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4zM387.33 327.37l8.68 4.81.15 9.88-8.69-4.81zm8.41 14l-.14-8.92-7.84-4.35.13 8.93 7.85 4.34" />
                </g>
              </motion.g>
              <motion.g id="ltlbackground__box6"
              
              
              
              
              >
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M552.94 329.26l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M533.43 341.08l.15 9.88-3.46 2.09-3.97 2.4-.14-9.87 7.42-4.5z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M502.88 359.55l.67 45.44-60.28-33.4-.67-45.44 60.28 33.4z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M552.94 329.26l-50.06 30.29-60.28-33.4 50.06-30.29 60.28 33.4z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M533.43 341.08l-7.42 4.5-60.28-33.4 7.42-4.49 60.28 33.39z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M462.66 370.7a5.2 5.2 0 01.13 1.07 3 3 0 01-1.47 2.88 1.62 1.62 0 00-.71 1.54l.71.4a.52.52 0 01.23.38.13.13 0 01-.22.12l-1.87-1a.48.48 0 01-.24-.38c0-.15.1-.2.23-.13l.71.39a5 5 0 00-.78-2.38c-1.3-2.1-1.8-4.06-1.49-5.5zM466.39 371.43l8.69 4.82.15 9.88-8.69-4.82zm8.41 14l-.13-8.93-7.85-4.34.14 8.92 7.84 4.35" />
                  <path d="M470.78 375a.34.34 0 01.15.26v.11a7.55 7.55 0 013.09 4.79 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.31 1.31 0 00-.54-.72.33.33 0 00-.52.13 1.31 1.31 0 00-.55-.73c-.16-.09-.28-.1-.37 0v3.38c0 .66-.46.94-1.05.61a2.32 2.32 0 01-1.08-1.81v-.37c0-.11.08-.15.17-.1a.36.36 0 01.15.28v.37a1.61 1.61 0 00.75 1.26c.41.23.74 0 .74-.42v-3.38a1 1 0 00-.38-.39.34.34 0 00-.53.12 1.35 1.35 0 00-.54-.73c-.24-.13-.43-.08-.53.14a1.29 1.29 0 00-.54-.74c-.24-.13-.44-.08-.54.14a1.91 1.91 0 013-1.34V375c.03-.06.1-.1.2 0zM452.58 371.6l.01.58-5.34-2.96-.01-.58 5.34 2.96zM452.56 370.47l.01.58-5.34-2.96-.01-.57.83.46.68.37 2.32 1.29.68.37.83.46z" />
                  <path d="M451.31 364.49l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.53 1.26-1.39z" />
                  <path d="M445.45 359.83l8.68 4.81.15 9.88-8.69-4.81zm8.4 14l-.13-8.92-7.85-4.35.13 8.93 7.85 4.34" />
                  <path d="M448.31 362.83l1.33 2.83-.96-.53.05 3.22-.68-.37-.05-3.23-.95-.53 1.26-1.39zM455.92 365.63l8.68 4.81.15 9.88-8.69-4.81zm8.41 14l-.14-8.93-7.84-4.35.13 8.93 7.85 4.35" />
                </g>
              </motion.g>
              <motion.g id="ltlbackground__box7"
              
              
              
              
              >
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M621.53 367.52l.67 45.44-50.06 30.29-.67-45.44 50.06-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M602.02 379.35l.15 9.87-3.46 2.09-3.96 2.4-.15-9.87 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M571.47 397.81l.67 45.44-60.28-33.4-.67-45.44 60.28 33.4z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M621.53 367.52l-50.06 30.29-60.28-33.4 50.06-30.28 60.28 33.39z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M602.02 379.35l-7.42 4.49-60.28-33.4 7.42-4.49 60.28 33.4z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M531.25 409a5 5 0 01.13 1.06 3 3 0 01-1.47 2.88 1.64 1.64 0 00-.71 1.55l.71.39a.52.52 0 01.23.38.14.14 0 01-.22.13l-1.87-1a.48.48 0 01-.24-.38c0-.15.1-.2.23-.13l.71.39a5 5 0 00-.78-2.38c-1.3-2.09-1.8-4.05-1.49-5.5zM535 409.7l8.69 4.81.15 9.88-8.69-4.81zm8.41 14l-.13-8.92-7.85-4.35.14 8.93 7.84 4.34" />
                  <path d="M539.37 413.21a.35.35 0 01.15.26v.12a7.52 7.52 0 013.09 4.78 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.35 1.35 0 00-.53-.74.33.33 0 00-.52.13 1.31 1.31 0 00-.55-.73c-.16-.09-.28-.09-.37 0v3.37c0 .66-.46.94-1.05.61a2.32 2.32 0 01-1.08-1.81v-.37a.11.11 0 01.17-.1.36.36 0 01.15.28v.37a1.63 1.63 0 00.75 1.27c.41.23.74 0 .74-.43v-3.38a1 1 0 00-.38-.39c-.24-.13-.43-.08-.53.12a1.28 1.28 0 00-.54-.72c-.24-.13-.43-.08-.53.14a1.32 1.32 0 00-.54-.75c-.24-.13-.44-.08-.54.15a1.91 1.91 0 013-1.35v-.12c.02-.09.09-.13.19-.08zM521.17 409.86l.01.58-5.34-2.96-.01-.58 5.34 2.96zM521.15 408.74l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                  <path d="M519.9 402.75l1.33 2.83-.96-.53.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                  <path d="M514 398.09l8.68 4.81.15 9.88-8.65-4.78zm8.4 14l-.13-8.93-7.85-4.35.13 8.93 7.85 4.35" />
                  <path d="M516.9 401.09l1.33 2.83-.96-.53.05 3.23-.68-.38-.05-3.23-.95-.52 1.26-1.4zM524.51 403.89l8.69 4.82.14 9.88-8.69-4.82zm8.41 14l-.14-8.93-7.84-4.34.13 8.92 7.85 4.35" />
                </g>
              </motion.g>
              <motion.g id="ltlbackground__box8"
              
              
              
              
              >
                <g
                  style={{
                    isolation: "isolate",
                  }}
                >
                  <path
                    fill="#f2c0a7"
                    d="M690.12 405.78l.67 45.44-50.06 30.29-.68-45.44 50.07-30.29z"
                  />
                  <path
                    fill="#e2af98"
                    d="M670.61 417.61l.15 9.88-3.46 2.08-3.96 2.41-.15-9.88 7.42-4.49z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M640.05 436.07l.68 45.44-60.28-33.39-.67-45.44 60.27 33.39z"
                  />
                  <path
                    fill="#ffdcc5"
                    d="M690.12 405.78l-50.07 30.29-60.27-33.39 50.06-30.29 60.28 33.39z"
                  />
                  <path
                    fill="#fed0b5"
                    d="M670.61 417.61l-7.42 4.49-60.28-33.39 7.42-4.49 60.28 33.39z"
                  />
                </g>
                <g fill="#616173">
                  <path d="M599.84 447.23a5.11 5.11 0 01.13 1.06 3 3 0 01-1.47 2.89 1.61 1.61 0 00-.71 1.54l.71.39a.54.54 0 01.23.38c0 .15-.11.19-.22.13l-1.87-1a.47.47 0 01-.24-.38c0-.15.1-.19.23-.12l.71.39a5 5 0 00-.78-2.39c-1.3-2.09-1.8-4.05-1.49-5.49zM603.57 448l8.69 4.81.15 9.88-8.69-4.81zm8.43 14l-.13-8.93-7.87-4.4.14 8.93L612 462" />
                  <path d="M608 451.48a.32.32 0 01.15.26v.11a7.55 7.55 0 013.09 4.79 1.33 1.33 0 00-.54-.75c-.24-.13-.44-.08-.54.12a1.33 1.33 0 00-.54-.73.34.34 0 00-.52.13 1.31 1.31 0 00-.55-.73c-.16-.08-.28-.09-.37 0v3.37c0 .66-.46.94-1 .62a2.34 2.34 0 01-1.08-1.82v-.37c0-.1.08-.14.17-.09a.36.36 0 01.15.27v.34a1.61 1.61 0 00.75 1.26c.41.23.74 0 .74-.43v-3.37a1.05 1.05 0 00-.38-.4c-.24-.13-.43-.08-.53.13a1.31 1.31 0 00-.54-.73c-.24-.13-.43-.08-.53.14a1.26 1.26 0 00-.54-.74.34.34 0 00-.54.14 1.91 1.91 0 013-1.35v-.11c-.05-.08.01-.12.15-.06zM589.76 448.13l.01.57-5.34-2.95-.01-.58 5.34 2.96zM589.74 447l.01.58-5.34-2.96-.01-.58.83.46.68.38 2.32 1.28.68.38.83.46z" />
                  <path d="M588.49 441.01l1.33 2.84-.96-.53.05 3.22-.68-.38-.05-3.22-.95-.53 1.26-1.4z" />
                  <path d="M582.63 436.35l8.68 4.82.15 9.88-8.69-4.81zm8.4 14l-.13-8.93-7.85-4.34.13 8.92 7.85 4.35" />
                  <path d="M585.49 439.35l1.33 2.84-.96-.54.05 3.23-.68-.38-.05-3.22-.95-.53 1.26-1.4zM593.1 442.16l8.69 4.81.14 9.88-8.69-4.85zm8.41 14l-.14-8.92-7.84-4.35.13 8.93 7.85 4.34" />
                </g>
              </motion.g>
            </motion.g>
            <path
              fill="#333"
              d="M725.59 516.4l-3.36-189.81-122.15 78.6 3.35 189.42 122.16-78.21z"
            />
            <g>
              <path
                d="M749.15 438.71c48.74-16 87.69 66.32 87.69 66.32l2.56 144.85-109.35 65.8-90.24-211.16z"
                fill="#8e000b"
              />
              <path
                d="M639.81 504.52l2.88 163.17 87.36 48-2.56-144.84s-38.94-82.36-87.68-66.33z"
                fill="#c1272d"
              />
              <path
                d="M845.62 557.65h.38l1.48 82.6a3.69 3.69 0 01-2.06 3 11.81 11.81 0 01-10.63.22 3.88 3.88 0 01-2.32-3v-.16l-1.47-82.7h.43A4.73 4.73 0 01833 556a11.81 11.81 0 0110.63-.22 4.79 4.79 0 011.99 1.87z"
                fill="#df8f92"
              />
              <path
                d="M833.28 560.82a11.8 11.8 0 0010.63-.21c2.85-1.75 2.73-4.51-.27-6.14a11.77 11.77 0 00-10.63.22c-2.85 1.74-2.73 4.5.27 6.13z"
                fill="#e9b1b3"
              />
              <g fill="#bdbdbd">
                <path d="M840.65 500.8h.16l1 57.08a1.64 1.64 0 01-.91 1.32 5.27 5.27 0 01-4.74.1 1.76 1.76 0 01-1-1.32v-.07l-1-57.13h.2a2.06 2.06 0 01.72-.68 5.27 5.27 0 014.74-.1 2.14 2.14 0 01.83.8z" />
                <path d="M840.81 500.8s.61-8.4-9.73-10.92c-1.34-.61-4.92 4.34-.58 6.19s3.61 4.71 3.61 4.71l.08 4.25 6.69-.12z" />
              </g>
              <path
                fill="#e9b1b3"
                d="M894.8 597.13l-2.62 1.62.11 6.14 2.62-1.34-.11-6.42z"
              />
              <path
                fill="#919191"
                d="M898.84 581.71l.69.37-.66 1.36.43 24.47-3.22 4.72-.31.63-.7-.38-.46-26.19 4.23-4.98z"
              />
              <path
                fill="#b5b5b5"
                d="M895.77 613.26l4.23-4.98-.47-26.2-4.22 4.98.46 26.2z"
              />
              <path
                fill="#df8f92"
                d="M900 608.28l3.44-10.12-7.67 15.1 4.23-4.98z"
              />
              <path
                fill="#fff"
                d="M899.53 582.08l3.91 16.08-8.13-11.1 4.22-4.98z"
              />
              <path
                fill="#e6a6a9"
                d="M895.31 587.06l8.13 11.1-7.67 15.1-.46-26.2z"
              />
              <path
                d="M830.72 529.46c34.51-8.4 53.25 33.71 53.25 33.71l11.59 47.38 1.44 83.77-77 46.15-66.34-164.86z"
                fill="#ed1c24"
              />
              <path
                d="M753.66 575.61L751.35 703 820 740.47l-1.48-83.76-11.58-47.38s-18.77-42.12-53.28-33.72z"
                fill="#8e000b"
              />
              <path d="M820.85 772.65l30.52-18.38-11.91-11.7-20.72 14.83 2.11 15.25z" />
              <path
                d="M645 516.48l-.13-.23 2.8-1.66a2.7 2.7 0 012.63.23 8.6 8.6 0 014 6.68 2.81 2.81 0 01-1 2.55l-.1.06-2.87 1.71-.18-.26a3.57 3.57 0 01-1.56-.48 8.64 8.64 0 01-4-6.68 3.41 3.41 0 01.41-1.92z"
                fill="#fff"
              />
              <path
                d="M651.46 523.18a8.64 8.64 0 00-4-6.68c-2.13-1.18-3.84-.11-3.8 2.38a8.61 8.61 0 004 6.68c2.13 1.18 3.84.11 3.8-2.38z"
                fill="#a3a3a3"
              />
              <path
                d="M645.34 535.4l-.12-.23 2.8-1.66a2.7 2.7 0 012.63.23 8.66 8.66 0 014 6.68 2.84 2.84 0 01-1.05 2.55l-.1.06-2.87 1.71-.18-.26a3.49 3.49 0 01-1.56-.48 8.59 8.59 0 01-4-6.68 3.42 3.42 0 01.45-1.92z"
                fill="#696969"
              />
              <path
                d="M651.79 542.1a8.59 8.59 0 00-4-6.68c-2.13-1.18-3.84-.11-3.8 2.38a8.64 8.64 0 004 6.68c2.14 1.18 3.85.11 3.8-2.38z"
                fill="#4d4d4d"
              />
              <path
                fill="#fff"
                d="M751.74 683.65l15.86 8.81-21.97 13.2-15.78-8.82 21.89-13.19z"
              />
              <path
                fill="#a3a3a3"
                d="M729.76 696.85l15.87 8.81.33 18.81-15.86-8.81-.34-18.81z"
              />
              <path
                fill="#919191"
                d="M745.96 724.47l-.33-18.81 21.97-13.2.34 19.24-21.98 12.77z"
              />
              <path
                fill="#fff"
                d="M767.63 693.96l64.53 35.73-14.37 8.67-64.53-35.72 14.37-8.68z"
              />
              <path
                fill="#a3a3a3"
                d="M753.17 702.65l64.54 35.73.15 8.67-64.53-35.72-.16-8.68z"
              />
              <path
                fill="#fff"
                d="M753.35 711.31l64.53 35.72-7.43 4.47-64.54-35.72 7.44-4.47z"
              />
              <path
                fill="#a3a3a3"
                d="M745.83 715.79l64.53 35.72.15 8.45-64.53-35.49-.15-8.68zM758 609.5h.35l1.47 82.6a3.7 3.7 0 01-2 3 11.83 11.83 0 01-10.64.21 3.87 3.87 0 01-2.32-3 .66.66 0 010-.14l-1.49-82.71h.44a4.73 4.73 0 011.62-1.54 11.77 11.77 0 0110.63-.21 4.72 4.72 0 011.94 1.79z"
              />
              <path
                d="M745.63 612.67a11.81 11.81 0 0010.63-.22c2.85-1.74 2.72-4.5-.28-6.13a11.77 11.77 0 00-10.63.21c-2.85 1.75-2.72 4.47.28 6.14z"
                fill="#fff"
              />
              <g fill="#bdbdbd">
                <path d="M753 552.64h.15l1 57.07a1.67 1.67 0 01-.92 1.33 5.24 5.24 0 01-4.74.09 1.71 1.71 0 01-1-1.32v-.07l-1-57.13h.19a2.08 2.08 0 01.72-.69 5.24 5.24 0 014.74-.09 2.15 2.15 0 01.86.81z" />
                <path d="M753.15 552.65s.61-8.41-9.72-10.93c-1.35-.61-4.93 4.34-.58 6.19s3.61 4.71 3.61 4.71l.07 4.25 6.69-.12z" />
              </g>
              <path
                d="M659.28 524.57l.32 18.11 48.75 26.78-.13-7.88s-21.74-45.96-48.94-37.01z"
                fill="#fff"
              />
              <path
                fill="#1d1d1b"
                d="M659.74 550.92l48.76 27.02 1.63 92.11-48.76-27.02-1.63-92.11z"
                opacity={0.4}
              />
              <path
                fill="#fff"
                d="M663.08 556.86l42.23 23.4.64 35.83-42.24-23.4-.63-35.83zM884.36 573.71l-72.77 43.62 7.38 31.97 73.33-43.79-7.94-31.8z"
              />
              <path
                transform="rotate(-15.76 852.655 609.75)"
                fill="#ed1c24"
                d="M850.87 586.53h3.96v46.65h-3.96z"
              />
              <path
                fill="#fff"
                d="M802.98 617.49l7.38 31.97-48.32-26.62-.47-26.86 41.41 21.51z"
              />
              <g
                style={{
                  mixBlendMode: "overlay",
                }}
                opacity={0.4}
              >
                <path
                  fill="#1d1d1b"
                  d="M762.04 622.76l48.32 26.77 1.05 59.56-48.32-26.77-1.05-59.56z"
                />
              </g>
              <g>
                <path
                  fill="#d15d62"
                  d="M766.84 649.7l8.6 4.76-.05-2.87-8.6-4.76.05 2.87z"
                />
                <path
                  fill="#e6a6a9"
                  d="M766.89 652.63l8.6 4.76-.05-2.87-8.6-4.77.05 2.88z"
                />
              </g>
              <g>
                <path
                  d="M899.51 689.55l19.76-12.37a31.7 31.7 0 014.45-2.79c8.2-4.26 21.59-6.78 40.3 3.52 33.6 18.49 33.6 18.67 33.6 18.67l.56 31.69-28 16.84-84.21-46z"
                  fill="#ef3a40"
                />
                <path
                  d="M886 699.11s16.45-22.85 50-4.36 33.6 18.67 33.6 18.67l.56 31.69z"
                  fill="#4d4d4d"
                />
                <path
                  fill="#828282"
                  d="M997.62 696.58l-28.02 16.84.56 31.69 28.02-16.84-.56-31.69z"
                />
                <g>
                  <path
                    fill="#fff"
                    d="M995.79 719.21l-.33-18.73-23.42 14.07.33 18.74 23.42-14.08z"
                  />
                  <path
                    d="M993.54 703.94l.25 14.17-19.5 11.72-.29-14.17 19.51-11.72zm3.83-6.93l-5.87 3.54-19.5 11.72-2 1.18v2.28l.25 14.17.12 6.85 5.87-3.53 19.51-11.72 1.95-1.18v-2.28l-.25-14.17-.12-6.86z"
                    fill="#c1272d"
                  />
                  <g fill="#c1272d">
                    <path d="M995.93 727.56l-.16-9.46-23.42 14.08.16 9.46 23.42-14.08z" />
                    <path d="M993.85 721.57l.08 4.88-19.5 11.73-.08-4.89 19.5-11.72zm3.83-6.93l-5.87 3.53-19.5 11.73-2 1.17v2.29l.09 4.89.12 6.85 5.87-3.53L996 729.84l2-1.17v-2.29l-.08-4.88-.13-6.86z" />
                  </g>
                </g>
              </g>
              <g>
                <path d="M900.48 702.92l-14.65-8.09z" fill="#696969" />
                <path
                  fill="#8e000b"
                  d="M823.93 660.66l3.62 2.01 58.28 32.16 14.65 8.09 1.32 74.76-76.54-41.81-1.33-75.21z"
                />
                <path
                  fill="#ef3a40"
                  d="M929.22 674.27l-63.96-34.67 19.31-15.67 76.63 42.3-31.98 8.04z"
                />
                <path
                  fill="#d1d1d1"
                  d="M901.82 779.02l-.02-1.34 7.68 4.2-7.66-2.86zM909.48 781.88l60.64-36.73zM961.2 666.23l7.6 4.2-7.6-4.2z"
                />
                <path d="M908.16 707.16l-7.68-4.24z" fill="#696969" />
                <path d="M908.16 707.16l-7.68-4.24z" fill="#d1d1d1" />
                <path
                  fill="#8c8c8c"
                  d="M900.48 702.92l7.68 4.24 1.32 74.72-7.68-4.2-1.32-74.76z"
                />
                <path
                  fill="#ccc"
                  d="M929.22 674.27l31.98-8.04 7.6 4.2-30.49 8.76-9.09-4.92z"
                />
                <path
                  fill="#a3a3a3"
                  d="M908.16 707.16l30.15-27.97 30.49-8.76 1.32 74.72-60.64 36.73-1.32-74.72z"
                />
                <path
                  d="M865.26 639.6l-41.32 21.06z"
                  fill="#1d1d1b"
                  opacity={0.1}
                />
                <path
                  fill="#8a8a8a"
                  d="M929.22 674.27l-.84.21-27.9 28.44-14.65-8.09-61.9-34.17 41.33-21.06 63.96 34.67z"
                />
                <path
                  fill="#8e000b"
                  d="M929.22 674.27l-.84.21-27.9 28.44-14.65-8.09-61.9-34.17 41.33-21.06 63.96 34.67z"
                />
                <path
                  fill="#b5b5b5"
                  d="M900.48 702.92l27.9-28.44.84-.21 9.09 4.92-30.15 27.97-7.68-4.24z"
                />
                <g>
                  <path d="M941.41 704.41l22.74-13.67-.05-3.15-22.74 13.67.05 3.15zM941.55 712.52l22.75-13.67-.06-3.15-22.74 13.67.05 3.15zM941.7 720.63l22.74-13.67-.06-3.15-22.74 13.67.06 3.15zM941.84 728.74l22.74-13.67-.05-3.16-22.74 13.68.05 3.15zM941.99 736.85l22.74-13.67-.06-3.16-22.74 13.68.06 3.15zM942.13 744.96l22.74-13.68-.05-3.15-22.75 13.67.06 3.16zM942.27 753.07l22.74-13.68-.05-3.15-22.74 13.67.05 3.16z" />
                  <g>
                    <path d="M914.66 720.5l22.74-13.68-.05-3.15-22.74 13.67.05 3.16zM914.81 728.6l22.74-13.67-.06-3.15-22.74 13.67.06 3.15zM914.95 736.71l22.74-13.67-.06-3.15-22.74 13.67.06 3.15zM915.09 744.82l22.74-13.67-.05-3.15-22.74 13.67.05 3.15zM915.24 752.93l22.74-13.67-.06-3.16-22.74 13.68.06 3.15zM915.38 761.04l22.74-13.67-.05-3.16-22.75 13.67.06 3.16zM915.52 769.15l22.74-13.68-.05-3.15-22.74 13.67.05 3.16z" />
                  </g>
                </g>
                <path d="M908.28 714.19l60.84-36.57-.41-.68-60.44 36.34-84.03-46.67-.39.69 84.43 46.89z" />
              </g>
              <g>
                <path d="M829 762.46l-.71-1.3 16.26-9.68c4-2.19 9.32-1.94 15.25 1.35 12.4 6.87 22.75 24.33 23 38.81.12 7.11-2.22 12.22-6.1 14.77l-.09.07c-.16.1-.33.19-.5.28l-16.67 9.93-1-1.51a20.28 20.28 0 01-9.07-2.76c-12.4-6.87-22.75-24.33-23-38.81a20.14 20.14 0 012.63-11.15z" />
                <path
                  d="M866.45 801.4c-.25-14.48-10.6-31.94-23-38.81s-22.33-.64-22.07 13.84 10.61 31.94 23 38.8 22.33.64 22.07-13.83z"
                  fill="#212121"
                />
                <ellipse
                  cx={843.92}
                  cy={788.91}
                  rx={10.74}
                  ry={18.99}
                  transform="rotate(-28.26 843.76 788.829)"
                  fill="#df8f92"
                />
                <path d="M853.45 790.05a11.14 11.14 0 00-4.91-8.58c-2.64-1.52-4.76-.15-4.7 3a11.14 11.14 0 004.91 8.58c2.64 1.57 4.76.2 4.7-3z" />
                <path
                  d="M866.45 801.4c-.25-14.48-10.6-31.94-23-38.81s-22.33-.64-22.07 13.84 10.61 31.94 23 38.8 22.33.64 22.07-13.83z"
                  fill="#212121"
                />
                <ellipse
                  cx={843.92}
                  cy={788.91}
                  rx={10.74}
                  ry={18.99}
                  transform="rotate(-28.26 843.76 788.829)"
                  fill="#df8f92"
                />
                <path d="M853.45 790.05a11.14 11.14 0 00-4.91-8.58c-2.64-1.52-4.76-.15-4.7 3a11.14 11.14 0 004.91 8.58c2.64 1.57 4.76.2 4.7-3z" />
              </g>
              <g>
                <path
                  d="M847 753.52c-13.49-7.48-24.55-2-26.26 12l-23.48-12.83 13.56-9.55 19.76-12.37A32.18 32.18 0 01835 728c8.19-4.25 21.58-6.77 40.29 3.53 33.6 18.49 33.6 18.66 33.6 18.66l.56 31.7-28 16.84-7.34-4c-2.37-16.13-13.75-33.81-27.11-41.21z"
                  fill="#ef3a40"
                />
                <path
                  fill="#828282"
                  d="M908.92 750.18l-28.01 16.85.56 31.69 28.01-16.84-.56-31.7z"
                />
                <path
                  fill="#fff"
                  d="M907.09 772.82l-.33-18.74-23.42 14.08.33 18.74 23.42-14.08z"
                />
                <path
                  d="M904.84 757.55l.25 14.16-19.5 11.73-.25-14.17 19.5-11.72zm3.83-6.93l-5.87 3.53-19.5 11.73-2 1.17v2.29l.25 14.17.12 6.85 5.88-3.53 19.5-11.72 2-1.18v-2.29l-.25-14.16-.13-6.86z"
                  fill="#c1272d"
                />
                <g fill="#c1272d">
                  <path d="M907.24 781.17l-.17-9.46-23.42 14.08.17 9.46 23.42-14.08z" />
                  <path d="M905.15 775.17l.09 4.89-19.51 11.72-.08-4.88 19.5-11.73zm3.84-6.92l-5.88 3.53-19.5 11.73-2 1.17v2.29l.09 4.88.12 6.86 5.87-3.53 19.51-11.73 2-1.18v-2.28l-.08-4.89-.12-6.85z" />
                </g>
              </g>
              <g>
                <path
                  fill="#a6a6a6"
                  d="M813.53 645.58l-2.62 1.63.11 6.14 2.62-1.35-.11-6.42z"
                />
                <path
                  fill="#919191"
                  d="M810.34 635.36l.69.38-.66 1.35.43 24.47-14.26 3.57-.31.63-.7-.38-.46-26.2 15.27-3.82z"
                />
                <path
                  fill="#b5b5b5"
                  d="M796.23 665.76l15.27-3.82-.47-26.2-15.26 3.82.46 26.2z"
                />
                <path
                  fill="#a6a6a6"
                  d="M811.5 661.94l-7.6-11.29-7.67 15.11 15.27-3.82z"
                />
                <path
                  fill="#ccc"
                  d="M811.03 635.74l-7.13 14.91-8.13-11.09 15.26-3.82z"
                />
              </g>
              <path
                d="M847 753.52c-13.49-7.48-24.55-2-26.26 12l-23.48-12.83s16.45-22.84 50-4.35S880.91 767 880.91 767l.56 31.69-7.34-4c-2.39-16.09-13.77-33.77-27.13-41.17z"
                fill="#42210b"
              />
              <g>
                <path
                  fill="#8f8f8f"
                  d="M881.8 822.86l123.07-74.18-.37-21.02-123.07 74.19.37 21.01z"
                />
                <path
                  fill="#a6a6a6"
                  d="M1004.5 727.66l-6.39-3.21.07 3.82 6.32-.61z"
                />
                <path
                  fill="#ccc"
                  d="M1004.5 727.66l-6.32.61-116.71 70.45-.04 3.13 123.07-74.19z"
                />
                <path
                  fill="#7a7a7a"
                  d="M871.72 817.29l10.08 5.57-.37-21.01-10.08-5.57.37 21.01z"
                />
                <path
                  fill="#999"
                  d="M871.35 796.28l2.78-1.57 7.34 4.01-.04 3.13-10.08-5.57z"
                />
              </g>
              <g>
                <path
                  fill="#c1272d"
                  d="M855.99 699.72l-23.9-13.25.16 9.46 23.91 13.24-.17-9.45z"
                />
                <path
                  d="M834.13 689.87l19.9 11 .09 4.89-19.91-11-.08-4.88zm-4.08-6.79l.12 6.86.09 4.88v2.29l2 1.1 19.91 11 6 3.32-.13-6.85-.08-4.89v-2.28l-2-1.11-19.96-11-6-3.32z"
                  fill="#212121"
                />
              </g>
              <path d="M640.86 672.9l179.99 99.72-.12-7.08-179.99-99.72.12 7.08z" />
              <path
                fill="#c1272d"
                d="M640.74 665.82l1.89-1.54.02 1.1 178.25 98.95-.17 1.21-179.99-99.72z"
              />
              <g>
                <path d="M989.56 751.71l8.18-4.92-.15-8.26-8.18 4.91.15 8.27zM963.93 767.11l18.46-11.1-.14-8.26-18.47 11.1.15 8.26zM898.76 797.94l-8.18 4.92.15 8.26 8.17-4.92-.14-8.26zM924.39 782.53l-18.47 11.1.15 8.26 18.47-11.1-.15-8.26z" />
              </g>
              <g
                style={{
                  mixBlendMode: "overlay",
                }}
                opacity={0.5}
              >
                <path
                  d="M743.55 457.35c18.59-.33 33.73 22.77 39.61 33.18a5.77 5.77 0 01-2.07 7.79L749 517.55a5.65 5.65 0 01-2.86.82 5.75 5.75 0 01-5.12-2.91c-4.86-8.57-15.74-25.23-29.56-31a5.78 5.78 0 01-.8-10.3l25.64-15.35a6.22 6.22 0 011.35-.59 22.28 22.28 0 015.95-.91zm0-2.77a25.06 25.06 0 00-6.68 1 8.36 8.36 0 00-2 .87l-25.64 15.34a8.54 8.54 0 001.16 15.23c13.36 5.54 24.18 22.67 28.21 29.77a8.52 8.52 0 007.58 4.32 8.43 8.43 0 004.23-1.21l32.15-19.23a8.51 8.51 0 003.05-11.53 105.62 105.62 0 00-15.5-21.14c-8.81-9.05-17.76-13.56-26.61-13.4z"
                  fill="#1d1d1b"
                />
              </g>
              <path
                transform="rotate(-61.09 686.128 672.747)"
                d="M685.66 622.81h.79v99.84h-.79z"
              />
              <g
                style={{
                  mixBlendMode: "overlay",
                }}
                opacity={0.3}
              >
                <path d="M900.48 702.92l.12 6.55-76.55-42.51-.12-6.3 76.55 42.26z" />
              </g>
            </g>
          </g>
        </g>
      </motion.g>
    </svg>
  );
}

export default SvgComponent;

